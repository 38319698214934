import { Button, ButtonProps } from 'antd';
import { FC } from 'react';

import styles from './appHeaderButton.module.less';

const AppHeaderButton: FC<ButtonProps> = (props: ButtonProps) => {
  const { children, ...rest } = props;

  return (
    <Button className={styles.wrapper} {...rest}>
      {children}
    </Button>
  );
};

export default AppHeaderButton;
