import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { Button, Menu } from 'antd';
import PageHeader from 'components/PageHeader/PageHeader';
import { ReactComponent as TimeOff } from 'images/pageHeaderIcons/time-off.svg';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import {
  HOLIDAY_AND_TIMEOFF_CHANNEL,
  OPEN_ADD_NEW_PLAN_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import HolidaysAndTimeOffSampleBannerImage from 'images/samples/holidays.png';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import PlanSelectButton from 'components/PlanSelectButton/PlanSelectButton';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import HolidayAndTimeOffCard from 'modules/holidaysAndTimeOff/components/HolidayAndTimeOffCard/HolidayAndTimeOffCard';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  addMultiplePlans,
  fetchHolidayAndTimeOffPlanList,
  getDefaultText,
  handleSectionImageUpload,
} from 'modules/holidaysAndTimeOff/slices/holidayAndTimeOffSlice';
import useBenguide from 'hooks/benguide';
import PlanReference from 'model/PlanReference';
import HolidayAndTimeOffPlansChannel from 'modules/clients/UPClient/channels/HolidayAndOffTimeChannel';
import editIcon from 'images/icon-edit.svg';
import { SectionName } from 'modules/home/constants';
import { getSectionImageUrl } from 'services/BenGuideService';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import {
  BENEFIT_CATEGORY,
  HOLIDAY_TIME_OFF_BENEFITS_KIND,
} from 'constants/commonConstants';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';
import PageSection from 'model/PageSection';
import { getBase64Image } from 'utils/screenshotUtil';
import { benguideApiV2 } from 'utils/apiUtil';
import { formatDataId } from 'utils/commonUtil';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { swapElements } from 'modules/benefitsWellness/util';
import styles from './holidaysAndTimeOff.module.less';

const HolidaysAndTimeOff: FC = () => {
  const upClient = useUPClient();
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const {
    planList: planListWrapper,
    holidayAndTimeOffSection,
    sections,
  } = useAppSelector((state) => state.holidayAndTimeOff);
  const [bannerImage, setBannerImage] = useState<string>('');
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [sortedFourOOneKPlans, setSortedPlans] = useState<any[]>([]);
  const addedPlansLoading = useAppSelector(
    (state) => state.holidayAndTimeOff.holidayAndTimeOffSection.inProgress
  );

  const {
    employerId,
    planYearId,
    masterId,
    isEditMode,
    benefitClasses,
    revision,
    latestRevision,
  } = benguide;
  const { data: planList = [], inProgress } = planListWrapper;
  const { plans: addedPlans = [] } = holidayAndTimeOffSection;
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [originalImg, setOriginalImg] = useState<string>('');
  const imageUploaderRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  const listAllHolidaysPlans = useCallback(() => {
    dispatch(
      fetchHolidayAndTimeOffPlanList(employerId, planYearId, benefitClasses)
    );
  }, [benefitClasses, dispatch, employerId, planYearId]);

  useEffect(() => {
    listAllHolidaysPlans();
  }, [listAllHolidaysPlans]);

  useEffect(() => {
    if (masterId) {
      setBannerImage(
        getSectionImageUrl(
          masterId,
          SectionName.HOLIDAY_TIME_OFF_IMAGE,
          latestRevision || revision
        )
      );
    }
  }, [dispatch, masterId, revision, latestRevision]);

  const moveTop = (index: number) => {
    if (addedPlansLoading) return;
    if (index > 0) {
      const newPlans = swapElements(
        sortedFourOOneKPlans,
        index,
        index - 1
      ) as any[];
      const updatedPlansWithOrder = newPlans.map((plan, i) => ({
        ...plan,
        orderNo: i + 1,
      }));
      setSortedPlans(updatedPlansWithOrder);
      updatePlans(updatedPlansWithOrder, false);
    }
  };

  const moveBottom = (index: number) => {
    if (addedPlansLoading) return;
    if (index < sortedFourOOneKPlans.length - 1) {
      const newPlans = swapElements(
        sortedFourOOneKPlans,
        index,
        index + 1
      ) as any[];
      const updatedPlansWithOrder = newPlans.map((plan, i) => ({
        ...plan,
        orderNo: i + 1,
      }));
      setSortedPlans(updatedPlansWithOrder);
      updatePlans(updatedPlansWithOrder, false);
    }
  };

  useEffect(() => {
    if (addedPlans) {
      const uniquePlansList = uniqBy(addedPlans, 'benefitKind');
      if (uniquePlansList) {
        uniquePlansList.forEach((element: any) => {
          if (element.id && element.benefitKind) {
            dispatch(getDefaultText(masterId, element.id, element.benefitKind));
          }
        });
      }
      setSortedPlans(addedPlans);
    }
  }, [dispatch, addedPlans, masterId]);

  useEffect(() => {
    if (sections) {
      const imgSection = sections.find(
        (section: PageSection) =>
          section.name === SectionName.HOLIDAY_TIME_OFF_IMAGE
      );
      if (imgSection?.data?.originalImageReference) {
        getBase64Image(
          `${benguideApiV2}/${masterId}/images?section-name=${
            SectionName.HOLIDAY_TIME_OFF_IMAGE
          }&original=true&revision=${latestRevision || revision}`
        ).then((data: any) => {
          setOriginalImg(data);
          if (loadingImage) {
            (imageUploaderRef?.current as any)?.close();
            setLoadingImage(false);
          }
        });
      }
    }
    // No need call when change loadingImage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterId, sections]);

  const updatePlans = (plans: any[], orderByPlanNames: boolean) => {
    const planReferences = plans.map((plan: PlanReference) => {
      return {
        ...plan,
        planId: plan.id,
        planName: plan.name,
        carrier: {
          id: get(plan, 'carrier.id', null),
          name: get(plan, 'carrier.name', null),
        },
        benefitCarrier: {
          id: get(plan, 'benefitCarrier.id', null),
          name: get(plan, 'benefitCarrier.name', null),
        },
      };
    });

    const addedPlansPayload: Array<AddPlanApiRequestPayload> = [];
    HOLIDAY_TIME_OFF_BENEFITS_KIND.forEach((benefitKind: string) => {
      return addedPlansPayload.push({
        sectionName: benefitKind,
        plans: planReferences.filter(
          (planRef) =>
            planRef.benefitKind && planRef.benefitKind === benefitKind
        ),
        orderByPlanNames: orderByPlanNames,
      });
    });
    dispatch(addMultiplePlans(masterId, addedPlansPayload));
  };

  const getPlanTypes = () => {
    return (
      <Menu>
        {[
          benefitCategory.PAID_TIME_OFF,
          benefitCategory.SICK,
          benefitCategory.HOLIDAY,
          benefitCategory.FLEXIBLE_WORKING_HOURS,
          benefitCategory.OTHER_TIME_OFF,
        ].map((item, key) => {
          return (
            <Menu.Item
              key={key}
              className={styles.menuItemHeader}
              data-id={formatDataId(`${item.label}-optn`)}
            >
              <EditableHotspot
                alwaysVisible={false}
                useChannel={{
                  client: upClient,
                  channel: HOLIDAY_AND_TIMEOFF_CHANNEL,
                  event: OPEN_ADD_NEW_PLAN_MODAL,
                  data: { item },
                }}
              >
                {item.label}
              </EditableHotspot>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const handleImageUpload = (
    image: string,
    originalImage?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    setLoadingImage(true);
    setBannerImage(image);
    dispatch(
      handleSectionImageUpload(
        masterId,
        image,
        SectionName.HOLIDAY_TIME_OFF_IMAGE,
        originalImage,
        cropArea
      )
    );
  };

  const sectionImageCrop = useMemo(() => {
    if (sections) {
      const imgSection = sections?.find(
        (section: PageSection) =>
          section.name === SectionName.HOLIDAY_TIME_OFF_IMAGE
      );

      return imgSection
        ? (imgSection as PageSection).data.originalImageCropParams
        : null;
    }
    return null;
  }, [sections]);

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.holidaysAndTimeOffWrapper}>
        <HolidayAndTimeOffPlansChannel />
        <BenefitGuideRefreshChannel
          benefitKind={BENEFIT_CATEGORY.HOLIDAYS_AND_TIME_OFF.value}
        />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle="Holidays & Time Off"
          pageHeaderIcon={<TimeOff className={styles.holIcon} />}
        />

        {isEditMode && !isEmpty(addedPlans) && (
          <div className={styles.addOrRemovePlansWrapper}>
            <EditableHotspot
              alwaysVisible={true}
              customModal={(visible: boolean, onClose: Function) => (
                <AddOrRemovePlans
                  onClose={onClose}
                  visible={visible}
                  isLoading={inProgress}
                  title="Holidays & Time Off"
                  onSave={updatePlans}
                  addedPlanList={addedPlans}
                  allPlans={planList || []}
                  reloadPlans={listAllHolidaysPlans}
                  channel={HOLIDAY_AND_TIMEOFF_CHANNEL}
                  actionContent={
                    <PlanSelectButton
                      overlay={getPlanTypes()}
                      selectLabel="Create New Plan"
                    />
                  }
                  showAlert={showAlert}
                />
              )}
            >
              <Button>+ Add or Remove Plans</Button>
            </EditableHotspot>
          </div>
        )}

        <EditableHotspot
          alwaysVisible={false}
          editText="Edit"
          editIcon={<img src={editIcon} alt="edit" />}
          customModal={(visible: boolean, onClose: Function) => (
            <ImageUploader
              title="Holidays & Time Off Photo"
              cropShape="rect"
              uploadedImage={bannerImage}
              aspect={1}
              loading={loadingImage}
              onConfirm={handleImageUpload}
              showOperations
              uploadNewImgText="+ Upload New Photo"
              isOpenImageTray={visible}
              onCloseImgTray={onClose}
              uploadImgAfterResetText="Upload Photo"
              hideUploader
              customCropper={{ width: 780, height: 248 }}
              defaultLogo={HolidaysAndTimeOffSampleBannerImage}
              imagePreviewStyle={styles.mainImage}
              uploadWrapperClass={styles.imageUploadWrapper}
              maxSizeMB={4}
              defaultCropArea={sectionImageCrop}
              unCroppedImage={originalImg}
              ref={imageUploaderRef}
              preventCloseOnConfirm={true}
              saving={loadingImage}
            />
          )}
        >
          {<></>}
        </EditableHotspot>

        {isEmpty(addedPlans) && isEditMode && (
          <AddOrRemovePlans
            visible={true}
            withoutModal={true}
            isLoading={inProgress}
            title="Holidays & Time Off"
            onSave={updatePlans}
            addedPlanList={addedPlans}
            allPlans={planList || []}
            reloadPlans={listAllHolidaysPlans}
            channel={HOLIDAY_AND_TIMEOFF_CHANNEL}
            actionContent={
              <PlanSelectButton
                overlay={getPlanTypes()}
                selectLabel="Create New Plan"
                className={isEmpty(planList) ? styles.darkButton : ''}
              />
            }
            showAlert={showAlert}
          />
        )}

        <div className={styles.planCardsContainer}>
          {sortedFourOOneKPlans.map((plan, index) => (
            <HolidayAndTimeOffCard
              key={index}
              dataIndex={index}
              plan={plan}
              moveTop={() => moveTop(index)}
              moveBottom={() => moveBottom(index)}
              hideTop={index === 0}
              hideBottom={index === sortedFourOOneKPlans?.length - 1}
              renderReorder={sortedFourOOneKPlans?.length > 1}
            />
          ))}
          {isEmpty(sortedFourOOneKPlans) && !isEditMode && (
            <NoPlansView className={styles.noPlanView} />
          )}
        </div>
      </div>
    </NotificationContextProvider>
  );
};

export default HolidaysAndTimeOff;
