import Dental from 'modules/benefitsWellness/pages/Dental/Dental';
import Medical from 'modules/benefitsWellness/pages/Medical/Medical';
import Vision from 'modules/benefitsWellness/pages/Vision/Vision';
import BasicInfo from 'modules/home/pages/BasicInfo/BasicInfo';
import Home from 'modules/home/pages/Home/Home';
import NewHireChecklist from 'modules/newHireChecklist/NewHireChecklist';
import Telehealth from 'modules/benefitsWellness/pages/Telehealth/Telehealth';
import Wellbeing from 'modules/benefitsWellness/pages/Wellbeing/Wellbeing';
import HowInsuranceWorks from 'modules/howInsuranceWorks/HowInsuranceWorks';
import LifeAndDisability from 'modules/benefitsWellness/pages/LifeAndDisability/LifeAndDisability';
import DatesEligability from 'modules/datesEligability/pages/datesEligibility/DatesEligability';
import PageTitle from 'routes/PageTitle';
import RouteMap from 'routes/RouteMap';
import UnderDevelopment from 'routes/UnderDevelopment';
import HolidaysAndTimeOff from 'modules/holidaysAndTimeOff/pages/HolidaysAndTimeOff/HolidaysAndTimeOff';
import FamilyAndLeave from 'modules/familyAndLeave/pages/FamilyAndLeave/FamilyAndLeave';
import AdditionalPerks from 'modules/additionalPerks/pages/AdditionalPerks/AdditionalPerks';
import VoluntaryBenefits from 'modules/benefitsWellness/pages/VoluntaryBenefits/VoluntaryBenefits';
import FourOOneK from 'modules/fourOOneK/FourOOneK';
import HSAPlans from 'modules/taxAdvantaged/pages/HSA/HSAPlans';
import HRAPlans from 'modules/taxAdvantaged/pages/HRA/HRAPlans';
import CommuterPlans from 'modules/taxAdvantaged/pages/Commuter/CommuterPlans';
import FSAPlans from 'modules/taxAdvantaged/pages/FSA/FSAPlans';
import Resources from 'modules/resources/pages/Resources';

export type NavItem = {
  path: RouteMap;
  label?: string;
  element: JSX.Element;
  children?: NavItem[];
};

export const routes: NavItem[] = [
  {
    path: RouteMap.BASIC_INFO_EDIT_PATH,
    element: <BasicInfo />,
    label: PageTitle.BASIC_INFO_EDIT_PATH,
  },
  { path: RouteMap.HOME_PATH, element: <Home />, label: PageTitle.HOME_PATH },
  {
    path: RouteMap.NEW_HIRE_CHECKLIST,
    element: <NewHireChecklist />,
    label: PageTitle.NEW_HIRE_CHECKLIST,
  },
  {
    path: RouteMap.HOW_INSURANCE_WORKS,
    element: <HowInsuranceWorks />,
    label: PageTitle.HOW_INSURANCE_WORKS,
  },
  {
    path: RouteMap.DATES_AND_ELIGIBILITY,
    element: <DatesEligability />,
    label: PageTitle.DATES_AND_ELIGIBILITY,
  },
  { path: RouteMap.MEDICAL, element: <Medical />, label: PageTitle.MEDICAL },
  {
    path: RouteMap.TELEHEALTH,
    element: <Telehealth />,
    label: PageTitle.TELEHEALTH,
  },
  { path: RouteMap.DENTAL, element: <Dental />, label: PageTitle.DENTAL },
  { path: RouteMap.VISION, element: <Vision />, label: PageTitle.VISION },
  {
    path: RouteMap.LIFE_AND_DISABILITY,
    element: <LifeAndDisability />,
    label: PageTitle.LIFE_AND_DISABILITY,
  },
  {
    path: RouteMap.VOLUNTARY,
    element: <VoluntaryBenefits />,
    label: PageTitle.VOLUNTARY,
  },
  {
    path: RouteMap.WELLBEING,
    element: <Wellbeing />,
    label: PageTitle.WELLBEING,
  },
  {
    path: RouteMap.HRA,
    element: <HRAPlans />,
    label: PageTitle.HRA,
  },
  {
    path: RouteMap.FSA,
    element: <FSAPlans />,
    label: PageTitle.FSA,
  },
  {
    path: RouteMap.HSA,
    element: <HSAPlans />,
    label: PageTitle.HSA,
  },
  {
    path: RouteMap.COMMUTER,
    element: <CommuterPlans />,
    label: PageTitle.COMMUTER,
  },
  {
    path: RouteMap.FOUR_O_ONE_K,
    element: <FourOOneK />,
    label: PageTitle.FOUR_O_ONE_K,
  },
  {
    path: RouteMap.HOLIDAYS_TIME_OFF,
    element: <HolidaysAndTimeOff />,
    label: PageTitle.HOLIDAYS_TIME_OFF,
  },
  {
    path: RouteMap.FAMILY_AND_LEAVE,
    element: <FamilyAndLeave />,
    label: PageTitle.FAMILY_AND_LEAVE,
  },
  {
    path: RouteMap.ADDITIONAL_PERKS,
    element: <AdditionalPerks />,
    label: PageTitle.ADDITIONAL_PERKS,
  },
  {
    path: RouteMap.RESOURCES,
    element: <Resources />,
    label: PageTitle.RESOURCES,
  },
  {
    path: RouteMap.PREVIEW,
    element: <UnderDevelopment />,
    label: PageTitle.PREVIEW,
  },
  {
    path: RouteMap.PUBLISH,
    element: <UnderDevelopment />,
    label: PageTitle.PUBLISH,
  },
];
