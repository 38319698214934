export const benefitCategory = {
  COMMUTER: { value: 'COMMUTER', label: 'Commuter' },
  TELEHEALTH: { value: 'TELEHEALTH', label: 'Telehealth' },
  RX_DELIVERY: { value: 'RX_DELIVERY', label: 'Rx Delivery' },
  RX_COUPONS: { value: 'RX_COUPONS', label: 'Rx Coupons' },
  MENTAL_HEALTH: { value: 'MENTAL_HEALTH', label: 'Mental Health' },
  PAID_TIME_OFF: { value: 'PAID_TIME_OFF', label: 'Paid Time Off' },
  SICK: { value: 'SICK', label: 'Sick Days' },
  HOLIDAY: { value: 'HOLIDAY', label: 'Company Holidays' },
  FLEXIBLE_WORKING_HOURS: {
    value: 'FLEXIBLE_WORKING_HOURS',
    label: 'Flexible Working Hours',
  },
  OTHER_TIME_OFF: { value: 'OTHER_TIME_OFF', label: 'Other Time Off' },
  PARENTAL_LEAVE: { value: 'PARENTAL_LEAVE', label: 'Parental Leave' },
  BACKUP_CHILDCARE: { value: 'BACKUP_CHILDCARE', label: 'Backup Childcare' },
  FERTILITY: { value: 'FERTILITY', label: 'Fertility' },
  BEREAVEMENT: { value: 'BEREAVEMENT', label: 'Bereavement' },
  OTHER: { value: 'OTHER', label: 'Other' },
  FAMILY_AND_LEAVE_OTHER: { value: 'FAMILY_AND_LEAVE_OTHER', label: 'Other' },
  HOSPITAL: { value: 'HOSPITAL', label: 'Hospital' },
  ACCIDENT: { value: 'ACCIDENT', label: 'Accident' },
  CRITICAL_ILLNESS: { value: 'CRITICAL_ILLNESS', label: 'Critical Illness' },
  CUSTOM_VOLUNTARY_BENEFIT: {
    value: 'CUSTOM_VOLUNTARY_BENEFIT',
    label: 'Other Voluntary Benefit',
  },
  MEDICAL: { value: 'MEDICAL', label: 'Medical' },
  DENTAL: { value: 'DENTAL', label: 'Dental' },
  VISION: { value: 'VISION', label: 'Vision' },
  STD: {
    label: 'Short Term Disability',
    value: 'STD',
  },
  LTD: {
    label: 'Long Term Disability',
    value: 'LTD',
  },
  BASIC_LIFE: {
    label: 'Basic Life',
    value: 'BASIC_LIFE',
  },
  BASIC_ADD: {
    label: 'Basic Add',
    value: 'BASIC_ADD',
  },
  BASIC_LIFE_AND_ADD: {
    label: 'Basic Life And ADD',
    value: 'BASIC_LIFE_AND_ADD',
  },
};
