import axios from 'axios';

import { baseApi } from 'utils/apiUtil';
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  http,
} from 'utils/httpUtil';
import WebLinkType from 'model/WebLinkType';
import { modifyAndEncodeBenefitClassNamesToHandleComma } from 'utils/commonUtil';
const planUrl = baseApi + '/v1/plans';
const benguideApiV2 = `${baseApi}/v2/benefit-guides`;

const textExtractorAPI = `${baseApi}/v1/text-extractor`;

const CancelToken = axios.CancelToken;

export const getMedicalPlanList = async (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return http.get(`${planUrl}/medicals`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-classes':
        modifyAndEncodeBenefitClassNamesToHandleComma(benefitClasses).join(','),
    },
  });
};

export const editWeblinks = (
  id: string,
  benefitKind: string,
  existingDocumentPlanName: string,
  webLink: WebLinkType
) => {
  return axios.put(
    `${planUrl}/${id}/weblink/edit?benefitKind=${benefitKind}&documentPlanName=${encodeURIComponent(
      webLink.planDocumentName
    )}&existingDocumentPlanName=${encodeURIComponent(
      existingDocumentPlanName
    )}&weblink=${encodeURIComponent(webLink.weblink)}`
  );
};

export const updateWeblinks = (
  id: string,
  benefitKind: string,
  webLinks: WebLinkType[]
) => {
  const webLinksMap = webLinks.reduce((acc, item) => {
    acc[item.planDocumentName] = item.weblink;
    return acc;
  }, {} as { [key: string]: string });
  // temporary fix for wellbeing generic, need to be changed align with backend
  benefitKind = benefitKind === 'WELLBEING' ? 'WELLBEING_GENERIC' : benefitKind;
  return http.put(`${planUrl}/${id}/weblink/upload`, {
    webLinks: webLinksMap,
    benefitKind: benefitKind,
  });
};

export const editPlanDocuments = async (
  planId: string,
  benefitKind: string,
  planDocumentName: string,
  existingPlanDocumentName: string
) => {
  // temporary fix for wellbeing generic, need to be changed align with backend
  benefitKind = benefitKind === 'WELLBEING' ? 'WELLBEING_GENERIC' : benefitKind;
  return http.put(
    `${planUrl}/${planId}/documents/upload?benefitKind=${benefitKind}&planDocumentName=${encodeURIComponent(
      planDocumentName
    )}&existingPlanDocumentName=${encodeURIComponent(existingPlanDocumentName)}`
  );
};

export const uploadPlanDocument = async (
  planId: string,
  benefitKind: string,
  documentType: string,
  file: File,
  fileName: string,
  planDocumentName?: string
) => {
  const formData = new FormData();
  formData.append('file', file, fileName);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
    withCredentials: true,
  };
  // temporary fix for wellbeing generic, need to be changed align with backend
  benefitKind = benefitKind === 'WELLBEING' ? 'WELLBEING_GENERIC' : benefitKind;
  return axios.post(
    `${planUrl}/${planId}/documents/upload?benefitKind=${benefitKind}&documentType=${encodeURIComponent(
      documentType
    )}&planDocumentName=${encodeURIComponent(planDocumentName as string)}`,
    formData,
    config
  );
};

export const retrievePlanDocument = async (
  planId: string,
  benefitKind: string,
  documentType: string
) => {
  return http.put(
    `${planUrl}/${planId}/documents?benefitKind=${benefitKind}&documentType=${documentType}`
  );
};

let cancelToken: any = undefined;
export const startTextractJob = (file: FormData) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = CancelToken.source();
  return axios.post(`${textExtractorAPI}/start`, file, {
    cancelToken: cancelToken.token,
  });
};

export const planRecommenderToggle = async (
  benguideId: string,
  section: string,
  data: any
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/section/${section}/enabled`,
    data
  );
};
