import { FC } from 'react';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import ContributionField from 'modules/taxAdvantaged/components/ContributionField/ContributionField';
import EmployerContributionSection from 'modules/taxAdvantaged/components/EmployerContributionSection/EmployerContributionSection';

import styles from './healthCareFsaPlanInfo.module.less';

type Props = {
  plan: TaxAdvantageAccountsPlan;
};

const HealthCareFsaPlanInfo: FC<Props> = (props: Props) => {
  const { plan } = props;

  return (
    <div className={styles.container}>
      {plan.employerContribution && (
        <EmployerContributionSection
          className={styles.employerContributions}
          employerContribution={plan.employerContribution}
        />
      )}
      <div className={styles.subContainer}>
        {plan.maxYearlyContribution !== null &&
          plan.maxYearlyContribution !== undefined && (
            <ContributionField
              label="Annual Max Contribution"
              value={plan.maxYearlyContribution}
            />
          )}
        {plan.endOfYearPolicy && (
          <ContributionField
            label="End of Year Policy"
            valueText={plan.endOfYearPolicy}
            valueEndOfYearPolicyField={true}
          />
        )}
      </div>
    </div>
  );
};

export default HealthCareFsaPlanInfo;
