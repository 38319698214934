export const MEDICAL_INDIVIDUAL_DEDUCTIBLE_IN =
  'MEDICAL_INDIVIDUAL_DEDUCTIBLE_IN';
export const MEDICAL_INDIVIDUAL_DEDUCTIBLE_OUT =
  'MEDICAL_INDIVIDUAL_DEDUCTIBLE_OUT';
export const MEDICAL_FAMILY_DEDUCTIBLE_IN = 'MEDICAL_FAMILY_DEDUCTIBLE_IN';
export const MEDICAL_FAMILY_DEDUCTIBLE_OUT = 'MEDICAL_FAMILY_DEDUCTIBLE_OUT';
export const MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_IN =
  'MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_IN';
export const MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_OUT =
  'MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_OUT';
export const MEDICAL_INDIVIDUAL_OOP_MAX_IN = 'MEDICAL_INDIVIDUAL_OOP_MAX_IN';
export const MEDICAL_INDIVIDUAL_OOP_MAX_OUT = 'MEDICAL_INDIVIDUAL_OOP_MAX_OUT';
export const MEDICAL_FAMILY_OOP_MAX_IN = 'MEDICAL_FAMILY_OOP_MAX_IN';
export const MEDICAL_FAMILY_OOP_MAX_OUT = 'MEDICAL_FAMILY_OOP_MAX_OUT';
export const MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_IN =
  'MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_IN';
export const MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_OUT =
  'MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_OUT';

export const MEDICAL_DEDUCTIBLES_OOP_MAX_PARAMETERS = [
  MEDICAL_INDIVIDUAL_DEDUCTIBLE_IN,
  MEDICAL_INDIVIDUAL_DEDUCTIBLE_OUT,
  MEDICAL_FAMILY_DEDUCTIBLE_IN,
  MEDICAL_FAMILY_DEDUCTIBLE_OUT,
  MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_IN,
  MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_OUT,
  MEDICAL_INDIVIDUAL_OOP_MAX_IN,
  MEDICAL_INDIVIDUAL_OOP_MAX_OUT,
  MEDICAL_FAMILY_OOP_MAX_IN,
  MEDICAL_FAMILY_OOP_MAX_OUT,
  MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_IN,
  MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_OUT,
];

export const MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_IN =
  'MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_IN';
export const MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_OUT =
  'MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_OUT';
export const MEDICAL_RX_FAMILY_DEDUCTIBLE_IN =
  'MEDICAL_RX_FAMILY_DEDUCTIBLE_IN';
export const MEDICAL_RX_FAMILY_DEDUCTIBLE_OUT =
  'MEDICAL_RX_FAMILY_DEDUCTIBLE_OUT';
export const MEDICAL_RX_INDIVIDUAL_OOP_MAX_IN =
  'MEDICAL_RX_INDIVIDUAL_OOP_MAX_IN';
export const MEDICAL_RX_INDIVIDUAL_OOP_MAX_OUT =
  'MEDICAL_RX_INDIVIDUAL_OOP_MAX_OUT';
export const MEDICAL_RX_FAMILY_OOP_MAX_IN = 'MEDICAL_RX_FAMILY_OOP_MAX_IN';
export const MEDICAL_RX_FAMILY_OOP_MAX_OUT = 'MEDICAL_RX_FAMILY_OOP_MAX_OUT';

export const MEDICAL_RX_TIER1_RETAIL_IN = 'MEDICAL_RX_TIER1_RETAIL_IN';
export const MEDICAL_RX_TIER1_RETAIL_OUT = 'MEDICAL_RX_TIER1_RETAIL_OUT';
export const MEDICAL_RX_TIER2_RETAIL_IN = 'MEDICAL_RX_TIER2_RETAIL_IN';
export const MEDICAL_RX_TIER2_RETAIL_OUT = 'MEDICAL_RX_TIER2_RETAIL_OUT';
export const MEDICAL_RX_TIER3_RETAIL_IN = 'MEDICAL_RX_TIER3_RETAIL_IN';
export const MEDICAL_RX_TIER3_RETAIL_OUT = 'MEDICAL_RX_TIER3_RETAIL_OUT';
export const MEDICAL_RX_TIER4_RETAIL_IN = 'MEDICAL_RX_TIER4_RETAIL_IN';
export const MEDICAL_RX_TIER4_RETAIL_OUT = 'MEDICAL_RX_TIER4_RETAIL_OUT';
export const MEDICAL_RX_TIER5_RETAIL_IN = 'MEDICAL_RX_TIER5_RETAIL_IN';
export const MEDICAL_RX_TIER5_RETAIL_OUT = 'MEDICAL_RX_TIER5_RETAIL_OUT';
export const MEDICAL_RX_TIER6_RETAIL_IN = 'MEDICAL_RX_TIER6_RETAIL_IN';
export const MEDICAL_RX_TIER6_RETAIL_OUT = 'MEDICAL_RX_TIER6_RETAIL_OUT';
export const MEDICAL_RX_TIER7_RETAIL_IN = 'MEDICAL_RX_TIER7_RETAIL_IN';
export const MEDICAL_RX_TIER7_RETAIL_OUT = 'MEDICAL_RX_TIER7_RETAIL_OUT';
export const MEDICAL_RX_TIER8_RETAIL_IN = 'MEDICAL_RX_TIER8_RETAIL_IN';
export const MEDICAL_RX_TIER8_RETAIL_OUT = 'MEDICAL_RX_TIER8_RETAIL_OUT';

export const MEDICAL_RX_TIER1_MAIL_ORDER_IN = 'MEDICAL_RX_TIER1_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER1_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER1_MAIL_ORDER_OUT';
export const MEDICAL_RX_TIER2_MAIL_ORDER_IN = 'MEDICAL_RX_TIER2_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER2_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER2_MAIL_ORDER_OUT';
export const MEDICAL_RX_TIER3_MAIL_ORDER_IN = 'MEDICAL_RX_TIER3_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER3_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER3_MAIL_ORDER_OUT';
export const MEDICAL_RX_TIER4_MAIL_ORDER_IN = 'MEDICAL_RX_TIER4_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER4_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER4_MAIL_ORDER_OUT';
export const MEDICAL_RX_TIER5_MAIL_ORDER_IN = 'MEDICAL_RX_TIER5_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER5_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER5_MAIL_ORDER_OUT';
export const MEDICAL_RX_TIER6_MAIL_ORDER_IN = 'MEDICAL_RX_TIER6_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER6_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER6_MAIL_ORDER_OUT';
export const MEDICAL_RX_TIER7_MAIL_ORDER_IN = 'MEDICAL_RX_TIER7_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER7_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER7_MAIL_ORDER_OUT';
export const MEDICAL_RX_TIER8_MAIL_ORDER_IN = 'MEDICAL_RX_TIER8_MAIL_ORDER_IN';
export const MEDICAL_RX_TIER8_MAIL_ORDER_OUT =
  'MEDICAL_RX_TIER8_MAIL_ORDER_OUT';

export const MEDICAL_RX_DEDUCTIBLES_OOP_MAX_PARAMETERS = [
  MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_IN,
  MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_OUT,
  MEDICAL_RX_FAMILY_DEDUCTIBLE_IN,
  MEDICAL_RX_FAMILY_DEDUCTIBLE_OUT,
  MEDICAL_RX_INDIVIDUAL_OOP_MAX_IN,
  MEDICAL_RX_INDIVIDUAL_OOP_MAX_OUT,
  MEDICAL_RX_FAMILY_OOP_MAX_IN,
  MEDICAL_RX_FAMILY_OOP_MAX_OUT,
  MEDICAL_RX_TIER1_RETAIL_IN,
  MEDICAL_RX_TIER1_RETAIL_OUT,
  MEDICAL_RX_TIER2_RETAIL_IN,
  MEDICAL_RX_TIER2_RETAIL_OUT,
  MEDICAL_RX_TIER3_RETAIL_IN,
  MEDICAL_RX_TIER3_RETAIL_OUT,
  MEDICAL_RX_TIER4_RETAIL_IN,
  MEDICAL_RX_TIER4_RETAIL_OUT,
  MEDICAL_RX_TIER5_RETAIL_IN,
  MEDICAL_RX_TIER5_RETAIL_OUT,
  MEDICAL_RX_TIER6_RETAIL_IN,
  MEDICAL_RX_TIER6_RETAIL_OUT,
  MEDICAL_RX_TIER7_RETAIL_IN,
  MEDICAL_RX_TIER7_RETAIL_OUT,
  MEDICAL_RX_TIER8_RETAIL_IN,
  MEDICAL_RX_TIER8_RETAIL_OUT,
  MEDICAL_RX_TIER1_MAIL_ORDER_IN,
  MEDICAL_RX_TIER1_MAIL_ORDER_OUT,
  MEDICAL_RX_TIER2_MAIL_ORDER_IN,
  MEDICAL_RX_TIER2_MAIL_ORDER_OUT,
  MEDICAL_RX_TIER3_MAIL_ORDER_IN,
  MEDICAL_RX_TIER3_MAIL_ORDER_OUT,
  MEDICAL_RX_TIER4_MAIL_ORDER_IN,
  MEDICAL_RX_TIER4_MAIL_ORDER_OUT,
  MEDICAL_RX_TIER5_MAIL_ORDER_IN,
  MEDICAL_RX_TIER5_MAIL_ORDER_OUT,
  MEDICAL_RX_TIER6_MAIL_ORDER_IN,
  MEDICAL_RX_TIER6_MAIL_ORDER_OUT,
  MEDICAL_RX_TIER7_MAIL_ORDER_IN,
  MEDICAL_RX_TIER7_MAIL_ORDER_OUT,
  MEDICAL_RX_TIER8_MAIL_ORDER_IN,
  MEDICAL_RX_TIER8_MAIL_ORDER_OUT,
];

export const DENTAL_INDIVIDUAL_DEDUCTIBLE_IN =
  'DENTAL_INDIVIDUAL_DEDUCTIBLE_IN';
export const DENTAL_INDIVIDUAL_DEDUCTIBLE_OUT =
  'DENTAL_INDIVIDUAL_DEDUCTIBLE_OUT';
export const DENTAL_FAMILY_DEDUCTIBLE_IN = 'DENTAL_FAMILY_DEDUCTIBLE_IN';
export const DENTAL_FAMILY_DEDUCTIBLE_OUT = 'DENTAL_FAMILY_DEDUCTIBLE_OUT';
export const DENTAL_PLAN_YEAR_MAX_IN = 'DENTAL_PLAN_YEAR_MAX_IN';
export const DENTAL_PLAN_YEAR_MAX_OUT = 'DENTAL_PLAN_YEAR_MAX_OUT';
export const DENTAL_ORTHODONTIA_MAX_IN = 'DENTAL_ORTHODONTIA_MAX_IN';
export const DENTAL_ORTHODONTIA_MAX_OUT = 'DENTAL_ORTHODONTIA_MAX_OUT';

export const DENTAL_DEDUCTIBLES_MAX_PARAMETERS = [
  DENTAL_INDIVIDUAL_DEDUCTIBLE_IN,
  DENTAL_INDIVIDUAL_DEDUCTIBLE_OUT,
  DENTAL_FAMILY_DEDUCTIBLE_IN,
  DENTAL_FAMILY_DEDUCTIBLE_OUT,
  DENTAL_PLAN_YEAR_MAX_IN,
  DENTAL_PLAN_YEAR_MAX_OUT,
  DENTAL_ORTHODONTIA_MAX_IN,
  DENTAL_ORTHODONTIA_MAX_OUT,
];
