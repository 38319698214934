import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/set';

import * as TelehealthPlanService from 'modules/benefitsWellness/services/TelehealthPlanService';
import * as BenguideService from 'modules/benefitsWellness/services/BenGuideService';
import * as PlanService from 'modules/auth/services/PlanService';
import * as RichMediaService from 'services/BenGuideService';

import Section from 'model/Section';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import {
  setCloseModalsInUP,
  updateBenguideLatestRevision,
} from 'modules/auth/slices/benguideSlice';
import { SectionName } from 'modules/home/constants';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import { benefitCategory } from 'modules/auth/constants/commonConstants';

const initialState = {
  planList: {
    inProgress: false,
    error: null,
    data: [],
  },
  telehealthSection: {
    inProgress: false,
    enable: false,
    plans: [],
    error: null,
  },
  richMedia: {},
  sectionImage: {
    inProgress: false,
    image: '',
    error: null,
  },
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.TELEHEALTH]: '',
      [SectionName.RX_DELIVERY]: '',
      [SectionName.RX_COUPONS]: '',
    },
  },
};

const telehealthSlice = createSlice({
  name: 'telehealth',
  initialState,
  reducers: {
    planListFetchingStarted: (state) => {
      state.planList.inProgress = true;
    },
    planListFetchingCompleted: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = null;
      state.planList.data = payload;
    },
    planListFetchingFailed: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = payload;
    },
    telehealthSectionUpdateStart: (state) => {
      state.telehealthSection.inProgress = true;
    },
    telehealthSectionUpdateCompleted: (state, { payload }) => {
      state.telehealthSection.inProgress = false;
      state.telehealthSection.enable = payload.enable;
      state.telehealthSection.plans = payload.plans;
      state.telehealthSection.error = null;
    },
    telehealthSectionUpdateFailed: (state, { payload }) => {
      state.telehealthSection.inProgress = false;
      state.telehealthSection.error = payload;
    },
    richMediaFetchingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingCompleted: (state, { payload }) => {
      const { planId, content } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, content);
      set(state, `richMedia.${planId}.error`, null);
    },
    richMediaUpdatingFailed: (state, { payload }) => {
      const { planId, error } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, '');
      set(state, `richMedia.${planId}.error`, error);
    },
    uploadSectionImageStart(state) {
      state.sectionImage.image = '';
      state.sectionImage.error = null;
      state.sectionImage.inProgress = true;
    },
    uploadSectionImageSuccess(state, { payload }) {
      state.sectionImage.error = null;
      state.sectionImage.image = payload;
      state.sectionImage.inProgress = false;
    },
    uploadSectionImageFailed(state, { payload }) {
      state.sectionImage.error = payload;
      state.sectionImage.image = '';
      state.sectionImage.inProgress = false;
    },
    deleteSectionImageStart(state) {
      state.sectionImage.inProgress = true;
      state.sectionImage.error = null;
    },
    deleteSectionImageSuccess(state) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = null;
    },
    deleteSectionImagaFailed(state, { payload }) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = payload;
    },
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      (state.defaultRichMedia.data as any)[payload.section] =
        payload.media.content;
    },
    getDefaultMediaFailed(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
  },
});

export const {
  planListFetchingStarted,
  planListFetchingCompleted,
  planListFetchingFailed,
  telehealthSectionUpdateStart,
  telehealthSectionUpdateCompleted,
  telehealthSectionUpdateFailed,
  richMediaFetchingStarted,
  richMediaUpdatingCompleted,
  richMediaUpdatingFailed,
  richMediaUpdatingStarted,
  deleteSectionImagaFailed,
  deleteSectionImageStart,
  deleteSectionImageSuccess,
  uploadSectionImageFailed,
  uploadSectionImageStart,
  uploadSectionImageSuccess,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
} = telehealthSlice.actions;

export const fetchTelehealthPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planListFetchingStarted());
    TelehealthPlanService.getTelehealthPlanList(
      employerId,
      planYearId,
      benefitClasses
    )
      .then(({ data }: AxiosResponse) => {
        const { content } = data;
        dispatch(planListFetchingCompleted(content));
      })
      .catch((error) => {
        dispatch(planListFetchingFailed(error));
      });
  };
};

export const updateTelehealthSection: any = (section: Section) => {
  return async (dispatch: Dispatch) => {
    const planPromisses = section.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return PlanService.getPlans('telehealth', planId || id, revision);
    });
    const plans = (await Promise.all(planPromisses)).map(
      (plan: any) => plan.data as Section
    );
    dispatch(telehealthSectionUpdateCompleted({ ...section, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const addMultiplePlans = (
  benguideId: string,
  request: Array<AddPlanApiRequestPayload>
) => {
  return async (dispatch: Dispatch) => {
    dispatch(telehealthSectionUpdateStart());
    RichMediaService.addMultiplePlans(benguideId, { newPlans: request })
      .then(({ data }) => {
        dispatch(reloadTelehealthSection(benguideId, data.latestRevision));
      })
      .catch((error) => {
        dispatch(telehealthSectionUpdateFailed(error));
      });
  };
};

export const reloadTelehealthSection: any = (
  benguideId: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await BenguideService.getTelehalthSection(
      benguideId,
      revision
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(updateTelehealthSection(sectionResponse.benefitPage));
  };
};

export const getRichMediaContent = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaFetchingStarted(planId));
    RichMediaService.getRichMediaContent(benguideId, benefitKind, planId)
      .then(({ data }) => {
        const { content } = data;
        dispatch(
          richMediaUpdatingCompleted({ planId: planId, content: content })
        );
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId: planId, error: error }));
      });
  };
};

export const updateRichMediaContent = (
  benguideId: string,
  planId: string,
  content: string,
  benefitKind: string,
  callBack: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaUpdatingStarted(planId));
    RichMediaService.updateRichMediaContent(
      benguideId,
      benefitKind,
      planId,
      content
    )
      .then(() => {
        callBack && callBack();
        dispatch(
          richMediaUpdatingCompleted({ planId: planId, content: content })
        );
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId: planId, error: error }));
      });
  };
};

export const handleSectionImageUpload =
  (benguideId: string, image: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadSectionImageStart());
    try {
      const imageResponse = await fetch(image);
      const imageBlob = await imageResponse.blob();
      const response = await RichMediaService.uploadSectionImage(
        benguideId,
        imageBlob,
        sectionName
      );
      dispatch(updateBenguideLatestRevision(response.data.latestRevision));
      dispatch(uploadSectionImageSuccess(response.data));
    } catch (error) {
      dispatch(uploadSectionImageFailed(error));
    }
  };

export const handleSectionImageDelete =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(deleteSectionImageStart());
    try {
      await RichMediaService.deleteSectionImage(benguideId, sectionName);
      dispatch(deleteSectionImageSuccess());
    } catch (error) {
      dispatch(deleteSectionImagaFailed(error));
    }
  };

export const getDefaultText = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    let sectionName = SectionName.TELEHEALTH;
    if (benefitKind === benefitCategory.RX_DELIVERY.value) {
      sectionName = SectionName.RX_DELIVERY;
    } else if (benefitKind === benefitCategory.RX_COUPONS.value) {
      sectionName = SectionName.RX_COUPONS;
    }
    RichMediaService.getRichMediaContentForPlan(
      benguideId,
      sectionName,
      planId,
      true
    )
      .then(({ data }) => {
        dispatch(
          getDefaultMediaSuccess({
            section: sectionName,
            media: data,
          })
        );
      })
      .catch((error) => {
        dispatch(getDefaultMediaFailed(error));
      });
  };
};

export default telehealthSlice.reducer;
