import {
  ADDITIONAL_CONTENT_OPTIONS,
  BENEFIT_TYPES,
} from 'modules/home/constants';

type BenefitGuideBasicInfo = {
  id?: string;
  masterId?: string;
  name: string;
  planYearId: string;
  benefitClasses: string[];
  employerId: string;
  homePage?: boolean;
  newHireCheckList: boolean;
  datesAndEligibility: boolean;
  howInsuranceWorks: boolean;
  medical: boolean;
  dental: boolean;
  vision: boolean;
  telehealth: boolean;
  lifeAndDisability: boolean;
  volBenefits: boolean;
  wellbeing: boolean;
  hra: boolean;
  fsa: boolean;
  hsa: boolean;
  commuter: boolean;
  fourOOneK: boolean;
  holidaysAndTimeOff: boolean;
  familyAndLeave: boolean;
  additionalPerks: boolean;
  resources: boolean;
};

export default BenefitGuideBasicInfo;

export const buildBenefitGuideBasicInfo = (
  name: string,
  planYearId: string,
  benefitClasses: string[],
  employerId: string,
  benefitTypes: string[],
  content: string[]
) => {
  return {
    name: name && name.trim(),
    planYearId,
    benefitClasses,
    employerId,
    newHireCheckList: content.includes(
      ADDITIONAL_CONTENT_OPTIONS.newHireCheckList.value
    ),
    howInsuranceWorks: content.includes(
      ADDITIONAL_CONTENT_OPTIONS.howInsuranceWorks.value
    ),
    datesAndEligibility: content.includes(
      ADDITIONAL_CONTENT_OPTIONS.datesAndEligibility.value
    ),
    resources: content.includes(ADDITIONAL_CONTENT_OPTIONS.resources.value),
    medical: benefitTypes.includes(BENEFIT_TYPES.medical.value),
    dental: benefitTypes.includes(BENEFIT_TYPES.dental.value),
    homePage: true,
    vision: benefitTypes.includes(BENEFIT_TYPES.vision.value),
    telehealth: benefitTypes.includes(BENEFIT_TYPES.telehealth.value),
    lifeAndDisability: benefitTypes.includes(
      BENEFIT_TYPES.lifeAndDisability.value
    ),
    volBenefits: benefitTypes.includes(BENEFIT_TYPES.volBenefits.value),
    wellbeing: benefitTypes.includes(BENEFIT_TYPES.wellbeing.value),
    hra: benefitTypes.includes(BENEFIT_TYPES.hra.value),
    fsa: benefitTypes.includes(BENEFIT_TYPES.fsa.value),

    hsa: benefitTypes.includes(BENEFIT_TYPES.hsa.value),
    commuter: benefitTypes.includes(BENEFIT_TYPES.commuter.value),
    fourOOneK: benefitTypes.includes(BENEFIT_TYPES.fourOOneK.value),
    holidaysAndTimeOff: benefitTypes.includes(
      BENEFIT_TYPES.holidaysAndTimeOff.value
    ),
    familyAndLeave: benefitTypes.includes(BENEFIT_TYPES.familyAndLeave.value),
    additionalPerks: benefitTypes.includes(BENEFIT_TYPES.additionalPerks.value),
  } as BenefitGuideBasicInfo;
};
