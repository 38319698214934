import { FC } from 'react';

import styles from './addYourText.module.less';

const AddYourText: FC = () => {
  return (
    <div className={styles.linkHotspot}>
      <strong>+</strong>Add Your Text
    </div>
  );
};

export default AddYourText;
