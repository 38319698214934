import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutState {
  isTablet: boolean;
  isMobile: boolean;
}

const initialState: LayoutState = {
  isTablet: false,
  isMobile: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    updateIsTablet(state, action: PayloadAction<boolean>) {
      state.isTablet = action.payload;
    },
    updateIsMobile(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },
  },
});

export const { updateIsMobile, updateIsTablet } = layoutSlice.actions;

export default layoutSlice.reducer;
