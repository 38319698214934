import { FC } from 'react';
import iconRightArrow from 'images/icons/icon-right-arrow-blue.svg';

import styles from './consultantTypeSelect.module.less';

type ConsultantTypeSelectProps = {
  header: string;
  description: string;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

const ConsultantTypeSelect: FC<ConsultantTypeSelectProps> = (
  props: ConsultantTypeSelectProps
) => {
  const { header, description, onSelect } = props;

  return (
    <div className={styles.consultantTypeSelectWrapper}>
      <h3>{header}</h3>
      <p className={styles.consultantDescription}>{description}</p>
      <div className={styles.getStartedLink} onClick={onSelect}>
        <label className={styles.labelText}>Book Consultation</label>
        <img
          src={iconRightArrow}
          alt="move-right-icon"
          className={styles.getStartedArrow}
        />
      </div>
    </div>
  );
};

export default ConsultantTypeSelect;
