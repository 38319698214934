import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanRecommenderHeader from 'modules/planRecommender/components/PlanRecommenderHeader/PlanRecommenderHeader';
import { previousStep } from 'modules/planRecommender/slices/planRecommenderSlice';

import styles from './planRecommenderLayout.module.less';

type PlanRecommenderLayoutProps = {
  children?: any;
};

const PlanRecommenderLayout: FC<PlanRecommenderLayoutProps> = (
  props: PlanRecommenderLayoutProps
) => {
  const { children } = props;
  const { stepCount, currentStep } = useAppSelector(
    (state) => state.planRecommender
  );
  const dispatch = useAppDispatch();
  return (
    <div className={styles.planRecommenderLayout}>
      <div className={styles.content}>
        <PlanRecommenderHeader
          onBackPress={() => {
            dispatch(previousStep());
          }}
          currentStep={currentStep}
          stepCount={stepCount}
        />
        {children}
      </div>
    </div>
  );
};

export default PlanRecommenderLayout;
