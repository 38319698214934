import { Fragment } from 'react';
import { Row } from 'antd';
import { isNull } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AuthModal from 'modules/auth/components/AuthModal/AuthModal';
import { AUTH_VIEW, PLAN_VIEW } from 'modules/auth/constants/authConstants';
import {
  setEmployeeModal,
  setAuthView,
} from 'modules/auth/slices/employeeAuthSlice';
import Login from 'modules/auth/components/EmployeeLogin/EmployeeLogin';
import Register from 'modules/auth/components/Register/Register';
import RequestResetPassword from 'modules/auth/components/RequestResetPassword/RequestResetPassword';
import FallBack from 'modules/auth/components/FallBack/FallBack';
import SendEmail from 'modules/auth/components/SendEmail/SendEmail';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ErAdmin from 'modules/auth/components/ErAdmin/ErAdmin';
import OnBoarding from 'modules/auth/components/IDCardView/OnBoarding/Onboarding';
import SSORegister from 'modules/auth/components/SsoLogin/SSORegister';
import MDVPlanSelection from 'modules/auth/components/IDCardView/MDVVplan/MDVPlanSelection';
import IDcardview from 'modules/auth/components/IDCardView/IDcardview';
import ResetPassword from 'modules/auth/components/ResetPassword/ResetPassword';
import Terms from 'modules/auth/components/Terms/Terms';

import styles from './employeeView.module.less';

const EmployeeViewSwitch = () => {
  const dispatch = useAppDispatch();
  const isEmployeeLoggedIn = useAppSelector((state) => state.idCard.isLoggedIn);
  const electionData = useAppSelector((state) => state.idCard.electionData);
  const authView = useAppSelector((state) => state.employeeAuth.view);
  const onboardView = useAppSelector((state) => state.idCard.view);
  const showEmployeeModal = useAppSelector(
    (state) => state.employeeAuth.showEmployeeModal
  );

  const handleCloseAuthModal = () => {
    dispatch(setEmployeeModal(false));
  };

  const getAuthForm = (type: string) => {
    switch (type) {
      case AUTH_VIEW.LOGIN.type:
        return <Login />;
      case AUTH_VIEW.REQUEST_RESET.type:
        return <RequestResetPassword />;
      case AUTH_VIEW.PWD_RESET.type:
        return <ResetPassword />;
      case AUTH_VIEW.FALL_BACK.type:
        return <FallBack />;
      case AUTH_VIEW.ER_ADMIN.type:
        return <ErAdmin />;
      case AUTH_VIEW.SSO_REGISTER.type:
        return <SSORegister />;
      case AUTH_VIEW.VERIFY.type:
        return <SendEmail />;
      case AUTH_VIEW.TERMS.type:
        return <Terms />;
      default:
        return <Register />;
    }
  };

  const getOnboardingView = (type: string) => {
    switch (type) {
      case PLAN_VIEW.ON_BOARDING.type:
        return <OnBoarding />;
      case PLAN_VIEW.CARD.type:
        return <IDcardview />;
      case PLAN_VIEW.TERMS.type:
        return <Terms />;
      default:
        return <MDVPlanSelection />;
    }
  };

  const fallBackFooter = () => {
    if (authView === AUTH_VIEW.LOGIN.type)
      return (
        <Row justify="center" className={styles.fallBackContainer}>
          <LinkButton
            className={styles.linkBtn}
            onClick={() => dispatch(setAuthView(AUTH_VIEW.FALL_BACK.type))}
          >
            <b>Trouble Logging In?</b>
          </LinkButton>
        </Row>
      );
  };

  const hasCodeHeader = () => {
    return (
      <Row justify="end">
        <LinkButton onClick={() => dispatch(setAuthView(AUTH_VIEW.CODE.type))}>
          <b>Have Code?</b>
        </LinkButton>
      </Row>
    );
  };

  const renderAuthModal = () => {
    if (isEmployeeLoggedIn && !isNull(electionData)) {
      return (
        <AuthModal
          centered={true}
          visible={showEmployeeModal}
          footer={<></>}
          handleClose={handleCloseAuthModal}
        >
          {getOnboardingView(onboardView)}
        </AuthModal>
      );
    } else {
      return (
        <AuthModal
          centered={true}
          title={authView === AUTH_VIEW.EMAIL.type && hasCodeHeader()}
          visible={showEmployeeModal}
          handleClose={handleCloseAuthModal}
          footer={<>{fallBackFooter()}</>}
        >
          {getAuthForm(authView)}
        </AuthModal>
      );
    }
  };

  return <Fragment>{renderAuthModal()}</Fragment>;
};

export default EmployeeViewSwitch;
