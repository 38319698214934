import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { routes } from 'routes';
import { getPathWithHash } from 'utils/routeUtils';
import RouteMap from 'routes/RouteMap';
import PageTitle from 'routes/PageTitle';

export const useGAPageViews = () => {
  const location = useLocation();

  const pathToTitle = useCallback((path: string) => {
    return path
      .split('/')
      .filter(Boolean)
      .map((segment: any) => {
        return segment
          .replace(/:[^\s/]+/g, '')
          .replace(/-/g, ' ')
          .replace(/\b\w/g, (l: string) => l.toUpperCase());
      })
      .join(' - ');
  }, []);

  const getPageTitle = useCallback(
    (path: string) => {
      const splitPath = path.split('/');
      const hash = splitPath[1] || '';
      const employerName = splitPath[2] || '';
      const route = routes.find((route) => {
        return path.match(
          new RegExp(
            `^${getPathWithHash(hash, route.path, employerName).replace(
              /:[^\s/]+/g,
              '([\\w-]+)'
            )}$`
          )
        );
      });

      if (route && route.label) {
        return route.label;
      }
      // If title is not explicitly set, you might infer from path or element
      if (route && route.path) {
        return pathToTitle(route.path);
      }
      if (path.includes(RouteMap.PLAN_RECOMMENDER_MEDICAL)) {
        return PageTitle.PLAN_RECOMMENDER_MEDICAL;
      }
      return path;
    },
    [pathToTitle]
  );

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location?.pathname,
      title: getPageTitle(location?.pathname),
    });
  }, [location, getPageTitle]);
};
