import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, isNull } from 'lodash';
import { Dispatch } from 'redux';
import * as EmpAuthService from 'modules/auth/services/EmployeeAuthService';
import * as PlanService from 'modules/auth/services/PlanService';
import * as IndividualService from 'services/IndividualService';
import { AuthErrorCodes } from 'modules/auth/constants/errorCodes';
import { clearURLParams, handleErrorCode } from 'modules/auth/util/error';
import {
  AUTH_VIEW,
  LoginType,
  PLAN_VIEW,
} from 'modules/auth/constants/authConstants';
import { AlertInfo } from 'components/FixedAlert/FixedAlertMessage';
import { setElectionData, setEmployee, setOnboardingView } from './idCardSlice';

interface AuthState {
  inProgress: boolean;
  showEmployeeModal: boolean;
  view: string;
  errorAlert: AlertInfo;
  showErrorAlert: boolean;
  user: string | null | undefined;
  token: string | null;
  isSelfDeclared?: boolean;
  isSSOUser?: boolean;
  isErAdmin?: boolean;
  dependentDetails: [];
  registerValues?: any;
  individualId?: string;
  whoami?: any;
  isRosterAndPersonal?: boolean;
}

const initialState = {
  inProgress: false,
  showErrorAlert: false,
  showEmployeeModal: false,
  view: AUTH_VIEW.LOGIN.type,
  errorAlert: { type: undefined, message: '' },
  user: null,
  token: null,
  isSelfDeclared: false,
  isSSOUser: false,
  isErAdmin: false,
  dependentDetails: [],
  registerValues: {},
  individualId: '',
  whoami: {},
  isRosterAndPersonal: false,
} as AuthState;

const employeeAuthSlice = createSlice({
  name: 'employeeAuth',
  initialState,
  reducers: {
    setEmployeeModal: (state, { payload }) => {
      if (payload === false) {
        state.showErrorAlert = false;
        state.view = AUTH_VIEW.LOGIN.type;
        state.user = null;
        state.showErrorAlert = false;
        state.isSelfDeclared = false;
        state.token = null;
        state.isErAdmin = false;
        state.user = null;
      }
      state.showEmployeeModal = payload;
    },
    setAuthView: (state, { payload }) => {
      state.view = payload;
      state.showErrorAlert = false;
    },
    setInProgress: (state, { payload }) => {
      if (payload) {
        state.showErrorAlert = false;
      }
      state.inProgress = payload;
    },
    setAuthAlert: (state, { payload }) => {
      state.showErrorAlert = true;
      state.errorAlert = payload;
    },
    resetAuthAlert: (state) => {
      state.errorAlert = { type: undefined, message: '' };
      state.showErrorAlert = false;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setShowAlert: (state, { payload }) => {
      state.showErrorAlert = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setSelfDecalredUser: (state, { payload }) => {
      state.isSelfDeclared = payload;
    },
    setSSOUserUser: (state, { payload }) => {
      state.isSSOUser = payload;
    },
    setIsErAdmin: (state, { payload }) => {
      state.isErAdmin = payload;
    },
    setDependentDetails: (state, { payload }) => {
      state.dependentDetails = payload;
    },
    resetAuthState: (state) => {
      Object.assign(state, initialState);
    },
    setRegisterValues: (state, { payload }) => {
      state.registerValues = payload;
    },
    setIndividualId: (state, { payload }) => {
      state.individualId = payload;
    },
    setWhoAmI: (state, { payload }) => {
      state.whoami = payload;
    },
    setRosterAndPersonal: (state, { payload }) => {
      state.isRosterAndPersonal = payload;
    },
  },
});

export const {
  setEmployeeModal,
  setAuthView,
  setToken,
  setInProgress,
  setAuthAlert,
  resetAuthAlert,
  setUser,
  setSelfDecalredUser,
  setIsErAdmin,
  setSSOUserUser,
  setDependentDetails,
  setShowAlert,
  resetAuthState,
  setRegisterValues,
  setIndividualId,
  setWhoAmI,
  setRosterAndPersonal,
} = employeeAuthSlice.actions;
export default employeeAuthSlice.reducer;

export const requestSignUp =
  (email: string, redirectUrl: string, domain?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setInProgress(true));
    try {
      const response = await EmpAuthService.requestSignUp(
        email,
        redirectUrl,
        domain
      );
      if (response.status === 201) {
        dispatch(setAuthView(AUTH_VIEW.VERIFY.type));
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };

export const requestPasswordReset =
  (email: string, redirectUrl: string) => async (dispatch: Dispatch) => {
    dispatch(setInProgress(true));
    try {
      const response = await EmpAuthService.requestPasswordReset(
        email,
        redirectUrl
      );
      if (response.status === 201) {
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.PWDRESET_INFO.type,
            message: AuthErrorCodes.PWDRESET_INFO.message,
          })
        );
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };

export const passwordReset =
  (token: string, newPassword: string) => async (dispatch: Dispatch) => {
    dispatch(setInProgress(true));
    try {
      const response = await EmpAuthService.passwordResetToken(
        token,
        newPassword
      );
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
      clearURLParams();
    }
  };

export const verifyEmail = (email: string) => async (dispatch: Dispatch) => {
  dispatch(setInProgress(true));
  try {
    const response = await EmpAuthService.verifyEmail(email);
    if (response.status === 200) {
      if (response?.data.personalEmail) {
        dispatch(setAuthView(AUTH_VIEW.PERSONAL.type));
      }
    }
    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data;
    handleErrorCode(dispatch, errorResponse);
  } finally {
    dispatch(setInProgress(false));
  }
};

export const signUp =
  (
    email: string,
    token: string,
    password: string,
    firstName?: string,
    lastName?: string,
    isSelfDeclared?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setInProgress(true));
    try {
      const response = await EmpAuthService.register(
        email,
        token,
        password,
        firstName,
        lastName,
        isSelfDeclared
      );

      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
      clearURLParams();
    }
  };

export const ssoSignUp =
  (email: string, token: string, firstName: string, lastName: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setInProgress(true));
    try {
      const response = await EmpAuthService.ssoRegister(
        email,
        token,
        firstName,
        lastName
      );
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
      dispatch(setSelfDecalredUser(false));
      clearURLParams();
    }
  };

export const validateToken = (token: string) => async (dispatch: Dispatch) => {
  dispatch(setInProgress(true));
  dispatch(setEmployeeModal(true));
  try {
    const response = await EmpAuthService.validateTokenAndGetUserRole(token);

    if (response.status == 200) {
      const isSelfDeclared = response.data?.isSelfDeclared ?? false;
      const isSSOEnabled = response.data?.isSsoEnabledSelfDeclaredUser ?? false;
      const isErAdmin = response.data?.isErAdmin ?? false;

      if (isSSOEnabled) {
        dispatch(setAuthView(AUTH_VIEW.SSO_REGISTER.type));
      } else if (!isSelfDeclared) {
        dispatch(setAuthView(AUTH_VIEW.ROSTER.type));
      } else if (isErAdmin) {
        dispatch(setAuthView(AUTH_VIEW.ER_ADMIN.type));
      } else {
        dispatch(setAuthView(AUTH_VIEW.REGISTER.type));
      }
      dispatch(setSelfDecalredUser(isSelfDeclared));
      dispatch(setIsErAdmin(isErAdmin));
      dispatch(setSSOUserUser(isSSOEnabled));
      dispatch(setUser(response?.data?.email));
      dispatch(setToken(token));
    }
    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data;
    handleErrorCode(dispatch, errorResponse);
  } finally {
    dispatch(setInProgress(false));
  }
};

export const validateResetPwsToken =
  (token: string) => async (dispatch: Dispatch) => {
    dispatch(setInProgress(true));
    dispatch(setEmployeeModal(true));
    try {
      const response = await EmpAuthService.validatePasswordResetToken(token);

      if (response.status == 200) {
        dispatch(setAuthView(AUTH_VIEW.PWD_RESET.type));
        dispatch(setUser(response?.data?.email));
        dispatch(setToken(token));
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;

      if (errorResponse?.code === AuthErrorCodes.TOKEN_EXPIRED.code) {
        errorResponse.code = AuthErrorCodes.TOKEN_EXPIRED_RESET.code;
      }
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };

export function login(
  email: string,
  password: string,
  benguideUrl: string,
  planYearId: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));

      const authResponse = await EmpAuthService.getAuthFlow(email, benguideUrl);

      if (authResponse?.status !== 200) {
        dispatch(
          setAuthAlert({
            type: 'error',
            message: `It looks like your Employer may not have authorized access for PlanYear mobile app.
            Please contact us for more information`,
          })
        );
        return authResponse;
      }

      if (authResponse?.data?.type === LoginType.PWD) {
        const response = await EmpAuthService.pwdAuthLogin(email, password);
        if (response.status === 200) {
          const individualId = response?.data?.individualId;
          const employeeId = response?.data?.employeeId;
          const hasElectionResponse = await PlanService.hasElections(
            individualId,
            planYearId
          );

          if (hasElectionResponse) {
            dispatch(setEmployee(response?.data));
            dispatch(setElectionData(hasElectionResponse?.data));

            if (hasElectionResponse.data?.hasPlans) {
              dispatch(setOnboardingView(PLAN_VIEW.CARD.type));

              if (
                response?.data?.isDep === false &&
                !isEmpty(response?.data?.dependentIds)
              ) {
                const dependentsRes = await EmpAuthService.getDependentsDetails(
                  employeeId,
                  planYearId
                );
                dispatch(setDependentDetails(dependentsRes?.data));
              }
            } else {
              dispatch(setOnboardingView(PLAN_VIEW.ON_BOARDING.type));
            }
          }
        }
      }
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export function fetchEmployeeLogin(planYearId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      dispatch(setEmployeeModal(true));

      const whoamiRes = await EmpAuthService.whoamiInduvidual();

      if (whoamiRes.status === 200) {
        const individualId = whoamiRes?.data?.individualId;
        const employeeId = whoamiRes?.data?.primaryEmployeeId;
        const primaryIndividualId = whoamiRes?.data?.primaryIndividualId;

        if (employeeId) {
          const employeeDetailsRes = await EmpAuthService.getEmployeeDetails(
            employeeId
          );

          const hasElectionResponse = await PlanService.hasElections(
            individualId,
            planYearId
          );

          if (hasElectionResponse && employeeDetailsRes) {
            dispatch(
              setEmployee({
                ...employeeDetailsRes?.data,
                employeeId: employeeId,
                isDep: primaryIndividualId === individualId ? false : true,
              })
            );
            dispatch(setElectionData(hasElectionResponse?.data));
            if (hasElectionResponse.data?.hasPlans) {
              dispatch(setOnboardingView(PLAN_VIEW.CARD.type));
              if (
                employeeDetailsRes?.data?.isDep === false &&
                !isEmpty(employeeDetailsRes?.data?.dependentIds)
              ) {
                const dependentsRes = await EmpAuthService.getDependentsDetails(
                  employeeId,
                  planYearId
                );
                dispatch(setDependentDetails(dependentsRes?.data));
              }
            } else {
              dispatch(setOnboardingView(PLAN_VIEW.ON_BOARDING.type));
            }
          }
        } else {
          dispatch(
            setAuthAlert({
              type: AuthErrorCodes.EMPLOYEE_NOT_FOUND.type,
              message: AuthErrorCodes.EMPLOYEE_NOT_FOUND.message,
            })
          );
        }
      }
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export function authFlow(email: string, benguideUrl: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      const response = await EmpAuthService.getAuthFlow(email, benguideUrl);
      if (response.status === 200) {
        const redirectUri = response?.data?.redirectUri;
        if (response?.data?.type === LoginType.SSO && !isNull(redirectUri)) {
          window.location.href = redirectUri;
        }
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export function whoamI() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await IndividualService.whoamI();
      dispatch(setWhoAmI(response?.data));
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    }
  };
}

export function updateTermsForIndividual(individualId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await IndividualService.updateTermsForIndividual(
        individualId
      );
      dispatch(setOnboardingView(PLAN_VIEW.ON_BOARDING.type));
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    }
  };
}
