import { ReactNode } from 'react';
import { Collapse, CollapseProps } from 'antd';
import { ReactComponent as CollapseCaretDown } from 'images/icons/icon-expand.svg';

import styles from './styledCollapse.module.less';

type Props = CollapseProps & {
  children: ReactNode;
};

const StyledCollapse = (props: Props) => {
  const { children = '', className, ...rest } = props;
  return (
    <Collapse
      className={`${className} ${styles.container}`}
      expandIconPosition="right"
      expandIcon={(panelProps) => (
        <CollapseCaretDown
          className={`${styles.expandIcon} ${
            panelProps.isActive ? styles.active : ''
          }`}
        />
      )}
      {...rest}
    >
      {children}
    </Collapse>
  );
};

export default StyledCollapse;
