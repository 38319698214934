import { useState } from 'react';
import dropDownArrow from 'images/icons/drop-down-arrow.png';

import styles from './planRecommenderDropDown.module.less';

const PlanRecommenderDropDown = (props: any) => {
  const [isChangeDropdown, setChangeDropdown] = useState<boolean>(false);
  const {
    onNextClick,
    currentAnswer = '-',
    title,
    config = {},
    editMode,
    index,
    isStateValid,
    onUpdateAnswer,
    id,
  } = props;

  const { options = [] } = config;
  const changeState = (event: any) => {
    const { value } = event.target;
    onUpdateAnswer(id, value);
    setChangeDropdown(true);
  };

  return (
    <div className={`${styles.stateSelectorWrapper} stateSelectorWrapper`}>
      <div className={`${styles.title} title`}>{`${
        editMode ? index + 1 + '.' : ''
      } ${title}`}</div>
      <div className={`${styles.stateDropdown} stateDropdown`}>
        <img src={dropDownArrow} alt=""></img>
        <select onChange={changeState} defaultValue={currentAnswer}>
          <option value="-" disabled>
            —
          </option>
          {options.map((option: any, index: number) => {
            const { label, value } = option;
            return (
              <option key={index} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
      {!isStateValid && isChangeDropdown && (
        <div className={`${styles.stateErrorWrapper} stateErrorWrapper`}>
          <div className={`${styles.stateError} stateError`}>
            Sorry, your employer does not currently offer plans in your
            location. Please select a different location.
            <br />
          </div>
        </div>
      )}
      <div className={`${styles.nextButtonWrapper} nextButtonWrapper`}>
        <button
          className={`${styles.nextButton} nextButton`}
          onClick={onNextClick}
          disabled={currentAnswer === '-' || !isStateValid}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlanRecommenderDropDown;
