import { FC } from 'react';
import { Drawer } from 'rsuite';
import { PLAN_RECOMMENDER_QUESTIONS } from 'modules/planRecommender/pages/constants';
import iconBack from 'images/icons/icon-back.png';
import { getCurrentAnswerByQuestionType } from 'modules/planRecommender/pages/PlanRecommender/PlanRecommender';

import styles from './planRecommenderEditDrawer.module.less';

type PlanRecommenderEditDrawerProps = {
  children: any;
  isdrawerOpen: boolean;
  onClose: () => void;
  onFetchResult: Function;
  isValidState: boolean;
  answers: any;
  questions: any;
};

const PlanRecommenderEditDrawer: FC<PlanRecommenderEditDrawerProps> = (
  props: PlanRecommenderEditDrawerProps
) => {
  const {
    isdrawerOpen,
    onClose,
    children,
    onFetchResult,
    isValidState,
    answers,
    questions,
  } = props;

  const updatePlans = () => {
    onFetchResult();
    onClose();
  };

  const isEnableUpdateButton = () => {
    let isEnable = true;
    answers.forEach((answerObj: any) => {
      const { answer } = answerObj;
      if (Array.isArray(answer)) {
        isEnable = answer.length > 0 && isEnable;
      }
    });
    (
      getCurrentAnswerByQuestionType(
        questions,
        PLAN_RECOMMENDER_QUESTIONS.PRESCRIPTIONS
      ) || []
    ).forEach((answer: any) => {
      isEnable = answer !== '' && isEnable;
    });
    return isEnable;
  };

  return (
    <Drawer
      show={isdrawerOpen}
      onHide={onClose}
      size="md"
      className={styles.editDrawer}
    >
      <Drawer.Header>
        <div className={styles.editModeHeaderWrapper}>
          <div className={styles.editYourAnswers}>
            <img
              className={styles.backArrow}
              onClick={onClose}
              src={iconBack}
            />
            Edit Your Answers
          </div>
          <button
            className={styles.updateButton}
            onClick={updatePlans}
            disabled={!(isValidState && isEnableUpdateButton())}
          >
            Update
          </button>
          <div className={styles.splitter}>
            <hr />
          </div>
        </div>
      </Drawer.Header>
      <Drawer.Body>
        <div className={styles.editModeWrapper}>{children}</div>
      </Drawer.Body>
    </Drawer>
  );
};
export default PlanRecommenderEditDrawer;
