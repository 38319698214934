import { FC } from 'react';

import { Row } from 'antd';
import notebookIcon from 'images/icons/notebook.svg';

import styles from './noPlansView.module.less';

type NoPlansViewProps = {
  className?: string;
};

const NoPlansView: FC<NoPlansViewProps> = ({ className }: NoPlansViewProps) => {
  return (
    <div className={styles.containedDiv}>
      <div className={`${styles.noPlansWrapper} ${className}`}>
        <Row className={styles.detailWrapper}>
          <img src={notebookIcon} alt="edit" />
        </Row>
        <Row className={styles.header}>It’s quiet in here</Row>
        <Row className={styles.subHeader}>
          Please contact your system administrator to enable plans for this
          benefit
        </Row>
      </div>
    </div>
  );
};

export default NoPlansView;
