import { FC } from 'react';

import { Button } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';

import styles from './linkButton.module.less';

interface LinkButtonProps extends NativeButtonProps {
  className?: string;
  href?: string;
}

const LinkButton: FC<LinkButtonProps> = (props: LinkButtonProps) => {
  const { className, ...rest } = props;
  return (
    <div className={styles.linkBtnWrapper}>
      <Button
        type="link"
        className={`${styles.linkBtn} ${className}`}
        {...rest}
      />
    </div>
  );
};

export default LinkButton;
