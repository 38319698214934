import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import { ReactComponent as GlanceSVG } from 'images/employee/glance.svg';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import {
  resetAuthState,
  setWhoAmI,
  whoamI,
} from 'modules/auth/slices/employeeAuthSlice';
import {
  getIDCardData,
  getPublishedPlans,
  resetIDcardState,
  setOnboardingView,
} from 'modules/auth/slices/idCardSlice';
import { PLAN_VIEW } from 'modules/auth/constants/authConstants';

import styles from './onBoarding.module.less';

const OnBoarding = () => {
  const dispatch = useDispatch();
  const { currentEmployee, inProgress } = useAppSelector(
    (state) => state.idCard
  );
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const { hasElection, hasPlans, hasDBGPlans } =
    useAppSelector((state) => state.idCard.electionData) || {};
  const whoAmI = useAppSelector((state) => state.employeeAuth.whoami);

  useEffect(() => {
    dispatch(getPublishedPlans(currentEmployee?.employerId));

    if (!!hasElection && !hasPlans) {
      dispatch(
        getIDCardData(
          currentEmployee?.individualId,
          currentEmployee?.employerId,
          benguideData?.planYearId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmployee, hasElection]);

  useEffect(() => {
    dispatch(whoamI());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(whoAmI) && !whoAmI?.isTermsAccepted) {
      dispatch(setOnboardingView(PLAN_VIEW.TERMS.type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoAmI]);

  const handleNext = () => {
    if (hasDBGPlans) {
      dispatch(setOnboardingView(PLAN_VIEW.MEDICAL.type));
    } else {
      handleLogout();
    }
  };

  const handleLogout = () => {
    dispatch(resetAuthState());
    dispatch(resetIDcardState());
    dispatch(setWhoAmI({}));
  };

  return (
    <div className={styles.container}>
      <GlanceSVG className={styles.displayImage} />
      <SubmitButton
        type="primary"
        htmlType="button"
        loading={inProgress}
        onClick={() => handleNext()}
        className={styles.submitPwdButton}
      >
        {hasDBGPlans ? 'Get Started' : 'Log Out'}
      </SubmitButton>
    </div>
  );
};

export default OnBoarding;
