import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Input } from 'antd';
import {
  authenticate,
  setAuthShowErrorMessage,
} from 'modules/auth/slices/authSlice';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import InputForm from 'components/InputForm/InputForm';

import styles from './login.module.less';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash = '' } = useParams();
  const { auth } = useSelector((state: any) => state);

  const { benguideError = {}, showErrorMessage } = auth;
  const { authFailed } = benguideError || {};
  const { error } = auth;
  const [credentials, setCredentials] = useState({
    password: '',
  });

  useEffect(() => {
    const { inProgress } = auth;
    if (!(inProgress || authFailed)) {
      navigate('./..');
    }
  }, [authFailed, auth, navigate]);

  const loginHandler = () => {
    dispatch(
      authenticate(hash, {
        mode: 'PUBLISHED',
        password: credentials.password,
      })
    );
    dispatch(setAuthShowErrorMessage(true));
  };

  const textChangeHandler = (event: any) => {
    const { value, name } = event.target;
    setCredentials({ ...credentials, [name]: value });
    dispatch(setAuthShowErrorMessage(false));
  };

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginHeader}>Login</div>
      <div> Password :</div>
      <InputForm layout="vertical" onFinish={loginHandler}>
        <div>
          <Input
            className={`${styles.passwordField} ${
              showErrorMessage ? styles.invalid : ''
            }`}
            name="password"
            type="password"
            onChange={textChangeHandler}
          />
        </div>
        {showErrorMessage && (
          <div className={styles.invalidCredentials}>Invalid Credentials!</div>
        )}
        <SubmitButton
          onClick={loginHandler}
          disabled={isEmpty(credentials.password)}
        >
          Login
        </SubmitButton>
        {error && <div>Authentication Failed</div>}
      </InputForm>
    </div>
  );
};

export default Login;
