import { FC, useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { Button, Menu } from 'antd';
import PageHeader from 'components/PageHeader/PageHeader';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import PlanSelectButton from 'components/PlanSelectButton/PlanSelectButton';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import {
  OPEN_ADD_NEW_PLAN_MODAL,
  VOLUNTARY_BENEFITS_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import { ReactComponent as VoluntaryIcon } from 'images/icons/side-nav/voluntary.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import PlanReference from 'model/PlanReference';
import {
  fetchVolBenefitsPlanList,
  addPlans,
  getDefaultText,
  listAllVoluntaryPlans,
} from 'modules/benefitsWellness/slices/voluntaryBenefitsSlice';
import VoluntaryBenefitsPlan from 'model/VoluntaryBenefitsPlan';
import { swapElements } from 'modules/benefitsWellness/util';
import { formatDataId } from 'utils/commonUtil';
import { VOL_BENEFITS_KIND, BENEFIT_CATEGORY } from 'constants/commonConstants';
import VoluntaryBenefitsChannel from 'modules/clients/UPClient/channels/VoluntaryBenefitsChannel';
import VoluntaryBenefitsCard from 'modules/benefitsWellness/components/VoluntaryBenefitsCard/VoluntaryBenefitsCard';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import { NotificationContextProvider } from 'context/NotificationContext';

import styles from './voluntaryBenefits.module.less';

const VoluntaryBenefits: FC = () => {
  const upClient = useUPClient();
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const { planList: planListWrapper, volBenefitsSection } = useAppSelector(
    (state) => state.voluntaryBenefits
  );
  const [sortedVolPlans, setSortedPlans] = useState<VoluntaryBenefitsPlan[]>(
    []
  );
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  const { employerId, planYearId, masterId, isEditMode, benefitClasses } =
    benguide;
  const { data: planList = [], inProgress } = planListWrapper;
  const { plans: addedPlans = [] } = volBenefitsSection;
  const addedPlansLoading = useAppSelector(
    (state) => state.voluntaryBenefits.volBenefitsSection.inProgress
  );

  useEffect(() => {
    dispatch(fetchVolBenefitsPlanList(employerId, planYearId, benefitClasses));
  }, [dispatch, employerId, planYearId, benefitClasses]);

  useEffect(() => {
    if (addedPlans) {
      const uniquePlansList = uniqBy(addedPlans, 'benefitKind');
      if (uniquePlansList) {
        uniquePlansList.forEach((element: any) => {
          if (element.id && element.benefitKind) {
            dispatch(getDefaultText(masterId, element.id, element.benefitKind));
          }
        });
      }
      setSortedPlans(addedPlans);
    }
  }, [dispatch, addedPlans, masterId]);

  const listAllPlans = useCallback(() => {
    dispatch(listAllVoluntaryPlans(employerId, planYearId, benefitClasses));
  }, [benefitClasses, dispatch, employerId, planYearId]);

  const moveTop = (index: number) => {
    if (addedPlansLoading) return;
    if (index > 0) {
      const newPlans = swapElements(sortedVolPlans, index, index - 1);
      const updatedPlansWithOrder = newPlans.map((plan, i) => ({
        ...plan,
        orderNo: i + 1,
      }));
      setSortedPlans(updatedPlansWithOrder);
      updatePlans(updatedPlansWithOrder, false);
    }
  };

  const moveBottom = (index: number) => {
    if (addedPlansLoading) return;
    if (index < sortedVolPlans.length - 1) {
      const newPlans = swapElements(sortedVolPlans, index, index + 1);
      const updatedPlansWithOrder = newPlans.map((plan, i) => ({
        ...plan,
        orderNo: i + 1,
      }));
      setSortedPlans(updatedPlansWithOrder);
      updatePlans(updatedPlansWithOrder, false);
    }
  };

  const updatePlans = (plans: any[], orderByPlanNames: boolean) => {
    const planReferences = plans.map((plan: PlanReference) => {
      return {
        ...plan,
        planId: plan.id,
        planName: plan.name,
        carrier: {
          id: get(plan, 'carrier.id', null),
          name: get(plan, 'carrier.name', null),
        },
        benefitCarrier: {
          id: get(plan, 'benefitCarrier.id', null),
          name: get(plan, 'benefitCarrier.name', null),
        },
      };
    });

    const addedPlansPayload: Array<AddPlanApiRequestPayload> = [];
    VOL_BENEFITS_KIND.forEach((benefitKind: string) => {
      return addedPlansPayload.push({
        sectionName: benefitKind,
        plans: planReferences.filter(
          (planRef) =>
            planRef.benefitKind && planRef.benefitKind === benefitKind
        ),
        orderByPlanNames: orderByPlanNames,
      });
    });
    dispatch(addPlans(masterId, addedPlansPayload));
  };

  const getPlanTypes = () => {
    return (
      <Menu>
        {[
          benefitCategory.ACCIDENT,
          benefitCategory.CRITICAL_ILLNESS,
          benefitCategory.HOSPITAL,
          benefitCategory.CUSTOM_VOLUNTARY_BENEFIT,
        ].map((item, key) => {
          return (
            <Menu.Item
              key={key}
              className={styles.menuItemHeader}
              data-id={formatDataId(`${item.label}-optn`)}
            >
              <EditableHotspot
                alwaysVisible={false}
                useChannel={{
                  client: upClient,
                  channel: VOLUNTARY_BENEFITS_CHANNEL,
                  event: OPEN_ADD_NEW_PLAN_MODAL,
                  data: { item },
                }}
              >
                {item.label}
              </EditableHotspot>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.volBenefitsWrapper}>
        <VoluntaryBenefitsChannel />
        <BenefitGuideRefreshChannel
          benefitKind={BENEFIT_CATEGORY.VOLUNTARY_BENEFIT.value}
        />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle="Voluntary Benefits "
          pageHeaderIcon={<VoluntaryIcon className={styles.volIcon} />}
        />

        {isEditMode && !isEmpty(addedPlans) && (
          <div className={styles.addOrRemovePlansWrapper}>
            <EditableHotspot
              alwaysVisible={true}
              customModal={(visible: boolean, onClose: Function) => (
                <AddOrRemovePlans
                  onClose={onClose}
                  visible={visible}
                  isLoading={inProgress}
                  title="Voluntary Benefits"
                  onSave={updatePlans}
                  addedPlanList={addedPlans}
                  allPlans={planList || []}
                  reloadPlans={listAllPlans}
                  channel={VOLUNTARY_BENEFITS_CHANNEL}
                  actionContent={
                    <PlanSelectButton
                      overlay={getPlanTypes()}
                      selectLabel="Create New Plan"
                    />
                  }
                  showAlert={showAlert}
                />
              )}
            >
              <Button>+ Add or Remove Plans</Button>
            </EditableHotspot>
          </div>
        )}

        {isEmpty(addedPlans) && isEditMode && (
          <AddOrRemovePlans
            visible={true}
            withoutModal={true}
            isLoading={inProgress}
            title="Voluntary Benefits"
            onSave={updatePlans}
            addedPlanList={addedPlans}
            allPlans={planList || []}
            reloadPlans={listAllPlans}
            channel={VOLUNTARY_BENEFITS_CHANNEL}
            actionContent={
              <PlanSelectButton
                overlay={getPlanTypes()}
                selectLabel="Create New Plan"
                className={isEmpty(planList) ? styles.darkButton : ''}
              />
            }
            showAlert={showAlert}
          />
        )}

        <div
          className={
            isEmpty(sortedVolPlans)
              ? styles.noPlans
              : styles.volBenefitsContainer
          }
        >
          {sortedVolPlans.map((plan, index) => (
            <VoluntaryBenefitsCard
              key={index}
              dataIndex={index}
              plan={plan}
              moveTop={() => moveTop(index)}
              moveBottom={() => moveBottom(index)}
              hideTop={index === 0}
              hideBottom={index === sortedVolPlans?.length - 1}
              renderReorder={sortedVolPlans?.length > 1}
            />
          ))}
          {isEmpty(sortedVolPlans) && !isEditMode && (
            <NoPlansView className={styles.noPlanView} />
          )}
        </div>
      </div>
    </NotificationContextProvider>
  );
};

export default VoluntaryBenefits;
