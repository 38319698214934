import { FC } from 'react';
import { Form, FormProps, Spin } from 'antd';

import styles from './inputForm.module.less';

interface Props extends FormProps {
  disabledMode?: boolean;
  isLoading?: boolean;
}

const InputForm: FC<Props> = (props: Props) => {
  const { disabledMode = false, children, isLoading, ...rest } = props;

  return (
    <Form
      className={`${styles.wrapper} ${disabledMode ? styles.disabledMode : ''}`}
      {...rest}
    >
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Spin size="large" />
        </div>
      )}
      {children}
    </Form>
  );
};

export default InputForm;
