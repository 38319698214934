import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { ReactComponent as HamburgerMenuIcon } from 'images/icons/side-nav/hamburger-menu.svg';

import styles from './hamburgerMenuCloseBtn.module.less';

type Props = ButtonProps & {
  closed: boolean;
};

const HamburgerMenuCloseBtn = ({ closed, ...rest }: Props) => {
  return (
    <Button type="text" {...rest}>
      <HamburgerMenuIcon
        className={closed ? styles.closeIcon : styles.hamburgerMenu}
      />
    </Button>
  );
};

export default HamburgerMenuCloseBtn;
