import {
  Dispatch,
  PayloadAction,
  ThunkAction,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { PLAN_VIEW } from 'modules/auth/constants/authConstants';
import * as PlanService from 'modules/auth/services/PlanService';
import { handleErrorCode } from 'modules/auth/util/error';

interface IDCardState {
  /**
   * Keeps track of the currently selected dependent or subscriber's individual id.
   */
  currentMemberId: string;
  isLoggedIn: boolean;
  currentEmployee: any | null;
  view: string;
  electionData: any | null;
  publishedPlans: any | null;
  inProgress: boolean;
  /**
   * An array that keeps track of the employee's election data
   */
  electionMDVIdCardData: any | null;
  /**
   * Keeps track of the dependents id cards data as individualId-electionArray pairs
   */
  dependentMDVIdCardData?: Record<string, any[]>;
  employerData: any | null;
  currentPlanYear: any;
}

const initialState = {
  isLoggedIn: false,
  currentEmployee: null,
  view: PLAN_VIEW.ON_BOARDING.type,
  publishedPlans: null,
  electionData: null,
  electionMDVIdCardData: [],
  employerData: null,
  currentPlanYear: {},
} as IDCardState;

const idCardSlice = createSlice({
  name: 'idCard',
  initialState,
  reducers: {
    setEmployee: (state, { payload }) => {
      state.currentEmployee = payload;
      state.isLoggedIn = true;
    },
    clearEmployee: (state) => {
      state.currentEmployee = null;
      state.isLoggedIn = false;
    },
    setOnboardingView: (state, { payload }) => {
      state.view = payload;
    },
    setInProgress: (state, { payload }) => {
      state.inProgress = payload;
    },
    setPublishedPlans: (state, { payload }) => {
      state.publishedPlans = payload;
    },
    setElectionData: (state, { payload }) => {
      state.electionData = payload;
    },
    setIDCardsData: (state, { payload }) => {
      state.electionMDVIdCardData = payload;
    },
    setDependentIdCardsData: (
      state,
      { payload }: PayloadAction<IDCardState['dependentMDVIdCardData']>
    ) => {
      state.dependentMDVIdCardData = payload;
    },
    setCurrentMemberId: (
      state,
      { payload }: PayloadAction<IDCardState['currentMemberId']>
    ) => {
      state.currentMemberId = payload;
    },
    setEmployerData: (state, { payload }) => {
      state.employerData = payload;
    },
    setPlanYearData: (state, { payload }) => {
      state.currentPlanYear = payload;
    },
    resetIDcardState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setEmployee,
  clearEmployee,
  setOnboardingView,
  setPublishedPlans,
  setInProgress,
  setElectionData,
  setIDCardsData,
  setEmployerData,
  setPlanYearData,
  setDependentIdCardsData,
  setCurrentMemberId,
  resetIDcardState,
} = idCardSlice.actions;

export default idCardSlice.reducer;

export function getPublishedPlans(employeeId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      const response = await PlanService.publishedPlansRequest(employeeId);
      if (response?.status === 200) {
        dispatch(setPublishedPlans(response?.data));
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      console.log(errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export function getIDCardData(
  individualId: string,
  employerId: string,
  planYearId: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      const response = await PlanService.getElectionsData(
        individualId,
        planYearId
      );

      const planYearRes = await PlanService.getCurrentPlanYearData(
        employerId,
        planYearId
      );

      if (response?.status === 200) {
        dispatch(setIDCardsData(response?.data));
      }
      if (planYearRes.status === 200) {
        dispatch(setPlanYearData(planYearRes?.data));
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export const getDependentIdCardData = (): ThunkAction<
  void,
  RootState,
  unknown,
  any
> => {
  return async (dispatch, getState) => {
    try {
      const {
        benguide: {
          data: { planYearId },
        },
        employeeAuth: { dependentDetails },
      } = getState();

      dispatch(setInProgress(true));
      const allResponses = await Promise.all(
        dependentDetails?.map(
          async (d: any) => await PlanService.getElectionsData(d.id, planYearId)
        )
      );
      const mappedData: IDCardState['dependentMDVIdCardData'] = {};
      allResponses.forEach(({ data }) => {
        const individualId: string = data?.[0]?.individualId;
        mappedData[individualId] = data;
      });
      dispatch(setDependentIdCardsData(mappedData));
      return allResponses;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      handleErrorCode(dispatch, errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
};

export function getEmployerData(employerId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      const response = await PlanService.getEmployerDetails(employerId);
      if (response?.status === 200) {
        dispatch(setEmployerData(response?.data));
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      console.log(errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export function hasElections(individualId: string, planYearId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      const response = await PlanService.hasElections(individualId, planYearId);
      if (response?.status === 200) {
        dispatch(setElectionData(response?.data));
        if (response?.data?.hasElection) {
          dispatch(setOnboardingView(PLAN_VIEW.CARD.type));
        } else {
          dispatch(setOnboardingView(PLAN_VIEW.ON_BOARDING.type));
        }
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      console.log(errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export function saveElections(
  individualId: string,
  employerId: string,
  planYearId: string,
  plans: any
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      const response = await PlanService.saveElections(
        individualId,
        employerId,
        planYearId,
        plans
      );
      if (response?.status === 200) {
        dispatch(setPublishedPlans(response?.data));
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      console.log(errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}

export function updateMemberId(
  individualId: string,
  planYearId: string,
  benefitKind: string,
  memberId: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInProgress(true));
      const response = await PlanService.updateMemberId(
        individualId,
        planYearId,
        benefitKind,
        memberId
      );
      if (response?.status === 204) {
        const response = await PlanService.getElectionsData(
          individualId,
          planYearId
        );
        if (response?.status === 200) {
          dispatch(setIDCardsData(response?.data));
        }
      }
      return response;
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      console.log(errorResponse);
    } finally {
      dispatch(setInProgress(false));
    }
  };
}
