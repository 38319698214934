import { RefObject } from 'react';
import { Col, Divider, Row } from 'antd';
import { isNull } from 'lodash';
import { useAppSelector } from 'hooks/redux';
import { CoverageDetails, IndividualName } from 'modules/auth/types/types';
import { formatEffectiveDates } from 'utils/commonUtil';
import { PLAN_VIEW } from 'modules/auth/constants/authConstants';
import OverflowPopover from 'components/PlanYearOverFlowPopover/OverflowPopover';

import styles from './idCardPrint.module.less';

type IDDataType = {
  id: string;
  benefitKind: string;
  planId: string;
  planName: string;
  individualId: string;
  subscriberId: string;
  employerId: string;
  individualName: IndividualName;
  subscriberName: IndividualName;
  dob: string | null;
  coverageDetails: CoverageDetails;
  tier: string | null;
  planYearId: string;
};

type IDType = {
  isFlipped: boolean;
  data: IDDataType;
  employerData: any;
  IdRef: RefObject<any>;
  view: string;
};

const IDCardPrint = ({ data, employerData, IdRef, view }: IDType) => {
  const currentPlanYear = useAppSelector(
    (state) => state.idCard.currentPlanYear
  );
  const getPlanYearDates = formatEffectiveDates(
    currentPlanYear?.startDate,
    currentPlanYear?.endDate
  );
  return (
    <div className={styles.wrapperContainer} ref={IdRef}>
      <Row justify="start">
        <h1 className={styles.heading}>
          {`${data?.individualName?.firstName} ${data?.individualName?.lastName}`}{' '}
          :
        </h1>
      </Row>
      <Row>
        <Col span={4}>
          <h2 className={styles.subHeading}>
            {PLAN_VIEW.MEDICAL.type === view
              ? PLAN_VIEW.MEDICAL.value
              : PLAN_VIEW.DENTAL.type === view
              ? PLAN_VIEW.DENTAL.value
              : PLAN_VIEW.VISION.value}
          </h2>
        </Col>
        <Col>
          <div className={styles.flipCardFront}>
            <div className={styles.cardTopContainer}>
              <Row justify="space-between">
                <Col>
                  {data?.coverageDetails?.logoUrl && (
                    <img
                      src={data?.coverageDetails?.logoUrl}
                      className={styles.employerLogo}
                      alt="employer"
                    />
                  )}
                </Col>
                <Col>
                  {employerData?.logoUrl && (
                    <img
                      src={employerData?.logoUrl}
                      className={styles.employerLogo}
                      alt="client"
                    />
                  )}
                </Col>
              </Row>
              <h1 className={styles.heading}>
                <OverflowPopover maxWidth="490px">
                  {data?.planName}
                </OverflowPopover>
              </h1>

              <p className={styles.subHeading}>
                <b> Effective Date : {`${getPlanYearDates?.startDate}`} </b>
              </p>
            </div>
            <div className={styles.cardBottomContainer}>
              <Row gutter={24}>
                <Col span={12} className={styles.labelText}>
                  <b>Name</b>
                </Col>
                <Col span={12}>
                  <div className={styles.printableData}>
                    {data?.individualName?.firstName &&
                    data?.individualName?.lastName ? (
                      <>
                        {`${data?.individualName?.firstName} ${data?.individualName?.lastName}`}
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} className={styles.labelText}>
                  <b>Primary Subscriber</b>
                </Col>
                <Col span={12}>
                  <div className={styles.printableData}>
                    {data?.subscriberName?.firstName &&
                    data?.subscriberName?.lastName ? (
                      <>
                        {`${data?.subscriberName?.firstName} ${data?.subscriberName?.lastName}`}
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
              </Row>
              <Divider className={styles.containerDivider} />
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={4} className={styles.labelText}>
                      <b>ID #</b>
                    </Col>
                    <Col span={9}>
                      <div className={styles.editcontainer}>
                        <div className={styles.printableData}>
                          {data?.coverageDetails?.memberIdNumber ? (
                            <>
                              {`${
                                view === PLAN_VIEW.MEDICAL.type &&
                                !isNull(data?.coverageDetails?.alphaPrefix)
                                  ? data?.coverageDetails?.alphaPrefix
                                  : ''
                              }${data?.coverageDetails?.memberIdNumber}`}{' '}
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    </Col>
                    {view === PLAN_VIEW.MEDICAL.type && (
                      <>
                        <Col span={4} className={styles.labelText}>
                          <b>Rx Bin #</b>
                        </Col>
                        <Col span={7}>
                          {data?.coverageDetails?.rxBINumber ? (
                            <div className={styles.printableData}>
                              {data?.coverageDetails?.rxBINumber}
                            </div>
                          ) : (
                            '-'
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={4} className={styles.labelText}>
                      <b>Group ID #</b>
                    </Col>
                    <Col span={9}>
                      {data?.coverageDetails?.groupIdNumber ? (
                        <div className={styles.printableData}>
                          {data?.coverageDetails?.groupIdNumber}
                        </div>
                      ) : (
                        '-'
                      )}
                    </Col>
                    {view === PLAN_VIEW.MEDICAL.type && (
                      <>
                        <Col span={4} className={styles.labelText}>
                          <b>Rx Pcn #</b>
                        </Col>
                        <Col span={7}>
                          {data?.coverageDetails?.rxBINumber ? (
                            <div className={styles.printableData}>
                              {data?.coverageDetails?.rxBINumber}
                            </div>
                          ) : (
                            '-'
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={4} className={styles.labelText}>
                      <b>Phone</b>
                    </Col>
                    <Col span={9}>
                      {data?.coverageDetails?.carrierPhone ? (
                        <div className={styles.printableData}>
                          <a
                            className={styles.linkText}
                            href={`tel:${data?.coverageDetails?.carrierPhone}`}
                          >
                            <b> {data?.coverageDetails?.carrierPhone}</b>
                          </a>
                        </div>
                      ) : (
                        '-'
                      )}
                    </Col>
                    {view === PLAN_VIEW.MEDICAL.type && (
                      <>
                        <Col span={4} className={styles.labelText}>
                          <b>Rx Group #</b>
                        </Col>
                        <Col span={7}>
                          {data?.coverageDetails?.rxGroupNumber ? (
                            <div className={styles.printableData}>
                              {data?.coverageDetails?.rxGroupNumber}
                            </div>
                          ) : (
                            '-'
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.flipCardBack}>
            <div className={styles.cardTopContainer}>
              <Row justify="space-between">
                <Col>
                  {data?.coverageDetails?.logoUrl && (
                    <img
                      src={data?.coverageDetails?.logoUrl}
                      className={styles.employerLogo}
                      alt="employer"
                    />
                  )}
                </Col>
                <Col>
                  {employerData?.logoUrl && (
                    <img
                      src={employerData?.logoUrl}
                      className={styles.employerLogo}
                      alt="client"
                    />
                  )}
                </Col>
              </Row>
            </div>{' '}
            <div className={styles.cardBackBottomContainer}>
              <Row>
                <Col span={24}>
                  <h3 className={styles.backHeader}>MEMBERS AND PROVIDERS</h3>
                  <span className={styles.subHeading}>
                    Network providers must call to request prior approval and
                    confirm eligibility
                  </span>
                </Col>
              </Row>
              <Divider className={styles.containerDivider} />

              <Row gutter={32} justify="space-between">
                <Col span={12} className={styles.labelText}>
                  <Row justify="space-between">
                    <b>Carrier Phone #:</b>
                    {!data?.coverageDetails?.carrierPhone && '-'}
                  </Row>
                </Col>
                <Col>
                  {data?.coverageDetails?.carrierPhone && (
                    <a
                      className={styles.linkText}
                      href={`tel:${data?.coverageDetails?.carrierPhone}`}
                    >
                      <b> {data?.coverageDetails?.carrierPhone}</b>
                    </a>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default IDCardPrint;
