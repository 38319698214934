import { FC } from 'react';
import { Switch, SwitchProps } from 'antd';

import styles from './switchButton.module.less';

const SwitchButton: FC<SwitchProps> = (props: SwitchProps) => {
  const { ...rest } = props;
  return (
    <div className={styles.switchWrapper}>
      <Switch {...rest} />
    </div>
  );
};

export default SwitchButton;
