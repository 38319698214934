import {
  FC,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import isEmpty from 'lodash/isEmpty';
import { Spin } from 'antd';
import unorm from 'unorm';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { NotificationContext } from 'context/NotificationContext';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';

import { handleRichMediaUpload } from 'modules/home/slices/homeSlice';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/commonConstants';
import { staticBaseUrl } from 'utils/apiUtil';
import {
  convertFontsToRichMediaEditorFormat,
  fontObjects,
} from 'utils/commonUtil';

import styles from './richTextEditor.module.less';

type RichTextEditorProps = {
  limit?: number;
  showMediaUpload?: boolean;
  onChange?: Function;
  initialValue?: string;
  resetValue?: string;
  defaultText?: string;
  ref: React.Ref<any>;
  masterId?: string;
  disabled?: boolean;
  height?: number | string;
  activateDefaultValue?: boolean;
  maxHeight?: number;
  detectUserInput?: Function;
  revision?: number;
  editorKey?: string;
};

const RichTextEditor: FC<RichTextEditorProps> = forwardRef(
  (props: RichTextEditorProps, ref) => {
    const {
      limit,
      showMediaUpload = true,
      onChange,
      initialValue = '',
      defaultText = '',
      masterId = '',
      disabled = false,
      activateDefaultValue = true,
      height = 280,
      maxHeight = 280,
      detectUserInput,
      resetValue = '',
      editorKey,
    } = props;
    const [editorContent, setEditorContent] = useState<string>(initialValue);
    const [editorContentLength, setEditorContentLength] = useState<number>(0);
    const [initialEditorContent, setInitialEditorContent] =
      useState<string>(initialValue);
    const dispatch = useAppDispatch();
    const { uploadInProgress } = useAppSelector((state) => state.home);
    const editorRef = useRef(null);
    const [isNotificationReceived, setNotificationReceived] =
      useState<boolean>(false);
    const notificationFlag = useContext(NotificationContext);

    const setNotificationReceiveFlag = useCallback(() => {
      if (notificationFlag) {
        setNotificationReceived(true);
      }
    }, [notificationFlag]);

    useEffect(() => {
      if (activateDefaultValue) {
        setEditorContent(defaultText);
      }
      setNotificationReceiveFlag();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activateDefaultValue, setNotificationReceiveFlag]);

    useEffect(() => {
      setInitialEditorContent(resetValue);
    }, [resetValue]);

    const checkCharacterCount = (event: any) => {
      detectUserInput && detectUserInput();
      if (limit) {
        if (editorContentLength >= limit && event.key !== 'Backspace') {
          event.preventDefault();
        }
      }
      if ((editorRef.current as any)?.editor) {
        const content = (editorRef.current as any).editor.contentDocument.body
          .innerText;
        const strippedContent = stripeContent(content);
        if (strippedContent === '' && event.key === 'Backspace') {
          setEditorContentLength(0);
        } else if (strippedContent === '' && event.key === ' ') {
          setEditorContentLength(1);
        }
      }
    };

    const stripeContent = (content: string) => {
      let strippedContent = content.replace(/(<([^>]+)>)/gi, ''); // Removes all HTML tags
      strippedContent = strippedContent.replace(/\n/gi, ''); // Removes any newline characters
      strippedContent = strippedContent.replace(/&nbsp;/g, ' '); // Removes any non-breaking space characters
      strippedContent = strippedContent.replace(/&#39;/g, "'"); // replaces unformatted commas

      return strippedContent;
    };

    const handleEditorChange = (content: string) => {
      if (content === '') {
        setEditorContent(`<div style="font-size: 16px;"><p></p></div>`);
      } else {
        setEditorContent(content);
      }

      onChange?.(content, (editorRef.current as any).editor.isDirty());

      // Ensure the current content length is updated properly
      if ((editorRef.current as any)?.editor) {
        const editorBodyContent = (editorRef.current as any).editor
          .contentDocument.body.innerText;
        const strippedContent = stripeContent(editorBodyContent);

        // Update content length based on stripped content
        setEditorContentLength(
          strippedContent.trim() === '' ? 0 : strippedContent.length
        );
      }
    };

    const clearContent = useCallback(
      (value) => {
        if (value) {
          setEditorContent(value);
        } else {
          setInitialEditorContent(initialValue);
        }
      },
      [initialValue]
    );

    useImperativeHandle(
      ref,
      () => ({
        clearTextEditorContent(value: any) {
          clearContent(value);
        },
        getEditorRef() {
          return editorRef;
        },
        changeEditorContent(value: string) {
          setEditorContent(value);
        },
        resetUndo() {
          (editorRef.current as any).editor.undoManager.clear();
        },
        clearSelection() {
          (editorRef.current as any).editor.selection.collapse();
        },
      }),
      [clearContent]
    );

    let toolbarList =
      'wordcount undo redo bold italic underline strikethrough fontselect fontsizeselect formatselect alignleft aligncenter alignright alignjustify indent outdent numlist bullist customchecklist removeformat emoticons link fileUpload forecolor backcolor ';
    if (showMediaUpload) {
      toolbarList += ' imageInsertButton media';
    }

    return (
      <div className={styles.richTextEditorWrapper}>
        {uploadInProgress && (
          <div className={styles.richMediaUploadIndicatorWrapper}>
            <Spin className={styles.spinner} />
          </div>
        )}
        {isNotificationReceived && (
          <FixedAlertMessage
            type={'error'}
            message={ERROR_MESSAGE_PLAN_EDITING}
          />
        )}
        <Editor
          key={editorKey}
          onClick={checkCharacterCount}
          onKeyDown={checkCharacterCount}
          initialValue={initialEditorContent}
          value={editorContent}
          onEditorChange={handleEditorChange}
          ref={editorRef}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          disabled={disabled}
          onInit={(evt, editor) => {
            editor.setContent(initialValue);
          }}
          init={{
            font_formats: convertFontsToRichMediaEditorFormat(fontObjects),
            contextmenu: false,
            relative_urls: false,
            remove_script_host: false,
            height: maxHeight ? undefined : height,
            max_height: maxHeight,
            paste_webkit_styles:
              'font-weight color font-size font-family margin',
            menubar: false,
            statusbar: false,
            media_poster: false,
            branding: false,
            plugins:
              'paste lists link image lists advlist emoticons media hr autoresize table',
            default_link_target: '_blank',
            link_assume_external_targets: 'http',
            target_list: [{ title: 'New Window', value: '_blank' }],
            content_css: `${process.env.PUBLIC_URL}/tinymce/skins/content/default/custom.css`,
            noneditable_noneditable_class: 'fa',
            extended_valid_elements: 'span[*]',
            toolbar: toolbarList,
            toolbar_mode: 'wrap',
            fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
            block_formats:
              'Normal=p;Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6',
            paste_preprocess: function (plugin: any, args: any) {
              if (limit) {
                const content = (editorRef.current as any).editor
                  .contentDocument.body.innerText;
                const strippedContent = stripeContent(content);
                const pasteContent = stripeContent(args.content);
                const normalizedText = unorm.nfkd(pasteContent);
                const pasteLength = pasteContent.length;

                if (strippedContent.length + pasteLength > limit) {
                  args.content = '';
                } else {
                  args.content = normalizedText;
                }
              }
            },
            setup: (editor) => {
              editor.on('undo', function () {
                const content = (editorRef.current as any).editor
                  .contentDocument.body.innerText;
                if (isEmpty(content?.trim())) {
                  (
                    editorRef.current as any
                  ).editor.contentDocument.body.innerHTML = initialValue;
                }
              });
              editor.on('ExecCommand', (event) => {
                const command = event.command;
                if (command === 'mceMedia') {
                  const tabElemsParent: any = document.querySelector(
                    '.tox-dialog__body-nav'
                  );
                  const tabElems = document.querySelectorAll(
                    'div[role="tablist"] .tox-tab'
                  );
                  tabElems.forEach((tabElem: any) => {
                    if (
                      tabElem.innerText === 'General' ||
                      'Embed' ||
                      'Advanced'
                    ) {
                      tabElem.style.display = 'none';
                      if (tabElemsParent) {
                        tabElemsParent.style.padding = '0px';
                      }
                    }
                  });
                }
              });

              editor.ui.registry.addButton('fileUpload', {
                icon: 'browse',
                tooltip: 'Attach file',
                onAction: () => {
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('hidden', String(true));
                  input.setAttribute(
                    'accept',
                    '.xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .html, .csv'
                  );
                  input.onchange = (event: any) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    dispatch(
                      handleRichMediaUpload(
                        masterId,
                        file,
                        (objectURL: string) => {
                          editor.insertContent(
                            `&nbsp;<img src='${staticBaseUrl}/paperclip-solid.svg'  class=${styles.paperclip} style="margin-bottom:-1px; height:14px; width: 14px; vertical-align: revert;" 
                          alt="paperclip" />&nbsp;
                          <a href=${objectURL} download=${file.name}>${file.name}</a>&nbsp; `
                          );
                        }
                      )
                    );
                  };
                  input.click();
                },
              });

              if (showMediaUpload) {
                editor.ui.registry.addButton('imageInsertButton', {
                  icon: 'image',
                  tooltip: 'Attach image',
                  onAction: () => {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('hidden', String(true));
                    input.setAttribute('accept', 'image/*');
                    input.onchange = (event: any) => {
                      const file = event.target.files[0];
                      const reader = new FileReader();
                      if (/(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file.type)) {
                        dispatch(
                          handleRichMediaUpload(
                            masterId,
                            file,
                            (objectUrl: string) => {
                              editor.insertContent(
                                `&nbsp;<img src=${objectUrl} alt="${file.name}"/>&nbsp;`
                              );
                            }
                          )
                        );
                        reader.readAsDataURL(file);
                      }
                    };
                    input.click();
                  },
                });
              }

              // Function to update the <a> tag's font-family based on its parent <span>
              const updateLinkFontFamily = () => {
                editor
                  .getBody()
                  .querySelectorAll('a')
                  .forEach(function (link) {
                    const parent = link.closest('span');
                    if (parent) {
                      const parentFontFamily =
                        window.getComputedStyle(parent).fontFamily;
                      link.style.fontFamily = parentFontFamily;
                    }
                  });
              };

              // Trigger font-family update when content is loaded
              editor.on('init', function () {
                updateLinkFontFamily();
              });

              // Trigger font-family update on any change in the content
              editor.on('NodeChange keyup SetContent', function () {
                updateLinkFontFamily();
              });
            },
          }}
        />
        {limit && (
          <div
            className={styles.maxCountLabel}
          >{`Max Character Count: ${editorContentLength}/${limit}`}</div>
        )}
      </div>
    );
  }
);
RichTextEditor.displayName = 'RichTextEditor';
export default RichTextEditor;
