import { FC, useEffect, useState } from 'react';
import { matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import { Layout } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useMediaQuery } from 'react-responsive';

import HamburgerMenuCloseBtn from 'components/buttons/HamburgerMenuCloseBtn/HamburgerMenuCloseBtn';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import { MediaWidth } from 'constants/mediaConstants';
import { BENGUIDE_MODE_EDIT } from 'modules/auth/constants/globleConstants';
import { triggerHotspots } from 'modules/auth/slices/benguideSlice';
import { GLOBAL, SIDE_BAR } from 'components/EditableHotspot/EditableHotspot';
import { getCommonImageUrl } from 'services/BenGuideService';
import { ImageType } from 'modules/home/constants';
import { updateIsMobile, updateIsTablet } from 'layout/layoutSlice';

import DefaultLayout, { DefaultLayoutProps } from 'layout/DefaultLayout';
import RouteMap from 'routes/RouteMap';
import AppHeaderNav from './AppHeader/AppHeaderNav';

import styles from './layout.module.less';

const { Content } = Layout;

type AppLayoutProps = {
  layout?: React.ReactElement;
};

const AppLayout: FC<AppLayoutProps> = (props: AppLayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const mode = useAppSelector((state) => state.benguide.mode);
  const dispatch = useAppDispatch();
  const {
    isEditMode,
    masterId,
    employerLogo,
    defaultEmployerLogo,
    latestRevision,
    revision,
  } = useBenguide();
  const location = useLocation();
  const { hash = '' } = useParams();
  const employerName = useAppSelector(
    (state) => state.basicInfo.layoutBenefitGuide?.employerName
  );

  const isTablet = useMediaQuery({ maxDeviceWidth: MediaWidth.lg });
  const isMobile = useMediaQuery({ maxDeviceWidth: MediaWidth.sm });

  useEffect(() => {
    dispatch(updateIsTablet(isTablet));
    if (isTablet) {
      setCollapsed(true);
    }
  }, [isTablet, dispatch]);

  useEffect(() => {
    dispatch(updateIsMobile(isMobile));
  }, [isMobile, dispatch]);

  const isOnBasicInfoPage = (): boolean => {
    const basicInfoEditMatch = matchPath(
      location.pathname,
      `/${hash}/${RouteMap.BASIC_INFO_EDIT_PATH}`
    );
    const basicInfoEditMatchWithEmpName = matchPath(
      location.pathname,
      `/${hash}/${employerName}/${RouteMap.BASIC_INFO_EDIT_PATH}`
    );

    const createBenguideMatch = matchPath(
      location.pathname,
      RouteMap.CREATE_BENGUIDE
    );

    return Boolean(
      basicInfoEditMatch || createBenguideMatch || basicInfoEditMatchWithEmpName
    );
  };

  const isOnPlanRecommenderPage = (): boolean => {
    const planRecommenderMatch = matchPath(
      location.pathname,
      `/${hash}/${RouteMap.PLAN_RECOMMENDER_MEDICAL}`
    );
    const planRecommenderMatchWithEmpName = matchPath(
      location.pathname,
      `/${hash}/${employerName}/${RouteMap.PLAN_RECOMMENDER_MEDICAL}`
    );
    return Boolean(planRecommenderMatch || planRecommenderMatchWithEmpName);
  };

  const planRecommendeMedicalMatch = matchPath(
    location.pathname,
    `/${hash}/${RouteMap.PLAN_RECOMMENDER_MEDICAL}`
  );

  useEffect(() => {
    if (isEditMode) {
      const triggerFunction = (event: any) => {
        // because event.path removed from browser 2023 JAN new property is event.composedPath but not updated browsers still might be using event.path
        const path = event?.composedPath ? event?.composedPath() : event?.path;
        const isSideBarClicked = path?.some((element: any) =>
          element?.classList?.contains('ant-layout-sider')
        );
        const hasClick =
          event?.target.className === 'ant-checkbox-input' ||
          (typeof event?.target.className === 'string' &&
            event?.target.className.includes('prevent-trigger-hotspots')) ||
          (path || [])
            .filter((element: any) => element.tagName !== undefined)
            .some(
              (element: any) =>
                typeof element.onclick === 'function' ||
                element.tagName === 'INPUT'
            );

        if (!hasClick) {
          dispatch(triggerHotspots(isSideBarClicked ? SIDE_BAR : GLOBAL));
        }
      };
      document.addEventListener('click', triggerFunction, true);
      return () => {
        document.removeEventListener('click', triggerFunction, true);
      };
    }
  }, [dispatch, isEditMode]);

  const BaseLayout = (
    props.layout ? props.layout : DefaultLayout
  ) as FC<DefaultLayoutProps>;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!isTablet && mode !== BENGUIDE_MODE_EDIT && (
        <div className={styles.containerTopBar}></div>
      )}
      {isTablet && !isMobile && !collapsed && (
        <div
          className={styles.layoutMask}
          onClick={() => setCollapsed(true)}
        ></div>
      )}
      {mode === BENGUIDE_MODE_EDIT && <AppHeaderNav />}
      {isTablet && !planRecommendeMedicalMatch && (
        <Header className={styles.collapseHeader}>
          <img
            className={`${styles.headerLogo} ${
              isMobile && !collapsed ? styles.headerMobileCollapsed : ''
            }`}
            src={
              employerLogo
                ? getCommonImageUrl(
                    masterId,
                    ImageType.EMPLOYER_LOGO,
                    latestRevision || revision
                  )
                : defaultEmployerLogo
            }
            alt="logo placeholder"
          />
          <HamburgerMenuCloseBtn
            closed={isMobile && !collapsed}
            onClick={() => setCollapsed(!collapsed)}
          />
        </Header>
      )}
      <Layout>
        <BaseLayout collapsed={collapsed} setCollapsed={setCollapsed}>
          <Content
            className={
              isMobile || isTablet
                ? styles.contentMobileTablet
                : isOnBasicInfoPage()
                ? styles.appBasicGuideContent
                : mode === BENGUIDE_MODE_EDIT
                ? styles.appContent
                : isOnPlanRecommenderPage()
                ? styles.appPlanRecommenderContent
                : styles.appPrevContent
            }
            style={{
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </BaseLayout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
