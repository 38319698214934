import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { cloneDeep, set } from 'lodash';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import ContactSupport from 'components/ContactSupport/ContactSupport';
import StyledCheckbox from 'components/StyledCheckbox/StyledCheckbox';
import { ReactComponent as IconStar } from 'images/icons/Icon-Star.svg';
import { ReactComponent as IconBenefitConsult } from 'images/icons/Icon-BenefitConsult.svg';
import IconEdit from 'images/icon-edit.svg';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import ConfigureBC from 'modules/benefitsWellness/components/ConfigureBC/ConfigureBC';
import { setActivePlanCompare } from 'modules/benefitsWellness/slices/benefitWellnessSlice';
import { getBenConsultation } from 'modules/benefitsWellness/slices/benefitsConsultationSlice';
import {
  enablePlanRecommenderSection,
  updateFilters,
} from 'modules/benefitsWellness/slices/medicalSlice';
import { BC_STEP_TWO, BENEFIT_TYPES } from 'constants/commonConstants';
import PlanRecommenderModal from 'modules/benefitsWellness/components/PlanRecommenderModal/PlanRecommenderModal';
import WhosCoverSelector from 'modules/benefitsWellness/components/WhosCoverSelector/WhosCoverSelector';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import PlanRecommenderPopup from 'modules/benefitsWellness/components/PlanRecommenderPopup/PlanRecommenderPopup';
import MissingDataModal from 'modules/benefitsWellness/components/MissingDataModal/MissingDataModal';
import { getPlanRecommenderStatus } from 'modules/benefitsWellness/slices/PlanRecommenderEnableSlice';
import { trackEvents } from 'utils/initGA4';
import styles from './mdvPlansFilters.module.less';

type MDVPlansFiltersProps = {
  show: boolean;
  disabledOnEdit: boolean;
  showFilters: boolean;
  isEditMode: boolean;
  rateType: string;
  benefitKind: MDVTypes;
  onView: Function;
  closeContributions?: boolean;
  isUpdated?: boolean;
};

const MDVPlansFilters: FC<MDVPlansFiltersProps> = ({
  show,
  disabledOnEdit,
  showFilters,
  isEditMode,
  rateType,
  benefitKind,
  onView,
  closeContributions = true,
  isUpdated = false,
}: MDVPlansFiltersProps) => {
  const [filterVisible, setFilterVisible] = useState<boolean>(true);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [editBCModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState<boolean>(false);
  const [missingDataModalStatus, setMissingDataModalStatus] =
    useState<boolean>(false);
  const [visiblePopup, setVisiblePopup] = useState<boolean>(false);
  const [isOpenContributionModal, setIsOpenContributionModal] =
    useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [missingPlanAttributes, setMissingPlanAttributes] = useState<Object[]>(
    []
  );

  const { medical, benefitWellness } = useAppSelector((state: any) => state);
  const benguideId = useAppSelector((state) => state.benguide.data?.masterId);
  const { selectedPlanForCompare, activePlanCompare } = benefitWellness;
  const { planProviders, planTypes, appliedFilters, medicalSection } = medical;
  const { isTablet, isMobile } = useAppSelector((state) => state.layout);

  const loggedInUserType = useAppSelector(
    (state) => state.benguide.loggedInUserType
  );

  const dispatch = useAppDispatch();
  const benConsultation = useAppSelector(
    (state) => state?.benefitsConsultation.benConsultation
  );

  const employerName = useAppSelector(
    (state) => state.basicInfo.layoutBenefitGuide?.employerName
  );

  const { planRecommender } = useAppSelector(
    (state) => state.planRecommenderStatus
  );

  const benGuide = useAppSelector((state) => state.benguide.data);
  const brokerConsultantData: any = useAppSelector(
    (state) => state.contactSupport.consultantConfirmationBroker
  );
  const employerConsultantData: any = useAppSelector(
    (state) => state.contactSupport.consultantConfirmationEmployer
  );

  const [isBrokerConsultantExist, setIsBrokerConsultantExist] =
    useState<boolean>(false);
  const [isEmployerConsultantExist, setIsEmployerConsultantExist] =
    useState<boolean>(false);

  useEffect(() => {
    if (brokerConsultantData) {
      setIsBrokerConsultantExist(checkIfBrokerExist());
    }

    if (employerConsultantData) {
      setIsEmployerConsultantExist(checkIfEmployerExist());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerConsultantData]);

  const checkIfBrokerExist = (): boolean => {
    const exist = brokerConsultantData.find((obj: any) => {
      const foundEmployer = obj.mappedEmployers.find(
        (emp: any) => emp.employerId === benGuide.employerId
      );
      if (obj.status.status && foundEmployer) return true;
    });

    return !!exist;
  };

  const checkIfEmployerExist = (): boolean => {
    const exist = employerConsultantData.find((emp: any) => emp.status.status);
    return !!exist;
  };

  useEffect(() => {
    dispatch(getBenConsultation(benguideId));
  }, [benguideId, dispatch, editBCModalVisible, isUpdated]);

  const benguide = useBenguide();
  const location = useLocation();

  useEffect(() => {
    if (BENEFIT_TYPES.MEDICAL === benefitKind) {
      dispatch(getPlanRecommenderStatus(benguideId));
    }
  }, [
    isUpdated,
    benguideId,
    dispatch,
    benguide.planRecommenderEnabled,
    isSubmitted,
    benefitKind,
  ]);

  useEffect(() => {
    return () => {
      dispatch(
        updateFilters({
          providers: {},
          types: {},
        })
      );
    };
    // eslint-disable-next-line
  }, [benefitKind]);

  const filterProviders = (event: any) => {
    const { target } = event;
    const { name, checked } = target;
    const existingFilters = cloneDeep(appliedFilters);
    set(existingFilters, ['providers', name], checked);
    dispatch(updateFilters(existingFilters));
  };

  useEffect(() => {
    onView(isOpenContributionModal);
  }, [isOpenContributionModal, onView]);

  const filterTypes = (event: any) => {
    const { target } = event;
    const { name, checked } = target;
    const existingFilters = cloneDeep(appliedFilters);
    set(existingFilters, ['types', name], checked);
    dispatch(updateFilters(existingFilters));
  };

  const closePopup = () => {
    setVisiblePopup(false);
  };

  const addedPlanProviderCount = (provider: string) => {
    let count: number = 0;
    if (medicalSection && medicalSection.plans) {
      medicalSection.plans.forEach((plan: any) => {
        if (plan.benefitCarrier?.name === provider) {
          count++;
        }
      });
    }
    return count;
  };

  const addedPlanTypeCount = (planType: string) => {
    let count: number = 0;
    if (medicalSection && medicalSection.plans) {
      medicalSection.plans.forEach((plan: any) => {
        if (plan.type === planType) {
          count++;
        }
      });
    }
    return count;
  };

  const configureBCModal = (visible: boolean, onClose: Function) => {
    setEditModalVisible(visible);
    return <ConfigureBC isOpenModal={visible} closeModal={onClose} />;
  };

  const isPlanRecommenderEnabled = useCallback(
    (isEnabled: boolean) => {
      dispatch(enablePlanRecommenderSection(isEnabled, benguideId));
      if (BENEFIT_TYPES.MEDICAL === benefitKind) {
        dispatch(getPlanRecommenderStatus(benguideId));
      }
    },
    [benguideId, dispatch, benefitKind]
  );

  const openPlanRecommender = () => {
    const { urlHash } = benguide;
    const search = location.search;
    const previewToken = new URLSearchParams(search).get('previewToken');
    const appendToken = previewToken ? `&previewToken=${previewToken}` : '';
    window.open(
      `/${urlHash}${
        employerName ? `/${employerName}` : ''
      }/plan-recommender/medical?revision=${
        benguide.latestRevision
      }${appendToken}`,
      '_blank'
    );
    trackEvents({
      category: 'Plan Recommender',
      action: 'plan_recommender_started',
      label: 'Plan Recommender Started',
    });
  };

  return (
    <>
      {visiblePopup ? (
        <PlanRecommenderPopup
          clickStart={openPlanRecommender}
          closeModal={closePopup}
        />
      ) : (
        ''
      )}
      <div
        className={`${styles.mdvPlansFilters} ${
          benefitKind !== MDVTypes.MEDICAL ? styles.dvFilters : ''
        }`}
      >
        {isTablet && <WhosCoverSelector rateType={rateType} />}
        {showFilters &&
          (!isTablet ? (
            <>
              <div
                className={`${styles.filterOptions} ${
                  filterVisible ? styles.active : ''
                } ${activePlanCompare ? styles.hide : ''}`}
                onClick={() => {
                  setFilterVisible(!filterVisible);
                }}
              >
                Filter Options
                {filterVisible ? (
                  <CaretDownFilled className={styles.arrowIcon} />
                ) : (
                  <CaretUpFilled className={styles.arrowIcon} />
                )}
              </div>

              {filterVisible && (
                <>
                  <div
                    className={`${styles.planProviders} ${
                      activePlanCompare ? styles.hide : ''
                    }`}
                  >
                    <div className={styles.title}>PLAN PROVIDERS</div>
                    <div className={styles.items}>
                      {planProviders.map((provider: string, index: number) => (
                        <div key={index}>
                          <StyledCheckbox
                            name={provider}
                            onChange={filterProviders}
                          >
                            {provider}
                            <span>
                              &nbsp;({addedPlanProviderCount(provider)})
                            </span>
                          </StyledCheckbox>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className={` ${styles.planType} ${
                      activePlanCompare ? styles.hide : ''
                    }`}
                  >
                    <div className={styles.title}>PLAN TYPE</div>
                    <div className={styles.items}>
                      {planTypes.map((type: string, index: number) => (
                        <div key={index}>
                          <StyledCheckbox name={type} onChange={filterTypes}>
                            {type}
                            <span>&nbsp;({addedPlanTypeCount(type)})</span>
                          </StyledCheckbox>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {/* Filters for tablet/mobile resolutions are displayed in a dropdown */}
              <Dropdown
                visible={filterDropdownOpen}
                onVisibleChange={(visible) => {
                  setFilterDropdownOpen(visible);
                }}
                overlayClassName={styles.dropdownFilterMenu}
                className={styles.dropdownPlansFilter}
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item>
                      <div className={styles.filterSection}>
                        <div className={styles.title}>PLAN PROVIDERS</div>
                        <div className={styles.items}>
                          {planProviders.map(
                            (provider: string, index: number) => (
                              <div key={index} className={styles.filterItem}>
                                <StyledCheckbox
                                  name={provider}
                                  onChange={filterProviders}
                                >
                                  {provider}
                                  <span>
                                    ({addedPlanProviderCount(provider)})
                                  </span>
                                </StyledCheckbox>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className={styles.filterSection}>
                        <div className={styles.title}>PLAN TYPE</div>
                        <div className={styles.items}>
                          {planTypes.map((type: string, index: number) => (
                            <div key={index} className={styles.filterItem}>
                              <StyledCheckbox
                                name={type}
                                onChange={filterTypes}
                              >
                                {type}
                                <span>({addedPlanTypeCount(type)})</span>
                              </StyledCheckbox>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  className={styles.filterToggleBtn}
                  onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
                >
                  Filters Options <CaretDownFilled />
                </Button>
              </Dropdown>
            </>
          ))}

        <div className={styles.right}>
          <div
            className={`${styles.btnRightMain} ${styles.right} ${
              activePlanCompare ? styles.hide : ''
            }`}
          >
            {!isEditMode &&
              benguide?.isBenefitConsultEnable &&
              (benConsultation.brokerBenefitConsultationEnabled ||
                benConsultation.employerBenefitConsultationEnabled) && (
                <div className={styles.benefitConsultBtnContainer}>
                  <EditableHotspot
                    alwaysVisible
                    viewModeAction={() => {
                      setOpenScheduleModal(true);
                      trackEvents({
                        category: 'Benefits Consultation',
                        action: 'schedule_consultation',
                        label: 'Schedule Consultation',
                      });
                    }}
                  >
                    <Button
                      className={`${styles.addBenefitConsultButton} ${
                        styles.featureEnabled
                      } ${showFilters || show ? styles.spacing : ''}`}
                    >
                      <span className={styles.benefitWrapper}>
                        <IconBenefitConsult className={styles.benefitIcon} />
                      </span>
                      <div className={styles.benefitBtnText}>
                        Benefits Consultation
                      </div>
                    </Button>
                  </EditableHotspot>
                  <ContactSupport
                    bcStep={BC_STEP_TWO}
                    showButton={false}
                    openScheduleModal={openScheduleModal}
                    setOpenScheduleModal={setOpenScheduleModal}
                  />
                </div>
              )}
            {isEditMode &&
              benguide?.isBenefitConsultEnable &&
              ((loggedInUserType === 'ER_ADMIN' &&
                benguide.isBenefitConsultVisibleToEmployer) ||
                loggedInUserType !== 'ER_ADMIN') && (
                <div
                  className={`${styles.bcButtonEditWrapper} ${
                    showFilters || show ? styles.spacing : ''
                  }`}
                >
                  {(benConsultation.brokerBenefitConsultationEnabled ||
                    benConsultation.employerBenefitConsultationEnabled) &&
                  (isBrokerConsultantExist || isEmployerConsultantExist) ? (
                    <EditableHotspot
                      alwaysVisible={false}
                      customModal={(visible: boolean, onClose: Function) =>
                        configureBCModal(visible, onClose)
                      }
                      overlayComponent={
                        (benConsultation.brokerBenefitConsultationEnabled ||
                          benConsultation.employerBenefitConsultationEnabled) && (
                          <div className={styles.linkButtonOffset}>
                            <IconBenefitConsult
                              className={styles.benefitIcon}
                            />
                            <div>Edit Benefits Consult</div>
                            <img src={IconEdit} alt="edit" />
                          </div>
                        )
                      }
                    >
                      <Button
                        className={`${styles.addBenefitConsultButton} ${styles.featureEnabled}`}
                      >
                        <span className={styles.benefitWrapper}>
                          <IconBenefitConsult className={styles.benefitIcon} />
                        </span>
                        <div className={styles.benefitBtnText}>
                          Benefits Consultation
                        </div>
                      </Button>
                    </EditableHotspot>
                  ) : (
                    <>
                      <EditableHotspot
                        alwaysVisible
                        customModal={(visible: boolean, onClose: Function) =>
                          configureBCModal(visible, onClose)
                        }
                      >
                        <Button
                          className={`${styles.addBenefitConsultButton} ${styles.edit}`}
                        >
                          <>
                            Add Benefits Consult
                            <img src={IconEdit} alt="edit" />
                          </>
                        </Button>
                      </EditableHotspot>
                    </>
                  )}
                </div>
              )}
            {(isEditMode ? show : show && planRecommender.enabled) && (
              <div
                className={`${styles.planRecommenderButtonWrapper} ${
                  disabledOnEdit ? styles.disabled : ''
                } `}
              >
                <EditableHotspot
                  alwaysVisible={!planRecommender.enabled}
                  overlayComponent={
                    planRecommender.enabled ? (
                      <div className={styles.recommenderBtnOverlay}>
                        <IconStar className={styles.starIcon} />
                        <div>Edit Recommender</div>
                        <img src={IconEdit} alt="edit" />
                      </div>
                    ) : (
                      ''
                    )
                  }
                  editIcon={
                    planRecommender.enabled ? (
                      <img src={IconEdit} alt="edit" />
                    ) : undefined
                  }
                  customModal={(visible: boolean, onClose: () => {}) => (
                    <PlanRecommenderModal
                      onView={setIsOpenContributionModal}
                      visible={visible}
                      onClose={onClose}
                      defaultEnabled={planRecommender.enabled}
                      onChange={(isEnabled: boolean) => {
                        isPlanRecommenderEnabled(isEnabled);
                        onClose();
                      }}
                      onSubmit={setIsSubmitted}
                      closeContributions={closeContributions}
                      missingDataModalStatus={setMissingDataModalStatus}
                      setMissingPlanAttributes={setMissingPlanAttributes}
                    />
                  )}
                >
                  <Button
                    className={`${styles.planRecommenderButton} ${
                      !!planRecommender.enabled ? styles.featureEnabled : ''
                    }`}
                    onClick={() => {
                      setVisiblePopup(true);
                    }}
                  >
                    {!!planRecommender.enabled ||
                    (!isEditMode && benguide?.planRecommenderEnabled) ? (
                      <>
                        <span>
                          <IconStar className={styles.starIcon} /> Plan
                          Recommender
                        </span>
                      </>
                    ) : (
                      <>
                        Add Plan Recommender <img src={IconEdit} alt="edit" />
                      </>
                    )}
                  </Button>
                </EditableHotspot>
              </div>
            )}
          </div>
          {showFilters && !isMobile && (
            <Button
              className={`${styles.compareButton} ${
                selectedPlanForCompare.length > 1 || activePlanCompare
                  ? styles.active
                  : ''
              } ${activePlanCompare ? styles.clearComparison : ''}`}
              onClick={() => {
                dispatch(setActivePlanCompare());
              }}
              disabled={
                selectedPlanForCompare.length <= 1 && !activePlanCompare
              }
            >
              {activePlanCompare ? 'Clear Comparison' : ' Compare Now'}
            </Button>
          )}
        </div>
      </div>
      {filterVisible && showFilters && <div className={styles.lineBreak}></div>}

      <MissingDataModal
        setVisible={setMissingDataModalStatus}
        status={missingDataModalStatus}
        missingData={missingPlanAttributes}
        plans={medicalSection.plans}
      />
    </>
  );
};

export default MDVPlansFilters;
