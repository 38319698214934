import { ReactNode } from 'react';
import BouncingDots from 'modules/aiAssistantChat/components/BouncingDots/BouncingDots';

interface LoadingWrapperProps {
  children: ReactNode;
  isPending: boolean;
  isAnswer: boolean;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  isPending,
  children,
  isAnswer,
}: LoadingWrapperProps) => {
  if (isPending && isAnswer) {
    return <BouncingDots />;
  }

  return <>{children}</>;
};

export default LoadingWrapper;
