import { FC, useEffect } from 'react';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import Plan from 'model/Plan';
import { buildPlanRefsFromPlan } from 'modules/benefitsWellness/util';
import { TaxAdvantagedSection } from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import {
  fetchCommuterPlans,
  getCommuterSection,
  getDefaultMediaForCommuter,
  getPlansFromPlanRefs,
  selectCommuterPlans,
} from 'modules/taxAdvantaged/slices/commuterSlice';
import TaxAdvantagedPlanOverview from 'modules/taxAdvantaged/components/TaxAdvantagePlanOverview/TaxAdvantagedPlanOverview';

const CommuterPlans: FC = () => {
  const dispatch = useAppDispatch();

  const { isEditMode, latestRevision } = useBenguide();

  const benguideData = useAppSelector((state) => state.benguide.data);
  const planRefs = useAppSelector(
    (state) => state.commuter.commuterSection.plans
  );
  const planList = useAppSelector((state) => state.commuter.planList);
  const { data: selectedPlans, inProgress } = useAppSelector(
    (state) => state.commuter.selectedPlans
  );

  useEffect(() => {
    const { employerId, planYearId, benefitClasses } = benguideData || {};
    if (employerId && planYearId && isEditMode) {
      dispatch(
        fetchCommuterPlans({
          employerId,
          planYearId,
          benefitClasses,
        })
      );
    }
  }, [dispatch, benguideData, isEditMode]);

  useEffect(() => {
    dispatch(
      getCommuterSection({
        benguideId: benguideData.masterId,
      })
    );
  }, [dispatch, benguideData.masterId, latestRevision]);

  useEffect(() => {
    dispatch(getPlansFromPlanRefs({ planRefs }));
  }, [dispatch, planRefs]);

  useEffect(() => {
    if (selectedPlans.length > 0) {
      dispatch(
        getDefaultMediaForCommuter(benguideData.masterId, selectedPlans[0].id)
      );
    }
  }, [dispatch, selectedPlans, benguideData]);

  const handleSave = (planList: Plan[], orderByPlanNames: boolean) => {
    const planRefs = planList.map((plan) =>
      buildPlanRefsFromPlan(plan, plan.benefitKind)
    );
    const payload: AddPlanApiRequestPayload = {
      orderByPlanNames: orderByPlanNames,
      plans: planRefs,
      sectionName: TaxAdvantagedSection.COMMUTER,
    };

    dispatch(
      selectCommuterPlans({
        addPlansPayload: payload,
        benefitKind: TaxAdvantagedSection.COMMUTER,
        benguideId: benguideData.masterId,
      })
    );
  };

  return (
    <TaxAdvantagedPlanOverview
      onSelectPlans={handleSave}
      planList={planList.data}
      planListLoading={planList.inProgress}
      planRefs={planRefs}
      section={TaxAdvantagedSection.COMMUTER}
      selectedPlans={selectedPlans}
      selectedPlansLoading={inProgress}
    />
  );
};

export default CommuterPlans;
