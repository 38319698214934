import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import UPClient from 'modules/clients/UPClient/UPClient';
import useUPClient from 'modules/clients/UPClient/useUPClient';

import {
  RELOAD_VOLUNTARY_BENEFITS_PLAN_LIST,
  RELOAD_VOLUNTARY_BENEFITS_SECTION,
  VOLUNTARY_BENEFITS_CHANNEL,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
} from 'modules/clients/UPClient/UPClientConst';
import {
  fetchVolBenefitsPlanList,
  reloadVoluntaryBenefitsSection,
} from 'modules/benefitsWellness/slices/voluntaryBenefitsSlice';

const VoluntaryBenefitsChannel = () => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();

  return (
    <UPClient
      channel={VOLUNTARY_BENEFITS_CHANNEL}
      subscribe={(event: string) => {
        switch (event) {
          case RELOAD_VOLUNTARY_BENEFITS_PLAN_LIST:
            dispatch(
              fetchVolBenefitsPlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case RELOAD_VOLUNTARY_BENEFITS_SECTION:
            dispatch(
              reloadVoluntaryBenefitsSection(
                benguide.masterId,
                benguide.latestRevision
              )
            );
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default VoluntaryBenefitsChannel;
