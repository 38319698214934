export const LIFE_AND_DISABILITY_CARD_TOOLTIPS = {
  benefit:
    'The total benefit amount before considering the policy’s benefit maximum.',
  maxBenefit: 'The maximum amount a carrier will pay out for this policy.',
  guaranteedIssue:
    'Guaranteed issue (GI) is the benefit amount automatically available without having to provide Evidence of Insurability (EOI). Carriers require EOI submission for any amount above the GI.',
  maxWeeklyBenefit:
    'The maximum weekly benefit amount a carrier will pay out for this policy.',
  maxMonthlyBenefit:
    'The maximum monthly benefit amount a carrier will pay out for this policy.',
  waitingPeriod:
    'The length of time you must be disabled before benefits are payable.',
  maxBenefitDuration:
    'The maximum length of time that you are eligible to receive the benefit.',
  volBenefit: 'The benefit amount that can be elected.',
  volGuaranteedIssue: (
    <span>
      Guaranteed issue (GI) is the benefit amount automatically available
      without having to provide Evidence of Insurability (EOI). Carriers require
      EOI submission for any amount above the GI.
      <br />
      Typically, the GI amount is only available the first chance you have to
      elect the benefit. Moving forward you will need to submit an EOI for any
      increase in coverage.
    </span>
  ),
};

export const FORMATTED_FREQUENCY: any = {
  ANNUALLY: 'Annual',
  MONTHLY: 'Monthly',
  SEMI_MONTHLY: 'Semi-Monthly',
  BI_WEEKLY: 'Bi-Weekly',
  QUARTERLY: 'Quarterly',
};
