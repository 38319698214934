import { FC, useEffect } from 'react';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import Plan from 'model/Plan';
import { buildPlanRefsFromPlan } from 'modules/benefitsWellness/util';
import { TaxAdvantagedSection } from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import {
  fetchHRAPlans,
  getDefaultMediaForHRA,
  getHRASection,
  getPlansFromPlanRefs,
  selectHRAPlans,
} from 'modules/taxAdvantaged/slices/hraSlice';
import TaxAdvantagedPlanOverview from 'modules/taxAdvantaged/components/TaxAdvantagePlanOverview/TaxAdvantagedPlanOverview';

const HRAPlans: FC = () => {
  const dispatch = useAppDispatch();

  const { isEditMode, latestRevision } = useBenguide();

  const benguideData = useAppSelector((state) => state.benguide.data);
  const planRefs = useAppSelector((state) => state.hra.hraSection.plans);
  const planList = useAppSelector((state) => state.hra.planList);
  const { data: selectedPlans, inProgress } = useAppSelector(
    (state) => state.hra.selectedPlans
  );

  useEffect(() => {
    const { employerId, planYearId, benefitClasses } = benguideData || {};
    if (employerId && planYearId && isEditMode) {
      dispatch(
        fetchHRAPlans({
          employerId,
          planYearId,
          benefitClasses,
        })
      );
    }
  }, [dispatch, benguideData, isEditMode]);

  useEffect(() => {
    dispatch(getPlansFromPlanRefs({ planRefs }));
  }, [dispatch, planRefs]);

  useEffect(() => {
    dispatch(
      getHRASection({
        benguideId: benguideData.masterId,
      })
    );
  }, [dispatch, benguideData.masterId, latestRevision]);

  useEffect(() => {
    if (selectedPlans.length > 0) {
      dispatch(
        getDefaultMediaForHRA(benguideData.masterId, selectedPlans[0].id)
      );
    }
  }, [dispatch, selectedPlans, benguideData]);

  const handleSave = (planList: Plan[], orderByPlanNames: boolean) => {
    const planRefs = planList.map((plan) =>
      buildPlanRefsFromPlan(plan, plan.benefitKind)
    );
    const payload: AddPlanApiRequestPayload = {
      orderByPlanNames: orderByPlanNames,
      plans: planRefs,
      sectionName: TaxAdvantagedSection.HRA,
    };

    dispatch(
      selectHRAPlans({
        addPlansPayload: payload,
        benefitKind: TaxAdvantagedSection.HRA,
        benguideId: benguideData.masterId,
      })
    );
  };

  return (
    <TaxAdvantagedPlanOverview
      onSelectPlans={handleSave}
      planList={planList.data}
      planListLoading={planList.inProgress}
      planRefs={planRefs}
      section={TaxAdvantagedSection.HRA}
      selectedPlans={selectedPlans}
      selectedPlansLoading={inProgress}
    />
  );
};

export default HRAPlans;
