import React from 'react';
import MissingInfo from 'modules/benefitsWellness/components/MissingInfo/MissingInfo';
import CustomField from 'model/CustomField';
import styles from './mdvCardCustomValue.module.less';
type Props = {
  title: any;
  data: CustomField;
  isMobile: boolean;
  isEditMode: boolean;
};

const MDVCardCustomValue = (props: Props) => {
  const { data, isEditMode, isMobile, title } = props;
  if (isEditMode && data?.fieldValue === title?.defaultValue) {
    return <MissingInfo missingInfoText="Missing Info" />;
  }
  return (
    <div>
      {isMobile && (
        <div className={styles.customFieldMobile}>{data?.fieldName}</div>
      )}
      <div className={styles.customFieldValueWrapper} title={data?.fieldValue}>
        <span className={styles.textStyles}>
          {!isEditMode && data?.fieldValue === title?.defaultValue
            ? '-'
            : data?.fieldValue}
        </span>
      </div>
    </div>
  );
};

export default MDVCardCustomValue;
