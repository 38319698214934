import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';

import AuthReducer from 'modules/auth/slices/authSlice';
import EmployeeAuthReducer from 'modules/auth/slices/employeeAuthSlice';
import IDCardReducer from 'modules/auth/slices/idCardSlice';
import BenguideReducer from 'modules/auth/slices/benguideSlice';
import BasicInfoReducer from 'modules/home/slices/basicInfoSlice';
import ContactSupportSlice from 'modules/home/slices/contactSupportSlice';
import HomeReducer from 'modules/home/slices/homeSlice';
import OEMeetingReducer from 'modules/home/slices/oeMeetingSlice';
import BenefitWellnessReducer from 'modules/benefitsWellness/slices/benefitWellnessSlice';
import MedicalReducer from 'modules/benefitsWellness/slices/medicalSlice';
import DentalReducer from 'modules/benefitsWellness/slices/dentalSlice';
import NewHireChecklistReducer from 'modules/newHireChecklist/slices/nhcSlice';
import TelehealthReducer from 'modules/benefitsWellness/slices/telehealthSlice';
import VisionReducer from 'modules/benefitsWellness/slices/visionSlice';
import WellbeingReducer from 'modules/benefitsWellness/slices/wellbeingSlice';
import HolidayAndTimeOffReducer from 'modules/holidaysAndTimeOff/slices/holidayAndTimeOffSlice';
import HowInsuranceWorksReducer from 'modules/howInsuranceWorks/slices/hiwSlice';
import LifeAndDisabilityReducer from 'modules/benefitsWellness/slices/lifeAndDisabilitySilce';
import FamilyAndLeaveReducer from 'modules/familyAndLeave/slices/familyAndLeaveSlice';
import AdditionalPerksReducer from 'modules/additionalPerks/slices/additionalPerksSlice';
import DatesSlice from 'modules/datesEligability/slices/datesSlice';
import VolBenefitsReducer from 'modules/benefitsWellness/slices/voluntaryBenefitsSlice';
import FourOOneKReducer from 'modules/fourOOneK/slices/fourOOneKSlice';
import HSAReducer from 'modules/taxAdvantaged/slices/hsaSlice';
import HRAReducer from 'modules/taxAdvantaged/slices/hraSlice';
import CommuterReducer from 'modules/taxAdvantaged/slices/commuterSlice';
import FSAReducer from 'modules/taxAdvantaged/slices/fsaSlice';
import ResourcesReducer from 'modules/resources/slices/resourcesSlice';
import BenefitsConsultationReducer from 'modules/benefitsWellness/slices/benefitsConsultationSlice';
import PlanRecommenderReducer from 'modules/planRecommender/slices/planRecommenderSlice';
import LayoutReducer from 'layout/layoutSlice';
import PlanRecommenderEnableReducer from 'modules/benefitsWellness/slices/PlanRecommenderEnableSlice';
import AiAssistantChatReducer from 'modules/aiAssistantChat/slices/aiAssistantChatSlice';

const persistConfig = {
  key: 'persistedRoot',
  storage: sessionStorage,
};

const combinePersistingReducers = combineReducers({
  aiAssistantChat: AiAssistantChatReducer,
});

const persistedReducer = persistReducer(
  persistConfig,
  combinePersistingReducers
);

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    employeeAuth: EmployeeAuthReducer,
    idCard: IDCardReducer,
    benguide: BenguideReducer,
    basicInfo: BasicInfoReducer,
    oeMeeting: OEMeetingReducer,
    benefitWellness: BenefitWellnessReducer,
    home: HomeReducer,
    medical: MedicalReducer,
    dental: DentalReducer,
    vision: VisionReducer,
    newHireChecklist: NewHireChecklistReducer,
    telehealth: TelehealthReducer,
    contactSupport: ContactSupportSlice,
    wellbeing: WellbeingReducer,
    holidayAndTimeOff: HolidayAndTimeOffReducer,
    howInsuranceWorks: HowInsuranceWorksReducer,
    lifeAndDisability: LifeAndDisabilityReducer,
    familyAndLeave: FamilyAndLeaveReducer,
    additionalPerks: AdditionalPerksReducer,
    datesEligibility: DatesSlice,
    voluntaryBenefits: VolBenefitsReducer,
    fourOOneK: FourOOneKReducer,
    hsa: HSAReducer,
    hra: HRAReducer,
    commuter: CommuterReducer,
    fsa: FSAReducer,
    resources: ResourcesReducer,
    benefitsConsultation: BenefitsConsultationReducer,
    planRecommender: PlanRecommenderReducer,
    layout: LayoutReducer,
    planRecommenderStatus: PlanRecommenderEnableReducer,
    persisted: persistedReducer,
  },
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
