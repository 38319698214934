import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import Section from 'model/Section';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import * as AdditionalPerksService from 'services/AdditionalPerksService';
import * as BenguideService from 'services/BenGuideService';
import * as PlanService from 'modules/auth/services/PlanService';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import { SectionName } from 'modules/home/constants';
import Plan from 'model/Plan';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { convertEncodedStringToBlob } from 'utils/fileUtil';
import {
  setCloseModalsInUP,
  updateBenguideLatestRevision,
} from 'modules/auth/slices/benguideSlice';

type AdditionalPerksState = {
  planList: {
    inProgress: boolean;
    data: Plan[];
    error: any;
  };
  additionalPerksSection: {
    inProgress: boolean;
    enable: boolean;
    error: any;
    plans: Plan[];
  };
  richMedia: any;
  sectionImage: {
    inProgress: boolean;
    image: string;
    error: any;
  };
  sectionImageOriginal: string | null;
  sections: any;
  latestRevision: number;
};

const initialState = {
  planList: {
    inProgress: false,
    data: [],
    error: null,
  },
  additionalPerksSection: {
    inProgress: false,
    enable: false,
    plans: [],
    error: null,
  },
  richMedia: {},
  sectionImage: {
    inProgress: false,
    image: '',
    error: null,
  },
  latestRevision: 0,
  sectionImageOriginal: null,
  sections: null,
} as AdditionalPerksState;

const additionalPerksSlice = createSlice({
  name: 'additionalPerks',
  initialState,
  reducers: {
    planListFetchingStarted: (state) => {
      state.planList.inProgress = true;
    },
    planListFetchingCompleted: (state, { payload }) => {
      state.planList.data = payload;
      state.planList.error = null;
      state.planList.inProgress = false;
    },
    planListFetchingFailed: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = payload;
    },
    additionalPerksSectionUpdateStarted: (state) => {
      state.additionalPerksSection.inProgress = true;
    },
    additionalPerksSectionUpdateCompleted: (state, { payload }) => {
      state.additionalPerksSection.inProgress = false;
      state.additionalPerksSection.enable = payload.enable;
      state.additionalPerksSection.plans = payload.plans;
      state.additionalPerksSection.error = null;
    },
    additionalPerksSectionUpdateFailed: (state, { payload }) => {
      state.additionalPerksSection.inProgress = false;
      state.additionalPerksSection.error = payload;
    },
    richMediaFetchingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingCompleted: (state, { payload }) => {
      const { planId, content } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, content);
      set(state, `richMedia.${planId}.error`, null);
    },
    richMediaUpdatingFailed: (state, { payload }) => {
      const { planId, error } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, '');
      set(state, `richMedia.${planId}.error`, error);
    },
    uploadSectionImageStarted(
      state,
      action: PayloadAction<{ image: string; original?: string }>
    ) {
      state.sectionImage.image = action.payload.image;
      state.sectionImage.error = null;
      state.sectionImage.inProgress = true;
      if (action.payload.original) {
        state.sectionImageOriginal = action.payload.original;
      }
    },
    uploadSectionImageSuccess(state, { payload }) {
      state.sectionImage.error = null;
      state.sectionImage.image = payload;
      state.sectionImage.inProgress = false;
      state.latestRevision = payload.latestRevision;
    },
    uploadSectionImageFailed(state, { payload }) {
      state.sectionImage.error = payload;
      state.sectionImage.image = '';
      state.sectionImage.inProgress = false;
    },
    deleteSectionImageStart(state) {
      state.sectionImage.inProgress = true;
      state.sectionImage.error = null;
    },
    deleteSectionImageSuccess(state) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = null;
    },
    deleteSectionImagaFailed(state, { payload }) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = payload;
    },
    setSections(state, { payload }) {
      state.sections = payload;
    },
  },
});

export const {
  planListFetchingCompleted,
  planListFetchingFailed,
  planListFetchingStarted,
  additionalPerksSectionUpdateStarted,
  additionalPerksSectionUpdateCompleted,
  additionalPerksSectionUpdateFailed,
  richMediaFetchingStarted,
  richMediaUpdatingCompleted,
  richMediaUpdatingFailed,
  richMediaUpdatingStarted,
  deleteSectionImagaFailed,
  deleteSectionImageStart,
  deleteSectionImageSuccess,
  uploadSectionImageFailed,
  uploadSectionImageStarted,
  uploadSectionImageSuccess,
  setSections,
} = additionalPerksSlice.actions;

export const fetchAdditionalPerksPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planListFetchingStarted());
    AdditionalPerksService.getPlanList(employerId, planYearId, benefitClasses)
      .then((response) => {
        const { data } = response || {};
        dispatch(planListFetchingCompleted(data));
      })
      .catch((error) => {
        dispatch(planListFetchingFailed(error));
      });
  };
};

export const updateAdditionalPerksSection: any = (section: Section) => {
  return async (dispatch: Dispatch) => {
    dispatch(setSections(section?.sections));
    const planPromisses = section.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return PlanService.getPlans('perks', planId || id, revision);
    });
    const plans = (await Promise.all(planPromisses)).map(
      (plan: any) => plan.data as Section
    );
    dispatch(additionalPerksSectionUpdateCompleted({ ...section, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const addPlans = (
  benguideId: string,
  request: AddPlanApiRequestPayload
) => {
  return async (dispatch: Dispatch) => {
    dispatch(additionalPerksSectionUpdateStarted());
    BenguideService.addPlans(benguideId, request)
      .then(({ data }) => {
        dispatch(reloadAdditionalPerksSection(benguideId, data.latestRevision));
      })
      .catch((error) => {
        dispatch(additionalPerksSectionUpdateFailed(error));
      });
  };
};

export const reloadAdditionalPerksSection: any = (
  benguideId: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await BenguideService.getPlanSection(
      benguideId,
      BENEFIT_CATEGORY.ADDITIONAL_PERK.value,
      revision
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(updateAdditionalPerksSection(sectionResponse.benefitPage));
  };
};

export const getRichMediaContent = (benguideId: string, planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaFetchingStarted(planId));
    BenguideService.getRichMediaContent(
      benguideId,
      BENEFIT_CATEGORY.ADDITIONAL_PERK.value,
      planId
    )
      .then(({ data }) => {
        const { content } = data;
        dispatch(richMediaUpdatingCompleted({ planId, content }));
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId, error }));
      });
  };
};

export const updateRichMediaContent = (
  benguideId: string,
  planId: string,
  content: string,
  callBack: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaUpdatingStarted(planId));
    BenguideService.updateRichMediaContent(
      benguideId,
      BENEFIT_CATEGORY.ADDITIONAL_PERK.value,
      planId,
      content
    )
      .then(() => {
        callBack && callBack();
        dispatch(richMediaUpdatingCompleted({ planId, content }));
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId, error }));
      });
  };
};

export const handleSectionImageUpload =
  (
    benguideId: string,
    image: string,
    sectionName: SectionName,
    originalImage?: string,
    cropArea?: OriginalImageCropParams,
    originalRef?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadSectionImageStarted({ image, original: originalImage }));

    let updateOriginalRef = originalRef;
    try {
      if (originalImage) {
        const originalImgBlob = await convertEncodedStringToBlob(originalImage);
        const originalImgRes = await BenguideService.uploadSectionImage(
          benguideId,
          originalImgBlob,
          sectionName,
          true
        );
        updateOriginalRef = originalImgRes.data.imageReference;
      }
      const imageBlob = await convertEncodedStringToBlob(image);
      const response = await BenguideService.uploadSectionImage(
        benguideId,
        imageBlob,
        sectionName,
        false,
        updateOriginalRef,
        cropArea
      );
      dispatch(updateBenguideLatestRevision(response.data.latestRevision));
      dispatch(
        reloadAdditionalPerksSection(benguideId, response.data.latestRevision)
      );
      dispatch(uploadSectionImageSuccess(response.data));
    } catch (error) {
      dispatch(uploadSectionImageFailed(error));
    }
  };

export default additionalPerksSlice.reducer;
