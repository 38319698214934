import { FC } from 'react';
import planYearLogo from 'images/navigation/PlanYearLogo.svg';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';

import styles from './authHeading.module.less';

type Props = {
  header: string;
};

const AuthHeading: FC<Props> = ({ header }: Props) => {
  return (
    <div className={styles.wrapper}>
      <img
        src={planYearLogo}
        alt="planYearLogo"
        className={styles.planYearLogo}
      />
      {header !== AUTH_VIEW.LOGIN.value && <h1>{header}</h1>}
    </div>
  );
};

export default AuthHeading;
