import { FC, useState, useEffect, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Form, Input, Row } from 'antd';
import InputForm from 'components/InputForm/InputForm';
import SwitchButton from 'components/SwitchButton/SwitchButton';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import {
  EMPTY_MESSAGE,
  ERROR_MESSAGE_PLAN_EDITING,
} from 'constants/commonConstants';
import { isValidUrl } from 'utils/httpUtil';
import { NotificationContext } from 'context/NotificationContext';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';

import styles from './benefitsEnrollEditor.module.less';

type BenefitsEnrollEditorProps = {
  isOpenModal: boolean;
  closeModal: Function;
  onSaveEnrollmentLink: Function;
  enrollmentLink: string;
  benefitButton: boolean;
};

const BenefitsEnrollEditor: FC<BenefitsEnrollEditorProps> = (
  props: BenefitsEnrollEditorProps
) => {
  const [form] = Form.useForm();
  const {
    isOpenModal,
    closeModal,
    onSaveEnrollmentLink,
    enrollmentLink,
    benefitButton,
  } = props;
  const [isChecked, setIsChecked] = useState<boolean>(benefitButton);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const notificationFlag = useContext(NotificationContext);

  const closeBenefitsEnrollModal = () => {
    if (benefitButton) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    form.resetFields();
    setAlertMessage('');
    setIsAlertVisible(false);
    closeModal();
  };

  useEffect(() => {
    setIsAlertVisible(false);
    form.resetFields();
  }, [isChecked, form]);

  const handleDisplayEnroll = (checked: boolean) => {
    setIsChecked(checked);
  };

  const saveEnrollmentLink = async () => {
    const link = form.getFieldValue(enrollmentLink).trim();
    setIsAlertVisible(false);

    try {
      await form.validateFields();
      if (isChecked) {
        if (isValidUrl(link)) {
          onSaveEnrollmentLink(link, isChecked);
          form.resetFields();
          setAlertMessage('');
          setIsAlertVisible(false);
          closeModal();
        } else {
          setIsAlertVisible(true);
          setAlertMessage('Please add a valid Benefits Enrollment link');
          form.setFields([{ name: enrollmentLink, errors: [''] }]);
        }
      } else {
        onSaveEnrollmentLink('', isChecked);
        form.resetFields();
        setAlertMessage('');
        setIsAlertVisible(false);
        closeModal();
      }
    } catch (error: any) {
      const { errorFields = [] } = error;
      if (!isEmpty(errorFields)) {
        setIsAlertVisible(true);
        setAlertMessage('Please correct all highlighted errors and try again.');
      }
    }
  };

  useEffect(() => {
    if (benefitButton) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [enrollmentLink, benefitButton]);

  return (
    <HalfScreenModal
      width="550px"
      title="Button to Enroll in Benefits :"
      visible={isOpenModal}
      onCancel={closeBenefitsEnrollModal}
      footerStyles={styles.benefitsEnrollFooter}
      onSubmit={saveEnrollmentLink}
    >
      {isAlertVisible && (
        <div className={styles.alertWrapper}>
          <AlertMessage
            className={styles.alert}
            type="error"
            message={alertMessage}
            closeAlert={() => setIsAlertVisible(false)}
          />
        </div>
      )}
      {notificationFlag && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      <Row className={styles.benefitSwitchWrapper}>
        <span className={styles.subHeader}>
          Display Enroll in Benefits Button?
        </span>
        <SwitchButton onChange={handleDisplayEnroll} checked={isChecked} />
      </Row>
      <div className={styles.benefitsLinkWrapper}>
        <InputForm form={form}>
          <Form.Item
            name={enrollmentLink}
            label="Benefits Enrollment link :"
            rules={[
              {
                required: isChecked,
                message: EMPTY_MESSAGE,
                whitespace: true,
              },
            ]}
            initialValue={enrollmentLink}
          >
            <Input disabled={!isChecked} />
          </Form.Item>
        </InputForm>
      </div>
    </HalfScreenModal>
  );
};

export default BenefitsEnrollEditor;
