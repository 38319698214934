import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const individualV2Url = `${baseApi}/v2/individuals`;
const individualV1Url = `${baseApi}/v1/individuals`;

export const findIndividualById = (individualId: string) => {
  return http.get(`${individualV2Url}/${individualId}`);
};

export const whoamI = () => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return http.get(`${individualV1Url}/whoami`, config);
};

export const updateTermsForIndividual = (individualId: string) => {
  return http.put(
    `${individualV2Url}/update-terms?individual-id=${individualId}`
  );
};
