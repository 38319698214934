// should be imported first so our styles are not overridden
import 'styles/antd.less';
import 'styles/defaults.less';

import { BrowserRouter } from 'react-router-dom';

import LayoutWrapper from 'layout/LayoutWrapper';
import { NavigationContextProvider } from 'context/NavigationContext';
import AppRoutes from 'routes/AppRoutes';
import UPCommonChannel from 'modules/clients/UPClient/channels/UPCommonChannel';

function App() {
  return (
    <NavigationContextProvider>
      <BrowserRouter>
        <UPCommonChannel />
        <LayoutWrapper>
          <AppRoutes />
        </LayoutWrapper>
      </BrowserRouter>
    </NavigationContextProvider>
  );
}

export default App;
