import { ReactNode } from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

import styles from './pageActionDropdown.module.less';

type Props = {
  icon?: React.ReactNode;
  onChange?: Function;
  options?: { value: string; label: string; disable?: boolean }[];
  className?: string;
  label?: ReactNode;
};

const PageActionDropdown = ({
  options,
  icon = <CaretDownFilled />,
  label = 'Actions',
  onChange = () => {},
  className,
}: Props) => {
  const menu = (
    <Menu onClick={(e) => onChange(e)}>
      {options?.map((option) => (
        <Menu.Item key={option.value} disabled={option.disable}>
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={`${className} ${styles.dropdownWrapper}`}>
      <Dropdown
        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
        overlay={menu}
        trigger={['click']}
      >
        <div className={styles.title}>
          {label} {icon}
        </div>
      </Dropdown>
    </div>
  );
};

export default PageActionDropdown;
