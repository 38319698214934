import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import iconWarning from 'images/icons/icon-warning.svg';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import { FORMAT_VALIDATE, SIZE_VALIDATE } from 'modules/resources/constants';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import styles from './additionalDocumentUploader.module.less';

type AdditionalDocumentUploaderProps = {
  onChange: Function;
  onRemove: Function;
  allowedFileTypes?: string | string[];
  onFileNameClick: Function;
  maxFileSizeMB: number;
  isLoading?: boolean;
  isRemoved?: boolean;
  defaultFile?: File | null;
  onValidateFails?: (validateSetting: string) => void;
  disabled?: boolean;
  selectFileName?: string;
  showCancelConfirmationBody?: boolean;
  overflowPopoverMaxWidth?: string;
  isFileNameVisible?: boolean;
  wrapperClassName?: string;
  noFileUploadedMessage?: string;
  showCancelConfirmation?: boolean;
  removeConfirmationPopUpZIndex?: number | undefined;
  fileNamePopoverZIndex?: number | undefined;
  isAdditionalFileUploader?: boolean;
};
const emptyFile = new File([''], '');

const removeMessage =
  'Removing the file will not affect any plan information, but it will no longer be available to' +
  ' download from a Digital Benefits Guide.';

const AdditionalDocumentUploader = forwardRef(
  (props: AdditionalDocumentUploaderProps, ref) => {
    const {
      onChange,
      onRemove,
      allowedFileTypes,
      maxFileSizeMB,
      isLoading = false,
      onFileNameClick,
      isRemoved = false,
      defaultFile,
      onValidateFails,
      disabled = false,
      selectFileName = false,
      showCancelConfirmationBody = true,
      overflowPopoverMaxWidth = '',
      isFileNameVisible = true,
      wrapperClassName,
      noFileUploadedMessage,
      showCancelConfirmation = true,
      removeConfirmationPopUpZIndex = undefined,
      fileNamePopoverZIndex = undefined,
      isAdditionalFileUploader = false,
    } = props;

    const [file, setFile] = useState<File>(emptyFile);

    const fileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (isRemoved) {
        setFile(emptyFile);
      }
    }, [isRemoved]);

    useEffect(() => {
      if (defaultFile && !file.name) {
        setFile(defaultFile);
      }
    }, [defaultFile, file.name]);

    const uploadFile = (): void => {
      if (fileInput.current !== null) {
        fileInput.current.click();
      }
    };

    const onSelectDocument = (event: any): void => {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const isAllowedFileType =
        typeof allowedFileTypes === 'string'
          ? file.type === allowedFileTypes
          : allowedFileTypes?.includes(file.type);

      if (!isAllowedFileType) {
        onValidateFails?.(FORMAT_VALIDATE);
        return;
      }

      const isAllowedFileSize = file.size / 1024 / 1024 < maxFileSizeMB;
      if (!isAllowedFileSize) {
        onValidateFails?.(SIZE_VALIDATE);
        return;
      }

      setFile(file);
      onChange(file);
    };

    const onInputClick = (
      event: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
      const element = event.target as HTMLInputElement;
      element.value = '';
    };

    useImperativeHandle(ref, () => ({
      reset() {
        setFile(emptyFile);
      },
    }));

    const loadingIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

    return (
      <div className={`${styles.documentUploaderWrapper} ${wrapperClassName}`}>
        <SubmitButton
          onClick={uploadFile}
          disabled={disabled}
          isAdditionalFileUploader={isAdditionalFileUploader}
        >
          {selectFileName || '+ Choose File'}
        </SubmitButton>
        <div
          className={
            isAdditionalFileUploader
              ? styles.additionalDocFileLabel
              : `${styles.fileLabel} ${disabled ? styles.disabled : ''}`
          }
        >
          {isLoading && (
            <>
              <Spin indicator={loadingIcon} /> &nbsp;
            </>
          )}
          {isFileNameVisible && file.name && (
            <div
              className={`${styles.fileNameArea} uploadedFileName`}
              onClick={() => onFileNameClick(file)}
            >
              {!isEmpty(overflowPopoverMaxWidth) ? (
                <OverflowPopover
                  placement={'right'}
                  maxWidth={overflowPopoverMaxWidth}
                  popoverZIndex={fileNamePopoverZIndex}
                >
                  {file.name}
                </OverflowPopover>
              ) : (
                file.name
              )}
            </div>
          )}
          {showCancelConfirmation && (
            <div className={styles.removeFile}>
              {isEmpty(file.name) ? (
                <span className="text label-disabled">
                  {noFileUploadedMessage
                    ? noFileUploadedMessage
                    : 'No File Selected'}
                </span>
              ) : (
                <ConfirmationModal
                  title={
                    <div className={styles.cancelUploadBody}>
                      Are you sure you want to remove this file?
                      {showCancelConfirmationBody ? <p>{removeMessage}</p> : ''}
                    </div>
                  }
                  icon={
                    <img
                      src={iconWarning}
                      alt="warning-icon"
                      className={styles.iconWarning}
                    />
                  }
                  confirmModalTrigger="(remove)"
                  onConfirm={() => {
                    setFile(emptyFile);
                    onRemove();
                  }}
                  okText="Yes, remove file"
                  zIndex={removeConfirmationPopUpZIndex}
                  placement="topLeft"
                />
              )}
            </div>
          )}
          {!showCancelConfirmation && (
            <div className={styles.removeFile}>
              {isEmpty(file.name) ? (
                <span className="text label-disabled">
                  {noFileUploadedMessage
                    ? noFileUploadedMessage
                    : 'No File Selected'}
                </span>
              ) : (
                <span
                  onClick={() => {
                    onRemove();
                    setFile(emptyFile);
                  }}
                >
                  (remove)
                </span>
              )}
            </div>
          )}
        </div>
        <input
          type="file"
          name="file"
          onClick={onInputClick}
          onChange={onSelectDocument}
          ref={fileInput}
          accept={typeof allowedFileTypes === 'string' ? allowedFileTypes : ''}
          hidden
        />
      </div>
    );
  }
);

AdditionalDocumentUploader.displayName = 'AdditionalDocumentUploader';

export default AdditionalDocumentUploader;
