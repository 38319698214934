import { Col, Form, Input, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';
import {
  authFlow,
  setAuthView,
  setUser,
  ssoSignUp,
} from 'modules/auth/slices/employeeAuthSlice';
import InputForm from 'components/InputForm/InputForm';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import styles from './ssoRegister.module.less';

const SSORegister = () => {
  const { hash = '', employerName = '' } = useParams();
  const baseUrl = `/${hash}/`;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector((state) => state.employeeAuth.user);
  const token = useAppSelector((state) => state.employeeAuth.token);
  const registerModalType = useAppSelector((state) => state.employeeAuth.view);
  const inProgress = useAppSelector((state) => state.employeeAuth.inProgress);

  const handleCancelAndBack = () => {
    dispatch(setAuthView(AUTH_VIEW.EMAIL.type));
  };

  const handleSSORegister = async (values: any) => {
    try {
      const constructRedirectURL = `benguideV2${baseUrl}${employerName}`;
      if (token && userEmail) {
        const response = await dispatch(
          ssoSignUp(userEmail!, token!, values?.firstName, values?.lastName)
        );
        if (response?.status === 201) {
          dispatch(authFlow(userEmail!, constructRedirectURL));
        } else {
          dispatch(setAuthView(AUTH_VIEW.LOGIN.type));
        }
      }
    } finally {
      dispatch(setUser(null));
    }
  };

  return (
    <Row justify="center" className={styles.wrapperContainer}>
      <InputForm
        form={form}
        isLoading={inProgress}
        layout="vertical"
        onFinish={handleSSORegister}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label={<>First Name</>}
              rules={[
                { required: true, message: 'Please enter your first name' },
              ]}
            >
              <Input className={styles.inputWrapper} name="firstName" />
            </Form.Item>
          </Col>{' '}
          <Col span={12}>
            <Form.Item
              name="lastName"
              label={<>Last Name</>}
              rules={[
                { required: true, message: 'Please enter your last name' },
              ]}
            >
              <Input className={styles.inputWrapper} name="lastName" />
            </Form.Item>
          </Col>
        </Row>

        <SubmitButton
          type="primary"
          htmlType="submit"
          disabled={inProgress}
          loading={inProgress}
          className={styles.submitPwdButton}
        >
          Register Account
        </SubmitButton>
        <Row justify="center">
          <LinkButton
            className={styles.resetPwdButton}
            onClick={() => handleCancelAndBack()}
          >
            {registerModalType === AUTH_VIEW.CODE.type ? 'Back' : 'Cancel'}
          </LinkButton>
        </Row>
      </InputForm>
    </Row>
  );
};

export default SSORegister;
