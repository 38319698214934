import React, { FC } from 'react';

import { fieldFormatters } from 'modules/benefitsWellness/util';
import { SUPER_SCRIPTS } from 'modules/benefitsWellness/constants/BenefitConstants';
import MissingInfo from 'modules/benefitsWellness/components/MissingInfo/MissingInfo';
import {
  getLabelsSuperscript,
  getSupTextFromReactNode,
} from 'utils/labelUtils';
import { Parameter } from 'model/LableTypes';
import styles from './mdvFormattedValue.module.less';

type NETWORK_TYPE = 'IN' | 'OUT';

type MDVFormattedValueProps = {
  data: any;
  formatter: string;
  isEdit?: boolean;
  removeSup?: boolean;
  fieldName: string;
  networkType: NETWORK_TYPE;
  labelParams: Parameter[];
};

const defaultValue = '-';

const showSuperScript = (
  data: any,
  value: string | undefined,
  removeSup: boolean,
  labelParams: Parameter[],
  networkType: NETWORK_TYPE,
  fieldName: string
) => {
  if (data && value && value !== defaultValue) {
    return (
      <>
        {value.replace(SUPER_SCRIPTS[0].strValue, '')}
        {!removeSup && labelParams?.length > 0 ? (
          <sup>
            {getLabelsSuperscript(
              labelParams,
              fieldName,
              networkType
            )?.toString()}
          </sup>
        ) : (
          ''
        )}
      </>
    );
  }
  return value;
};

const MDVFormattedValue: FC<MDVFormattedValueProps> = (
  props: MDVFormattedValueProps
) => {
  const {
    data,
    formatter,
    isEdit = false,
    removeSup = false,
    fieldName,
    labelParams,
    networkType,
  } = props;
  const formattedValue = fieldFormatters(data, formatter);

  const value = showSuperScript(
    data,
    formattedValue,
    removeSup,
    labelParams!,
    networkType,
    fieldName
  );

  if (value === defaultValue && isEdit) {
    return <MissingInfo missingInfoText="Missing Info" />;
  }

  const toolTip = `${
    formattedValue?.includes(SUPER_SCRIPTS[0].strValue)
      ? formattedValue.replace(SUPER_SCRIPTS[0].strValue, '')
      : formattedValue
  } ${getSupTextFromReactNode(value)}`;

  return (
    <div className={styles.benefitDetail} title={toolTip}>
      <span className={styles.networkValue}>{value}</span>
    </div>
  );
};

export default MDVFormattedValue;
