import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Drawer, DrawerProps } from 'antd';
import {
  PLAN_TYPE_EPO,
  PLAN_TYPE_HMO,
  PLAN_TYPE_OAP,
  PLAN_TYPE_POS,
  PLAN_TYPE_PPO,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import { MediaWidth } from 'constants/mediaConstants';
import iconBack from 'images/icons/icon-back.png';
import styles from './planTypeInfoDrawer.module.less';

interface PlanTypeInfoDrawerProps extends DrawerProps {
  planType: string;
  isDrawerOpen: boolean;
  isHSA: boolean;
}

const PPO = () => {
  return (
    <div className={styles.planTypeSection}>
      {/* <ReactPlayer
        width="100%"
        height={338}
        controls={true}
        url="https://www.youtube.com/watch?v=S9qGbbSroSE"
      /> */}
      <div className={styles.sectionInfo}>What is a PPO?</div>
      <p>
        A PPO, or Preferred Provider Organization provides a wider degree of
        choice. Insurance carriers make agreements with medical providers every
        year on participating in these PPO networks to provide consumers a wider
        range of choice on healthcare.
      </p>
      <div className={styles.paraSpacing}>
        With a PPO, the network is wider and no physician referral is required
        to go see a specialist. If you need to go outside of a PPO network, the
        insurer will still provide some coverage, but your out-of-network
        coverage will result in higher out-of-pocket costs.
      </div>
    </div>
  );
};

const POS = () => {
  return (
    <div className={styles.planTypeSection}>
      <div className={styles.sectionInfo}>What is a POS?</div>
      <p>
        A POS, or Point of Service plan, combines features of both HMO and PPO
        plans to offer flexibility and cost savings. Unlike PPO plans, POS plans
        require you to choose a PCP and obtain referrals for specialist visits.
      </p>
    </div>
  );
};

const OAP = () => {
  return (
    <div className={styles.planTypeSection}>
      <div className={styles.sectionInfo}>What is an OAP?</div>
      <p>
        An OAP or Open Access Plus provides a hybrid of an HMO and a PPO. On the
        one hand, like a PPO, you do not need a physician’s referral to go see a
        specialist. On the other hand, like an HMO, you must stay within the
        network.
      </p>
    </div>
  );
};

const EPO = () => {
  return (
    <div className={styles.planTypeSection}>
      {/* <ReactPlayer
        width="100%"
        height={338}
        controls={true}
        url="https://www.youtube.com/watch?v=S9qGbbSroSE"
      /> */}
      <div className={styles.sectionInfo}>What is an EPO?</div>
      <p>
        An EPO or Exclusive Provider Organization provides a hybrid of an HMO
        and a PPO. On the one hand, like a PPO, you do not need a physician’s
        referral to go see a specialist. On the other hand, like an HMO, you
        must stay within the network.
      </p>
    </div>
  );
};

const HMO = () => {
  return (
    <div className={styles.planTypeSection}>
      {/* <ReactPlayer
        width="100%"
        height={338}
        controls={true}
        url="https://www.youtube.com/watch?v=S9qGbbSroSE"
      /> */}
      <div className={styles.sectionInfo}>What is an HMO?</div>
      <p>
        A Health Maintenance Organization, or HMO plan, is a fully integrated
        health plan that includes doctors, pharmacies and administrative
        functions all within a single network. You pick a primary care doctor
        who serves as a primary point of contact. When necessary, you are
        required to get a referral from your primary care physician to see a
        specialist within the HMO network, such as a dermatologist.
      </p>
      <div className={styles.paraSpacing}>
        One of the primary reasons employees like HMOs is because they serve as
        a single provider for everything. Because of this, HMOs typically have
        lower administrative costs associated with running their networks.
      </div>
    </div>
  );
};

const HSA = () => {
  return (
    <div className={styles.planTypeSection}>
      {/* <ReactPlayer
        width="100%"
        height={338}
        controls={true}
        url="https://www.youtube.com/watch?v=yJGbsHz8eLo"
      /> */}

      <div className={styles.sectionInfo}>What is an HSA-Eligible Plan?</div>
      <p>
        An HSA-eligible plan is a High-Deductible Health Plan (HDHP) that can be
        paired with a Health Savings Account (HSA). An HDHP can be an HMO, PPO,
        or EPO. The key difference is how you share in health care costs. An
        HDHP has lower premiums, but you pay all costs out-of-pocket (including
        prescriptions) until you meet the plan’s deductible. Once you meet the
        deductible, the plan shares costs (copays or coinsurance kicks in).
      </p>
      <div className={styles.paraSpacing}>
        The HSA serves as a “health 401(k)” you can use to offset your
        out-of-pocket costs now—or let it build to pay for future health care
        costs after your retire. An HSA is a personal bank account, and the
        money is yours to keep even if you change employers. HSA funds are
        always yours to spend on qualified medical expenses (you just can’t
        *contribute* to an HSA unless you have an HDHP). HSAs funds rollover
        year-to-year, and they have a triple tax advantage (contributions are
        not federally taxed, funds grow tax-free, and funds used to pay for
        qualified medical expenses are not taxed).
      </div>
    </div>
  );
};

const PlanTypeInfoDrawer: FC<PlanTypeInfoDrawerProps> = ({
  planType,
  isDrawerOpen,
  onClose,
  isHSA,
}: PlanTypeInfoDrawerProps) => {
  const isTablet = useMediaQuery({ maxWidth: MediaWidth.lg });
  const screenWidth = window.innerWidth;
  return (
    <Drawer
      visible={isDrawerOpen}
      onClose={onClose}
      width={!isTablet ? 1000 : '100%'}
      destroyOnClose
      className={styles.drawer}
    >
      <div className={styles.planTypeInfoWrapper}>
        <div className={styles.typeHeader}>
          {isHSA ? (
            <div className={styles.sectionHeader}>
              {screenWidth < 1025 && (
                <>
                  <img
                    className={styles.backArrow}
                    onClick={onClose}
                    src={iconBack}
                  />
                </>
              )}
              Learn More About HSAs
            </div>
          ) : (
            <div className={styles.sectionHeader}>
              {screenWidth < 1025 && (
                <>
                  <img
                    className={styles.backArrow}
                    onClick={onClose}
                    src={iconBack}
                  />
                </>
              )}
              Learn More About {planType}s
            </div>
          )}

          <div className={styles.splitter}>
            <hr />
          </div>
        </div>
        {planType === PLAN_TYPE_PPO && !isHSA && <PPO />}
        {isHSA && <HSA />}
        {planType === PLAN_TYPE_POS && !isHSA && <POS />}
        {planType === PLAN_TYPE_HMO && !isHSA && <HMO />}
        {planType === PLAN_TYPE_EPO && !isHSA && <EPO />}
        {planType === PLAN_TYPE_OAP && !isHSA && <OAP />}

        <div className={styles.typeSubHeader}>
          <div className={styles.sectionHeader}>How Other Plans Compare</div>

          <div className={styles.splitter}>
            <hr />
          </div>
        </div>
        <span className={styles.otherPlansDescription}>
          Knowing the difference between plan types and their networks can help
          you choose the best plan for your needs and preferences.
        </span>
        <table className={styles.tablePlanInfo}>
          <col></col>
          {planType === PLAN_TYPE_HMO ? (
            <col className={styles.highlightCol}></col>
          ) : (
            <col className={styles.normalCol}></col>
          )}
          {planType === PLAN_TYPE_PPO ? (
            <col className={styles.highlightCol}></col>
          ) : (
            <col className={styles.normalCol}></col>
          )}
          {planType === PLAN_TYPE_EPO || planType === PLAN_TYPE_OAP ? (
            <col className={styles.highlightCol}></col>
          ) : (
            <col className={styles.normalCol}></col>
          )}
          {planType === PLAN_TYPE_POS ? (
            <col className={styles.highlightCol}></col>
          ) : (
            <col className={styles.normalCol}></col>
          )}
          <tr>
            <th className={styles.th1}></th>

            <th className={styles.tmiddle}>
              {planType === PLAN_TYPE_HMO && (
                <div className={styles.highlightHeader} />
              )}
              <span className={styles.heading}>HMO</span>
              <p className={styles.description}>
                Health Maintenance Organization
              </p>
            </th>

            <th className={styles.tmiddle}>
              {planType === PLAN_TYPE_PPO && (
                <div className={styles.highlightHeader} />
              )}
              <span className={styles.heading}>PPO</span>
              <p className={styles.description}>
                Preferred Provider Organization
              </p>
            </th>
            <th className={styles.tmiddle}>
              {(planType === PLAN_TYPE_OAP || planType === PLAN_TYPE_EPO) && (
                <div className={styles.highlightHeader} />
              )}
              <span className={styles.heading}>EPO</span>
              <p className={styles.description}>
                Exclusive Provider Organization
              </p>
            </th>
            <th className={styles.th1}>
              {planType === PLAN_TYPE_POS && (
                <div className={styles.highlightHeader} />
              )}
              <span className={styles.heading}>POS</span>
              <p className={styles.descriptionPOS}>Point of Service</p>
            </th>
          </tr>
          <tr>
            <td className={styles.titleCol}>Self Referral</td>
            <td className={styles.tdBorder}>-</td>
            <td className={styles.tdBorder}>
              <span className={styles.greenDot} />
            </td>
            <td className={styles.tdBorder}>
              <span className={styles.greenDot} />
            </td>
            <td className={styles.tdBorder}>-</td>
          </tr>
          <tr className={styles.tRow}>
            <td className={styles.titleCol}>Primary Doc Referral</td>
            {planType === PLAN_TYPE_HMO ? (
              <td className={`${styles.highlightCol} ${styles.light}`}>
                <span className={styles.greenDot} />
              </td>
            ) : (
              <td className={`${styles.darkData} ${styles.bordered}`}>
                <span className={styles.greenDot} />
              </td>
            )}

            {planType === PLAN_TYPE_PPO ? (
              <td className={`${styles.highlightCol} ${styles.light}`}>-</td>
            ) : (
              <td className={`${styles.darkData} ${styles.bordered}`}>-</td>
            )}
            {planType === PLAN_TYPE_EPO || planType === PLAN_TYPE_OAP ? (
              <td className={`${styles.highlightCol} ${styles.light}`}>-</td>
            ) : (
              <td className={`${styles.darkData} ${styles.bordered}`}>-</td>
            )}
            {planType === PLAN_TYPE_POS ? (
              <td
                className={`${styles.highlightCol} ${styles.light} ${styles.borderless}`}
              >
                <span className={styles.greenDot} />
              </td>
            ) : (
              <td className={styles.darkData}>
                <span className={styles.greenDot} />
              </td>
            )}
          </tr>
          <tr>
            <td className={styles.titleCol}>No Coinsurance</td>
            <td className={styles.tdBorder}>
              <span className={styles.greenDot} />
            </td>
            <td className={styles.tdBorder}>-</td>
            <td className={styles.tdBorder}>
              <span className={styles.greenDot} />
            </td>
            <td>-</td>
          </tr>
          <tr className={styles.tRow}>
            <td className={styles.titleCol}>No Deductible</td>
            {planType === PLAN_TYPE_HMO ? (
              <td className={`${styles.highlightCol} ${styles.light}`}>
                <span className={styles.greenDot} />
              </td>
            ) : (
              <td className={`${styles.darkData} ${styles.bordered}`}>
                <span className={styles.greenDot} />
              </td>
            )}
            {planType === PLAN_TYPE_PPO ? (
              <td className={`${styles.highlightCol} ${styles.light}`}>-</td>
            ) : (
              <td className={`${styles.darkData} ${styles.bordered}`}>-</td>
            )}
            {planType === PLAN_TYPE_EPO || planType === PLAN_TYPE_OAP ? (
              <td className={`${styles.highlightCol} ${styles.light}`}>-</td>
            ) : (
              <td className={`${styles.darkData} ${styles.bordered}`}>
                <span className={styles.greenDot} />
              </td>
            )}
            {planType === PLAN_TYPE_POS ? (
              <td
                className={`${styles.highlightCol} ${styles.light} ${styles.borderless}`}
              >
                <span className={styles.greenDot} />
              </td>
            ) : (
              <td className={styles.darkData}>
                <span className={styles.greenDot} />
              </td>
            )}
          </tr>
          <tr>
            <td className={styles.titleCol}>Out-of-Network Coverage</td>
            <td className={styles.tdBorder}>-</td>
            <td className={styles.tdBorder}>
              <span className={styles.greenDot} />
            </td>
            <td className={styles.tdBorder}>-</td>
            <td>
              <span className={styles.greenDot} />
            </td>
          </tr>
        </table>
        <div className={styles.oapDescription}>
          * OAP is similar to an EPO, with one major difference: out-of-network
          coverage is included at a higher out-of-pocket cost.
        </div>
      </div>
    </Drawer>
  );
};

export default PlanTypeInfoDrawer;
