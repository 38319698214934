import { FC, ReactNode } from 'react';
import { Layout } from 'antd';

import AppSider from 'layout/AppSider';
import { useAppSelector } from 'hooks/redux';

export type DefaultLayoutProps = {
  collapsed: boolean;
  setCollapsed: (collpased: boolean) => void;
  children: ReactNode;
};

const DefaultLayout: FC<DefaultLayoutProps> = (props: DefaultLayoutProps) => {
  const { collapsed, setCollapsed } = props;
  const isTablet = useAppSelector((state) => state.layout.isTablet);

  return (
    <>
      {!isTablet && (
        <AppSider
          collapsed={collapsed}
          onCloseSider={() => setCollapsed(true)}
        />
      )}
      <Layout>{props.children}</Layout>
      {isTablet && (
        <AppSider
          collapsed={collapsed}
          onCloseSider={() => setCollapsed(true)}
        />
      )}
    </>
  );
};

export default DefaultLayout;
