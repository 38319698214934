import { getTimezone } from 'countries-and-timezones';
import RouteMap from 'routes/RouteMap';

export enum BenefitSection {
  MEDICAL = 'medical',
  LIFE_AND_DISABILITY = 'lifeAndDisability',
  HRA = 'hra',
  HOLIDAYS = 'holidaysAndTimeOff',
  TELEHEALTH = 'telehealth',
  VOLUNTARY_BENEFITS = 'volBenefits',
  FSA = 'fsa',
  FAMILY_AND_LEAVE = 'familyAndLeave',
  DENTAL = 'dental',
  WELLBEING = 'wellbeing',
  HSA = 'hsa',
  ADDITIONAL_PERKS = 'additionalPerks',
  VISION = 'vision',
  FOUR_O_ONE_K = 'fourOOneK',
  COMMUTER = 'commuter',
}

export const BENEFIT_TYPES: {
  [key in BenefitSection]: {
    label: string;
    value: BenefitSection;
    route: RouteMap;
  };
} = {
  medical: {
    label: 'Medical',
    value: BenefitSection.MEDICAL,
    route: RouteMap.MEDICAL,
  },
  lifeAndDisability: {
    label: 'Life & Disability',
    value: BenefitSection.LIFE_AND_DISABILITY,
    route: RouteMap.LIFE_AND_DISABILITY,
  },
  hra: { label: 'HRA', value: BenefitSection.HRA, route: RouteMap.HRA },
  holidaysAndTimeOff: {
    label: 'Holidays & Time Off',
    value: BenefitSection.HOLIDAYS,
    route: RouteMap.HOLIDAYS_TIME_OFF,
  },
  telehealth: {
    label: 'Telehealth/Rx Delivery',
    value: BenefitSection.TELEHEALTH,
    route: RouteMap.TELEHEALTH,
  },
  volBenefits: {
    label: 'Voluntary Benefits',
    value: BenefitSection.VOLUNTARY_BENEFITS,
    route: RouteMap.VOLUNTARY,
  },
  fsa: { label: 'FSA', value: BenefitSection.FSA, route: RouteMap.FSA },
  familyAndLeave: {
    label: 'Family & Leave',
    value: BenefitSection.FAMILY_AND_LEAVE,
    route: RouteMap.FAMILY_AND_LEAVE,
  },
  dental: {
    label: 'Dental',
    value: BenefitSection.DENTAL,
    route: RouteMap.DENTAL,
  },
  wellbeing: {
    label: 'Wellbeing',
    value: BenefitSection.WELLBEING,
    route: RouteMap.WELLBEING,
  },
  hsa: { label: 'HSA', value: BenefitSection.HSA, route: RouteMap.HSA },
  additionalPerks: {
    label: 'Additional Perks',
    value: BenefitSection.ADDITIONAL_PERKS,
    route: RouteMap.ADDITIONAL_PERKS,
  },
  vision: {
    label: 'Vision',
    value: BenefitSection.VISION,
    route: RouteMap.VISION,
  },
  fourOOneK: {
    label: '401(k)',
    value: BenefitSection.FOUR_O_ONE_K,
    route: RouteMap.FOUR_O_ONE_K,
  },
  commuter: {
    label: 'Commuter',
    value: BenefitSection.COMMUTER,
    route: RouteMap.COMMUTER,
  },
};

export enum CHECKLIST_OPTIONS {
  NEW_HIRE_CHECKLIST = 'NEW_HIRE_CHECKLIST',
  HOW_INSURANCE_WORKS = 'HOW_INSURANCE_WORKS',
  DATES_AND_ELIGIBILITY = 'DATES_AND_ELIGIBILITY',
  MEDICAL_PLAN_RECOMMENDER = 'MEDICAL_PLAN_RECOMMENDER',
}

export const HIGHLIGHT_OPTIONS = [
  {
    label: 'New Hire Checklist',
    value: 'NEW_HIRE_CHECKLIST',
    description: 'An overview checklist for new hires.',
    objectName: 'newHireCheckList',
  },
  {
    label: 'How Insurance Works',
    value: 'HOW_INSURANCE_WORKS',
    description:
      'Questions to employees most frequently asked questions about benefits.',
    objectName: 'howInsuranceWorks',
  },
  {
    label: 'Dates & Eligibility',
    value: 'DATES_AND_ELIGIBILITY',
    description: 'Important dates for enrollment and eligbility.',
    objectName: 'datesAndEligibility',
  },
  {
    label: 'Medical Plan Recommender',
    value: 'MEDICAL_PLAN_RECOMMENDER',
    description:
      'Links to tool that recommends the best available plan for the employee based on their needs.',
    objectName: 'medicalPlanRecommenderd',
  },
];

export enum ContentTypes {
  NEW_HIRE_CHECKLIST = 'newHireCheckList',
  HOW_INSURANCE_WORKS = 'howInsuranceWorks',
  DATES_AND_ELIGIBILITY = 'datesAndEligibility',
  MEDICAL_PLAN_RECOMMENDOR = 'medicalPlanRecommenderd',
  RESOURCES = 'resources',
}

export enum BasicInfoAdditionalContent {
  NEW_HIRE_CHECKLIST = 'newHireCheckList',
  HOW_INSURANCE_WORKS = 'howInsuranceWorks',
  DATES_AND_ELIGIBILITY = 'datesAndEligibility',
  RESOURCES = 'resources',
}

export const ADDITIONAL_CONTENT_OPTIONS: {
  [id in BasicInfoAdditionalContent]: {
    label: string;
    value: ContentTypes;
    route: RouteMap;
  };
} = {
  newHireCheckList: {
    label: 'New Hire Checklist',
    value: ContentTypes.NEW_HIRE_CHECKLIST,
    route: RouteMap.NEW_HIRE_CHECKLIST,
  },
  howInsuranceWorks: {
    label: 'How Insurance Works',
    value: ContentTypes.HOW_INSURANCE_WORKS,
    route: RouteMap.HOW_INSURANCE_WORKS,
  },
  datesAndEligibility: {
    label: 'Dates & Eligibility',
    value: ContentTypes.DATES_AND_ELIGIBILITY,
    route: RouteMap.DATES_AND_ELIGIBILITY,
  },
  resources: {
    label: 'Resources',
    value: ContentTypes.RESOURCES,
    route: RouteMap.RESOURCES,
  },
};

export const DUPLICATE_BENGUIDE_NAME_ERROR_CODE =
  'benguide.name.already.exists';

export const BENGUIDE_CREATE_ERRORS = [
  {
    code: 'benguide.invalid.name.required',
    message: 'Guide name is required',
  },
  {
    code: 'benguide.invalid.plan.year.required',
    message: 'Plan year is required',
  },
  {
    code: DUPLICATE_BENGUIDE_NAME_ERROR_CODE,
    message: 'Please correct all highlighted errors and try again.',
  },
];

export enum SectionName {
  HOME_OVERVIEW_TEXT = 'HOME_OVERVIEW_TEXT',
  HOME_BENEFIT_GUIDE_DESCRIPTION = 'HOME_BENEFIT_GUIDE_DESCRIPTION',
  HOME_MAIN_IMAGE = 'HOME_MAIN_IMAGE',
  BENEFITS_ENROLL_BUTTON = 'BENEFITS_ENROLL_BUTTON',
  CONTACT_SUPPORT_GENERAL = 'CONTACT_SUPPORT_GENERAL',
  CONTACT_SUPPORT_BENEFIT_CONSULTATION = 'CONTACT_SUPPORT_BENEFIT_CONSULTATION',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  NHC_TEXT = 'NHC_TEXT',
  NHC_BANNER_IMAGE = 'NHC_BANNER_IMAGE',
  FOUR_ONE_K_OVERVIEW_TEXT = 'FOUR_ONE_K_OVERVIEW_TEXT',
  FOUR_ONE_K_CONTRIBUTIONS_TEXT = 'FOUR_ONE_K_CONTRIBUTIONS_TEXT',
  FOUR_ONE_K_ADVANTAGES_TEXT = 'FOUR_ONE_K_ADVANTAGES_TEXT',
  RETIREMENT_401K = 'RETIREMENT_401K',
  HIW_HOW_DOES_A_DEDUCTIBLE_WORK = 'HIW_HOW_DOES_A_DEDUCTIBLE_WORK',
  HIW_WHAT_COUNTS_TOWARDS_A_DEDUCTIBLE = 'HIW_WHAT_COUNTS_TOWARDS_A_DEDUCTIBLE',
  HIW_WHAT_IS_COINSURANCE = 'HIW_WHAT_IS_COINSURANCE',
  HIW_COUNTS_TOWARDS_AN_OUT_OF_POCKET_LIMIT = 'HIW_COUNTS_TOWARDS_AN_OUT_OF_POCKET_LIMIT',
  HIW_WHAT_IS_IN_NETWORK_VS_OUT_NETWORK = 'HIW_WHAT_IS_IN_NETWORK_VS_OUT_NETWORK',
  HIW_WHAT_HAPPENS_IF_I_GO_OUT_OF_NETWORK = 'HIW_WHAT_HAPPENS_IF_I_GO_OUT_OF_NETWORK',
  HIW_CHANGING_MEDICAL_CARRIERS = 'HIW_CHANGING_MEDICAL_CARRIERS',
  HIW_PRIOR_AUTHORIZATION_OF_PRESCRIPTION = 'HIW_PRIOR_AUTHORIZATION_OF_PRESCRIPTION',
  HOLIDAY_TIME_OFF_IMAGE = 'HOLIDAY_TIME_OFF_IMAGE',
  FAMILY_AND_LEAVE_IMAGE = 'FAMILY_AND_LEAVE_IMAGE',
  ADDITIONAL_PERKS_IMAGE = 'ADDITIONAL_PERKS_IMAGE',
  DE_LIFE_EVENTS_TEXT = 'DE_LIFE_EVENTS_TEXT',
  DE_OE_TEXT = 'DE_OE_TEXT',
  DE_RULES_TEXT = 'DE_RULES_TEXT',
  DE_WAIVER_TEXT = 'DE_WAIVER_TEXT',
  DATES_AND_ELIGIBILITY = 'DATES_AND_ELIGIBILITY',
  HSA_OVERVIEW_TEXT = 'HSA_OVERVIEW_TEXT',
  HSA_ELIGIBILITY_TEXT = 'HSA_ELIGIBILITY_TEXT',
  HSA_EXPENSES_TEXT = 'HSA_EXPENSES_TEXT',
  HSA_ADVANTAGES_TEXT = 'HSA_ADVANTAGES_TEXT',
  HSA_CONSIDERATIONS_TEXT = 'HSA_CONSIDERATIONS_TEXT',
  HRA_OVERVIEW_TEXT = 'HRA_OVERVIEW_TEXT',
  HRA_ELIGIBILITY_TEXT = 'HRA_ELIGIBILITY_TEXT',
  HRA_EXPENSES_TEXT = 'HRA_EXPENSES_TEXT',
  HRA_ADVANTAGES_TEXT = 'HRA_ADVANTAGES_TEXT',
  HRA_CONSIDERATIONS_TEXT = 'HRA_CONSIDERATIONS_TEXT',
  COMMUTER_OVERVIEW_TEXT = 'COMMUTER_OVERVIEW_TEXT',
  COMMUTER_ELIGIBILITY_TEXT = 'COMMUTER_ELIGIBILITY_TEXT',
  COMMUTER_EXPENSES_TEXT = 'COMMUTER_EXPENSES_TEXT',
  COMMUTER_ADVANTAGES_TEXT = 'COMMUTER_ADVANTAGES_TEXT',
  COMMUTER_CONSIDERATIONS_TEXT = 'COMMUTER_CONSIDERATIONS_TEXT',
  FSA_OVERVIEW_TEXT = 'FSA_OVERVIEW_TEXT',
  FSA_ELIGIBILITY_TEXT = 'FSA_ELIGIBILITY_TEXT',
  FSA_EXPENSES_TEXT = 'FSA_EXPENSES_TEXT',
  FSA_ADVANTAGES_TEXT = 'FSA_ADVANTAGES_TEXT',
  FSA_CONSIDERATIONS_TEXT = 'FSA_CONSIDERATIONS_TEXT',
  LIMITED_FSA_OVERVIEW_TEXT = 'LIMITED_FSA_OVERVIEW_TEXT',
  LIMITED_FSA_ELIGIBILITY_TEXT = 'LIMITED_FSA_ELIGIBILITY_TEXT',
  LIMITED_FSA_EXPENSES_TEXT = 'LIMITED_FSA_EXPENSES_TEXT',
  LIMITED_FSA_ADVANTAGES_TEXT = 'LIMITED_FSA_ADVANTAGES_TEXT',
  LIMITED_FSA_CONSIDERATIONS_TEXT = 'LIMITED_FSA_CONSIDERATIONS_TEXT',
  DCAP_FSA_OVERVIEW_TEXT = 'DCAP_FSA_OVERVIEW_TEXT',
  DCAP_FSA_ELIGIBILITY_TEXT = 'DCAP_FSA_ELIGIBILITY_TEXT',
  DCAP_FSA_EXPENSES_TEXT = 'DCAP_FSA_EXPENSES_TEXT',
  DCAP_FSA_ADVANTAGES_TEXT = 'DCAP_FSA_ADVANTAGES_TEXT',
  DCAP_FSA_CONSIDERATIONS_TEXT = 'DCAP_FSA_CONSIDERATIONS_TEXT',
  RESOURCES_TEXT = 'RESOURCES_TEXT',
  TELEHEALTH = 'TELEHEALTH',
  RX_DELIVERY = 'RX_DELIVERY',
  RX_COUPONS = 'RX_COUPONS',
  ACCIDENT = 'ACCIDENT',
  CRITICAL_ILLNESS = 'CRITICAL_ILLNESS',
  HOSPITAL = 'HOSPITAL',
  CUSTOM_VOLUNTARY_BENEFIT = 'CUSTOM_VOLUNTARY_BENEFIT',
  WELLBEING = 'WELLBEING',
  HOLIDAY = 'HOLIDAY',
  FLEXIBLE_WORKING_HOURS = 'FLEXIBLE_WORKING_HOURS',
  OTHER_TIME_OFF = 'OTHER_TIME_OFF',
  PAID_TIME_OFF = 'PAID_TIME_OFF',
  SICK = 'SICK',
  BACKUP_CHILDCARE = 'BACKUP_CHILDCARE',
  BEREAVEMENT = 'BEREAVEMENT',
  FERTILITY = 'FERTILITY',
  FAMILY_AND_LEAVE_OTHER = 'FAMILY_AND_LEAVE_OTHER',
  PARENTAL_LEAVE = 'PARENTAL_LEAVE',
  BASIC_LIFE = 'BASIC_LIFE',
  BASIC_ADD = 'BASIC_ADD',
  BASIC_LIFE_AND_ADD = 'BASIC_LIFE_AND_ADD',
  LTD = 'LTD',
  STD = 'STD',
  VOLUNTARY_ADD = 'VOLUNTARY_ADD',
  VOLUNTARY_LIFE = 'VOLUNTARY_LIFE',
  VOLUNTARY_LIFE_ADD = 'VOLUNTARY_LIFE_ADD',
  VOLUNTARY_STD = 'VOLUNTARY_STD',
  VOLUNTARY_LTD = 'VOLUNTARY_LTD',
}

export const TIME_ZONES = [
  { id: 'America/New_York', name: 'Eastern Time (U.S.)', abbreviation: 'ET' },
  { id: 'America/Chicago', name: 'Central Time (U.S.)', abbreviation: 'CT' },
  { id: 'America/Denver', name: 'Mountain Time (U.S.)', abbreviation: 'MT' },
  { id: 'America/Phoenix', name: 'Arizona Time (U.S.)' },
  {
    id: 'America/Los_Angeles',
    name: 'Pacific Time (U.S.)',
    abbreviation: 'PT',
  },
  { id: 'America/Anchorage', name: 'Alaska Time (U.S.)', abbreviation: 'AK' },
  { id: 'Pacific/Honolulu', name: 'Hawaii Time (U.S.)', abbreviation: 'HAST' },
];

export const TIME_ZONES_IN_GMT = TIME_ZONES.map((timezone) => {
  const timezoneObj = getTimezone(timezone.id);
  let timezoneText = timezone.name;
  if (timezoneObj) {
    timezoneText = timezoneObj.dstOffsetStr;
  }
  return {
    value: timezone.id,
    label: timezone.name,
    timezone: parseInt(timezoneText.replace(':', '')),
    name: timezone.name,
  };
});

export const OE_MEETING_ALERT_MSGS = {
  MEETING_TIME_ERROR: 'Meeting end time cannot be in the past.',
  URL_ERROR: 'Please enter a valid URL.',
  GENERIC_ERROR: 'Please correct all highlighted errors and try again.',
  FILE_SIZE_ERROR: 'Video upload exceeds file size limit. Please try again.',
  FILE_TYPE_ERROR: 'Unsupported file type for OE Meeting recording.',
};
export const OE_MEETING_MINUTES_OPTIONS = [0, 15, 30, 45];
export const OE_MEETING_HOURS_OPTIONS = [0, 1, 2, 3, 4];

export const DURATION_TYPES = {
  HOURS: 'hours',
  MINUTES: 'minutes',
};

export const OE_MEETING_FORM_FIELDS = {
  TIMEZONE: 'timezone',
  DATE: 'meetingDate',
  TIME: 'meetingTime',
};
export const BASIC_INFO_EXIT_MESSAGES = {
  createPage: {
    title: 'Exit Guide?',
    confirmText: 'Yes - Exit Guide',
    cancelText: 'Cancel',
    message:
      'Your Benefits Guide has not been saved. Are you sure you want to exit this page?',
  },
  editPage: {
    title: 'Changes not Saved',
    confirmText: "Leave and Don't Save Changes",
    cancelText: 'Stay Here',
    message:
      'Your changes to Basic Guide Information have not been saved. Are you sure you want to exit this page?',
  },
};

export enum ImageType {
  EMPLOYER_LOGO = 'EMPLOYER_LOGO',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  BENGUIDE_THUMBNAIL = 'BENGUIDE_THUMBNAIL',
}

export const Section = {
  HOME: 'HOME',
  BENEFIT_ENROLL_BUTTON: 'BENEFIT_ENROLL_BUTTON',
};

export const HOME_CHECK_LIST = 'HOME_CHECK_LIST';

export const BASIC_INFO_ERROR_MESSAGES = {
  planYear: {
    fieldChange:
      'Changing the plan year will remove all existing plans from the guide',
  },
  benefitClasses: {
    fieldChange:
      'Changing the benefit classes may change the available plans for this guide and/or the employee contributions shown for medical, dental, or vision plans',
  },
  benefitTypes: {
    empty: 'You must have at least one benefit selected',
  },
  alertMessage: {
    planYear:
      'Changing the plan year or benefit classes may change or remove existing plans from the guide.',
    apiError: 'Error while creating Benefit Guide',
    formError: 'Please correct all highlighted errors and try again.',
  },
};

export const REMOVE_BENEFIT_MODAL_MESSAGE =
  'This section currently has plans added to it. Are you sure you want to remove it from the guide?';
export const REMOVE_CONTENT_MODAL_MESSAGE =
  'This section currently has customized content. Removing this from the guide wil lose all of its data. Are you sure want to remove this section from the guide?';

export const UPDATE_NHC_CONFIRMATION_TEXT =
  'Yes - Reset New Hire Checklist to Default Text';
export const UPDATE_NHC_CANCEL_TEXT =
  'No - Keep Current New Hire Checklist Text';
export const UPDATE_NHC_MODAL_DESCRIPTION =
  'Some of your changes may affect the New Hire Checklist. Would you like to reset the checklist to the default state to reflect your changes?';
export const NHC_REGENERATE_WARNING_MSG =
  'NOTE: Any New Hire Checklist text customizations will be overwritten if “Yes” is selected.';
export const PLAN_RECOMMENDER = 'PLAN_RECOMMENDER';

export const DEFAULT_COLOR = '#000000'; // Black color
export const DEFAULT_BACKGROUND_COLOR = '#f7f8fa'; // white color
