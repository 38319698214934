import { FC } from 'react';
import { Tabs } from 'antd';
import { SUPER_SCRIPTS } from 'modules/benefitsWellness/constants/BenefitConstants';
import { DEFAULT_TEXT } from 'modules/planRecommender/pages/constants';
import { getCardFieldValue } from 'utils/planRecommenderUtils/planRecommenderUtils';
import { hasSuperScripts } from 'modules/benefitsWellness/util';

import styles from './PlanCardBodyMobile.module.less';

const PlanCardBody: FC<any> = (props: any) => {
  const { mappings, selectedRate, plan, showSuperScripts = true } = props;

  const appendSuperScripts = (value: any, withElement = true) => {
    return SUPER_SCRIPTS.reduce((current: any, superscript: any) => {
      if (value.indexOf(superscript.strValue) >= 0 && withElement) {
        const elements = value.split(superscript.strValue);
        return elements.map((elem: any) =>
          elem && elem.length > 0 ? (
            <>
              {elem}
              <sup>{superscript.superScriptValue}</sup>
            </>
          ) : (
            <></>
          )
        );
      } else {
        if (value.indexOf(superscript.strValue) >= 0 && !withElement) {
          const search = new RegExp(superscript.strValue, 'g');
          return value.replace(search, `(${superscript.superScriptValue})`);
        } else {
          return current;
        }
      }
    }, value);
  };

  const clearSuperScripts = (value: any, withElement = true) => {
    return SUPER_SCRIPTS.reduce((current: any, superscript: any) => {
      if (value.indexOf(superscript.strValue) >= 0 && withElement) {
        const elements = value.split(superscript.strValue);
        return elements.map((elem: any) =>
          elem && elem.length > 0 ? <>{elem}</> : <></>
        );
      } else {
        if (value.indexOf(superscript.strValue) >= 0 && !withElement) {
          const search = new RegExp(superscript.strValue, 'g');
          return value.replace(search, `(${superscript.superScriptValue})`);
        } else {
          return current;
        }
      }
    }, value);
  };

  const formatFieldValue = (
    showSuperScripts: any,
    key: any,
    network: any,
    selectedRate: any,
    plan: any,
    appendSuperScripts: any,
    clearSuperScripts: any,
    isTooltip = false,
    hasDollar = true,
    shouldLowerCase = true
  ) => {
    const rate = selectedRate;
    const value = getCardFieldValue(
      plan,
      key,
      network,
      rate,
      hasDollar,
      shouldLowerCase
    );
    return showSuperScripts
      ? appendSuperScripts(
          !value || value.toString().trim().length === 0 ? DEFAULT_TEXT : value,
          !isTooltip
        )
      : clearSuperScripts(
          !value || value.toString().trim().length === 0 ? DEFAULT_TEXT : value,
          !isTooltip
        );
  };

  const generateCardRows = (showSuperScripts: any) => {
    const rows = [] as any[];
    mappings.forEach((mapping: any) => {
      mapping.forEach((key: any, index: any) => {
        if (index % 2) {
          const row = (
            <div key={Math.random()} className={styles.cardBodyRowCaontainer}>
              <div className={`${styles.cardBodyRow} ${styles.cardBodyLight}`}>
                <div
                  className={styles.cardBodyValue}
                  title={formatFieldValue(
                    showSuperScripts,
                    key,
                    'InNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts,
                    true
                  )}
                >
                  {formatFieldValue(
                    showSuperScripts,
                    key,
                    'InNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts
                  )}
                </div>
              </div>
            </div>
          );
          rows.push(row);
        } else {
          const row = (
            <div key={Math.random()} className={styles.cardBodyRowCaontainer}>
              <div className={`${styles.cardBodyRow} ${styles.cardBodyDark}`}>
                <div
                  className={styles.cardBodyValue}
                  title={formatFieldValue(
                    showSuperScripts,
                    key,
                    'InNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts,
                    true
                  )}
                >
                  {formatFieldValue(
                    showSuperScripts,
                    key,
                    'InNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts
                  )}
                </div>
              </div>
            </div>
          );
          rows.push(row);
        }
      });
      const seperator = (
        <div key={Math.random()} className={styles.cardBodyRowSeperator}></div>
      );
      if (mapping.length > 0) rows.push(seperator);
    });
    return rows;
  };

  const generateCardRowsOut = (showSuperScripts: any) => {
    const rows = [] as any[];
    mappings.forEach((mapping: any) => {
      mapping.forEach((key: any, index: any) => {
        if (index % 2) {
          const row = (
            <div key={Math.random()} className={styles.cardBodyRowCaontainer}>
              <div className={`${styles.cardBodyRow} ${styles.cardBodyLight}`}>
                <div
                  className={styles.cardBodyValue}
                  title={formatFieldValue(
                    showSuperScripts,
                    key,
                    'OutOfNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts,
                    true
                  )}
                >
                  {formatFieldValue(
                    showSuperScripts,
                    key,
                    'OutOfNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts
                  )}
                </div>
              </div>
            </div>
          );
          rows.push(row);
        } else {
          const row = (
            <div key={Math.random()} className={styles.cardBodyRowCaontainer}>
              <div className={`${styles.cardBodyRow} ${styles.cardBodyDark}`}>
                <div
                  className={styles.cardBodyValue}
                  title={formatFieldValue(
                    showSuperScripts,
                    key,
                    'OutOfNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts,
                    true
                  )}
                >
                  {formatFieldValue(
                    showSuperScripts,
                    key,
                    'OutOfNetwork',
                    selectedRate,
                    plan,
                    appendSuperScripts,
                    clearSuperScripts
                  )}
                </div>
              </div>
            </div>
          );
          rows.push(row);
        }
      });
      const seperator = (
        <div key={Math.random()} className={styles.cardBodyRowSeperator}></div>
      );
      if (mapping.length > 0) rows.push(seperator);
    });
    return rows;
  };

  const cardRows = generateCardRows(showSuperScripts);
  const availableSuperScripts = hasSuperScripts([plan], 'InNetwork');
  hasSuperScripts([plan], 'OutOfNetwork').forEach((a) => {
    availableSuperScripts.add(a);
  });
  const cardRowsOut = generateCardRowsOut(showSuperScripts);
  const availableSuperScriptsOut = hasSuperScripts([plan], 'InNetwork');
  hasSuperScripts([plan], 'OutOfNetwork').forEach((a) => {
    availableSuperScriptsOut.add(a);
  });

  return (
    <div>
      <div>
        <Tabs defaultActiveKey="1" className={styles.TabView}>
          <Tabs.TabPane tab="In-Network" key="1" className={styles.TabOne}>
            <div key={Math.random()} className={styles.planDetailCardBody}>
              {cardRows}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Out-of-Network" key="2" className={styles.TabTwo}>
            <div key={Math.random()} className={styles.planDetailCardBody}>
              {cardRowsOut}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default PlanCardBody;
