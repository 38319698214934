import { FC } from 'react';

import styles from './noPlansText.module.less';

type NoPlansTextProps = {
  text: string;
};

const NoPlansText: FC<NoPlansTextProps> = (props: NoPlansTextProps) => {
  const { text } = props;
  return <div className={styles.noPlans}>{text}</div>;
};

export default NoPlansText;
