import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import { Popconfirm, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'hooks/redux';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import iconWarning from 'images/icons/icon-warning.svg';
import { uploadSBC } from 'modules/benefitsWellness/slices/medicalSlice';
import {
  FORMAT_VALIDATE,
  MEDICAL_PLAN_DOCUMENT_TYPE,
  SIZE_VALIDATE,
} from 'modules/resources/constants';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

import styles from './planDocumentUploader.module.less';

type PlanDocumentUploaderProps = {
  onChange: Function;
  onRemove: Function;
  onFileNameClick: Function;
  allowedFileTypes?: string;
  maxFileSizeMB: number;
  isLoading?: boolean;
  isRemoved?: boolean;
  file?: File;
  docType: string;
  onValidateFails?: (validateSetting: string) => void;
  isDisabled?: boolean;
};
const emptyFile = new File([''], '');

const OVERWRIRE = 'OVERWRIRE';

const PlanDocumentUploader = forwardRef(
  (props: PlanDocumentUploaderProps, ref) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File>(emptyFile);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [lastSelectOption, setLastSelectOption] = useState<string>('');

    const dispatch = useAppDispatch();

    const {
      onChange,
      onRemove,
      onFileNameClick,
      allowedFileTypes,
      maxFileSizeMB,
      isLoading = false,
      isRemoved = false,
      docType,
      onValidateFails,
      isDisabled = false,
    } = props;

    useEffect(() => {
      if (isRemoved) {
        setFile(emptyFile);
      }
    }, [isRemoved]);

    useEffect(() => {
      if (props.file) {
        setFile(props.file);
      } else {
        setFile(emptyFile);
      }
    }, [props.file]);

    useImperativeHandle(ref, () => ({
      resetSelectedFile() {
        setFile(emptyFile);
      },
    }));

    const uploadFile = (): void => {
      if (fileInput.current !== null) {
        fileInput.current.click();
      }
    };

    const onSelectDocument = (event: any): void => {
      const file = event.target.files[0];
      const isallowedFileTypes = file.type === allowedFileTypes;
      if (!isallowedFileTypes) {
        if (onValidateFails) {
          onValidateFails(FORMAT_VALIDATE);
        }
        return;
      }
      const isallowedFileSize = file.size / 1024 / 1024 < maxFileSizeMB;
      if (!isallowedFileSize) {
        if (onValidateFails) {
          onValidateFails(SIZE_VALIDATE);
        }
        return;
      }
      if (!file) {
        return;
      } else {
        setFile(file);
        onChange(file);
        if (OVERWRIRE === lastSelectOption) {
          const data = new FormData();
          data.append('file', file);
          dispatch(uploadSBC(data));
        }
        setLastSelectOption('');
      }
    };

    const onInputClick = (
      event: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
      const element = event.target as HTMLInputElement;
      element.value = '';
    };

    const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

    return (
      <div className={styles.documentUploaderWrapper}>
        {MEDICAL_PLAN_DOCUMENT_TYPE.SBC.value === docType ? (
          <Popconfirm
            icon={
              <img
                src={iconWarning}
                alt="warning-icon"
                className={styles.iconWarning}
              />
            }
            onConfirm={() => {
              setLastSelectOption('');
              uploadFile();
            }}
            onCancel={() => {
              setLastSelectOption(OVERWRIRE);
              uploadFile();
            }}
            placement="bottomRight"
            okText="Only upload new SBC file (do not overwrite data)"
            cancelText="Upload new SBC file and overwrite any existing data"
            overlayClassName="planDocumentUploaderWrapper"
            title={
              <div>
                <div className="title">
                  Do you want to upload a new SBC file and import new data?
                </div>
                <div className="seconday-text">
                  Importing new plan data will overwrite any existing
                  information in:
                </div>
                <ul className="list">
                  <li>Deductibles & OOP Max</li>
                  <li>Medical Services</li>
                  <li>RX</li>
                </ul>
              </div>
            }
          >
            <SubmitButton
              className={styles.uploadButton}
              onClick={() => {
                setConfirmModalOpen(!confirmModalOpen);
              }}
              disabled={isDisabled}
            >
              + Choose File
            </SubmitButton>
          </Popconfirm>
        ) : (
          <SubmitButton
            className={styles.uploadButton}
            onClick={uploadFile}
            disabled={isDisabled}
          >
            + Choose File
          </SubmitButton>
        )}

        <div className={styles.fileLabel}>
          {isLoading && (
            <>
              <Spin indicator={antIcon} /> &nbsp;
            </>
          )}
          {file.name && !isLoading && (
            <div
              onClick={() => onFileNameClick(file)}
              className={styles.fileNameArea}
            >
              {file.name}
            </div>
          )}
        </div>

        <div className={styles.removeFile}>
          {isEmpty(file.name) ? (
            <span className={styles.noFileSelected}>No File Selected</span>
          ) : (
            !isLoading && (
              <ConfirmationModal
                title={
                  <div className={styles.cancelUploadBody}>
                    Are you sure you want to remove this file?
                    <p>
                      Removing the file will not affect any plan information,
                      but it will no longer be available to download from a
                      Digital Benefits Guide.
                    </p>
                  </div>
                }
                icon={
                  <img
                    src={iconWarning}
                    alt="warning-icon"
                    className={styles.iconWarning}
                  />
                }
                confirmModalTrigger="(remove)"
                onConfirm={() => onRemove()}
                okText="Yes, remove file"
                placement="topLeft"
              />
            )
          )}
        </div>
        <input
          type="file"
          name="file"
          onClick={onInputClick}
          onChange={onSelectDocument}
          ref={fileInput}
          accept={allowedFileTypes}
          hidden
        />
      </div>
    );
  }
);

PlanDocumentUploader.displayName = 'PlanDocumentUploader';
export default PlanDocumentUploader;
