import { forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

import StyledCollapse from 'components/Collpase/StyledCollapse';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import TabPanel, { TabPane } from 'components/TabPanel/TabPanel';
import { MediaWidth } from 'constants/mediaConstants';
import AddYourText from 'modules/benefitsWellness/components/AddYourText/AddYourText';
import PlanCardEditor from 'modules/benefitsWellness/components/PlanCardEditor';
import iconEdit from 'images/icon-edit.svg';
import SectionName from 'model/SectionName';
import { SectionName as Section } from 'modules/home/constants';

import styles from './planCardSectionEditor.module.less';

type Props = {
  onTabClick: (tab: string) => void;
  activeKey: string;
  editorSelectedTab: string;
  onEditorTabChange: (section: Section) => void;
  tabs: SectionName[];
  planContent: { [key: string]: string };
  isEditMode: boolean;
  resetAll: () => void;
  selectedEditorContent: string;
  onResetToDefault: () => void;
  selectedTabDefaultContent?: string;
  onUpdateContent: (content: string) => void;
  onResetToCurrent: (initialContent: any) => void;
  benguideId: string;
  onSaveContent: () => void;
  defaultActiveKey?: string;
  dataIndex?: number;
};

const PlanCardSectionEditor = forwardRef((props: Props, ref) => {
  const {
    defaultActiveKey,
    onTabClick,
    activeKey,
    tabs,
    planContent,
    isEditMode,
    resetAll,
    selectedEditorContent,
    onResetToDefault,
    selectedTabDefaultContent = '',
    onUpdateContent,
    onResetToCurrent,
    editorSelectedTab,
    onEditorTabChange,
    benguideId,
    onSaveContent,
    dataIndex,
  } = props;

  const isTablet = useMediaQuery({ maxDeviceWidth: MediaWidth.lg });

  if (isTablet) {
    return (
      <StyledCollapse className={styles.planContentCollapse}>
        {tabs.map((tab) => (
          <CollapsePanel key={tab.code} header={tab.title}>
            <div className={styles.content}>
              <ContentPreviewer htmlContent={planContent[tab.code]} />
            </div>
          </CollapsePanel>
        ))}
      </StyledCollapse>
    );
  }

  return (
    <TabPanel
      defaultActiveKey={defaultActiveKey}
      className={styles.tabPanel}
      onTabClick={onTabClick}
      activeKey={activeKey}
      destroyInactiveTabPane
    >
      {tabs.map((tab) => {
        const showAddTextBtn: boolean =
          isEmpty(planContent[tab.code]) && isEditMode;
        return (
          <TabPane
            key={tab.code}
            tab={tab.title}
            className={showAddTextBtn ? styles.showAddTextBtnContainer : ''}
          >
            <EditableHotspot
              dataTestId={`${tab.code ?? 'planTab'}${dataIndex}`}
              editText={showAddTextBtn ? '' : 'Edit'}
              editIcon={
                showAddTextBtn ? <></> : <img src={iconEdit} alt="edit" />
              }
              alwaysVisible={showAddTextBtn}
              className={styles.hotSpot}
              customModal={(
                visible: boolean,
                onClose: Function,
                editorKey: string
              ) => (
                <PlanCardEditor
                  isOpenModal={visible}
                  closeModal={() => {
                    resetAll();
                    onClose();
                  }}
                  editorContent={selectedEditorContent}
                  isResetEnabled={true}
                  resetToCurrentValue={() => {
                    onResetToCurrent({ ...planContent });
                  }}
                  resetToDefault={onResetToDefault}
                  defaultContent={selectedTabDefaultContent}
                  updateEditorContent={(data: string) => {
                    onUpdateContent(data);
                  }}
                  benguideId={benguideId}
                  sectionNames={tabs}
                  defaultSelectedOption={editorSelectedTab}
                  sectionEditEnabled
                  onSelectOptionChange={onEditorTabChange}
                  ref={ref}
                  onSubmit={onSaveContent}
                  editorKey={editorKey}
                />
              )}
            >
              {showAddTextBtn ? (
                <AddYourText />
              ) : (
                <div className={styles.content}>
                  <ContentPreviewer htmlContent={planContent[tab.code]} />
                </div>
              )}
            </EditableHotspot>
          </TabPane>
        );
      })}
    </TabPanel>
  );
});

PlanCardSectionEditor.displayName = 'PlanCardSectionEditor';

export default PlanCardSectionEditor;
