import BenConsultation from 'model/BenConsultation';
import CustomField from 'model/CustomField';
import SectionApiRequestPayload from 'model/SectionApiRequestPayload';
import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const benguideApiV2 = `${baseApi}/v2/benefit-guides`;

export const updateBenefitSection = async (
  benguideId: string,
  sectionApiRequest: SectionApiRequestPayload
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/benefit-page`,
    sectionApiRequest
  );
};

export const getMedicalSection = async (
  benguideId: string,
  revision: number
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=MEDICAL&revision=${revision}`
  );
};

export const getDentalSection = async (
  benguideId: string,
  revision: number
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=DENTAL&revision=${revision}`
  );
};

export const getVisionSection = async (
  benguideId: string,
  revision: number
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=VISION&revision=${revision}`
  );
};

export const getTelehalthSection = async (
  benguideId: string,
  revision: number
) => {
  if (revision) {
    return http.get(
      `${benguideApiV2}/${benguideId}/benefit-page?section=TELEHEALTH&revision=${revision}`
    );
  } else {
    return http.get(
      `${benguideApiV2}/${benguideId}/benefit-page?section=TELEHEALTH`
    );
  }
};

export const getWellbeingSection = async (
  benguideId: string,
  revision: number
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=WELLBEING&revision=${revision}`
  );
};

export const updateBenefitsConsultation = (
  benguideId: string,
  benConsultation: BenConsultation
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/benefit-consultation`,
    benConsultation
  );
};

export const getBenefitsConsultation = (benguideId: string) => {
  return http.get(`${benguideApiV2}/${benguideId}/benefit-consultation`);
};

export const updateCustomFields = async (
  benguideId: string,
  customFieldVOs: CustomField[]
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/update-custom-fields`,
    customFieldVOs
  );
};

export const updateCustomFieldValue = async (
  benguideId: string,
  customFieldVO: CustomField
) => {
  return await http.put(
    `${benguideApiV2}/${benguideId}/edit-custom-field_values`,
    customFieldVO
  );
};

export const getCustomFields = async (benefitGuideId: string) => {
  try {
    const response = await http.get(
      `${benguideApiV2}/${benefitGuideId}/custom-fields`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
