import { FC } from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import EmployerContribution from 'model/EmployerContribution';
import ContributionField from 'modules/taxAdvantaged/components/ContributionField/ContributionField';
import { EMPLOYER_FREQUENCY_PLAN_LABEL_MAPPING } from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';

import styles from './employerContributionSection.module.less';

type Props = {
  employerContribution: EmployerContribution;
  className?: string;
  tierCount?: number;
};

const EmployerContributionSection: FC<Props> = (props: Props) => {
  const { isEditMode } = useBenguide();
  const { isTablet, isMobile } = useAppSelector((state) => state.layout);
  const { employerContribution, className = '', tierCount = 0 } = props;

  const checkEmployerContribution = (): boolean => {
    if (tierCount == 0) {
      if (
        !employerContribution.employerEEContribution &&
        !employerContribution.employerESContribution &&
        !employerContribution.employerECContribution &&
        !employerContribution.employerEFContribution
      ) {
        if (isEditMode) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else if (tierCount > 0) {
      if (
        !employerContribution.employerEEContribution &&
        !employerContribution.employerEE1Contribution &&
        !employerContribution.employerEE2Contribution &&
        !employerContribution.employerEE3Contribution &&
        !employerContribution.employerEE4Contribution &&
        !employerContribution.employerEE5Contribution &&
        !employerContribution.employerEE6Contribution
      ) {
        if (isEditMode) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const checkIndividualContribution = (
    contribution: number | undefined
  ): boolean => {
    if (contribution !== 0 && contribution) {
      return true;
    } else {
      if (isEditMode) return true;
      return false;
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.contributionsGrid}>
        {employerContribution &&
          employerContribution.frequency &&
          checkEmployerContribution() && (
            <div>
              {!isTablet && (
                <div className={styles.frequency}>
                  {`Employer Contribution per ${
                    EMPLOYER_FREQUENCY_PLAN_LABEL_MAPPING[
                      employerContribution.frequency
                    ]
                  }`}
                </div>
              )}
              {tierCount === 0 ? (
                <div>
                  <Row gutter={[0, 16]}>
                    {checkIndividualContribution(
                      employerContribution.employerEEContribution
                    ) && (
                      <Col span={isTablet && !isMobile ? 6 : 12}>
                        <ContributionField
                          type="employer"
                          label="Just You"
                          value={employerContribution.employerEEContribution}
                        />
                      </Col>
                    )}
                    {checkIndividualContribution(
                      employerContribution.employerESContribution
                    ) && (
                      <Col span={isTablet && !isMobile ? 6 : 12}>
                        <ContributionField
                          type="employer"
                          label="You + Spouse"
                          value={employerContribution.employerESContribution}
                        />
                      </Col>
                    )}
                    {isTablet && !isMobile && (
                      <>
                        {checkIndividualContribution(
                          employerContribution.employerECContribution
                        ) && (
                          <Col span={isTablet && !isMobile ? 6 : 12}>
                            <ContributionField
                              type="employer"
                              label="You + Child(ren)"
                              value={
                                employerContribution.employerECContribution
                              }
                            />
                          </Col>
                        )}
                        {checkIndividualContribution(
                          employerContribution.employerEFContribution
                        ) && (
                          <Col span={isTablet && !isMobile ? 6 : 12}>
                            <ContributionField
                              type="employer"
                              label="You + Family"
                              value={
                                employerContribution.employerEFContribution
                              }
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                  {(!isTablet || isMobile) && (
                    <Row className={styles.row}>
                      {checkIndividualContribution(
                        employerContribution.employerECContribution
                      ) && (
                        <Col span={12}>
                          <ContributionField
                            type="employer"
                            label="You + Child(ren)"
                            value={employerContribution.employerECContribution}
                          />
                        </Col>
                      )}
                      {checkIndividualContribution(
                        employerContribution.employerEFContribution
                      ) && (
                        <Col span={12}>
                          <ContributionField
                            type="employer"
                            label="You + Family"
                            value={employerContribution.employerEFContribution}
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                </div>
              ) : (
                <div>
                  <Row gutter={[0, 16]}>
                    {checkIndividualContribution(
                      employerContribution.employerEEContribution
                    ) && (
                      <Col span={isTablet && !isMobile ? 6 : 12}>
                        <ContributionField
                          type="employer"
                          label="Just You"
                          value={employerContribution.employerEEContribution}
                        />
                      </Col>
                    )}
                    {checkIndividualContribution(
                      employerContribution?.employerEE1Contribution
                    ) && (
                      <Col span={isTablet && !isMobile ? 6 : 12}>
                        <ContributionField
                          type="employer"
                          label="You + 1"
                          value={employerContribution?.employerEE1Contribution}
                        />
                      </Col>
                    )}
                    {isTablet && !isMobile && (
                      <>
                        {(tierCount >= 3 ||
                          (employerContribution?.employerEE2Contribution ??
                            null) !== null) &&
                          checkIndividualContribution(
                            employerContribution.employerEE2Contribution
                          ) && (
                            <Col span={isTablet && !isMobile ? 6 : 12}>
                              <ContributionField
                                type="employer"
                                label="You + 2"
                                value={
                                  employerContribution.employerEE2Contribution
                                }
                              />
                            </Col>
                          )}
                        {(tierCount >= 4 ||
                          (employerContribution?.employerEE3Contribution ??
                            null) !== null) &&
                          checkIndividualContribution(
                            employerContribution.employerEE3Contribution
                          ) && (
                            <Col span={isTablet && !isMobile ? 6 : 12}>
                              <ContributionField
                                type="employer"
                                label="You + 3"
                                value={
                                  employerContribution.employerEE3Contribution
                                }
                              />
                            </Col>
                          )}
                      </>
                    )}
                  </Row>
                  {(!isTablet || isMobile) && (
                    <Row className={styles.nTierRow}>
                      {(tierCount >= 3 ||
                        (employerContribution?.employerEE2Contribution ??
                          null) !== null) &&
                        checkIndividualContribution(
                          employerContribution.employerEE2Contribution
                        ) && (
                          <Col span={12}>
                            <ContributionField
                              type="employer"
                              label="You + 2"
                              value={
                                employerContribution.employerEE2Contribution
                              }
                            />
                          </Col>
                        )}
                      {(tierCount >= 4 ||
                        (employerContribution?.employerEE3Contribution ??
                          null) !== null) &&
                        checkIndividualContribution(
                          employerContribution.employerEE3Contribution
                        ) && (
                          <Col span={12}>
                            <ContributionField
                              type="employer"
                              label="You + 3"
                              value={
                                employerContribution.employerEE3Contribution
                              }
                            />
                          </Col>
                        )}
                    </Row>
                  )}
                  <Row className={styles.nTierRow}>
                    {(tierCount >= 5 ||
                      (employerContribution?.employerEE4Contribution ??
                        null) !== null) &&
                      checkIndividualContribution(
                        employerContribution.employerEE4Contribution
                      ) && (
                        <Col span={12}>
                          <ContributionField
                            type="employer"
                            label="You + 4"
                            value={employerContribution.employerEE4Contribution}
                          />
                        </Col>
                      )}
                    {(tierCount >= 6 ||
                      (employerContribution?.employerEE5Contribution ??
                        null) !== null) &&
                      checkIndividualContribution(
                        employerContribution.employerEE5Contribution
                      ) && (
                        <Col span={12}>
                          <ContributionField
                            type="employer"
                            label="You + 5"
                            value={employerContribution.employerEE5Contribution}
                          />
                        </Col>
                      )}
                    {isTablet && !isMobile && (
                      <>
                        {(tierCount >= 7 ||
                          (employerContribution?.employerEE6Contribution ??
                            null) !== null) &&
                          checkIndividualContribution(
                            employerContribution.employerEE6Contribution
                          ) && (
                            <Col span={isTablet && !isMobile ? 6 : 12}>
                              <ContributionField
                                type="employer"
                                label="You + 6"
                                value={
                                  employerContribution.employerEE6Contribution
                                }
                              />
                            </Col>
                          )}
                      </>
                    )}
                  </Row>
                  {(!isTablet || isMobile) && (
                    <Row className={styles.nTierRow}>
                      {(tierCount >= 7 ||
                        (employerContribution?.employerEE6Contribution ??
                          null) !== null) &&
                        checkIndividualContribution(
                          employerContribution.employerEE6Contribution
                        ) && (
                          <Col span={12}>
                            <ContributionField
                              type="employer"
                              label="You + 6"
                              value={
                                employerContribution.employerEE6Contribution
                              }
                            />
                          </Col>
                        )}
                    </Row>
                  )}
                </div>
              )}
            </div>
          )}
      </div>
      {employerContribution.otherContribution && (
        <div className={styles.otherContribution}>
          {employerContribution.otherContribution}
        </div>
      )}
    </div>
  );
};

export default EmployerContributionSection;
