import { useCallback, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import EmailSuccessNotification from 'modules/planRecommender/components/EmailSuccessNotification/EmailSuccessNotification';
import PlanRecommenderCombinedSlider from 'modules/planRecommender/components/PlanRecommenderCombinedSlider/PlanRecommenderCombinedSlider';
import PlanRecommenderDropDown from 'modules/planRecommender/components/PlanRecommenderDropDown/PlanRecommenderDropDown';
import PlanRecommenderIncrementer from 'modules/planRecommender/components/PlanRecommenderIncrementer/PlanRecommenderIncrementer';
import PlanRecommenderOptions from 'modules/planRecommender/components/PlanRecommenderOptions/PlanRecommenderOptions';
import PlanRecommenderPlans from 'modules/planRecommender/components/PlanRecommenderPlans/PlanRecommenderPlans';
import PlanRecommenderResultsSender from 'modules/planRecommender/components/PlanRecommenderResultsSender/PlanRecommenderResultsSender';
import PlanRecommenderStep from 'modules/planRecommender/components/PlanRecommenderStep/PlanRecommenderStep';
import {
  PLAN_RECOMMENDER_QUESTIONS,
  PLAN_RECOMMENDER_QUESTIONS_TYPES,
} from 'modules/planRecommender/pages/constants';
import {
  fetchPlanRecommenderQuestions,
  fetchPlanRecommenderResult,
  nextStep,
  updatePlanRecommenderAnswers,
  updatePlanRecommenderAnswersEditDrawer,
} from 'modules/planRecommender/slices/planRecommenderSlice';
import PlanRecommenderEditDrawer from 'modules/planRecommender/components/PlanRecommenderEditDrawer/PlanRecommenderEditDrawer';
import useBenguide from 'hooks/benguide';
import 'styles/rsuite-drawer.scss';
import { http } from 'utils/httpUtil';
import { baseApi } from 'utils/apiUtil';
import { addOrReplace } from 'utils/commonUtil';
import OtherPlansSummary from 'modules/planRecommender/components/OtherPlansSummary/OtherPlansSummary';
import PlanRecommenderEditDrawerMobile from 'modules/planRecommender/components/PlanRecommenderEditDrawer/PlanRecommenderEditDrawerMobile/PlanRecommenderEditDrawerMobile';
import PlanRecommenderPlansMobile from 'modules/planRecommender/components/PlanRecommenderPlans/planRecommenderPlansMobile/PlanRecommenderPlansMobile';
import { trackEvents } from 'utils/initGA4';
import styles from './planRecommender.module.less';

const PlanRecommender = () => {
  const [isOpenMorePlansInfo, setOpenMorePlansInfo] = useState<boolean>(false);
  const [isEditDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const [employer] = useState<any>({});
  const [benefitKind] = useState<string>('MEDICAL');
  const benguide = useBenguide();
  const { medical } = useAppSelector((state: any) => state);
  const { medicalSection } = medical || {};
  const { plans } = medicalSection || {};

  const dispatch = useAppDispatch();
  const {
    stepCount,
    currentStep,
    questionsInProgress,
    questions,
    answers,
    answersInProgress,
    resultInProgress,
  } = useAppSelector((state) => state.planRecommender);

  const [drawerAnswerCopy, setDrawerAnswerCopy] = useState<
    { questionId: string; answer: any }[]
  >([]);

  useEffect(() => {
    if (isEditDrawerOpen) {
      setDrawerAnswerCopy(answers);
    }
    // eslint-disable-next-line
  }, [isEditDrawerOpen]);

  useEffect(() => {
    dispatch(
      fetchPlanRecommenderQuestions(
        benguide.masterId,
        benguide.latestRevision,
        benefitKind
      )
    );
  }, [dispatch, benguide.masterId, benguide.latestRevision, benefitKind]);

  const answerQuestion = useCallback(
    (questionId: any, answer: any) => {
      if (isEditDrawerOpen) {
        const newArray = addOrReplace(
          drawerAnswerCopy,
          { questionId, answer },
          'questionId'
        );
        setDrawerAnswerCopy(newArray);
      } else {
        dispatch(
          updatePlanRecommenderAnswers(
            benguide.masterId,
            benguide.latestRevision,
            benefitKind,
            questionId,
            answer,
            answers
          )
        );
      }
    },
    // eslint-disable-next-line
    [answers, benguide.latestRevision, benguide.masterId, dispatch, benefitKind]
  );

  const fetchResults = useCallback(() => {
    dispatch(
      fetchPlanRecommenderResult(
        benguide.masterId,
        benguide.latestRevision,
        benefitKind,
        answers,
        plans
      )
    );
  }, [
    benguide.latestRevision,
    benguide.masterId,
    dispatch,
    benefitKind,
    answers,
    plans,
  ]);

  const handleUpdateEditModal = () => {
    dispatch(
      updatePlanRecommenderAnswersEditDrawer(
        benguide.masterId,
        benguide.latestRevision,
        benefitKind,
        drawerAnswerCopy,
        plans
      )
    );
  };

  const renderNextStep = useCallback(() => {
    if (currentStep + 1 === stepCount) {
      fetchResults();
    }
    dispatch(nextStep());
  }, [currentStep, dispatch, fetchResults, stepCount]);

  const isStateValid = useCallback(() => {
    const stateAlias = {
      California: ['California-Northern', 'California-Southern'],
      'California-Northern': ['California'],
      'California-Southern': ['California'],
    } as any;

    let isValidState = false;
    const location = getCurrentAnswerByQuestionType(
      questions,
      PLAN_RECOMMENDER_QUESTIONS.LOCATION
    );
    if (!location) {
      return true;
    }
    plans.forEach((plan: any) => {
      const { states = [] } = plan;
      let updatedStates = states;
      states.forEach((state: any) => {
        if (stateAlias[state]) {
          updatedStates = updatedStates.concat(stateAlias[state]);
        }
      });
      if (updatedStates.length === 0) {
        isValidState = true;
      }
      if (states.length > 0) {
        isValidState = updatedStates.includes(location) || isValidState;
      }
    });
    return isValidState;
  }, [plans, questions]);

  const onEdit = useCallback(() => {
    setEditDrawerOpen(!isEditDrawerOpen);
    setDrawerAnswerCopy([]);
  }, [isEditDrawerOpen]);

  const toggleMorePlansInfo = useCallback(() => {
    setOpenMorePlansInfo(!isOpenMorePlansInfo);
  }, [isOpenMorePlansInfo]);

  const downloadResultPdf = useCallback(() => {
    const { masterId } = benguide;
    const ApiUrl = `${baseApi}/v2/benefit-guides/${masterId}/plan-recommender/MEDICAL/pdf`;
    http
      .post(ApiUrl, answers, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = decodeURIComponent(
          response.request
            .getResponseHeader('Content-Disposition')
            .split('filename=')[1]
        );
        if (typeof (window.navigator as any).msSaveBlob === 'function') {
          (window.navigator as any).msSaveBlob(response.data, fileName);
        } else {
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          trackEvents({
            category: 'Plan Recommender',
            action: 'download_plan_recommender_pdf',
            label: 'Download Plan Recommender PDF',
          });
        }
      });
  }, [answers, benguide]);

  const getComponentByAnswerType = useCallback(
    (type: string, props: any) => {
      const { benAdminUrl } = employer;
      switch (type) {
        case PLAN_RECOMMENDER_QUESTIONS_TYPES.DROPDOWN:
          return <PlanRecommenderDropDown {...props} />;
        case PLAN_RECOMMENDER_QUESTIONS_TYPES.SELECT:
          return <PlanRecommenderOptions {...props} />;
        case PLAN_RECOMMENDER_QUESTIONS_TYPES.SLIDER:
          return <PlanRecommenderCombinedSlider {...props} />;
        case PLAN_RECOMMENDER_QUESTIONS_TYPES.PLANS:
          const deviceWidth: number = window.innerWidth;
          return deviceWidth > 576 ? (
            <PlanRecommenderPlans
              {...props}
              benAdminUrl={benAdminUrl}
              benguide={benguide}
              employer={employer}
            />
          ) : (
            <PlanRecommenderPlansMobile
              {...props}
              benAdminUrl={benAdminUrl}
              benguide={benguide}
              employer={employer}
            />
          );
        case PLAN_RECOMMENDER_QUESTIONS_TYPES.INCREMENTER:
          return <PlanRecommenderIncrementer {...props} />;
        case PLAN_RECOMMENDER_QUESTIONS_TYPES.EMAIL:
          return <PlanRecommenderResultsSender {...props} />;
        case PLAN_RECOMMENDER_QUESTIONS_TYPES.EMAIL_SUCCESS:
          return <EmailSuccessNotification {...props} />;
        default:
          return (
            <div>
              Type Not Found
              <button className="next-button" onClick={nextStep}>
                Next
              </button>
            </div>
          );
      }
    },
    [benguide, employer]
  );

  const getSteps = useCallback(
    (isEditMode = false) => {
      let updatedQuestionsList = [] as any[];
      if (questions.length > 0) {
        updatedQuestionsList = [...questions];
        updatedQuestionsList.push({
          type: PLAN_RECOMMENDER_QUESTIONS_TYPES.PLANS,
        });
        updatedQuestionsList.push({
          type: PLAN_RECOMMENDER_QUESTIONS_TYPES.EMAIL,
        });
        updatedQuestionsList.push({
          type: PLAN_RECOMMENDER_QUESTIONS_TYPES.EMAIL_SUCCESS,
        });
      }

      const updatedQuestions = updatedQuestionsList.map((question: any) => {
        const { config, questionType } = question;
        if (
          config?.length > 0 &&
          questionType !== PLAN_RECOMMENDER_QUESTIONS.COMBINED_DOCTOR_VISITS
        ) {
          return { ...question, config: config[0] };
        }
        return question;
      });

      const defaultPropsForSteps = {
        onNextClick: renderNextStep,
        onUpdateAnswer: answerQuestion,
        plans: plans,
        onEdit: onEdit,
        toggleMorePlansInfo: toggleMorePlansInfo,
        editMode: isEditMode,
        isEditDrawerOpen: isEditDrawerOpen,
        isOpenMorePlansInfo: isOpenMorePlansInfo,
        onFetchResult: fetchResults,
        answers: answers,
        questions: updatedQuestions,
        questionsInProgress: questionsInProgress,
        isStateValid: isStateValid(),
        downloadResultPdf: downloadResultPdf,
        answersInProgress: answersInProgress,
        resultInProgress: resultInProgress,
      };

      return updatedQuestions.map((step, index: number) => {
        const { type } = step;
        const propsForStep = { ...defaultPropsForSteps, ...step };
        return (
          <PlanRecommenderStep
            key={index}
            step={index}
            currentStep={isEditMode ? index : currentStep}
          >
            {getComponentByAnswerType(type, {
              ...propsForStep,
              index: index,
            })}
          </PlanRecommenderStep>
        );
      });
    },
    [
      answerQuestion,
      answers,
      answersInProgress,
      currentStep,
      fetchResults,
      getComponentByAnswerType,
      isEditDrawerOpen,
      isOpenMorePlansInfo,
      onEdit,
      plans,
      questions,
      questionsInProgress,
      renderNextStep,
      toggleMorePlansInfo,
      isStateValid,
      downloadResultPdf,
      resultInProgress,
    ]
  );
  const deviceWidth: number = window.innerWidth;
  return (
    <div className={styles.planRecommenderWrapper}>
      {!isOpenMorePlansInfo && (
        <div className={styles.stepContainer}>{getSteps()}</div>
      )}
      {deviceWidth > 576 ? (
        <PlanRecommenderEditDrawer
          isdrawerOpen={isEditDrawerOpen}
          onClose={onEdit}
          onFetchResult={handleUpdateEditModal}
          questions={questions}
          answers={drawerAnswerCopy}
          isValidState={isStateValid()}
        >
          {getSteps(true)}
        </PlanRecommenderEditDrawer>
      ) : (
        <PlanRecommenderEditDrawerMobile
          isdrawerOpen={isEditDrawerOpen}
          onClose={onEdit}
          onFetchResult={handleUpdateEditModal}
          questions={questions}
          answers={drawerAnswerCopy}
          isValidState={isStateValid()}
        >
          {getSteps(true)}
        </PlanRecommenderEditDrawerMobile>
      )}

      <Modal
        visible={isOpenMorePlansInfo}
        onCancel={toggleMorePlansInfo}
        width="100vw"
        wrapClassName={styles.moreInfoModal}
        footer={false}
        style={{ top: 0 }}
        closable={false}
      >
        {questions.length > 0 && (
          <OtherPlansSummary
            isdrawerOpen={isOpenMorePlansInfo}
            onClose={toggleMorePlansInfo}
            questions={questions}
            isEditDrawerOpen={isEditDrawerOpen}
            questionsInProgress={questionsInProgress}
            downloadResultPdf={downloadResultPdf}
          />
        )}
      </Modal>
    </div>
  );
};

export const getCurrentAnswerByQuestionType = (
  questions: any,
  questionType: any
) => {
  if (questionType === PLAN_RECOMMENDER_QUESTIONS.DOCTOR_VISITS) {
    return (
      questions.find(
        (q: any) =>
          q.questionType === PLAN_RECOMMENDER_QUESTIONS.COMBINED_DOCTOR_VISITS
      ) || {}
    )?.currentAnswer?.DOCTOR_VISITS;
  } else if (questionType === PLAN_RECOMMENDER_QUESTIONS.SPECIALIST_VISITS) {
    return (
      questions.find(
        (q: any) =>
          q.questionType === PLAN_RECOMMENDER_QUESTIONS.COMBINED_DOCTOR_VISITS
      ) || {}
    )?.currentAnswer?.SPECIALIST_VISITS;
  } else {
    return (questions.find((q: any) => q.questionType === questionType) || [])
      .currentAnswer;
  }
};

export default PlanRecommender;
