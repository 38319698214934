import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import {
  fetchLifePlans,
  getLifeAndDisabilitySection,
} from 'modules/benefitsWellness/slices/lifeAndDisabilitySilce';
import UPClient from 'modules/clients/UPClient/UPClient';
import {
  BENGUIDE_NOTIFICATIONS,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  LIFE_AND_DISABILITY_CHANNEL,
  RELOAD_LIFE_AND_DISABILITY_PLAN,
  RELOAD_LIFE_AND_DISABILITY_PLAN_LIST,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';

const LifeAndDisabilityPlansChannel = () => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();

  return (
    <UPClient
      channel={LIFE_AND_DISABILITY_CHANNEL}
      subscribe={(event: string) => {
        switch (event) {
          case RELOAD_LIFE_AND_DISABILITY_PLAN:
            dispatch(
              getLifeAndDisabilitySection({
                benGuideId: benguide.masterId,
                revision: benguide.latestRevision,
              })
            );
            break;
          case RELOAD_LIFE_AND_DISABILITY_PLAN_LIST:
            dispatch(
              fetchLifePlans({
                employerId: benguide.employerId,
                planYearId: benguide.planYearId,
                benefitClasses: benguide.benefitClasses,
              })
            );
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default LifeAndDisabilityPlansChannel;
