import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { PLAN_RECOMMENDER_QUESTIONS_ICONS } from 'modules/planRecommender/pages/constants';
import styles from './planRecommenderOptions.module.less';

type PlanRecommenderOptionsProps = {
  editMode: boolean;
  index: number;
  title: string;
  subTitle: string;
  config: any;
  onUpdateAnswer: Function;
  id: any;
  footerText: string;
  onNextClick: MouseEventHandler<HTMLButtonElement>;
  currentAnswer: any;
};

const PlanRecommenderOptions: FC<PlanRecommenderOptionsProps> = (
  props: PlanRecommenderOptionsProps
) => {
  const {
    editMode,
    index,
    title,
    subTitle,
    config,
    onUpdateAnswer,
    id,
    footerText,
    onNextClick,
    currentAnswer,
  } = props;

  const { options = [] } = config;

  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    currentAnswer ? currentAnswer : []
  );

  useEffect(() => {
    setSelectedOptions(
      options
        .map((option: any, index: number) =>
          currentAnswer?.includes(option.value) ? index : undefined
        )
        .filter((option: any) => option !== undefined)
    );
  }, [setSelectedOptions, currentAnswer, options]);

  const toggleOptions = useCallback(
    (index: number) => {
      let updatedSelectedOptions = [...selectedOptions];
      if (options[index].multiple) {
        if (selectedOptions.includes(index)) {
          updatedSelectedOptions.splice(selectedOptions.indexOf(index), 1);
        } else {
          selectedOptions.forEach((option) => {
            if (!options[option].multiple) {
              updatedSelectedOptions.splice(selectedOptions.indexOf(option), 1);
            }
          });
          updatedSelectedOptions.push(index);
        }
      } else {
        if (!selectedOptions.includes(index)) {
          updatedSelectedOptions = [index];
        } else {
          updatedSelectedOptions = [];
        }
      }
      setSelectedOptions(updatedSelectedOptions);
      const answers = updatedSelectedOptions.map((option) => {
        return {
          choice: options[option].value,
        };
      });
      onUpdateAnswer(id, answers);
    },
    [id, onUpdateAnswer, options, selectedOptions]
  );

  return (
    <div className={`${styles.planRecommenderOptions} planRecommenderOptions`}>
      <div className={`${styles.content} content`}>
        <div className={`${styles.title} title`}>{`${
          editMode ? index + 1 + '.' : ''
        } ${title}`}</div>
        {subTitle && (
          <div className={`${styles.subTitle} subTitle`}>{subTitle}</div>
        )}

        <div className={`${styles.optionsWrapper} optionsWrapper`}>
          {options.map((option: any, index: number) => {
            const classes = [`${styles.option} option`];
            const isSelected = selectedOptions.includes(index);
            if (isSelected) {
              classes.push(styles.optionSelected);
            }
            return (
              <div
                key={index}
                className={classes.join(' ')}
                onClick={() => {
                  toggleOptions(index);
                }}
              >
                <img
                  src={
                    (PLAN_RECOMMENDER_QUESTIONS_ICONS as any)[option.iconClass]
                  }
                  alt=""
                />
                <div className={`${styles.labelWrapper} labelWrapper`}>
                  <div className={`${styles.label} label`}>{option.label}</div>
                </div>
              </div>
            );
          })}
        </div>
        {footerText && <div className={styles.footerText}>{footerText}</div>}
      </div>

      <div className={styles.nextBtnWrapper}>
        <button
          className={`${styles.nextButton} nextButton`}
          onClick={onNextClick}
          disabled={selectedOptions.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default PlanRecommenderOptions;
