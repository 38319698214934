import { FC } from 'react';

import { Alert, AlertProps } from 'antd';

import alertError from 'images/icons/alert-error.svg';
import alertInfo from 'images/icons/alert-info.svg';
import alertSuccess from 'images/icons/alert-success.svg';
import alertWarning from 'images/icons/alert-warning.svg';

import styles from './fixedAlertMessage.module.less';

interface Props extends AlertProps {
  closeAlert?: () => void;
  wrapperClassName?: string;
}

const ALERT_TYPE = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

export type AlertInfo = {
  type: 'success' | 'error' | 'info' | 'warning' | undefined;
  message: string;
};

const FixedAlertMessage: FC<Props> = (props: Props) => {
  const {
    type,
    message,
    icon,
    className = styles.alert,
    wrapperClassName = '',
  } = props;

  return (
    <div className={`${styles.fixedAlertWrapper} ${wrapperClassName}`}>
      <Alert
        type={type}
        message={message}
        icon={icon ? icon : getAlertIconByType(type)}
        showIcon
        className={className}
        closable={false}
      />
    </div>
  );
};

const getAlertIconByType = (type: string | undefined) => {
  switch (type) {
    case ALERT_TYPE.success:
      return <img src={alertSuccess} alt="success icon" />;
    case ALERT_TYPE.error:
      return <img src={alertError} alt="error icon" />;
    case ALERT_TYPE.info:
      return <img src={alertInfo} alt="info icon" />;
    case ALERT_TYPE.warning:
      return <img src={alertWarning} alt="warning icon" />;
  }
};

export default FixedAlertMessage;
