import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { Spin, Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import iconWarning from 'images/icons/icon-warning.svg';

import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { downloadDocument } from 'utils/fileUtil';

import styles from './documentUploader.module.less';

const oneMBInBytes = 1024 * 1024;

type DocumentUploaderProps = {
  onChange: Function;
  onRemoveFile: Function;
  allowedFileTypes?: string;
  isLoading?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  uploadSizeLimitInMB?: number;
  alternateFileName?: string | null;
  onValidateSize?: Function;
  onValidateType?: Function;
  downloadFileUrl?: any;
  onBeforeRemove?: Function;
  removeComponent?: React.ElementType;
  fileName?: string;
  isExpand?: boolean;
  hideSizePlaceholder?: boolean;
  invalidError?: string;
  isEdit?: boolean;
  planId?: string;
  planDocument?: string;
  benefitKind?: string;
  isOEMeeting?: boolean;
};

const DocumentUploader = forwardRef((props: DocumentUploaderProps, ref) => {
  useImperativeHandle(ref, () => ({
    reset() {
      setFileName('');
    },
    setFileName(name: string) {
      setFileName(name);
    },
  }));

  const [fileName, setFileName] = useState<string>(props.fileName || '');
  const [file, setFile] = useState<File>();

  const fileInput = useRef<HTMLInputElement>(null);

  const {
    onChange,
    allowedFileTypes,
    isLoading = false,
    disabled = false,
    invalid = false,
    isExpand = false,
    uploadSizeLimitInMB,
    onValidateSize,
    onValidateType,
    onRemoveFile,
    alternateFileName,
    downloadFileUrl,
    onBeforeRemove,
    removeComponent: RemoveComponent,
    hideSizePlaceholder = false,
    invalidError,
    isEdit,
    planId,
    planDocument,
    benefitKind,
    isOEMeeting,
  } = props;

  useEffect(() => {
    if (alternateFileName) {
      setFileName(alternateFileName);
    }
  }, [alternateFileName]);

  useEffect(() => {
    if (isEdit && props.fileName) {
      setFileName(props.fileName);
    }
  }, [props.fileName, isEdit]);

  const uploadFile = (): void => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onSelectDocument = (event: any): void => {
    const file = event.target.files[0];
    if (file) {
      if (
        allowedFileTypes &&
        allowedFileTypes.split(/[ ,]+/).indexOf(file.type) === -1
      ) {
        onValidateType && onValidateType();
        return;
      }

      if (
        uploadSizeLimitInMB &&
        file.size / oneMBInBytes > uploadSizeLimitInMB
      ) {
        onValidateSize && onValidateSize();
      } else {
        setFile(file);
        setFileName(file.name);
        onChange(file);
      }
    }
  };

  const onRemove = (): void => {
    if (onBeforeRemove) {
      const shouldRemove = onBeforeRemove();
      if (shouldRemove) {
        setFileName('');
        onRemoveFile();
      }
    } else {
      setFileName('');
      onRemoveFile();
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const openFileLink = () => {
    if (file) {
      const newObjectUrl = URL.createObjectURL(file);
      const downloadLink = document.createElement('a');
      downloadLink.href = newObjectUrl;
      downloadLink.download = file.name;
      downloadLink.setAttribute('target', '_blank');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else if (!isEmpty(planId) && !isEmpty(planDocument)) {
      downloadDocument(
        planId || '',
        planDocument || '',
        benefitKind || '',
        fileName
      );
    } else {
      const downloadLink = document.createElement('a');
      if (downloadFileUrl && !isLoading) {
        downloadLink.href = downloadFileUrl;
        downloadLink.setAttribute('target', '_blank');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  };

  const antIcon = <LoadingOutlined className={styles.loadingIcon} spin />;
  return (
    <div className={styles.documentUploaderWrapper}>
      <Row gutter={6}>
        <Col>
          <SubmitButton
            onClick={uploadFile}
            className={`${styles.fileUploadBtn} ${
              disabled ? styles.disabled : ''
            }  ${invalid ? styles.warning : ''}`}
            disabled={disabled}
          >
            + Choose File
          </SubmitButton>
        </Col>
        <Col span={1}>{isLoading && <Spin indicator={antIcon} />}</Col>
        {!isEmpty(fileName) && (
          <Col>
            <div
              className={`${styles.fileNameArea} ${
                isExpand ? styles.expandingArea : ''
              }`}
              onClick={openFileLink}
              title={fileName}
            >
              {fileName}
            </div>
          </Col>
        )}
        <Col>
          <div className={styles.removeFile}>
            {isEmpty(fileName) && isEmpty(alternateFileName) ? (
              !hideSizePlaceholder && (
                <div className={styles.placeholderText}>
                  {uploadSizeLimitInMB
                    ? uploadSizeLimitInMB + ' MB max file limit'
                    : 'No File Selected'}
                </div>
              )
            ) : RemoveComponent ? (
              <RemoveComponent />
            ) : (
              <ConfirmationModal
                title={
                  <div className={styles.cancelUploadBody}>
                    Are you sure you want to remove this file?
                    {!isOEMeeting && (
                      <p>
                        Removing the file will not affect any plan information,
                        but it will no longer be available to download from a
                        Digital Benefits Guide.
                      </p>
                    )}
                  </div>
                }
                icon={
                  <img
                    src={iconWarning}
                    alt="warning-icon"
                    className={styles.iconWarning}
                  />
                }
                confirmModalTrigger="(remove)"
                onConfirm={() => onRemove()}
                okText="Yes, remove file"
                placement="topLeft"
              />
            )}
          </div>
        </Col>
      </Row>
      <div>
        {!isEmpty(invalidError) && (
          <span className={styles.invalidError}>{invalidError}</span>
        )}
      </div>

      <input
        type="file"
        name="file"
        onClick={onInputClick}
        onChange={onSelectDocument}
        ref={fileInput}
        accept={allowedFileTypes}
        hidden
      />
    </div>
  );
});

DocumentUploader.displayName = 'DocumentUploader';

export default DocumentUploader;
