import { FC, useState, useCallback, useEffect } from 'react';
import { Button, Menu, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PageHeader from 'components/PageHeader/PageHeader';
import PlanSelectButton from 'components/PlanSelectButton/PlanSelectButton';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';

import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import {
  FSA_BENEFIT_KINDS,
  TaxAdvantagedSection,
  TAX_ADVANTAGED_BENEFIT_TYPE_INFO,
} from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { formatDataId } from 'utils/commonUtil';
import Plan from 'model/Plan';
import PlanReference from 'model/PlanReference';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import HSAPlanCard from 'modules/taxAdvantaged/components/HSAPlanCard/HSAPlanCard';
import HRAPlanCard from 'modules/taxAdvantaged/components/HRAPlanCard/HRAPlanCard';
import CommuterPlanCard from 'modules/taxAdvantaged/components/CommuterPlanCard/CommuterPlanCard';
import FsaPlanCard from 'modules/taxAdvantaged/components/FSAPlanCard/FsaPlanCard';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import {
  listHSAAssociatedPlans,
  listHSAPlans,
} from 'modules/taxAdvantaged/slices/hsaSlice';
import { listFSAPlans } from 'modules/taxAdvantaged/slices/fsaSlice';
import {
  listHRAAssociatedDentalPlans,
  listHRAAssociatedMedicalPlans,
  listHRAAssociatedVisionPlans,
  listHRAPlans,
} from 'modules/taxAdvantaged/slices/hraSlice';
import { listCommuterPlans } from 'modules/taxAdvantaged/slices/commuterSlice';
import { swapElements } from 'modules/benefitsWellness/util';
import {
  OPEN_ADD_NEW_PLAN_MODAL,
  TAX_ADVANTAGED_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import TaxAdvantagedPlansChannel from 'modules/clients/UPClient/channels/TaxAdvantagedPlansChannel';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';

import { BENEFIT_CATEGORY, LEGAL_DISCLAIMER } from 'constants/commonConstants';

import styles from './taxAdvantagedPlanOverview.module.less';

type Props = {
  section: TaxAdvantagedSection;
  planList: Plan[];
  planRefs: PlanReference[];
  selectedPlans: TaxAdvantageAccountsPlan[];
  onSelectPlans: (selectedPlans: Plan[], orderByPlanNames: boolean) => void;
  planListLoading: boolean;
  selectedPlansLoading: boolean;
  className?: string;
};

const TaxAdvantagedPlanOverview: FC<Props> = (props: Props) => {
  const {
    section,
    planList,
    onSelectPlans,
    planListLoading,
    selectedPlans,
    selectedPlansLoading,
    className = '',
  } = props;
  const [sortedTaxPlans, setSortedPlans] = useState<any[]>([]);
  const { isEditMode, planYearId, employerId, benefitClasses } = useBenguide();
  const upClient = useUPClient();
  const dispatch = useAppDispatch();
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  const { data: associatedPlans } = useAppSelector(
    (state) => state.hsa.associatedPlans
  );
  const { data: associatedMedicalPlans } = useAppSelector(
    (state) => state.hra.associatedMedicalPlans
  );
  const { data: associatedDentalPlans } = useAppSelector(
    (state) => state.hra.associatedDentalPlans
  );
  const { data: associatedVisionPlans } = useAppSelector(
    (state) => state.hra.associatedVisionPlans
  );

  const addedHRAPlansLoading = useAppSelector(
    (state) => state.hra.selectedPlans.inProgress
  );
  const addedHSAPlansLoading = useAppSelector(
    (state) => state.hsa.selectedPlans.inProgress
  );
  const addedFSAPlansLoading = useAppSelector(
    (state) => state.fsa.selectedPlans.inProgress
  );
  const addedCommuterPlansLoading = useAppSelector(
    (state) => state.commuter.selectedPlans.inProgress
  );

  const isAddedPlansLoading =
    addedHRAPlansLoading ||
    addedHSAPlansLoading ||
    addedFSAPlansLoading ||
    addedCommuterPlansLoading;

  const getAllPlans = useCallback(() => {
    if (section === TAX_ADVANTAGED_BENEFIT_TYPE_INFO.HSA.value) {
      dispatch(listHSAPlans(employerId, planYearId, benefitClasses));
    } else if (section === TAX_ADVANTAGED_BENEFIT_TYPE_INFO.FSA.value) {
      dispatch(listFSAPlans(employerId, planYearId, benefitClasses));
    } else if (section === TAX_ADVANTAGED_BENEFIT_TYPE_INFO.HRA.value) {
      dispatch(listHRAPlans(employerId, planYearId, benefitClasses));
    } else if (section === TaxAdvantagedSection.COMMUTER) {
      dispatch(listCommuterPlans(employerId, planYearId, benefitClasses));
    }
  }, [benefitClasses, dispatch, employerId, planYearId, section]);

  useEffect(() => {
    if (section === TAX_ADVANTAGED_BENEFIT_TYPE_INFO.HSA.value) {
      dispatch(listHSAAssociatedPlans(employerId, planYearId, 'HSA'));
    } else if (section === TAX_ADVANTAGED_BENEFIT_TYPE_INFO.HRA.value) {
      dispatch(listHRAAssociatedMedicalPlans(employerId, planYearId));
      dispatch(listHRAAssociatedDentalPlans(employerId, planYearId));
      dispatch(listHRAAssociatedVisionPlans(employerId, planYearId));
    }
  }, [selectedPlans, dispatch, employerId, planYearId, section]);

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const moveTop = (index: number) => {
    if (isAddedPlansLoading) return;
    if (index > 0) {
      const newPlans = swapElements(sortedTaxPlans, index, index - 1) as any[];
      setSortedPlans(newPlans);
      onSelectPlans(newPlans, false);
    }
  };

  const moveBottom = (index: number) => {
    if (isAddedPlansLoading) return;
    if (index < sortedTaxPlans.length - 1) {
      const newPlans = swapElements(sortedTaxPlans, index, index + 1) as any[];
      setSortedPlans(newPlans);
      onSelectPlans(newPlans, false);
    }
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  const getTierCount = () => {
    const keys = Object.keys(associatedPlans?.[0]?.rates ?? {});
    if (associatedPlans?.[0]?.rates?.[keys?.[0]]?.type === 'N_TIER') {
      return (
        associatedPlans?.[0]?.rates?.[keys?.[0]]?.ntierContributions
          ?.contributions?.length ?? 0
      );
    } else {
      return 0;
    }
  };

  const getHRATierCount = (plan: any) => {
    const selectedMedicalPlans = associatedMedicalPlans?.filter(
      (medPlan: any) => plan.selectedMedicalPlans?.includes(medPlan.id)
    );
    const selectedDentalPlans = associatedDentalPlans?.filter(
      (dentalPlan: any) => plan.selectedDentalPlans?.includes(dentalPlan.id)
    );
    const selectedVisionPlans = associatedVisionPlans?.filter(
      (visionPlan: any) => plan.selectedVisionPlans?.includes(visionPlan.id)
    );
    const medicalKeys = Object.keys(selectedMedicalPlans?.[0]?.rates ?? {});
    const dentalKeys = Object.keys(selectedDentalPlans?.[0]?.rates ?? {});
    const visionKeys = Object.keys(selectedVisionPlans?.[0]?.rates ?? {});
    if (
      !isEmpty(selectedMedicalPlans) &&
      selectedMedicalPlans?.[0]?.rates?.[medicalKeys?.[0]]?.type === 'N_TIER'
    ) {
      return (
        selectedMedicalPlans?.[0]?.rates?.[medicalKeys?.[0]]?.ntierContributions
          ?.contributions?.length ?? 0
      );
    } else if (
      !isEmpty(selectedDentalPlans) &&
      selectedDentalPlans?.[0]?.rates?.[dentalKeys?.[0]]?.type === 'N_TIER'
    ) {
      return (
        selectedDentalPlans?.[0]?.rates?.[dentalKeys?.[0]]?.ntierContributions
          ?.contributions?.length ?? 0
      );
    } else if (
      !isEmpty(selectedVisionPlans) &&
      selectedVisionPlans?.[0]?.rates?.[visionKeys?.[0]]?.type === 'N_TIER'
    ) {
      return (
        selectedVisionPlans?.[0]?.rates?.[visionKeys?.[0]]?.ntierContributions
          ?.contributions?.length ?? 0
      );
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setSortedPlans(selectedPlans);
  }, [selectedPlans]);

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={`${styles.wrapper} ${className}`}>
        <TaxAdvantagedPlansChannel />
        <BenefitGuideRefreshChannel
          benefitKind={BENEFIT_CATEGORY.TAX_ADVANTAGE.value}
        />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle={TAX_ADVANTAGED_BENEFIT_TYPE_INFO[section].label}
          pageHeaderIcon={
            <div className={styles.headerIcon}>
              {TAX_ADVANTAGED_BENEFIT_TYPE_INFO[section].headerIcon}
            </div>
          }
          managePlansButton={
            isEditMode &&
            selectedPlans.length > 0 && (
              <EditableHotspot
                alwaysVisible={true}
                customModal={(visible: boolean, onClose: Function) => (
                  <AddOrRemovePlans
                    visible={visible}
                    onClose={onClose}
                    title={`${TAX_ADVANTAGED_BENEFIT_TYPE_INFO[section].label}`}
                    allPlans={planList}
                    isLoading={planListLoading}
                    onSave={onSelectPlans}
                    addedPlanList={selectedPlans}
                    reloadPlans={getAllPlans}
                    channel={TAX_ADVANTAGED_PLAN_CHANNEL}
                    actionContent={
                      TAX_ADVANTAGED_BENEFIT_TYPE_INFO[section].value !==
                      TaxAdvantagedSection.FSA ? (
                        <EditableHotspot
                          alwaysVisible={false}
                          useChannel={{
                            client: upClient,
                            channel: TAX_ADVANTAGED_PLAN_CHANNEL,
                            event: OPEN_ADD_NEW_PLAN_MODAL,
                            data: { benefitKind: section, planYearId },
                          }}
                        >
                          <Button
                            disabled={isSaveDisable}
                            onClick={(e) => e.preventDefault()}
                          >
                            Create New Plan
                          </Button>
                        </EditableHotspot>
                      ) : (
                        <PlanSelectButton
                          selectLabel="Create New Plan"
                          overlay={getFSAPlanMenu(upClient, planYearId)}
                          className={isEmpty(planList) ? styles.darkButton : ''}
                        />
                      )
                    }
                    showAlert={showAlert}
                  />
                )}
              >
                <Button>+ Add or Remove Plans</Button>
              </EditableHotspot>
            )
          }
        />
        <div>
          {selectedPlans.length === 0 && selectedPlansLoading ? (
            <Spin />
          ) : (
            isEditMode &&
            selectedPlans.length === 0 && (
              <AddOrRemovePlans
                visible
                withoutModal
                title={`${TAX_ADVANTAGED_BENEFIT_TYPE_INFO[section].label}`}
                allPlans={planList}
                isLoading={planListLoading}
                onSave={onSelectPlans}
                addedPlanList={selectedPlans}
                reloadPlans={getAllPlans}
                channel={TAX_ADVANTAGED_PLAN_CHANNEL}
                actionContent={
                  TAX_ADVANTAGED_BENEFIT_TYPE_INFO[section].value !==
                  TaxAdvantagedSection.FSA ? (
                    <EditableHotspot
                      alwaysVisible={false}
                      useChannel={{
                        client: upClient,
                        channel: TAX_ADVANTAGED_PLAN_CHANNEL,
                        event: OPEN_ADD_NEW_PLAN_MODAL,
                        data: { benefitKind: section, planYearId },
                      }}
                    >
                      <Button
                        onClick={(e) => e.preventDefault()}
                        className={isEmpty(planList) ? styles.darkButton : ''}
                      >
                        Create New Plan
                      </Button>
                    </EditableHotspot>
                  ) : (
                    <PlanSelectButton
                      selectLabel="Create New Plan"
                      overlay={getFSAPlanMenu(upClient, planYearId)}
                      className={isEmpty(planList) ? styles.darkButton : ''}
                    />
                  )
                }
                showAlert={showAlert}
              />
            )
          )}
          {sortedTaxPlans.length > 0 && (
            <>
              {sortedTaxPlans.map((plan, index) => (
                <div key={index} className={styles.planCardWrapper}>
                  {section === TaxAdvantagedSection.HSA && (
                    <HSAPlanCard
                      tierCount={getTierCount()}
                      dataIndex={index}
                      plan={plan}
                      moveTop={() => moveTop(index)}
                      moveBottom={() => moveBottom(index)}
                      hideTop={index === 0}
                      hideBottom={index === sortedTaxPlans?.length - 1}
                      renderReorder={sortedTaxPlans.length > 1}
                    />
                  )}
                  {section === TaxAdvantagedSection.HRA && (
                    <HRAPlanCard
                      plan={plan}
                      dataIndex={index}
                      tierCount={getHRATierCount(plan)}
                      moveTop={() => moveTop(index)}
                      moveBottom={() => moveBottom(index)}
                      hideTop={index === 0}
                      hideBottom={index === sortedTaxPlans?.length - 1}
                      renderReorder={sortedTaxPlans.length > 1}
                    />
                  )}
                  {section === TaxAdvantagedSection.COMMUTER && (
                    <CommuterPlanCard
                      plan={plan}
                      dataIndex={index}
                      moveTop={() => moveTop(index)}
                      moveBottom={() => moveBottom(index)}
                      hideTop={index === 0}
                      hideBottom={index === sortedTaxPlans?.length - 1}
                      renderReorder={sortedTaxPlans.length > 1}
                    />
                  )}
                  {section === TaxAdvantagedSection.FSA && (
                    <FsaPlanCard
                      plan={plan}
                      dataIndex={index}
                      moveTop={() => moveTop(index)}
                      moveBottom={() => moveBottom(index)}
                      hideTop={index === 0}
                      hideBottom={index === sortedTaxPlans?.length - 1}
                      renderReorder={sortedTaxPlans.length > 1}
                    />
                  )}
                </div>
              ))}
              <div className={styles.disclaimer}>{LEGAL_DISCLAIMER}</div>
            </>
          )}
          {isEmpty(selectedPlans) && !isEditMode && !selectedPlansLoading && (
            <NoPlansView className={styles.noPlanView} />
          )}
        </div>
      </div>
    </NotificationContextProvider>
  );
};

export default TaxAdvantagedPlanOverview;

const getFSAPlanMenu = (
  upClient: { postMessage: Function },
  planYearId: string
) => {
  return (
    <Menu>
      {FSA_BENEFIT_KINDS.map((fsaBenefitData) => (
        <Menu.Item
          key={fsaBenefitData.benefitKind}
          data-id={formatDataId(`${fsaBenefitData.benefitKind}-optn`)}
        >
          <EditableHotspot
            alwaysVisible={false}
            useChannel={{
              client: upClient,
              channel: TAX_ADVANTAGED_PLAN_CHANNEL,
              event: OPEN_ADD_NEW_PLAN_MODAL,
              data: { benefitKind: fsaBenefitData.value, planYearId },
            }}
          >
            {fsaBenefitData.label}
          </EditableHotspot>
        </Menu.Item>
      ))}
    </Menu>
  );
};
