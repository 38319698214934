import {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import { Col, Row } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal/Modal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { NotificationContext } from 'context/NotificationContext';
import iconWarning from 'images/information-banner.svg';
import iconEdit from 'images/icon-edit.svg';
import { ReactComponent as RemoveIcon } from 'images/icons/icon-remove.svg';

import styles from './halfScreenModal.module.less';

interface Props extends ModalProps {
  children: ReactNode;
  resetText?: string;
  cancelText?: string;
  onReset?: Function;
  onSubmit?: Function;
  footerStyles?: string;
  confirmModalShow?: boolean;
  isResetEnabled?: boolean;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  increaseResetSpacing?: boolean;
  editableTitle?: boolean;
  onChangeTitle?: Function;
  removeContentText?: string;
  onRemoveContent?: Function;
  disabledRemoveButton?: boolean;
}

const HalfScreenModal: FC<Props> = (props: Props) => {
  const notificationFlag = useContext(NotificationContext);
  const {
    width,
    children,
    resetText = 'Reset to default',
    cancelText = 'Cancel',
    okText = 'Done',
    onCancel,
    onReset,
    className = '',
    onSubmit,
    footerStyles = '',
    confirmModalShow = false,
    isResetEnabled = true,
    isSubmitDisabled = false,
    isLoading = false,
    increaseResetSpacing = '',
    visible,
    editableTitle,
    title,
    onChangeTitle,
    removeContentText,
    onRemoveContent,
    disabledRemoveButton,
    ...rest
  } = props;

  const [isActiveEditTitle, setActiveEditTitle] = useState<boolean>(false);
  const [isValidationTriggered, triggerValidations] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState<number>(0);

  useEffect(() => {
    setInputWidth(
      title !== undefined && (title as String).length > 24
        ? (title as String).length
        : 24
    );
  }, [title]);

  const footerComponent = (
    <Row className={`${styles.footerWrapper} ${footerStyles}`}>
      {removeContentText && (
        <Col span={7}>
          <LinkButton
            disabled={notificationFlag || disabledRemoveButton}
            onClick={() => onRemoveContent && onRemoveContent()}
            className={`${
              disabledRemoveButton || notificationFlag
                ? styles.disabledRemoveButton
                : styles.removeButton
            }`}
          >
            <RemoveIcon className={styles.removeIcon} />
            {removeContentText}
          </LinkButton>
        </Col>
      )}
      {onReset && !confirmModalShow && (
        <Col span={7}>
          <LinkButton
            onClick={() => onReset()}
            className={`${styles.resetButton} resetButton`}
          >
            {resetText}
          </LinkButton>
        </Col>
      )}
      {onReset && confirmModalShow && (
        <ConfirmationModal
          title={
            <div className={styles.cancelResetBody}>
              Are you sure you want to reset to default content?
              <p>Your customized content for this section will be removed.</p>
            </div>
          }
          icon={
            <img
              src={iconWarning}
              alt="warning-icon"
              className={styles.iconWarning}
            />
          }
          confirmModalTrigger={
            <Col span={7}>
              <LinkButton
                className={`${styles.resetButton} ${
                  isResetEnabled && !notificationFlag
                    ? ''
                    : styles.disabledReset
                } ${increaseResetSpacing ? styles.incPadding : ''}`}
                disabled={!isResetEnabled || notificationFlag}
              >
                {resetText}
              </LinkButton>
            </Col>
          }
          onConfirm={() => onReset()}
          okText="Yes, reset to default"
          cancelText="Nevermind"
          placement="bottomLeft"
        />
      )}
      <Col className={styles.cancelBtnWrapper}>
        <SubmitButton className={styles.cancelButton} onClick={onCancel}>
          {cancelText}
        </SubmitButton>
      </Col>
      <Col>
        <SubmitButton
          className={styles.okButton}
          onClick={() => {
            if (editableTitle) {
              if (isEmpty((title as any)?.trim())) {
                triggerValidations(true);
              } else {
                onSubmit && onSubmit();
              }
            } else {
              onSubmit && onSubmit();
            }
          }}
          type="primary"
          loading={isLoading}
          disabled={isSubmitDisabled || notificationFlag}
        >
          {okText}
        </SubmitButton>
      </Col>
    </Row>
  );

  const outSideClick = useCallback(
    (event: any) => {
      const path = event?.composedPath ? event?.composedPath() : event?.path;
      if (
        editableTitle &&
        !(path || [])
          .map((element: any) => element.classList)
          .filter((classList: string[]) => !isEmpty(classList))
          .reduce((a: string[], b: string[]) => {
            return [...a, ...b];
          }, [])
          .some((cssClass: string) => cssClass === 'titleField')
      ) {
        triggerValidations(true);
        if (isEmpty((title as any)?.trim())) {
          triggerValidations(true);
        } else {
          setActiveEditTitle(false);
        }
      }
    },
    [editableTitle, title]
  );

  useEffect(() => {
    if (visible) {
      document.addEventListener('click', outSideClick, true);
    }

    return () => {
      document.removeEventListener('click', outSideClick, true);
    };
  }, [visible, outSideClick]);

  return (
    <Modal
      centered
      width={width}
      wrapClassName={`${styles.modalWrapper} ${className}`}
      className={styles.modalContent}
      maskClosable={false}
      footer={footerComponent}
      onCancel={onCancel}
      visible={visible}
      title={
        editableTitle ? (
          <div
            className={`titleField ${styles.editableTitle} ${
              isActiveEditTitle ? styles.active : ''
            } ${
              isValidationTriggered && isEmpty((title as any)?.trim())
                ? styles.invalid
                : ''
            }`}
          >
            {isActiveEditTitle ? (
              <>
                <input
                  maxLength={40}
                  style={{ width: inputWidth + 'ch' }}
                  value={title as any}
                  onChange={({ target }) => {
                    setInputWidth(
                      target.value.length > 24 ? target.value.length : 24
                    );
                    onChangeTitle && onChangeTitle(target.value);
                  }}
                />
                <label>Title is required</label>
              </>
            ) : (
              title
            )}
            {!isActiveEditTitle && (
              <img
                src={iconEdit}
                alt="edit"
                onClick={() => {
                  setActiveEditTitle(true);
                }}
              />
            )}
          </div>
        ) : (
          title
        )
      }
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default HalfScreenModal;
