import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import union from 'lodash/union';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { RootState } from 'app/store';
import * as MedicalPlanService from 'modules/benefitsWellness/services/MedicalPlanService';
import * as PlanService from 'modules/auth/services/PlanService';
import * as BenguideService from 'services/BenGuideService';
import {
  getCustomFields,
  updateCustomFieldValue,
} from 'modules/benefitsWellness/services/BenGuideService';
import MedicalSection from 'model/MDVSection';
import MDVPlan from 'model/MDVPlan';
import PlanReference from 'model/PlanReference';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import {
  setCloseModalsInUP,
  setPlanRecommenderState,
} from 'modules/auth/slices/benguideSlice';
import CustomField from 'model/CustomField';
import { PLAN_RECOMMENDER } from 'modules/home/constants';
import { reloadDentalSection } from './dentalSlice';
import { reloadVisionSection } from './visionSlice';

const SECTION_MEDICAL = 'MEDICAL';

type MedicalState = {
  planList: {
    inProgress: boolean;
    error: any;
    data: MDVPlan[];
  };
  medicalSection: {
    enable: boolean;
    plans: MDVPlan[];
  };
  inProgress: boolean;
  filteredPlanList: MDVPlan[];
  planProviders: string[];
  planTypes: string[];
  appliedFilters: {
    providers: object;
    types: object;
  };
  documentUploadInProgress: boolean;
  planRecommenderUpdateInProgress: boolean;
  retrievePlanDocumentInProgress: boolean;
  sbcUpload: {
    inProgress: boolean;
    jobId: string | null;
    status: string;
    error: any;
  };
};

const initialState = {
  planList: {
    inProgress: false,
    error: null,
    data: [],
  },
  medicalSection: {
    enable: false,
    plans: [],
  },
  inProgress: false,
  filteredPlanList: [],
  planProviders: [],
  planTypes: [],
  appliedFilters: {
    providers: {},
    types: {},
  },
  documentUploadInProgress: false,
  planRecommenderUpdateInProgress: false,
  retrievePlanDocumentInProgress: false,
  sbcUpload: {
    inProgress: false,
    jobId: null,
    status: 'INITIALIZING',
    error: null,
  },
} as MedicalState;

const medicalSlice = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    planListFetchingStarted: (state) => {
      state.planList.inProgress = true;
      state.planList.error = null;
    },
    planListFetchingCompleted: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = null;
      state.planList.data = payload;
    },
    planListFetchingFailed: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = payload;
      state.planList.data = [];
    },
    medicalSectionUpdatingCompleted: (state, { payload }) => {
      state.medicalSection = payload;
      state.filteredPlanList = payload.plans;
      const { plans = [] } = payload;
      state.planProviders = union(
        plans
          .filter((plan: MDVPlan) => !isEmpty(plan.benefitCarrier))
          .map((plan: MDVPlan) => plan.benefitCarrier?.name)
      );
      state.planTypes = union(
        plans
          .filter((plan: MDVPlan) => !isEmpty(plan.type))
          .map((plan: MDVPlan) => plan.type)
      );
    },
    filterUpdatingCompleted: (state, { payload }) => {
      state.appliedFilters = payload;
      const { plans = [] } = state.medicalSection;
      const appliedProviders = Object.keys(payload.providers).filter(
        (provider) => payload.providers[provider]
      );
      const appliedTypes = Object.keys(payload.types).filter(
        (type) => payload.types[type]
      );

      state.filteredPlanList = plans.filter((plan) => {
        const { type, benefitCarrier } = plan;
        const { name } = benefitCarrier || {};
        let validProvider = true;
        let validCarrier = true;
        if (!isEmpty(appliedProviders)) {
          validProvider = appliedProviders.includes(name);
        }
        if (!isEmpty(appliedTypes)) {
          validCarrier = appliedTypes.includes(type);
        }
        return validProvider && validCarrier;
      });
    },
    documentUploadStarted: (state) => {
      state.documentUploadInProgress = true;
    },
    planRecommenderUpdateStarted: (state) => {
      state.planRecommenderUpdateInProgress = true;
    },
    documentUploadCompleted: (state) => {
      state.documentUploadInProgress = false;
    },
    retrievePlanDocumentStarted: (state) => {
      state.retrievePlanDocumentInProgress = true;
    },
    retrievePlanDocumentCompleted: (state) => {
      state.retrievePlanDocumentInProgress = false;
    },
    sbcUploadingInprogress: (state) => {
      state.sbcUpload.inProgress = true;
      state.sbcUpload.status = 'INITIALIZING';
      state.sbcUpload.jobId = null;
    },
    sbcUploadingCompleted: (state, action) => {
      state.sbcUpload.inProgress = false;
      state.sbcUpload.jobId = action.payload;
    },
    planRecommenderUpdatingCompleted: (state) => {
      state.planRecommenderUpdateInProgress = false;
    },
    sbcUploadingFailed: (state, action) => {
      state.sbcUpload.inProgress = false;
      state.sbcUpload.error = action.payload;
    },
  },
});

export const {
  planListFetchingStarted,
  planListFetchingCompleted,
  planListFetchingFailed,
  medicalSectionUpdatingCompleted,
  filterUpdatingCompleted,
  documentUploadStarted,
  documentUploadCompleted,
  retrievePlanDocumentStarted,
  retrievePlanDocumentCompleted,
  sbcUploadingInprogress,
  sbcUploadingCompleted,
  sbcUploadingFailed,
  planRecommenderUpdateStarted,
  planRecommenderUpdatingCompleted,
} = medicalSlice.actions;

export default medicalSlice.reducer;

export const getMedicalPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planListFetchingStarted());
    MedicalPlanService.getMedicalPlanList(
      employerId,
      planYearId,
      benefitClasses
    )
      .then(({ data }: AxiosResponse) => {
        const { content } = data;
        const updatedContent = setDefaultToCustomServices(content);
        dispatch(planListFetchingCompleted(updatedContent));
      })
      .catch((error) => {
        dispatch(planListFetchingFailed(error));
      });
  };
};

export const updateMedicalSection: any = (
  medicalSection: MedicalSection,
  benGuideMasterId?: string,
  closeUpPopUp: boolean = true
) => {
  return async (dispatch: Dispatch) => {
    const medicalPlanPromises = medicalSection.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return PlanService.getPlans('medicals', planId || id, revision);
    });
    let plans = (await Promise.all(medicalPlanPromises)).map(
      (plan: any) => plan.data as MDVPlan
    );

    if (benGuideMasterId) {
      const response = await getCustomFields(benGuideMasterId);
      const customFields = response.data;
      const updatedPlans = setPlanCustomFields(plans, customFields);
      plans = setDefaultToCustomServices(updatedPlans);
    } else {
      plans = setDefaultToCustomServices(plans);
    }

    dispatch(medicalSectionUpdatingCompleted({ ...medicalSection, plans }));
    if (closeUpPopUp) {
      dispatch(setCloseModalsInUP(true));
    }
  };
};

export const saveAndReloadMedicalSection = (
  benguideId: string,
  selectedPlans: MDVPlan[],
  orderByPlanNames: boolean
) => {
  const planReferences = selectedPlans.map((plan) => {
    return {
      planId: plan.id,
      planName: plan.name,
      startDate: plan.startDate,
      endDate: plan.endDate,
      isArchived: plan.archived,
      revision: plan.revision,
      groups: plan.groups,
      benefitCategory: BENEFIT_CATEGORY.MEDICAL.value,
      benefitKind: BENEFIT_CATEGORY.MEDICAL.value,
      carrier: {
        id: get(plan, 'carrier.id', null),
        name: get(plan, 'carrier.name', null),
      },
      benefitCarrier: {
        id: get(plan, 'benefitCarrier.id', null),
        name: get(plan, 'benefitCarrier.name', null),
      },
    } as PlanReference;
  });

  return async (dispatch: Dispatch) => {
    const sectionApiRequest = {
      plans: planReferences,
      sectionName: SECTION_MEDICAL,
      orderByPlanNames: orderByPlanNames,
    } as AddPlanApiRequestPayload;
    await BenguideService.addPlans(benguideId, sectionApiRequest);
    dispatch(reloadMedicalSection(benguideId));
  };
};

export const reloadMedicalSection: any = (
  benguideId: string,
  closeUpPopUp: boolean = true
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await BenguideService.getBenefitPageSection(
      benguideId,
      SECTION_MEDICAL
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(
      updateMedicalSection(
        sectionResponse.benefitPage,
        benguideId,
        closeUpPopUp
      )
    );
  };
};

export const updateFilters: any = (filterData: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(filterUpdatingCompleted(filterData));
  };
};

export const uploadPlanDocument = (
  planId: string,
  benefitKind: string,
  documentType: string,
  file: File,
  fileName: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(documentUploadStarted());
    await MedicalPlanService.uploadPlanDocument(
      planId,
      benefitKind,
      documentType,
      file,
      fileName
    );
    dispatch(documentUploadCompleted());
  };
};

export const retrievePlanDocument = (
  planId: string,
  benefitKind: string,
  documentType: string,
  benguide?: any,
  onClose?: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(retrievePlanDocumentStarted());
    await MedicalPlanService.retrievePlanDocument(
      planId,
      benefitKind,
      documentType
    );
    dispatch(retrievePlanDocumentCompleted());
    if (benguide) {
      if (benefitKind === BENEFIT_CATEGORY.MEDICAL.value) {
        dispatch(
          reloadMedicalSection(benguide.masterId, benguide.latestRevision)
        );
      } else if (benefitKind === BENEFIT_CATEGORY.DENTAL.value) {
        dispatch(reloadDentalSection(benguide.masterId));
      } else if (benefitKind === BENEFIT_CATEGORY.VISION.value) {
        dispatch(
          reloadVisionSection(benguide.masterId, benguide.latestRevision)
        );
      }
      if (onClose) {
        onClose();
      }
    }
  };
};

export const uploadSBC = (file: FormData) => {
  return (dispatch: Dispatch) => {
    dispatch(sbcUploadingInprogress());
    MedicalPlanService.startTextractJob(file)
      .then((res) => {
        const jobId = res.data;
        dispatch(sbcUploadingCompleted(jobId));
      })
      .catch((error) => {
        dispatch(sbcUploadingFailed(error));
      });
  };
};

export const enablePlanRecommenderSection = (
  isEnabled: boolean,
  benguideId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planRecommenderUpdateStarted());
    const data = {
      enabled: isEnabled,
    };
    await MedicalPlanService.planRecommenderToggle(
      benguideId,
      PLAN_RECOMMENDER,
      data
    );
    dispatch(setPlanRecommenderState(isEnabled));
    dispatch(planRecommenderUpdatingCompleted());
  };
};

export const getCustomFieldsToState =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const benGuideMasterId = getState().benguide?.data?.masterId;
      const medicalSection = getState().medical.medicalSection;
      const response = await getCustomFields(benGuideMasterId);
      const customFields = response.data;
      const updatedPlans = setPlanCustomFields(
        medicalSection.plans,
        customFields
      );
      dispatch(
        medicalSectionUpdatingCompleted({
          ...medicalSection,
          plans: updatedPlans,
        })
      );
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const editCustomFieldValue =
  (customFieldVO: CustomField) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const benGuideMasterId = getState().benguide?.data?.masterId;
      await updateCustomFieldValue(benGuideMasterId, customFieldVO);
    } catch (error) {
      return Promise.reject(error);
    }
  };

const setDefaultToCustomServices = (plans: MDVPlan[]) => {
  const updatedPlans: MDVPlan[] = [];
  plans?.forEach((plan: MDVPlan) => {
    const planCopy = cloneDeep(plan);

    planCopy.customServices = plan?.customServices?.map((service: any) => {
      const clonedService = cloneDeep(service);
      clonedService.isDefault = true;
      return clonedService;
    });
    updatedPlans.push(planCopy);
  });
  return updatedPlans;
};

const setPlanCustomFields = (plans: MDVPlan[], customFields: CustomField[]) => {
  const updatedPlans: MDVPlan[] = [];
  plans?.forEach((plan: MDVPlan) => {
    const planCopy = cloneDeep(plan);
    planCopy.customFields = customFields.filter(
      (item) => item.planId === plan.id
    );

    updatedPlans.push(planCopy);
  });
  return updatedPlans;
};
