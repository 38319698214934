import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Form, Input, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import {
  requestPasswordReset,
  setEmployeeModal,
} from 'modules/auth/slices/employeeAuthSlice';

import styles from './requestResetPassword.module.less';

const RequestResetPassword = () => {
  const { hash = '', employerName = '' } = useParams();
  const baseUrl = `/${hash}/`;
  const inProgress = useAppSelector((state) => state.employeeAuth.inProgress);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const resetPasswordHandler = async (values: any) => {
    const constructRedirectURL = `${window.location.origin}${baseUrl}${employerName}`;
    const response = await dispatch(
      requestPasswordReset(values?.email?.trim(), constructRedirectURL)
    );
    if (response) {
      form?.resetFields();
    }
    return response;
  };

  useEffect(() => {
    return () => {
      form?.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row justify="center" className={styles.wrapperContainer}>
      <InputForm form={form} layout="vertical" onFinish={resetPasswordHandler}>
        <Form.Item
          name="email"
          label={<>Your Account Email Address</>}
          rules={[
            {
              type: 'email',
              message: 'Invalid email format',
            },
            {
              required: true,
              message: 'Please enter your email',
            },
          ]}
        >
          <Input className={styles.inputWrapper} name="email" autoFocus />
        </Form.Item>

        <SubmitButton
          type="primary"
          htmlType="submit"
          disabled={inProgress}
          loading={inProgress}
          className={styles.submitPwdButton}
        >
          Request New Password
        </SubmitButton>

        <Row justify="center">
          <LinkButton
            className={styles.resetPwdButton}
            onClick={() => dispatch(setEmployeeModal(false))}
          >
            Cancel
          </LinkButton>
        </Row>
      </InputForm>
    </Row>
  );
};

export default RequestResetPassword;
