import EmployerContribution from 'model/EmployerContribution';

export const hasErContributionValue = (
  employerContributionObj: EmployerContribution
): boolean => {
  const {
    employerEEContribution,
    employerESContribution,
    employerECContribution,
    employerEFContribution,
    empContribution,
  } = employerContributionObj;
  return Boolean(
    (employerEEContribution !== null && employerEEContribution !== undefined) ||
      (employerESContribution !== null &&
        employerESContribution !== undefined) ||
      (employerECContribution !== null &&
        employerECContribution !== undefined) ||
      (employerEFContribution !== null &&
        employerEFContribution !== undefined) ||
      (empContribution !== null && empContribution !== undefined)
  );
};
