import { Row } from 'antd';
import { useAppDispatch } from 'hooks/redux';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';
import { setAuthView } from 'modules/auth/slices/employeeAuthSlice';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import styles from './erAdmin.module.less';

const ErAdmin = () => {
  const dispatch = useAppDispatch();
  return (
    <Row justify="center">
      <div>
        <h1 className={styles.textWrapper}>
          You can now use your unified portal credentials to login to the app
        </h1>
        <SubmitButton
          type="primary"
          onClick={() => {
            dispatch(setAuthView(AUTH_VIEW.LOGIN.type));
          }}
          htmlType="button"
          className={styles.submitPwdButton}
        >
          Login
        </SubmitButton>
      </div>
    </Row>
  );
};

export default ErAdmin;
