import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import * as VisionPlanService from 'modules/benefitsWellness/services/VisionPlanService';
import * as PlanService from 'modules/auth/services/PlanService';
import * as BenguideService from 'services/BenGuideService';
import PlanReference from 'model/PlanReference';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import VisionSection from 'model/MDVSection';
import MDVPlan from 'model/MDVPlan';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import { setCloseModalsInUP } from 'modules/auth/slices/benguideSlice';
const SECTION_VISION = 'VISION';

type VisionState = {
  visionPlanList: {
    inProgress: boolean;
    error: any;
    data: MDVPlan[];
  };
  visionSection: {
    enabled: boolean;
    plans: MDVPlan[];
  };
  inProgress: boolean;
  filteredPlanList: MDVPlan[];
  planProviders: string[];
  planTypes: string[];
  appliedFilters: {
    providers: object;
    types: object;
  };
};

const initialState = {
  visionPlanList: {
    inProgress: false,
    error: null,
    data: [],
  },
  visionSection: {
    enabled: false,
    plans: [],
  },
  inProgress: false,
  filteredPlanList: [],
  planProviders: [],
  planTypes: [],
  appliedFilters: {
    providers: {},
    types: {},
  },
} as VisionState;

const visionSlice = createSlice({
  name: 'vision',
  initialState,
  reducers: {
    visionPlanListFetchingStarted: (state) => {
      state.visionPlanList.inProgress = true;
      state.visionPlanList.error = null;
    },
    visionPlanListFetchingCompleted: (state, { payload }) => {
      state.visionPlanList.inProgress = false;
      state.visionPlanList.error = null;
      state.visionPlanList.data = payload;
    },
    visionPlanListFetchingFailed: (state, { payload }) => {
      state.visionPlanList.inProgress = false;
      state.visionPlanList.error = payload;
      state.visionPlanList.data = [];
    },
    visionSectionUpdatingCompleted: (state, { payload }) => {
      state.visionSection = payload;
      state.filteredPlanList = payload.plans;
    },
    visionSectionUpdateFailed: (state, { payload }) => {
      state.visionPlanList.error = payload;
    },
  },
});

export const {
  visionPlanListFetchingStarted,
  visionPlanListFetchingCompleted,
  visionPlanListFetchingFailed,
  visionSectionUpdatingCompleted,
  visionSectionUpdateFailed,
} = visionSlice.actions;

export default visionSlice.reducer;

export const getVisionPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(visionPlanListFetchingStarted());
    VisionPlanService.getVisionPlanList(employerId, planYearId, benefitClasses)
      .then(({ data }: AxiosResponse) => {
        const { content } = data;
        dispatch(visionPlanListFetchingCompleted(content));
      })
      .catch((error) => {
        dispatch(visionPlanListFetchingFailed(error));
      });
  };
};

export const updateVisionSection: any = (visionSection: VisionSection) => {
  return async (dispatch: Dispatch) => {
    const visionPlanPromises = visionSection.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return PlanService.getPlans('visions', planId || id, revision);
    });
    const plans = (await Promise.all(visionPlanPromises)).map(
      (plan: any) => plan.data as MDVPlan
    );
    dispatch(visionSectionUpdatingCompleted({ ...visionSection, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const saveAndReloadVisionSection = (
  benguideId: string,
  selectedPlans: MDVPlan[],
  orderByPlanNames: boolean
) => {
  const planReferences = selectedPlans.map((plan) => {
    return {
      planId: plan.id,
      planName: plan.name,
      startDate: plan.startDate,
      endDate: plan.endDate,
      isArchived: plan.archived,
      revision: plan.revision,
      groups: plan.groups,
      benefitCategory: BENEFIT_CATEGORY.VISION.value,
      benefitKind: BENEFIT_CATEGORY.VISION.value,
      carrier: {
        id: get(plan, 'carrier.id', null),
        name: get(plan, 'carrier.name', null),
      },
      benefitCarrier: {
        id: get(plan, 'benefitCarrier.id', null),
        name: get(plan, 'benefitCarrier.name', null),
      },
    } as PlanReference;
  });

  return async (dispatch: Dispatch) => {
    const sectionApiRequest = {
      plans: planReferences,
      sectionName: SECTION_VISION,
      orderByPlanNames: orderByPlanNames,
    } as AddPlanApiRequestPayload;
    try {
      await BenguideService.addPlans(benguideId, sectionApiRequest);
      const { data } = await BenguideService.getBenefitPageSection(
        benguideId,
        SECTION_VISION
      );
      const sectionResponse = data as SectionApiRequestResponse;
      dispatch(updateVisionSection(sectionResponse.benefitPage));
    } catch (error) {
      dispatch(visionSectionUpdateFailed(error));
    }
  };
};

export const reloadVisionSection: any = (benguideId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await BenguideService.getBenefitPageSection(
        benguideId,
        SECTION_VISION
      );
      const sectionResponse = data as SectionApiRequestResponse;
      dispatch(updateVisionSection(sectionResponse.benefitPage));
    } catch (error) {
      dispatch(visionSectionUpdateFailed(error));
    }
  };
};
