import { UPEvent } from 'modules/clients/UPClient/UPClient';
import { unifiedPortalUrl } from 'utils/apiUtil';

const useUPClient = () => {
  const postMessage = (event: UPEvent) => {
    window.parent.postMessage(event, unifiedPortalUrl);
  };
  return { postMessage };
};

export default useUPClient;
