import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import RouteMap from 'routes/RouteMap';

type NavigationContextHolder = {
  disabledRoutes: RouteMap[];
  setDisabledRoutes: (routes: RouteMap[]) => void;
};

const initialState: NavigationContextHolder = {
  disabledRoutes: [],
  setDisabledRoutes: () => {},
};

export const NavigationContext =
  createContext<NavigationContextHolder>(initialState);
export const NavigationContextProvider: FC = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [navigationContext, setNavigationContext] =
    useState<NavigationContextHolder>(initialState);

  const setDisabledRoutes = useCallback((disabledRoutes: RouteMap[]) => {
    setNavigationContext((current) => {
      return {
        ...current,
        disabledRoutes: disabledRoutes,
      };
    });
  }, []);

  useEffect(() => {
    setNavigationContext((current) => {
      return {
        ...current,
        setDisabledRoutes,
      };
    });
  }, [setDisabledRoutes]);

  return (
    <NavigationContext.Provider value={navigationContext}>
      {children}
    </NavigationContext.Provider>
  );
};
