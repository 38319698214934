import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {
  FOUR_TIER_COVERAGES,
  IN_NETWORK,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import * as DBGConfigService from 'modules/benefitsWellness/services/DBGConfigurationService';
import ContributionFrequency from 'model/ContributionFrequency';
import MDVPlan from 'model/MDVPlan';
import { benguideFrequencyUpdateCompleted } from 'modules/auth/slices/benguideSlice';

type BenefitWellnessState = {
  selectedNetwork: string;
  selectedCoverageType: any;
  selectedPlanForCompare: string[];
  activePlanCompare: boolean;
  contributionFrequency: {};
  inProgress: boolean;
  error: {};
  rateValidations: {
    benefitKind: null;
    hasMismatchContributions: false;
    mismatchedPlans: MDVPlan[];
  };
};

const initialState = {
  selectedNetwork: IN_NETWORK.value,
  selectedCoverageType: FOUR_TIER_COVERAGES[0].items[0],
  selectedPlanForCompare: [],
  activePlanCompare: false,
  inProgress: false,
  error: {},
  rateValidations: {
    benefitKind: null,
    hasMismatchContributions: false,
    mismatchedPlans: [],
  },
} as unknown as BenefitWellnessState;

const benefitWellnessSlice = createSlice({
  name: 'benefitWellness',
  initialState,
  reducers: {
    setSelectedNetwork: (state, { payload }) => {
      state.selectedNetwork = payload;
    },
    setSelectedCoverage: (state, { payload }) => {
      state.selectedCoverageType = payload;
    },
    setSelectedPlanForCompare: (state, { payload }) => {
      state.selectedPlanForCompare = payload;
      if (state.selectedPlanForCompare.length === 0) {
        state.activePlanCompare = false;
      }
    },
    setActivePlanCompare: (state) => {
      state.activePlanCompare = !state.activePlanCompare;
      if (!state.activePlanCompare) {
        state.selectedPlanForCompare = [];
      }
    },
    updateDBGFrequencyInProgress: (state) => {
      state.inProgress = true;
    },
    updateDBGFrequencySuccess: (state, { payload }) => {
      state.inProgress = false;
      state.contributionFrequency = payload;
    },
    updateDBGFrequencyError: (state) => {
      state.inProgress = false;
      state.contributionFrequency = {} as ContributionFrequency;
    },
    checkBenefitGuideRatesInProgress(state) {
      state.inProgress = true;
    },
    checkBenefitGuideRatesCompleted: (state, action) => {
      const { payload } = action;
      state.inProgress = false;
      state.rateValidations = payload;
    },
    checkBenefitGuideRatesFailed(state, action) {
      state.inProgress = false;
      state.error = action.payload;
      state.rateValidations = {
        benefitKind: null,
        hasMismatchContributions: false,
        mismatchedPlans: [],
      };
    },
  },
});

export default benefitWellnessSlice.reducer;

export const {
  setSelectedNetwork,
  setSelectedCoverage,
  setSelectedPlanForCompare,
  setActivePlanCompare,
  updateDBGFrequencyInProgress,
  updateDBGFrequencySuccess,
  updateDBGFrequencyError,
  checkBenefitGuideRatesInProgress,
  checkBenefitGuideRatesCompleted,
  checkBenefitGuideRatesFailed,
} = benefitWellnessSlice.actions;

export const updateDBGFrequencyConfiguration: any = (
  benefitGuideId: string,
  formData: ContributionFrequency
) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateDBGFrequencyInProgress());
    try {
      const response = await DBGConfigService.updateDBGFrequency(
        benefitGuideId,
        formData.benefitKind,
        formData.frequency,
        formData.enabled
      );
      dispatch(
        benguideFrequencyUpdateCompleted(response.data.contributionFrequencies)
      );
    } catch (e) {
      dispatch(updateDBGFrequencyError());
    }
  };
};

export const checkBenefitGuidesRates = (
  benefitGuideMasterId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(checkBenefitGuideRatesInProgress());
      const response = await DBGConfigService.checkDBGRates(
        benefitGuideMasterId,
        benefitKind
      );
      const data = response.data;
      dispatch(checkBenefitGuideRatesCompleted(data));
    } catch (error) {
      dispatch(checkBenefitGuideRatesFailed(error));
    }
  };
};
