import { FC } from 'react';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

import generatePicker, {
  PickerDateProps,
} from 'antd/lib/date-picker/generatePicker';

import styles from './datePickerInput.module.less';

interface DatePickerLocalProps {
  placeholder?: string;
}

type Props = DatePickerLocalProps & PickerDateProps<Dayjs>;

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const DatePickerInput: FC<Props> = (props: Props) => {
  const { suffixIcon, ...rest } = props;
  return (
    <div className={styles.datePickerWrapper}>
      <DatePicker {...rest} suffixIcon={suffixIcon} />
    </div>
  );
};

export default DatePickerInput;
