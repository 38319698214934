import { FC, ReactNode } from 'react';

import { Popconfirm, PopconfirmProps } from 'antd';

import styles from './confirmationModal.module.less';

interface ConfirmModalProps extends PopconfirmProps {
  confirmModalTrigger: ReactNode;
}

const ConfirmationModal: FC<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const { okText, cancelText, icon, title, confirmModalTrigger, ...rest } =
    props;

  return (
    <Popconfirm
      title={title}
      icon={icon}
      overlayClassName={styles.confirmModalWrapper}
      {...rest}
      okText={okText}
      cancelText={cancelText}
    >
      {confirmModalTrigger}
    </Popconfirm>
  );
};

export default ConfirmationModal;
