import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const employerApiV1 = `${baseApi}/v1/employers`;
const employerApiV2 = `${baseApi}/v2/employers`;

export const findEmployerById = (employerId: string) => {
  return http.get(`${employerApiV2}/${employerId}`);
};

export const findPlanYears = (employerId: string) => {
  return http.get(`${employerApiV1}/${employerId}/plan-years`);
};

export const findPlanYearById = (employerId: string, planYearId: string) => {
  return http.get(`${employerApiV1}/${employerId}/plan-years/${planYearId}`);
};
