import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import Plan from 'model/Plan';
import PlanReference from 'model/PlanReference';
import {
  getAllHSAAscociatedMedicals,
  getAllTaxAdvantagedPlans,
  getTaxAdvantagedPlanList,
} from 'modules/benefitsWellness/services/TaxAdvantagedPlanService';
import * as BenGuideService from 'services/BenGuideService';
import * as PlanService from 'modules/auth/services/PlanService';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import SectionApiRequestPayload from 'model/SectionApiRequestPayload';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import {
  HSA_PLAN_CONTENT_TABS,
  TaxAdvantagedSection,
} from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { SectionName } from 'modules/home/constants';

interface TaxAdvantagedState {
  planList: {
    inProgress: boolean;
    data: Plan[];
  };
  hsaSection: {
    enabled: boolean;
    plans: PlanReference[];
  };
  selectedPlans: {
    data: TaxAdvantageAccountsPlan[];
    inProgress: boolean;
  };
  inProgress: boolean;
  defaultRichMedia: {
    inProgress: boolean;
    error: any;
    data: {
      [key in string]: string;
    };
  };
  associatedPlans: {
    inProgress: boolean;
    data: any;
  };
}

const initialState: TaxAdvantagedState = {
  planList: {
    inProgress: false,
    data: [],
  },
  hsaSection: {
    enabled: false,
    plans: [],
  },
  selectedPlans: {
    data: [],
    inProgress: false,
  },
  inProgress: false,
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.HSA_OVERVIEW_TEXT]: '',
      [SectionName.HSA_ELIGIBILITY_TEXT]: '',
      [SectionName.HSA_EXPENSES_TEXT]: '',
      [SectionName.HSA_ADVANTAGES_TEXT]: '',
      [SectionName.HSA_CONSIDERATIONS_TEXT]: '',
    },
  },
  associatedPlans: {
    inProgress: false,
    data: null,
  },
};

type PlanFetchData = {
  employerId: string;
  planYearId: string;
  benefitClasses: string[];
};

type SelectPlanParams = {
  benguideId: string;
  addPlansPayload: AddPlanApiRequestPayload;
  benefitKind: string;
};

export const fetchHSAPlans = createAsyncThunk<any, PlanFetchData>(
  'hsa/fetchHSAPlans',
  async ({ employerId, planYearId, benefitClasses }) => {
    const response = await getTaxAdvantagedPlanList(
      employerId,
      planYearId,
      [TaxAdvantagedSection.HSA],
      benefitClasses
    );
    return response.data.content;
  }
);

export const selectHSAPlans = createAsyncThunk<
  SectionApiRequestPayload,
  SelectPlanParams
>('hsa/selectHSAPlans', async ({ benguideId, addPlansPayload }) => {
  await BenGuideService.addPlans(benguideId, addPlansPayload);
  const response = await BenGuideService.getBenefitPageSection(
    benguideId,
    TaxAdvantagedSection.HSA
  );
  return response.data;
});

export const getHSASection = createAsyncThunk<
  SectionApiRequestResponse,
  { benguideId: string }
>('hsa/getBenefitSection', async ({ benguideId }) => {
  const response = await BenGuideService.getBenefitPageSection(
    benguideId,
    TaxAdvantagedSection.HSA
  );
  return response.data;
});

export const getPlansFromPlanRefs = createAsyncThunk<
  TaxAdvantageAccountsPlan[],
  { planRefs: PlanReference[] }
>('hsa/getPlansFromPlanRefs', async ({ planRefs }) => {
  const planPromises = planRefs.map((plan) => {
    const { planId, revision } = plan;
    return PlanService.getPlans('tax-advantage-accounts', planId, revision);
  });
  const promiseResponses = await Promise.all(planPromises);
  return promiseResponses.map((plan) => plan.data);
});

const hsaSlice = createSlice({
  name: 'hsa',
  initialState,
  reducers: {
    updateHSASection(state, { payload }) {
      state.hsaSection = payload;
    },
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.data[payload.section] = payload.media.content;
    },
    getDefaultMediaFailed(state, payload) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
    listAllHSAPlansStarted(state) {
      state.planList.inProgress = true;
    },
    listAllHSAPlansSuccess(state, { payload }) {
      state.planList.inProgress = false;
      state.planList.data = payload;
    },
    listAllHSAPlansFailed(state) {
      state.planList.inProgress = false;
    },
    associatedPlansStarted(state) {
      state.associatedPlans.inProgress = true;
    },
    associatedPlansSuccess(state, { payload }) {
      state.associatedPlans.inProgress = true;
      state.associatedPlans.data = payload;
    },
    associatedPlansFailed(state) {
      state.associatedPlans.inProgress = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHSAPlans.pending, (state) => {
      state.planList.inProgress = true;
    });
    builder.addCase(fetchHSAPlans.fulfilled, (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.data = payload;
    });
    builder.addCase(selectHSAPlans.pending, (state) => {
      state.selectedPlans.inProgress = true;
    });
    builder.addCase(selectHSAPlans.fulfilled, (state, { payload }) => {
      state.selectedPlans.inProgress = false;
      state.hsaSection = payload.benefitPage;
    });
    builder.addCase(getHSASection.pending, (state) => {
      state.inProgress = true;
    });
    builder.addCase(getHSASection.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.hsaSection = payload.benefitPage;
    });
    builder.addCase(getPlansFromPlanRefs.pending, (state) => {
      state.selectedPlans.inProgress = true;
    });
    builder.addCase(getPlansFromPlanRefs.fulfilled, (state, { payload }) => {
      state.selectedPlans.inProgress = false;
      state.selectedPlans.data = payload;
    });
  },
});

export const {
  updateHSASection,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
  listAllHSAPlansStarted,
  listAllHSAPlansSuccess,
  listAllHSAPlansFailed,
  associatedPlansStarted,
  associatedPlansSuccess,
  associatedPlansFailed,
} = hsaSlice.actions;

export default hsaSlice.reducer;

export const listHSAPlans = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(listAllHSAPlansStarted());
    try {
      const response = await getAllTaxAdvantagedPlans(
        employerId,
        planYearId,
        ['HSA'],
        benefitClasses
      );
      dispatch(listAllHSAPlansSuccess(response.data));
    } catch (error) {
      dispatch(listAllHSAPlansFailed());
    }
  };
};

export const getDefaultMediaForHSA = (benguideId: string, planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getDefaultMediaStarted());
    HSA_PLAN_CONTENT_TABS.forEach((section) => {
      BenGuideService.getRichMediaContentForPlan(
        benguideId,
        section.code,
        planId,
        true
      )
        .then(({ data }) => {
          dispatch(
            getDefaultMediaSuccess({
              section: section.code,
              media: data,
            })
          );
        })
        .catch((error) => {
          dispatch(getDefaultMediaFailed(error));
        });
    });
  };
};

export const listHSAAssociatedPlans = (
  employerId: string,
  planYearId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(associatedPlansStarted());
    try {
      const response = await getAllHSAAscociatedMedicals(
        employerId,
        planYearId,
        benefitKind
      );
      dispatch(associatedPlansSuccess(response.data));
    } catch (error) {
      dispatch(associatedPlansFailed());
    }
  };
};
