import { FC, useMemo, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { ReactComponent as RankIcon } from 'images/plan-recommender/icon-rank.svg';
import { ReactComponent as RankIconGreen } from 'images/plan-recommender/icon-rank-green.svg';
import { ReactComponent as RankIconStar } from 'images/plan-recommender/icon-rank-star.svg';
import { ReactComponent as RankIconStarGreen } from 'images/plan-recommender/icon-rank-star-green.svg';
import {
  DEFAULT_TEXT,
  PLAN_RECOMMENDER_PLAN_NOTES,
  RATE_TIERS,
  SUPER_SCRIPTS,
} from 'modules/planRecommender/pages/constants';
import {
  currencyFormatter,
  currencyFormatterForSignedNumbers,
  getPlanCoverFooterHeight,
} from 'utils/commonUtil';
import { getCardFieldValue } from 'utils/planRecommenderUtils/planRecommenderUtils';
import { PLAN_TYPE_HSA } from 'modules/benefitsWellness/constants/BenefitConstants';
import infoIcon from 'images/icons/icon-info.png';
import playButton from 'images/icons/play-button.png';
import metadata from 'data/metadata';
import PlanDetailsPopup from 'modules/planRecommender/components/PlanDetailsPopup/PlanDetailsPopup';
import PlanTypeInfoDrawer from 'modules/benefitsWellness/components/PlanTypeInfoDrawer/PlanTypeInfoDrawer';

import styles from './planRecommenderPlan.module.less';

type PlanRecommenderPlanProps = {
  plan: any;
  index: number;
  rank: number;
  activePlan: number;
  onClick: Function;
  isNTierPlan: boolean;
  dbgPlanFrequency: string;
  maxRxTiers: number;
};

const PlanRecommenderPlan: FC<PlanRecommenderPlanProps> = (
  props: PlanRecommenderPlanProps
) => {
  const {
    index,
    activePlan,
    plan,
    rank,
    isNTierPlan,
    onClick,
    dbgPlanFrequency,
    maxRxTiers,
  } = props;

  const [isOpenPlanDetails, setOpenPlanDetails] = useState<boolean>(false);
  const [isOpenPlanInfo, setOpenPlanInfo] = useState<boolean>(false);

  const togglePlanDetails = () => {
    setOpenPlanDetails(!isOpenPlanDetails);
  };

  const togglePlanInfo = () => {
    setOpenPlanInfo(!isOpenPlanInfo);
  };
  const getRankIcon = (rank: number) => {
    if (rank === 1) {
      return (
        <div className={styles.rankWrapper}>
          {index !== activePlan ? (
            <RankIconStar className={styles.rankIcon} />
          ) : (
            <RankIconStarGreen className={styles.rankIcon} />
          )}
          <span>Recommendation</span>
        </div>
      );
    } else {
      return (
        <div className={styles.rankWrapper}>
          {index !== activePlan ? (
            <RankIcon className={styles.rankIcon} />
          ) : (
            <RankIconGreen className={styles.rankIcon} />
          )}
          <div className={styles.rankText}>#{rank}</div>
        </div>
      );
    }
  };

  const filterRateTier = (plan: any) => {
    return RATE_TIERS.find((tier) => tier.fourTierValue === plan.rateTier);
  };

  const formatFieldValue = (
    key: any,
    isTooltip = false,
    hasDollar = true,
    shouldLowerCase = true
  ) => {
    const rate = filterRateTier(plan);
    const network = 'InNetwork';
    let value = getCardFieldValue(
      plan,
      key,
      network,
      rate,
      hasDollar,
      shouldLowerCase,
      dbgPlanFrequency
    );
    value =
      !value || value.toString().trim().length === 0
        ? 'defaultValue' in key
          ? key.defaultValue
          : DEFAULT_TEXT
        : value;
    return clearSuperScripts(value, !isTooltip);
  };

  const clearSuperScripts = (value: any, withElement = true) => {
    const fieldValue = SUPER_SCRIPTS.reduce(
      (current: any, superscript: any) => {
        const search = new RegExp(superscript.strValue, 'g');
        if (current.indexOf(superscript.strValue) >= 0) {
          return current.replace(search, '');
        } else {
          return current;
        }
      },
      value
    );
    if (withElement) {
      return <>{fieldValue}</>;
    } else {
      return fieldValue;
    }
  };

  const { planRecommenderPlanKeys: keys } = metadata;

  const rxTierColumn = useMemo(() => {
    const getcolumns = (divCount: number) => {
      const rxColumns = [];
      for (let i = 6; i <= divCount + 5; i++) {
        rxColumns.push(
          <div
            className={
              i % 2
                ? `${styles.valueField} ${styles.darkBg}`
                : `${styles.valueField}`
            }
          >
            {formatFieldValue(keys[i])}
          </div>
        );
      }

      return <>{rxColumns}</>;
    };

    return getcolumns(maxRxTiers);

    // eslint-disable-next-line
  }, [maxRxTiers]);

  const {
    benefitCarrier,
    name,
    estAnnualBreakdown = {},
    type: planType,
  } = plan;

  const {
    estAnnualCost = 0,
    hsaContribution = 0,
    hraContribution = 0,
  } = estAnnualBreakdown;
  const { type, hsaCompatible } = plan;
  const rateTier = filterRateTier(plan) as any;

  const planClasses = [styles.plan];
  if (index === activePlan) {
    planClasses.push(styles.activePlan);
  }

  return (
    <div
      className={planClasses.join(' ')}
      onClick={() => {
        onClick(index);
      }}
    >
      {getRankIcon(rank)}
      <div
        className={`${isNTierPlan ? styles.noPremium : ''} ${styles.upper}`}
        style={{ height: `${getPlanCoverFooterHeight(maxRxTiers)}px` }}
      >
        <div className={styles.headerBorder}></div>
        <img
          className={styles.carrierLogo}
          src={benefitCarrier?.logoUrl}
          alt=""
        />
        <div className={styles.planName}>
          <div className={styles.planNameContent} title={name}>
            {name}
          </div>
          <img
            src={infoIcon}
            alt="info"
            className={styles.infoTooltipIcon}
            data-tip={
              hsaCompatible
                ? PLAN_RECOMMENDER_PLAN_NOTES[PLAN_TYPE_HSA]
                : PLAN_RECOMMENDER_PLAN_NOTES[type]
            }
          />
          <ReactTooltip
            className={styles.infoTooltip}
            place="right"
            type="light"
            effect="solid"
            border={true}
            borderColor="gray"
          />
        </div>
        <div className={styles.links}>
          {type && (
            <div className={styles.infoLink} onClick={togglePlanInfo}>
              <img src={playButton} alt="play" />
              {hsaCompatible ? 'HSA' : planType} Info
            </div>
          )}
          <div className={styles.detailsLink} onClick={togglePlanDetails}>
            Plan Details
          </div>
        </div>
        {!isNTierPlan && (
          <div className={`${styles.valueField} ${styles.monthlyPremium}`}>
            <div
              className={styles.value}
              title={formatFieldValue(keys[0], true)}
            >
              {formatFieldValue(keys[0])}
            </div>
            <div className={styles.cover}>{rateTier.coverageLabel}</div>
          </div>
        )}
        <div
          className={styles.valueField}
          title={formatFieldValue(keys[1], true)}
        >
          {formatFieldValue(keys[1])}
        </div>
        <div
          className={`${styles.valueField} ${styles.darkBg}`}
          title={formatFieldValue(keys[2], true)}
        >
          {formatFieldValue(keys[2])}
        </div>
        <div
          className={styles.valueField}
          title={formatFieldValue(keys[3], true)}
        >
          {formatFieldValue(keys[3])}
        </div>
        <div
          className={`${styles.valueField} ${styles.darkBg}`}
          title={formatFieldValue(keys[4], true)}
        >
          {formatFieldValue(keys[4])}
        </div>
        <div
          className={styles.valueField}
          title={formatFieldValue(keys[5], true)}
        >
          {formatFieldValue(keys[5])}
        </div>
        {rxTierColumn}

        <div
          className={`${styles.valueField} ${styles.hsa}`}
          title={`${currencyFormatter(hsaContribution)} / ${currencyFormatter(
            hraContribution
          )}`}
        >
          {currencyFormatter(hsaContribution)}/
          {currencyFormatter(hraContribution)}
        </div>
        <div
          className={`${styles.valueField} ${styles.annualCost}`}
          title={currencyFormatterForSignedNumbers(estAnnualCost)}
        >
          {currencyFormatterForSignedNumbers(estAnnualCost)}
        </div>
      </div>
      <div className={styles.planFooter}></div>
      <PlanTypeInfoDrawer
        isDrawerOpen={isOpenPlanInfo}
        onClose={() => setOpenPlanInfo(false)}
        isHSA={plan.hsaCompatible}
        planType={plan.type}
      />
      <PlanDetailsPopup
        isdrawerOpen={isOpenPlanDetails}
        onHide={togglePlanDetails}
        plan={plan}
        planId={plan.id}
        selectedRate={rateTier}
        networkType={'InNetwork'}
        planType={'medical'}
      />
    </div>
  );
};

export default PlanRecommenderPlan;
