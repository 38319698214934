import Login from 'model/Login';
import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const loginUrl = `${baseApi}/v1/logins`;

export const getLoginById = (id: string): Promise<Login> => {
  return http.get(`${loginUrl}/${id}`);
};

export const getLoginByUsername = (username: string) => {
  return http.get<Login>(`${loginUrl}/usernames/${username}`);
};
