import {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useRef,
  useContext,
} from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Radio,
  Select,
  Space,
  RadioChangeEvent,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import omit from 'lodash/omit';
import some from 'lodash/some';

import { ReactComponent as CalendarIcon } from 'images/icon-calendar.svg';
import { ReactComponent as QuestionCircleIcon } from 'images/question-round.svg';

import OEMeeting from 'model/oeMeeting/OEMeeting';
import OEVideoFile from 'model/oeMeeting/OEVideoFile';

import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import InputForm from 'components/InputForm/InputForm';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import TimePickerInput from 'components/TimePickerInput/TimePickerInput';
import DocumentUploader from 'components/DocumentUploader/DocumentUploader';
import SwitchButton from 'components/SwitchButton/SwitchButton';
import RadioButton from 'components/RadioButton/RadioButton';
import TooltipPopover from 'components/TooltipPopover/TooltipPopover';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { convertArrayToOptions } from 'utils/commonUtil';
import {
  INPUT_DATE_FORMAT_DEFAULT,
  TWELVE_HOUR_TIME_FORMAT,
  getFormattedInputDate,
  getCurrentUserTimezone,
  getEndOfTimeUnitWithTimezone,
  getCurrentHourWithTimezone,
  getCurrentMinuteWithTimezone,
  TWENTY_HOUR_TIME_FORMAT,
  UTC_TIME_FORMAT,
  getDateWithTimezoneFormatted,
} from 'utils/dateUtil';
import { isValidUrl } from 'utils/httpUtil';
import {
  OE_MEETING_MINUTES_OPTIONS,
  OE_MEETING_HOURS_OPTIONS,
  OE_MEETING_FORM_FIELDS,
  TIME_ZONES_IN_GMT,
  DURATION_TYPES,
  OE_MEETING_ALERT_MSGS,
  UPDATE_NHC_CANCEL_TEXT,
  UPDATE_NHC_CONFIRMATION_TEXT,
  UPDATE_NHC_MODAL_DESCRIPTION,
  NHC_REGENERATE_WARNING_MSG,
} from 'modules/home/constants';
import OEMeetingType from 'modules/home/enums/OEMeetingType';
import OEMeetingRecordType from 'modules/home/enums/OEMeetingRecordType';
import { getOEMeetingState } from 'modules/home/util';
import {
  updateOEMeeting,
  uploadOEVideo,
  uploadOEVideoStarted,
  updateOEMeetingStarted,
  resetOEVideoStateCompleted,
} from 'modules/home/slices/oeMeetingSlice';
import { getOEMeetingVideoRecording } from 'modules/home/services/BenGuideService';
import {
  EMPTY_MESSAGE,
  ERROR_MESSAGE_PLAN_EDITING,
} from 'constants/commonConstants';
import iconHourglass from 'images/icons/icon-hourglass.svg';
import { usePrevious } from 'hooks/usePrevious';
import { NotificationContext } from 'context/NotificationContext';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import styles from './openEnrollmentModal.module.less';

const emptyFile = new File([''], '');

type OpenEnrollmentModalProps = {
  isOpenModal: boolean;
  closeModal: Function;
  benGuideId?: string | null;
  oeMeeting?: OEMeeting;
  revision: number;
};

type ErrorFields = {
  url: boolean;
  videoFile: boolean;
};

type DateTimeOE = {
  date: dayjs.Dayjs | null;
  time: dayjs.Dayjs | null;
};

const OpenEnrollmentModal: FC<OpenEnrollmentModalProps> = (
  props: OpenEnrollmentModalProps
) => {
  const foundTimeZone = find(TIME_ZONES_IN_GMT, {
    value: getCurrentUserTimezone(),
  });
  let defaultTimeZone = '';
  if (foundTimeZone) {
    defaultTimeZone = foundTimeZone.value;
  }

  const { isOpenModal, closeModal, benGuideId, oeMeeting, revision } = props;

  const oeVideoFile = useAppSelector((state) => state.oeMeeting.oeVideoFile);
  const inProgress = useAppSelector((state) => state.oeMeeting.inProgress);
  const requestType = useAppSelector((state) => state.oeMeeting.requestType);
  const dispatch = useAppDispatch();

  const setEmptyFormData = () => {
    return {
      durationMinutes: 60,
      timezone: defaultTimeZone,
      meetingTime: null,
      meetingUrl: '',
      meetingRecordUrl: '',
      oeMeetingType: OEMeetingType.FUTURE,
      oeMeetingRecordType: null,
      displayMeetingInfo: false,
      oeVideoFile: null,
    };
  };

  const [selectedHours, setSelectedHours] = useState<number>(1);
  const [selectedMins, setSelectedMins] = useState<number>(0);
  const [dateTime, setDateTime] = useState<DateTimeOE>({
    date: null,
    time: null,
  });
  const [selectedRecordingType, setSelectedRecordingType] =
    useState<OEMeetingRecordType | null>(null);
  const [recordingUrl, setSelectedRecordingUrl] = useState<string | null>(null);
  const [videoFile, setVideoFile] = useState<File>(emptyFile);
  const [videoFileReference, setVideoFileReference] =
    useState<OEVideoFile | null>(null);
  const [isRecordingUrlInvalid, setIsRecordingUrlInvalid] =
    useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<ErrorFields>({
    url: false,
    videoFile: false,
  });
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] =
    useState<boolean>(false);

  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [formData, setFormData] = useState<OEMeeting>(setEmptyFormData());

  const [form] = Form.useForm();

  const documentUploaderRef = useRef<any>(null);

  const previousOeMeetingType = usePrevious(formData.oeMeetingType);
  const notificationFlag = useContext(NotificationContext);

  useEffect(() => {
    if (
      OEMeetingType.FUTURE === previousOeMeetingType &&
      OEMeetingType.RECORDED === formData.oeMeetingType &&
      !selectedRecordingType
    ) {
      setSelectedRecordingType(OEMeetingRecordType.URL);
    }
  }, [selectedRecordingType, formData.oeMeetingType, previousOeMeetingType]);

  const setPreviousFormData = useCallback(() => {
    if (oeMeeting) {
      setFormData(omit(oeMeeting, ['benGuideId', 'id']));
      setSelectedHours(Math.floor(oeMeeting.durationMinutes / 60));
      setSelectedMins(oeMeeting.durationMinutes % 60);
      if (oeMeeting.meetingTime) {
        setDateTime({
          date: dayjs(oeMeeting.meetingTime),
          time: dayjs(oeMeeting.meetingTime),
        });

        form.setFieldsValue({
          meetingDate: getFormattedInputDate(
            oeMeeting.meetingTime,
            INPUT_DATE_FORMAT_DEFAULT
          ),
          meetingTime: getFormattedInputDate(
            oeMeeting.meetingTime,
            TWELVE_HOUR_TIME_FORMAT
          ),
        });
      }

      form.setFieldsValue({
        timezone: oeMeeting.timezone,
        recordingType: oeMeeting.oeMeetingRecordType,
      });

      if (oeMeeting.oeMeetingRecordType) {
        setSelectedRecordingType(oeMeeting.oeMeetingRecordType);
      }

      if (oeMeeting.meetingRecordUrl) {
        setSelectedRecordingUrl(oeMeeting.meetingRecordUrl);
      }
      if (oeMeeting.oeVideoFile) {
        setVideoFileReference(oeMeeting.oeVideoFile);
      }
    }
  }, [form, oeMeeting]);

  useEffect(() => {
    if (isEmpty(oeVideoFile) && oeMeeting && oeMeeting.oeVideoFile) {
      setVideoFileReference(oeMeeting.oeVideoFile);
    } else {
      setVideoFileReference(oeVideoFile);
    }
  }, [oeMeeting, oeVideoFile]);

  useEffect(() => {
    setPreviousFormData();
  }, [setPreviousFormData]);

  useEffect(() => {
    form.setFieldsValue({
      recordingType: formData.displayMeetingInfo ? selectedRecordingType : null,
    });
  }, [form, formData.displayMeetingInfo, selectedRecordingType]);

  useEffect(() => {
    form.setFieldsValue({
      durationMinutes: selectedMins + selectedHours * 60,
    });
  }, [form, selectedHours, selectedMins]);

  const closeOEModal = () => {
    resetAll();
    resetVideoFileName();
    closeModal();
  };

  const resetVideoFileName = () => {
    if (documentUploaderRef.current) {
      if (oeMeeting) {
        documentUploaderRef.current.setFileName(oeMeeting.oeVideoFile);
      } else {
        documentUploaderRef.current.setFileName('');
      }
    }
  };

  const onChange = (checked: boolean) => {
    resetAll();

    setFormData((prevState) => ({
      ...prevState,
      displayMeetingInfo: checked,
    }));
  };

  const onChangeMeetingType = (event: RadioChangeEvent) => {
    resetAlert();
    clearAllErrorFields();
    setErrorFields({ ...errorFields, url: false, videoFile: false });
    setFormData({ ...formData, oeMeetingType: event.target.value });
    form.setFieldsValue({
      oeMeetingType: event.target.value,
    });
    if (
      !formData.displayMeetingInfo ||
      formData.oeMeetingType === OEMeetingType.FUTURE
    ) {
      if (
        (isEmpty(recordingUrl) &&
          selectedRecordingType === OEMeetingRecordType.URL) ||
        (isEmpty(oeVideoFile ? oeVideoFile : oeMeeting?.oeVideoFile) &&
          selectedRecordingType === OEMeetingRecordType.VIDEO_FILE)
      ) {
        form.setFieldsValue({
          recordingType: null,
        });
        setSelectedRecordingType(null);
      }
    }
  };

  const onChangeRecordingType = (event: RadioChangeEvent) => {
    const { value } = event.target;
    setSelectedRecordingType(value);
    form.setFieldsValue({
      recordingType: value,
    });
    setErrorFields({ ...errorFields, url: false, videoFile: false });
    resetAlert();
  };

  const onVideoUpload = (uploadedFile: File) => {
    if (uploadedFile) {
      setVideoFile(uploadedFile);
      if (benGuideId)
        dispatch(uploadOEVideo(benGuideId, revision, uploadedFile));
    }
    setErrorFields({ ...errorFields, videoFile: false });
    resetAlert();
    form.setFields([
      {
        name: 'recordingType',
        errors: [],
      },
    ]);
  };

  const onRecordingUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedRecordingUrl(value);
    setIsRecordingUrlInvalid(!isValidUrl(value?.trim()));
    resetAlert();
    setErrorFields({ ...errorFields, url: false });
    form.setFields([
      {
        name: 'recordingType',
        errors: [],
      },
    ]);
  };

  const onChangeDateTime = (
    dateTimeCurrent: dayjs.Dayjs | null,
    dateTimeString: string,
    fieldType: string
  ) => {
    resetAlert();
    clearTimeErrorFields();
    form.setFieldsValue({
      [fieldType]: dateTimeString,
    });
    if (fieldType === OE_MEETING_FORM_FIELDS.DATE) {
      setDateTime({ ...dateTime, date: dateTimeCurrent });
    } else {
      setDateTime({ ...dateTime, time: dateTimeCurrent });
    }
  };

  const onSelectOption = (value: any, type: string) => {
    clearTimeErrorFields();
    resetAlert();
    switch (type) {
      case DURATION_TYPES.HOURS:
        setSelectedHours(value);
        break;
      case DURATION_TYPES.MINUTES:
        setSelectedMins(value);
        break;
      case OE_MEETING_FORM_FIELDS.TIMEZONE:
        form.setFieldsValue({
          timezone: value,
        });
        setFormData({ ...formData, timezone: value });
        break;
      default:
        break;
    }
  };

  const isInvalidDate = (date: Dayjs): boolean => {
    const nowFormatted = getDateWithTimezoneFormatted(
      dayjs(),
      form.getFieldValue(OE_MEETING_FORM_FIELDS.TIMEZONE),
      INPUT_DATE_FORMAT_DEFAULT
    );
    const scheduledDateFormatted = date.format(INPUT_DATE_FORMAT_DEFAULT);
    return nowFormatted > scheduledDateFormatted;
  };

  const getInvalidHours = () => {
    const hours: number[] = [];
    const currentTimeZone = form.getFieldValue(OE_MEETING_FORM_FIELDS.TIMEZONE);

    if (
      dateTime.date &&
      dateTime.date < getEndOfTimeUnitWithTimezone(currentTimeZone, 'day')
    ) {
      for (
        let hour = 0;
        hour < getCurrentHourWithTimezone(currentTimeZone);
        hour++
      ) {
        hours.push(hour);
      }
    }
    return hours;
  };

  const getInvalidMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    const currentTimeZone = form.getFieldValue(OE_MEETING_FORM_FIELDS.TIMEZONE);

    if (
      dateTime.date &&
      dateTime.date < getEndOfTimeUnitWithTimezone(currentTimeZone, 'day')
    ) {
      if (selectedHour === getCurrentHourWithTimezone(currentTimeZone)) {
        for (
          let min = 0;
          min < getCurrentMinuteWithTimezone(currentTimeZone);
          min++
        ) {
          minutes.push(min);
        }
      }
    }

    return minutes;
  };

  const isDateToday = (date: Dayjs): boolean => {
    const nowFormatted = getDateWithTimezoneFormatted(
      dayjs(),
      form.getFieldValue(OE_MEETING_FORM_FIELDS.TIMEZONE),
      INPUT_DATE_FORMAT_DEFAULT
    );
    const scheduledDateFormatted = date.format(INPUT_DATE_FORMAT_DEFAULT);
    return nowFormatted === scheduledDateFormatted;
  };

  const resetAll = () => {
    dispatch(resetOEVideoStateCompleted());
    setIsRecordingUrlInvalid(false);
    form.resetFields();
    if (oeMeeting) {
      setPreviousFormData();
    } else {
      setFormData(setEmptyFormData());
      setSelectedMins(0);
      setSelectedHours(1);
      setDateTime({ date: null, time: null });
      setSelectedRecordingUrl(null);
      form.setFieldsValue({
        timezone: defaultTimeZone,
      });
    }
    resetAlert();
    setErrorFields({ ...errorFields, url: false, videoFile: false });
  };

  const resetAlert = () => {
    if (isAlertVisible) {
      setIsAlertVisible(false);
      setAlertMessage('');
    }
  };

  const onSubmit = async () => {
    if (!oeMeeting && !formData.displayMeetingInfo) {
      resetVideoFileName();
      closeModal();
      return;
    }
    let validationPassed = true;
    if (formData.displayMeetingInfo) {
      validationPassed = await validateForm();
    }

    if (validationPassed) {
      if (
        formData.oeMeetingType === OEMeetingType.RECORDED ||
        oeMeeting?.oeMeetingType === OEMeetingType.RECORDED
      ) {
        setIsRegenerateModalOpen(true);
      } else {
        submitUpdateOeMeeting(false);
      }
    }
  };

  const submitUpdateOeMeeting = (updateNHCText: boolean) => {
    if (benGuideId) {
      const currentFormData = form.getFieldsValue();
      let parsedDate = null;
      if (currentFormData.meetingDate && currentFormData.meetingTime) {
        const combinedDateTime = `${currentFormData.meetingDate} ${dayjs(
          currentFormData.meetingTime,
          [TWELVE_HOUR_TIME_FORMAT]
        ).format(TWENTY_HOUR_TIME_FORMAT)}${UTC_TIME_FORMAT}`;
        parsedDate = dayjs(combinedDateTime).toISOString();
      }

      const updatedOEMeeting = {
        id: oeMeeting && oeMeeting.id,
        durationMinutes: currentFormData.durationMinutes,
        timezone: currentFormData.timezone,
        meetingTime: parsedDate,
        meetingUrl: currentFormData.meetingUrl.trim(),
        meetingRecordUrl: recordingUrl,
        oeMeetingType: formData.oeMeetingType,
        oeMeetingRecordType: selectedRecordingType,
        displayMeetingInfo: formData.displayMeetingInfo,
        oeVideoFile: videoFileReference,
      };
      dispatch(
        updateOEMeeting(benGuideId, updatedOEMeeting, !oeMeeting, updateNHCText)
      ).then(() => {
        closeOEModal();
      });
    }
  };

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    form.setFieldsValue({ ...allValues });
    resetAlert();

    if (
      alertMessage === OE_MEETING_ALERT_MSGS.MEETING_TIME_ERROR &&
      Object.keys(changedValues)[0] === 'timezone'
    ) {
      clearTimeErrorFields();
    }
  };

  const clearTimeErrorFields = () => {
    form.setFields([
      {
        name: 'meetingTime',
        errors: [],
      },
      {
        name: 'meetingDate',
        errors: [],
      },
      {
        name: 'timezone',
        errors: [],
      },
      {
        name: 'durationMinutes',
        errors: [],
      },
    ]);
  };

  const clearAllErrorFields = () => {
    form.setFields([
      {
        name: 'recordingType',
        errors: [],
      },
    ]);
    form.setFields([
      {
        name: 'meetingUrl',
        errors: [],
      },
    ]);
    clearTimeErrorFields();
  };

  const validateForm = async () => {
    let isValidationPassed = true;
    try {
      if (form.getFieldValue('oeMeetingType') === OEMeetingType.FUTURE) {
        await form.validateFields([
          'meetingUrl',
          'meetingDate',
          'meetingTime',
          'timezone',
          'durationMinutes',
        ]);
      } else {
        await form.validateFields(['recordingType']);
      }

      setIsAlertVisible(false);
    } catch (error: any) {
      const { errorFields = [] } = error;
      if (isEmpty(errorFields)) {
        setIsAlertVisible(false);
        isValidationPassed = true;
      } else {
        isValidationPassed = false;
        setIsAlertVisible(true);

        const validationData = omit(form.getFieldsValue(), [
          'recordingType',
          'durationMinutes',
        ]);
        if (
          some(validationData, isEmpty) ||
          (selectedHours === 0 && selectedMins === 0) ||
          (!isValidUrl(form.getFieldValue('meetingUrl')?.trim()) &&
            errorFields.length !== 1)
        ) {
          setAlertMessage(OE_MEETING_ALERT_MSGS.GENERIC_ERROR);
        }

        if (form.getFieldValue('oeMeetingType') === OEMeetingType.RECORDED) {
          if (!isEmpty(recordingUrl) && isRecordingUrlInvalid) {
            setAlertMessage(OE_MEETING_ALERT_MSGS.URL_ERROR);
          } else {
            setAlertMessage(OE_MEETING_ALERT_MSGS.GENERIC_ERROR);
          }
        }
      }
    }

    return isValidationPassed;
  };

  const timeValidator = (isFromDuration: boolean) => {
    let validationPassed = true;
    const currentHour = dayjs(dateTime.time).hour() || 0;
    const currentMinute = dayjs(dateTime.time).minute() || 0;

    if (
      !isEmpty(formData.timezone) &&
      !isEmpty(form.getFieldValue(OE_MEETING_FORM_FIELDS.TIME))
    ) {
      if (dateTime.date && isInvalidDate(dateTime.date)) {
        validationPassed = false;
      } else if (dateTime.date && isDateToday(dateTime.date)) {
        if (
          getInvalidHours().includes(currentHour + selectedHours) ||
          getInvalidMinutes(currentHour + selectedHours).includes(
            currentMinute + selectedMins
          )
        ) {
          validationPassed = false;
        }
      }
    }
    if (isFromDuration && selectedHours === 0 && selectedMins === 0) {
      validationPassed = false;
    }

    if (validationPassed) {
      return Promise.resolve();
    } else {
      setIsAlertVisible(true);
      setAlertMessage(OE_MEETING_ALERT_MSGS.MEETING_TIME_ERROR);

      return Promise.reject(new Error());
    }
  };

  const recordingTypeValidator = () => {
    if (
      selectedRecordingType === OEMeetingRecordType.URL &&
      (isEmpty(recordingUrl) || isRecordingUrlInvalid)
    ) {
      setErrorFields({ ...errorFields, url: true });
      return Promise.reject(new Error());
    }
    if (
      selectedRecordingType === OEMeetingRecordType.VIDEO_FILE &&
      ((isEmpty(videoFile.name) && isEmpty(videoFileReference)) ||
        errorFields.videoFile)
    ) {
      setErrorFields({ ...errorFields, videoFile: true });
      return Promise.reject(new Error());
    }
    return Promise.resolve();
  };

  const oeMeetingState = oeMeeting && getOEMeetingState(oeMeeting);

  const cancelRegenerateNHC = () => {
    submitUpdateOeMeeting(false);
    setIsRegenerateModalOpen(false);
  };

  const regenerateNHC = () => {
    submitUpdateOeMeeting(true);
    setIsRegenerateModalOpen(false);
  };

  return (
    <HalfScreenModal
      title={
        <div className={styles.oeHeaderContent}>
          Open Enrollment Meeting Info
          {oeMeetingState?.inProgress && (
            <div className={styles.inProgressSticker}>
              <img
                src={iconHourglass}
                alt="hourglass"
                className={styles.hourglass}
              />
              MEETING IN PROGRESS
            </div>
          )}
        </div>
      }
      visible={isOpenModal}
      onCancel={closeOEModal}
      onSubmit={onSubmit}
      isLoading={inProgress && requestType === updateOEMeetingStarted.type}
      isSubmitDisabled={inProgress && requestType === uploadOEVideoStarted.type}
      width="650px"
    >
      <div className={styles.openEnrollmentWrapper}>
        <Row>
          <Col span={13}>
            <b>Display Open Enrollment Meeting Info?</b>
          </Col>
          <Col>
            <SwitchButton
              onChange={onChange}
              checked={formData.displayMeetingInfo}
              defaultChecked={formData.displayMeetingInfo}
            />
          </Col>
        </Row>
        {isAlertVisible && (
          <div className={styles.alertWrapper}>
            <AlertMessage
              className={styles.alert}
              type="error"
              message={alertMessage}
              closeAlert={() => setIsAlertVisible(false)}
            />
          </div>
        )}
        {notificationFlag && (
          <div className={styles.notificationAlertWrapper}>
            <FixedAlertMessage
              type={'error'}
              message={ERROR_MESSAGE_PLAN_EDITING}
            />
          </div>
        )}
        <div className={styles.meetingTypeHeader}>
          <span
            className={`${
              !formData.displayMeetingInfo ? styles.disabledLabel : ''
            }`}
          >
            <b>Choose Meeting Type</b>
          </span>
          <Radio.Group
            onChange={onChangeMeetingType}
            className={styles.radioGroupSelection}
            defaultValue={
              formData.displayMeetingInfo ? formData.oeMeetingType : null
            }
            disabled={!formData.displayMeetingInfo}
            value={formData.displayMeetingInfo ? formData.oeMeetingType : null}
          >
            <Space direction="horizontal">
              <RadioButton value={OEMeetingType.FUTURE}>
                Link to future Open Enrollment meeting
              </RadioButton>
              <RadioButton value={OEMeetingType.RECORDED}>
                Link to previously recorded meeting
              </RadioButton>
            </Space>
          </Radio.Group>
        </div>

        <InputForm
          layout="vertical"
          form={form}
          disabledMode={!formData.displayMeetingInfo}
          onValuesChange={handleFormValuesChange}
          initialValues={formData}
        >
          <Form.Item
            hidden={formData.oeMeetingType === OEMeetingType.RECORDED}
            label="Meeting URL"
            name="meetingUrl"
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
                validateTrigger: 'onSubmit',
              },
              {
                validator: () => {
                  if (isValidUrl(form.getFieldValue('meetingUrl')?.trim())) {
                    return Promise.resolve();
                  } else {
                    setIsAlertVisible(true);
                    setAlertMessage(OE_MEETING_ALERT_MSGS.URL_ERROR);
                  }

                  return Promise.reject(new Error(''));
                },
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Input disabled={!formData.displayMeetingInfo} />
          </Form.Item>
          <Row>
            <Col span={8}>
              <Form.Item
                hidden={formData.oeMeetingType === OEMeetingType.RECORDED}
                label="Meeting Date"
                name={OE_MEETING_FORM_FIELDS.DATE}
                required
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    validateTrigger: 'onSubmit',
                  },
                  {
                    validator: () => timeValidator(false),
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <div className={styles.itemContainer}>
                  <DatePickerInput
                    allowClear
                    suffixIcon={
                      <CalendarIcon className={styles.calenderIcon} />
                    }
                    value={dateTime.date}
                    placeholder={INPUT_DATE_FORMAT_DEFAULT}
                    disabled={!formData.displayMeetingInfo}
                    className={styles.dateTimePicker}
                    onChange={(dateTime, dateTimeString) =>
                      onChangeDateTime(
                        dateTime,
                        dateTimeString,
                        OE_MEETING_FORM_FIELDS.DATE
                      )
                    }
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                hidden={formData.oeMeetingType === OEMeetingType.RECORDED}
                label="Meeting Time"
                name={OE_MEETING_FORM_FIELDS.TIME}
                required
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    validateTrigger: 'onSubmit',
                  },
                  {
                    validator: () => timeValidator(false),
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <div className={styles.itemContainer}>
                  <TimePickerInput
                    value={dateTime.time}
                    use12Hours
                    format={TWELVE_HOUR_TIME_FORMAT}
                    className={styles.dateTimePicker}
                    disabled={!formData.displayMeetingInfo}
                    onChange={(dateTime, dateTimeString) =>
                      onChangeDateTime(
                        dateTime,
                        dateTimeString,
                        OE_MEETING_FORM_FIELDS.TIME
                      )
                    }
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                hidden={formData.oeMeetingType === OEMeetingType.RECORDED}
                label="Time Zone"
                name={OE_MEETING_FORM_FIELDS.TIMEZONE}
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    validateTrigger: 'onSubmit',
                  },
                  {
                    validator: () => timeValidator(false),
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Select
                  allowClear
                  options={TIME_ZONES_IN_GMT}
                  disabled={!formData.displayMeetingInfo}
                  onChange={(value) =>
                    onSelectOption(value, OE_MEETING_FORM_FIELDS.TIMEZONE)
                  }
                  listHeight={128}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            hidden={formData.oeMeetingType === OEMeetingType.RECORDED}
            label={
              <>
                Duration
                <TooltipPopover
                  content='This field indicates how long the meeting is expected to last. The "Join Open Enrollment Meeting" tile will stay on the home screen for the expected duration of the meeting.'
                  placement="right"
                >
                  <QuestionCircleIcon
                    className={`${styles.tooltipIcon} ${
                      !formData.displayMeetingInfo ? styles.disabled : ''
                    }`}
                  />
                </TooltipPopover>
              </>
            }
            name="durationMinutes"
            required
            rules={[
              {
                validator: () => timeValidator(true),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <div className={styles.durationContainer}>
              <div className={styles.selectionContent}>
                <Select
                  value={selectedHours}
                  onChange={(value) =>
                    onSelectOption(value, DURATION_TYPES.HOURS)
                  }
                  options={convertArrayToOptions(OE_MEETING_HOURS_OPTIONS)}
                  disabled={!formData.displayMeetingInfo}
                />
              </div>
              <div className={styles.selectionLabel}>
                <span
                  className={
                    !formData.displayMeetingInfo ? styles.disabledLabel : ''
                  }
                >
                  hr
                </span>
              </div>
              <div className={styles.selectionContent}>
                <Select
                  value={selectedMins}
                  onChange={(value) =>
                    onSelectOption(value, DURATION_TYPES.MINUTES)
                  }
                  options={convertArrayToOptions(OE_MEETING_MINUTES_OPTIONS)}
                  disabled={!formData.displayMeetingInfo}
                />
              </div>
              <div className={styles.selectionLabel}>
                <span
                  className={
                    !formData.displayMeetingInfo ? styles.disabledLabel : ''
                  }
                >
                  min
                </span>
              </div>
            </div>
          </Form.Item>
          <Form.Item
            hidden={formData.oeMeetingType === OEMeetingType.FUTURE}
            label="Meeting Recording"
            name="recordingType"
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
                validateTrigger: 'onSubmit',
              },
              {
                validator: recordingTypeValidator,
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Radio.Group
              onChange={onChangeRecordingType}
              disabled={!formData.displayMeetingInfo}
              className={`${styles.radioGroupSelection} ${styles.recordingSection}`}
              value={formData.displayMeetingInfo ? selectedRecordingType : null}
              defaultValue={
                formData.displayMeetingInfo ? selectedRecordingType : null
              }
            >
              <Space direction="vertical">
                <div className={styles.recordingContainer}>
                  <RadioButton value={OEMeetingRecordType.URL}>
                    <div
                      className={`${styles.selectionLabel} ${
                        errorFields.url ? styles.invalidLabel : ''
                      }`}
                    >
                      <b>URL</b>
                    </div>
                  </RadioButton>
                  <div className={styles.selectionContent}>
                    <Input
                      value={recordingUrl ? recordingUrl : ''}
                      onChange={onRecordingUrlChange}
                      disabled={
                        !formData.displayMeetingInfo ||
                        !selectedRecordingType ||
                        selectedRecordingType === OEMeetingRecordType.VIDEO_FILE
                      }
                    />
                  </div>
                </div>
                <div className={styles.recordingContainer}>
                  <RadioButton value={OEMeetingRecordType.VIDEO_FILE}>
                    <div
                      className={`${styles.selectionLabel} ${
                        errorFields.videoFile ? styles.invalidLabel : ''
                      }`}
                    >
                      <b>Video File</b>
                    </div>
                  </RadioButton>
                  <div className={styles.selectionContent}>
                    <DocumentUploader
                      fileName={videoFileReference?.fileName}
                      isLoading={
                        requestType === uploadOEVideoStarted.type && inProgress
                      }
                      onRemoveFile={() => {
                        setVideoFile(emptyFile);
                        setVideoFileReference(null);
                        dispatch(resetOEVideoStateCompleted());
                      }}
                      ref={documentUploaderRef}
                      uploadSizeLimitInMB={1000}
                      onChange={onVideoUpload}
                      allowedFileTypes="video/mp4"
                      disabled={
                        !formData.displayMeetingInfo ||
                        !selectedRecordingType ||
                        selectedRecordingType === OEMeetingRecordType.URL
                      }
                      invalid={errorFields.videoFile}
                      alternateFileName={videoFileReference?.fileName}
                      onValidateSize={() => {
                        setErrorFields({ ...errorFields, videoFile: true });
                        setIsAlertVisible(true);
                        setAlertMessage(OE_MEETING_ALERT_MSGS.FILE_SIZE_ERROR);
                      }}
                      onValidateType={() => {
                        setIsAlertVisible(true);
                        setAlertMessage(OE_MEETING_ALERT_MSGS.FILE_TYPE_ERROR);
                      }}
                      downloadFileUrl={
                        benGuideId && getOEMeetingVideoRecording(benGuideId)
                      }
                      isExpand
                      isOEMeeting
                    />
                  </div>
                </div>
              </Space>
            </Radio.Group>
          </Form.Item>
        </InputForm>
        <ConfirmationDialog
          visible={isRegenerateModalOpen}
          onCancel={() => {
            cancelRegenerateNHC();
          }}
          cancelText={UPDATE_NHC_CANCEL_TEXT}
          flipFooterOrder
          okText={UPDATE_NHC_CONFIRMATION_TEXT}
          onOk={() => regenerateNHC()}
          title="Update New Hire Checklist?"
        >
          <>
            <p className={styles.nhcUpdateText}>
              {UPDATE_NHC_MODAL_DESCRIPTION}
            </p>
            <br />
            {NHC_REGENERATE_WARNING_MSG}
          </>
        </ConfirmationDialog>
      </div>
    </HalfScreenModal>
  );
};

export default OpenEnrollmentModal;
