import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const benefitConsultationV2Url = `${baseApi}/v2/benefit-consultants`;

export const getBenefitConsultationConfig = (
  organizationId: string,
  employerId: string
) => {
  return http.get(
    `${benefitConsultationV2Url}/organization/${organizationId}/employer/${employerId}/benguide-config`
  );
};

export const checkIfConsultantsExist = (
  consultantLevel: string,
  organizationId: string,
  employerId: string
) => {
  return http.get(
    `${benefitConsultationV2Url}/?consultation-level=${consultantLevel}&organization-id=${organizationId}&employer-id=${employerId}`
  );
};
