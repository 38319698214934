import axios from 'axios';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import AddPlanMultipleApiRequestPayload from 'model/AddPlanMultipleApiRequestPayload';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import SectionApiRequestPayload from 'model/SectionApiRequestPayload';
import { SectionName } from 'modules/home/constants';
import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const benguideApiV2 = `${baseApi}/v2/benefit-guides`;

export const updateBenefitSection = async (
  benguideId: string,
  sectionApiRequest: SectionApiRequestPayload
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/benefit-page`,
    sectionApiRequest
  );
};

export const addPlans = async (
  benguideId: string,
  addPlanApiRequest: AddPlanApiRequestPayload
) => {
  return http.put(`${benguideApiV2}/${benguideId}/plans`, addPlanApiRequest);
};

export const addMultiplePlans = async (
  benguideId: string,
  addPlanApiRequest: AddPlanMultipleApiRequestPayload
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/plans/multiple`,
    addPlanApiRequest
  );
};

export const getHolidayAndTimeOffSection = async (
  benguideId: string,
  revision: number
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=HOLIDAYS_AND_TIME_OFF&revision=${revision}`
  );
};

export const getFamilyAndLeaveSection = async (
  benguideId: string,
  revision: number
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=FAMILY_AND_LEAVE&revision=${revision}`
  );
};

export const getPlanSection = (
  benguideId: string,
  section: string,
  revision: number
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=${section}&revision=${revision}`
  );
};

export const updateRichMediaContent = async (
  benguideId: string,
  section: string,
  planId: string,
  content: string
) => {
  return http.put(`${benguideApiV2}/${benguideId}/plan/${planId}/rich-media`, {
    sectionName: section,
    title: '',
    content: content,
  });
};

export const getRichMediaContent = async (
  benguideId: string,
  section: string,
  planId: string
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/rich-media?section-name=${section}&planId=${planId}`
  );
};

export const getRichMediaContentForPlan = async (
  benguideId: string,
  section: SectionName,
  planId: string,
  defaultValue: boolean
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/plan/${planId}/rich-media?section-name=${section}&default-value=${defaultValue}`
  );
};

export const getSectionImageUrl = (
  benguideId: string,
  sectionName: SectionName,
  revision: number
) => {
  return `${benguideApiV2}/${benguideId}/images?section-name=${sectionName}&revision=${revision}`;
};

export const uploadSectionImage = async (
  benguideId: string,
  image: Blob,
  sectionName: SectionName,
  original?: boolean,
  originalRef?: string,
  cropArea?: OriginalImageCropParams
) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('section-name', sectionName);

  if (originalRef) {
    formData.append('original-reference', originalRef);
  }

  if (cropArea) {
    formData.append('height', cropArea.height.toString());
    formData.append('width', cropArea.width.toString());
    formData.append('x', cropArea.x.toString());
    formData.append('y', cropArea.y.toString());
  }

  if (original) {
    formData.append('original', 'true');
  }
  return http.put(`${benguideApiV2}/${benguideId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteSectionImage = async (
  benguideId: string,
  imageType: SectionName
) => {
  return http.delete(
    `${benguideApiV2}/${benguideId}/images?section-name=${imageType}`
  );
};

export const getBenefitPageSection = (benguideId: string, section: string) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/benefit-page?section=${section}`
  );
};

export const getLatestBenefitGuideByHash = (urlHash: string) => {
  return http.get(`${benguideApiV2}/edit-mode?url-hash=${urlHash}`);
};

export const getPlanRecommenderQuestions = (
  benguideId: string,
  benefitKind: string
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/plan-recommender/${benefitKind}`
  );
};

const cancelInfightAnswerRequest = (answerCancelationToken: any) => {
  const { currentToken } = answerCancelationToken;
  if (typeof currentToken === 'function') {
    currentToken();
  }
};

const CancelToken = axios.CancelToken;
const answerCancelationToken = {} as any;

export const updatePlanRecommenderAnswers = (
  benguideId: string,
  benefitKind: string,
  answers: any
) => {
  cancelInfightAnswerRequest(answerCancelationToken);

  return http.post(
    `${benguideApiV2}/${benguideId}/plan-recommender/${benefitKind}`,
    answers,
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        answerCancelationToken.currentToken = c;
      }),
    }
  );
};

export const fetchPlanRecommenderResult = async (
  benguideId: string,
  benefitKind: string,
  answers: any
) => {
  return http.post(
    `${benguideApiV2}/${benguideId}/plan-recommender/${benefitKind}/result`,
    answers
  );
};

export const getCommonImageUrl = (
  dbgMasterId: string,
  type: string,
  revision: number
) => {
  return `${benguideApiV2}/${dbgMasterId}/common-images?type=${type}&revision=${revision}`;
};

export const planRecommenderToggle = async (
  benguideId: string,
  section: string,
  data: any
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/section/${section}/enabled`,
    data
  );
};
