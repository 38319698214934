import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Similar to useState hook used to store the state, adds ability to call a
 * function directly after changing the state.
 * Useful when function is dependant on the state change.
 * @param initialState initial state
 * @returns state and function to change the state
 */

export function useStateCallback(initialState: any) {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef<any>(null);
  const setStateCallback = useCallback((state, callback) => {
    callbackRef.current = callback;
    setState(state);
  }, []);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}
