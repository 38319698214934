import { FC, MouseEventHandler } from 'react';
import { Helmet } from 'react-helmet';
import iconBack from 'images/icons/icon-back.png';
import useBenguide from 'hooks/benguide';

import styles from './planRecommenderHeader.module.less';

type PlanRecommenderHeaderProps = {
  onBackPress: MouseEventHandler<HTMLImageElement>;
  currentStep: number;
  stepCount: number;
};

const PlanRecommenderHeader: FC<PlanRecommenderHeaderProps> = (
  props: PlanRecommenderHeaderProps
) => {
  const { defaultEmployerLogo } = useBenguide();
  const { onBackPress, currentStep, stepCount } = props;
  const renderTitle = (currentStep: number) => {
    return (
      <Helmet>
        <title>{`Benefits Guide | Plan Recommender | Step ${
          currentStep + 1
        } `}</title>
      </Helmet>
    );
  };

  let stepText = `${currentStep + 1}/${stepCount} Questions`;
  if (currentStep === stepCount) {
    stepText = 'Your Recommendation';
  }
  if (currentStep > stepCount) {
    stepText = '';
  }
  const indicatorStyles = {
    width: `${(currentStep / stepCount) * 100}%`,
  };

  return (
    <div className={`${styles.planRecommenderHeader} container`}>
      {renderTitle(currentStep)}
      <div className={styles.headerTop}>
        <div className={styles.backArrow}>
          {currentStep === 0 ? (
            <></>
          ) : (
            <img src={iconBack} onClick={onBackPress} alt="" />
          )}
        </div>
        <div className={styles.centerLogo}>
          <img alt="" src={defaultEmployerLogo} />
        </div>
        <div className={styles.completePercentage}>{stepText} </div>
      </div>
      <div className={styles.headerBottom}>
        <div className={styles.indicator} style={indicatorStyles}></div>
      </div>
    </div>
  );
};

export default PlanRecommenderHeader;
