import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { ModalProps } from 'antd/lib/modal/Modal';
import { Col, Row, Select } from 'antd';
import { isEmpty } from 'lodash';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import SwitchButton from 'components/SwitchButton/SwitchButton';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import { FREQUENCIES } from 'modules/benefitsWellness/constants/BenefitConstants';
import { ReactComponent as QuestionCircleIcon } from 'images/question-round.svg';
import BoxPopover from 'components/BoxPopover/BoxPopover';
import ContributionFrequency from 'model/ContributionFrequency';
import {
  checkBenefitGuidesRates,
  updateDBGFrequencyConfiguration,
} from 'modules/benefitsWellness/slices/benefitWellnessSlice';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  DENTAL_PLAN_CHANNEL,
  MEDICAL_PLAN_CHANNEL,
  OPEN_RATES_MODAL,
  VISION_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import {
  BENEFIT_CATEGORY,
  BENEFIT_TYPES,
  ERROR_MESSAGE_PLAN_EDITING,
} from 'constants/commonConstants';
import { findFrequencyByBenefitKind } from 'modules/benefitsWellness/util';
import { NotificationContext } from 'context/NotificationContext';

import { enablePlanRecommenderSection } from 'modules/benefitsWellness/slices/medicalSlice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { getPlanRecommenderStatus } from 'modules/benefitsWellness/slices/PlanRecommenderEnableSlice';
import styles from './frequencyConfiguration.module.less';

interface FrequencyConfigurationProps extends ModalProps {
  isOpenModal: boolean;
  closeModal: any;
  onCancel?: any;
  benefitKind: string;
  onCloseContributions: Function;
}

const FrequencyConfiguration: FC<FrequencyConfigurationProps> = (
  props: FrequencyConfigurationProps
) => {
  const { isOpenModal, closeModal, benefitKind, onCloseContributions } = props;
  const [isConfirmationVisible, setIsConfirmationVisible] =
    useState<boolean>(false);
  const [initalClick, setInitalClick] = useState<boolean>(false);
  const currentBenguide = useBenguide();
  const upClient = useUPClient();

  const [formData, setFormData] = useState<ContributionFrequency>({
    benefitKind: benefitKind,
    frequency: 'MONTHLY',
    enabled: false,
  });

  const { planRecommender } = useAppSelector(
    (state) => state.planRecommenderStatus
  );

  const [isChecked, setIsChecked] = useState<boolean>(formData.enabled);
  const [closeStatus, setCloseStatus] = useState<boolean>(formData.enabled);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const [initialFormData, setInitialFormData] = useState<ContributionFrequency>(
    {
      benefitKind: benefitKind,
      frequency: 'MONTHLY',
      enabled: false,
    }
  );

  const dispatch = useAppDispatch();
  const { rateValidations, inProgress } = useAppSelector(
    (state) => state.benefitWellness
  );
  const { planRecommenderUpdateInProgress } = useAppSelector(
    (state) => state.medical
  );
  const notificationFlag = useContext(NotificationContext);

  useEffect(() => {
    dispatch(checkBenefitGuidesRates(currentBenguide.masterId, benefitKind));
    if (BENEFIT_CATEGORY.MEDICAL.value === benefitKind) {
      dispatch(getPlanRecommenderStatus(currentBenguide.masterId));
    }
  }, [benefitKind, currentBenguide.masterId, dispatch, isOpenModal]);

  useEffect(() => {
    if (isOpenModal) setIsChecked(formData.enabled);
  }, [isOpenModal, formData.enabled]);

  useEffect(() => {
    const matchingContribFrequency = findFrequencyByBenefitKind(
      currentBenguide.contributionFrequencies,
      benefitKind
    );
    if (matchingContribFrequency) {
      setFormData(matchingContribFrequency);
      setInitialFormData(matchingContribFrequency);
    }
  }, [benefitKind, currentBenguide.contributionFrequencies, isOpenModal]);

  const onInputChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };
  const onSwitchChange = (checked: boolean) => {
    if (checked) {
      setFormData({ ...formData, enabled: checked });
    }
  };

  const onConfirmationOk = () => {
    if (planRecommender.enabled) {
      const matchingContribFrequency =
        findFrequencyByBenefitKind(
          currentBenguide.contributionFrequencies,
          benefitKind
        ) || ({} as ContributionFrequency);
      const { frequency } = matchingContribFrequency;
      setFormData({ ...formData, frequency: frequency, enabled: false });
      dispatch(enablePlanRecommenderSection(false, currentBenguide.masterId));
      setIsChecked(false);
      setIsClicked(true);
      setIsConfirmationVisible(false);
    } else {
      setIsChecked(false);
      setFormData({
        ...formData,
        frequency: initialFormData.frequency,
        enabled: false,
      });
    }
  };

  const onSubmit = useCallback(() => {
    dispatch(
      updateDBGFrequencyConfiguration(currentBenguide.masterId, formData)
    );
    setCloseStatus(!closeStatus);
    onCloseContributions(closeStatus);
    closeModal();
  }, [
    closeModal,
    closeStatus,
    currentBenguide.masterId,
    dispatch,
    formData,
    onCloseContributions,
  ]);

  useEffect(() => {
    if (isClicked && !planRecommenderUpdateInProgress) {
      setIsClicked(false);
      onSubmit();
    }
  }, [onSubmit, isClicked, planRecommenderUpdateInProgress]);

  useEffect(() => {
    if (initalClick) {
      onSubmit();
      setInitalClick(false);
    }
  }, [initalClick, onSubmit, isClicked]);

  const openPlanRates = (plan: any) => {
    closeModal();
    let channel;
    if (benefitKind === BENEFIT_CATEGORY.MEDICAL.value) {
      channel = MEDICAL_PLAN_CHANNEL;
    } else if (benefitKind === BENEFIT_CATEGORY.DENTAL.value) {
      channel = DENTAL_PLAN_CHANNEL;
    } else {
      channel = VISION_PLAN_CHANNEL;
    }
    upClient.postMessage({
      channel: channel,
      event: OPEN_RATES_MODAL,
      data: plan,
      dbgMetaData: currentBenguide,
    });
  };

  return (
    <HalfScreenModal
      width="600px"
      title="Edit Contribution Display"
      visible={isOpenModal}
      isLoading={inProgress}
      onCancel={() => {
        closeModal();
        setCloseStatus(!closeStatus);
        onCloseContributions(closeStatus);
      }}
      className={styles.frequencyWrapper}
      onSubmit={() => {
        onSubmit();
        setInitalClick(true);
      }}
    >
      {notificationFlag && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      <Row className={styles.frequencyDetailsRow}>
        <Col span={11}>
          <h4>Display {BENEFIT_TYPES[benefitKind]} Contributions</h4>
        </Col>
        <Col span={12}>
          <SwitchButton
            disabled={rateValidations.hasMismatchContributions}
            onChange={onSwitchChange}
            onClick={(value) =>
              value
                ? setIsChecked(true)
                : planRecommender.enabled
                ? setIsConfirmationVisible(true)
                : onConfirmationOk()
            }
            checked={isChecked}
          />
        </Col>
      </Row>
      <Row className={styles.frequencyDetailsRow}>
        <Col span={11}>
          <Row>
            <h4>Cost Frequency Display</h4>
            <BoxPopover
              header="Cost Frequency"
              description="You can change the frequency of the displayed employee contributions here. The contributions will auto-calculate based on the existing values and can be updated within each plan if needed."
              trigger="hover"
            >
              <QuestionCircleIcon className={styles.popoverIcon} />
            </BoxPopover>
          </Row>
        </Col>
        <Col span={12} className={styles.frequencyDisplayRow}>
          <Select
            className={styles.frequencySelect}
            defaultValue="MONTHLY"
            value={formData.frequency}
            onChange={(value) => {
              onInputChange(value, 'frequency');
            }}
            options={Object.values(FREQUENCIES)}
            disabled={
              !formData.enabled || rateValidations.hasMismatchContributions
            }
          />
        </Col>
      </Row>
      {!isEmpty(rateValidations) && rateValidations.hasMismatchContributions && (
        <>
          <Row className={styles.frequencyDetailsRow}>
            <div className={styles.disabledContributionNotice}>
              <Row>
                <Col span={2}>
                  <ExclamationCircleTwoTone
                    width="3em"
                    height="3em"
                    twoToneColor="#f9c747"
                  />
                </Col>
                <Col span={22}>
                  <span>
                    Displaying contributions is disabled and are hidden from
                    view in the Benefits Guide because they are different across
                    Benefit Classes
                  </span>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className={styles.frequencyDetailsRow}>
            <span>The following plans contain contribution differences</span>
          </Row>
          {!isEmpty(rateValidations) &&
            !isEmpty(rateValidations.mismatchedPlans) &&
            rateValidations.mismatchedPlans.map((plan) => {
              return (
                <Row key={plan.id}>
                  <span
                    className={styles.planRedirectLink}
                    onClick={() => {
                      openPlanRates(plan);
                    }}
                  >
                    {plan.name}
                  </span>
                </Row>
              );
            })}
        </>
      )}

      <ConfirmationDialog
        title="Plan Recommender Update"
        okText="No - Continue Editing"
        cancelText="Yes - Save Changes"
        visible={isConfirmationVisible}
        onCancel={onConfirmationOk}
        onOk={() => setIsConfirmationVisible(false)}
      >
        <div>
          <div>
            Disabling contributions will turn off the Plan Recommender tool for
            this guide.
          </div>
          <br></br>
          <div>Are you sure you want to make these changes?</div>
        </div>
      </ConfirmationDialog>
    </HalfScreenModal>
  );
};

export default FrequencyConfiguration;
