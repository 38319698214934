import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import UPClient from 'modules/clients/UPClient/UPClient';
import useUPClient from 'modules/clients/UPClient/useUPClient';

import {
  FAMILY_AND_LEAVE_CHANNEL,
  RELOAD_FAMILY_AND_LEAVE_PLAN_LIST,
  RELOAD_FAMILY_AND_LEAVE_SECTION,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
} from 'modules/clients/UPClient/UPClientConst';
import {
  fetchFamilyAndLeavePlanList,
  reloadBenefitAndFamilyAndLeaveSection,
} from 'modules/familyAndLeave/slices/familyAndLeaveSlice';

const FamilyAndLeavePlansChannel = () => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();
  return (
    <UPClient
      channel={FAMILY_AND_LEAVE_CHANNEL}
      subscribe={(event: string) => {
        switch (event) {
          case RELOAD_FAMILY_AND_LEAVE_PLAN_LIST:
            dispatch(
              fetchFamilyAndLeavePlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case RELOAD_FAMILY_AND_LEAVE_SECTION:
            dispatch(
              reloadBenefitAndFamilyAndLeaveSection(
                benguide.masterId,
                benguide.latestRevision
              )
            );
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default FamilyAndLeavePlansChannel;
