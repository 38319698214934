import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import { getRichMedia } from 'modules/auth/services/BenguideService';

import { SectionName } from 'modules/home/constants';
import RichMedia from 'model/RichMedia';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { convertEncodedStringToBlob } from 'utils/fileUtil';
import { updateBenguideLatestRevision } from 'modules/auth/slices/benguideSlice';
interface NHCState {
  defaultContent: string;
  currentContent: string;
  inProgress: boolean;
  error: any;
  requestType: string;
  sectionImage: string | undefined;
  latestRevision: number;
  sectionImageOriginal: string | null;
  sections: any;
}

const initialState = {
  defaultContent: '',
  currentContent: '',
  inProgress: false,
  error: null,
  requestType: '',
  sectionImage: '',
  latestRevision: 0,
  sectionImageOriginal: '',
  sections: [],
} as NHCState;

const currentContentInProgress = (state: NHCState, action: PayloadAction) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.currentContent = '';
  state.error = null;
};

const currentContentCompleted = (
  state: NHCState,
  action: PayloadAction<RichMedia>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentContent = action.payload.content;
  state.error = null;
};

const currentContentFailed = (state: NHCState, action: PayloadAction) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentContent = '';
  state.error = action.payload;
};

const nhcSlice = createSlice({
  name: 'newHireChecklist',
  initialState,
  reducers: {
    getDefaultRichMediaStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.defaultContent = '';
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultRichMediaSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultContent = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultRichMediaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultContent = '';
      state.error = action.payload;
      state.requestType = action.type;
    },

    createRichMediaStarted(state, action: PayloadAction) {
      currentContentInProgress(state, action);
    },
    createRichMediaSuccess(state, action: PayloadAction<RichMedia>) {
      currentContentCompleted(state, action);
    },
    createRichMediaFailed(state, action: PayloadAction<any>) {
      currentContentFailed(state, action);
    },

    getCurrentRichMediaStarted(state, action: PayloadAction) {
      currentContentInProgress(state, action);
    },
    getCurrentRichMediaSuccess(state, action: PayloadAction<RichMedia>) {
      currentContentCompleted(state, action);
    },
    getCurrentRichMediaFailed(state, action: PayloadAction<any>) {
      currentContentFailed(state, action);
    },

    uploadSectionImageStart(
      state,
      action: PayloadAction<{ image: string; original?: string }>
    ) {
      state.sectionImage = action.payload.image;
      state.requestType = action.type;
      state.error = null;
      state.inProgress = true;

      if (action.payload.original) {
        state.sectionImageOriginal = action.payload.original;
      }
    },
    uploadSectionImageSuccess(state, action: PayloadAction<RichMedia>) {
      state.error = null;
      state.requestType = action.type;
      state.sectionImage = action.payload.content;
      state.inProgress = false;
    },
    uploadSectionImageFailed(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.sectionImage = '';
      state.requestType = action.type;
      state.inProgress = false;
    },
    deleteSectionImageStart(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    deleteSectionImageSuccess(state, action: PayloadAction<number>) {
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
      state.latestRevision = action.payload;
    },
    deleteSectionImagaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
      state.requestType = action.type;
    },
    updateNHCContentStarted(state) {
      state.error = null;
      state.inProgress = true;
    },
    updateNHCContentSuccess(state, action: PayloadAction<string>) {
      state.error = null;
      state.requestType = action.type;
      state.inProgress = false;
    },
    updateNHCContentFailed(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.requestType = action.type;
      state.inProgress = false;
    },
    setSections(state, { payload }) {
      state.sections = payload;
    },
  },
});

export const {
  getDefaultRichMediaStarted,
  getDefaultRichMediaSuccess,
  getDefaultRichMediaFailed,
  createRichMediaStarted,
  createRichMediaSuccess,
  createRichMediaFailed,
  getCurrentRichMediaStarted,
  getCurrentRichMediaSuccess,
  getCurrentRichMediaFailed,
  deleteSectionImageStart,
  deleteSectionImageSuccess,
  deleteSectionImagaFailed,
  uploadSectionImageStart,
  uploadSectionImageSuccess,
  uploadSectionImageFailed,
  updateNHCContentStarted,
  updateNHCContentSuccess,
  updateNHCContentFailed,
  setSections,
} = nhcSlice.actions;

export default nhcSlice.reducer;

export const getDefaultRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getDefaultRichMediaFailed(error));
    }
  };

export const createRichMedia =
  (
    benguideId: string,
    sectionName: SectionName,
    content: string,
    onCreate?: Function
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(createRichMediaStarted());
    try {
      const data = {
        sectionName: sectionName,
        content: content,
      };
      await BenGuideService.createRichMedia(benguideId, data);
      const responseData: RichMedia = {
        content: content,
        benGuideId: '',
        title: '',
      };
      dispatch(createRichMediaSuccess(responseData));
      onCreate && onCreate();
    } catch (error) {
      dispatch(createRichMediaFailed(error));
    }
  };

export const getCurrentRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getCurrentRichMediaStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(getCurrentRichMediaSuccess(response.data));
    } catch (error) {
      dispatch(getCurrentRichMediaFailed(error));
    }
  };

export const handleSectionImageUpload =
  (
    benguideId: string,
    image: string,
    sectionName: SectionName,
    originalImage?: string,
    cropArea?: OriginalImageCropParams,
    originalRef?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadSectionImageStart({ image, original: originalImage }));
    try {
      let updateOriginalRef = originalRef;

      if (originalImage) {
        const originalImgBlob = await convertEncodedStringToBlob(originalImage);
        const originalImgRes = await BenGuideService.uploadSectionImage(
          benguideId,
          originalImgBlob,
          sectionName,
          true
        );
        updateOriginalRef = originalImgRes.data.imageReference;
      }
      const imageBlob = await convertEncodedStringToBlob(image);
      const response = await BenGuideService.uploadSectionImage(
        benguideId,
        imageBlob,
        sectionName,
        false,
        updateOriginalRef,
        cropArea
      );
      const responseData: RichMedia = {
        content: response.data,
        benGuideId: '',
        title: '',
      };
      dispatch(reloadNHCSection(benguideId, response.data.latestRevision));
      dispatch(uploadSectionImageSuccess(responseData));
      dispatch(updateBenguideLatestRevision(response.data.latestRevision));
    } catch (error) {
      dispatch(uploadSectionImageFailed(error));
    }
  };

export const handleSectionImageDelete =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(deleteSectionImageStart());
    try {
      const response = await BenGuideService.deleteSectionImage(
        benguideId,
        sectionName
      );
      dispatch(deleteSectionImageSuccess(response.data.latestRevision));
    } catch (error) {
      dispatch(deleteSectionImagaFailed(error));
    }
  };

export const regenerateNHCContent: any =
  (benguideId: string, section: string) => async (dispatch: Dispatch) => {
    dispatch(updateNHCContentStarted());
    try {
      const response = await BenGuideService.regenerateContent(
        benguideId,
        section
      );
      dispatch(updateNHCContentSuccess(response.data));
    } catch (error) {
      dispatch(updateNHCContentFailed(error));
    }
  };

// TODO: update NHC section
export const reloadNHCSection: any = (benguideId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await BenGuideService.getContentPage(
        benguideId,
        'NEW_HIRE_CHECKLIST'
      );
      dispatch(setSections(data.contentPageVO.sections));
    } catch (error) {}
  };
};
