import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

type Props = {
  onConfirm?: Function;
  onClose?: Function;
  isVisible?: boolean;
  confirmLoading?: boolean;
};

const SavePlanDescriptionModal = ({
  isVisible,
  onClose,
  onConfirm,
  confirmLoading,
}: Props) => {
  return (
    <ConfirmationDialog
      title="Save Plan Description"
      okText="Save & Continue"
      cancelText="Cancel"
      onCancel={() => onClose?.()}
      onOk={() => onConfirm?.()}
      visible={isVisible}
      isCancelLink
      destroyOnClose
      flipFooterOrder
      btnLoading={confirmLoading}
    >
      You are editing the plan description. This change will be reflected in all
      instances of this plan, including in the Digital Benefits Guide. Do you
      want to proceed?
    </ConfirmationDialog>
  );
};

export default SavePlanDescriptionModal;
