import { Popover } from 'antd';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './overflowPopover.module.less';

type Props = {
  popoverContent?: string | ReactNode;
  maxWidth?: string | number;
  showPopover?: boolean;
  popoverZIndex?: number;
  children: ReactNode;
  placement?: 'top' | 'right';
};

const OverflowPopover = (props: Props) => {
  const {
    popoverContent,
    maxWidth,
    showPopover = true,
    popoverZIndex = 99999,
    placement,
  } = props;

  const [isOverflow, setIsOverflow] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);
  const overFlowDiv = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      const { scrollWidth, offsetWidth } = divRef.current;
      const isWidthOverflow = scrollWidth > offsetWidth;
      setIsOverflow(isWidthOverflow);
    }
    if (overFlowDiv.current) {
      const { scrollWidth, offsetWidth } = overFlowDiv.current;
      const isWidthOverflow = scrollWidth > offsetWidth;
      setIsOverflow(isWidthOverflow);
    }
  }, [props.children, size]);

  if (isOverflow) {
    if (showPopover) {
      return (
        <Popover
          content={popoverContent ? popoverContent : props.children}
          placement={placement ? placement : 'top'}
          overlayClassName={
            placement === 'right' ? styles.overlayRight : styles.overlayTop
          }
          zIndex={popoverZIndex}
          className={styles.overFlowWrapper}
        >
          <div
            ref={overFlowDiv}
            className={styles.container}
            style={maxWidth ? { maxWidth: maxWidth } : {}}
          >
            {props.children}
          </div>
        </Popover>
      );
    } else {
      return (
        <div
          ref={overFlowDiv}
          className={styles.container}
          style={maxWidth ? { maxWidth: maxWidth } : {}}
        >
          {props.children}
        </div>
      );
    }
  }

  return (
    <div
      className={styles.container}
      ref={divRef}
      style={maxWidth ? { maxWidth: maxWidth } : {}}
    >
      {props.children}
    </div>
  );
};

export default OverflowPopover;
