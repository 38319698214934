import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import Plan from 'model/Plan';
import PlanReference from 'model/PlanReference';
import {
  getAllTaxAdvantagedPlans,
  getTaxAdvantagedPlanList,
} from 'modules/benefitsWellness/services/TaxAdvantagedPlanService';
import * as BenGuideService from 'services/BenGuideService';
import * as PlanService from 'modules/auth/services/PlanService';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import SectionApiRequestPayload from 'model/SectionApiRequestPayload';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import {
  COMMUTER_PLAN_CONTENT_TABS,
  TaxAdvantagedSection,
} from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { SectionName } from 'modules/home/constants';

interface TaxAdvantagedState {
  planList: {
    inProgress: boolean;
    data: Plan[];
  };
  commuterSection: {
    enabled: boolean;
    plans: PlanReference[];
  };
  selectedPlans: {
    data: TaxAdvantageAccountsPlan[];
    inProgress: boolean;
  };
  inProgress: boolean;
  defaultRichMedia: {
    inProgress: boolean;
    error: any;
    data: {
      [key in string]: string;
    };
  };
}

const initialState: TaxAdvantagedState = {
  planList: {
    inProgress: false,
    data: [],
  },
  commuterSection: {
    enabled: false,
    plans: [],
  },
  selectedPlans: {
    data: [],
    inProgress: false,
  },
  inProgress: false,
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.COMMUTER_OVERVIEW_TEXT]: '',
      [SectionName.COMMUTER_ELIGIBILITY_TEXT]: '',
      [SectionName.COMMUTER_EXPENSES_TEXT]: '',
      [SectionName.COMMUTER_ADVANTAGES_TEXT]: '',
      [SectionName.COMMUTER_CONSIDERATIONS_TEXT]: '',
    },
  },
};

type PlanFetchData = {
  employerId: string;
  planYearId: string;
  benefitClasses: string[];
};

type SelectPlanParams = {
  benguideId: string;
  addPlansPayload: AddPlanApiRequestPayload;
  benefitKind: string;
};

export const fetchCommuterPlans = createAsyncThunk<any, PlanFetchData>(
  'commuter/fetchCommuterPlans',
  async ({ employerId, planYearId, benefitClasses }) => {
    const response = await getTaxAdvantagedPlanList(
      employerId,
      planYearId,
      ['COMMUTER_GENERIC'],
      benefitClasses
    );
    return response.data.content;
  }
);

export const selectCommuterPlans = createAsyncThunk<
  SectionApiRequestPayload,
  SelectPlanParams
>('commuter/selectCommuterPlans', async ({ benguideId, addPlansPayload }) => {
  await BenGuideService.addPlans(benguideId, addPlansPayload);
  const response = await BenGuideService.getBenefitPageSection(
    benguideId,
    TaxAdvantagedSection.COMMUTER
  );
  return response.data;
});

export const getCommuterSection = createAsyncThunk<
  SectionApiRequestResponse,
  { benguideId: string }
>('commuter/getBenefitSection', async ({ benguideId }) => {
  const response = await BenGuideService.getBenefitPageSection(
    benguideId,
    TaxAdvantagedSection.COMMUTER
  );
  return response.data;
});

export const getPlansFromPlanRefs = createAsyncThunk<
  TaxAdvantageAccountsPlan[],
  { planRefs: PlanReference[] }
>('commuter/getPlansFromPlanRefs', async ({ planRefs }) => {
  const planPromises = planRefs.map((plan) => {
    const { planId, revision } = plan;
    return PlanService.getPlans('tax-advantage-accounts', planId, revision);
  });
  const promiseResponses = await Promise.all(planPromises);
  return promiseResponses.map((plan) => plan.data);
});

const commuterSlice = createSlice({
  name: 'commuter',
  initialState,
  reducers: {
    updateCommuterSection(state, { payload }) {
      state.commuterSection = payload;
    },
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.data[payload.section] = payload.media.content;
    },
    getDefaultMediaFailed(state, payload) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
    listAllCommuterPlansStarted(state) {
      state.planList.inProgress = true;
    },
    listAllCommuterPlansSuccess(state, { payload }) {
      state.planList.inProgress = false;
      state.planList.data = payload;
    },
    listAllCommuterPlansFailed(state) {
      state.planList.inProgress = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommuterPlans.pending, (state) => {
      state.planList.inProgress = true;
    });
    builder.addCase(fetchCommuterPlans.fulfilled, (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.data = payload;
    });
    builder.addCase(selectCommuterPlans.pending, (state) => {
      state.selectedPlans.inProgress = true;
    });
    builder.addCase(selectCommuterPlans.fulfilled, (state, { payload }) => {
      state.selectedPlans.inProgress = false;
      state.commuterSection = payload.benefitPage;
    });
    builder.addCase(getCommuterSection.pending, (state) => {
      state.inProgress = true;
    });
    builder.addCase(getCommuterSection.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.commuterSection = payload.benefitPage;
    });
    builder.addCase(getPlansFromPlanRefs.pending, (state) => {
      state.selectedPlans.inProgress = true;
    });
    builder.addCase(getPlansFromPlanRefs.fulfilled, (state, { payload }) => {
      state.selectedPlans.inProgress = false;
      state.selectedPlans.data = payload;
    });
  },
});

export const {
  updateCommuterSection,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
  listAllCommuterPlansStarted,
  listAllCommuterPlansSuccess,
  listAllCommuterPlansFailed,
} = commuterSlice.actions;

export default commuterSlice.reducer;

export const listCommuterPlans = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(listAllCommuterPlansStarted());
    try {
      const response = await getAllTaxAdvantagedPlans(
        employerId,
        planYearId,
        ['COMMUTER_GENERIC', 'PARKING', 'BIKING', 'TRANSIT'],
        benefitClasses
      );
      dispatch(listAllCommuterPlansSuccess(response.data));
    } catch (error) {
      dispatch(listAllCommuterPlansFailed());
    }
  };
};

export const getDefaultMediaForCommuter = (
  benguideId: string,
  planId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getDefaultMediaStarted());
    COMMUTER_PLAN_CONTENT_TABS.forEach((section) => {
      BenGuideService.getRichMediaContentForPlan(
        benguideId,
        section.code,
        planId,
        true
      )
        .then(({ data }) => {
          dispatch(
            getDefaultMediaSuccess({
              section: section.code,
              media: data,
            })
          );
        })
        .catch((error) => {
          dispatch(getDefaultMediaFailed(error));
        });
    });
  };
};
