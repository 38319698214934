import { FC, useEffect, useState } from 'react';

import reactHtmlParser from 'react-html-parser';
import { Col, Collapse, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { addHttp } from 'utils/httpUtil';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SectionName } from 'modules/home/constants';
import ResourceCard from 'modules/resources/components/ResourceCard';
import {
  getCurrentRichMedia,
  getDefaultRichMedia,
  getLatestBenguideByHash,
  initResourceData,
} from 'modules/resources/slices/resourcesSlice';
import { getPlansFromPlanRefs } from 'modules/benefitsWellness/slices/lifeAndDisabilitySilce';
import { getPlansFromPlanRefs as getHSAPlansFromPlanRefs } from 'modules/taxAdvantaged/slices/hsaSlice';
import { getPlansFromPlanRefs as getHRAPlansFromPlanRefs } from 'modules/taxAdvantaged/slices/hraSlice';
import { getPlansFromPlanRefs as getFSAPlansFromPlanRefs } from 'modules/taxAdvantaged/slices/fsaSlice';
import { getPlansFromPlanRefs as getCommuterPlansFromPlanRefs } from 'modules/taxAdvantaged/slices/commuterSlice';
import PageHeader from 'components/PageHeader/PageHeader';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import iconEdit from 'images/icon-edit.svg';
import expandIcon from 'images/icons/icon-expand.svg';
import { ReactComponent as ResourcesIcon } from 'images/pageHeaderIcons/resources.svg';
import AdditionalResource from 'model/AdditionalResource';
import AddAdditionalResource from 'modules/resources/components/AddAdditionalResource/AddAdditionalResource';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import ResourceTextEditor from 'modules/resources/components/ResourceTextEditor/ResourceTextEditor';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';

import styles from './resources.module.less';

const { Panel } = Collapse;

const Resources: FC = () => {
  const { masterId, isEditMode, latestRevision, status, urlHash } =
    useBenguide();
  const dispatch = useAppDispatch();
  const {
    resources,
    latestBenefitguide,
    defaultContent,
    currentContent,
    planDocsUploadInProgress,
    planDocsUploadSuccess,
    allPlanDocsUpdatingSuccess,
    planDocsRemoveSuccess,
  } = useAppSelector((state) => state.resources);
  const { selectedPlans } = useAppSelector((state) => state.lifeAndDisability);
  const { medicalSection } = useAppSelector((state) => state.medical);
  const { dentalSection } = useAppSelector((state) => state.dental);
  const { visionSection } = useAppSelector((state) => state.vision);
  const { fourOOneKSection } = useAppSelector((state) => state.fourOOneK);
  const { wellbeingSection } = useAppSelector((state) => state.wellbeing);
  const { telehealthSection } = useAppSelector((state) => state.telehealth);
  const { selectedPlans: hsaPlans } = useAppSelector((state) => state.hsa);
  const { selectedPlans: fsaPlans } = useAppSelector((state) => state.fsa);
  const { selectedPlans: hraPlans } = useAppSelector((state) => state.hra);
  const { selectedPlans: commuterPlans } = useAppSelector(
    (state) => state.commuter
  );
  const { volBenefitsSection } = useAppSelector(
    (state) => state.voluntaryBenefits
  );
  const { isTablet, isMobile } = useAppSelector((state) => state.layout);
  const { resources: additionalResources } = latestBenefitguide;

  const [contentData, setContentData] = useState<string>('');
  const [displayData, setDisplayData] = useState<string>('');
  const [activePanel, setActivePanel] = useState<string>('');
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);

  useEffect(() => {
    const { lifeAndDisability, hsa, hra, fsa, commuter } = latestBenefitguide;
    if (lifeAndDisability) {
      const planRefs = lifeAndDisability.plans;
      dispatch(getPlansFromPlanRefs({ planRefs }));
    }
    if (hsa) {
      const planRefs = hsa.plans;
      dispatch(getHSAPlansFromPlanRefs({ planRefs }));
    }
    if (fsa) {
      const planRefs = fsa.plans;
      dispatch(getFSAPlansFromPlanRefs({ planRefs }));
    }
    if (hra) {
      const planRefs = hra.plans;
      dispatch(getHRAPlansFromPlanRefs({ planRefs }));
    }
    if (commuter) {
      const planRefs = commuter.plans;
      dispatch(getCommuterPlansFromPlanRefs({ planRefs }));
    }
  }, [dispatch, latestBenefitguide]);

  useEffect(() => {
    if (
      allPlanDocsUpdatingSuccess &&
      medicalSection &&
      dentalSection &&
      visionSection &&
      hsaPlans &&
      hraPlans &&
      fsaPlans &&
      commuterPlans &&
      wellbeingSection &&
      fourOOneKSection &&
      volBenefitsSection &&
      telehealthSection &&
      selectedPlans
    ) {
      dispatch(initResourceData());
    }
  }, [
    dispatch,
    allPlanDocsUpdatingSuccess,
    medicalSection,
    dentalSection,
    visionSection,
    fourOOneKSection,
    hsaPlans,
    hraPlans,
    fsaPlans,
    commuterPlans,
    telehealthSection,
    wellbeingSection,
    volBenefitsSection,
    selectedPlans,
  ]);

  useEffect(() => {
    if (
      selectedPlans &&
      hsaPlans &&
      hraPlans &&
      fsaPlans &&
      commuterPlans &&
      latestBenefitguide
    ) {
      dispatch(initResourceData());
    }
  }, [
    dispatch,
    latestBenefitguide,
    selectedPlans,
    hsaPlans,
    hraPlans,
    fsaPlans,
    commuterPlans,
  ]);

  useEffect(() => {
    if (urlHash) {
      dispatch(getLatestBenguideByHash(urlHash));
      dispatch(getDefaultRichMedia(masterId, SectionName.RESOURCES_TEXT));
      dispatch(getCurrentRichMedia(masterId, SectionName.RESOURCES_TEXT));
    }
  }, [masterId, urlHash, dispatch, latestRevision, status]);

  useEffect(() => {
    if (
      urlHash &&
      ((!planDocsUploadInProgress && planDocsUploadSuccess) ||
        planDocsRemoveSuccess)
    ) {
      dispatch(getLatestBenguideByHash(urlHash));
    }
  }, [
    dispatch,
    urlHash,
    latestRevision,
    planDocsUploadInProgress,
    planDocsUploadSuccess,
    planDocsRemoveSuccess,
  ]);

  useEffect(() => {
    if (!isEmpty(currentContent)) {
      setContentData(currentContent);
      setDisplayData(currentContent);
    }
  }, [currentContent]);

  const updateEditorContent = (data: string) => {
    setContentData(data);
  };

  const resetToCurrentValue = () => {
    if (
      displayData === defaultContent &&
      isEmpty(displayData) &&
      isEmpty(currentContent)
    ) {
      setContentData('');
    } else {
      setContentData(currentContent);
    }
  };

  const resetToDefault = () => {
    if (!isEmpty(defaultContent)) {
      setContentData(defaultContent);
    }
  };

  const collapseOnChange = (key: string | string[]) => {
    setActivePanel(key as string);
  };

  const showExpandIcon = () => {
    return (
      <div className={styles.expandIconWrapper}>
        <div className={styles.expandText}>View More</div>
        <img
          src={expandIcon}
          alt="expand-icon"
          className={styles.collapseCaret}
        />
      </div>
    );
  };

  const getResourceHeader = (resource: any) => {
    return (
      <div className={styles.resourceHeader}>
        <Col span={24}>
          <div className={styles.providerInfo}>
            <div className={styles.providerType}>
              {resource.type?.toUpperCase()}
            </div>
            <div>
              <Row className={styles.providerNameRow}>
                <div
                  className={`${styles.providerName} ${
                    !resource.phoneNumber ? styles.fullWidth : ''
                  }`}
                >
                  <OverflowPopover placement="top" trigger="hover">
                    {resource.carrierName}
                  </OverflowPopover>
                </div>
                {!!resource.phoneNumber && (
                  <div className={styles.providerContact}>
                    {resource.phoneNumber}
                  </div>
                )}
              </Row>
              <div className={styles.carrierUrlWrapper}>
                {resource.carrierUrl && resource.carrierUrl.length > 0 && (
                  <a
                    href={addHttp(resource.carrierUrl)}
                    className={styles.carrierUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {resource.carrierUrl}
                  </a>
                )}
              </div>
            </div>
          </div>
        </Col>
      </div>
    );
  };

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      <BenefitGuideRefreshChannel />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setSaveDisable(true)}
      />
      <div className={styles.resourceWrapper}>
        <PageHeader
          pageHeaderTitle="Resources"
          pageHeaderIcon={<ResourcesIcon className={styles.iconSize} />}
          customIconStyle={styles.resIcon}
        />
        <Row gutter={isMobile ? 0 : 48} className={styles.cardsContainer}>
          <Col span={isTablet ? 24 : 18}>
            <Collapse
              activeKey={activePanel}
              onChange={collapseOnChange}
              bordered={false}
              accordion
              className={styles.cardCollapse}
              expandIconPosition="right"
              expandIcon={showExpandIcon}
            >
              {resources.map((resource: any) => {
                return (
                  <Panel
                    key={resource.carrierName + resource.type}
                    header={getResourceHeader(resource)}
                  >
                    <ResourceCard resource={resource} />
                  </Panel>
                );
              })}
            </Collapse>
          </Col>
          <Col span={isTablet ? 24 : 4}>
            {additionalResources &&
              additionalResources.additionalResources?.length > 0 && (
                <>
                  <div className={styles.additionalRes}>
                    Additional Resources
                  </div>
                  <div className={styles.resourceLinksWrapper}>
                    {additionalResources.additionalResources.map(
                      (additionalRes: AdditionalResource, index: number) => {
                        return (
                          <AddAdditionalResource
                            resource={additionalRes}
                            key={index}
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}
            <AddAdditionalResource />
            <div
              className={
                !contentData && isEditMode
                  ? styles.resContentBtn
                  : styles.resTextArea
              }
            >
              <EditableHotspot
                alwaysVisible={!contentData && isEditMode}
                editText={!contentData && isEditMode ? '' : 'Edit'}
                editIcon={<img src={iconEdit} alt="edit" />}
                customModal={(visible: boolean, onClose: Function) => (
                  <ResourceTextEditor
                    isOpenModal={visible}
                    closeModal={onClose}
                    isResetEnabled
                    editorContent={contentData}
                    updateEditorContent={updateEditorContent}
                    resetToCurrentValue={resetToCurrentValue}
                    resetToDefault={resetToDefault}
                    benguideId={masterId}
                    defaultContent={contentData}
                    onCreate={() => {
                      if (urlHash) {
                        dispatch(
                          getCurrentRichMedia(
                            masterId,
                            SectionName.RESOURCES_TEXT
                          )
                        );
                      }
                    }}
                  />
                )}
              >
                {!contentData && isEditMode ? (
                  <div className={styles.linkHotspot}>
                    Add Additional Content
                  </div>
                ) : (
                  <div className={styles.resourceContent}>
                    {reactHtmlParser(contentData)}
                  </div>
                )}
              </EditableHotspot>
            </div>
          </Col>
        </Row>
      </div>
    </NotificationContextProvider>
  );
};

export default Resources;
