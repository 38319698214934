export enum AdditionalResourceType {
  FILE = 'FILE',
  WEB_LINK = 'WEB_LINK',
}

type AdditionalResource = {
  id: string;
  resourceName: string;
  weblink?: string;
  type: AdditionalResourceType;
  s3referenceId?: string;
};

export default AdditionalResource;
