import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import BenConsultation from 'model/BenConsultation';
import {
  updateBenefitsConsultation,
  getBenefitsConsultation,
} from 'modules/benefitsWellness/services/BenGuideService';

type BenefitsConsultationState = {
  inProgress: boolean;
  error: {};
  benConsultation: BenConsultation;
};

const initialState = {
  inProgress: false,
  error: {},
  benConsultation: {},
} as BenefitsConsultationState;

const benefitsConsultationSlice = createSlice({
  name: 'benefitsConsultationSlice',
  initialState,
  reducers: {
    editBenConsultationInProgress(state) {
      state.inProgress = true;
    },
    editBenConsultationCompleted: (state, action) => {
      state.inProgress = false;
      state.benConsultation = action.payload;
    },
    editBenConsultationFailed(state, action) {
      state.inProgress = false;
      state.error = action.payload;
    },
    getBenConsultationInProgress(state) {
      state.inProgress = true;
    },
    getBenConsultationCompleted: (state, action) => {
      state.inProgress = false;
      state.benConsultation = action.payload;
    },
    getBenConsultationFailed(state, action) {
      state.inProgress = false;
      state.error = action.payload;
      state.benConsultation = {} as BenConsultation;
    },
  },
});

export default benefitsConsultationSlice.reducer;

export const {
  editBenConsultationInProgress,
  editBenConsultationCompleted,
  editBenConsultationFailed,
  getBenConsultationInProgress,
  getBenConsultationCompleted,
  getBenConsultationFailed,
} = benefitsConsultationSlice.actions;

export const editBenConsultation = (
  benguideId: string,
  benConsultation: BenConsultation
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(editBenConsultationInProgress());
      const response = await updateBenefitsConsultation(
        benguideId,
        benConsultation
      );
      const data = response.data;
      dispatch(editBenConsultationCompleted(data));
    } catch (error) {
      dispatch(editBenConsultationFailed(error));
    }
  };
};

export const getBenConsultation = (benguideId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getBenConsultationInProgress());
      const response = await getBenefitsConsultation(benguideId);
      const data = response.data;
      dispatch(getBenConsultationCompleted(data));
    } catch (error) {
      dispatch(getBenConsultationFailed(error));
    }
  };
};
