import { FC, useState } from 'react';
import { Checkbox, Input, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import styles from './multiSelectDropdown.module.less';

type MultiSelectDropdownProps = {
  options: Array<Option>;
  increased?: boolean;
  onChange?: (e: CheckboxValueType[]) => void;
  name?: string;
  value?: CheckboxValueType[];
  disabled?: boolean;
  dataCy?: string;
  showAll?: boolean;
};

export type Option = {
  value: string;
  label: string;
};

const MultiSelectDropdown: FC<MultiSelectDropdownProps> = (
  props: MultiSelectDropdownProps
) => {
  const {
    options,
    increased = false,
    onChange,
    name,
    value: selectedValues = [],
    disabled = false,
    dataCy,
    showAll = false,
  } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  const handleVisibleChange = (flag: boolean): void => {
    setIsDropdownVisible(flag);
  };

  const isAllSelected = (
    selected: CheckboxValueType[],
    options: Option[]
  ): boolean => {
    const optionValues = options.map((option) => option.value);
    return (
      optionValues.length > 0 &&
      optionValues.every(
        (value) => typeof value === 'string' && selected.includes(value)
      )
    );
  };

  const getSelectedLabels = (
    selectedValues: CheckboxValueType[],
    options: Option[]
  ) => {
    return options
      .filter((option) => selectedValues.includes(option.value))
      .map((option) => option.label);
  };

  return (
    <div className={styles.multiSelectPlanWrapper}>
      <Dropdown
        disabled={disabled}
        trigger={['click']}
        overlayClassName={
          increased ? styles.horizontalDropdown : styles.normalDropdown
        }
        overlayStyle={increased ? { minWidth: '0px' } : {}}
        onVisibleChange={handleVisibleChange}
        visible={isDropdownVisible}
        overlay={
          <Menu>
            <Checkbox.Group
              data-cy={dataCy}
              value={selectedValues}
              onChange={onChange}
            >
              {options.map(({ value: optionValue, label }) => (
                <Menu.Item key={optionValue}>
                  <Checkbox
                    value={optionValue}
                    name={name}
                    checked={Boolean(
                      selectedValues && selectedValues.includes(optionValue)
                    )}
                  >
                    {label}
                  </Checkbox>
                </Menu.Item>
              ))}
            </Checkbox.Group>
          </Menu>
        }
      >
        <Input
          className={styles.dropdownInput}
          suffix={<DownOutlined />}
          value={
            showAll && isAllSelected(selectedValues, options)
              ? 'All'
              : getSelectedLabels(selectedValues, options).join(', ')
          }
        />
      </Dropdown>
    </div>
  );
};

export default MultiSelectDropdown;
