import { FC, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { isEmpty } from 'lodash';

import PlanCardSectionEditor from 'components/PlanCardSectionEditor/PlanCardSectionEditor';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import { BenefitKind } from 'constants/commonConstants';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import useBenguide from 'hooks/benguide';
import { useAppSelector } from 'hooks/redux';
import IconEdit from 'images/icon-edit.svg';
import {
  TAX_ADVANTAGED_PLAN_CHANNEL,
  OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import * as BenguideService from 'services/BenGuideService';
import { ReactComponent as LeftArrowIcon } from 'images/icons/Icon-left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'images/icons/Icon-right-arrow.svg';
import PlanCardContainer from 'modules/benefitsWellness/components/PlanCardContainer/PlanCardContainer';
import { HSA_PLAN_CONTENT_TABS } from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { SectionName } from 'modules/home/constants';
import PlanCardHeader from 'modules/benefitsWellness/components/PlanCardHeader/PlanCardHeader';
import ContributionField from 'modules/taxAdvantaged/components/ContributionField/ContributionField';
import EmployerContributionSection from 'modules/taxAdvantaged/components/EmployerContributionSection/EmployerContributionSection';

import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './hsaPlanCard.module.less';

type Props = {
  plan: TaxAdvantageAccountsPlan;
  tierCount?: number;
  moveBottom: () => void;
  moveTop: () => void;
  hideTop: boolean;
  hideBottom: boolean;
  renderReorder: boolean;
  dataIndex?: number;
};

const HSAPlanCard: FC<Props> = (props: Props) => {
  const {
    plan,
    tierCount = 0,
    moveBottom,
    moveTop,
    hideTop,
    hideBottom,
    renderReorder,
    dataIndex,
  } = props;

  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [selectedEditorContent, setSelectedEditorContent] =
    useState<string>('');
  const [editorContent, setEditorContent] = useState<{ [key: string]: string }>(
    {
      [SectionName.HSA_OVERVIEW_TEXT]: '',
      [SectionName.HSA_ELIGIBILITY_TEXT]: '',
      [SectionName.HSA_EXPENSES_TEXT]: '',
      [SectionName.HSA_ADVANTAGES_TEXT]: '',
      [SectionName.HSA_CONSIDERATIONS_TEXT]: '',
    }
  );
  const [planContent, setPlanContent] = useState<{ [key: string]: string }>({
    [SectionName.HSA_OVERVIEW_TEXT]: 'HSA_OVERVIEW_TEXT default',
    [SectionName.HSA_ELIGIBILITY_TEXT]: 'HSA_ELIGIBILITY_TEXT default',
    [SectionName.HSA_EXPENSES_TEXT]: 'HSA_EXPENSES_TEXT default',
    [SectionName.HSA_ADVANTAGES_TEXT]: 'HSA_ADVANTAGES_TEXT default',
    [SectionName.HSA_CONSIDERATIONS_TEXT]: 'HSA_CONSIDERATIONS_TEXT default',
  });
  const [selectedOption, setSelectedOption] = useState<string>(
    SectionName.HSA_OVERVIEW_TEXT
  );
  const [activeTab, setActiveTab] = useState<string>(
    SectionName.HSA_OVERVIEW_TEXT
  );
  const [isVisible, setIsVisible] = useState(false);

  const upClient = useUPClient();
  const { masterId, isEditMode } = useBenguide();

  const defaultRichMedia = useAppSelector(
    (state) => state.hsa.defaultRichMedia.data
  );

  const planCardEditorRef = useRef<any>();

  useEffect(() => {
    getContent(masterId, plan.id);
  }, [masterId, plan.id]);

  const renderHoverMoveOverlay = () => (
    <div className={styles.moveOverlay}>
      {/* Top Section */}
      {!hideTop ? (
        <div className={styles.top} onClick={moveTop}>
          <LeftArrowIcon className={styles.topIcon} />
          <label>Move</label>
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}

      {/* Bottom Section */}
      {!hideBottom ? (
        <div className={styles.bottom} onClick={moveBottom}>
          <label>Move</label>
          <RightArrowIcon className={styles.bottomIcon} />
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}
    </div>
  );

  const getContent = async (masterId: string, planId: string) => {
    setContentLoading(true);
    try {
      const promises = await Promise.all(
        HSA_PLAN_CONTENT_TABS.map((section) => {
          const result = BenguideService.getRichMediaContentForPlan(
            masterId,
            section.code,
            planId,
            false
          ).then((response) => ({
            section: section.code,
            data: response.data,
          }));
          return result;
        })
      );
      promises.forEach((sectionData) => {
        setPlanContent((prevState) => ({
          ...prevState,
          [sectionData.section]: sectionData.data.content,
        }));
        setEditorContent((prevState) => ({
          ...prevState,
          [sectionData.section]: sectionData.data.content,
        }));
        if (sectionData.section === SectionName.HSA_OVERVIEW_TEXT) {
          setSelectedEditorContent(sectionData.data.content);
          if (planCardEditorRef.current) {
            planCardEditorRef.current.changeContent(sectionData.data.content);
          }
        }
      });
    } catch (error) {}
    setContentLoading(false);
  };

  const getDefaultRichMediaForSection = (selectTab: string) => {
    const sectionPropertyName = HSA_PLAN_CONTENT_TABS.find(
      (section) => section.code === selectTab
    )?.code;
    if (sectionPropertyName && defaultRichMedia[sectionPropertyName]) {
      return defaultRichMedia[sectionPropertyName];
    }
    return '';
  };

  const saveContent = async () => {
    const editedContent = Object.entries(editorContent)
      .filter(([key, value]) => {
        return planContent[key] !== value;
      })
      .map(([key, value]) => ({ section: key, content: value }));
    setContentLoading(true);

    const editPromises = async () => {
      try {
        for (const section of editedContent) {
          await BenguideService.updateRichMediaContent(
            masterId,
            section.section,
            plan.id,
            section.content
          );
          setPlanContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          setEditorContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          if (activeTab === section.section) {
            setSelectedEditorContent(section.content);
          }
        }
      } catch (error) {}
    };
    editPromises();

    setContentLoading(false);
  };

  const updateEditorContent = (data: string) => {
    setSelectedEditorContent(data);
    setEditorContent((prevData) => ({ ...prevData, [selectedOption]: data }));
  };

  const onSelectOptionChange = (section: SectionName) => {
    setSelectedOption(section);
    setSelectedEditorContent(editorContent[section]);
  };

  const resetAll = () => {
    setEditorContent({ ...planContent });
    setSelectedOption(activeTab);
    setSelectedEditorContent(planContent[activeTab]);
  };

  const onTabsChange = (key: string) => {
    setActiveTab(key);
    onSelectOptionChange(key as SectionName);
  };

  const handleResetDefault = () => {
    const defaultContent = getDefaultRichMediaForSection(selectedOption);
    setEditorContent((prevState) => ({
      ...prevState,
      [selectedOption]: defaultContent,
    }));
    setSelectedEditorContent(defaultContent);
    planCardEditorRef?.current?.changeContent(defaultContent);
  };

  const checkMaxYearlyContributionIndividual = (): boolean => {
    if (plan.maxYearlyContributionIndividual !== 0) return true;
    else {
      if (isEditMode) return true;
      else return false;
    }
  };

  const checkMaxYearlyContributionFamily = (): boolean => {
    if (plan.maxYearlyContributionFamily !== 0) return true;
    else {
      if (isEditMode) return true;
      else return false;
    }
  };

  return (
    <PlanCardContainer>
      {renderReorder && (
        <div className={styles.moveWrapper}>
          <EditableHotspot
            alwaysVisible={false}
            className={styles.hotSpotHoverContainer}
            customModal={() => <></>}
            overlayComponent={renderHoverMoveOverlay()}
          >
            <div className={styles.moveArea}></div>
          </EditableHotspot>
        </div>
      )}

      <div className={styles.planDetailsWrapper}>
        <div className={styles.planDetailsContainer}>
          <EditableHotspot
            alwaysVisible={false}
            editText="Edit"
            editIcon={<img src={IconEdit} alt="edit icon" />}
            useChannel={{
              client: upClient,
              channel: TAX_ADVANTAGED_PLAN_CHANNEL,
              event: OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL,
              data: plan,
            }}
            className={styles.hotSpot}
            dataTestId={`hsa-plan${dataIndex}-name`}
          >
            <div className={styles.planDetails}>
              <PlanCardHeader
                planName={plan.name}
                carrier={plan.benefitCarrier}
              />
              <div className={styles.planInfoValues}>
                {plan.employerContribution && (
                  <EmployerContributionSection
                    className={styles.employerContribution}
                    employerContribution={plan.employerContribution}
                    tierCount={tierCount}
                  />
                )}
                {plan.maxYearlyContributionIndividual !== null &&
                  plan.maxYearlyContributionIndividual !== undefined &&
                  checkMaxYearlyContributionIndividual() && (
                    <ContributionField
                      className={styles.contributionField}
                      label="Max Yearly Contribution (Individual)"
                      value={plan.maxYearlyContributionIndividual}
                    />
                  )}
                {plan.maxYearlyContributionFamily !== null &&
                  plan.maxYearlyContributionFamily !== undefined &&
                  checkMaxYearlyContributionFamily() && (
                    <ContributionField
                      className={styles.contributionField}
                      label="Max Yearly Contribution (Family)"
                      value={plan.maxYearlyContributionFamily}
                    />
                  )}
              </div>
              {(!isEmpty(plan.documents) ||
                !isEmpty(plan.additionalDocuments) ||
                !isEmpty(plan.additionalWeblinks)) && (
                <div
                  className={styles.viewDetailsLink}
                  onClick={() => setIsVisible(true)}
                >
                  Plan Resources
                </div>
              )}
            </div>
          </EditableHotspot>
        </div>
      </div>
      <div className={styles.tabsArea}>
        {contentLoading ? (
          <Spin />
        ) : (
          <PlanCardSectionEditor
            ref={planCardEditorRef}
            activeKey={activeTab}
            defaultActiveKey={SectionName.COMMUTER_OVERVIEW_TEXT}
            onTabClick={onTabsChange}
            editorSelectedTab={selectedOption}
            onEditorTabChange={onSelectOptionChange}
            tabs={HSA_PLAN_CONTENT_TABS}
            planContent={planContent}
            isEditMode={isEditMode}
            resetAll={resetAll}
            selectedEditorContent={selectedEditorContent}
            onResetToDefault={handleResetDefault}
            selectedTabDefaultContent={getDefaultRichMediaForSection(
              selectedOption
            )}
            onUpdateContent={updateEditorContent}
            onResetToCurrent={() => {
              setEditorContent({ ...planContent });
            }}
            benguideId={masterId}
            onSaveContent={saveContent}
            dataIndex={dataIndex}
          />
        )}
      </div>
      <ViewResourcesModal
        plan={plan}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        benefitKind={BenefitKind.HSA.value}
      />
    </PlanCardContainer>
  );
};

export default HSAPlanCard;
