import RouteMap from 'routes/RouteMap';
import { ReactComponent as MedicalIcon } from 'images/icons/side-nav/medical.svg';
import { ReactComponent as TelehealthIcon } from 'images/icons/side-nav/telehealth.svg';
import { ReactComponent as DentalIcon } from 'images/icons/side-nav/dental.svg';
import { ReactComponent as VisionIcon } from 'images/icons/side-nav/vision.svg';
import { ReactComponent as WellbeingIcon } from 'images/icons/side-nav/wellbeing.svg';
import { ReactComponent as LifeDisabilityIcon } from 'images/icons/side-nav/life-and-disability.svg';
import { ReactComponent as VoluntaryIcon } from 'images/icons/side-nav/voluntary.svg';
import { ReactComponent as TaxAdvantagedIcon } from 'images/icons/side-nav/tax-advantaged.svg';
import { ReactComponent as CommuterIcon } from 'images/icons/side-nav/commuter.svg';

export const sideNavRoutes = [
  {
    path: RouteMap.HOME_PATH,
    label: 'Home',
  },

  {
    path: RouteMap.NEW_HIRE_CHECKLIST,
    label: 'New Hire Checklist',
  },
  {
    path: RouteMap.HOW_INSURANCE_WORKS,
    label: 'How Insurance Works',
  },
  {
    path: RouteMap.DATES_AND_ELIGIBILITY,
    label: 'Dates & Eligibility',
  },
  {
    path: 'health-benefit/',
    label: 'Benefits & Wellness',
    children: [
      {
        path: RouteMap.MEDICAL,
        label: 'Medical',
        icon: <MedicalIcon />,
      },
      {
        path: RouteMap.TELEHEALTH,
        label: 'Telehealth/Rx Delivery',
        icon: <TelehealthIcon />,
      },
      {
        path: RouteMap.DENTAL,
        label: 'Dental',
        icon: <DentalIcon />,
      },
      {
        path: RouteMap.VISION,
        label: 'Vision',
        icon: <VisionIcon />,
      },
      {
        path: RouteMap.LIFE_AND_DISABILITY,
        label: 'Life & Disability',
        icon: <LifeDisabilityIcon />,
      },
      {
        path: RouteMap.VOLUNTARY,
        label: 'Voluntary Benefits',
        icon: <VoluntaryIcon />,
      },
      {
        path: RouteMap.WELLBEING,
        label: 'Wellbeing',
        icon: <WellbeingIcon />,
      },
    ],
  },
  {
    path: 'tax-advantaged/',
    label: 'Tax Advantaged Accts',
    children: [
      {
        path: RouteMap.HRA,
        label: 'HRA',
        icon: <TaxAdvantagedIcon />,
      },
      {
        path: RouteMap.FSA,
        label: 'FSA',
        icon: <TaxAdvantagedIcon />,
      },
      {
        path: RouteMap.HSA,
        label: 'HSA',
        icon: <TaxAdvantagedIcon />,
      },
      {
        path: RouteMap.COMMUTER,
        label: 'Commuter',
        icon: <CommuterIcon />,
      },
    ],
  },
  {
    path: RouteMap.FOUR_O_ONE_K,
    label: '401(k)',
  },
  {
    path: RouteMap.HOLIDAYS_TIME_OFF,
    label: 'Holidays & Time Off',
  },
  {
    path: RouteMap.FAMILY_AND_LEAVE,
    label: 'Family & Leave',
  },
  {
    path: RouteMap.ADDITIONAL_PERKS,
    label: 'Additional Perks',
  },
  {
    path: RouteMap.RESOURCES,
    label: 'Resources',
  },
];
