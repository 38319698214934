import React, { useEffect, useState } from 'react';
import { Input, Button, Row, Col, Typography } from 'antd';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import CustomField from 'model/CustomField';
import styles from './editCustomRowModal.module.less';

const { Text } = Typography;
type EditCustomRowModalProps = {
  visible: boolean;
  onClose: () => void;
  onSave: (customField: CustomField) => void;
  customFieldData: CustomField;
};

const EditCustomRowModal = ({
  visible,
  onClose,
  onSave,
  customFieldData,
}: EditCustomRowModalProps) => {
  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (visible) {
      setText(customFieldData?.fieldValue);
    }
  }, [visible, customFieldData?.fieldValue]);

  const handleSave = () => {
    const data = {
      ...customFieldData,
      fieldValue: text.trim(),
    };

    onSave(data);
  };

  return (
    <HalfScreenModal
      width="552px"
      title={`Edit ${customFieldData.fieldName}`}
      visible={visible}
      className={styles.modalWrapper}
      footer={[
        <Button
          className={`${styles.btn} ${styles.cancel}`}
          key="cancel"
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          className={styles.btn}
          key="submit"
          type="primary"
          onClick={handleSave}
        >
          Done
        </Button>,
      ]}
      onCancel={onClose}
    >
      <Row>
        <Col xs={4} className={styles.labelWrapper}>
          <div>Text</div>
        </Col>
        <Col xs={20}>
          <Input
            className={styles.input}
            placeholder="Custom text goes here..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            maxLength={100}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Text className={styles.charLimit}>
          Character Limit: {text.length}/100
        </Text>
      </Row>
    </HalfScreenModal>
  );
};

export default EditCustomRowModal;
