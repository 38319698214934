import React, { FC, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  currencyFormatter,
  currencyFormatterForSignedNumbers,
  currencyFormatterForSignedNumbersWithoutDecimalPoints,
  getFrequencies,
} from 'utils/commonUtil';
import infoIcon from 'images/icons/icon-info.png';

import styles from './annualCostBreakdown.module.less';

type AnnualCostBreakdownProps = {
  activePlan: any;
};

const AnnualCostBreakdown: FC<AnnualCostBreakdownProps> = (
  props: AnnualCostBreakdownProps
) => {
  const { activePlan = {} } = props;
  const {
    annualPremiumConfig = {},
    estAnnualBreakdown = {},
    hsaCompatiblePlan,
    oopMaxIncluded,
    medicalCostIncluded,
    premiumIncluded,
    procedureCostIncluded,
    estimatedMedicalCostConfig = {},
    hraCompatiblePlan,
    hraPlan = {},
  } = activePlan;

  const {
    annualPremiumCost = 0,
    estMedicalCost = 0,
    estAnnualCost = 0,
    hsaContribution = 0,
    oopMaxCost = 0,
  } = estAnnualBreakdown;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activePlan]);

  const getToolTip = (type: any) => {
    const { monthlyCost = 0.0, premiumFrequency } = annualPremiumConfig;
    let toolTipText = '<div class="info-tooltip-content-wrapper">';
    if (type !== 'estMedicalCost') {
      toolTipText += '<strong class="based-on">BASED ON:</strong>';
    }
    if (type === 'annualPremiumCost') {
      toolTipText += `<div>$${monthlyCost.toFixed(
        2
      )} Premium <span>&#215;</span> ${getFrequencies(premiumFrequency)}<div>`;
    }
    if (type === 'hsaContribution') {
      toolTipText += `<div>Annual Employer Contribution<div>`;
    }
    if (type === 'hraContribution') {
      toolTipText += `<div>Annual employer allowance of up to ${currencyFormatterForSignedNumbers(
        hraPlan?.employerContribution?.employerEEContribution
      )} for individual or ${currencyFormatterForSignedNumbers(
        hraPlan?.employerContribution?.employerEFContribution
      )} for families. <div>`;
    }
    return toolTipText;
  };

  const getMedCostTooltipText = () => {
    const {
      coPayment = 0,
      deductible = 0,
      eeProcedureCost = 0,
      oopMaxCost = 0,
      estMedicalCost = 0,
    } = estAnnualBreakdown;
    const { procedureSelected } = estimatedMedicalCostConfig;

    return (
      <div>
        <div className="med-cost-tooltip-content">
          <div className="tooltip-values">
            {procedureSelected && <span>Deductible</span>}
            <span>Copays</span>
            {procedureCostIncluded && <span>Coinsurance</span>}
          </div>
          <div className="tooltip-values currency-section">
            {procedureSelected && (
              <strong className="tooltip-currency-values">
                {currencyFormatterForSignedNumbers(deductible)}
              </strong>
            )}
            <strong className="tooltip-currency-values">
              {currencyFormatterForSignedNumbers(coPayment)}
            </strong>
            {procedureCostIncluded && (
              <strong className="tooltip-currency-values">
                {currencyFormatterForSignedNumbers(eeProcedureCost)}
              </strong>
            )}
          </div>
        </div>
        <div className="tooltip-plan-cost-section">
          <strong>
            You pay:{' '}
            {medicalCostIncluded
              ? currencyFormatterForSignedNumbers(estMedicalCost)
              : currencyFormatterForSignedNumbers(oopMaxCost)}
            *
          </strong>
        </div>
        {oopMaxIncluded && (
          <>
            <hr />
            <div>
              <span>
                *Your above costs exceed your out-of-pocket max, so the most you
                will pay is{' '}
              </span>
              <strong>{currencyFormatterForSignedNumbers(oopMaxCost)}</strong>
              <span> for in-network services.</span>
            </div>
          </>
        )}
      </div>
    );
  };

  const getDigitBasedStyle = (value: any) => {
    const digitCount = Math.round(value).toString().length;
    if (digitCount < 6) return styles.lowestValue;
    if (digitCount === 6) return styles.lowValue;
    if (digitCount === 7) return styles.mediumValue;
    return styles.highValue;
  };

  return (
    <div className={styles.calculatedFields}>
      <ReactTooltip
        id="med-cost-tooltip"
        className={styles.breakdownTooltip}
        place="right"
        type="light"
        effect="solid"
        border={true}
        borderColor="gray"
      >
        {getMedCostTooltipText()}
      </ReactTooltip>
      <ReactTooltip
        id="breakdown-tooltip"
        className={styles.breakdownTooltip}
        place="right"
        type="light"
        effect="solid"
        border={true}
        borderColor="gray"
        html={true}
      ></ReactTooltip>
      {premiumIncluded && (
        <div className={styles.breakdownField}>
          <div className={styles.title}>
            Annual Premium Cost
            <img
              src={infoIcon}
              alt="info"
              className={styles.infoTooltipIcon}
              data-for="breakdown-tooltip"
              data-tip={getToolTip('annualPremiumCost')}
              data-html={true}
            />
          </div>
          <div className={getDigitBasedStyle(annualPremiumCost)}>
            {currencyFormatter(Math.round(annualPremiumCost))}
          </div>
        </div>
      )}
      {medicalCostIncluded && premiumIncluded && (
        <div className={styles.sign}>+</div>
      )}
      {medicalCostIncluded && (
        <div className={styles.breakdownField}>
          <div className={styles.title}>
            Estimated Medical Cost
            <img
              src={infoIcon}
              alt="info"
              className={styles.infoTooltipIcon}
              data-for="med-cost-tooltip"
              data-tip
            />
          </div>
          <div className={getDigitBasedStyle(estMedicalCost)}>
            {currencyFormatter(Math.round(estMedicalCost))}
          </div>
        </div>
      )}
      {oopMaxIncluded && (
        <React.Fragment>
          {premiumIncluded && <div className={styles.sign}>+</div>}
          <div className={styles.breakdownField}>
            <div className={styles.title}>
              OOP MAX
              <img
                src={infoIcon}
                alt="info"
                className={styles.infoTooltipIcon}
                data-for="med-cost-tooltip"
                data-tip
              />
            </div>
            {window.innerWidth > 576 ? (
              <>
                <div className={getDigitBasedStyle(oopMaxCost)}>
                  {currencyFormatterForSignedNumbers(oopMaxCost)}
                </div>
              </>
            ) : (
              <div className={getDigitBasedStyle(oopMaxCost)}>
                {currencyFormatter(Math.round(oopMaxCost))}
              </div>
            )}
          </div>
        </React.Fragment>
      )}

      {hsaCompatiblePlan && Math.round(hsaContribution) !== 0 && (
        <>
          <div className={styles.sign}>-</div>
          <div className={styles.breakdownField}>
            <div className={styles.title}>
              HSA Contribution
              <img
                src={infoIcon}
                alt="info"
                className={styles.infoTooltipIcon}
                data-for="breakdown-tooltip"
                data-tip={getToolTip('hsaContribution')}
                data-html={true}
              />
            </div>
            <div
              className={`${getDigitBasedStyle(hsaContribution)} ${
                styles.greenText
              }`}
            >
              {currencyFormatter(Math.round(hsaContribution))}
            </div>
          </div>
        </>
      )}
      {hraCompatiblePlan && (
        <>
          <div className={styles.sign}>-</div>
          <div className={styles.breakdownField}>
            <div className={styles.title}>
              HRA Allowance
              <img
                src={infoIcon}
                alt="info"
                className={styles.infoTooltipIcon}
                data-for="breakdown-tooltip"
                data-tip={getToolTip('hraContribution')}
                data-html={true}
              />
            </div>
            <div
              className={`${getDigitBasedStyle(
                activePlan.estAnnualBreakdown.hraContribution
              )} ${styles.greenText}`}
            >
              {currencyFormatter(
                Math.round(activePlan.estAnnualBreakdown.hraContribution)
              )}
            </div>
          </div>
        </>
      )}

      <div className={styles.sign}>=</div>
      <div className={`${styles.breakdownField} ${styles.total}`}>
        <div className={styles.title}>Estimated Annual Cost</div>
        <div
          className={`${getDigitBasedStyle(estAnnualCost)} ${styles.greenText}`}
        >
          {currencyFormatterForSignedNumbersWithoutDecimalPoints(
            Math.round(estAnnualCost)
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnualCostBreakdown;
