import { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Tabs } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { isEmpty, isNull } from 'lodash';
import DependentIcon from 'images/dependents-icon.svg';
import { useAppSelector } from 'hooks/redux';
import { WAIVED } from 'modules/auth/constants/globleConstants';
import {
  resetAuthState,
  setWhoAmI,
  whoamI,
} from 'modules/auth/slices/employeeAuthSlice';
import {
  getDependentIdCardData,
  getEmployerData,
  getIDCardData,
  resetIDcardState,
  setCurrentMemberId,
  setOnboardingView,
} from 'modules/auth/slices/idCardSlice';
import PageActionDropdown from 'components/PageActionDropdown/PageActionDropdown';

import { ID_CARD_PAGE_ACTIONS_OPTIONS } from 'utils/commonUtil';
import { PLAN_VIEW } from 'modules/auth/constants/authConstants';
import IDCard from './IDCard/IdCard';
import IDCardPrint from './IDCard/Print/IDCardPrint';

import styles from './idCardView.module.less';

const IDcardview = () => {
  const dispatch = useDispatch();
  const { currentPlanYearId } =
    useAppSelector((state) => state.idCard.publishedPlans) || {};
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [isDependent, setIsDependent] = useState<boolean>(false);
  const [view, setView] = useState<string>(PLAN_VIEW.MEDICAL.type);
  const IdRef = useRef<HTMLDivElement | null>(null);
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide || { data: {} };
  const employee = useAppSelector((state) => state.idCard.currentEmployee);
  const dependentDetails = useAppSelector(
    (state) => state.employeeAuth.dependentDetails
  );
  const employer = useAppSelector((state) => state.idCard.employerData);
  const mdvElectionData = useAppSelector(
    (state) => state.idCard.electionMDVIdCardData
  );
  const electionsByDependentIndividualId = useAppSelector(
    (state) => state.idCard.dependentMDVIdCardData
  );
  const isIDFetchLoading = useAppSelector((state) => state.idCard.inProgress);
  const currentPlanYearDetails = useAppSelector(
    (state) => state.idCard.currentPlanYear
  );
  const currentMemberId = useAppSelector(
    (state) => state.idCard.currentMemberId
  );
  const isAuthLoading = useAppSelector(
    (state) => state.employeeAuth.inProgress
  );
  const whoAmI = useAppSelector((state) => state.employeeAuth.whoami);

  useEffect(() => {
    dispatch(whoamI());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(whoAmI) && !whoAmI?.isTermsAccepted) {
      dispatch(setOnboardingView(PLAN_VIEW.TERMS.type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoAmI]);

  const getCurrentMDVCardData = useCallback(
    (id: string) => {
      let election = mdvElectionData?.find(
        (item: any) => item?.benefitKind === view && item.individualId === id
      );
      if (!election) {
        election = electionsByDependentIndividualId?.[id]?.find(
          (item: any) => item?.benefitKind === view && item.individualId === id
        );
      }
      return election;
    },
    [mdvElectionData, view, electionsByDependentIndividualId]
  );

  const hasPlan = !isNull(getCurrentMDVCardData(currentMemberId)?.planId);

  const isDependentView = () => {
    if (employee?.isDep) {
      return true;
    } else if (isDependent) {
      return true;
    }
    return false;
  };
  const handlePrint = useReactToPrint({
    content: () => IdRef.current,
    documentTitle: `${
      getCurrentMDVCardData(currentMemberId)?.individualName?.firstName
    } ${
      getCurrentMDVCardData(currentMemberId)?.individualName?.lastName
    }-${view}.pdf`,
  });

  // TODO : This was commented because of a requirement wasn't available
  // const showPlanMatched = (mdvElectionData: any) => {
  //   if (!isEmpty(mdvElectionData)) {
  //     const benguideBenefits = extractBenefitSections(benguideData);

  //     const allPlansMatched = mdvElectionData.every((item: any) => {
  //       const { benefitKind, planId } = item;

  //       if (!isNull(planId)) {
  //         return (benguideBenefits[benefitKind] || []).some(
  //           (plan: any) => plan.planId === planId
  //         );
  //       }
  //       return true; // If planId is null, consider it as matched
  //     });

  //     if (allPlansMatched) {
  //       dispatch(resetAuthAlert());
  //     } else {
  //       dispatch(
  //         setAuthAlert({
  //           type: AuthErrorCodes.PLAN_NOT_MATCHED.type,
  //           message: AuthErrorCodes.PLAN_NOT_MATCHED.message,
  //         })
  //       );
  //     }
  //   }
  // };

  const isWaived = () =>
    getCurrentMDVCardData(currentMemberId)?.planId == WAIVED.type;

  const isEmptyOrWaived =
    isEmpty(getCurrentMDVCardData(currentMemberId)) ||
    isWaived() ||
    isAuthLoading ||
    isIDFetchLoading;

  const getOnboardingHeading = (type: string) => {
    switch (type) {
      case PLAN_VIEW.MEDICAL.type:
        return PLAN_VIEW.MEDICAL.viewTitle;
      case PLAN_VIEW.DENTAL.type:
        return PLAN_VIEW.DENTAL.viewTitle;
      case PLAN_VIEW.VISION.type:
        return PLAN_VIEW.VISION.viewTitle;
      default:
        break;
    }
  };

  const getMemberPlanOptions = () => {
    const getMDV = getCurrentMDVCardData(currentMemberId);
    let options: any = [];
    if (!isEmpty(dependentDetails)) {
      options = dependentDetails?.map((item: any) => ({
        value: item?.id,
        label: `${item.firstName} ${item.lastName}`,
      }));
    }
    options?.splice(0, 0, {
      value: getMDV?.subscriberId,
      label: `${getMDV?.subscriberName?.firstName} ${getMDV?.subscriberName?.lastName} - Subscriber`,
    });
    return options;
  };

  const getDropdownOptions = () => {
    const options = Object.values(ID_CARD_PAGE_ACTIONS_OPTIONS).filter(
      (item) =>
        item !== ID_CARD_PAGE_ACTIONS_OPTIONS.SHOW_FRONT &&
        item !== ID_CARD_PAGE_ACTIONS_OPTIONS.SHOW_BACK &&
        item !== ID_CARD_PAGE_ACTIONS_OPTIONS.UPDATE_ID &&
        item !== ID_CARD_PAGE_ACTIONS_OPTIONS.PRINT_ID
    );
    const optionToAdd = isFlipped
      ? ID_CARD_PAGE_ACTIONS_OPTIONS.SHOW_FRONT
      : ID_CARD_PAGE_ACTIONS_OPTIONS.SHOW_BACK;

    if (!isEmptyOrWaived && hasPlan) {
      options.splice(options.length - 1, 0, optionToAdd);
      options.unshift(ID_CARD_PAGE_ACTIONS_OPTIONS.PRINT_ID);
    }

    if (!employee?.isDep) {
      options.splice(
        options.length - 1,
        0,
        ID_CARD_PAGE_ACTIONS_OPTIONS.UPDATE_ID
      );
    }
    return options;
  };

  const handleLogout = () => {
    dispatch(resetAuthState());
    dispatch(resetIDcardState());
    dispatch(setWhoAmI({}));
  };

  const handleUpdate = () => {
    dispatch(setOnboardingView(PLAN_VIEW.MEDICAL.type));
  };

  const handleActionDropDown = (action: string) => {
    switch (action) {
      case ID_CARD_PAGE_ACTIONS_OPTIONS.SHOW_FRONT.value:
        setIsFlipped(false);
        break;
      case ID_CARD_PAGE_ACTIONS_OPTIONS.SHOW_BACK.value:
        setIsFlipped(true);
        break;
      case ID_CARD_PAGE_ACTIONS_OPTIONS.UPDATE_ID.value:
        handleUpdate();
        break;
      case ID_CARD_PAGE_ACTIONS_OPTIONS.PRINT_ID.value:
        handlePrint();
        break;
      case ID_CARD_PAGE_ACTIONS_OPTIONS.LOGOUT.value:
        handleLogout();
        break;
      default:
        break;
    }
  };
  const handleMemberPlanChange = (id: string) => {
    if (getCurrentMDVCardData(id)?.subscriberId === id) {
      setIsDependent(false);
    } else {
      setIsDependent(true);
    }
    dispatch(setCurrentMemberId(id));
  };

  const renderActionHeader = () => (
    <Row justify="space-between" className={styles.actionsHeader}>
      <div className={styles.flexContainer}>
        Viewing:
        <span className={styles.linkText}>
          <b>
            {currentPlanYearDetails?.name}{' '}
            {`${
              currentPlanYearId === benguideData.planYearId ? '(Current)' : ''
            }`}
          </b>
        </span>
      </div>
      {!employee?.isDep && (
        <PageActionDropdown
          label={
            <div className={styles.flexContainer}>
              <img src={DependentIcon} alt="dependents" />
              <span className={styles.linkText}>
                Plan Members
                {` (${dependentDetails?.length})`}
              </span>
            </div>
          }
          onChange={(e: any) => handleMemberPlanChange(e?.key)}
          icon={<></>}
          options={getMemberPlanOptions()}
        />
      )}
    </Row>
  );

  const renderSwitch = () => {
    return (
      <div className={styles.footerContainer}>
        <div className={styles.flexEndContainer}>
          <PageActionDropdown
            options={Object.values(getDropdownOptions())}
            onChange={(e: any) => handleActionDropDown(e?.key)}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(setCurrentMemberId(employee.individualId));
    dispatch(
      getIDCardData(
        employee.individualId,
        employee.employerId,
        benguideData.planYearId
      )
    );
    dispatch(getEmployerData(employee?.employerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDependentIdCardData());
  }, [dependentDetails, dispatch]);

  useEffect(() => {
    setIsFlipped(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, isWaived()]);

  return (
    <div className={styles.cardContainer}>
      <h1 className={styles.heading}>{getOnboardingHeading(view)}</h1>
      <Tabs
        className={styles.tabContainer}
        defaultActiveKey={PLAN_VIEW.MEDICAL.type}
        onChange={(e: string) => setView(e)}
      >
        <Tabs.TabPane
          className={styles.tabWrapper}
          tab={PLAN_VIEW.MEDICAL.value}
          key={PLAN_VIEW.MEDICAL.type}
        >
          <div className={styles.innerWrapper}>
            {renderActionHeader()}
            <IDCard
              isFlipped={isFlipped}
              data={getCurrentMDVCardData(currentMemberId)}
              employerData={employer}
              isWaived={isWaived()}
              isEmpty={isEmpty(mdvElectionData)}
              hasPlan={hasPlan}
              isLoading={isIDFetchLoading || isAuthLoading}
              view={view}
              isDependent={isDependentView()}
            />
            {renderSwitch()}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          className={styles.tabWrapper}
          tab={PLAN_VIEW.DENTAL.value}
          key={PLAN_VIEW.DENTAL.type}
        >
          <div className={styles.innerWrapper}>
            {renderActionHeader()}
            <IDCard
              isFlipped={isFlipped}
              isEmpty={isEmpty(mdvElectionData)}
              data={getCurrentMDVCardData(currentMemberId)}
              employerData={employer}
              hasPlan={hasPlan}
              isWaived={isWaived()}
              isLoading={isIDFetchLoading || isAuthLoading}
              view={view}
              isDependent={isDependentView()}
            />
            {renderSwitch()}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          className={styles.tabWrapper}
          tab={PLAN_VIEW.VISION.value}
          key={PLAN_VIEW.VISION.type}
        >
          <div className={styles.innerWrapper}>
            {renderActionHeader()}
            <IDCard
              isFlipped={isFlipped}
              isEmpty={isEmpty(mdvElectionData)}
              data={getCurrentMDVCardData(currentMemberId)}
              employerData={employer}
              hasPlan={hasPlan}
              isWaived={isWaived()}
              isLoading={isIDFetchLoading || isAuthLoading}
              view={view}
              isDependent={isDependentView()}
            />
            {renderSwitch()}
          </div>
        </Tabs.TabPane>
      </Tabs>
      <div className={styles.hiddenContainer}>
        <IDCardPrint
          isFlipped={isFlipped}
          data={getCurrentMDVCardData(currentMemberId)}
          employerData={employer}
          IdRef={IdRef}
          view={view}
        />
      </div>
    </div>
  );
};

export default IDcardview;
