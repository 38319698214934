import { BENGUIDE_MODE_EDIT } from 'modules/auth/constants/globleConstants';
import { useAppSelector } from 'hooks/redux';

const useBenguide = () => {
  const { benguide } = useAppSelector((state) => state);
  const { mode, data } = benguide;
  const isEditMode = mode === BENGUIDE_MODE_EDIT;
  return { ...data, isEditMode };
};

export default useBenguide;
