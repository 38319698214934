import ReactGA4 from 'react-ga4';
import { measurementID } from './apiUtil';

export const initGA4 = () => {
  ReactGA4.initialize(measurementID);
};

export const trackEvents = ({
  category,
  action,
  label,
  value,
}: {
  category: string;
  action: string;
  label: string;
  value?: any;
}) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};
