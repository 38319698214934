// Use this hook to get previous value of props or state.
import { useEffect, useRef } from 'react';

export function usePrevious(initialValue: any) {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = initialValue;
  });
  return ref.current;
}
