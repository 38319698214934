import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import PlanRecommenderSlider from 'modules/planRecommender/components/PlanRecommenderSlider/PlanRecommenderSlider';

import styles from './planRecommenderCombinedSlider.module.less';

type PlanRecommenderCombinedSliderProps = {
  editMode: boolean;
  index: number;
  title: string;
  subTitle: string;
  config: any;
  onUpdateAnswer: Function;
  id: string;
  footerText: string;
  onNextClick: MouseEventHandler<HTMLButtonElement>;
  currentAnswer: any;
};

const PlanRecommenderCombinedSlider: FC<PlanRecommenderCombinedSliderProps> = (
  props: PlanRecommenderCombinedSliderProps
) => {
  const [currentAnswer, setCurrentAnswer] = useState<any>({
    DOCTOR_VISITS: 0,
    SPECIALIST_VISITS: 0,
  });

  const {
    editMode,
    index,
    title,
    subTitle,
    config,
    onUpdateAnswer,
    onNextClick,
    id,
  } = props;

  useEffect(() => {
    if (props.currentAnswer) {
      setCurrentAnswer(props.currentAnswer);
    }
  }, [props.currentAnswer]);

  const updateAnswer = (name: string, answer: string) => {
    const updatedAnswer = { ...currentAnswer, [name]: answer };
    setCurrentAnswer(updatedAnswer);
    onUpdateAnswer(id, updatedAnswer);
  };

  return (
    <div
      className={`${styles.planRecommenderCombinedSlider} planRecommenderCombinedSlider`}
    >
      <div className={`${styles.content} content`}>
        <div className={`${styles.title} title`}>{`${
          editMode ? index + 1 + '.' : ''
        } ${title}`}</div>
        {subTitle && (
          <div className={`${styles.subTitle} subTitle`}>{subTitle}</div>
        )}
        {config.map((sliderConfig: any, index: number) => {
          const { name = '' } = sliderConfig;
          const defaultProps = {
            ...props,
            onUpdateAnswer: (answer: any) => {
              updateAnswer(name, answer);
            },
            currentAnswer: get(currentAnswer, name),
            config: sliderConfig,
          };
          return (
            <React.Fragment key={index}>
              {index === 1 && <hr />}
              <PlanRecommenderSlider key={index} {...defaultProps} />
            </React.Fragment>
          );
        })}
      </div>
      <div className={`${styles.nextBtnWrapper} nextBtnWrapper`}>
        <button
          className={`${styles.nextButton} nextButton`}
          onClick={onNextClick}
        >
          Next
        </button>
      </div>
      <ReactTooltip
        className="info-tooltip"
        place="right"
        effect="solid"
        border={true}
        borderColor="gray"
      />
    </div>
  );
};

export default PlanRecommenderCombinedSlider;
