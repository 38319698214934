import { FC, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as LeftArrowIcon } from 'images/icons/Icon-left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'images/icons/Icon-right-arrow.svg';
import FourOOneKPlan from 'model/FourOOneKPlan';
import PlanCardHeader from 'modules/benefitsWellness/components/PlanCardHeader/PlanCardHeader';
import PlanCardSectionEditor from 'components/PlanCardSectionEditor/PlanCardSectionEditor';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import * as BenguideService from 'services/BenGuideService';
import useBenguide from 'hooks/benguide';
import { useAppSelector } from 'hooks/redux';

import IconEdit from 'images/icon-edit.svg';
import { currencyFormatter } from 'modules/benefitsWellness/util';
import { SectionName } from 'modules/home/constants';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import { FOUR_ONE_K_SECTIONS } from 'modules/fourOOneK/constants';
import {
  FOUR_O_ONE_K_PLAN_CHANNEL,
  OPEN_FOUR_O_ONE_K_PLAN_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';

import styles from './fourOOneKCard.module.less';

type FourOOneKCardProps = {
  plan: FourOOneKPlan;
  moveBottom: () => void;
  moveTop: () => void;
  hideTop: boolean;
  hideBottom: boolean;
  renderReorder: boolean;
  dataIndex?: number;
};

type InfoFieldProps = {
  label: string;
  value?: string;
  className?: string;
};

const InfoField: FC<InfoFieldProps> = ({
  label,
  value,
  className = '',
}: InfoFieldProps) => {
  return (
    <div className={`${styles.infoField} ${className}`}>
      <div className={styles.label}>{label}</div>
      {value && <span className={styles.value}>{value}</span>}
    </div>
  );
};

const FourOOneKCard: FC<FourOOneKCardProps> = ({
  plan,
  moveBottom,
  moveTop,
  hideTop,
  hideBottom,
  renderReorder,
  dataIndex,
}: FourOOneKCardProps) => {
  const upClient = useUPClient();
  const { masterId, isEditMode } = useBenguide();

  const { id } = plan;

  const [contentLoading, setContentLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedEditorContent, setSelectedEditorContent] =
    useState<string>('');
  const [editorContent, setEditorContent] = useState<{ [key: string]: string }>(
    {
      [SectionName.FOUR_ONE_K_OVERVIEW_TEXT]: '',
      [SectionName.FOUR_ONE_K_CONTRIBUTIONS_TEXT]: '',
      [SectionName.FOUR_ONE_K_ADVANTAGES_TEXT]: '',
    }
  );
  const [planContent, setPlanContent] = useState<{ [key: string]: string }>({
    [SectionName.FOUR_ONE_K_OVERVIEW_TEXT]: '',
    [SectionName.FOUR_ONE_K_CONTRIBUTIONS_TEXT]: '',
    [SectionName.FOUR_ONE_K_ADVANTAGES_TEXT]: '',
  });
  const [selectedOption, setSelectedOption] = useState<string>(
    SectionName.FOUR_ONE_K_OVERVIEW_TEXT
  );
  const [activeTab, setActiveTab] = useState<string>(
    SectionName.FOUR_ONE_K_OVERVIEW_TEXT
  );

  const planCardEditorRef = useRef<any>(null);

  const defaultRichMedia = useAppSelector(
    (state) => state.fourOOneK.fourOOneKDefaultRichMedia.media
  );

  useEffect(() => {
    getContent(masterId, id);
  }, [masterId, id]);

  const getContent = async (masterId: string, planId: string) => {
    setContentLoading(true);
    try {
      const promises = await Promise.all(
        FOUR_ONE_K_SECTIONS.map((section) => {
          const result = BenguideService.getRichMediaContentForPlan(
            masterId,
            section.code,
            planId,
            false
          ).then((response) => ({
            section: section.code,
            data: response.data,
          }));
          return result;
        })
      );
      promises.forEach((sectionData) => {
        setPlanContent((prevState) => ({
          ...prevState,
          [sectionData.section]: sectionData.data.content,
        }));
        setEditorContent((prevState) => ({
          ...prevState,
          [sectionData.section]: sectionData.data.content,
        }));
        if (sectionData.section === SectionName.FOUR_ONE_K_OVERVIEW_TEXT) {
          setSelectedEditorContent(sectionData.data.content);
          if (planCardEditorRef.current) {
            planCardEditorRef.current.changeContent(sectionData.data.content);
          }
        }
      });
    } catch (error) {}
    setContentLoading(false);
  };

  const saveContent = async () => {
    const editedContent = Object.entries(editorContent)
      .filter(([key, value]) => {
        return planContent[key] !== value;
      })
      .map(([key, value]) => ({ section: key, content: value }));
    setContentLoading(true);

    const editPromises = async () => {
      try {
        for (const section of editedContent) {
          await BenguideService.updateRichMediaContent(
            masterId,
            section.section,
            plan.id,
            section.content
          );
          setPlanContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          setEditorContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          if (activeTab === section.section) {
            setSelectedEditorContent(section.content);
          }
        }
      } catch (error) {}
    };
    editPromises();

    setContentLoading(false);
  };

  const updateEditorContent = (data: string) => {
    setSelectedEditorContent(data);
    setEditorContent((prevData) => ({ ...prevData, [selectedOption]: data }));
  };

  const resetAll = (): void => {
    setEditorContent({ ...planContent });
    setSelectedOption(activeTab);
    setSelectedEditorContent(planContent[activeTab]);
  };

  // selecting section in editor
  const onSelectOptionChange = (section: SectionName) => {
    setSelectedOption(section);
    setSelectedEditorContent(editorContent[section]);
  };

  const onTabsChange = (key: string) => {
    setActiveTab(key);
    onSelectOptionChange(key as SectionName);
  };

  const getDefaultRichMediaForSection = (sectionCode: string) => {
    const sectionPropertyName = FOUR_ONE_K_SECTIONS.find(
      (section) => section.code === sectionCode
    )?.code;
    if (sectionPropertyName && defaultRichMedia[sectionPropertyName]) {
      return defaultRichMedia[sectionPropertyName];
    }
  };

  const renderHoverMoveOverlay = () => (
    <div className={styles.moveOverlay}>
      {/* Top Section */}
      {!hideTop ? (
        <div className={styles.top} onClick={moveTop}>
          <LeftArrowIcon className={styles.topIcon} />
          <label>Move</label>
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}

      {/* Bottom Section */}
      {!hideBottom ? (
        <div className={styles.bottom} onClick={moveBottom}>
          <label>Move</label>
          <RightArrowIcon className={styles.bottomIcon} />
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}
    </div>
  );

  return (
    <div className={styles.fourOOneKCardWrapper}>
      {renderReorder && (
        <div className={styles.moveWrapper}>
          <EditableHotspot
            alwaysVisible={false}
            className={styles.hotSpotHoverContainer}
            customModal={() => <></>}
            overlayComponent={renderHoverMoveOverlay()}
          >
            <div className={styles.moveArea}></div>
          </EditableHotspot>
        </div>
      )}
      <div className={styles.summaryWrapper}>
        <EditableHotspot
          alwaysVisible={false}
          editText="Edit"
          editIcon={<img src={IconEdit} alt="edit" />}
          useChannel={{
            client: upClient,
            channel: FOUR_O_ONE_K_PLAN_CHANNEL,
            event: OPEN_FOUR_O_ONE_K_PLAN_EDIT_MODAL,
            data: plan,
          }}
          dataTestId={`fourOOneKCard${dataIndex}-name`}
        >
          <div className={styles.summary}>
            <PlanCardHeader
              planName={plan.name}
              carrier={plan.benefitCarrier}
              className={styles.detailsHeader}
            />
            {!isEmpty(plan.details) && (
              <InfoField label="401(k) Match Policy" value={plan.details} />
            )}
            <div className={styles.contributionFields}>
              {plan.annualMaxContribution !== null &&
                plan.annualMaxContribution !== undefined && (
                  <InfoField
                    label="Annual Max Contribution"
                    value={`${currencyFormatter(
                      parseFloat(plan.annualMaxContribution || '')
                    )}`}
                  />
                )}
              {plan.annualCatchupContribution !== null &&
                plan.annualCatchupContribution !== undefined && (
                  <>
                    <InfoField
                      label="Annual Max Catch-Up Contribution*"
                      value={`${currencyFormatter(
                        parseFloat(plan.annualCatchupContribution || '')
                      )}`}
                    />
                    <InfoField
                      label="*Catch-up only for individuals who are age 50 or over at the end of the calendar year"
                      className={styles.disclaimer}
                    />
                  </>
                )}
            </div>

            {(!isEmpty(plan.documents) ||
              !isEmpty(plan.additionalDocuments) ||
              !isEmpty(plan.additionalWeblinks)) && (
              <div
                className={styles.viewDetailsLink}
                onClick={() => setIsVisible(true)}
              >
                Plan Resources
              </div>
            )}
          </div>
        </EditableHotspot>
      </div>
      <div className={styles.tabsArea}>
        {contentLoading ? (
          <Spin />
        ) : (
          <PlanCardSectionEditor
            ref={planCardEditorRef}
            activeKey={activeTab}
            defaultActiveKey={SectionName.FOUR_ONE_K_OVERVIEW_TEXT}
            onTabClick={onTabsChange}
            editorSelectedTab={selectedOption}
            onEditorTabChange={onSelectOptionChange}
            tabs={FOUR_ONE_K_SECTIONS}
            planContent={planContent}
            isEditMode={isEditMode}
            resetAll={resetAll}
            selectedEditorContent={selectedEditorContent}
            onResetToDefault={() => {}}
            selectedTabDefaultContent={getDefaultRichMediaForSection(
              selectedOption
            )}
            onUpdateContent={updateEditorContent}
            onResetToCurrent={() => {
              setEditorContent({ ...planContent });
            }}
            benguideId={masterId}
            onSaveContent={saveContent}
            dataIndex={dataIndex}
          />
        )}
      </div>
      <ViewResourcesModal
        plan={plan}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        benefitKind={BENEFIT_CATEGORY.RETIREMENT.value}
      />
    </div>
  );
};
export default FourOOneKCard;
