export const EXPIRE_TIME = 'expire_time'; // expire key for local storage.
export const LOGGED_IN_USER_EMAIL = 'logged_user'; // expire key for local storage.

export const AUTH_VIEW = {
  LOGIN: { type: 'LOGIN', value: 'Log in' },
  REQUEST_RESET: { type: 'REQUEST_RESET', value: 'Reset Password' },
  PWD_RESET: { type: 'PWD_RESET', value: 'Reset Password' },
  REGISTER: { type: 'REGISTER', value: 'Register' },
  EMAIL: { type: 'EMAIL', value: 'Register' },
  CODE: { type: 'CODE', value: 'Register' },
  ROSTER: { type: 'ROSTER', value: 'Register' },
  PERSONAL: { type: 'PERSONAL', value: 'Register' },
  VERIFY: { type: 'VERIFY', value: 'Check Your Email' },
  FALL_BACK: { type: 'FALL_BACK', value: 'Trouble Logging In ?' },
  SSO_REGISTER: { type: 'SSO_REGISTER' },
  ER_ADMIN: { type: 'ER_ADMIN', value: 'Registration Success' },
  TERMS: { type: 'TERMS', value: 'Terms and Conditions' },
};

export const PLAN_VIEW: Record<string, any> = {
  ON_BOARDING: {
    type: 'ON_BOARDING',
    title: 'Your Benefits at a Glance',
    fallBackTitle: 'Your Benefits are on the way!',
    descripion:
      'Next, you’ll enter and store your benefits ID card information to quickly access it here.',
    fallBackDescription:
      'Your benefits team is working hard to bring your benefits to DBG. Please check back soon.',
  },
  MEDICAL: {
    type: 'MEDICAL',
    title: 'Which Medical Plan Do You Have?',
    value: 'Medical',
    viewTitle: 'Your Medical ID Card',
  },
  DENTAL: {
    type: 'DENTAL',
    title: 'Which Dental Plan Do You Have?',
    value: 'Dental',
    viewTitle: 'Your Dental ID Card',
  },
  VISION: {
    type: 'VISION',
    title: 'Which Vision Plan Do You Have?',
    value: 'Vision',
    viewTitle: 'Your Vision ID Card',
  },
  CARD: {
    type: 'CARD',
    value: 'ID CARD',
  },
  TERMS: {
    type: 'TERMS',
    value: 'Terms and Conditions',
  },
};

export const LoginType = {
  PWD: 'PWD',
  SSO: 'SSO',
};

export const URIAuthTypes = {
  DBG_RESET_PWS: 'DBG_RESET_PWS',
  DBG_SIGNUP: 'DBG_SIGNUP',
  ID_CARD: 'ID_CARD',
};
