import { SectionName, ImageType } from 'modules/home/constants';
import OEMeeting from 'model/oeMeeting/OEMeeting';

import { baseApi } from 'utils/apiUtil';
import {
  http,
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
} from 'utils/httpUtil';
import BenefitGuideBasicInfo from 'model/BenefitGuideBasicInfo ';
import BenefitGuide from 'model/BenefitGuide';
import OriginalImageCropParams from 'model/OriginalImageCropParams';

const benguideApiV2 = `${baseApi}/v2/benefit-guides`;

export const createBenGuide = (benGuide: BenefitGuideBasicInfo) => {
  return http.post(benguideApiV2, { ...benGuide });
};

export const updateBasicInfo = (benGuideBasicInfo: BenefitGuideBasicInfo) => {
  return http.put(`${benguideApiV2}/${benGuideBasicInfo.masterId}/basic-info`, {
    ...benGuideBasicInfo,
  });
};

export const updateBenGuide = (benGuide: BenefitGuide) => {
  return http.put(`${benguideApiV2}/${benGuide.id}`, { ...benGuide });
};

export const findBenGuideByHash = (urlHash: string, revision: number) => {
  return http.get(`${benguideApiV2}?url-hash=${urlHash}&revision=${revision}`);
};

export const getDefaultRichMedia = async (
  benguideId: string,
  sectionName: SectionName
) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/rich-media?section-name=${sectionName}&default-value=${true}`
  );
};

export const createRichMedia = (benguideId: string, data: any) => {
  return http.put(`${benguideApiV2}/${benguideId}/rich-media`, data);
};

export const uploadRichMedia = async (benguideId: string, logo: File) => {
  const formData = new FormData();
  formData.append('file', logo);
  return http.post(
    `${benguideApiV2}/${benguideId}/rich-media/files`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const uploadImage = async (
  benguideId: string,
  image: Blob,
  imageType: ImageType,
  original?: boolean,
  cropArea?: OriginalImageCropParams,
  originalRef?: string
) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('type', imageType);
  if (originalRef) {
    formData.append('original-reference', originalRef);
  }
  if (cropArea) {
    formData.append('height', cropArea.height.toString());
    formData.append('width', cropArea.width.toString());
    formData.append('x', cropArea.x.toString());
    formData.append('y', cropArea.y.toString());
  }
  if (original) {
    formData.append('original', 'true');
  }

  return http.put(`${benguideApiV2}/${benguideId}/common-images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadSnapShotImage = async (benguideId: string, image: Blob) => {
  const formData = new FormData();
  formData.append('image', image);
  return http.put(`${benguideApiV2}/${benguideId}/thumbnail`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadSectionImage = async (
  benguideId: string,
  image: Blob,
  sectionName: SectionName,
  original?: boolean,
  originalRef?: string,
  cropArea?: OriginalImageCropParams
) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('section-name', sectionName);

  if (originalRef) {
    formData.append('original-reference', originalRef);
  }

  if (cropArea) {
    formData.append('height', cropArea.height.toString());
    formData.append('width', cropArea.width.toString());
    formData.append('x', cropArea.x.toString());
    formData.append('y', cropArea.y.toString());
  }
  if (original) {
    formData.append('original', 'true');
  }
  return http.put(`${benguideApiV2}/${benguideId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteSectionImage = async (
  benguideId: string,
  imageType: SectionName
) => {
  return http.delete(
    `${benguideApiV2}/${benguideId}/images?section-name=${imageType}`
  );
};

export const deleteCommonImageContent = async (
  benguideId: string,
  imageType: ImageType
) => {
  return http.delete(
    `${benguideApiV2}/${benguideId}/common-images?type=${imageType}`
  );
};

export const createOEMeeting = async (
  benGuideId: string,
  oeMeeting: OEMeeting
) => {
  return http.post(`${benguideApiV2}/${benGuideId}/oe-meetings`, oeMeeting);
};

export const getOEMeeting = async (benGuideId: string, oeMeetingId: string) => {
  return http.get(`${benguideApiV2}/${benGuideId}/oe-meetings/${oeMeetingId}`);
};

export const updateOEMeeting = async (
  benGuideId: string,
  oeMeeting: OEMeeting
) => {
  return http.put(`${benguideApiV2}/${benGuideId}/oe-meetings`, oeMeeting);
};

export const uploadOEMeetingVideoRecording = async (
  benGuideId: string,
  fileBlob: File
) => {
  const formData = new FormData();
  formData.append('video', fileBlob);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
  };
  return http.post(
    `${benguideApiV2}/${benGuideId}/oe-meetings/video`,
    formData,
    config
  );
};

export const getOEMeetingVideoRecording = (
  benGuideId: string,
  isDownload: boolean = true
) => {
  return `${benguideApiV2}/${benGuideId}/oe-meetings/video?is-download=${isDownload}`;
};

export const getContentPage = async (benguideId: string, section: string) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/content-page?section=${section}`
  );
};

export const updateContentPage = async (benguideId: string, data: any) => {
  return http.put(`${benguideApiV2}/${benguideId}/content-page`, data);
};

export const updateStyles = async (benguideId: string, data: any) => {
  return http.put(`${benguideApiV2}/${benguideId}/styles`, data);
};

export const getCheckList = async (benguideId: string, section: string) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/check-list?section-name=${section}`
  );
};

export const updateCheckList = async (benguideId: string, data: any) => {
  return http.put(`${benguideApiV2}/${benguideId}/check-list`, data);
};

export const updateSection = async (
  benguideId: string,
  section: string,
  data: any
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/section/${section}/enabled`,
    data
  );
};

export const updatePageSection = async (
  benguideId: string,
  section: string,
  sectionName: string,
  data: any
) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/section/${section}/page/${sectionName}/enabled`,
    data
  );
};

export const updatePageSections = async (benguideId: string, data: any) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/section-date-and-eligibility`,
    data
  );
};

export const regenerateContent = (benguideId: string, sectionName: string) => {
  return http.put(
    `${benguideApiV2}/${benguideId}/regenerate-content?section-name=${sectionName}`
  );
};
