import { ReactNode } from 'react';
import { ReactComponent as TaxAdvantagedIcon } from 'images/icons/side-nav/tax-advantaged.svg';
import { ReactComponent as CommuterIcon } from 'images/icons/side-nav/commuter.svg';
import { SectionName } from 'modules/home/constants';

export enum TaxAdvantagedSection {
  FSA = 'FSA',
  HRA = 'HRA',
  HSA = 'HSA',
  COMMUTER = 'COMMUTER',
}

export const TAX_ADVANTAGED_BENEFIT_TYPE_INFO: {
  [key in TaxAdvantagedSection]: {
    value: string;
    label: string;
    headerIcon: ReactNode;
  };
} = {
  FSA: { value: 'FSA', label: 'FSA', headerIcon: <TaxAdvantagedIcon /> },
  HRA: { value: 'HRA', label: 'HRA', headerIcon: <TaxAdvantagedIcon /> },
  HSA: { value: 'HSA', label: 'HSA', headerIcon: <TaxAdvantagedIcon /> },
  COMMUTER: {
    value: 'COMMUTER_GENERIC',
    label: 'Commuter',
    headerIcon: <CommuterIcon />,
  },
};

export enum TaxAdvantageBenefitKind {
  HRA = 'HRA',
  HEALTHCARE_FSA = 'HEALTHCARE_FSA',
  DEPENDENT_CARE_FSA = 'DEPENDENT_CARE_FSA',
  LIMITED_PURPOSE_FSA = 'LIMITED_PURPOSE_FSA',
  HSA = 'HSA',
  COMMUTER = 'COMMUTER',
}

export const TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP: {
  [key in TaxAdvantageBenefitKind]: {
    section: TaxAdvantagedSection;
    benefitKind: string;
    label: string;
    value: string;
  };
} = {
  HSA: {
    section: TaxAdvantagedSection.HSA,
    benefitKind: 'HSA',
    label: 'HSA',
    value: 'HRA',
  },
  HRA: {
    section: TaxAdvantagedSection.HSA,
    benefitKind: 'HRA',
    label: 'HRA',
    value: 'HRA',
  },
  COMMUTER: {
    section: TaxAdvantagedSection.COMMUTER,
    benefitKind: 'COMMUTER_GENERIC',
    label: 'Commuter',
    value: 'COMMUTER',
  },
  HEALTHCARE_FSA: {
    section: TaxAdvantagedSection.FSA,
    benefitKind: 'FSA',
    label: 'Healthcare FSA',
    value: 'HEALTHCARE_FSA',
  },
  DEPENDENT_CARE_FSA: {
    section: TaxAdvantagedSection.FSA,
    benefitKind: 'DCAP_FSA',
    label: 'Dependent Care FSA',
    value: 'DEPENDENT_CARE_FSA',
  },
  LIMITED_PURPOSE_FSA: {
    section: TaxAdvantagedSection.FSA,
    benefitKind: 'LIMITED_FSA',
    label: 'Limited Purpose FSA',
    value: 'LIMITED_PURPOSE_FSA',
  },
};

export const FSA_BENEFIT_KINDS = [
  TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.HEALTHCARE_FSA,
  TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.DEPENDENT_CARE_FSA,
  TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.LIMITED_PURPOSE_FSA,
];

export const HSA_PLAN_CONTENT_TABS = [
  {
    title: 'Overview',
    code: SectionName.HSA_OVERVIEW_TEXT,
  },
  {
    title: 'Eligibility',
    code: SectionName.HSA_ELIGIBILITY_TEXT,
  },
  {
    title: 'Expenses',
    code: SectionName.HSA_EXPENSES_TEXT,
  },
  {
    title: 'Advantages',
    code: SectionName.HSA_ADVANTAGES_TEXT,
  },
  {
    title: 'Things to Consider',
    code: SectionName.HSA_CONSIDERATIONS_TEXT,
  },
];

export const HRA_PLAN_CONTENT_TABS = [
  {
    title: 'Overview',
    code: SectionName.HRA_OVERVIEW_TEXT,
  },
  {
    title: 'Eligibility',
    code: SectionName.HRA_ELIGIBILITY_TEXT,
  },
  {
    title: 'Expenses',
    code: SectionName.HRA_EXPENSES_TEXT,
  },
  {
    title: 'Advantages',
    code: SectionName.HRA_ADVANTAGES_TEXT,
  },
  {
    title: 'Things to Consider',
    code: SectionName.HRA_CONSIDERATIONS_TEXT,
  },
];

export const COMMUTER_PLAN_CONTENT_TABS = [
  {
    title: 'Overview',
    code: SectionName.COMMUTER_OVERVIEW_TEXT,
  },
  {
    title: 'Eligibility',
    code: SectionName.COMMUTER_ELIGIBILITY_TEXT,
  },
  {
    title: 'Expenses',
    code: SectionName.COMMUTER_EXPENSES_TEXT,
  },
  {
    title: 'Advantages',
    code: SectionName.COMMUTER_ADVANTAGES_TEXT,
  },
  {
    title: 'Things to Consider',
    code: SectionName.COMMUTER_CONSIDERATIONS_TEXT,
  },
];
export const FSA_PLAN_CONTENT_TABS = [
  {
    title: 'Overview',
    code: SectionName.FSA_OVERVIEW_TEXT,
  },
  {
    title: 'Eligibility',
    code: SectionName.FSA_ELIGIBILITY_TEXT,
  },
  {
    title: 'Expenses',
    code: SectionName.FSA_EXPENSES_TEXT,
  },
  {
    title: 'Advantages',
    code: SectionName.FSA_ADVANTAGES_TEXT,
  },
  {
    title: 'Things to Consider',
    code: SectionName.FSA_CONSIDERATIONS_TEXT,
  },
];

export const DCAP_FSA_PLAN_CONTENT_TABS = [
  {
    title: 'Overview',
    code: SectionName.DCAP_FSA_OVERVIEW_TEXT,
  },
  {
    title: 'Eligibility',
    code: SectionName.DCAP_FSA_ELIGIBILITY_TEXT,
  },
  {
    title: 'Expenses',
    code: SectionName.DCAP_FSA_EXPENSES_TEXT,
  },
  {
    title: 'Advantages',
    code: SectionName.DCAP_FSA_ADVANTAGES_TEXT,
  },
  {
    title: 'Things to Consider',
    code: SectionName.DCAP_FSA_CONSIDERATIONS_TEXT,
  },
];

export const LIMITED_FSA_PLAN_CONTENT_TABS = [
  {
    title: 'Overview',
    code: SectionName.LIMITED_FSA_OVERVIEW_TEXT,
  },
  {
    title: 'Eligibility',
    code: SectionName.LIMITED_FSA_ELIGIBILITY_TEXT,
  },
  {
    title: 'Expenses',
    code: SectionName.LIMITED_FSA_EXPENSES_TEXT,
  },
  {
    title: 'Advantages',
    code: SectionName.LIMITED_FSA_ADVANTAGES_TEXT,
  },
  {
    title: 'Things to Consider',
    code: SectionName.LIMITED_FSA_CONSIDERATIONS_TEXT,
  },
];

export const EMPLOYER_FREQUENCY_PLAN_LABEL_MAPPING: {
  [key: string]: string;
} = {
  MONTHLY: 'Month',
  QUARTERLY: 'Quarter',
  ANNUALLY: 'Year',
  SEMI_MONTHLY: 'Semi-Monthly',
  BI_WEEKLY: 'Bi-Weekly',
};
