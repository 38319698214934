import { FC } from 'react';

import Modal, { ModalProps } from 'antd/lib/modal/Modal';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import BenefitCarrier from 'model/BenefitCarrier';
import closeIcon from 'images/icon-close.svg';
import { addHttp } from 'utils/httpUtil';

import styles from './providerSearchModal.module.less';

interface ProviderSearchModalProps extends ModalProps {
  visible: boolean;
  carrier: BenefitCarrier;
  planName: string;
  networkName: string;
}

const ProviderSearchModal: FC<ProviderSearchModalProps> = ({
  onCancel,
  visible,
  carrier,
  planName,
  networkName,
}: ProviderSearchModalProps) => {
  const { name, logoUrl, providerSearchUrl, providerSearchAdditionalInfo } =
    carrier || {};

  const searchCarrier = () => {
    providerSearchUrl && window.open(addHttp(providerSearchUrl), '_blank');
  };

  return (
    <Modal
      maskClosable={false}
      width={820}
      visible={visible}
      footer={null}
      centered
      className={styles.providerSearchWrapper}
      onCancel={onCancel}
      closeIcon={<img src={closeIcon} alt="close-icon" />}
    >
      <div className={styles.providerHeader}>Provider Search</div>
      <div className={styles.logoWrapper}>
        <img src={logoUrl} alt="carrier" className={styles.logo} />
      </div>
      <div className={styles.instructionWrapper}>
        <div className={styles.searchHeader}>Search Instructions</div>
        {networkName && (
          <>
            <span className={styles.urlSection}>
              The network name for this plan{' '}
              <p className={styles.boldTextWrapper}>({planName})</p> is{' '}
              <p className={styles.boldTextWrapper}>{networkName}</p>
            </span>
            <div className={styles.urlSectionSeparator} />
          </>
        )}
        <span>
          You can search for providers on {name}&apos;s website at
          <a
            className={styles.homeUrl}
            href={addHttp(providerSearchUrl ? providerSearchUrl : '')}
            target="_blank"
            rel="noreferrer"
          >
            {providerSearchUrl}
          </a>
        </span>
      </div>
      {providerSearchAdditionalInfo && (
        <div className={styles.additionalInfo}>
          <div className={styles.searchHeader}>Additional Information</div>
          <ul className={styles.instructionList}>
            {providerSearchAdditionalInfo}
          </ul>
        </div>
      )}

      <div className={styles.buttonWrapper}>
        <SubmitButton
          className={styles.searchButton}
          onClick={searchCarrier}
          type="primary"
        >
          Search on Carrier Site
        </SubmitButton>
      </div>
    </Modal>
  );
};

export default ProviderSearchModal;
