export const ASSISTANT_INITIALIZE_TEXT = 'Talk with AI Assistant';
export const ASSISTANT_INITIALIZE_WARNING =
  'AI Assistant can make mistakes. Consider checking important information.';

export const PLAN_HEADER_TEXT = 'Plan Assistance';
export const SELECTION_PLAN_ASSISTANCE_TEXT =
  'Use our AI Assistant to inquire about any plans from this benefits guide.';

export const GENERAL_HEADER_TEXT = 'General Questions';
export const SELECTION_GENERAL_ASSISTANT_TEXT =
  'Have a general question outside of specific plans? Ask anything.';

export const HELP_HEADER_TEXT = 'How can I help you today?';

export const PLAN_ASSISTANT_TEXT =
  'What would you like to know about these plans?';

export const GENERAL_ASSISTANT_TEXT =
  'Have a general question? Ask me anything.';

export const TYPING_DELAY = 30;

export const SCROLLING_DELAY = 5000;

export const PLAN_DOCUMENT_NOT_FOUND =
  'Selected plan has no associated document. Please select another plan.';

export const ASSISTANT_COMMON_ERROR = `Sorry, I'm unable to retrieve the information you're looking for at the moment. Please try again later or refine your query.`;

export const PLAN_CHANGE_POPOVER_TEXT = 'Start a new chat to change the plan';
