import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { PLAN_RECOMMENDER_QUESTIONS_ICONS } from 'modules/planRecommender/pages/constants';
import infoIcon from 'images/icons/icon-info.png';

import styles from './planRecommender.module.less';

type PlanRecommenderSliderProps = {
  config: any;
  onUpdateAnswer: Function;
  id: string;
  currentAnswer: any;
};

const PlanRecommenderSlider: FC<PlanRecommenderSliderProps> = (
  props: PlanRecommenderSliderProps
) => {
  const [selectorLocation, setSelectorLocation] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);

  const [answer, setAnswer] = useState<number>();

  const selectorCircle = useRef(null);
  const indecatorLine = useRef(null);

  const { config = {}, currentAnswer } = props;
  const {
    steps = [],
    minLabel,
    maxLabel,
    sliderHandleIcon,
    sliderHandleLabel = '',
    title,
    infoText,
    maxValue,
    minValue,
  } = config;

  const onUpdateAnswer = useCallback((value: number) => {
    setAnswer(value);
  }, []);

  useEffect(() => {
    props.onUpdateAnswer(answer);
    // Answer changes only need to update
    // eslint-disable-next-line
  }, [answer]);

  useEffect(() => {
    const { current } = selectorCircle;
    if (current) {
      (current as any).addEventListener('mousedown', () => {
        document.onmouseup = (event) => {
          document.onmouseup = null;
          document.onmousemove = null;
          updateSelectorLocation(getPositionOnIndecator(event));
        };
        document.onmousemove = (event) => {
          event.preventDefault();
          const location = getPositionOnIndecator(event);
          if (location >= 0 && location <= 100) {
            setSelectorLocation(location);
          }
        };
      });
    }
    if (current) {
      (current as any).addEventListener('touchmove', () => {
        document.ontouchend = (event) => {
          event.preventDefault();
          document.ontouchend = null;
          document.ontouchmove = null;
          updateSelectorLocation(getPositionOnIndecator(event));
        };
        document.ontouchmove = (event) => {
          event.preventDefault();
          const location = getPositionOnIndecator(event);
          if (location >= 0 && location <= 100) {
            setSelectorLocation(location);
          }
        };
      });
    }
    // Only need to bind the event when component mount
    // eslint-disable-next-line
  }, []);

  const getPositionOnIndecator = (event: any) => {
    const { current } = indecatorLine;
    const { x, width } = (current as any).getBoundingClientRect();
    if (event.type == 'touchmove') {
      return ((event.touches[0].clientX - x) / width) * 100;
    }
    if (event.type == 'touchend') {
      return ((event.changedTouches[0].clientX - x) / width) * 100;
    }
    return ((event.clientX - x) / width) * 100;
  };

  const clickOnIndecatorLine = (event: any) => {
    const { x, width } = event.target.getBoundingClientRect();
    updateSelectorLocation(((event.clientX - x) / width) * 100);
  };

  const getStepLocations = useCallback(() => {
    const { steps = [] } = config;
    const stepLocations = [0];
    const offSet = 100 / steps.length / 2;
    for (let i = 0; i < steps.length; i++) {
      stepLocations.push((100 / steps.length) * i + offSet);
    }
    return stepLocations;
  }, [config]);

  const updateSelectorLocation = useCallback(
    (location: number) => {
      const stepLocations = getStepLocations();
      const closestStep = stepLocations.reduce(function (prev, curr) {
        return Math.abs(curr - location) < Math.abs(prev - location)
          ? curr
          : prev;
      });
      setSelectorLocation(closestStep);
      setActiveStep(stepLocations.indexOf(closestStep));
      if (closestStep === 100) {
        onUpdateAnswer(maxValue);
      } else if (closestStep === 0) {
        onUpdateAnswer(minValue);
      } else {
        onUpdateAnswer(steps[stepLocations.indexOf(closestStep) - 1].value);
      }
    },
    [getStepLocations, maxValue, minValue, onUpdateAnswer, steps]
  );

  const updateCurrentAnswer = useCallback(() => {
    const { steps = [], maxValue, minValue } = config;
    const stepLocations = getStepLocations();
    if (currentAnswer === minValue) {
      updateSelectorLocation(stepLocations[0]);
    } else if (currentAnswer === maxValue) {
      updateSelectorLocation(stepLocations[stepLocations.length - 1]);
    } else if (currentAnswer) {
      let currentSelectIndex = 0;
      steps.forEach((step: any, index: number) => {
        if (step.value === currentAnswer) {
          currentSelectIndex = index + 1;
        }
      });
      updateSelectorLocation(stepLocations[currentSelectIndex]);
    }
  }, [config, currentAnswer, getStepLocations, updateSelectorLocation]);

  useEffect(() => {
    if (currentAnswer) {
      updateCurrentAnswer();
    }
    // Only need update current answer when change updateCurrentAnswer function
    // eslint-disable-next-line
  }, [currentAnswer]);

  return (
    <div className={`${styles.planRecommenderSlider} planRecommenderSlider`}>
      <div className={`${styles.content} content`}>
        <div className={`${styles.titleSlider} titleSlider`}>
          {title}
          <img
            src={infoIcon}
            alt="info"
            className={styles.infoTooltipIcon}
            data-place="right"
            data-tip={infoText}
            data-for="sliderInfoTooltip"
          />
        </div>
        <ReactTooltip
          effect="solid"
          id="sliderInfoTooltip"
          className={styles.sliderInfoTooltip}
          place="right"
          type="light"
          border={true}
          borderColor="#d8d8d8"
        />
        <div className={`${styles.sliderWrapper} sliderWrapper`}>
          <div className={`${styles.sliderHeader} sliderHeader`}>
            <div className={styles.minLabel}>{minLabel}</div>
            <div className={styles.maxLabel}>{maxLabel}</div>
          </div>
          <div
            className={styles.slector}
            style={{ left: `calc(${selectorLocation}% - 15px)` }}
          >
            <div className={styles.slectorInner}></div>
            <div
              className={`${styles.slectorInner} ${styles.selectorClickable}`}
              ref={selectorCircle}
            ></div>
          </div>
          <div className={styles.sliderIndicator}></div>
          <div
            className={styles.sliderIndicatorHighlited}
            style={{ width: `${selectorLocation}%` }}
          ></div>
          <div
            ref={indecatorLine}
            className={styles.sliderIndicatorClickable}
            onClick={clickOnIndecatorLine}
          ></div>
          <div
            className={styles.sliderSteps}
            style={{ marginLeft: `calc(100%/${steps.length * 2})` }}
          >
            {steps.map((step: any, index: number) => {
              const { label } = step;
              return (
                <div
                  key={index}
                  className={styles.step}
                  style={{
                    left: `calc( ${(index / steps.length) * 100}% )`,
                  }}
                >
                  <div className={styles.label}>{label}</div>

                  {activeStep === index + 1 && (
                    <>
                      <div className={styles.sliderHandleLabel}>
                        {sliderHandleLabel}
                      </div>
                      <div className={styles.image}>
                        <img
                          src={
                            (PLAN_RECOMMENDER_QUESTIONS_ICONS as any)[
                              sliderHandleIcon
                            ]
                          }
                          alt=""
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanRecommenderSlider;
