import { PromptType } from 'model/AiAssistant/common';
import { baseApi, wsBaseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';
import ConversationUserType from 'modules/aiAssistantChat/enums/ConversationUserType';

const v1ConversationURL = `${baseApi}/v1/ai-assistant`;

export const WEB_SOCKET_URL = wsBaseApi + '/websocket-ai-orchestration';

export const APP_PREFIX = '/app';
export const TOPIC_PREFIX = '/topic';
export const BASE_DESTINATION_URL = '/assistant/chat/';

export const assistantWSURL = (conversationId: string): string =>
  `${BASE_DESTINATION_URL}${conversationId}`;

export const publishingURL = (conversationId: string): string =>
  `${APP_PREFIX}${assistantWSURL(conversationId)}`;

export const receivingURL = (conversationId: string): string =>
  `${TOPIC_PREFIX}${assistantWSURL(conversationId)}`;

export const getInitialChatAnswer = async ({
  promptType,
  question,
  documentReference,
  planId,
  category,
  benGuideId,
  conversationUserType = ConversationUserType.DBG_USER,
}: {
  promptType: PromptType;
  question: string;
  documentReference?: string;
  planId?: string;
  category?: string;
  benGuideId?: string;
  conversationUserType?: ConversationUserType;
}) => {
  const data = {
    type: promptType,
    question,
    documentReference,
    planId,
    benefitCategory: category,
    benGuideId,
    conversationUserType,
  };
  const response = await http.post(`${v1ConversationURL}`, data);

  return response;
};

export const getValidatePlanDocument = async ({
  promptType,
  planId,
  category,
}: {
  promptType: PromptType;
  planId: string;
  category: string;
}) => {
  const data = {
    type: promptType,
    planId,
    benefitCategory: category,
  };
  const response = await http.post(`${v1ConversationURL}/dbg/validate`, data);

  return response;
};
