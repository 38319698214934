import {
  BENGUIDE_ACK_NOTIFICATION,
  BENGUIDE_NOTIFICATION_RECEIVED,
  BENGUIDE_NOTIFICATIONS,
} from 'modules/clients/UPClient/UPClientConst';
import {
  NOTIFICATION_GENERAL_UPDATE_STATE,
  NOTIFICATION_GENERAL_ARCHIVED_STATE,
} from 'constants/commonConstants';
import UPClient from 'modules/clients/UPClient/UPClient';
import useUPClient from 'modules/clients/UPClient/useUPClient';

interface BenefitGuideNotificationsChannelProps {
  setDisableSave?: () => void;
  isOverlayHide?: boolean;
}

const BenefitGuideNotificationsChannel = (
  props: BenefitGuideNotificationsChannelProps
) => {
  const { setDisableSave = false } = props;
  const upClient = useUPClient();
  return (
    <UPClient
      channel={BENGUIDE_NOTIFICATIONS}
      subscribe={(event: string, data: any) => {
        if (event === BENGUIDE_NOTIFICATION_RECEIVED && data) {
          if (
            data.state === NOTIFICATION_GENERAL_UPDATE_STATE &&
            (!data.newStatus ||
              data.newStatus !== NOTIFICATION_GENERAL_ARCHIVED_STATE)
          ) {
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_ACK_NOTIFICATION,
              data: {},
            });
            if (setDisableSave) {
              setDisableSave();
            }
          }
        }
      }}
    />
  );
};

export default BenefitGuideNotificationsChannel;
