export enum PromptType {
  DBG_PLAN = 'DBG_PLAN',
  GENERAL_BENEFITS = 'GENERAL_BENEFITS',
}

export enum ScreenSelectionType {
  SELECTION = 'SELECTION',
  NEW_CHAT_INITIALIZE = 'NEW_CHAT_INITIALIZE',
  CHAT_HISTORY = 'CHAT_HISTORY',
}
