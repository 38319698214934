import trim from 'lodash/trim';
import { ADDITIONAL_RESOURCE_TYPES } from 'modules/resources/constants';
import { getPlanDocument } from 'modules/resources/services/ResourceService';

export const openDocument = async (
  planId: string,
  docType: string,
  benefitKind: string
) => {
  const response = await getPlanDocument(planId, docType, benefitKind);
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);

  window.open(url);
};

export const downloadDocument = async (
  planId: string,
  docType: string,
  benefitKind: string,
  fileName: string
) => {
  const response = await getPlanDocument(planId, docType, benefitKind);
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.setAttribute('target', '_blank');
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const isValidWeblink = (weblink: string): boolean => {
  const webLinkFormat = new RegExp(
    '(https?://(?:www[.]|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^s]{2,}|www[.][a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^s]{2,}|https?://(?:www[.]|(?!www))[a-zA-Z0-9]+\\.[^s]{2,}|www[.][a-zA-Z0-9]+\\.[^s]{2,})'
  );
  return webLinkFormat.test(weblink);
};

export const isValidFile = (file: string) => {
  if (trim(file)) {
    return true;
  } else {
    return false;
  }
};

export const validateResourcesFields = (
  plan: { weblink: string; file: any; fileName: string },
  type: string
) => {
  const { weblink, file, fileName } = plan;
  switch (type) {
    case ADDITIONAL_RESOURCE_TYPES.WEBLINK:
      return isValidWeblink(weblink);
    case ADDITIONAL_RESOURCE_TYPES.FILE:
      return isValidFile(file || fileName);
    default:
      return false;
  }
};

export const convertEncodedStringToBlob = async (
  imageString: string
): Promise<Blob> => {
  const imageResponse = await fetch(imageString);
  const imageBlob = await imageResponse.blob();
  return imageBlob;
};

/**
 * Enum for maintaining all file-types and corresponding mime-types used the system.
 * Please extend this object if trying to use a file-type not present in the list.
 */
export const MIME_TYPES = {
  PDF: 'application/pdf',
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  DOC: 'application/msword',
  PPT: 'application/vnd.ms-powerpoint',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  POTX: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  PPSX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  ZIP: 'application/zip',
} as const;

export const incrementNumberInFileName = (fileName: string): string => {
  const pattern = /\((\d+)\)$/;
  const match = fileName.match(pattern);

  if (match) {
    let number = parseInt(match[1]);
    number++;
    const incrementedNumber = `(${number})`;
    return fileName.replace(pattern, incrementedNumber);
  } else {
    return `${fileName} (1)`;
  }
};

export const getDocumentNameWithHighestNumber = (
  resourceNameList: string[],
  baseName: string
): string => {
  const baseNamePattern = new RegExp(`^${baseName}(?: \\((\\d+)\\))?$`);

  if (resourceNameList.length > 0) {
    // Filter documents that match the base name pattern
    const filteredDocuments = resourceNameList.filter((resource) =>
      baseNamePattern.test(resource)
    );
    if (filteredDocuments.length === 0) {
      return baseName; // No matching documents
    }
    const highest = resourceNameList.reduce((highestDoc, currentDoc) => {
      const highestNumber = highestDoc.match(baseNamePattern)?.[1];
      const currentNumber = currentDoc.match(baseNamePattern)?.[1];

      const highestNum = highestNumber ? parseInt(highestNumber) : 0;
      const currentNum = currentNumber ? parseInt(currentNumber) : 0;

      return currentNum > highestNum ? currentDoc : highestDoc;
    });
    return highest ? highest : baseName;
  } else {
    return baseName;
  }
};
