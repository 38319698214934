import { FC, useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';

import { trackEvents } from 'utils/initGA4';
import iconRemove from 'images/icons/icon-remove-plan-rec.svg';
import iconAdd from 'images/icons/icon-add-plan-rec.svg';

import styles from './planRecommenderIncrementer.module.less';

type PlanRecommenderIncrementerProps = {
  title: string;
  subTitle: string;
  config: any;
  editMode: boolean;
  index: number;
  onUpdateAnswer: Function;
  id: string;
  onNextClick: Function;
  questionsInProgress: boolean;
  currentAnswer: any;
};

const PlanRecommenderIncrementer: FC<PlanRecommenderIncrementerProps> = (
  props: PlanRecommenderIncrementerProps
) => {
  const {
    title = '',
    subTitle,
    config = {},
    editMode,
    index,
    onUpdateAnswer,
    id,
    onNextClick,
    questionsInProgress,
    currentAnswer,
  } = props;

  const { incrementerList: incrementerListProps = [] } = config;
  const [incrementerList, setIncrementerList] = useState(() => {
    const newIncrementerListProps: any = incrementerListProps?.map(
      (item: any, index: number) => {
        const eachValue = currentAnswer?.[index];
        return {
          ...item,
          defaultValue:
            item.defaultValue === eachValue
              ? item.defaultValue
              : eachValue || 0,
        };
      }
    );
    return newIncrementerListProps;
  });
  const [pendingNextEvent, setPendingNextEvent] = useState<boolean>(false);

  useEffect(() => {
    if (!questionsInProgress && pendingNextEvent) {
      setPendingNextEvent(false);
      onNextClick();
    }
  }, [questionsInProgress, pendingNextEvent, onNextClick]);

  const updateAnswers = useCallback(() => {
    const answers = incrementerList.map((listItem: any) => {
      return listItem.defaultValue;
    });
    onUpdateAnswer(id, answers);
  }, [id, incrementerList, onUpdateAnswer]);

  const validateAndUpdateValue = (index: number, value: any) => {
    const { max, min } = incrementerList[index];
    if ((value >= min && value <= max) || value === '') {
      const updatedSection = { ...incrementerList[index], defaultValue: value };
      setIncrementerList(
        incrementerList.map((section: any, index2: number) => {
          if (index === index2) {
            return updatedSection;
          }
          return section;
        })
      );
    }
  };

  const updateCurrentAnswer = () => {
    const updatedIncrementerList = incrementerList.map(
      (listItem: any, index: number) => {
        if (currentAnswer && currentAnswer[index]) {
          return {
            ...listItem,
            defaultValue:
              currentAnswer && currentAnswer[index] ? currentAnswer[index] : 0,
          };
        }
        return listItem;
      }
    );
    setIncrementerList(updatedIncrementerList);
  };

  useEffect(() => {
    updateCurrentAnswer();
    // Should call only mount
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateAnswers();
    // eslint-disable-next-line
  }, [incrementerList]);

  const clickDecrement = (index: number) => {
    validateAndUpdateValue(index, incrementerList[index].defaultValue - 1);
  };

  const changeValue = (event: any, index: number) => {
    const { value } = event.target;
    const intValue = parseInt(value);
    validateAndUpdateValue(index, isNaN(intValue) ? 0 : intValue);
  };

  const handlePaste = (e: any) => {
    const str = e.clipboardData.getData('Text');
    const newStr = str.replace(/[+-.eE]/g, '');
    if (str !== newStr) {
      e.preventDefault();
    }
  };

  const clickIncrement = (index: number) => {
    validateAndUpdateValue(
      index,
      parseInt(incrementerList[index].defaultValue + 1)
    );
  };

  const skipClick = () => {
    onUpdateAnswer(id, [0, 0, 0, 0]);
    setPendingNextEvent(true);
  };

  const nextClick = () => {
    onNextClick();
    trackEvents({
      category: 'Plan Recommender',
      action: 'plan_recommender_completed',
      label: 'Plan Recommender Completed',
    });
  };

  let isNotDisableNextBtn = false;
  return (
    <div
      className={`${styles.planRecommenderIncrementer} planRecommenderIncrementer`}
    >
      <div className={`${styles.title} title`}>{`${
        editMode ? index + 1 + '.' : ''
      } ${title}`}</div>
      {subTitle && (
        <div className={`${styles.subTitle} subTitle`}>{subTitle}</div>
      )}
      <div className={styles.incrementListWrapper}>
        <div className={styles.perYear}>Per Year</div>
        {incrementerListProps.map((item: any, index: number) => {
          const { title, subTitle, min, max } = item;
          let value = parseInt(incrementerList[index]?.defaultValue);
          if (value) {
            value = parseInt(value.toString().replace(/^0+/, ''));
          }
          isNotDisableNextBtn = value > min || isNotDisableNextBtn;
          return (
            <div key={index} className={styles.listItemWrapper}>
              <div className={styles.listItem}>
                <div className={styles.listTitle}>
                  {title}
                  <span className={styles.subTitle}> {subTitle}</span>
                </div>
                <div className={`${styles.controlBtn} ${styles.minusBtn}`}>
                  <img
                    className={!value || value === min ? styles.disabled : ''}
                    src={iconRemove}
                    alt="Minus"
                    onClick={() => {
                      clickDecrement(index);
                    }}
                  />
                </div>
                <div
                  className={`${styles.inputField} ${
                    value > 0 ? styles.active : ''
                  }`}
                >
                  <Input
                    type="number"
                    value={value}
                    min={min}
                    max={max}
                    onChange={(event) => {
                      changeValue(event, index);
                    }}
                    onPaste={handlePaste}
                    pattern="^-?[0-9]\d*\.?\d*$"
                  />
                </div>
                <div className={`${styles.controlBtn} ${styles.plusBtn}`}>
                  <img
                    className={value === max ? styles.disabled : ''}
                    src={iconAdd}
                    alt="Plus"
                    onClick={() => {
                      clickIncrement(index);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <span className={`${styles.skipText} skipText`} onClick={skipClick}>
        Skip, I’m not taking any prescriptions
      </span>
      <div className={styles.nextBtnWrapper}>
        <button
          className={`${styles.nextButton} nextButton`}
          onClick={nextClick}
          disabled={!isNotDisableNextBtn}
        >
          View My Recommendations
        </button>
      </div>
    </div>
  );
};
export default PlanRecommenderIncrementer;
