import dayjs from 'dayjs';
import PlanYear from 'model/PlanYear';
import {
  SHORT_MONTH_DATE_FORMAT,
  LONG_MONTH_DATE_FORMAT,
} from 'utils/dateUtil';

export const formatPlanYearLabel = (planYear: PlanYear) => {
  const startDate = dayjs(planYear.startDate).format(SHORT_MONTH_DATE_FORMAT);
  const endDate = dayjs(planYear.endDate).format(SHORT_MONTH_DATE_FORMAT);

  return `${planYear.name} (${startDate} - ${endDate})`;
};

export const formatPlanYear = (planYear: PlanYear) => {
  const startDate = dayjs(planYear.startDate).format(LONG_MONTH_DATE_FORMAT);
  const endDate = dayjs(planYear.endDate).format(LONG_MONTH_DATE_FORMAT);

  return `${startDate} - ${endDate}`;
};
