import { Checkbox, CheckboxProps } from 'antd';
import { FC } from 'react';

import styles from './styledCheckbox.module.less';

interface Props extends CheckboxProps {
  invalid?: boolean;
}

const StyledCheckbox: FC<Props> = (props: Props) => {
  const { children, className = '', invalid = false, ...rest } = props;
  return (
    <Checkbox
      className={`${styles.wrapper} ${
        invalid ? styles.invalid : ''
      } ${className}`}
      {...rest}
    >
      {children}
    </Checkbox>
  );
};

export default StyledCheckbox;
