import useBenguide from 'hooks/benguide';
import MissingInfo from 'modules/benefitsWellness/components/MissingInfo/MissingInfo';

import styles from './missingBenefitValue.module.less';

const MissingBenefitValue = () => {
  const { isEditMode } = useBenguide();
  return (
    <div className={styles.wrapper}>
      {isEditMode ? (
        <MissingInfo missingInfoText="Missing Info" />
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

export default MissingBenefitValue;
