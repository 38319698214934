import { createSlice } from '@reduxjs/toolkit';

import { Dispatch } from 'redux';
import set from 'lodash/set';
import * as AuthService from 'modules/auth/services/AuthService';
import { initBenGuide } from 'modules/auth/slices/benguideSlice';

interface AuthState {
  inProgress: boolean;
  benguideError: any;
  showErrorMessage: boolean;
}

const initialState = {
  inProgress: false,
  benguideError: { authFailed: false, notPublishedBenguide: false },
  showErrorMessage: false,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticateInprogress: (state) => {
      state.inProgress = true;
      state.showErrorMessage = false;
    },
    authenticateCompleted: (state) => {
      state.benguideError = null;
      state.inProgress = false;
    },
    authenticateFailed: (state, action: any) => {
      const { payload = {} } = action;
      const { response = {} } = payload;
      const { status, data } = response;
      const { code = '', error_description: errorDescription } = data || {};
      state.benguideError =
        status === 401 || status === 403
          ? { authFailed: true }
          : { authFailed: true, notFound: true };
      set(
        state,
        'benguideError.notFound',
        status === 404 ||
          errorDescription === 'benguide.deleted' ||
          code === 'archived/deleted benefit guide'
      );
      set(
        state,
        'benguideError.notPublishedBenguide',
        code === 'not a published benefit guide'
      );
      set(
        state,
        'benguideError.invalidated',
        code === 'benefit guide invalidated'
      );
      state.inProgress = false;
    },
    setAuthShowErrorMessage: (state, { payload }) => {
      state.showErrorMessage = payload;
    },
  },
});

export const {
  authenticateInprogress,
  authenticateFailed,
  authenticateCompleted,
  setAuthShowErrorMessage,
} = authSlice.actions;
export default authSlice.reducer;

export function authenticate(benguideHash: string, authParams: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(authenticateInprogress());
      await AuthService.authenticateBenguide(benguideHash, authParams);
      dispatch(initBenguideDataByHash(benguideHash, false));
      dispatch(authenticateCompleted());
    } catch (error) {
      dispatch(authenticateFailed(error));
    }
  };
}

export function initBenguideDataByHash(hash: string, isEditMode: boolean): any {
  return async (dispatch: Dispatch) => {
    dispatch(authenticateInprogress());
    try {
      const { data } = await AuthService.attemptPublicAuthentication(
        hash,
        AuthService.getBenguideByHash,
        hash,
        isEditMode
      );
      dispatch(authenticateCompleted());
      dispatch(initBenGuide(data));
    } catch (error: any) {
      dispatch(authenticateFailed(error));
    }
  };
}

export function initBenguideDataByToken(previewToken: string, hash: string) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } =
        await AuthService.authenticateAndGetBenguideByPreviewToken(
          previewToken,
          hash
        );
      dispatch(authenticateCompleted());
      dispatch(initBenGuide(data));
    } catch (error) {
      dispatch(authenticateFailed(error));
    }
  };
}
