import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  LoadingOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { NotificationContext } from 'context/NotificationContext';
import useBenguide from 'hooks/benguide';

import TooltipPopover from 'components/TooltipPopover/TooltipPopover';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import StyledCheckbox from 'components/StyledCheckbox/StyledCheckbox';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  DENTAL_PLAN_CHANNEL,
  MEDICAL_PLAN_CHANNEL,
  OPEN_ADD_NEW_PLAN_MODAL,
  VISION_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import { addPlansTooltipContent } from 'modules/benefitsWellness/constants/BenefitConstants';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';

import MDVPlan from 'model/MDVPlan';
import Plan from 'model/Plan';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/commonConstants';
import { usePrevious } from 'hooks/usePrevious';

import styles from './AddOrRemovePlans.module.less';

const MDVChannels = [
  MEDICAL_PLAN_CHANNEL,
  DENTAL_PLAN_CHANNEL,
  VISION_PLAN_CHANNEL,
];

type AddOrRemovePlansProps = {
  onClose?: Function;
  visible: boolean;
  title: string;
  withoutModal?: boolean;
  onSave: Function;
  addedPlanList: (MDVPlan | Plan)[] | [];
  allPlans: (MDVPlan | Plan)[];
  reloadPlans: Function;
  channel?: string;
  actionContent?: ReactNode;
  isLoading?: boolean;
  showAlert: Function;
};

type PlanItem = {
  plan: MDVPlan | Plan;
  status: 'ADDED' | 'REMOVED';
  isInInitialList: boolean;
};

const AddOrRemovePlans: FC<AddOrRemovePlansProps> = (
  props: AddOrRemovePlansProps
) => {
  const {
    onClose,
    visible,
    title,
    withoutModal,
    onSave,
    addedPlanList,
    allPlans,
    reloadPlans,
    channel = MEDICAL_PLAN_CHANNEL,
    actionContent,
    isLoading,
    showAlert,
  } = props;

  const benguide = useBenguide();
  const { benefitClasses, planYearId } = benguide;

  const [selectedPlans, setSelectedPlans] =
    useState<(MDVPlan | Plan)[]>(addedPlanList);
  const [selectedPlanItems, setSelectedPlanItems] = useState<PlanItem[]>([]);
  const [isNotificationReceived, setNotificationReceived] =
    useState<boolean>(false);
  const [isPlanCreatingMode, setPlanCreatingMode] = useState<boolean>(false);
  const [isPlanAdded, setPlanAdded] = useState<boolean>(false);
  const notificationFlag = useContext(NotificationContext);

  const prevAllPlans = usePrevious(allPlans);

  const setNotificationReceiveFlag = useCallback(() => {
    if (notificationFlag) {
      setNotificationReceived(true);
    }
  }, [notificationFlag]);

  useEffect(() => {
    setSelectedPlans(addedPlanList);
    const data = [...addedPlanList].map((plan: MDVPlan | Plan) => {
      return {
        plan: plan,
        status: 'ADDED',
        isInInitialList: true,
      } as PlanItem;
    });
    setSelectedPlanItems(data);
    setNotificationReceiveFlag();
  }, [visible, addedPlanList, setNotificationReceiveFlag]);

  useEffect(() => {
    if (visible) {
      reloadPlans();
    }
  }, [reloadPlans, visible]);

  const addPlan = useCallback(
    (plan: MDVPlan | Plan) => {
      const updated = [...selectedPlanItems].map((planItem: PlanItem) => {
        if (
          planItem.plan.id === plan.id &&
          planItem.isInInitialList &&
          planItem.status === 'REMOVED'
        ) {
          return {
            plan: plan,
            status: 'ADDED',
            isInInitialList: true,
          } as PlanItem;
        } else if (
          planItem.plan.id === plan.id &&
          !planItem.isInInitialList &&
          planItem.status === 'REMOVED'
        ) {
          return {
            plan: plan,
            status: 'ADDED',
            isInInitialList: false,
          } as PlanItem;
        } else {
          return planItem;
        }
      });
      const filteredByPlanId = updated.filter((t) => t.plan.id === plan.id);
      if (filteredByPlanId.length === 0) {
        updated.push({
          plan: plan,
          status: 'ADDED',
          isInInitialList: false,
        } as PlanItem);
      }
      setSelectedPlanItems(updated);
      const updatedPlans = [plan, ...selectedPlans];
      setSelectedPlans(updatedPlans);
    },
    [selectedPlanItems, selectedPlans]
  );

  const removePlan = (planId: string) => {
    const updated = [...selectedPlanItems].map((planItem: PlanItem) => {
      if (planItem.plan.id === planId) {
        return {
          plan: planItem.plan,
          status: 'REMOVED',
          isInInitialList: planItem.isInInitialList,
        } as PlanItem;
      } else {
        return planItem;
      }
    });
    setSelectedPlanItems(updated);
    const updatedPlans = selectedPlans.filter((plan) => plan.id !== planId);
    setSelectedPlans(updatedPlans);
  };

  const saveSelectedPlans = useCallback(() => {
    let count = 1;
    const sortedNewList = selectedPlanItems
      .filter((item) => item.status === 'ADDED' && !item.isInInitialList)
      .sort((a, b) => a.plan.name.localeCompare(b.plan.name))
      .map((item) => item.plan);
    const existingList = selectedPlanItems
      .filter((item) => item.status === 'ADDED' && item.isInInitialList)
      .map((item) => item.plan);
    const completePlans = [...sortedNewList, ...existingList].map(
      (plan: MDVPlan | Plan) => {
        return { ...plan, orderNo: count++ } as Plan;
      }
    );
    const updatedplans = existingList?.length !== selectedPlanItems?.length;
    onSave(completePlans, false, updatedplans);
    onClose && onClose();
    setPlanAdded(false);
  }, [onClose, onSave, selectedPlanItems]);

  useEffect(() => {
    if (isPlanCreatingMode && allPlans.length > prevAllPlans.length) {
      const newPlan = allPlans.find(
        (plan) =>
          !prevAllPlans.map((prePlan: MDVPlan) => prePlan.id).includes(plan.id)
      );
      if (newPlan) {
        addPlan(newPlan);
        setPlanAdded(true);
      }
    }
  }, [allPlans, prevAllPlans, isPlanCreatingMode, addPlan]);

  useEffect(() => {
    if (isPlanCreatingMode && isPlanAdded) {
      showAlert();
      saveSelectedPlans();
      setPlanCreatingMode(false);
    }
  }, [isPlanCreatingMode, isPlanAdded, saveSelectedPlans, showAlert]);

  const isPlanAlreadyAdded = (planId: string) => {
    return (
      selectedPlans.find((plan: MDVPlan | Plan) => plan.id === planId) !==
      undefined
    );
  };

  const isNotificationAlertVisible = () => {
    return isNotificationReceived && !isEmpty(addedPlanList);
  };

  let dbgPlans: (MDVPlan | Plan)[] = [];
  dbgPlans = dbgPlans.concat(allPlans);
  addedPlanList.forEach((plan) => {
    if (plan.planYearId !== planYearId) {
      dbgPlans.push(plan);
    } else if (
      benefitClasses &&
      !plan.groups.some((value) => benefitClasses.includes(value))
    ) {
      dbgPlans.push(plan);
    }
  });

  const hasSelectedPlans = selectedPlanItems.some(
    (item) => item.status === 'ADDED'
  );

  const dbgPlanIds = dbgPlans?.map((plan) => plan.id) || [];

  const allPlansSorted =
    dbgPlans &&
    [
      ...dbgPlans,
      ...addedPlanList.filter((plan) => !dbgPlanIds.includes(plan.id)),
    ].sort((a, b) => a?.name?.localeCompare(b?.name));

  const hasPlan = allPlansSorted && allPlansSorted.length !== 0;

  const upClient = useUPClient();

  const modalBody = (
    <div
      className={`${styles.addOrRemovePlansWrapper} ${
        !withoutModal && styles.planModalWrapper
      }`}
    >
      <div className={styles.header}>
        <div>
          <PlusCircleOutlined className={styles.addIcon} />
        </div>
        <div>
          <div className={styles.title}>{`${title} Plans`}</div>
          <div className={styles.noPlansHeader}>
            {hasPlan || isLoading ? (
              <>Here are the available plans for this benefits guide.</>
            ) : (
              <>You have no {title} plans available for this benefits guide.</>
            )}
            <TooltipPopover
              content={addPlansTooltipContent}
              placement="top"
              className="addPlansOverlay"
            >
              <QuestionCircleOutlined className={styles.infoIcon} />
            </TooltipPopover>
          </div>
        </div>
      </div>
      {isNotificationAlertVisible() && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      {isLoading ? (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      ) : (
        <>
          {hasPlan && (
            <div className={styles.selectPlanText}>
              Select plans below to include them in this guide:
            </div>
          )}
          <div
            className={
              !withoutModal && !hasPlan ? styles.planNameWrapperNoPlan : ''
            }
          >
            {hasPlan && (
              <div
                className={`${styles.planNameWrapper} ${
                  isNotificationAlertVisible()
                    ? styles.heightWithNotificationAlert
                    : ''
                }`}
              >
                <div className={styles.planNames}>
                  {allPlansSorted.map((plan, index) => {
                    const { name, id } = plan;
                    return (
                      <StyledCheckbox
                        key={index}
                        value={id}
                        checked={isPlanAlreadyAdded(id)}
                        onChange={(event) => {
                          const { target } = event;
                          const { checked } = target;
                          if (checked) {
                            addPlan(plan);
                          } else {
                            removePlan(id);
                          }
                        }}
                      >
                        <div
                          className={`${styles.planName} prevent-trigger-hotspots`}
                          title={name}
                        >
                          {name}
                        </div>
                      </StyledCheckbox>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className={styles.buttons}>
            <div
              data-id="create-new-plan"
              className={styles.createNewPlanWrapper}
              onClick={() => {
                setPlanCreatingMode(true);
              }}
            >
              {actionContent && channel && !MDVChannels.includes(channel) ? (
                actionContent
              ) : (
                <EditableHotspot
                  alwaysVisible={false}
                  dataTestId="plan-create-new-plan"
                  useChannel={{
                    client: upClient,
                    channel: channel,
                    event: OPEN_ADD_NEW_PLAN_MODAL,
                    data: {},
                  }}
                >
                  <Button
                    disabled={notificationFlag}
                    className={!hasPlan ? styles.darkButton : ''}
                  >
                    Create New Plan
                  </Button>
                </EditableHotspot>
              )}
            </div>
            {onClose && (
              <Button
                onClick={() => {
                  setPlanAdded(false);
                  setPlanCreatingMode(false);
                  onClose?.();
                }}
              >
                Cancel
              </Button>
            )}
            {hasPlan && (
              <Button
                className={styles.addSelectedBtn}
                disabled={
                  notificationFlag ||
                  (isEmpty(addedPlanList) && !hasSelectedPlans)
                }
                loading={isLoading}
                onClick={saveSelectedPlans}
              >
                Save Plans
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );

  if (withoutModal) {
    return <div className={styles.widthoutModal}>{modalBody}</div>;
  }

  return (
    <HalfScreenModal
      visible={visible}
      onCancel={() => onClose && onClose()}
      footer={null}
      closeIcon={false}
      className={styles.addOrRemovePlansModal}
      destroyOnClose={true}
    >
      {modalBody}
    </HalfScreenModal>
  );
};

export default AddOrRemovePlans;
