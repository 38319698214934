import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';
const benguideServiceUrl = `${baseApi}/v2/benefit-guides`;
const benguideByHashUrl = `${benguideServiceUrl}/?url-hash=`;
const benguidePublicHashUrl = `${benguideServiceUrl}/public?url-hash=`;
const authBenguideUrl = `${baseApi}/oauth/token`;

export const EXPIRE_TIME = 'expire_time'; // expire key for local storage.
export const LOGGED_IN_USER_EMAIL = 'logged_user'; // expire key for local storage.

const configOptions = {
  headers: {
    'X-Web-Auth-Scope': 'DIGITAL_BENEFIT_GUIDE',
    'Content-Type': 'multipart/form-data',
  },
};

const buildAuthFormData = (benguideHash: string, authParams: any) => {
  const bodyFormData = new FormData();
  bodyFormData.append('grant_type', 'ben-guide');
  bodyFormData.append('scope', 'DIGITAL_BENEFIT_GUIDE');
  bodyFormData.append('hash', benguideHash);
  bodyFormData.append('mode', authParams.mode);

  if (authParams.mode === 'PREVIEW')
    bodyFormData.append('token', authParams.token);
  if (authParams.mode === 'PUBLISHED')
    bodyFormData.append('password', authParams.password);

  return bodyFormData;
};

export const getBenguideByHash = async (
  benguideHash: string,
  isEditMode: boolean
) => {
  try {
    if (isEditMode) {
      const response = getLastestEditedVersionForPublished(benguideHash);
      return Promise.resolve(response);
    } else {
      const response = await http.get(
        `${
          isEditMode ? benguideByHashUrl : benguidePublicHashUrl
        }${benguideHash}`
      );
      return Promise.resolve(response);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getLastestEditedVersionForPublished = async (
  benguideHash: string
) => {
  try {
    const publishResponse = await http.get(
      `${benguideServiceUrl}/edit-mode?url-hash=${benguideHash}`
    );

    return Promise.resolve(publishResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authenticateBenguide = async (
  benguideHash: string,
  authParams: any
) => {
  try {
    const response = await http.post(
      authBenguideUrl,
      buildAuthFormData(benguideHash, authParams),
      configOptions
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const attemptPublicAuthentication = async (
  hash: string,
  wrappedService: Function,
  params: any,
  isEditMode: boolean
) => {
  try {
    const response = await wrappedService(params, isEditMode);
    return Promise.resolve(response);
  } catch (errorResponse: any) {
    if (
      errorResponse.response &&
      (errorResponse.response.status === 403 ||
        errorResponse.response.status === 401)
    ) {
      try {
        await authenticateBenguide(hash, { mode: 'PUBLISHED' });
        const response = await wrappedService(params);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(errorResponse);
  }
};

export const authenticateAndGetBenguideByPreviewToken = async (
  previewToken: string,
  hash: string
) => {
  try {
    const response = await http.get(
      `${benguideServiceUrl}?previewToken=${previewToken}`
    );
    return Promise.resolve(response);
  } catch (errorResponse: any) {
    if (
      errorResponse.response &&
      (errorResponse.response.status === 403 ||
        errorResponse.response.status === 401)
    ) {
      await authenticateBenguide(hash, {
        mode: 'PREVIEW',
        token: previewToken,
      });
      try {
        const response = await http.get(
          `${benguideServiceUrl}?previewToken=${previewToken}`
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(errorResponse);
    }
  }
};

export const getBenguideById = async (id: string) => {
  return http.get(`${benguideServiceUrl}/${id}`);
};
