import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from 'app/store';
import { AssistantChatResponseVO } from 'model/AiAssistant/chatResponse';
import { PromptType, ScreenSelectionType } from 'model/AiAssistant/common';
import * as AiAssistantServices from 'modules/aiAssistantChat/services/AiAssistantServices';

interface IMessage {
  content?: string;
  isQuestion: boolean;
  isAnswer: boolean;
  animate: boolean;
  isPending: boolean;
  isError: boolean;
  error: string | null;
}

interface AiAssistantChatState {
  isOpenPlanModal: boolean;
  promptType: PromptType | null;
  chatId: string | null;
  selectedScreen: ScreenSelectionType;
  selectedPlan: { planId: string; planName: string; category: string } | null;
  messages: IMessage[];
  error: string | null;
  validPlan: {
    isPending: boolean;
    isError: boolean;
    error: string | null;
    data: any;
  };
}

const initialState: AiAssistantChatState = {
  isOpenPlanModal: false,
  promptType: null,
  chatId: null,
  selectedScreen: ScreenSelectionType.SELECTION,
  selectedPlan: null,
  messages: [],
  error: null,
  validPlan: {
    isPending: false,
    isError: false,
    error: null,
    data: null,
  },
};
const aiAssistantChatSlice = createSlice({
  name: 'aiAssistantChat',
  initialState,
  reducers: {
    setIsPlanModalOpen: (state, action) => {
      state.isOpenPlanModal = action.payload;
    },
    setPromptType: (state, action) => {
      state.promptType = action.payload;
      state.selectedScreen = ScreenSelectionType.NEW_CHAT_INITIALIZE;
    },
    setToNewChat: (state) => {
      state.selectedScreen = ScreenSelectionType.SELECTION;
      state.promptType = null;
      state.chatId = null;
      state.selectedPlan = null;
      state.messages = [];
      state.error = null;
    },
    setInitMessage: (state, action) => {
      state.messages = [...action.payload];
      state.selectedScreen = ScreenSelectionType.CHAT_HISTORY;
    },
    setUpdateLastMessage: (state, action) => {
      state.messages[state.messages.length - 1] = action.payload;
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setNextMessage: (state, action) => {
      state.messages = [...state.messages, ...action.payload];
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setValidPlanPending: (state) => {
      state.validPlan = {
        isPending: true,
        isError: false,
        error: null,
        data: null,
      };
    },
    setValidPlanSuccess: (state, action) => {
      state.validPlan = {
        isPending: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    },
    setValidPlanError: (state, action) => {
      state.validPlan = {
        isPending: false,
        isError: true,
        error: action.payload,
        data: null,
      };
    },
    setValidPlanClear: (state) => {
      state.validPlan = {
        isPending: false,
        isError: false,
        error: null,
        data: null,
      };
    },
  },
});

export const {
  setIsPlanModalOpen,
  setPromptType,
  setToNewChat,
  setInitMessage,
  setUpdateLastMessage,
  setChatId,
  setSelectedPlan,
  setNextMessage,
  setError,
  setValidPlanPending,
  setValidPlanSuccess,
  setValidPlanError,
  setValidPlanClear,
} = aiAssistantChatSlice.actions;
export default aiAssistantChatSlice.reducer;

export const chatInitialize =
  ({ text }: { text: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const {
        aiAssistantChat: { promptType, selectedPlan },
      } = getState().persisted;
      const benGuideData = getState().benguide;

      dispatch(
        setInitMessage([
          {
            content: text,
            isQuestion: true,
            isAnswer: false,
            animate: false,
            isPending: false,
            error: null,
          },
          {
            content: '',
            isQuestion: false,
            isAnswer: true,
            animate: false,
            isPending: true,
            error: null,
          },
        ])
      );
      const response = await AiAssistantServices.getInitialChatAnswer({
        promptType: promptType!,
        question: text,
        planId: selectedPlan?.planId,
        benGuideId: benGuideData?.data?.masterId,
        category: selectedPlan?.category,
      });

      const data: AssistantChatResponseVO = response.data;

      if (data) {
        const lastChatMessage = data.messages[data.messages.length - 1];
        const { content, error } = lastChatMessage.content;
        const isError = !isEmpty(error) || isEmpty(content);
        dispatch(setChatId(isError ? null : data.conversationId));
        dispatch(
          setUpdateLastMessage({
            content,
            isQuestion: false,
            isAnswer: true,
            animate: !isError,
            isPending: false,
            isError,
            error,
          })
        );
        if (isError) {
          dispatch(setError('Error initializing conversation:'));
        }
      } else {
        dispatch(setError('Error initializing conversation:'));
      }
    } catch (error: any) {
      dispatch(setError('Error initializing conversation:'));
    }
  };

export const setUpdateLastMessageAnimate =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const aiAssistantState = getState().persisted.aiAssistantChat;
    const lastMessage =
      aiAssistantState.messages[aiAssistantState.messages.length - 1];
    dispatch(
      setUpdateLastMessage({
        ...lastMessage,
        animate: false,
      })
    );
  };

export const appendChatQuestion =
  ({
    text,
    chatId,
    wsClient,
  }: {
    text: string;
    chatId: string;
    wsClient: any;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(
        setNextMessage([
          {
            content: text,
            isQuestion: true,
            isAnswer: false,
            animate: false,
            isPending: false,
            error: null,
          },
          {
            content: '',
            isQuestion: false,
            isAnswer: true,
            animate: false,
            isPending: true,
            error: null,
          },
        ])
      );

      if (!wsClient) {
        console.error("Stomp client undefined. Can't send message.");
        return;
      }

      wsClient.publish({
        destination: AiAssistantServices.publishingURL(chatId),
        body: JSON.stringify({
          conversationId: chatId,
          message: text,
        }),
      });
    } catch (error) {
      console.error('Error while sending message:', error);
    }
  };

export const appendChatAnswer =
  ({ content, error }: { content: string; error: string }) =>
  (dispatch: Dispatch) => {
    const isError = !isEmpty(error) || isEmpty(content);
    dispatch(
      setUpdateLastMessage({
        content,
        isQuestion: false,
        isAnswer: true,
        animate: !isError,
        isPending: false,
        isError,
        error,
      })
    );
    if (isError) {
      dispatch(setError('Error initializing conversation:'));
    }
  };

export const setValidPlan =
  ({
    planId,
    planName,
    category,
    callback,
  }: {
    planId: string;
    planName: string;
    category: string;
    callback: () => void;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setValidPlanPending());

      const response = await AiAssistantServices.getValidatePlanDocument({
        promptType: PromptType.DBG_PLAN,
        planId,
        category,
      });
      dispatch(setValidPlanSuccess(response.data));
      dispatch(setSelectedPlan({ planId, planName, category }));
      dispatch(setPromptType(PromptType.DBG_PLAN));
      callback();
    } catch (error: any) {
      dispatch(setValidPlanError(error.response.data.code));
    }
  };
