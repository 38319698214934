import React, { memo } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import ChatBubble from 'modules/aiAssistantChat/components/ChatBubble/ChatBubble';
import {
  appendChatAnswer,
  setUpdateLastMessageAnimate,
} from 'modules/aiAssistantChat/slices/aiAssistantChatSlice';
import { receivingURL } from 'modules/aiAssistantChat/services/AiAssistantServices';
import { ASSISTANT_COMMON_ERROR } from 'modules/aiAssistantChat/constants/constants';

import styles from './aiAssistantChatBody.module.less';

type Props = {
  scrollIntoView: () => void;
};
const AiAssistantChatBody = ({ scrollIntoView }: Props) => {
  const dispatch = useAppDispatch();
  const aiAssistantState = useAppSelector(
    (state) => state.persisted.aiAssistantChat
  );
  const { messages, chatId } = aiAssistantState;

  const lastMessage = messages[messages.length - 1];

  useSubscription(receivingURL(chatId!), (message) => {
    const response = JSON.parse(message.body);
    dispatch(
      appendChatAnswer({
        content: response?.message,
        error: response?.error,
      })
    );
  });

  const handleOnFinishTyping = () => {
    dispatch(setUpdateLastMessageAnimate());
  };
  return (
    <>
      {messages?.map((message, index) => {
        return message.isError ? null : (
          <ChatBubble
            key={index}
            isQuestion={message.isQuestion}
            isAnswer={message?.isAnswer}
            animate={message?.animate}
            content={message?.content || ''}
            isPending={message?.isPending}
            onFinishTyping={handleOnFinishTyping}
            scrollIntoView={scrollIntoView}
          />
        );
      })}
      {lastMessage?.isError && (
        <div className={styles.errorWrapper}>
          <FixedAlertMessage type="error" message={ASSISTANT_COMMON_ERROR} />
        </div>
      )}
    </>
  );
};

export default memo(AiAssistantChatBody);
