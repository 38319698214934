import { Fragment, useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useParams } from 'react-router';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import {
  requestSignUp,
  requestSignUp as getTokenFromEmailAndDomain,
  setEmployeeModal,
  setAuthView,
  validateToken,
  verifyEmail,
  signUp,
  login,
  setRegisterValues,
  setRosterAndPersonal,
} from 'modules/auth/slices/employeeAuthSlice';

import styles from './register.module.less';

const Register = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { hash = '', employerName = '' } = useParams();
  const baseUrl = `/${hash}/`;
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const registerModalType = useAppSelector((state) => state.employeeAuth.view);
  const inProgress = useAppSelector((state) => state.employeeAuth.inProgress);
  const isSelfDeclared = useAppSelector(
    (state) => state.employeeAuth.isSelfDeclared
  );
  const isErAdmin = useAppSelector((state) => state.employeeAuth.isErAdmin);
  const userEmail = useAppSelector((state) => state.employeeAuth.user);
  const token = useAppSelector((state) => state.employeeAuth.token);

  const handleCancelAndBack = () => {
    if (registerModalType === AUTH_VIEW.CODE.type) {
      dispatch(setAuthView(AUTH_VIEW.EMAIL.type));
    } else {
      dispatch(setEmployeeModal(false));
    }
  };

  const sendEmail = async (values: any) => {
    const response = await dispatch(verifyEmail(values?.email));
    if (!response?.data?.personalEmail) {
      const constructRedirectURL = `${window.location.origin}${baseUrl}${employerName}`;
      dispatch(
        requestSignUp(
          values?.email?.trim(),
          constructRedirectURL,
          values?.domain
        )
      );
    }
  };

  const verifyCode = (values: any) => {
    dispatch(validateToken(values?.code));
  };

  const handlePersonal = async (values: any) => {
    const constructRedirectURL = `${window.location.origin}${baseUrl}${employerName}`;
    const response = await dispatch(
      getTokenFromEmailAndDomain(
        values?.email?.trim(),
        constructRedirectURL,
        values?.domain
      )
    );
    // ROSTER AND PERSONAL has the same fields and login fields this
    // is why we redirect to ROSTER form component
    if (response?.status === 201) {
      dispatch(setAuthView(AUTH_VIEW.VERIFY.type));
    }
  };

  const handleSignUp = async (values: any) => {
    const constructRedirectURL = `benguideV2${baseUrl}${employerName}`;
    if (token) {
      const response = await dispatch(
        signUp(
          values?.email?.trim(),
          token!,
          values?.confirmPassword?.trim(),
          values?.firstName,
          values?.lastName,
          isSelfDeclared
        )
      );
      if (response?.status === 201) {
        dispatch(
          login(
            values?.email?.trim(),
            values?.confirmPassword?.trim(),
            constructRedirectURL,
            benguideData?.planYearId
          )
        );
      }
    }
  };

  const registerHandler = (values: any) => {
    switch (registerModalType) {
      case AUTH_VIEW.EMAIL.type:
        sendEmail(values);
        break;
      case AUTH_VIEW.CODE.type:
        verifyCode(values);
        break;
      case AUTH_VIEW.PERSONAL.type:
        handlePersonal(values);
        break;
      case AUTH_VIEW.ROSTER.type:
        dispatch(setRegisterValues(values));
        dispatch(setRosterAndPersonal(true));
        dispatch(setAuthView(AUTH_VIEW.TERMS.type));
        break;
      case AUTH_VIEW.REGISTER.type:
        dispatch(setRegisterValues(values));
        dispatch(setAuthView(AUTH_VIEW.TERMS.type));
        break;
      default:
        handleSignUp(values);
        break;
    }
  };

  const renderIfRosterOrRegister = () => {
    if (
      registerModalType === AUTH_VIEW.ROSTER.type ||
      registerModalType === AUTH_VIEW.REGISTER.type
    ) {
      return (
        <>
          <Form.Item
            name="password"
            className={styles.formInput}
            label={<>Create Password</>}
            rules={[
              {
                pattern: new RegExp(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/
                ),
                message: `Please choose a different password with 8
                or more characters, upper & lower case
                letters, and a special character.`,
              },
              { required: true, message: 'Please enter your password' },
            ]}
          >
            <Input
              className={styles.inputWrapper}
              name="password"
              type="password"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            className={styles.formInput}
            label={<>Confirm Password</>}
            rules={[
              {
                required: true,
                message: 'Please enter confirmation password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                },
              }),
            ]}
          >
            <Input
              className={styles.inputWrapper}
              name="confirmPassword"
              type="password"
              autoComplete="off"
            />
          </Form.Item>
        </>
      );
    }
  };

  useEffect(() => {
    form.setFieldsValue({ email: userEmail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  useEffect(() => {
    return () => {
      form?.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row justify="center" className={styles.wrapperContainer}>
      <InputForm
        form={form}
        isLoading={inProgress}
        layout="vertical"
        onFinish={registerHandler}
      >
        {registerModalType === AUTH_VIEW.EMAIL.type && (
          <p className={styles.disabledText}>
            IMPORTANT: Register with your primary email address
          </p>
        )}
        {registerModalType !== AUTH_VIEW.CODE.type && (
          <Form.Item
            name="email"
            label={<>Work Email Address</>}
            className={styles.formInput}
            help={
              isErAdmin && userEmail
                ? 'You are registering as an employer admin. You will be able to login to unified portal with these same credentials'
                : ''
            }
            rules={[
              {
                type: 'email',
                message: 'Invalid email format',
              },
              {
                required: true,
                message: 'Please enter your work email',
              },
            ]}
          >
            <Input
              disabled={!!userEmail}
              className={styles.inputWrapper}
              name="email"
              autoFocus
            />
          </Form.Item>
        )}
        {registerModalType === AUTH_VIEW.PERSONAL.type && (
          <Form.Item
            name="domain"
            label={<>Employer’s Website</>}
            rules={[
              { required: true, message: 'Please enter a employers domain' },
              {
                pattern: new RegExp(/^[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/),
                message:
                  'Please include a valid website extension (Example: .com, .net, .org)',
              },
            ]}
          >
            <Row>
              <Col flex="center" span={6} className={styles.columnContainer}>
                <span className={styles.disabledText}>https://www.</span>
              </Col>
              <Col span={18}>
                <Input className={styles.inputWrapper} name="domain" />
              </Col>
            </Row>
          </Form.Item>
        )}
        {registerModalType === AUTH_VIEW.CODE.type && (
          <div className={styles.codeWrapper}>
            <h1 className={styles.textWrapper}>
              Paste your code from the PlanYear registration email below
            </h1>
            <Form.Item
              name="code"
              label={<>Code</>}
              rules={[
                { required: true, message: 'Please enter a verification code' },
              ]}
            >
              <Input className={styles.inputWrapper} name="code" />
            </Form.Item>
          </div>
        )}
        {registerModalType === AUTH_VIEW.REGISTER.type && (
          <Fragment>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label={<>First Name</>}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input className={styles.inputWrapper} name="firstName" />
                </Form.Item>
              </Col>{' '}
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label={<>Last Name</>}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input className={styles.inputWrapper} name="lastName" />
                </Form.Item>
              </Col>
            </Row>
          </Fragment>
        )}
        {renderIfRosterOrRegister()}
        <SubmitButton
          type="primary"
          htmlType="submit"
          disabled={inProgress}
          loading={inProgress}
          className={styles.submitPwdButton}
        >
          {registerModalType === AUTH_VIEW.REGISTER.type ||
          registerModalType === AUTH_VIEW.ROSTER.type
            ? 'Register Account'
            : 'Next'}
        </SubmitButton>
        <Row justify="center">
          <LinkButton
            className={styles.resetPwdButton}
            onClick={() => handleCancelAndBack()}
          >
            {registerModalType === AUTH_VIEW.CODE.type ? 'Back' : 'Cancel'}
          </LinkButton>
        </Row>
      </InputForm>
    </Row>
  );
};

export default Register;
