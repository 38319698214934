import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Layout } from 'antd';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import BenefitGuideBasicInfo, {
  buildBenefitGuideBasicInfo,
} from 'model/BenefitGuideBasicInfo ';

import { NavigationContext } from 'context/NavigationContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  fetchPlanYearById,
  fetchCreatedUserByLoginId,
  fetchLastUpdatedUserByLoginId,
} from 'modules/auth/slices/benguideSlice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { BenGuideStatus, exitDBGMessages } from 'constants/commonConstants';
import ToolBarDropdown from 'layout/ToolbarDropdown/ToolbarDropdown';
import StatusIndicator from 'layout/StatusIndicator/StatusIndicator';
import { ReactComponent as LeftArrow } from 'images/left-arrow-outline.svg';
import AppHeaderButton from 'components/AppHeaderButton/AppHeaderButton';
import RouteMap from 'routes/RouteMap';
import {
  ToolbarItem,
  toolbarRoutes,
  ToolbarSubmenu,
} from 'routes/ToolbarRoutes';
import { getPathWithHash } from 'utils/routeUtils';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  ADDITIONAL_CONTENT_OPTIONS,
  BENEFIT_TYPES,
} from 'modules/home/constants';
import {
  BENGUIDE_COMMON_CHANNEL,
  CLOSE_DBG_MODAL,
  PREVIEW_BENGUIDE,
  PUBLISH_BENGUIDE,
} from 'modules/clients/UPClient/UPClientConst';
import { buildFullName } from 'utils/stringUtil';

import styles from './appHeader.module.less';

const { Header } = Layout;

const AppHeaderNav: FC = () => {
  const [selectedPathLabel, setSelectedPathLabel] = useState('');
  const [selectedPathIndex, setSelectedPathIndex] = useState(0);
  const [isModalOpen, _setIsModalOpen] = useState(false);
  const [enabledToolbarItems, setEnabledToolbarItems] = useState<ToolbarItem[]>(
    []
  );
  const [enabledRoutes, setEnabledRoutes] = useState<string[]>([]);
  const [hasBasicInfoChanged, setHasBasicInfoChanged] =
    useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { hash = '', employerName = '' } = useParams();
  const dispatch = useAppDispatch();
  const benGuide = useAppSelector((state) => state.benguide.data);
  const planYear = useAppSelector((state) => state.benguide.planYear);
  const basicInfo = useAppSelector((state) => state.basicInfo.basicInfo);

  const setIsModalOpen = (open: boolean) => {
    const { pathname } = location;
    const match =
      matchPath('/:hash/basic-info/edit', pathname) ||
      matchPath('/create', pathname);
    if (open && match === null) {
      handleExitBenguide();
    } else {
      _setIsModalOpen(open);
    }
  };

  useEffect(() => {
    if (benGuide) {
      const enabledBenefits =
        benGuide &&
        Object.keys(BENEFIT_TYPES).filter((key) => {
          return benGuide[key] && benGuide[key].enabled;
        });

      const enabledContent =
        benGuide &&
        Object.keys(ADDITIONAL_CONTENT_OPTIONS).filter((key) => {
          return benGuide[key] && benGuide[key].enabled;
        });
      const previousBasicInfo: BenefitGuideBasicInfo =
        buildBenefitGuideBasicInfo(
          benGuide.name,
          benGuide.planYearId,
          benGuide.benefitClasses,
          benGuide.employerId,
          enabledBenefits,
          enabledContent
        );
      previousBasicInfo.masterId = benGuide.masterId;

      if (!isEmpty(basicInfo) && !isEqual(basicInfo, previousBasicInfo)) {
        setHasBasicInfoChanged(true);
      }

      if (!isEmpty(basicInfo) && isEqual(basicInfo, previousBasicInfo)) {
        setHasBasicInfoChanged(false);
      }
    }
  }, [basicInfo, benGuide, hasBasicInfoChanged]);

  const lastUpdatedUser = useAppSelector(
    (state) => state.benguide.lastUpdatedUser
  );
  const createdByUser = useAppSelector((state) => state.benguide.createdUser);

  const { disabledRoutes } = useContext(NavigationContext);

  useEffect(() => {
    const matchedItem = enabledToolbarItems.find((route) => {
      if (route.children) {
        return route.children.find((childRoute) =>
          matchPath(
            location.pathname,
            getPathWithHash(hash, childRoute.path, employerName)
          )
        );
      }
      return matchPath(
        location.pathname,
        `/${hash}${employerName ? '/' + employerName : ''}/${route.path}`
      );
    });

    if (matchedItem && matchedItem.label) {
      setSelectedPathLabel(matchedItem.label);
    }

    if (matchedItem && matchedItem.children) {
      const selectedChildItem = matchedItem.children.find((childItem) =>
        matchPath(
          location.pathname,
          getPathWithHash(hash, childItem.path, employerName)
        )
      );
      setSelectedPathLabel(
        selectedChildItem ? selectedChildItem.label : matchedItem.label
      );
    }

    if (matchPath(location.pathname, RouteMap.CREATE_BENGUIDE)) {
      setSelectedPathLabel(
        toolbarRoutes.find(
          (route) => route.path === RouteMap.BASIC_INFO_EDIT_PATH
        )?.label || 'Benefit Guide'
      );
    }
  }, [location, hash, enabledToolbarItems, employerName]);

  useEffect(() => {
    const enabledRouteIndex = enabledRoutes.findIndex((route) => {
      const matchedRoute = matchPath(
        location.pathname,
        getPathWithHash(hash, route, employerName)
      );
      return matchedRoute;
    });
    setSelectedPathIndex(enabledRouteIndex);
  }, [location.pathname, hash, enabledRoutes, employerName]);

  useEffect(() => {
    const filteredItems = toolbarRoutes.filter((route) => {
      return !route.children
        ? !disabledRoutes.includes(route.path as RouteMap)
        : !route.children.every((r) =>
            disabledRoutes.includes(r.path as RouteMap)
          );
    });
    setEnabledToolbarItems(filteredItems);

    const filteredRoutes: (RouteMap | ToolbarSubmenu)[] = [];
    toolbarRoutes.forEach((route) => {
      if (route.children) {
        route.children.forEach((childRoute) => {
          !disabledRoutes.includes(childRoute.path as RouteMap) &&
            filteredRoutes.push(childRoute.path);
        });
      } else {
        !disabledRoutes.includes(route.path as RouteMap) &&
          route.path !== ToolbarSubmenu.TAX_ADVANTAGED &&
          filteredRoutes.push(route.path);
      }
    });
    setEnabledRoutes(filteredRoutes);
  }, [disabledRoutes]);

  useEffect(() => {
    if (benGuide?.employerId && benGuide?.planYearId) {
      dispatch(
        fetchPlanYearById({
          employerId: benGuide.employerId,
          planYearId: benGuide.planYearId,
        })
      );
    }
  }, [benGuide?.planYearId, benGuide?.employerId, dispatch]);

  useEffect(() => {
    if (benGuide?.lastUpdatedByLoginId) {
      dispatch(fetchLastUpdatedUserByLoginId(benGuide.lastUpdatedByLoginId));
    }
  }, [benGuide?.lastUpdatedByLoginId, dispatch]);

  useEffect(() => {
    if (benGuide?.initialCreatedByLoginId) {
      dispatch(fetchCreatedUserByLoginId(benGuide.initialCreatedByLoginId));
    }
  }, [benGuide?.initialCreatedByLoginId, dispatch]);

  const handleNextClick = () => {
    navigate(enabledRoutes[selectedPathIndex + 1]);
  };

  const handlePrevClick = () => {
    navigate(enabledRoutes[selectedPathIndex - 1]);
  };

  const handlePreviewClick = () => {
    if (benGuide) {
      upClient.postMessage({
        channel: BENGUIDE_COMMON_CHANNEL,
        event: PREVIEW_BENGUIDE,
        data: { id: benGuide.masterId },
      });
    }
  };

  const handlePublishClick = () => {
    if (benGuide) {
      upClient.postMessage({
        channel: BENGUIDE_COMMON_CHANNEL,
        event: PUBLISH_BENGUIDE,
        data: {
          planYearName: planYear?.name,
          urlHash: benGuide.urlHash,
          isPublished: benGuide?.publishedBenefitGuide,
        },
      });
    }
  };

  const isOnBasicInfoPage = useMemo(() => {
    const basicInfoEditMatch = matchPath(
      location.pathname,
      `/${hash}/${RouteMap.BASIC_INFO_EDIT_PATH}`
    );
    const createBenguideMatch = matchPath(
      location.pathname,
      RouteMap.CREATE_BENGUIDE
    );
    return Boolean(basicInfoEditMatch || createBenguideMatch);
  }, [hash, location.pathname]);

  const isNextButtonDisabled = (): boolean => {
    const createBenguideMatch = matchPath(
      location.pathname,
      RouteMap.CREATE_BENGUIDE
    );
    return Boolean(
      selectedPathIndex >= enabledRoutes.length - 1 || createBenguideMatch
    );
  };

  const handleExitBenguide = () => {
    closeBenguideModal();
  };

  const upClient = useUPClient();

  const closeBenguideModal = () => {
    upClient.postMessage({
      channel: BENGUIDE_COMMON_CHANNEL,
      event: CLOSE_DBG_MODAL,
      data: {
        planYearId: benGuide?.planYearId,
        status: benGuide?.publishedBenefitGuide
          ? BenGuideStatus.PUBLISHED
          : benGuide?.status,
      },
    });
  };

  const handleToolbarItemClick = (route: string) => {
    const subMenuItem = enabledToolbarItems.find((item) => route === item.path);
    if (subMenuItem) {
      if (subMenuItem.children) {
        const firstEnabledChild = subMenuItem.children.find((route) =>
          enabledRoutes.includes(route.path)
        );
        firstEnabledChild && navigate(firstEnabledChild.path);
        return;
      }

      navigate(subMenuItem.path);
    }
  };

  const handleModalBehaviour = () => {
    if (!benGuide) {
      setIsModalOpen(true);
      return;
    }

    if (!benGuide.publishedBenefitGuide) {
      if (hasBasicInfoChanged) {
        setIsModalOpen(true);
      } else {
        handleExitBenguide();
      }
    } else {
      if (hasBasicInfoChanged) {
        setIsModalOpen(true);
      } else {
        handleExitBenguide();
      }
    }
  };

  return (
    <Header
      className={`${styles.appHeader} ${
        isOnBasicInfoPage ? '' : styles.displayBottomBar
      }`}
    >
      <div className={styles.backSection}>
        <AppHeaderButton onClick={handleModalBehaviour}>
          <LeftArrow className={styles.btnIcon} />
          All Benefits Guides
        </AppHeaderButton>
      </div>
      <div className={styles.navigation}>
        <AppHeaderButton
          onClick={handlePrevClick}
          disabled={selectedPathIndex <= 0 || isOnBasicInfoPage}
        >
          <LeftArrow className={styles.btnIcon} />
          Previous
        </AppHeaderButton>
        <ToolBarDropdown
          menuItems={enabledToolbarItems}
          selected={selectedPathLabel}
          disabled={Boolean(
            matchPath(location.pathname, RouteMap.CREATE_BENGUIDE)
          )}
          onMenuItemClick={handleToolbarItemClick}
        />
        <AppHeaderButton
          onClick={handleNextClick}
          disabled={isNextButtonDisabled()}
        >
          Next
          <LeftArrow className={styles.nextIcon} />
        </AppHeaderButton>
        <div className={styles.nextButtonDivider}>
          {benGuide?.status && (
            <StatusIndicator
              isPreviouslyPublished={benGuide?.publishedBenefitGuide}
              className={styles.statusIndicator}
              status={benGuide.status}
              benGuideName={benGuide.name}
              planYear={planYear}
              benefitClasses={benGuide.benefitClasses}
              createdBy={
                createdByUser
                  ? buildFullName(
                      createdByUser?.firstName,
                      createdByUser?.lastName
                    )
                  : ''
              }
              lastUpdatedBy={
                lastUpdatedUser
                  ? buildFullName(
                      lastUpdatedUser?.firstName,
                      lastUpdatedUser?.lastName
                    )
                  : ''
              }
              lastUpdatedTs={benGuide.lastUpdatedTs}
              createdTs={benGuide.initialCreatedTs}
            />
          )}
        </div>
      </div>
      <div className={styles.headerActions}>
        <Button
          className={styles.greyButton}
          onClick={handlePreviewClick}
          disabled={isOnBasicInfoPage}
        >
          Preview
        </Button>
        {!benGuide?.publishedBenefitGuide && (
          <Button
            className={styles.publishButton}
            onClick={handlePublishClick}
            disabled={isOnBasicInfoPage}
          >
            Publish
          </Button>
        )}
      </div>
      <ConfirmationDialog
        flipFooterOrder={true}
        title="Exit Guide?"
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        okText={
          benGuide?.publishedBenefitGuide
            ? exitDBGMessages.PUBLISHED.confirmBtnText
            : exitDBGMessages.DRAFT.confirmBtnText
        }
        cancelText={
          benGuide?.publishedBenefitGuide
            ? exitDBGMessages.PUBLISHED.cancelBtnText
            : exitDBGMessages.DRAFT.cancelBtnText
        }
        onOk={handleExitBenguide}
      >
        {benGuide?.publishedBenefitGuide
          ? exitDBGMessages.PUBLISHED.description
          : benGuide
          ? exitDBGMessages.DRAFT.description
          : exitDBGMessages.DRAFT.descriptionNew}
      </ConfirmationDialog>
    </Header>
  );
};

export default AppHeaderNav;
