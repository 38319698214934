import { baseApi } from 'utils/apiUtil';
import { httpM as httpM } from 'utils/httpUtil';

const employeeSignUpURL = `${baseApi}/v2/mobile/signup`;
const authFlow = `${baseApi}/v1/logins/flow`;
const featureAccessURL = `${baseApi}/v2/mobile/feature-access`;
const oAuthURL = `${baseApi}/oauth/token`;
const resetPasswordURL = `${baseApi}/v2/mobile/password-reset`;
const employeeDetails = `${baseApi}/v1/employees`;
const loginByIndividualId = `${baseApi}/v1/logins/individuals`;

export const EXPIRE_TIME = 'expire_time'; // expire key for local storage.
export const LOGGED_IN_USER_EMAIL = 'logged_user'; // expire key for local storage.

export const getFeatureAccess = async (email: string) => {
  try {
    const response = await httpM.post(featureAccessURL, { email: email });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const requestPasswordReset = async (
  email: string,
  redirectUrl: string
) => {
  try {
    const response = await httpM.post(`${resetPasswordURL}/token/request`, {
      email: email,
      isDBGv2: true,
      redirectUrl: redirectUrl,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const passwordResetToken = async (
  token: string,
  newPassword: string
) => {
  try {
    const response = await httpM.post(`${resetPasswordURL}`, {
      token: token,
      newPassword: newPassword,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validatePasswordResetToken = async (token: string) => {
  try {
    const response = await httpM.post(`${resetPasswordURL}/token/validate`, {
      token: token,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAuthFlow = async (email: string, benguideUrl: string) => {
  try {
    const response = await httpM.post(authFlow, {
      username: email,
      benguideUrl: benguideUrl,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const whoamiInduvidual = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return await httpM.get(`${baseApi}/v1/individuals/whoami`, config);
};

export const pwdAuthLogin = async (email: string, password: string) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      transformRequest: (jsonData: Record<string, any>) =>
        transformRequest(jsonData),
    };
    const response = await httpM.post(
      oAuthURL,
      {
        username: email,
        password: password,
        grant_type: 'password',
        scope: 'USER',
      },
      config
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validateTokenAndGetUserRole = async (token: string) => {
  try {
    const response = await httpM.post(`${employeeSignUpURL}/token/validate`, {
      token: token,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyEmail = async (email: string) => {
  try {
    const response = await httpM.post(
      `${employeeSignUpURL}/personal/email/verify`,
      {
        email: email,
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const requestSignUp = async (
  email: string,
  redirectUrl: string,
  domain?: string | undefined
) => {
  try {
    const response = await httpM.post(
      `${employeeSignUpURL}/employee/token/request`,
      {
        username: email,
        employerDomain: domain,
        isDBGv2: true,
        redirectUrl: redirectUrl,
      }
    );

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const register = async (
  email: string,
  token: string,
  password: string,
  firstName?: string,
  lastName?: string,
  isSelfDeclared?: boolean
) => {
  try {
    const response = await httpM.post(`${employeeSignUpURL}`, {
      token: token,
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
      isSelfDeclared: isSelfDeclared,
    });

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ssoRegister = async (
  email: string,
  token: string,
  firstName?: string,
  lastName?: string,
  isSelfDeclared?: boolean
) => {
  try {
    const response = await httpM.post(
      `${employeeSignUpURL}/employee/sso-register`,
      {
        email: email,
        token: token,
        firstName: firstName,
        lastName: lastName,
        isSelfDeclared: isSelfDeclared,
      }
    );

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const loginByInduvidualId = async (individualId: string) => {
  try {
    const response = await httpM.get(`${loginByIndividualId}/${individualId}`);

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDependentsDetails = async (
  employeeId: string,
  planYearId: string
) => {
  try {
    const response = await httpM.get(
      `${employeeDetails}/${employeeId}/dependents`,
      {
        params: {
          'plan-year-id': planYearId,
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployeeDetails = async (employeeId: string) => {
  try {
    const response = await httpM.get(`${employeeDetails}/${employeeId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

const transformRequest = (jsonData: Record<string, any> = {}): string => {
  return Object.entries(jsonData)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
};
