import { FC, useEffect, useState } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import BenefitCarrier from 'model/BenefitCarrier';
import defaultImage from 'images/default-image.png';

import { SectionName } from 'modules/home/constants';
import { VoluntaryPlanTag } from 'modules/benefitsWellness/components/VoluntaryTag/voluntaryPlanTag';
import styles from './planCardHeader.module.less';

type Props = {
  planName: string;
  carrier?: BenefitCarrier;
  className?: string;
  benefitType?: string;
};

const PlanCardHeader: FC<Props> = (props: Props) => {
  const { planName, carrier, className = '', benefitType } = props;

  const [logoError, setLogoError] = useState(false);
  const previousLogo = usePrevious(carrier?.logoUrl);

  useEffect(() => {
    if (carrier && carrier.logoUrl) {
      if (previousLogo !== carrier.logoUrl) {
        setLogoError(false);
      }
    }
  }, [previousLogo, carrier]);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.carrierLogo}>
        {carrier && (
          <>
            <img
              src={logoError ? defaultImage : carrier.logoUrl}
              alt={`${carrier.name}`}
              onError={() => setLogoError(true)}
            />
            {logoError && carrier?.name}
          </>
        )}
      </div>
      <div className={styles.planName}>{planName}</div>
      {[
        SectionName.VOLUNTARY_ADD,
        SectionName.VOLUNTARY_LIFE,
        SectionName.VOLUNTARY_LIFE_ADD,
        SectionName.VOLUNTARY_LTD,
        SectionName.VOLUNTARY_STD,
      ]
        .map(String)
        .includes(benefitType!) && <VoluntaryPlanTag />}
    </div>
  );
};

export default PlanCardHeader;
