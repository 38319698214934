import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import { getRichMedia } from 'modules/auth/services/BenguideService';

import { SectionName } from 'modules/home/constants';
import RichMedia from 'model/RichMedia';
import ContentPageApiResponse from 'model/ContentPageDetails';
import PageSection from 'model/PageSection';

type sectionConfig = {
  sectionName: SectionName;
  content: string;
  startDate: string | null;
  endDate: string | null;
  waiverAmount?: number;
  waiverFrequency?: string;
};

interface DatesEligibilityState {
  defaultOEContent: string;
  currentOEContent: string;
  defaultRulesContent: string;
  currentRulesContent: string;
  defaultLifeContent: string;
  currentLifeContent: string;
  defaultWaiverContent: string;
  currentWaiverContent: string;
  inProgress: boolean;
  createRichMediaSuccess: boolean;
  error: any;
  requestType: string;
  multipleUpdateInProgress: boolean;
  latestRevision: number;
  sections: {
    inProgress: boolean;
    error: any;
    data: PageSection[];
  };
}

const initialState = {
  defaultOEContent: '',
  currentOEContent: '',
  defaultLifeContent: '',
  currentLifeContent: '',
  defaultRulesContent: '',
  currentRulesContent: '',
  defaultWaiverContent: '',
  currentWaiverContent: '',
  inProgress: false,
  createRichMediaSuccess: true,
  error: null,
  requestType: '',
  multipleUpdateInProgress: false,
  latestRevision: 0,
  sections: {
    inProgress: false,
    error: null,
    data: [],
  },
} as DatesEligibilityState;

const currentOEContentInProgress = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.currentOEContent = '';
  state.error = null;
};

const currentOEContentCompleted = (
  state: DatesEligibilityState,
  action: PayloadAction<string>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentOEContent = action.payload;
  state.error = null;
};

const currentOEContentFailed = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentOEContent = '';
  state.error = action.payload;
};

const currentRulesContentInProgress = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.currentRulesContent = '';
  state.error = null;
};

const currentRulesContentCompleted = (
  state: DatesEligibilityState,
  action: PayloadAction<string>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentRulesContent = action.payload;
  state.error = null;
};

const currentRulesContentFailed = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentRulesContent = '';
  state.error = action.payload;
};

const currentLifeContentInProgress = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.currentLifeContent = '';
  state.error = null;
};

const currentLifeContentCompleted = (
  state: DatesEligibilityState,
  action: PayloadAction<string>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentLifeContent = action.payload;
  state.error = null;
};

const currentLifeContentFailed = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentLifeContent = '';
  state.error = action.payload;
};

const currentWaiverContentInProgress = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.currentWaiverContent = '';
  state.error = null;
};

const currentWaiverContentCompleted = (
  state: DatesEligibilityState,
  action: PayloadAction<string>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentWaiverContent = action.payload;
  state.error = null;
};

const currentWaiverContentFailed = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentWaiverContent = '';
  state.error = action.payload;
};

const currentContentInProgress = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.error = null;
  state.createRichMediaSuccess = false;
};

const currentContentCompleted = (
  state: DatesEligibilityState,
  action: PayloadAction<RichMedia>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.error = null;
  state.createRichMediaSuccess = true;
};

const currentContentFailed = (
  state: DatesEligibilityState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.error = action.payload;
  state.createRichMediaSuccess = false;
};

const datesEligibilitySlice = createSlice({
  name: 'datesEligibility',
  initialState,
  reducers: {
    getDefaultOERichMediaStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.defaultOEContent = '';
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultOERichMediaSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultOEContent = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultOERichMediaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultOEContent = '';
      state.error = action.payload;
      state.requestType = action.type;
    },
    getDefaultRulesRichMediaStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.defaultRulesContent = '';
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultRulesRichMediaSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultRulesContent = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultRulesRichMediaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultRulesContent = '';
      state.error = action.payload;
      state.requestType = action.type;
    },
    getDefaultLifeRichMediaStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.defaultLifeContent = '';
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultLifeRichMediaSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultLifeContent = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultLifeRichMediaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultLifeContent = '';
      state.error = action.payload;
      state.requestType = action.type;
    },
    getDefaultWaiverRichMediaStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.defaultWaiverContent = '';
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultWaiverRichMediaSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultWaiverContent = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultWaiverRichMediaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultWaiverContent = '';
      state.error = action.payload;
      state.requestType = action.type;
    },
    resetDefaultText(state) {
      state.defaultOEContent = '';
    },
    createRichMediaStarted(state, action: PayloadAction) {
      currentContentInProgress(state, action);
    },
    createRichMediaSuccess(state, action: PayloadAction<RichMedia>) {
      currentContentCompleted(state, action);
    },
    createRichMediaFailed(state, action: PayloadAction<any>) {
      currentContentFailed(state, action);
    },

    getCurrentOERichMediaStarted(state, action: PayloadAction) {
      currentOEContentInProgress(state, action);
    },
    getCurrentOERichMediaSuccess(state, action: PayloadAction<string>) {
      currentOEContentCompleted(state, action);
    },
    getCurrentOERichMediaFailed(state, action: PayloadAction<any>) {
      currentOEContentFailed(state, action);
    },
    getCurrentRulesRichMediaStarted(state, action: PayloadAction) {
      currentRulesContentInProgress(state, action);
    },
    getCurrentRulesRichMediaSuccess(state, action: PayloadAction<string>) {
      currentRulesContentCompleted(state, action);
    },
    getCurrentRulesRichMediaFailed(state, action: PayloadAction<any>) {
      currentRulesContentFailed(state, action);
    },
    getCurrentLifeRichMediaStarted(state, action: PayloadAction) {
      currentLifeContentInProgress(state, action);
    },
    getCurrentLifeRichMediaSuccess(state, action: PayloadAction<string>) {
      currentLifeContentCompleted(state, action);
    },
    getCurrentLifeRichMediaFailed(state, action: PayloadAction<any>) {
      currentLifeContentFailed(state, action);
    },
    getCurrentWaiverRichMediaStarted(state, action: PayloadAction) {
      currentWaiverContentInProgress(state, action);
    },
    getCurrentWaiverRichMediaSuccess(state, action: PayloadAction<string>) {
      currentWaiverContentCompleted(state, action);
    },
    getCurrentWaiverRichMediaFailed(state, action: PayloadAction<any>) {
      currentWaiverContentFailed(state, action);
    },
    updateMultipleSectionStarted(state) {
      state.multipleUpdateInProgress = true;
    },
    updateMultipleSectionCompleted(state) {
      state.multipleUpdateInProgress = false;
    },
    updateMultipleSectionFailed(state) {
      state.multipleUpdateInProgress = false;
    },
    getDatesEligibilitySectionStarted(state) {
      state.sections.inProgress = true;
      state.error = null;
    },
    getDatesEligibilitySectionCompleted(
      state,
      action: PayloadAction<ContentPageApiResponse>
    ) {
      state.sections.inProgress = false;
      state.error = null;
      state.sections.data = action.payload.contentPageVO?.sections || [];
    },
    getDatesEligibilitySectionFailed(state, { payload }) {
      state.sections.inProgress = false;
      state.sections.error = payload;
    },
  },
});

export const {
  getDefaultOERichMediaStarted,
  getDefaultOERichMediaSuccess,
  getDefaultOERichMediaFailed,
  getDefaultRulesRichMediaStarted,
  getDefaultRulesRichMediaSuccess,
  getDefaultRulesRichMediaFailed,
  getDefaultLifeRichMediaStarted,
  getDefaultLifeRichMediaSuccess,
  getDefaultLifeRichMediaFailed,
  getDefaultWaiverRichMediaStarted,
  getDefaultWaiverRichMediaSuccess,
  getDefaultWaiverRichMediaFailed,
  createRichMediaStarted,
  createRichMediaSuccess,
  createRichMediaFailed,
  getCurrentOERichMediaStarted,
  getCurrentOERichMediaSuccess,
  getCurrentOERichMediaFailed,
  getCurrentRulesRichMediaStarted,
  getCurrentRulesRichMediaSuccess,
  getCurrentRulesRichMediaFailed,
  getCurrentLifeRichMediaStarted,
  getCurrentLifeRichMediaSuccess,
  getCurrentLifeRichMediaFailed,
  getCurrentWaiverRichMediaStarted,
  getCurrentWaiverRichMediaSuccess,
  getCurrentWaiverRichMediaFailed,
  resetDefaultText,
  updateMultipleSectionStarted,
  updateMultipleSectionCompleted,
  updateMultipleSectionFailed,
  getDatesEligibilitySectionStarted,
  getDatesEligibilitySectionCompleted,
  getDatesEligibilitySectionFailed,
} = datesEligibilitySlice.actions;

export default datesEligibilitySlice.reducer;

export const getDefaultOERichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultOERichMediaStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultOERichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getDefaultOERichMediaFailed(error));
    }
  };

export const getDefaultRulesRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultRulesRichMediaStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultRulesRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getDefaultRulesRichMediaFailed(error));
    }
  };

export const getDefaultLifeRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultLifeRichMediaStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultLifeRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getDefaultLifeRichMediaFailed(error));
    }
  };

export const getDefaultWaiverRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultWaiverRichMediaStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultWaiverRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getDefaultWaiverRichMediaFailed(error));
    }
  };

export const createMultipleRichMedia =
  (benguideId: string, sections: any[]) => async (dispatch: Dispatch) => {
    dispatch(createRichMediaStarted());

    try {
      const updatedSections = sections.map((section) => {
        const {
          sectionName,
          content,
          enabled,
          startDate,
          endDate,
          waiverAmount,
          waiverFrequency,
        } = section;
        const data: sectionConfig = {
          sectionName: sectionName,
          content: content,
          startDate: null,
          endDate: null,
        };

        if (sectionName === SectionName.DE_OE_TEXT) {
          data.startDate = startDate;
          data.endDate = endDate;
        }

        if (sectionName === SectionName.DE_WAIVER_TEXT) {
          data.waiverAmount = waiverAmount;
          data.waiverFrequency = waiverFrequency;
        }

        return {
          enabled: enabled,
          ...data,
        };
      });

      const response = await BenGuideService.updatePageSections(benguideId, {
        sections: updatedSections,
      });

      const newData = {
        benGuideId: benguideId,
        content: 'content',
        title: 'title',
        latestRevision: response.data.latestRevision,
      };
      dispatch(createRichMediaSuccess(newData));
    } catch (error) {
      dispatch(createRichMediaFailed(error));
    }
  };

export const getCurrentOERichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getCurrentOERichMediaStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(getCurrentOERichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getCurrentOERichMediaFailed(error));
    }
  };

export const getCurrentRulesRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getCurrentRulesRichMediaStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(getCurrentRulesRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getCurrentRulesRichMediaFailed(error));
    }
  };

export const getCurrentLifeRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getCurrentLifeRichMediaStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(getCurrentLifeRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getCurrentLifeRichMediaFailed(error));
    }
  };

export const getCurrentWaiverRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getCurrentWaiverRichMediaStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(getCurrentWaiverRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getCurrentWaiverRichMediaFailed(error));
    }
  };

export const getDatesEligibilitySection =
  (benGuideId: string) => async (dispatch: Dispatch) => {
    dispatch(getDatesEligibilitySectionStarted());
    try {
      const response = await BenGuideService.getContentPage(
        benGuideId,
        SectionName.DATES_AND_ELIGIBILITY
      );
      dispatch(getDatesEligibilitySectionCompleted(response.data));
    } catch (error) {
      dispatch(getDatesEligibilitySectionFailed(error));
    }
  };
