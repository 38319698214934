import { Popover } from 'antd';
import {
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import styles from './overflowPopover.module.less';

type Props = {
  popoverContent?: string | ReactNode;
  maxWidth?: string | number;
  children: ReactNode;
  placement: 'top' | 'right';
  trigger?: TriggerActions | TriggerActions[];
  popoverZIndex?: number;
};

type TriggerActions = 'click' | 'hover';

const OverflowPopover: FC<Props> = (props: Props) => {
  const {
    popoverContent,
    maxWidth,
    placement,
    trigger = 'click',
    popoverZIndex = undefined,
  } = props;

  const [isOverflow, setIsOverflow] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);
  const overFlowDiv = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      const { scrollWidth, offsetWidth } = divRef.current;
      const isWidthOverflow = scrollWidth > offsetWidth;
      setIsOverflow(isWidthOverflow);
    }
    if (overFlowDiv.current) {
      const { scrollWidth, offsetWidth } = overFlowDiv.current;
      const isWidthOverflow = scrollWidth > offsetWidth;
      setIsOverflow(isWidthOverflow);
    }
  }, [props.children, size]);

  if (isOverflow) {
    return (
      <Popover
        content={popoverContent ? popoverContent : props.children}
        placement={placement}
        overlayClassName={
          placement === 'top' ? styles.overlayTop : styles.overlayRight
        }
        trigger={trigger}
        zIndex={popoverZIndex}
      >
        <div
          ref={overFlowDiv}
          className={styles.container}
          style={maxWidth ? { maxWidth: maxWidth } : {}}
        >
          {props.children}
        </div>
      </Popover>
    );
  }

  return (
    <div
      className={styles.container}
      ref={divRef}
      style={maxWidth ? { maxWidth: maxWidth } : {}}
    >
      {props.children}
    </div>
  );
};

export default OverflowPopover;
