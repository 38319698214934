import { http as axios } from 'utils/httpUtil';

import { baseApi } from 'utils/apiUtil';

const benefitGuideUrl = baseApi + '/v2/benefit-guides';

export const updateDBGFrequency = async (
  benefitGuideId: string,
  benefitKind: string,
  frequency: string,
  isEnabled: boolean
) => {
  return axios.put(`${benefitGuideUrl}/${benefitGuideId}/update-frequency`, {
    benefitKind: benefitKind,
    frequency: frequency,
    enabled: isEnabled,
  });
};

export const getBenefitGuidesGroupByFrequency = (
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${benefitGuideUrl}/${employerId}/get-by-frequency?plan-year-id=${planYearId}`
  );
};
export const checkDBGRates = (
  benefitGuideMasterId: string,
  benefitKind: string
) => {
  return axios.get(
    `${benefitGuideUrl}/validate-rates?benefitGuideMasterId=${benefitGuideMasterId}&benefitKind=${benefitKind}`
  );
};
