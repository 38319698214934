import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Col, Form, Input, Radio, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InputForm from 'components/InputForm/InputForm';
import DocumentUploader from 'components/DocumentUploader/DocumentUploader';
import {
  ADDITIONAL_RESOURCE_TYPES,
  DUPLICATE_VALIDATION,
} from 'modules/resources/constants';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import { NotificationContext } from 'context/NotificationContext';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/commonConstants';
import { clearAdditionalResUpload } from 'modules/resources/slices/resourcesSlice';

import styles from './additionalResource.module.less';

export type InvalidField =
  | 'documentInvalid'
  | 'webLinkInvalid'
  | 'bothInvalid'
  | 'resourceInvalid'
  | '';
type AdditionalResourceProps = {
  visible: boolean;
  onClose: Function;
  onSubmit: Function;
  onFileChange: Function;
  onFileRemove: Function;
  setFormData: Function;
  formData: any;
  form: any;
  isRemoved?: boolean;
  isCloseConfirmed?: boolean;
  additionalResourceType: any;
  setAdditionalResourceType: Function;
  onValidateFails?: (validateSetting: string) => void;
  disableFileUpload?: boolean;
  invalidField?: InvalidField;
  setIsAdditionalInfoValid?: Function;
  showResourceName?: boolean;
  fileName?: string;
  onRemoveResource: Function;
  isInvalid?: boolean;
  setIsInvalid?: Function;
  onCloseWithoutResetData?: Function;
  planId?: string;
  planDocument?: string;
  benefitKind?: string;
};

const AdditionalResource = forwardRef((props: AdditionalResourceProps, ref) => {
  const {
    visible,
    onClose,
    onSubmit,
    onFileChange,
    onFileRemove,
    setFormData,
    formData,
    form,
    isCloseConfirmed,
    additionalResourceType,
    setAdditionalResourceType,
    disableFileUpload = false,
    invalidField,
    setIsAdditionalInfoValid,
    showResourceName = true,
    fileName,
    onRemoveResource,
    setIsInvalid,
    onCloseWithoutResetData,
    isRemoved,
    planId,
    planDocument,
    benefitKind,
  } = props;

  const documenUploaderRef = useRef<any>();
  const [duplicateName, setDuplicateName] = useState<boolean>(false);
  const [resNameLength, setResNameLength] = useState<number>(
    form.getFieldValue('name')?.length || 0
  );
  const notificationFlag = useContext(NotificationContext);

  const dispatch = useAppDispatch();
  const { error, resUploadSuccess, resUploadInProgress } = useAppSelector(
    (state) => state.resources
  );

  useEffect(() => {
    if (isCloseConfirmed) {
      const { current = {} } = documenUploaderRef;
      const { reset = () => {} } = current;
      reset();
      form.resetFields();
    }
  }, [form, isCloseConfirmed]);

  useEffect(() => {
    if (formData['name']) {
      setResNameLength(formData['name'].length);
    }
  }, [formData]);

  useEffect(() => {
    if (error?.code === DUPLICATE_VALIDATION) {
      setDuplicateName(true);
    } else if (resUploadSuccess) {
      onCloseWithoutResetData && onCloseWithoutResetData();
    } else {
      setDuplicateName(false);
    }
  }, [error, resUploadSuccess, onCloseWithoutResetData]);

  const onInputChange = (changedValues: any, allValues: any) => {
    if (Object.keys(changedValues).includes('name')) {
      const nameLength = changedValues['name'].length;
      setResNameLength(nameLength);
      if (nameLength === 0) {
        setIsInvalid && setIsInvalid(true);
      }
      dispatch(clearAdditionalResUpload());
      setDuplicateName(false);
    }
    if (changedValues['weblink']) {
      setIsAdditionalInfoValid && setIsAdditionalInfoValid('');
      removeFile();
      setFormData(allValues);
    } else {
      setFormData({ ...formData, ...allValues });
    }
    form.setFieldsValue(allValues);
  };

  const handleClose = () => {
    removeFile();
    onClose();
  };

  const removeFile = () => {
    const { current = {} } = documenUploaderRef;
    const { reset = () => {}, setFileName = () => {} } = current;
    reset();
    setFileName('');
    onFileRemove();
  };

  const handleClick = (val: any) => {
    if (setIsAdditionalInfoValid) {
      setIsAdditionalInfoValid('');
    }

    if (val.target.value === additionalResourceType) {
      setAdditionalResourceType('');
      removeFile();
      form.setFieldsValue({ weblink: '' });
    } else {
      removeFile();
      form.setFieldsValue({ weblink: '' });
      setAdditionalResourceType(val.target.value);
    }
  };

  useImperativeHandle(ref, () => ({
    reset() {
      const { current = {} } = documenUploaderRef;
      const { reset = () => {} } = current;
      reset();
    },
    resetNameCharCount() {
      setResNameLength(0);
    },
    resetErrors() {
      setDuplicateName(false);
      dispatch(clearAdditionalResUpload());
    },
    setResFileName(name: string) {
      const { current = {} } = documenUploaderRef;
      const { setFileName = () => {} } = current;
      setFileName(name);
    },
  }));

  return (
    <HalfScreenModal
      visible={visible}
      onCancel={() => handleClose()}
      closeIcon={false}
      title="Upload Resources"
      width={555}
      onSubmit={onSubmit}
      disabledRemoveButton={
        (!fileName && !formData.weblink) || disableFileUpload || isRemoved
      }
      removeContentText="Remove Resource"
      onRemoveContent={() => onRemoveResource(onClose)}
      isLoading={resUploadInProgress}
    >
      {notificationFlag && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      <div className={styles.resWrapperForm}>
        <InputForm form={form} onValuesChange={onInputChange}>
          {showResourceName && (
            <div className={duplicateName ? styles.resNameWrapper : ''}>
              <Form.Item
                className={styles.resourceName}
                label="Resource Name"
                name="name"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Name Required',
                  },
                ]}
              >
                <Input data-cy="resourceName" maxLength={40} />
              </Form.Item>
              <div>
                {duplicateName && (
                  <div className={styles.errorMsg}>Name Already Exists</div>
                )}
                <div
                  className={
                    duplicateName ? styles.leftLabel : styles.maxCountLabel
                  }
                >{`Character Limit: ${resNameLength}/40`}</div>
              </div>
            </div>
          )}

          <Radio.Group
            disabled={disableFileUpload || notificationFlag}
            name="radiogroup"
            value={additionalResourceType}
          >
            <Row gutter={48} className={styles.rowContainer}>
              <Col
                span={6}
                className={
                  invalidField === 'documentInvalid' ||
                  invalidField === 'bothInvalid' ||
                  invalidField === 'resourceInvalid'
                    ? styles.radioBtnError
                    : ''
                }
              >
                <Radio
                  value={ADDITIONAL_RESOURCE_TYPES.FILE}
                  onClick={handleClick}
                >
                  <div
                    className={
                      invalidField === 'documentInvalid' ||
                      invalidField === 'bothInvalid' ||
                      invalidField === 'resourceInvalid'
                        ? styles.errorOption
                        : styles.optionalTitle
                    }
                  >
                    File
                  </div>
                </Radio>
              </Col>
              <Col>
                <div className={styles.fileUploader}>
                  <DocumentUploader
                    ref={documenUploaderRef}
                    fileName={fileName}
                    onChange={onFileChange}
                    onRemoveFile={removeFile}
                    allowedFileTypes="application/pdf"
                    uploadSizeLimitInMB={100}
                    disabled={
                      additionalResourceType !==
                        ADDITIONAL_RESOURCE_TYPES.FILE ||
                      disableFileUpload ||
                      notificationFlag
                    }
                    hideSizePlaceholder
                    invalidError={
                      invalidField === 'documentInvalid'
                        ? 'Invalid File'
                        : invalidField === 'bothInvalid' ||
                          invalidField === 'resourceInvalid'
                        ? 'Resource Required'
                        : ''
                    }
                    isExpand
                    onValidateType={() =>
                      setIsAdditionalInfoValid &&
                      setIsAdditionalInfoValid('documentInvalid')
                    }
                    planId={planId}
                    planDocument={planDocument}
                    benefitKind={benefitKind}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={48} className={styles.rowContainer}>
              <Col
                span={6}
                className={
                  invalidField === 'webLinkInvalid' ||
                  invalidField === 'bothInvalid'
                    ? styles.radioBtnError
                    : ''
                }
              >
                <div className={styles.weblink}>
                  <Row>
                    <Radio
                      disabled={disableFileUpload}
                      value={ADDITIONAL_RESOURCE_TYPES.WEBLINK}
                      onClick={handleClick}
                    >
                      <div
                        className={
                          invalidField === 'webLinkInvalid' ||
                          invalidField === 'bothInvalid'
                            ? styles.errorOption
                            : styles.optionalTitle
                        }
                      >
                        Weblink
                      </div>
                    </Radio>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <Form.Item name="weblink" className={styles.weblink}>
                    <Input
                      disabled={
                        additionalResourceType !==
                          ADDITIONAL_RESOURCE_TYPES.WEBLINK || disableFileUpload
                      }
                      className={styles.weblinkInput}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={16}>
                <div>
                  {(invalidField === 'webLinkInvalid' ||
                    invalidField === 'bothInvalid') && (
                    <span className={styles.invalidError}>
                      {invalidField === 'webLinkInvalid' && formData?.weblink
                        ? `Invalid weblink`
                        : `Resource Required`}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Radio.Group>
        </InputForm>
      </div>
    </HalfScreenModal>
  );
});
AdditionalResource.displayName = 'AdditionalResource';
export default AdditionalResource;
