import { FC, forwardRef } from 'react';
import { Dayjs } from 'dayjs';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';

import styles from './timePickerInput.module.less';

interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const TimePickerInput: FC<TimePickerProps> = forwardRef(
  (props: TimePickerProps, ref: any) => {
    const { ...rest } = props;

    return (
      <DatePicker
        dropdownClassName={styles.timePickerWrapper}
        {...rest}
        picker="time"
        ref={ref}
      />
    );
  }
);
TimePickerInput.displayName = 'TimePicker';

export default TimePickerInput;
