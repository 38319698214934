import { FC } from 'react';
import { Drawer } from 'rsuite';
import metadata from 'data/metadata';
import {
  getAdditionalServices,
  getConfiguredBenefitCodeList,
} from 'modules/benefitsWellness/util';
import CardTitles from 'modules/planRecommender/components/PlanDetailsPopup/CardTitles/CardTitles';
import PlanCardBody from 'modules/planRecommender/components/PlanDetailsPopup/PlanCardBody/PlanCardBody';
import { DEFAULT_TEXT } from 'modules/planRecommender/pages/constants';
import { baseApi } from 'utils/apiUtil';
import { getCardFieldValue } from 'utils/planRecommenderUtils/planRecommenderUtils';
import iconBack from 'images/icons/icon-back.png';
import PlanCardBodyMobile from './PlanCardBody/PlanCardBodyMobile/PlanCardBodyMobile';
import styles from './planDetailsPopup.module.less';

type PlanDetailsPopupProps = {
  planType: string;
  plan: any;
  networkType: string;
  selectedRate: string;
  isdrawerOpen: boolean;
  onHide: () => void;
  planId: string;
};

const PlanDetailsPopup: FC<PlanDetailsPopupProps> = (
  props: PlanDetailsPopupProps
) => {
  const { planType, plan, networkType, selectedRate, isdrawerOpen, onHide } =
    props;

  const getTitlesAndKeys: any = () => {
    if (planType) {
      const fieldGroups = metadata.benguideMetaData.planFieldGroupings.find(
        function (planFieldGroup) {
          return planFieldGroup.planType === planType;
        }
      ) as any;
      //  check field groups matching to custom fields to remove the custom fields from plan recommender
      const filteredGroups = fieldGroups.groups.filter((subGroup: any) => {
        return subGroup.find(
          (field: any) => field.fieldName !== 'customFields'
        );
      });
      const titles = [] as any[];
      const tempKeys = [] as any[];
      const definedBenefitCodes = getConfiguredBenefitCodeList(filteredGroups);
      const additionalServiceList = getAdditionalServices(
        [plan],
        definedBenefitCodes
      );
      filteredGroups.forEach((fieldGroup: any) => {
        const titleGroup = [] as any;
        const keyGroup = [] as any;
        fieldGroup.forEach((e: any) => {
          if (e.additionalServices) {
            additionalServiceList.forEach((additionalService) =>
              titleGroup.push(additionalService.fieldValue)
            );
          } else if (
            !('showTitleIfExists' in e) ||
            (e.showTitleIfExists &&
              checkIfFieldExistsInAllPlans(
                e,
                [plan],
                networkType,
                selectedRate
              ))
          ) {
            titleGroup.push(e.fieldValue);
            keyGroup.push({ ...e });
          }
        });
        titles.push(titleGroup);
        tempKeys.push(keyGroup);
      });
      return [titles, tempKeys];
    }
  };
  const renderSBCNavLink = () => {
    // SBC For Medical
    if (
      planType &&
      planType === 'medical' &&
      plan &&
      plan.documents &&
      plan.documents.SBC
    ) {
      return (
        <a
          href={
            plan && plan.documents && plan.documents.SBC
              ? `${baseApi}/v1/plans/${
                  plan.id
                }/documents?documentType=SBC&benefitKind=${planType.toUpperCase()}&isOpen=${true}`
              : ''
          }
          target="_blank"
          rel="noopener noreferrer"
          className={styles.heapSeeSbc}
        >
          See SBC
        </a>
      );
    }
  };

  const [titles, keys] = getTitlesAndKeys();
  const deviceWidth: number = window.innerWidth;
  return (
    // full - for full screen
    <Drawer
      size="md"
      className={styles.planDetailsPopup}
      show={isdrawerOpen}
      onHide={onHide}
    >
      <Drawer.Header>
        <div className={styles.planDetailsHeader}>
          {deviceWidth < 1025 ? (
            <>
              <img
                className={styles.backArrow}
                onClick={onHide}
                src={iconBack}
              />
              <div className={styles.planName}>{plan.name}</div>
              <hr className={styles.hrTag} />
            </>
          ) : (
            <div>
              <div className={styles.title}>{plan.name}</div>
              <hr className={styles.hrTag} />
            </div>
          )}
        </div>
      </Drawer.Header>
      <Drawer.Body className={styles.drawerBody}>
        <div className={styles.planDetailsPopupWrapper}>
          <div className={styles.inNetworkOutNetworkWrapper}>
            {titles && (
              <CardTitles
                // titles={titles.splice(1, titles.length)}
                keys={[...keys].splice(1, titles.length)}
                allowHeaderTitle={false}
              />
            )}
            {deviceWidth > 576 ? (
              <div className={styles.inOutNetworkPlanCard}>
                {selectedRate && plan && networkType && (
                  <PlanCardBody
                    key={Math.random()}
                    mappings={[...keys].splice(1, titles.length)}
                    selectedRate={selectedRate}
                    plan={plan}
                    showSuperScripts={false}
                    networkType={networkType}
                  />
                )}
              </div>
            ) : (
              <div className={styles.inOutNetworkPlanCard}>
                {selectedRate && plan && networkType && (
                  <PlanCardBodyMobile
                    key={Math.random()}
                    mappings={[...keys].splice(1, titles.length)}
                    selectedRate={selectedRate}
                    plan={plan}
                    showSuperScripts={false}
                    networkType={networkType}
                  />
                )}
              </div>
            )}
            <div className={styles.sbcLink}>{renderSBCNavLink()}</div>
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};

const checkIfFieldExistsInAllPlans = (
  fieldData: any,
  plans: any,
  network: any,
  rate: any
) =>
  plans.reduce(
    (result: any, currentValue: any) =>
      result ||
      getCardFieldValue(currentValue, fieldData, network, rate) !==
        ('defaultValue' in fieldData ? fieldData.defaultValue : DEFAULT_TEXT),
    false
  );

export default PlanDetailsPopup;
