import { Row } from 'antd';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import AuthHeading from 'modules/auth/components/AuthModal/AuthHeading/AuthHeading';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';
import {
  setAuthView,
  signUp,
  login,
  setRegisterValues,
  updateTermsForIndividual,
  resetAuthState,
  setWhoAmI,
  setRosterAndPersonal,
} from 'modules/auth/slices/employeeAuthSlice';
import { resetIDcardState } from 'modules/auth/slices/idCardSlice';

import styles from './terms.module.less';

const Terms = () => {
  const isSSOUser = useAppSelector((state) => state.employeeAuth.isSSOUser);
  const isEmployeeLoggedIn = useAppSelector((state) => state.idCard.isLoggedIn);
  const dispatch = useAppDispatch();
  const { hash = '', employerName = '' } = useParams();
  const baseUrl = `/${hash}/`;
  const [isButtonActive, setIsButtonActive] = useState(false);
  const registerValues = useAppSelector(
    (state) => state.employeeAuth.registerValues
  );
  const isSelfDeclared = useAppSelector(
    (state) => state.employeeAuth.isSelfDeclared
  );
  const token = useAppSelector((state) => state.employeeAuth.token);
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const inProgress = useAppSelector((state) => state.employeeAuth.inProgress);
  const whoAmI = useAppSelector((state) => state.employeeAuth.whoami);
  const isRosterAndPersonal = useAppSelector(
    (state) => state.employeeAuth.isRosterAndPersonal
  );

  const handleBack = () => {
    if (isSSOUser) {
      dispatch(setAuthView(AUTH_VIEW.SSO_REGISTER.type));
    } else if (isRosterAndPersonal) {
      dispatch(setAuthView(AUTH_VIEW.ROSTER.type));
      dispatch(setRosterAndPersonal(false));
    } else {
      dispatch(setAuthView(AUTH_VIEW.REGISTER.type));
    }
  };

  const handleLogout = () => {
    dispatch(resetAuthState());
    dispatch(resetIDcardState());
    dispatch(setWhoAmI({}));
  };

  useEffect(() => {
    const modalContent = document.getElementById('scroll-container');
    if (modalContent) {
      const handleScroll = () => {
        const bottomPosition =
          modalContent.scrollHeight - modalContent.clientHeight;
        if (!isButtonActive) {
          setIsButtonActive(
            Math.round(modalContent.scrollTop) === bottomPosition
          );
        }
      };
      modalContent.addEventListener('scroll', handleScroll);
      return () => modalContent.removeEventListener('scroll', handleScroll);
    }
  }, [isButtonActive]);

  const handleSignUp = async (values: any) => {
    const constructRedirectURL = `benguideV2${baseUrl}${employerName}`;
    if (token) {
      const response = await dispatch(
        signUp(
          values?.email?.trim(),
          token!,
          values?.confirmPassword?.trim(),
          values?.firstName,
          values?.lastName,
          isSelfDeclared
        )
      );
      dispatch(setRegisterValues({}));
      if (response?.status === 201) {
        dispatch(
          login(
            values?.email?.trim(),
            values?.confirmPassword?.trim(),
            constructRedirectURL,
            benguideData?.planYearId
          )
        );
      }
    }
  };

  const rosterAndPersonalHandle = async (values: any) => {
    const constructRedirectURL = `benguideV2${baseUrl}${employerName}`;
    if (token) {
      const response = await dispatch(
        signUp(values?.email?.trim(), token!, values?.confirmPassword?.trim())
      );
      if (response?.status === 201) {
        dispatch(
          login(
            values?.email?.trim(),
            values?.confirmPassword?.trim(),
            constructRedirectURL,
            benguideData?.planYearId
          )
        );
        dispatch(setRegisterValues({}));
        dispatch(setRosterAndPersonal(false));
      }
    }
  };

  return (
    <>
      {isEmployeeLoggedIn && (
        <>
          <AuthHeading header="" />
          <h1 className={styles.termsHeader}>Terms and Conditions</h1>
        </>
      )}
      <div className={styles.scrollContainer} id="scroll-container">
        <p>
          The following Terms and Conditions of Use (“Terms”) is an agreement
          between you, the user (“you”), and PlanYear, LLC, and its affiliates
          (“PlanYear” or “we”) with respect to your use of the website
          planyear.com, including any subdomains or web services available
          thereon (the “Site”) and the PlanYear Mobile Application
          (“Application”). By installing and/or using the application on a web
          or mobile device you acknowledge (a) acknowledge that you have read,
          understand and accept this agreement; (b) represent that you are 18
          years of age or older; and (c) accept this agreement and agree that
          you are legally bound by its terms. If you do not agree to these
          terms, do not download, install or use the application or delete it
          from your mobile device.
        </p>
        <p>
          <br />
          <strong>1. Acceptance of Terms and Conditions of Use</strong>
          <br />
          By using the Site or Application, you agree to comply with these Terms
          and Conditions. Please review them carefully. If you do not comply or
          agree with theseTerms and Conditions, please do not access or use the
          Site or any of its Content (as defined herein).
        </p>
        <p>
          These Terms may be revised or updated from time to time. You should
          check the Terms regularly for updates. Each time you access, use or
          browse the Site or Application, you signify your acceptance of the
          then-current Terms. Any material changes in these Terms take effect
          upon posting and apply only to your use of the Site and/or Application
          and information collected from you on and after Last Updated date,
          unless we have other communications with you.
        </p>
        <p>
          We may modify, suspend or discontinue, temporarily or permanently, the
          Site, the Content, or the Application at any time. In addition,
          PlanYear may suspend, discontinue, restrict or terminate your use of
          the Site or Application at any time for any reason or no reason with
          or without notice. PlanYear will not be liable to you or any third
          party for any such suspension, discontinuance, restriction or
          termination.
        </p>
        <p>
          You represent and warrant that you are at least 18 years of age and
          possess the legal authority, based on the state of your residence, to
          enter into this Agreement, access the Site and use PlanYear&apos;s
          products and services. If you intend to access and use PlanYear&apos;s
          services and products for a minor, you must be the legal guardian for
          such minor and agree that you will supervise all usage on such minor
          account.
        </p>
        <p>
          <br />
          <strong>2. Permitted Uses</strong>
          <br />
          The content available through the Site and/or Application, including
          without limitation text, graphics, brochures, materials, charts,
          audio, animation, videos, logos, icons, images, media, data, the look
          and feel of the Site and/or Application, software, products, features,
          tools and other information, materials or services available on, or
          from, the Site and/or Application (the &quot;Content&quot;) is the
          sole and exclusive property of PlanYear, its licensors, or other
          content providers.
        </p>
        <p>
          You agree to abide by any posted limitations relating to use,
          reproduction or dissemination of any Content. Any use of the Site,
          Application or Content in any way not expressly permitted by these
          Terms is prohibited, and may be actionable under United States or
          international law. Any rights not expressly granted by these Terms are
          expressly reserved by PlanYear.
        </p>
        <p>
          PlanYear hereby grants you permission to access and use the Site
          and/or Application subject to the following express conditions, and
          you agree that your failure to adhere to any of these conditions shall
          constitute a breach of the Terms on your part:
        </p>
        <ul>
          <li>
            You agree not to access (or attempt to access) any of the services
            by any automated means (including use of scripts or web crawlers) or
            means other than through the interface that is provided by PlanYear.
          </li>
          <li>
            You agree that you will not engage in any activity that interferes
            with or disrupts the Site, Application, or the servers and networks
            which are connected to the Site.
          </li>
          <li>
            You agree that you will not reproduce, duplicate, copy, sell, trade
            or resell the Content, products or services available on the Site
            and/or Application for any purpose.
          </li>
          <li>
            You will not interfere with, or disrupt, (or attempt to do so), the
            access of any user, host or network, including, without limitation,
            sending a virus, overloading, flooding, spamming, mail-bombing the
            Site, or by scripting the creation of Content in such a manner as to
            interfere with or create an undue burden on the Site and/or
            Application. In addition, you are prohibited from violating or
            attempting to violate the security of the Site and/or Application
            by, for example, attempting to access data not intended for you or
            logging into a server or account you are not authorized to access.
            Violations and attempted violations of system or network security
            may result in civil or criminal liability. PlanYear will investigate
            occurrences and may involve, and cooperate with, law enforcement
            authorities in prosecuting any user or users who are involved in
            such violations.
          </li>
          <li>
            You agree that you are solely responsible for (and that PlanYear has
            no responsibility to you or to any third party for) any breach of
            your obligations under the Terms and for the consequences (including
            any loss or damage which PlanYear or any third party may suffer) of
            any such breach.
          </li>
          <li>
            Special terms may apply to some products, services or features
            offered on the Site and/or Application, or to any promotions,
            sweepstakes, contests, surveys, questionnaires, or other activities
            (collectively, &quot;Activities&quot;) that may be offered on the
            Site, Application or offline. Such special terms or rules (which may
            include official rules and expiration dates) may be posted in
            connection with the applicable Activity. By participating in any
            Activity you will become subject to those terms or rules. We urge
            you to read the applicable terms or rules which are linked from the
            particular Activity, and to review our Privacy Policy which, as part
            of these Terms, governs any information you submit in connection
            with such Activities. Any special terms or rules are in addition to
            these Terms and, in the event of a conflict, any such terms or rules
            shall prevail over these Terms.
          </li>
        </ul>
        <p>
          <br />
          <strong>3. Protected Health Information</strong>
          <br />
          User acknowledges that PlanYear is not a “Business Associate” as
          defined in the Health Insurance Portability and Accountability Act and
          related amendments and regulations as updated or replaced (“HIPAA”).
          Users should not use, disclose, transmit or otherwise process any
          “Protected Health Information” as defined in HIPAA (“PHI”) through the
          Services. User agrees that we have no liability for PHI received from
          User, notwithstanding anything to the contrary herein.
        </p>
        <p>
          <br />
          <strong>4. Mobile Application</strong>
          <br />
          The Application and the associated cloud services may include certain
          features that are delivered via a mobile device, including:
          <ol>
            <li>
              the ability to upload content to the Services via a mobile device,
            </li>
            <li>
              the ability to browse the Services and the PlanYear Platform from
              a mobile device, and
            </li>
            <li>
              the ability to access certain features including items like
              digital ID cards through an application downloaded and installed
              on a mobile device.
            </li>
          </ol>
          <br />
          Consistent with our Corporate Website Privacy Policy and Digital
          Platform Privacy Policy, some of our Services may allow PlanYear and
          selected third-party partners to send push notifications, to
          User&apos;s mobile device. User&apos;s wireless service carrier&apos;s
          charges, data rates, and other fees may apply. Under no circumstances
          will PlanYear or third-party partners be responsible for any internet
          service or wireless service carrier charges, fees, or expenses
          incurred by the User. In addition, downloading, installing, or using
          certain Services may be prohibited or restricted by User&apos;s
          carrier, and not all Services may work with all carriers or devices.
          If User changes or deactivates User&apos;s mobile telephone number,
          User agrees to promptly update User&apos;s PlanYear account
          information. The Application is intended for use within the USA and
          using the same from a foreign country (outside of the USA) is
          prohibited.
        </p>
        <p>
          <br />
          <strong>5. Provider Lookup Service</strong>
          <br />
          The Application and the process of enrollment enables the users to
          look for suitable service providers including participating doctors
          and other facilities. The data provided on an as-is basis and should
          be independently confirmed by the user. The service is provided as is
          without any representations or warranties of any kind.
          <br />
          PlanYear LLC DISCLAIMS ANY AND ALL EXPRESS OR IMPLIED WARRANTIES OR
          REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE PROVIDER
          INFORMATION ON THE GET CARE TOOL, INCLUDING WITHOUT LIMITATION, THE
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        </p>
        <p>
          <br />
          <strong>6. Collection and User of Your Information</strong>
          <br />
          You acknowledge that when you download, install or use the
          Application, Company may use automatic means (including, for example,
          cookies and web beacons) to collect information about your Mobile
          Device and about your use of the Application, including accessing your
          device&apos;s geolocation features. You also may be required to
          provide certain information about yourself as a condition to
          downloading, installing or using the Application or certain of its
          features or functionality, and the Application may provide you with
          opportunities to share information about yourself with others. All
          information we collect through or in connection with this Application
          is subject to our Privacy Policy. By downloading, installing, using
          and providing information to or through this Application, you consent
          to all actions taken by us with respect to your information in
          compliance with the Privacy Policy.
        </p>
        <p>
          <br />
          <strong>7. Proprietary Rights</strong>
          <br />
          You acknowledge and agree that, as between PlanYear and you, all
          right, title, and interest in and to the Site, Application and the
          Content, including without limitation any patents, copyrights,
          trademarks, trade secrets, inventions, know-how, and all other
          intellectual property and proprietary rights, are owned exclusively by
          PlanYear, or its suppliers, vendors, merchants, licensors, or
          Activities partners or sponsors, and are protected by United States
          intellectual property and other applicable laws. Any suggestions,
          information or feedback provided by you to PlanYear regarding the
          Site, Application or the Content (including, without limitation, with
          respect to modifications, enhancements, improvements and other
          changes) (“Feedback”) is voluntary and you hereby grant to PlanYear a
          world-wide, royalty free, irrevocable, transferrable, perpetual
          license to use (and authorize others to use) any Feedback without
          restriction.
          <br />
          Trademarks: The trademarks, service marks, logos, slogans, trade names
          and trade dress used on the Site and Application are proprietary to
          PlanYear or its suppliers, vendors, merchants, licensors, or
          Activities partners or sponsors. PlanYear reserves all rights in and
          to the PlanYear trademarks and servicemarks, and you agree not to use
          such marks in any connection with any product or service, in any
          manner that is likely to cause confusion among customers, or in any
          manner that disparages or discredits PlanYear. All other trademarks,
          service marks and logos used in this Site and Application are the
          trademarks, service marks or logos of their respective owners. Third
          party trademarks referenced on the Site do not constitute or imply
          affiliation with or endorsement of PlanYear by the respective
          trademark owners, or by PlanYear of the respective trademark owners.
          <br />
          Copyright: All Content, including material made available for download
          from the Site or Application, is the copyright and property of
          PlanYear or its suppliers, vendors, merchants, licensors, or
          Activities partners or sponsors, and protected by U.S. and
          international copyright laws. You agree not to copy, reprint,
          republish, modify, distribute, transmit, republish, sell, resell or
          exploit, or create derivative works based upon, any portion of the
          Site or the Content, for any purpose, or in any form or media, other
          than as expressly authorized by PlanYear in writing. You shall not
          alter, obscure or remove any proprietary notices, including copyright
          notices, contained on any downloaded materials.
          <br />
          DMCA Contact Information: We will respond to notices of alleged
          copyright infringement that comply with applicable law and are
          properly provided to us. If you believe that your content has been
          copied on the Site or Application in a way that constitutes copyright
          infringement, please provide us with the following information: (i) a
          physical or electronic signature of the copyright owner or a person
          authorized to act on their behalf; (ii) identification of the
          copyrighted work claimed to have been infringed; (iii) identification
          of the material that is claimed to be infringing or to be the subject
          of infringing activity and that is to be removed or access to which is
          to be disabled, and information reasonably sufficient to permit us to
          locate the material; (iv) your contact information, including your
          address, telephone number, and an email address; (v) a statement by
          you that you have a good faith belief that use of the material in the
          manner complained of is not authorized by the copyright owner, its
          agent, or the law; and (vi) a statement that the information in the
          notification is accurate, and, under penalty of perjury, that you are
          authorized to act on behalf of the copyright owner. Our designated
          copyright agent for notice of alleged copyright infringement appearing
          on the Site or Application is:
          <br />
          <br />
          PlanYear, Inc.
          <br />
          17595 Harvard Ave <br />
          Ste C-850 <br />
          Irvine, CA 92614 <br />
          <br />
          We reserve the right to remove Content alleged to be infringing
          without prior notice, at our sole discretion, and without liability to
          you. In appropriate circumstances, PlanYear may also terminate a
          user&apos;s account.
        </p>
        <p>
          <br />
          <strong>8. Warranty Disclaimer and Limitation of Liability</strong>
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SITE AND THE
          APPLICATION, INCLUDING ANY CONTENT CONTAINED WITHIN OR ACCESSED FROM
          THE SITE AND/OR APPLICATION, ARE PROVIDED &quot;AS IS,&quot; WITH ALL
          FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER
          EXPRESSED OR IMPLIED, AND PLANYEAR HEREBY DISCLAIMS, TO THE MAXIMUM
          EXTENT PERMITTED BY LAW, (A) ANY IMPLIED WARRANTY OF ACCURACY,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
          NONINFRINGEMENT OR (B) ANY WARRANTY THAT THE SITE WILL BE
          UNINTERRUPTED, ERROR-FREE, FREE OF HARMFUL COMPONENTS, OR SECURE. THE
          SITE, APPLICATION AND CONTENT SHOULD NOT BE RELIED UPON FOR PERSONAL,
          MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN
          APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR
          SITUATION. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF
          THIS SITE AND/OR APPLICATION, THE CONTENT AND ANY THIRD PARTY WEBSITES
          OR SERVICES ACCESSED OR REFERENCED FROM THE SITE AND/OR APPLICATION.
          WITHOUT LIMITING THE FOREGOING, PLANYEAR AND/OR ITS LICENSORS AND
          SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
          AVAILABILITY, TIMELINESS OR ACCURACY OF ANY OF THE CONTENT ON THE SITE
          OR APPLICATION, OR THAT THE SITE, ITS SERVERS OR E-MAILS SENT FROM
          PLANYEAR OR ITS SERVICE PROVIDERS ARE OR WILL BE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS.
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, PLANYEAR AND/OR ITS OFFICERS,
          DIRECTORS, EMPLOYEES, AGENTS, CONTRACTORS, LICENSORS AND SUPPLIERS ARE
          NEITHER RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES ARISING
          OUT OF OR RELATING IN ANY WAY TO THE SITE OR CONTENT AVAILABLE ON OR
          ACCESSED FROM THE SITE AND/OR APPLICATION, AND/OR ANY THIRD PARTY
          WEBSITES OR SERVICES ACCESSED OR REFERENCED FROM THE SITE AND/OR
          APPLICATION, OR OTHERWISE ARISING OUT OF YOUR USE OF THE SITE AND/OR
          APPLICATION, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
          LIABILITY OR OTHERWISE, EVEN IF PLANYEAR IS MADE AWARE OF THE
          POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO
          NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
          OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. YOUR
          SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, APPLICATION, THE
          CONTENT AND/OR ANY THIRD PARTY SITE OR SERVICES IS TO STOP USING THE
          SITE, APPLICATION, CONTENT AND/OR THOSE SERVICES.
          <br />
          Although PlanYear attempts to ensure the integrity and accurateness of
          the Site and the Application, it makes no guarantees whatsoever as to
          the correctness or accuracy of the Site and the Application. It is
          possible that the Site and/or the Application could include
          inaccuracies or errors, and that unauthorized additions, deletions and
          alterations could be made to the Site and/or Application by third
          parties. In the event that an inaccuracy arises, please inform
          PlanYear (info@PlanYear.com) so that it can be corrected.
        </p>
        <p>
          <br />
          <strong>9. Indemnification</strong>
          <br />
          You will indemnify, hold harmless and defend PlanYear (including its
          affiliates, officer, directors, employees, contractors and agents),
          its licensors and service providers from any and all claims, actions,
          proceedings, suits, liabilities, damages, settlements, penalties,
          fines, costs or expenses (including, without limitation, reasonable
          attorneys’ fees and other litigation expenses) incurred by PlanYear
          arising out of or relating to: (a) your breach of any term or
          condition of these Terms, (b) your use or misuse of the Site,
          Application, or Content, or (c) violations of any laws, rules or
          regulations applicable to your use of the Site, Application, or
          Content. PlanYear reserves the right, at your expense, to assume the
          exclusive defense and control of any matter for which you are required
          to indemnify PlanYear and you will cooperate with PlanYear&apos;s
          defense of such claims. You will not settle any such claim without
          PlanYear&apos;s prior written consent.
        </p>
        <p>
          <br />
          <strong>
            10. Products and Services May Not be Available in All States
          </strong>
          <br />
          We provide services and products, if applicable, described on this
          Site and/or Application only to persons in those states and
          jurisdictions where such products and services may legally be sold.
          Nothing on our Site and/or Application should be considered a
          solicitation to buy or an offer to sell any products or services to
          any person or company in any jurisdiction where such offer,
          solicitation, purchase or sale would not be lawful.
        </p>
        <p>
          <br />
          <strong>11. Accounts on PlanYear</strong>
          <br />
          If you create an account on the Site and/or Application, you are
          responsible for protecting the confidentiality of your account and
          password and for restricting access to your account. You understand
          and agree that you are responsible for all activities that occur under
          your account or password, whether by you or others. You agree to (a)
          log out of your account at the end of each session; (b) keep your
          password confidential and not share it with anyone else; and (c)
          immediately notify PlanYear of any unauthorized use of your password
          or account or any other breach of security. You are solely responsible
          (to PlanYear and others) for all activity that occurs under your
          PlanYear account. If you have the ability to upload materials to your
          account, you are solely responsible to backup such materials. PlanYear
          reserves the right to delete or remove any materials uploaded to any
          account at any time in its sole discretion. PlanYear is authorized to
          act on instructions received through use of your password, and is not
          liable for any loss or damage arising from your failure to comply with
          this Section.
          <br />
          The information PlanYear collects on or through its services may
          include: (a) information that you provide by filling in forms; (b)
          records and copies of your correspondence, if you contact PlanYear;
          (c) details of transactions you carry out through our Services; (d)
          details regarding your use of PlanYear&apos;s Services, including
          traffic data, logs, and other communication data and the resources
          that you access and use of the Services, (e) information about your
          computer and internet connection, including your IP address; and (f)
          any other information you provide to PlanYear in connection with your
          use of the Services (collectively, “Customer Information&apos;).
          <br />
          You hereby authorize PlanYear to collect and use Customer Information
          to (a) present our Services and its contents to you, (b) to provide
          you with information, products, or services that you request from us,
          (c) to fulfill any other purpose for which you provide it, (d) to
          carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection, and (e) and to help PlanYear improve the Services.
          PlanYear shall implement appropriate technical and organizational
          measures to safeguard Customer Information, including aggregation and
          anonymization, to prevent unauthorized access, disclosure, alteration,
          or destruction. PlanYear shall not sell or disclose any Customer
          Information without the customers&apos; explicit consent, except as
          required by law.
        </p>
        <p>
          <br />
          <strong>12. Links or Pointers to Other Sites</strong>
          <br />
          The Site and/or Application may contain advertisements, postings and
          links to websites, applications or services operated by other parties.
          The Site and/or Application provides these advertisements, postings
          and links as a convenience, and your use of other websites,
          applications or services is at your own risk. The advertisements,
          postings and linked sites are not under the control of PlanYear, which
          is not responsible for their content or privacy practices. PlanYear
          makes no representations whatsoever about any other website that you
          may access through the Site and/or Application. In addition, a
          hyperlink to another website from the Site and/or Application does not
          mean that PlanYear endorses or accepts any responsibility for the
          content, or the use, of the linked site. It is up to you to take
          precautions to ensure accuracy of the information and that whatever
          you select for your use or download is free of such items as viruses,
          worms, trojan horses, and other items of a destructive nature. You
          acknowledge and agree that PlanYear is not responsible or liable,
          directly or indirectly, for any damage, loss or other claim caused or
          alleged to be caused by or in connection with, access to, use of or
          reliance on any content available on or through any other site or
          resource.
        </p>
        <p>
          <br />
          <strong>
            13. Confidential and Proprietary Information; Consent to Electronic
            Disclosure
          </strong>
          <br />
          In addition, by using PlanYear&apos;s service, you may be asked to
          consent to receive electronic disclosure of Employee Benefits notices,
          such as Summary Plan Descriptions and plan amendments. If you provide
          such consent, you must have access to equipment and software required
          to log into the Site and/or Application and access, review and print
          such documents. By using PlanYear&apos;s service, you also understand
          and agree to receive Employee Benefit notices electronically and to be
          notified of employee benefit notices using the email address you have
          provided to PlanYear.
          <br />
          You may withdraw your consent to electronic disclosure at any time by
          sending an email to info@PlanYear.com. If you withdraw your electronic
          disclosure consent, you are entitled to receive a paper copy of all
          Employee Benefit notices, including Summary Plan Descriptions, COBRA
          notifications and enrollment information, and plan amendments, upon
          request at no charge.
        </p>
        <p>
          <br />
          <strong>14. Notice Required by California Law</strong>
          <br />
          Pursuant to California Civil Code Section 1789.3, California residents
          are entitled to the following specific consumer rights notice: The
          name, address and telephone number of the provider of this service is
          PlanYear LLC, 17595 Harvard Ave , Ste C-850, Irvine, CA 92614.
          Complaints regarding the service or requests to receive further
          information regarding use of this service may be sent to the above
          address or to info@planyear.com The Complaint Assistance Unit of the
          Division of Consumer Services of the California Department of Consumer
          Affairs may be contacted in writing at 1625 North Market Boulevard,
          Suite S202, Sacramento, CA 95834 or by telephone at (916) 574-7950 or
          (800) 952-5210.
        </p>
        <p>
          <br />
          <strong>15. Choice of Law and Venue</strong>
          <br />
          Unless required otherwise by Federal or other applicable law, these
          Terms shall be governed by and construed in accordance with the laws
          of the State of California, exclusive of its choice of law rules. You
          agree that any dispute arising under these Terms shall be resolved by
          the state or federal courts sitting in San Francisco County in the
          State of California, and you agree to waive any jurisdictional, venue,
          or inconvenient forum objections to such courts. In the event that any
          of the Terms are held by a court or other tribunal of competent
          jurisdiction to be unenforceable, those provisions shall be limited or
          eliminated to the minimum extent necessary so that these Terms shall
          otherwise remain in full force and effect. Unless prohibited by
          applicable law, we each agree that any dispute resolution proceedings
          will be conducted only on an individual basis and not in a class,
          consolidated or representative action.
        </p>
        <p>
          <br />
          <strong>16. General</strong>
          <br />
          If any provision of these Terms is held to be invalid or
          unenforceable, such provision shall be struck and the remaining
          provisions shall be enforced. Headings are for reference purposes only
          and in no way define, limit, construe or describe the scope or extent
          of such section. PlanYear&apos;s failure to act with respect to any
          failure by you or others to comply with these Terms does not waive
          PlanYear&apos;s right to act with respect to subsequent or similar
          failures. These Terms, which include the Privacy Policy, set forth the
          entire understanding and agreement between you and PlanYear with
          respect to the subject matter hereof. PlanYear reserves the right to
          seek all remedies available at law and in equity for violations of
          these Terms or special terms or rules set forth on the Site, including
          without limitation the right to block access from a particular
          internet address. PlanYear may need to communicate with you from time
          to time regarding these Terms or the Site, Application, or Content.
          PlanYear may provide such notice to you via email to the email address
          you provided to PlanYear or on the Site and/or Application. If you
          have questions regarding these Terms or need to contact PlanYear,
          please see [www.planyear.com/contact] for contact information.
          <br />
          PlanYear may update the Terms and Conditions from time to time without
          notice to you. Your continued use of the Site and/or Application will
          constitute your acceptance of such updates.
        </p>
      </div>
      <Row justify="center">
        <SubmitButton
          type="primary"
          htmlType="button"
          onClick={() => {
            if (isEmployeeLoggedIn) {
              dispatch(updateTermsForIndividual(whoAmI?.individualId));
              dispatch(setWhoAmI({}));
            } else if (isRosterAndPersonal) {
              rosterAndPersonalHandle(registerValues);
            } else {
              handleSignUp(registerValues);
            }
          }}
          className={styles.submitPwdButton}
          disabled={!isButtonActive}
          loading={inProgress}
        >
          Agree to Terms and Conditions
        </SubmitButton>
      </Row>
      <Row justify="center">
        <LinkButton
          className={styles.cancelButton}
          onClick={() => {
            if (isEmployeeLoggedIn) {
              handleLogout();
            } else {
              handleBack();
            }
          }}
        >
          Back
        </LinkButton>
      </Row>
    </>
  );
};

export default Terms;
