import { baseApi } from 'utils/apiUtil';
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  http,
} from 'utils/httpUtil';

const benguideApiV2 = `${baseApi}/v2/benefit-guides`;
const planUrl = `${baseApi}/v1/plans`;
const planUrlV2 = `${baseApi}/v2/plans`;

export const uploadAdditionalResource = (
  benguideId: string,
  file: File | null,
  fileName: string,
  resourceName: string,
  weblink: string,
  id?: string
) => {
  const formData = new FormData();
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
  };
  if (file) {
    formData.append('file', file, fileName);
  }

  if (id) {
    if (file)
      return http.put(
        `${benguideApiV2}/${benguideId}/additional-resources/${id}?resource-name=${resourceName}`,
        formData,
        config
      );

    return http.put(
      `${benguideApiV2}/${benguideId}/additional-resources/${id}?resource-name=${resourceName}${
        weblink ? `&weblink=${weblink}` : ``
      }`
    );
  } else {
    if (file)
      return http.post(
        `${benguideApiV2}/${benguideId}/additional-resources/${id}?resource-name=${resourceName}`,
        formData,
        config
      );
    return http.post(
      `${benguideApiV2}/${benguideId}/additional-resources?resource-name=${resourceName}&weblink=${weblink}`
    );
  }
};

export const removeAdditionalResource = (benguideId: string, id: string) => {
  return http.delete(
    `${benguideApiV2}/${benguideId}/additional-resources/${id}`
  );
};

export const getPlanDocument = async (
  planId: string,
  documentType: string,
  benefitKind: string
) => {
  return await http.get(
    `${planUrl}/${planId}/documents?documentType=${documentType}&benefitKind=${benefitKind}&isOpen=true`,
    { responseType: 'blob' }
  );
};

export const uploadPlanDocuments = (fileName: string, fileBlob: File) => {
  const formData = new FormData();
  formData.append('file', fileBlob, fileName);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
  };
  return http.post(`${planUrlV2}/temp-document`, formData, config);
};

export const removePlanDocument = async (
  id: string,
  documentType: string,
  benefitKind: string,
  planDocumentName?: string
) => {
  // temporary fix for wellbeing generic, need to be changed align with backend
  benefitKind = benefitKind === 'WELLBEING' ? 'WELLBEING_GENERIC' : benefitKind;
  return await http.put(
    `${planUrl}/${id}/documents?benefitKind=${benefitKind}&documentType=${encodeURIComponent(
      documentType
    )}&planDocumentName=${encodeURIComponent(planDocumentName as string)}`
  );
};
