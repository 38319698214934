import SelectSectionName from 'model/SectionName';
import { SectionName } from 'modules/home/constants';

export const DATES_SECTIONS: SelectSectionName[] = [
  {
    title: 'Open Enrollment',
    code: SectionName.DE_OE_TEXT,
  },
  {
    title: 'Rules',
    code: SectionName.DE_RULES_TEXT,
  },
  {
    title: 'Qualifying Life Events',
    code: SectionName.DE_LIFE_EVENTS_TEXT,
  },
  {
    title: 'Waiver Credit',
    code: SectionName.DE_WAIVER_TEXT,
  },
];

/**
 * An object enum to keep track of the different frequencies
 * that waiver credit is applicable
 */
export const WAIVER_CREDIT_FREQUENCY = {
  PayPeriod: { label: 'per pay period' },
  Month: { label: 'per month' },
  Quarter: { label: 'per quarter' },
  Year: { label: 'per year' },
} as const;
