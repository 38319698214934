import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import PlanRecommender from 'model/PlanRecommender';
import { getPlanRecommenderEnableOrDisable } from 'modules/benefitsWellness/services/PlanRecommenderService';

type PlanRecommenderEnableState = {
  inProgress: boolean;
  error: {};
  planRecommender: PlanRecommender;
};

const initialState = {
  inProgress: false,
  error: {},
  planRecommender: {},
} as PlanRecommenderEnableState;

const PlanRecommenderSlice = createSlice({
  name: 'PlanRecommenderEnableSlice',
  initialState,
  reducers: {
    editPlanRecommenderInProgress(state) {
      state.inProgress = true;
    },
    editPlanRecommenderCompleted: (state, action) => {
      state.inProgress = false;
      state.planRecommender = action.payload;
    },
    editPlanRecommenderFailed(state, action) {
      state.inProgress = false;
      state.error = action.payload;
    },
    getPlanRecommenderInProgress(state) {
      state.inProgress = true;
    },
    getPlanRecommenderCompleted: (state, action) => {
      state.inProgress = false;
      state.planRecommender = action.payload;
    },
    getPlanRecommenderFailed(state, action) {
      state.inProgress = false;
      state.error = action.payload;
      state.planRecommender = {} as PlanRecommender;
    },
  },
});

export default PlanRecommenderSlice.reducer;

export const {
  editPlanRecommenderInProgress,
  editPlanRecommenderCompleted,
  editPlanRecommenderFailed,
  getPlanRecommenderInProgress,
  getPlanRecommenderCompleted,
  getPlanRecommenderFailed,
} = PlanRecommenderSlice.actions;

export const getPlanRecommenderStatus = (benguideId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(editPlanRecommenderInProgress());
      const response = await getPlanRecommenderEnableOrDisable(benguideId);
      const data = response.data;
      dispatch(getPlanRecommenderCompleted(data));
    } catch (error) {
      dispatch(getPlanRecommenderFailed(error));
    }
  };
};
