import { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';

const Typewriter = ({
  text,
  speed = 40,
  onTypingEnd,
  scrollIntoView,
}: {
  text: string;
  speed?: number;
  onTypingEnd?: () => void;
  scrollIntoView: () => void;
}) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  const words = text.split(' ');

  useEffect(() => {
    if (index < words.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) =>
          prev ? prev + ' ' + words[index] : words[index]
        );
        setIndex(index + 1);
        scrollIntoView?.();
      }, speed);
      return () => clearTimeout(timeoutId);
    } else if (onTypingEnd) {
      onTypingEnd?.();
    }
  }, [index, words, speed, onTypingEnd, scrollIntoView]);

  return (
    <>
      <Markdown>{displayedText}</Markdown>
    </>
  );
};

export default Typewriter;
