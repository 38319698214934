import { FC } from 'react';

import { ModalProps, Row } from 'antd';
import notebookIcon from 'images/icons/notebook.svg';

import styles from './errorBoundary.module.less';

interface Props extends ModalProps {
  lineOne?: string;
  lineTwo?: string;
}

const ErrorBoundary: FC<Props> = (props: Props) => {
  const { lineOne = '', lineTwo = '' } = props;

  return (
    <div
      className={`${styles.errorBoundaryWrapper} ${
        lineOne ? `${styles.noLeftMargin}` : ''
      }`}
    >
      <Row className={styles.detailsWrapper}>
        <img src={notebookIcon} alt="edit" />
      </Row>
      <Row className={styles.header}>It’s quiet in here</Row>
      <Row className={styles.subHeader}>
        <span className={styles.firstLine}>
          {lineOne ? lineOne : 'This page is no longer available.'}
        </span>
        <br />
        <span>
          {lineTwo ? lineTwo : 'Please contact your system administrator.'}
        </span>
      </Row>
    </div>
  );
};

export default ErrorBoundary;
