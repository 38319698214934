import { ReactNode, memo } from 'react';
import { Row, Button, Col, Tooltip } from 'antd';
import { isString } from 'lodash';

import { ReactComponent as CopyIcon } from 'images/icon-copy.svg';
import styles from './feedback.module.less';

interface FeedbackProps {
  content: ReactNode;
}

interface Control {
  label: string;
  onClick: () => void;
  icon: ReactNode;
}

const Feedback = memo(({ content }: FeedbackProps) => {
  const handleCopy = (content: ReactNode) => () => {
    if (isString(content)) {
      navigator.clipboard.writeText(content);
    }
  };

  const controls: Control[] = [
    {
      label: 'Copy',
      onClick: handleCopy(content),
      icon: <CopyIcon />,
    },
  ];

  return (
    <Row gutter={1} className={styles.feedbackRow}>
      {controls.map((control, index) => (
        <Col key={index}>
          <Tooltip placement="bottom" title={control.label}>
            <Button
              onClick={control.onClick}
              icon={control.icon}
              aria-label={control.label}
              className={styles.feedbackButton}
            />
          </Tooltip>
        </Col>
      ))}
    </Row>
  );
});

Feedback.displayName = 'Feedback';

export default Feedback;
