import { Dispatch } from '@reduxjs/toolkit';
import { AuthErrorCodes } from 'modules/auth/constants/errorCodes';
import {
  setAuthAlert,
  setAuthView,
} from 'modules/auth/slices/employeeAuthSlice';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';

export const handleErrorCode = (dispatch: Dispatch, errorResponse: any) => {
  const errorCode = errorResponse?.code;

  if (errorResponse?.error) {
    switch (errorResponse?.error) {
      case AuthErrorCodes.INVALID_CREDENTIALS.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.INVALID_CREDENTIALS.type,
            message: AuthErrorCodes.INVALID_CREDENTIALS.message,
          })
        );
        break;
      case AuthErrorCodes.EMPLOYEE_NOT_FOUND.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.EMPLOYEE_NOT_FOUND.type,
            message: AuthErrorCodes.EMPLOYEE_NOT_FOUND.message,
          })
        );
        break;
      case AuthErrorCodes.LOGIN_COMPROMISED.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.LOGIN_COMPROMISED.type,
            message: AuthErrorCodes.LOGIN_COMPROMISED.message,
          })
        );
        break;
      default:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.UNKNOWN_ERROR.type,
            message: AuthErrorCodes.UNKNOWN_ERROR.message,
          })
        );
        break;
    }
  } else {
    switch (errorCode) {
      case AuthErrorCodes.SSO_EXISITS.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.SSO_EXISITS.type,
            message: AuthErrorCodes.SSO_EXISITS.message,
          })
        );
        break;
      case AuthErrorCodes.INVALID_DOMAIN.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.INVALID_DOMAIN.type,
            message: AuthErrorCodes.INVALID_DOMAIN.message,
          })
        );
        break;
      case AuthErrorCodes.PERMISSION_DENIED.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.PERMISSION_DENIED.type,
            message: AuthErrorCodes.PERMISSION_DENIED.message,
          })
        );
        break;
      case AuthErrorCodes.INTERNAL_ERROR.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.INTERNAL_ERROR.type,
            message: AuthErrorCodes.INTERNAL_ERROR.message,
          })
        );
        break;
      case AuthErrorCodes.PLAN_NOT_FOUND.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.PLAN_NOT_FOUND.type,
            message: AuthErrorCodes.PLAN_NOT_FOUND.message,
          })
        );
        break;
      case AuthErrorCodes.INVALID_EMAIL.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.INVALID_EMAIL.type,
            message: AuthErrorCodes.INVALID_EMAIL.message,
          })
        );
        break;
      case AuthErrorCodes.LOGIN_EXISTS.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.LOGIN_EXISTS.type,
            message: AuthErrorCodes.LOGIN_EXISTS.message,
          })
        );
        break;
      case AuthErrorCodes.ER_ADMIN_EXISTS.code:
        dispatch(setAuthView(AUTH_VIEW.ER_ADMIN.type));
        break;
      case AuthErrorCodes.INVALID_LOGIN_TYPE.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.INVALID_LOGIN_TYPE.type,
            message: AuthErrorCodes.INVALID_LOGIN_TYPE.message,
          })
        );
        break;
      case AuthErrorCodes.EMPLOYEE_SIGN_ERROR.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.EMPLOYEE_SIGN_ERROR.type,
            message: AuthErrorCodes.EMPLOYEE_SIGN_ERROR.message,
          })
        );
        break;
      case AuthErrorCodes.TOKEN_NOT_FOUND.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.TOKEN_NOT_FOUND.type,
            message: AuthErrorCodes.TOKEN_NOT_FOUND.message,
          })
        );
        break;
      case AuthErrorCodes.TOKEN_EXPIRED.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.TOKEN_EXPIRED.type,
            message: AuthErrorCodes.TOKEN_EXPIRED.message,
          })
        );
        break;
      case AuthErrorCodes.TOKEN_EXPIRED_RESET.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.TOKEN_EXPIRED_RESET.type,
            message: AuthErrorCodes.TOKEN_EXPIRED_RESET.message,
          })
        );
        break;
      case AuthErrorCodes.TOKEN_CLAIMED.code:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.TOKEN_CLAIMED.type,
            message: AuthErrorCodes.TOKEN_CLAIMED.message,
          })
        );
        break;
      default:
        dispatch(
          setAuthAlert({
            type: AuthErrorCodes.UNKNOWN_ERROR.type,
            message: AuthErrorCodes.UNKNOWN_ERROR.message,
          })
        );
        break;
    }
  }
};

export const clearURLParams = () => {
  if (window.history.pushState) {
    const newURL =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newURL }, '', newURL);
  }
};
