import {
  FC,
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
  ReactNode,
  useMemo,
} from 'react';

import Cropper from 'react-easy-crop';
// eslint-disable-next-line import/no-unresolved
import { Area, Point, Size } from 'react-easy-crop/types';
import { Button, Modal, Slider, Typography, Upload } from 'antd';
import Text from 'antd/lib/typography/Text';
import { LoadingOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import { getCroppedImg } from 'components/ImageUploader/canvasUtils';
import {
  cropShapeTypes,
  ERROR_MESSAGE_PLAN_EDITING,
} from 'constants/commonConstants';
import closeIcon from 'images/icon-close.svg';
import uploadIcon from 'images/icon-upload.svg';
import { NotificationContext } from 'context/NotificationContext';
import {
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import { useStateCallback } from 'hooks/useStateCallBack';

import styles from './imageUploader.module.less';

export const FORMAT_VALIDATE: string = 'FORMAT';
export const SIZE_VALIDATE: string = 'SIZE';

const ACCEPTED_FILE_TYPES = ['.png', '.jpg', '.jpeg'];

type ImageUploaderProps = {
  description?: string;
  cropShape: 'rect' | 'round';
  aspect: number; // rect {4 / 3} / round {1}
  loading?: boolean;
  hideUploader?: boolean;
  onConfirm: (image: string, originalImage: string, croppedArea: Area) => void;
  uploadedImage: string | undefined;
  showOperations?: boolean;
  isOpenImageTray?: boolean;
  onCloseImgTray?: Function;
  title?: string;
  helpText?: string;
  uploadNewImgText?: string;
  uploadImgAfterResetText?: string;
  dataCyUpload?: string;
  dataCySave?: string;
  customCropper?: Size;
  defaultLogo?: string;
  customUploaderSize?: number;
  imagePreviewStyle?: string;
  uploadWrapperClass?: string;
  getImageName?: Function;
  ref?: React.Ref<any>;
  maxSizeMB?: number;
  cropperHelperText?: string;
  objectFit?: 'contain' | 'horizontal-cover' | 'vertical-cover';
  defaultCropArea?: Area;
  unCroppedImage?: string;
  onRemove?: () => void;
  defaultImageCropArea?: Area;
  preventCloseOnConfirm?: boolean;
  saving?: boolean;
};

const { RECT, ROUND } = cropShapeTypes;

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 2;
const DEFAULT_ZOOM = 1;
const ZOOM_STEP = 0.05;

const ImageUploader: FC<ImageUploaderProps> = forwardRef(
  (props: ImageUploaderProps, ref) => {
    const {
      description,
      cropShape,
      aspect,
      onConfirm,
      loading,
      uploadedImage,
      hideUploader = false,
      showOperations = false,
      title,
      helpText,
      onCloseImgTray,
      isOpenImageTray,
      uploadNewImgText = '+ Upload New Image',
      uploadImgAfterResetText = 'Upload Background',
      dataCyUpload,
      dataCySave,
      customCropper,
      defaultLogo,
      customUploaderSize,
      imagePreviewStyle = styles.logoWrapper,
      uploadWrapperClass = '',
      getImageName,
      maxSizeMB = 2,
      cropperHelperText = 'Fit photo within constraints above.',
      objectFit = 'contain',
      defaultCropArea,
      unCroppedImage,
      onRemove,
      defaultImageCropArea,
      preventCloseOnConfirm,
      saving,
    } = props;

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [imageSrc, _setImageSrc] = useStateCallback('');
    const setImageSrc = useCallback(
      (data: string, func?: Function) => {
        if (!saving) {
          _setImageSrc(data, func);
        }
      },
      [_setImageSrc, saving]
    );
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
    const [isDefaultImage, setResetDefault] = useState<boolean>(false);
    const [imageValidateSetting, setImageValidateSetting] =
      useState<string>('');
    const [isNotificationReceived, setNotificationReceived] =
      useState<boolean>(false);
    const notificationFlag = useContext(NotificationContext);
    const upClient = useUPClient();
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    });
    const [initialCropArea, setInitialCropArea] = useState<Area | null>(null);

    const setNotificationReceiveFlag = useCallback(() => {
      if (notificationFlag) {
        setNotificationReceived(true);
      }
    }, [notificationFlag]);

    useImperativeHandle(
      ref,
      () => ({
        clearImageSrc() {
          setImageSrc('');
          setImageValidateSetting('');
        },
        close() {
          setIsModalOpen(false);
          onCloseImgTray && onCloseImgTray();
        },
      }),
      [setImageSrc, onCloseImgTray]
    );
    useEffect(() => {
      if (!isModalOpen) {
        setImageValidateSetting('');
      }
      setNotificationReceiveFlag();
    }, [isModalOpen, setNotificationReceiveFlag]);

    useEffect(() => {
      if (isOpenImageTray) {
        setIsModalOpen(true);
      }
    }, [isOpenImageTray]);

    useEffect(() => {
      if (unCroppedImage && isModalOpen) {
        setImageSrc(unCroppedImage);
      }
      if (!unCroppedImage && defaultLogo && isModalOpen && uploadedImage) {
        setImageSrc(defaultLogo);
      }
    }, [setImageSrc, unCroppedImage, isModalOpen, defaultLogo, uploadedImage]);

    useEffect(() => {
      if (defaultCropArea) {
        setInitialCropArea(defaultCropArea);
      }
    }, [defaultCropArea]);

    const beforeUpload = (file: File) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        if (onValidateFails) {
          onValidateFails(FORMAT_VALIDATE);
        }
        return false;
      }
      const isMaxSizeExceeded = file.size / 1024 / 1024 < maxSizeMB;
      if (!isMaxSizeExceeded) {
        if (onValidateFails) {
          onValidateFails(SIZE_VALIDATE);
        }
        return false;
      }
      getImageName && getImageName(file.name);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setInitialCropArea(null);
        setCrop({ x: 0, y: 0 });
        if (reader.result !== null) {
          const blob = new Blob([reader.result], {
            type: 'text/plain; charset=utf-8',
          });
          blob.text().then((text) =>
            setImageSrc(text, () => {
              setIsModalOpen(true);
            })
          );
          setImageValidateSetting('');
        }
      });
      reader.readAsDataURL(file);
      setIsModalOpen(true);
      setResetDefault(false);
      return false;
    };

    const handleCancel = () => {
      setZoom(DEFAULT_ZOOM);
      setIsModalOpen(false);
      setResetDefault(false);
      setImageSrc('');
      defaultCropArea && setInitialCropArea(defaultCropArea);
      onCloseImgTray && onCloseImgTray();
      if (notificationFlag) {
        upClient.postMessage({
          channel: BENGUIDE_NOTIFICATIONS,
          event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
          data: {},
        });
      }
    };

    const cropSize = useMemo(() => {
      if (cropShape === RECT) {
        if (hideUploader) {
          if (customCropper) {
            return customCropper;
          }
          return { width: 250, height: 350 } as Size;
        }
        return { width: 625, height: 450 } as Size;
      } else {
        return { width: 300, height: 300 } as Size;
      }
    }, [cropShape, hideUploader, customCropper]);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
      if (!imageSrc && onRemove) {
        onRemove();
        setIsModalOpen(false);
        return;
      }
      try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        if (croppedImage) {
          onConfirm(croppedImage, imageSrc, croppedAreaPixels);
        }
        if (preventCloseOnConfirm) {
          return;
        }
        onCloseImgTray && onCloseImgTray();
        setIsModalOpen(false);
      } catch (e) {
        console.error(e);
      }
    }, [
      imageSrc,
      croppedAreaPixels,
      onConfirm,
      onCloseImgTray,
      onRemove,
      preventCloseOnConfirm,
    ]);

    const onResetSelectedImage = () => {
      setResetDefault(true);
      setCrop({ x: 0, y: 0 });
      setCroppedAreaPixels({ width: 0, height: 0, x: 0, y: 0 });
      setZoom(DEFAULT_ZOOM);
      if (defaultLogo) {
        setImageSrc(defaultLogo);
      } else {
        setImageSrc('');
      }
      if (defaultImageCropArea) {
        setInitialCropArea(defaultImageCropArea);
      } else {
        setInitialCropArea(null);
      }
    };

    const uploadButton = (
      <div>
        {isModalOpen && !hideUploader ? (
          <LoadingOutlined />
        ) : cropShape === ROUND ? (
          <div className={styles.uploadBtnWrapper}>
            <SubmitButton
              type="dashed"
              className={styles.uploadBtnCircle}
              dataCy={dataCyUpload}
            >
              + Upload
            </SubmitButton>
          </div>
        ) : (
          <div className={styles.uploadBtnWrapper}>
            <SubmitButton
              type="dashed"
              className={styles.uploadBtnSquare}
              dataCy={dataCyUpload}
            >
              {hideUploader ? '' : '+ Upload'}
            </SubmitButton>
          </div>
        )}
      </div>
    );

    const imagePreview = (
      <>
        {loading ? (
          <LoadingOutlined />
        ) : (
          uploadedImage && (
            <img
              src={uploadedImage}
              alt=""
              className={
                cropShape === ROUND
                  ? styles.imageStyleCircle
                  : hideUploader
                  ? customCropper
                    ? imagePreviewStyle
                    : styles.imageVerticalStreched
                  : styles.imageStyleRect
              }
            />
          )
        )}
      </>
    );

    const { Paragraph } = Typography;

    const uploadPhotoTitle = (
      <div>
        <div>
          <div className={styles.title}>{title || 'Upload Photo'}</div>
        </div>
        <br />
        <div className={styles.descriptionContainer}>
          <Paragraph className={styles.center}>{description}</Paragraph>
        </div>
      </div>
    );

    const isSmallActionWrapper =
      hideUploader && customUploaderSize && customUploaderSize < 600;

    const footer = (
      <div>
        {showOperations && (
          <div
            className={
              isSmallActionWrapper ? styles.operationsHandle : styles.operations
            }
          >
            <div className={styles.uploadNewImgWrapper}>
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  setZoom(DEFAULT_ZOOM);
                  beforeUpload(file);
                }}
                customRequest={() => null}
                className={styles.uploadNewContainer}
                accept={ACCEPTED_FILE_TYPES.join(',')}
              >
                <SubmitButton
                  className={
                    isSmallActionWrapper
                      ? styles.smallActionBtn
                      : styles.actionButton
                  }
                  disabled={notificationFlag || saving}
                >
                  {uploadNewImgText}
                </SubmitButton>
              </Upload>
            </div>
            <div className={styles.resetImgWrapper}>
              <SubmitButton
                className={
                  isSmallActionWrapper
                    ? styles.smallActionBtn
                    : styles.actionButton
                }
                onClick={onResetSelectedImage}
                disabled={notificationFlag || saving}
              >
                Reset to Default
              </SubmitButton>
            </div>
          </div>
        )}
        <SubmitButton
          className={
            isSmallActionWrapper ? styles.smallSaveBtn : styles.saveButton
          }
          key="submit"
          type="primary"
          onClick={showCroppedImage}
          disabled={
            (isOpenImageTray && !(uploadedImage || imageSrc)) ||
            (!imageSrc && !isDefaultImage) ||
            notificationFlag
          }
          block
          dataCy={dataCySave}
          loading={saving}
        >
          Save
        </SubmitButton>
      </div>
    );

    const showImagePreview = () => {
      if (!hideUploader) {
        setIsModalOpen(true);
      }
    };

    const onValidateFails = (validateSetting: string) => {
      setImageValidateSetting(validateSetting);
    };

    const closeAlert = () => {
      if (!isEmpty(imageValidateSetting)) {
        setImageValidateSetting('');
      }
    };

    const getImageValidationErrorMsg = (validateSetting: string): ReactNode => {
      switch (validateSetting) {
        case FORMAT_VALIDATE:
          return 'This image file type is not supported.';
        case SIZE_VALIDATE:
          return 'Maximum size allowed for this upload is 2 MB.';
        default:
          setImageValidateSetting('');
          return;
      }
    };

    const imgDiv = <div onClick={showImagePreview}>{imagePreview}</div>;

    return (
      <div
        className={`${
          hideUploader && !uploadedImage
            ? styles.nonVisibleUploader
            : styles.uploadWrapper
        } ${uploadWrapperClass}`}
      >
        {uploadedImage ? (
          imgDiv
        ) : (
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            // disables upload action http request
            customRequest={() => null}
            accept={ACCEPTED_FILE_TYPES.join(',')}
            className={styles.primaryUploader}
          >
            {uploadButton}
          </Upload>
        )}

        {helpText && !uploadedImage && (
          <Text type="secondary" className={styles.helpText}>
            {helpText}
          </Text>
        )}
        <Modal
          width={customUploaderSize || 840}
          className={styles.imgModalWrapper}
          visible={isModalOpen}
          onCancel={() => {
            if (!saving) {
              handleCancel();
            }
          }}
          title={uploadPhotoTitle}
          onOk={showCroppedImage}
          footer={footer}
          closeIcon={<img src={closeIcon} alt="close-icon" />}
          maskClosable={false}
          destroyOnClose
          // disabled open animation to fix issue with image cropper alignment
          transitionName=""
        >
          <div className={styles.alertMessageWrapper}>
            {imageValidateSetting && (
              <AlertMessage
                type="error"
                message={getImageValidationErrorMsg(imageValidateSetting)}
                closeAlert={() => {
                  closeAlert();
                }}
              />
            )}
          </div>
          {isNotificationReceived && (
            <FixedAlertMessage
              type={'error'}
              message={ERROR_MESSAGE_PLAN_EDITING}
            />
          )}
          <div className={styles.cropContainer}>
            {imageSrc || (uploadedImage && isOpenImageTray) ? (
              <Cropper
                cropSize={cropSize}
                cropShape={cropShape}
                showGrid={false}
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                classes={{
                  containerClassName: styles.cropContainer,
                }}
                objectFit={objectFit}
                restrictPosition={false}
                initialCroppedAreaPixels={initialCropArea || undefined}
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
              />
            ) : (
              <div className={styles.uploadNewContainer}>
                <Upload
                  showUploadList={false}
                  beforeUpload={(file) => {
                    setZoom(DEFAULT_ZOOM);
                    beforeUpload(file);
                  }}
                  customRequest={() => null}
                  className={styles.uploadNewContainer}
                  accept={ACCEPTED_FILE_TYPES.join(',')}
                >
                  <div className={styles.uploadNewInner}>
                    <img src={uploadIcon} alt="upload-icon" />
                    <h4 className={styles.uploadNewImgText}>
                      {uploadImgAfterResetText}
                    </h4>
                  </div>
                </Upload>
              </div>
            )}
          </div>
          <div className={`${styles.center} ${styles.cropperHelperText}`}>
            {cropperHelperText}
          </div>
          <section className={styles.controls}>
            <Button
              type="text"
              onClick={() => setZoom(zoom - ZOOM_STEP)}
              disabled={zoom - ZOOM_STEP < MIN_ZOOM}
            >
              －
            </Button>
            <div className={styles.slider}>
              <Slider
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                step={ZOOM_STEP}
                onChange={(zoom: number) => setZoom(zoom)}
                value={zoom}
              />
            </div>
            <Button
              type="text"
              onClick={() => setZoom(zoom + ZOOM_STEP)}
              disabled={zoom + ZOOM_STEP > MAX_ZOOM}
            >
              ＋
            </Button>
          </section>
        </Modal>
      </div>
    );
  }
);
ImageUploader.displayName = 'ImageUploader';
export default ImageUploader;
