import { FC, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'antd';

import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useBenguide from 'hooks/benguide';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import OverviewEditor from 'modules/home/components/OverviewEditor/OverviewEditor';
import ExploreBenefitEditor from 'modules/home/components/ExploreBenefitEditor/ExploreBenefitEditor';
import BenefitsEnrollEditor from 'modules/home/components/BenefitsEnrollEditor/BenefitsEnrollEditor';
import RouteMap from 'routes/RouteMap';

import {
  getDefaultRichMediaOverview,
  getDefaultRichMediaBenefit,
  resetDefaultText,
  handleSectionImageUpload,
  handleSectionImageDelete,
  createBenefitEnrollLink,
  getHomeContentPage,
} from 'modules/home/slices/homeSlice';
import {
  SectionName,
  CHECKLIST_OPTIONS,
  Section,
} from 'modules/home/constants';

import ImageUploader from 'components/ImageUploader/ImageUploader';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import { benguideApiV2 } from 'utils/apiUtil';
import { addHttp } from 'utils/httpUtil';

import imagePlaceholder from 'images/icons/bitmap.svg';
import editIcon from 'images/icon-edit.svg';
import removeIcon from 'images/icons/icon-bin.svg';
import defaultOverviewImage from 'images/samples/default-overview.png';
import { ReactComponent as NewHire } from 'images/samples/new-hire-checklist.svg';
import { ReactComponent as Insurance } from 'images/samples/how-insurance-works.svg';
import { ReactComponent as DatesEligibility } from 'images/samples/dates-eligibility.svg';
import { ReactComponent as PlanRecommenderIcon } from 'images/samples/plan-recommender.svg';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import PageSection from 'model/PageSection';
import { getBase64Image } from 'utils/screenshotUtil';
import PlanRecommenderPopup from 'modules/benefitsWellness/components/PlanRecommenderPopup/PlanRecommenderPopup';

import styles from './homeTextWrapper.module.less';

type HomeTextWrapperProps = {
  isEditMode?: boolean;
  isOverview: boolean;
  title: string;
  initialTextOverview?: string | undefined;
  initialTextBenefit?: string | undefined;
  benguideData?: any; // Benguide object might differ
  overviewImage?: Blob;
  checkHomeImageLoad?: () => void;
};

const HomeTextWrapper: FC<HomeTextWrapperProps> = ({
  isOverview,
  initialTextOverview,
  initialTextBenefit,
  benguideData,
  isEditMode,
  checkHomeImageLoad = () => {},
}: HomeTextWrapperProps) => {
  const search = location.search;
  const previewToken = new URLSearchParams(search).get('previewToken');
  const appendToken = previewToken ? `previewToken=${previewToken}` : '';
  const textRef = useRef<HTMLDivElement>(null);
  const [textWdith, setTextWidth] = useState<number>(0);
  const [editorContentOverview, setEditorContentOverview] = useState<
    string | undefined
  >('');
  const [editorContentBenefit, setEditorContentBenefit] = useState<
    string | undefined
  >('');
  const [isResetEnabledOverview, setEnableResetOverview] =
    useState<boolean>(false);
  const [isResetEnabledBenefit, setEnableResetBenefit] =
    useState<boolean>(false);
  const [enrollmentLink, setEnrollmentLink] = useState<string>('');
  const dispatch = useAppDispatch();
  const {
    defaultTextOverview,
    defaultTextBenefit,
    contentOverview,
    titleOverview,
    titleBenefit,
    contentBenefit,
    benefitButton,
    homeContentPage,
  } = useAppSelector((state) => state.home);
  const {
    masterId = '',
    defaultOverview = '',
    defaultChecklist = '',
  } = benguideData || {};
  const { isTablet } = useAppSelector((state) => state.layout);
  const employerName = useAppSelector(
    (state) => state.basicInfo.layoutBenefitGuide?.employerName
  );

  const { checkList, benefitEnrollLink = '' } = useAppSelector(
    (state) => state.home
  );
  const { medical } = useAppSelector((state: any) => state);
  const { medicalSection } = medical || {};
  const { plans: medicalPlans = [] } = medicalSection || {};
  const [homeImg, setHomeImg] = useState<string>('');
  const [originalHomeImg, setOriginalHomeImg] = useState<string>('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const imageUploderRef = useRef<any>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [visibleRecommenderPopup, setVisibleRecommenderPopup] = useState(false);

  const {
    revision,
    planRecommenderEnabled,
    latestRevision,
    newHireCheckList,
    howInsuranceWorks,
    datesAndEligibility,
  } = useBenguide();

  const sectionImageCrop = useMemo(() => {
    if (homeContentPage && homeContentPage.sections) {
      const homeImgSection = homeContentPage.sections.find(
        (section: PageSection) => section.name === SectionName.HOME_MAIN_IMAGE
      );

      return homeImgSection
        ? homeImgSection.data.originalImageCropParams
        : null;
    }
    return null;
  }, [homeContentPage]);

  useEffect(() => {
    if (homeContentPage && homeContentPage.sections) {
      const homeImgSection = homeContentPage.sections.find(
        (section: PageSection) => section.name === SectionName.HOME_MAIN_IMAGE
      );

      if (homeImgSection?.data?.originalImageReference) {
        getBase64Image(
          `${benguideApiV2}/${masterId}/images?section-name=${
            SectionName.HOME_MAIN_IMAGE
          }&original=true&revision=${latestRevision || revision}`
        ).then((data) => {
          setOriginalHomeImg(data as string);
        });
      } else {
        setOriginalHomeImg('');
      }
    }
  }, [masterId, homeContentPage, revision, latestRevision]);

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.clientWidth);
    }
  }, [textRef]);

  useEffect(() => {
    if (homeContentPage?.sections) {
      const homeImgSection = homeContentPage.sections.find(
        (section: PageSection) => section.name === SectionName.HOME_MAIN_IMAGE
      );

      if (homeImgSection?.data?.imageReference) {
        getBase64Image(
          `${benguideApiV2}/${masterId}/images?section-name=${
            SectionName.HOME_MAIN_IMAGE
          }&revision=${latestRevision || revision}`,
          false,
          true
        )
          .then((data) => {
            setHomeImg(data as string);
            checkHomeImageLoad();
          })
          .catch(() => {});
        if (loadingImage) {
          imageUploderRef?.current?.close();
          setLoadingImage(false);
        }
      } else {
        setHomeImg('');
      }
    }
    // No need call when change loadingImage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterId, revision, homeContentPage, latestRevision]);

  const updateEditorContentOverview = (data: string) => {
    setEditorContentOverview(data);
  };

  const updateEditorContentBenefit = (data: string) => {
    setEditorContentBenefit(data);
  };

  const resetToDefault = () => {
    if (isOverview) {
      dispatch(
        getDefaultRichMediaOverview(masterId, SectionName.HOME_OVERVIEW_TEXT)
      );
      setEnableResetOverview(false);
    } else {
      dispatch(
        getDefaultRichMediaBenefit(
          masterId,
          SectionName.HOME_BENEFIT_GUIDE_DESCRIPTION
        )
      );
      dispatch(getHomeContentPage(masterId, Section.HOME, revision));
      setEnableResetBenefit(false);
    }
  };

  const disableResetter = (disable: boolean) => {
    if (
      !disable ||
      (!isOverview &&
        editorContentBenefit &&
        editorContentBenefit !== defaultChecklist) ||
      isEmpty(editorContentBenefit)
    ) {
      setEnableResetBenefit(true);
    } else {
      setEnableResetBenefit(false);
    }
  };

  const contentUpdateOverview = useCallback(() => {
    setEnableResetOverview(true);
    dispatch(resetDefaultText());
  }, [dispatch]);

  const contentUpdateBenefit = useCallback(() => {
    setEnableResetBenefit(true);
    dispatch(resetDefaultText());
  }, [dispatch]);

  const resetCurrentValueOverview = () => {
    if (!isEmpty(contentOverview)) {
      setEditorContentOverview(contentOverview);
    } else {
      setEditorContentOverview(initialTextOverview);
    }
  };

  const resetCurrentValueBenefit = () => {
    if (!isEmpty(contentBenefit)) {
      setEditorContentBenefit(contentBenefit);
    } else {
      setEditorContentBenefit(initialTextBenefit);
    }
  };

  useEffect(() => {
    if (
      (isOverview &&
        editorContentOverview &&
        editorContentOverview !== defaultOverview) ||
      isEmpty(editorContentOverview)
    ) {
      contentUpdateOverview();
    } else {
      setEnableResetOverview(false);
      dispatch(resetDefaultText());
    }
  }, [
    editorContentOverview,
    isOverview,
    defaultOverview,
    contentUpdateOverview,
    setEnableResetOverview,
    dispatch,
  ]);

  useEffect(() => {
    if (
      (!isOverview &&
        editorContentBenefit &&
        editorContentBenefit !== defaultChecklist) ||
      isEmpty(editorContentBenefit)
    ) {
      contentUpdateBenefit();
    } else {
      setEnableResetBenefit(false);
      dispatch(resetDefaultText());
    }
  }, [
    editorContentBenefit,
    isOverview,
    defaultChecklist,
    contentUpdateBenefit,
    setEnableResetBenefit,
    dispatch,
  ]);

  useEffect(() => {
    if (!isEmpty(defaultTextOverview)) {
      setEditorContentOverview(defaultTextOverview);
    }
  }, [defaultTextOverview]);

  useEffect(() => {
    if (!isEmpty(defaultTextBenefit)) {
      setEditorContentBenefit(defaultTextBenefit);
    }
  }, [defaultTextBenefit]);

  useEffect(() => {
    if (contentOverview !== initialTextOverview || !isEmpty(contentOverview)) {
      setEditorContentOverview(contentOverview);
    }
  }, [contentOverview, initialTextOverview]);

  useEffect(() => {
    if (contentBenefit !== initialTextBenefit || !isEmpty(contentBenefit)) {
      setEditorContentBenefit(contentBenefit);
    }
  }, [contentBenefit, initialTextBenefit]);

  useEffect(() => {
    if (!isEmpty(benefitEnrollLink)) {
      setEnrollmentLink(benefitEnrollLink);
    }
  }, [benefitEnrollLink]);

  const changeEnrollmentLink = (link: string, isChecked: boolean) => {
    setEnrollmentLink(link);
    dispatch(
      createBenefitEnrollLink(
        masterId,
        SectionName.BENEFITS_ENROLL_BUTTON,
        link,
        isChecked
      )
    );
  };

  const handleImageUpload = (
    image: string,
    originalImage?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    setLoadingImage(true);
    dispatch(
      handleSectionImageUpload(
        masterId,
        image,
        SectionName.HOME_MAIN_IMAGE,
        originalImage,
        cropArea
      )
    );
  };

  const onRemoveImage = () => {
    dispatch(handleSectionImageDelete(masterId, SectionName.HOME_MAIN_IMAGE));
    setHomeImg('');
    setIsConfirmModalOpen(false);
    imageUploderRef.current.clearImageSrc();
  };

  const isOpenRemoveConfirmation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsConfirmModalOpen(true);
  };

  const handleOpenPlanRecommender = () => {
    setVisibleRecommenderPopup(false);
    const { urlHash } = benguideData;
    window.open(
      `/${urlHash}/${employerName}/plan-recommender/medical?revision=${latestRevision}&${appendToken}`,
      '_blank'
    );
  };

  return (
    <div
      className={`${styles.contentWrapper} ${homeImg ? '' : styles.noImage}`}
    >
      <div className={styles.lineBorder}></div>
      {isOverview && (
        <div
          className={
            isEmpty(homeImg)
              ? styles.addImgWrapper
              : styles.overviewImageWrapper
          }
        >
          {homeImg && (
            <img
              src={imagePlaceholder}
              className={styles.imgPlaceholder}
              alt="dots"
            />
          )}
          <div
            className={
              homeImg
                ? `${styles.homeImgWrapper} ${
                    isEditMode ? styles.isEditWrapper : ''
                  }`
                : ''
            }
          >
            <EditableHotspot
              alwaysVisible={!homeImg}
              overlayComponent={
                homeImg && (
                  <>
                    <div className={styles.editImageBtn}>
                      Edit Image
                      <img src={editIcon} alt="edit" />
                    </div>
                    <div
                      className={styles.removeImgBtn}
                      onClick={isOpenRemoveConfirmation}
                    >
                      Remove
                      <img src={removeIcon} alt="remove" />
                    </div>
                  </>
                )
              }
              customModal={(visible: boolean, onClose: Function) => (
                <ImageUploader
                  title="Home Screen Image"
                  cropShape="rect"
                  uploadedImage={homeImg}
                  aspect={1}
                  loading={false}
                  onConfirm={handleImageUpload}
                  showOperations
                  uploadNewImgText="+ Upload New Image"
                  isOpenImageTray={visible}
                  onCloseImgTray={onClose}
                  uploadImgAfterResetText="Upload Image"
                  hideUploader={true}
                  defaultLogo={defaultOverviewImage}
                  customUploaderSize={520}
                  ref={imageUploderRef}
                  defaultCropArea={sectionImageCrop}
                  unCroppedImage={originalHomeImg}
                  preventCloseOnConfirm={true}
                  saving={loadingImage}
                />
              )}
            >
              {isEmpty(homeImg) && isEditMode && (
                <div className={styles.linkHotspot}>
                  <span>Add Image</span>
                  <img src={editIcon} alt="edit" />
                </div>
              )}
            </EditableHotspot>
          </div>
          <ConfirmationDialog
            visible={isConfirmModalOpen}
            flipFooterOrder={true}
            title="Remove Image"
            cancelText="Cancel"
            okText="Yes - Remove Image"
            onCancel={() => setIsConfirmModalOpen(false)}
            onOk={onRemoveImage}
          >
            Are you sure you want to remove this image?
          </ConfirmationDialog>
        </div>
      )}
      <div
        className={styles.contentBody}
        style={{ width: (homeImg || isEditMode) && !isTablet ? '75%' : '100%' }}
      >
        <EditableHotspot
          alwaysVisible={false}
          editText="Edit Text"
          editIcon={<img src={editIcon} alt="edit" />}
          customModal={(visible: boolean, onClose: Function) => (
            <ExternalEditableModal
              isOverview={isOverview}
              visible={visible}
              onClose={onClose}
              textWdith={textWdith}
              editorContentOverview={editorContentOverview}
              editorContentBenefit={editorContentBenefit}
              initialTextOverview={initialTextOverview}
              initialTextBenefit={initialTextBenefit}
              resetToDefault={resetToDefault}
              isResetEnabledOverview={isResetEnabledOverview}
              isResetEnabledBenefit={isResetEnabledBenefit}
              benguideData={benguideData}
              updateEditorContentOverview={updateEditorContentOverview}
              updateEditorContentBenefit={updateEditorContentBenefit}
              defaultTextOverview={defaultTextOverview}
              defaultTextBenefit={defaultTextBenefit}
              resetCurrentValueOverview={resetCurrentValueOverview}
              resetCurrentValueBenefit={resetCurrentValueBenefit}
              disableResetter={disableResetter}
              title={isOverview ? titleOverview : titleBenefit}
            />
          )}
        >
          <div className={styles.textContent}>
            <div className={styles.title}>
              {isOverview ? titleOverview : titleBenefit}
            </div>
            <div className={styles.text} ref={textRef}>
              {isOverview ? (
                <ContentPreviewer
                  htmlContent={
                    contentOverview !== initialTextOverview
                      ? contentOverview
                      : initialTextOverview
                  }
                />
              ) : (
                <ContentPreviewer
                  htmlContent={
                    contentBenefit !== initialTextBenefit
                      ? contentBenefit
                      : initialTextBenefit
                  }
                />
              )}
            </div>
            {!isOverview && (
              <div className={styles.benefitsWrapper}>
                {newHireCheckList?.enabled &&
                  checkList &&
                  checkList.includes(CHECKLIST_OPTIONS.NEW_HIRE_CHECKLIST) && (
                    <Link
                      to={`${RouteMap.NEW_HIRE_CHECKLIST}?${appendToken}`}
                      className={styles.checklistLink}
                    >
                      <div className={styles.areaWrapper}>
                        <div className={styles.area}>
                          <NewHire className={styles.benefitImage} />
                          <div className={styles.areaName}>
                            New Hire Checklist
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                {howInsuranceWorks?.enabled &&
                  checkList &&
                  checkList.includes(CHECKLIST_OPTIONS.HOW_INSURANCE_WORKS) && (
                    <Link
                      to={`${RouteMap.HOW_INSURANCE_WORKS}?${appendToken}`}
                      className={styles.checklistLink}
                    >
                      <div className={styles.areaWrapper}>
                        <div className={styles.area}>
                          <Insurance className={styles.benefitImage} />
                          <div className={styles.areaName}>
                            How Insurance Works
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                {datesAndEligibility?.enabled &&
                  checkList &&
                  checkList.includes(
                    CHECKLIST_OPTIONS.DATES_AND_ELIGIBILITY
                  ) && (
                    <Link
                      to={`${RouteMap.DATES_AND_ELIGIBILITY}?${appendToken}`}
                      className={styles.checklistLink}
                    >
                      <div className={styles.areaWrapper}>
                        <div className={styles.area}>
                          <DatesEligibility className={styles.benefitImage} />
                          <div className={styles.areaName}>
                            Dates &amp; Eligibility
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                {medicalPlans.length > 1 &&
                  planRecommenderEnabled &&
                  checkList &&
                  checkList.includes(
                    CHECKLIST_OPTIONS.MEDICAL_PLAN_RECOMMENDER
                  ) && (
                    <Button
                      type="link"
                      className={`${styles.checklistLink} ${styles.planRecommenderBtn}`}
                      onClick={() => setVisibleRecommenderPopup(true)}
                    >
                      <div className={styles.areaWrapper}>
                        <div className={styles.area}>
                          <PlanRecommenderIcon
                            className={styles.benefitImage}
                          />
                          <div className={styles.areaName}>
                            Medical Plan Recommender
                          </div>
                        </div>
                      </div>
                    </Button>
                  )}
              </div>
            )}
          </div>
        </EditableHotspot>
        {isOverview && isEditMode && (
          <div
            className={
              enrollmentLink
                ? `${styles.benefitsEnrollmentWrapper} ${styles.editMode}`
                : styles.benefitsHotspotWrapper
            }
          >
            <EditableHotspot
              alwaysVisible={!enrollmentLink}
              overlayComponent={
                enrollmentLink && (
                  <div className={styles.linkButtonOffset}>
                    <p>Link to Enrollment Website</p>
                    <img src={editIcon} alt="edit" />
                  </div>
                )
              }
              customModal={(visible: boolean, onClose: Function) => (
                <BenefitsEnrollEditor
                  isOpenModal={visible}
                  closeModal={onClose}
                  onSaveEnrollmentLink={changeEnrollmentLink}
                  enrollmentLink={enrollmentLink}
                  benefitButton={benefitButton}
                />
              )}
            >
              {enrollmentLink ? (
                <Button className={styles.linkButton}>
                  Manage and Enroll in Benefits
                </Button>
              ) : (
                <div className={styles.linkHotspot}>
                  Add Button to Enroll in Benefits
                  <img src={editIcon} alt="edit" />
                </div>
              )}
            </EditableHotspot>
          </div>
        )}
        {visibleRecommenderPopup && (
          <PlanRecommenderPopup
            closeModal={() => setVisibleRecommenderPopup(false)}
            clickStart={handleOpenPlanRecommender}
          />
        )}
        {isOverview &&
          !isEditMode &&
          !isEmpty(enrollmentLink) &&
          benefitButton && (
            <div className={styles.benefitsEnrollmentWrapper}>
              <Button
                className={styles.linkButton}
                href={addHttp(enrollmentLink)}
                target="_blank"
              >
                <span className={styles.enrollLinkText}>
                  Manage and Enroll in Benefits
                </span>
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

const ExternalEditableModal = (props: any) => {
  const {
    isOverview,
    visible,
    onClose,
    textWdith,
    editorContentOverview,
    editorContentBenefit,
    initialTextOverview,
    initialTextBenefit,
    updateEditorContentOverview,
    updateEditorContentBenefit,
    resetToDefault,
    benguideData,
    defaultTextOverview,
    defaultTextBenefit,
    resetCurrentValueOverview,
    resetCurrentValueBenefit,
    disableResetter,
    title,
  } = props;
  return (
    <>
      {isOverview && visible && (
        <OverviewEditor
          isOpenModal={visible}
          closeModal={() => onClose()}
          textWdith={textWdith}
          editorContent={
            editorContentOverview !== initialTextOverview
              ? editorContentOverview
              : initialTextOverview
          }
          updateEditorContent={updateEditorContentOverview}
          resetToDefault={resetToDefault}
          isResetEnabled={true}
          benguideData={benguideData}
          defaultTextOverview={defaultTextOverview}
          resetCurrentValue={resetCurrentValueOverview}
          title={title}
        />
      )}
      {!isOverview && visible && (
        <ExploreBenefitEditor
          isOpenModal={visible}
          closeModal={() => onClose()}
          textWdith={textWdith}
          editorContent={
            editorContentBenefit !== initialTextBenefit
              ? editorContentBenefit
              : initialTextBenefit
          }
          updateEditorContent={updateEditorContentBenefit}
          resetToDefault={resetToDefault}
          isResetEnabled={true}
          benguideData={benguideData}
          defaultTextBenefit={defaultTextBenefit}
          resetCurrentValue={resetCurrentValueBenefit}
          disableResetter={disableResetter}
          title={title}
        />
      )}
    </>
  );
};

export default HomeTextWrapper;
