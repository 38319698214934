export const buildFullName = (firstName: string, lastName: string): string => {
  return `${firstName} ${lastName}`;
};

export const isEmptyString = (value?: string) =>
  [undefined, null, ''].includes(value);

export const getAvatarName = (name: string) => {
  const avatarName = '';
  return name.split(' ').map((word) => {
    if (word[0] !== undefined) {
      return avatarName.concat(word[0]);
    }
    return '';
  });
};

export const buildInitials = (firstName: string, lastName: string): string => {
  return `${firstName.trim().charAt(0)} ${lastName.trim().charAt(0)}`;
};
