import React from 'react';
import styles from './aiAssistantIcon.module.less';

const AiAssistantIcon = () => {
  return (
    <svg
      className={styles.aiAssistantIcon}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="plans-benefits-consult">
        <path
          id="Vector"
          d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
          strokeWidth="1.28"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2 8.10064C6.90938 4.37001 9.09062 11.63 14 7.89939"
          strokeWidth="1.28"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M11.0874 13.1457C5.39985 10.7601 10.5986 5.24012 4.91235 2.85449"
          strokeWidth="1.28"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M10.9127 2.75293C11.6877 8.87043 4.31268 7.12918 5.08768 13.2498"
          strokeWidth="1.28"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default AiAssistantIcon;
