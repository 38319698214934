import { FC, ReactNode } from 'react';
import { Popover, PopoverProps } from 'antd';

import { ReactComponent as QuestionCircleIcon } from 'images/question-round.svg';

import styles from './boxPopover.module.less';

interface Props extends PopoverProps {
  header?: string;
  description?: string | ReactNode;
}

const BoxPopover: FC<Props> = (props: Props) => {
  const { children, description = '', header = '', content, ...rest } = props;

  return (
    <Popover
      overlayClassName={styles.overlay}
      content={
        content ? (
          content
        ) : (
          <ContentContainer header={header} description={description} />
        )
      }
      trigger="hover"
      {...rest}
    >
      {children}
    </Popover>
  );
};

type ContentProps = {
  header: string;
  description: string | ReactNode;
};

const ContentContainer: FC<ContentProps> = (props: ContentProps) => {
  const { header, description } = props;

  return (
    <div className={styles.container}>
      <QuestionCircleIcon className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.header}>{header}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default BoxPopover;
