import { useEffect, useState } from 'react';
import { Divider, Form, Input, Row } from 'antd';
import { isArray, isEmpty, isNull } from 'lodash';
import InputForm from 'components/InputForm/InputForm';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import { PLAN_VIEW } from 'modules/auth/constants/authConstants';
import * as EmpAuthService from 'modules/auth/services/EmployeeAuthService';
import { setDependentDetails } from 'modules/auth/slices/employeeAuthSlice';
import {
  hasElections,
  saveElections,
  setOnboardingView,
} from 'modules/auth/slices/idCardSlice';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { WAIVED } from 'modules/auth/constants/globleConstants';

import TickIcon from 'images/shape-icon.svg';
import MedicalIcon from 'images/plan/medical-plan.svg';
import DentalIcon from 'images/plan/dental-plan.svg';
import VisionIcon from 'images/plan/vision-plan.svg';
import { extractBenefitSections } from 'utils/commonUtil';

import styles from './mdvPlan.module.less';

type PlanItem = {
  value: string;
  id: string;
  isChecked: boolean;
  isEmpty?: boolean;
};

const MDVPlanSelection = () => {
  const [form] = Form.useForm();
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide || { data: {} };
  const publishedPlans = useAppSelector((state) => state.idCard.publishedPlans);
  const employee = useAppSelector((state) => state.idCard.currentEmployee);
  const inProgress = useAppSelector((state) => state.idCard.inProgress);
  const [selectedPlans, setSelectedPlans] = useState<any>({
    MEDICAL: {},
    DENTAL: {},
    VISION: {},
  });
  const dispatch = useAppDispatch();
  const onBoardingView = useAppSelector((state) => state.idCard.view);
  const election = useAppSelector((state) => state.idCard.electionData) || {};
  const mdvElectionData = useAppSelector(
    (state) => state.idCard.electionMDVIdCardData
  );
  const isWaived = selectedPlans[onBoardingView]?.planId === WAIVED.type;

  const filterPublishedPlansBySelectedBenguide = () => {
    const benguideBenefits =
      extractBenefitSections(benguideData)[onBoardingView];
    const getBenguidePlanIds = benguideBenefits.map(
      (benefit) => benefit.planId
    );

    const matchingPlans = publishedPlans?.plans[onBoardingView]?.filter(
      (plan: any) => getBenguidePlanIds.includes(plan.planId)
    );

    return matchingPlans || [];
  };

  const getCurrentPlans = mdvElectionData?.find(
    (item: any) => item.benefitKind === onBoardingView
  );

  const filteredMDVPlans = filterPublishedPlansBySelectedBenguide();

  const navigateNext = (type: string) => {
    switch (type) {
      case PLAN_VIEW.MEDICAL.type:
        dispatch(setOnboardingView(PLAN_VIEW.DENTAL.type));
        break;
      case PLAN_VIEW.DENTAL.type:
        dispatch(setOnboardingView(PLAN_VIEW.VISION.type));
        break;
      case PLAN_VIEW.VISION.type:
        dispatch(setOnboardingView(PLAN_VIEW.CARD.type));
        break;
      default:
        dispatch(setOnboardingView(PLAN_VIEW.CARD.type));
        break;
    }
  };

  const handleChecked = (type: string, id: string) => {
    const currentPlan = selectedPlans[type];
    if (
      !isEmpty(filteredMDVPlans) &&
      !isEmpty(mdvElectionData) &&
      currentPlan
    ) {
      currentPlan.planId = id;
      currentPlan.memberId =
        id === WAIVED.type ||
        isNull(getCurrentPlans?.coverageDetails?.memberIdNumber)
          ? ''
          : getCurrentPlans?.coverageDetails?.memberIdNumber;
      setSelectedPlans({ ...selectedPlans, [type]: currentPlan });
      form.setFieldsValue({
        memberId: currentPlan.memberId,
      });
    }
  };

  const PlanListItem = ({ value, id, isChecked, isEmpty }: PlanItem) => {
    return (
      <div className={styles.planItemWrapper}>
        <Divider className={styles.itemDivider} />
        <div
          className={`${styles.flexRow} ${isEmpty && styles.removeCursor}`}
          onClick={() => handleChecked(onBoardingView, id)}
        >
          <span
            className={`${styles.listText} ${isChecked && styles.listChecked} ${
              isEmpty && styles.disabledList
            }`}
          >
            {isEmpty
              ? 'Your employer doesn’t offer plans for this benefit type.'
              : value}
          </span>
          {isChecked && <img src={TickIcon} alt="checked" />}
        </div>
      </div>
    );
  };

  const getIconAndHeader = (type: string) => {
    switch (type) {
      case PLAN_VIEW.MEDICAL.type:
        return (
          <div className={styles.headingRow}>
            <img src={MedicalIcon} alt={PLAN_VIEW.MEDICAL.value} />
            <h1 className={styles.planHeading}>{PLAN_VIEW.MEDICAL.value}</h1>
          </div>
        );
      case PLAN_VIEW.DENTAL.type:
        return (
          <div className={styles.headingRow}>
            <img src={DentalIcon} alt={PLAN_VIEW.DENTAL.value} />
            <h1 className={styles.planHeading}>{PLAN_VIEW.DENTAL.value}</h1>
          </div>
        );
      case PLAN_VIEW.VISION.type:
        return (
          <div className={styles.headingRow}>
            <img src={VisionIcon} alt={PLAN_VIEW.VISION.value} />
            <h1 className={styles.planHeading}>{PLAN_VIEW.VISION.value}</h1>
          </div>
        );
      default:
        return (
          <div className={styles.headingRow}>
            <h1 className={styles.planHeading}>{PLAN_VIEW.MEDICAL.type}</h1>
            <img src={VisionIcon} alt="" />
          </div>
        );
    }
  };

  const planSelectionHandler = async (values: any) => {
    const currentPlan = selectedPlans[onBoardingView];

    if (currentPlan) {
      currentPlan.memberId =
        currentPlan.planId === WAIVED.type ? '' : values.memberId;
      setSelectedPlans({ ...selectedPlans, [onBoardingView]: currentPlan });
    }
    if (onBoardingView === PLAN_VIEW.VISION.type) {
      const response = await dispatch(
        saveElections(
          employee?.individualId,
          employee?.employerId,
          benguideData?.planYearId,
          selectedPlans
        )
      );
      if (response?.status === 204) {
        const hasElectionResponse = await dispatch(
          hasElections(employee?.individualId, benguideData?.planYearId)
        );

        if (hasElectionResponse?.data?.hasPlans) {
          const dependentsRes = await EmpAuthService.getDependentsDetails(
            employee.employeeId,
            benguideData.planYearId
          );
          dispatch(setDependentDetails(dependentsRes?.data));
        }
      }
    } else {
      navigateNext(onBoardingView);
    }
  };

  const handleCancel = (type: string) => {
    if (!!election?.hasPlans && type === PLAN_VIEW.MEDICAL.type) {
      dispatch(setOnboardingView(PLAN_VIEW.CARD.type));
    } else {
      switch (type) {
        case PLAN_VIEW.MEDICAL.type:
          dispatch(setOnboardingView(PLAN_VIEW.ON_BOARDING.type));
          break;
        case PLAN_VIEW.DENTAL.type:
          dispatch(setOnboardingView(PLAN_VIEW.MEDICAL.type));
          break;
        case PLAN_VIEW.VISION.type:
          dispatch(setOnboardingView(PLAN_VIEW.DENTAL.type));
          break;
        default:
          dispatch(setOnboardingView(PLAN_VIEW.ON_BOARDING.type));
          break;
      }
    }
  };

  const disableNext = () => {
    const plansEmpty = isEmpty(filteredMDVPlans);
    const hasPlanIds = filteredMDVPlans?.some(
      (plan: any) => !isEmpty(plan?.planId)
    );

    if (!plansEmpty && isEmpty(selectedPlans[onBoardingView]?.planId)) {
      return true;
    } else if (
      !plansEmpty &&
      onBoardingView === PLAN_VIEW.VISION.type &&
      !hasPlanIds
    ) {
      return true;
    } else if (inProgress) {
      return true;
    }
    return false;
  };

  const handleInitializePlans = () => {
    if (!isEmpty(mdvElectionData)) {
      const existingMDVData = mdvElectionData?.reduce(
        (acc: any, item: any) => {
          return {
            ...acc,
            [item?.benefitKind]: {
              planId: isWaived ? WAIVED.type : item?.planId,
              memberId: isWaived ? '' : item?.coverageDetails?.memberIdNumber,
            },
          };
        },
        {
          MEDICAL: {},
          DENTAL: {},
          VISION: {},
        }
      );
      setSelectedPlans(existingMDVData);
    } else {
      setSelectedPlans({
        MEDICAL: {},
        DENTAL: {},
        VISION: {},
      });
    }
  };

  useEffect(() => {
    handleInitializePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(selectedPlans[onBoardingView]?.planId)) {
      form.setFieldsValue({
        memberId: '',
      });
    } else {
      form.setFieldsValue({
        memberId: isNull(getCurrentPlans?.coverageDetails?.memberIdNumber)
          ? ''
          : getCurrentPlans?.coverageDetails?.memberIdNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans[onBoardingView], getCurrentPlans]);

  return (
    <InputForm
      isLoading={inProgress}
      form={form}
      layout="vertical"
      onFinish={planSelectionHandler}
    >
      <div className={styles.planContainer}>
        <div className={styles.planListWrapper}>
          {getIconAndHeader(onBoardingView)}
          {isArray(filteredMDVPlans) &&
            [
              ...filteredMDVPlans,
              { name: WAIVED.value, planId: WAIVED.type },
            ].map((item: any) => (
              <PlanListItem
                key={item?.planId}
                value={item?.name}
                id={item?.planId}
                isChecked={
                  selectedPlans[onBoardingView]?.planId === item?.planId
                }
                isEmpty={isEmpty(filteredMDVPlans)}
              />
            ))}
        </div>
        {/* If waived make it not required */}
        {!isEmpty(filteredMDVPlans) && !isWaived && (
          <>
            <Form.Item name="memberId" label={<>Member ID #</>}>
              <Input
                disabled={isEmpty(selectedPlans[onBoardingView].planId)}
                className={styles.inputWrapper}
                name="memberId"
              />
            </Form.Item>
            <p
              className={styles.disabledText}
            >{`The Member ID # can be found after registering for your carrier's
             website or by referencing your physical ID card. Your provider's office will typically need
             this number before you receive services to confirm your coverage.`}</p>
          </>
        )}
        <SubmitButton
          type="primary"
          htmlType="submit"
          disabled={disableNext()}
          loading={inProgress}
          className={styles.submitPwdButton}
        >
          {onBoardingView === PLAN_VIEW.VISION.type ? 'Save' : 'Next'}
        </SubmitButton>
        <Row justify="center" className={styles.backButton}>
          <LinkButton
            className={styles.resetPwdButton}
            onClick={() => handleCancel(onBoardingView)}
          >
            Back
          </LinkButton>
        </Row>
      </div>
    </InputForm>
  );
};

export default MDVPlanSelection;
