enum PageTitle {
  CREATE_BENGUIDE = 'Create Benguide',
  BASIC_INFO_EDIT_PATH = 'Basic Info Edit',
  HOME_PATH = 'Home',
  NEW_HIRE_CHECKLIST = 'New Hire Checklist',
  HOW_INSURANCE_WORKS = 'How Insurance Works',
  DATES_AND_ELIGIBILITY = 'Dates and Eligibility',
  MEDICAL = 'Medical',
  PLAN_RECOMMENDER_MEDICAL = 'Plan Recommender Medical',
  TELEHEALTH = 'Telehealth',
  DENTAL = 'Dental',
  VISION = 'Vision',
  LIFE_AND_DISABILITY = 'Life and Disability',
  VOLUNTARY = 'Voluntary',
  WELLBEING = 'Wellbeing',
  HRA = 'Tax Advantage HRA',
  FSA = 'Tax Advantage FSA',
  HSA = 'Tax Advantage HSA',
  COMMUTER = 'Tax Advantage Commuter',
  FOUR_O_ONE_K = '401k',
  HOLIDAYS_TIME_OFF = 'Holidays and Time Off',
  FAMILY_AND_LEAVE = 'Family and Leave',
  ADDITIONAL_PERKS = 'Additional Perks',
  RESOURCES = 'Resources',
  PUBLISH = 'Publish',
  PREVIEW = 'Preview',
}

export default PageTitle;
