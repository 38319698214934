import React from 'react';
import styles from './textButton.module.less';
type Props = {
  label: React.ReactNode | string;
  className?: string;
  onClick: (e?: any) => void;
  type: 'default' | 'primary';
  icon?: React.ReactNode;
  disabled?: boolean;
};

const TextButton = (props: Props) => {
  const {
    label,
    className = '',
    type = 'default',
    icon,
    disabled,
    onClick,
  } = props;

  const getType = (type: any) => {
    switch (type) {
      case 'default':
        return styles.default;
      case 'primary':
        return styles.primary;
      default:
        return styles.default;
    }
  };
  return (
    <div
      className={`${styles.textButton} ${
        disabled ? styles.disabled : ''
      } ${getType(type)} ${className}`}
      onClick={!disabled ? onClick : () => {}}
    >
      {label} {icon}
    </div>
  );
};

export default TextButton;
