const baseApi =
  (window as any)._serverConfig.apiUrl || process.env.REACT_APP_API_BASE;
export const unifiedPortalUrl =
  (window as any)._serverConfig.unifiedPortalUrl ||
  process.env.REACT_APP_UP_HOST;
const wsBaseApi = (window as any)._serverConfig.apiUrl
  ? (window as any)._serverConfig.apiUrl.replace(/^https:\/\//i, 'wss://')
  : process.env.REACT_APP_WS_BASE_URL;
export const benguideUrl =
  (window as any)._serverConfig.benguideUrl ||
  process.env.REACT_APP_BENGUIDE_URL;
export const onSchedEnvironment =
  (window as any)._serverConfig.onSchedEnvironment || process.env.ON_SCHED_ENV;
export const measurementID =
  (window as any)._serverConfig.measurementID ||
  process.env.REACT_APP_LOCAL_GA_KEY;
export const gtmID =
  (window as any)._serverConfig.gtmID || process.env.REACT_APP_LOCAL_GTM_KEY;

export { baseApi, wsBaseApi };

export const benguideApiV2 = `${baseApi}/v2/benefit-guides`;

export const staticBaseUrl = 'https://static-content.planyear.com/benguide-v3';
