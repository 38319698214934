import axios from 'axios';
import { baseApi } from 'utils/apiUtil';
import { http, httpM } from 'utils/httpUtil';

const planServiceUrl = `${baseApi}/v1/plans`;
const benguideURL = `${baseApi}/v2/benefit-guides`;
const electionsV2 = `${baseApi}/v2/elections`;
const electionsV1 = `${baseApi}/v1/elections`;
const employerDetails = `${baseApi}/v2/employers`;

export const getPlans = async (
  type: string,
  planId: string,
  revision: number
) => {
  try {
    const response = await http.get(
      `${planServiceUrl}/${type}/${planId}/revisions/${revision}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPlanDocument = async (
  planId: string,
  documentType: string,
  benefitKind: string
) => {
  return await axios.get(
    `${planServiceUrl}/${planId}/documents?documentType=${documentType}&benefitKind=${benefitKind}&isOpen=true`,
    { responseType: 'blob' }
  );
};

export const publishedPlansRequest = async (employerId: string) => {
  try {
    const response = await httpM.get(
      `${benguideURL}/published-plans?employer-id=${employerId}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCurrentPlanYearData = async (
  employerId: string,
  planYearId: string
) => {
  try {
    const response = await httpM.get(
      `${baseApi}/v1/employers/${employerId}/plan-years/${planYearId}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveElections = async (
  individualId: string,
  employerId: string,
  planYearId: string,
  plans: any
) => {
  try {
    const response = await httpM.post(electionsV2, {
      individualId: individualId,
      employerId: employerId,
      planYearId: planYearId,
      plans: plans,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const hasElections = async (
  individualId: string,
  planYearId: string
) => {
  try {
    const response = await httpM.get(
      `${benguideURL}/has-election?individual-id=${individualId}&plan-year-id=${planYearId}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getElectionsData = async (
  individualId: string,
  planYearId: string
) => {
  try {
    const response = await httpM.get(
      `${electionsV1}/?individualId=${individualId}&planYearId=${planYearId}&isDbgV2=${true}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateMemberId = async (
  individualId: string,
  planYearId: string,
  benefitKind: string,
  memberId: string
) => {
  try {
    const response = await httpM.put(
      `${electionsV2}/individual/${individualId}`,
      {
        benefitKind: benefitKind,
        memberId: memberId || '',
        planYearId: planYearId,
        isUserAmended: true,
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployerDetails = async (employerId: string) => {
  try {
    const response = await httpM.get(`${employerDetails}/${employerId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
