import { FC } from 'react';
import { Button } from 'antd';

import { NativeButtonProps } from 'antd/lib/button/button';

import styles from './submitButton.module.less';

interface SubmitButtonProps extends NativeButtonProps {
  className?: string;
  wrapperClassName?: string;
  dataCy?: string;
  isAdditionalFileUploader?: boolean;
}

const SubmitButton: FC<SubmitButtonProps> = (props: SubmitButtonProps) => {
  const {
    className = styles.acceptBtn,
    wrapperClassName = '',
    dataCy,
    isAdditionalFileUploader = false,
    ...rest
  } = props;

  return (
    <>
      {!isAdditionalFileUploader && (
        <div
          className={`${styles.acceptBtnWrapper} ${wrapperClassName}`}
          data-cy={`${dataCy}-wrapper`}
        >
          <Button data-cy={dataCy} className={className} {...rest} />
        </div>
      )}
      {isAdditionalFileUploader && (
        <div className={styles.pageAdditionalActionBtnWrapper}>
          <Button
            className={styles.additionalPageActionBtn}
            {...rest}
            data-cy={dataCy}
          />
        </div>
      )}
    </>
  );
};

export default SubmitButton;
