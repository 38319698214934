import { Row } from 'antd';
import { useAppDispatch } from 'hooks/redux';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import { setAuthView } from 'modules/auth/slices/employeeAuthSlice';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';

import styles from './fallback.module.less';
const FallBack = () => {
  const dispatch = useAppDispatch();
  return (
    <Row justify="center">
      <div>
        <div className={styles.codeWrapper}>
          <h1 className={styles.textWrapper}>
            For primary subscribers, please use your work email address to log
            in or register for an account.
          </h1>
          <h1 className={styles.textWrapper}>
            For additional questions, please contact
            <LinkButton
              className={styles.linkBtn}
              href="mailto: support@planyear.com"
            >
              support@planyear.com
            </LinkButton>
          </h1>
        </div>
        <SubmitButton
          type="primary"
          htmlType="button"
          className={styles.submitPwdButton}
          onClick={() => dispatch(setAuthView(AUTH_VIEW.LOGIN.type))}
        >
          Back
        </SubmitButton>
      </div>
    </Row>
  );
};

export default FallBack;
