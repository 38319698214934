import { FC, useEffect, useRef, useState } from 'react';

import { Form } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import { baseApi } from 'utils/apiUtil';
import { addHttp } from 'utils/httpUtil';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import AdditionalResource, {
  InvalidField,
} from 'modules/resources/components/AdditionalResource/AdditionalResource';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  clearAdditionalResUpload,
  clearRemovedResource,
  getLatestBenguideByHash,
  handleRemoveResource,
  uploadAdditionalDocument,
} from 'modules/resources/slices/resourcesSlice';
import { ADDITIONAL_RESOURCE_TYPES } from 'modules/resources/constants';
import { AdditionalResourceType } from 'model/AdditionalResource';
import iconEdit from 'images/icon-edit.svg';
import { validateResourcesFields } from 'utils/fileUtil';

import styles from './addAdditionalResource.module.less';

type AddAdditionalResourceProps = {
  resource?: any;
  benefitKind?: string;
};
const benguideApiV2 = `${baseApi}/v2/benefit-guides`;

const AddAdditionalResource: FC<AddAdditionalResourceProps> = (
  props: AddAdditionalResourceProps
) => {
  const { resource } = props;

  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const [removeResource, setRemoveResource] = useState<boolean>(false);
  const [additionalResourceType, setAdditionalResourceType] =
    useState<string>('');
  const [formData, setFormData] = useState<any>({
    weblink: '',
    fileName: '',
  });
  const [isAdditionalInfoValid, setIsAdditionalInfoValid] =
    useState<InvalidField>('');
  const additionalResourcesRef = useRef<any>();
  const dispatch = useAppDispatch();
  const { masterId, isEditMode, latestRevision, urlHash } = useBenguide();
  const { resources } = useAppSelector((state) => state);

  useEffect(() => {
    if (resource) {
      const { resourceName, weblink, type, fileName } = resource;
      const data = {
        name: resourceName,
        weblink,
        fileName,
      };
      const { current } = additionalResourcesRef as any;
      const { setResFileName } = current;
      let resourceType =
        AdditionalResourceType.WEB_LINK && ADDITIONAL_RESOURCE_TYPES.WEBLINK;
      if (type === AdditionalResourceType.FILE) {
        resourceType = ADDITIONAL_RESOURCE_TYPES.FILE;
      }
      setIsRemoved(false);
      setAdditionalResourceType(resourceType);
      form.setFieldsValue(data);
      setFormData(data);
      if (type === AdditionalResourceType.FILE) setResFileName(fileName);
    }
  }, [setFormData, form, resource]);

  useEffect(() => {
    if (
      urlHash &&
      latestRevision &&
      !resources.resUploadInProgress &&
      resources.resUploadSuccess
    ) {
      dispatch(clearAdditionalResUpload());
    }
  }, [
    dispatch,
    urlHash,
    latestRevision,
    resources.resUploadInProgress,
    resources.resUploadSuccess,
  ]);

  useEffect(() => {
    if (
      masterId &&
      resources.isResRemovedSuccess &&
      !resources.deleteInProgress
    ) {
      setRemoveResource(false);
      dispatch(getLatestBenguideByHash(urlHash));
      dispatch(clearRemovedResource());
    }
  }, [
    dispatch,
    masterId,
    urlHash,
    latestRevision,
    resources.deleteInProgress,
    resources.isResRemovedSuccess,
  ]);

  useEffect(() => {
    if (formData['name']) {
      setIsInvalid(false);
      formData?.fileName && setFileName(formData?.fileName);
    }
  }, [formData]);

  const onFileChange = (value: File) => {
    setIsRemoved(false);
    setIsAdditionalInfoValid('');
    if (value) {
      setFile(value);
      setFormData({ ...formData, weblink: '', fileName: value?.name });
    }
  };

  const onFileRemove = () => {
    setFile(null);
    setIsRemoved(true);
    setFileName('');
  };

  const reset = (isCloseConfirmed?: boolean) => {
    setIsAdditionalInfoValid('');
    setIsInvalid(false);
    setFile(null);
    setFileName('');
    const { current } = additionalResourcesRef as any;
    const { reset, resetNameCharCount, resetErrors, setResFileName } = current;
    resetErrors();
    if (resource) {
      const { resourceName, weblink, fileName, type } = resource;
      const data = {
        name: resourceName,
        weblink,
        fileName,
      };
      let resourceType =
        AdditionalResourceType.WEB_LINK && ADDITIONAL_RESOURCE_TYPES.WEBLINK;
      if (type === AdditionalResourceType.FILE) {
        resourceType = ADDITIONAL_RESOURCE_TYPES.FILE;
      }
      setAdditionalResourceType(resourceType);
      setFormData(data);
      if (!weblink && isCloseConfirmed) {
        setResFileName(fileName);
      }
      form.setFieldsValue(data);
    } else {
      reset();
      resetNameCharCount();
      setAdditionalResourceType('');
      form.resetFields();
      setFormData({
        name: '',
        fileName: '',
        weblink: '',
      });
    }
  };

  const handleClose = (onClose: Function) => {
    setIsRemoved(false);
    onClose();
    reset(true);
  };

  const onCloseWithoutResetData = (onClose: Function) => {
    onClose();
    reset();
  };

  const handleSubmit = async (onClose: Function) => {
    const isFormValid = await getValidationResult();
    if (isFormValid) {
      if (
        file ||
        (formData.weblink && formData?.weblink?.trim() !== resource?.weblink) ||
        formData?.name?.trim() !== resource?.resourceName?.trim()
      ) {
        dispatch(
          uploadAdditionalDocument(
            masterId,
            file,
            file?.name || '',
            formData.name,
            formData?.weblink,
            resource?.id
          )
        );
      } else {
        onClose();
      }
    } else {
      if (!formData['name']) {
        setIsInvalid(true);
      }
    }
  };

  const onRemoveResource = () => {
    setRemoveResource(true);
  };

  const onCloseDeleteConfirmation = () => {
    setRemoveResource(false);
    reset(true);
  };

  const onDeleteResource = () => {
    if (resource?.id) {
      dispatch(handleRemoveResource(masterId, resource.id));
    } else {
      onCloseDeleteConfirmation();
    }
  };

  const validateAdditionalInfo = () => {
    const { weblink } = form.getFieldsValue();
    const additionalResourcesData = { weblink, file, fileName };

    const isAdditionalFormValid = validateResourcesFields(
      additionalResourcesData,
      additionalResourceType
    );
    if (isAdditionalFormValid) {
      setIsAdditionalInfoValid('');
      return true;
    } else {
      !isAdditionalFormValid &&
      additionalResourceType === ADDITIONAL_RESOURCE_TYPES.WEBLINK
        ? setIsAdditionalInfoValid('webLinkInvalid')
        : additionalResourceType === ADDITIONAL_RESOURCE_TYPES.FILE
        ? setIsAdditionalInfoValid('resourceInvalid')
        : setIsAdditionalInfoValid('');
      return false;
    }
  };

  const validateResourceName = async () => {
    try {
      await form.validateFields(['name']);
      return true;
    } catch (errorInfo: any) {
      return false;
    }
  };

  const getValidationResult = async () => {
    try {
      const isFormValid = await validateResourceName();
      let isRadioBtnValid = false;

      if (additionalResourceType) {
        isRadioBtnValid = validateAdditionalInfo();
      } else {
        setIsAdditionalInfoValid('bothInvalid');
      }

      return isRadioBtnValid && isFormValid;
    } catch (error) {}
  };

  useEffect(() => {
    if (
      additionalResourceType === ADDITIONAL_RESOURCE_TYPES.WEBLINK &&
      formData.weblink !== null
    ) {
      setIsRemoved(false);
    }
  }, [additionalResourceType, formData.weblink]);

  return (
    <div
      className={
        resource ? styles.additionalResEditWrapper : styles.additionalResWrapper
      }
    >
      <EditableHotspot
        editIcon={resource ? <img src={iconEdit} alt="edit" /> : <></>}
        editText={resource ? 'Edit/Replace' : ''}
        alwaysVisible={!resource}
        customModal={(visible: boolean, onClose: Function) => (
          <AdditionalResource
            visible={visible}
            onClose={() => handleClose(onClose)}
            onSubmit={() => handleSubmit(onClose)}
            isRemoved={isRemoved}
            ref={additionalResourcesRef}
            onFileChange={onFileChange}
            onFileRemove={onFileRemove}
            setFormData={setFormData}
            form={form}
            formData={formData}
            additionalResourceType={additionalResourceType}
            setAdditionalResourceType={setAdditionalResourceType}
            onRemoveResource={() => onRemoveResource()}
            invalidField={isAdditionalInfoValid}
            setIsAdditionalInfoValid={setIsAdditionalInfoValid}
            isInvalid={isInvalid}
            setIsInvalid={setIsInvalid}
            fileName={file?.name || formData.fileName}
            onCloseWithoutResetData={() => onCloseWithoutResetData(onClose)}
          />
        )}
      >
        {resource ? (
          <div className={styles.editHotspot}>
            <a
              href={
                resource.weblink
                  ? addHttp(resource.weblink)
                  : `${benguideApiV2}/${masterId}/additional-resources/${resource.id}/download?is-open=true`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {resource.resourceName}
            </a>
            <br />
          </div>
        ) : (
          isEditMode && (
            <div className={styles.linkHotspot}>
              Add Additional Resource
              <img src={iconEdit} alt="edit" />
            </div>
          )
        )}
      </EditableHotspot>
      {removeResource && (
        <ConfirmationDialog
          visible={removeResource}
          onCancel={onCloseDeleteConfirmation}
          flipFooterOrder
          okText="Yes - Remove Resource"
          onOk={onDeleteResource}
          cancelText="Cancel"
          title="Remove Resource?"
        >
          Are you sure you want to remove this resource? This action cannot be
          undone.
        </ConfirmationDialog>
      )}
    </div>
  );
};
export default AddAdditionalResource;
