import { FC, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NavigationContext } from 'context/NavigationContext';
import AppLayout from 'layout/AppLayout';
import BasicInfo from 'modules/home/pages/BasicInfo/BasicInfo';
import { routes } from 'routes';
import PrivateRoute from 'routes/PrivateRoute';
import RouteMap from 'routes/RouteMap';
import ErrorBoundary from 'modules/auth/pages/ErrorBoundary/ErrorBoundary';
import PlanRecommender from 'modules/planRecommender/pages/PlanRecommender/PlanRecommender';
import PlanRecommenderLayout from 'modules/planRecommender/components/PlanRecommenderLayout/PlanRecommenderLayout';
import useBenguide from 'hooks/benguide';

const AppRoutes: FC = () => {
  const { disabledRoutes } = useContext(NavigationContext);
  const { planRecommenderEnabled } = useBenguide();

  return (
    <Routes>
      <Route path={RouteMap.CREATE_BENGUIDE} element={<AppLayout />}>
        <Route path="" element={<BasicInfo />} />
      </Route>
      <Route path="/:hash">
        {planRecommenderEnabled && (
          <Route
            path="plan-recommender"
            element={<PrivateRoute layout={PlanRecommenderLayout} />}
          >
            <Route path="medical" element={<PlanRecommender />} />
          </Route>
        )}
      </Route>
      <Route path="/:hash/:employerName">
        {planRecommenderEnabled && (
          <Route
            path="plan-recommender"
            element={<PrivateRoute layout={PlanRecommenderLayout} />}
          >
            <Route path="medical" element={<PlanRecommender />} />
          </Route>
        )}
      </Route>
      <Route path="/:hash" element={<PrivateRoute />}>
        {routes
          .filter((route) => !disabledRoutes.includes(route.path))
          .map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        <Route path="*" element={<ErrorBoundary />} />
      </Route>
      <Route path="/:hash/:employerName" element={<PrivateRoute />}>
        {routes
          .filter((route) => !disabledRoutes.includes(route.path))
          .map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        <Route path="*" element={<ErrorBoundary />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
