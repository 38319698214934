import { Col, Row } from 'antd';
import Markdown from 'markdown-to-jsx';
import LoadingWrapper from 'modules/aiAssistantChat/layout/LoadingWrapper/LoadingWrapper';
import Feedback from 'modules/aiAssistantChat/components/Feedback/Feedback';

import { ReactComponent as AiAssistantUserIcon } from 'images/icon-ai-assistant-user.svg';
import { ReactComponent as AiAssistantIcon } from 'images/icon-ai-assistant-black.svg';

import Typewriter from 'modules/aiAssistantChat/components/Typewriter/Typewriter';
import styles from './chatBubble.module.less';

type ChatBubbleProps = {
  isQuestion?: boolean;
  isAnswer: boolean;
  isPending?: boolean;
  animate: boolean;
  content: string;
  onFinishTyping?: () => void;
  scrollIntoView?: () => void;
};

const ChatBubble = ({
  isQuestion = false,
  isAnswer = false,
  isPending = false,
  animate = false,
  content,
  onFinishTyping,
  scrollIntoView,
}: ChatBubbleProps) => {
  return (
    <div
      className={`${styles.chatBubbleWrapper} ${
        isQuestion ? styles.chatBubbleQuestion : ''
      }`}
    >
      <Row gutter={16}>
        <Col flex={'42px'}>
          {isQuestion && <AiAssistantUserIcon className={styles.userIcon} />}

          {isAnswer && <AiAssistantIcon className={styles.aiAssistantIcon} />}
        </Col>
        <Col xs={22} className={styles.textBody}>
          <LoadingWrapper isAnswer={isAnswer} isPending={isPending}>
            {isAnswer && animate ? (
              <Typewriter
                text={content}
                onTypingEnd={onFinishTyping!}
                scrollIntoView={scrollIntoView!}
              />
            ) : (
              <Markdown>{content}</Markdown>
            )}
            {isAnswer && !animate && <Feedback content={content} />}
          </LoadingWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default ChatBubble;
