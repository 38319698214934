import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import { getRichMedia } from 'modules/auth/services/BenguideService';

import { SectionName } from 'modules/home/constants';
import RichMedia from 'model/RichMedia';
interface HIWState {
  defaultContent: RichMedia;
  currentContent: RichMedia;
  inProgress: boolean;
  error: any;
  requestType: string;
}

const initialState = {
  defaultContent: {},
  currentContent: {},
  inProgress: false,
  error: null,
  requestType: '',
} as HIWState;

const currentContentInProgress = (state: HIWState, action: PayloadAction) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.currentContent = {} as RichMedia;
  state.error = null;
};

const currentContentCompleted = (
  state: HIWState,
  action: PayloadAction<RichMedia>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentContent = action.payload;
  state.error = null;
};

const currentContentFailed = (state: HIWState, action: PayloadAction) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.currentContent = {} as RichMedia;
  state.error = action.payload;
};

const hiwSlice = createSlice({
  name: 'howInsuranceWorks',
  initialState,
  reducers: {
    getDefaultRichMediaStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.defaultContent = {} as RichMedia;
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultRichMediaSuccess(state, action: PayloadAction<RichMedia>) {
      state.inProgress = false;
      state.defaultContent = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getDefaultRichMediaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultContent = {} as RichMedia;
      state.error = action.payload;
      state.requestType = action.type;
    },

    createRichMediaStarted(state, action: PayloadAction) {
      currentContentInProgress(state, action);
    },
    createRichMediaSuccess(state, action: PayloadAction<RichMedia>) {
      currentContentCompleted(state, action);
    },
    createRichMediaFailed(state, action: PayloadAction<any>) {
      currentContentFailed(state, action);
    },
    getCurrentRichMediaStarted(state, action: PayloadAction) {
      currentContentInProgress(state, action);
    },
    getCurrentRichMediaSuccess(state, action: PayloadAction<RichMedia>) {
      currentContentCompleted(state, action);
    },
    getCurrentRichMediaFailed(state, action: PayloadAction<any>) {
      currentContentFailed(state, action);
    },
  },
});

export const {
  getDefaultRichMediaStarted,
  getDefaultRichMediaSuccess,
  getDefaultRichMediaFailed,
  createRichMediaStarted,
  createRichMediaSuccess,
  createRichMediaFailed,
  getCurrentRichMediaStarted,
  getCurrentRichMediaSuccess,
  getCurrentRichMediaFailed,
} = hiwSlice.actions;

export default hiwSlice.reducer;

export const getDefaultRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultRichMediaSuccess(response.data));
    } catch (error) {
      dispatch(getDefaultRichMediaFailed(error));
    }
  };

export const createRichMedia =
  (
    benguideId: string,
    sectionName: SectionName,
    content: string,
    title: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(createRichMediaStarted());
    try {
      const data = {
        sectionName: sectionName,
        content: content,
        title: title,
      };
      const response = await BenGuideService.createRichMedia(benguideId, data);
      const newData = {
        benGuideId: benguideId,
        content: content,
        title: title,
        latestRevision: response.data.latestRevision,
      };
      dispatch(createRichMediaSuccess(newData));
    } catch (error) {
      dispatch(createRichMediaFailed(error));
    }
  };

export const getCurrentRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getCurrentRichMediaStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(getCurrentRichMediaSuccess(response.data));
    } catch (error) {
      dispatch(getCurrentRichMediaFailed(error));
    }
  };
