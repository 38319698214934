import { FC } from 'react';
import reactHtmlParser from 'react-html-parser';

import styles from './contentPreviewer.module.less';

type ContentPreviewerProps = {
  htmlContent: any;
};

const ContentPreviewer: FC<ContentPreviewerProps> = (
  props: ContentPreviewerProps
) => {
  const { htmlContent } = props;
  return (
    <div className={styles.container}>
      {htmlContent && reactHtmlParser(htmlContent)}
    </div>
  );
};

export default ContentPreviewer;
