import TagManager from 'react-gtm-module';
import { gtmID } from './apiUtil';

const tagManagerArgs = {
  gtmId: gtmID,
};

export const initGTM = () => {
  TagManager.initialize(tagManagerArgs);
};
