import { Button, ButtonProps } from 'antd';
import { FC } from 'react';

import styles from './largeButton.module.less';

const LargeButton: FC<ButtonProps> = (props: ButtonProps) => {
  const { className = '', children, ...rest } = props;

  return (
    <Button className={`${styles.button} ${className}`} {...rest}>
      {children}
    </Button>
  );
};

export default LargeButton;
