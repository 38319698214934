import { SectionName } from 'modules/home/constants';

export const FOUR_ONE_K_SECTIONS = [
  {
    title: 'Overview',
    code: SectionName.FOUR_ONE_K_OVERVIEW_TEXT,
  },
  {
    title: 'Contributions',
    code: SectionName.FOUR_ONE_K_CONTRIBUTIONS_TEXT,
  },
  {
    title: 'Advantages',
    code: SectionName.FOUR_ONE_K_ADVANTAGES_TEXT,
  },
];

export const DOC_TYPE_LABELS: { [key: string]: string } = {
  PLAN_DOCUMENT: 'View Plan Document',
  FOUR_O_ONE_K_ROLLOVER_FORM: 'View 401(k) Rollover Form',
  PLAN_SUMMARY: 'View Plan Summary',
};
