// eslint-disable-next-line import/no-unresolved
import { Area } from 'react-easy-crop/types';
import { getProtocol } from 'utils/httpUtil';
import { getBase64Image } from 'utils/screenshotUtil';

export async function getCroppedImg(_imageSrc: string, pixelCrop: Area) {
  const protocol = getProtocol(_imageSrc);
  const isHttp = protocol === 'http:' || protocol === 'https:';
  let imageSrc = _imageSrc;
  if (isHttp) {
    imageSrc = (await getBase64Image(_imageSrc)) as any;
  }

  const image = await createImage(imageSrc);

  // Create a new canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // Set the canvas size to match the dimensions of the cropped area
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Enable anti-aliasing
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Return the cropped image as a base64 data URI
  return canvas.toDataURL('image/png');
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
