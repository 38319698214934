import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setSelectedNetwork } from 'modules/benefitsWellness/slices/benefitWellnessSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  IN_NETWORK,
  OUT_NETWORK,
} from 'modules/benefitsWellness/constants/BenefitConstants';

import styles from './mdvNetworkSelector.module.less';

const MDVNetworkSelector = ({ className = '' }: { className?: string }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { selectedNetwork } = useAppSelector((state) => state.benefitWellness);

  useEffect(() => {
    dispatch(setSelectedNetwork(IN_NETWORK.value));
  }, [dispatch, pathname]);

  return (
    <div className={`${styles.mdvNetworkSelectorWrapper} ${className}`}>
      <div
        className={`${styles.networkSection} ${
          selectedNetwork === IN_NETWORK.value ? styles.selected : ''
        }`}
        onClick={() => dispatch(setSelectedNetwork(IN_NETWORK.value))}
      >
        <label>In-Network</label>
        <div className={styles.selectedBorder}></div>
      </div>
      <div
        className={`${styles.networkSection} ${
          selectedNetwork === OUT_NETWORK.value ? styles.selected : ''
        }`}
        onClick={() => dispatch(setSelectedNetwork(OUT_NETWORK.value))}
      >
        <label>Out-of-Network</label>
        <div className={styles.selectedBorder}></div>
      </div>
    </div>
  );
};

export default MDVNetworkSelector;
