import { FC, Ref, useEffect, useState } from 'react';

import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';

import { useAppSelector } from 'hooks/redux';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import SectionEditor from 'components/SectionEditor/SectionEditor';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import { formatOpenEnrolmentDate } from 'utils/dateUtil';
import { usePrevious } from 'hooks/usePrevious';

import { currencyFormatter } from 'modules/benefitsWellness/util';
import { isEmptyString } from 'utils/stringUtil';
import { BENGUIDE_MODE_EDIT } from 'modules/auth/constants/globleConstants';
import {
  DATES_SECTIONS,
  WAIVER_CREDIT_FREQUENCY,
} from 'modules/datesEligability/constants';

import editIcon from 'images/icon-edit.svg';
import { ReactComponent as OpenEnrollmentIcon } from 'images/icon-open-enrollment.svg';
import styles from './datesEditable.module.less';

type DatesEditableProps = {
  closeSupportModal: (onClose: Function, option: string) => void;
  onSubmit: (
    onClose: Function,
    selectedOption: string,
    isHiddenEditor: boolean
  ) => void;
  onChangeSection: (sectionName: string) => void;
  onSwitch: (value: boolean, selectedOption: string) => void;
  textEditorRef: Ref<any>;
  sectionEditorRef: Ref<any>;
  enabled: (option: string) => boolean;
  displayData: string;
  datesSectionLabel: string;
  masterId: string;
  editableSectionName: string;
  editableSectionTitle: string;
  startDate?: dayjs.Dayjs | null;
  endDate?: dayjs.Dayjs | null;
  onchangeDate?: (value: dayjs.Dayjs | null, isStartDate: boolean) => void;
  displayOEData: string;
  displayRulesData: string;
  displayLifeData: string;
  displayWaiverData: string;
  resetToDefault: (selectedOption: string) => void;
  isSectionDisabled?: boolean;
  isHiddenEditor?: boolean;
  saveAllContent: (option: string, content: string) => void;
  nextEnableSection?: string;
  savedData: any;
  previewContent?: string;
  waiverAmount?: string;
  waiverFrequency?: string;
  onChangeWaiverAmount?: Function;
  onChangeWaiverFrequency?: Function;
  sectionWaiverAmount?: string;
  sectionWaiverFrequency?: string;
};

const DatesEditable: FC<DatesEditableProps> = (props: DatesEditableProps) => {
  const {
    onSubmit,
    onChangeSection,
    onSwitch,
    closeSupportModal,
    enabled,
    displayData,
    datesSectionLabel,
    masterId,
    textEditorRef,
    sectionEditorRef,
    editableSectionName,
    editableSectionTitle,
    startDate,
    endDate,
    onchangeDate = () => {},
    displayOEData,
    displayRulesData,
    displayLifeData,
    displayWaiverData,
    resetToDefault,
    isSectionDisabled = false,
    isHiddenEditor = false,
    saveAllContent,
    nextEnableSection,
    savedData,
    previewContent,
    waiverAmount,
    waiverFrequency,
    onChangeWaiverAmount,
    onChangeWaiverFrequency,
    sectionWaiverAmount,
    sectionWaiverFrequency,
  } = props;
  const { benguide } = useAppSelector((state) => state);
  const { mode } = benguide;
  const isEditMode = mode === BENGUIDE_MODE_EDIT;
  const [resetValue, setResetValue] = useState<string | undefined>('');
  const [option, setOption] = useState<string>(editableSectionName);

  useEffect(() => {
    setResetValue(displayData);
  }, [displayData]);

  const resetToCurrent = (onClose: Function) => {
    setResetValue(displayData);
    closeSupportModal(onClose, option);
  };

  const getDefaultData = () => {
    if (option === DATES_SECTIONS[0].code) {
      return displayOEData;
    } else if (option === DATES_SECTIONS[1].code) {
      return displayRulesData;
    } else if (option === DATES_SECTIONS[2].code) {
      return displayLifeData;
    } else {
      return displayWaiverData;
    }
  };

  const onChange = (value: string) => {
    const { content, isUpdated } =
      savedData.find((element: any) => element.code === value) || {};
    if (isUpdated) {
      setResetValue(content);
    } else if (value === DATES_SECTIONS[0].code) {
      setResetValue(displayOEData);
    } else if (value === DATES_SECTIONS[1].code) {
      setResetValue(displayRulesData);
    } else if (value === DATES_SECTIONS[2].code) {
      setResetValue(displayLifeData);
    } else {
      setResetValue(displayWaiverData);
    }
    setOption(value);
    onChangeSection(value);
  };

  const handleTextEditor = (data: string) => {
    saveAllContent(option, data);
  };

  const resetTextEditor = () => {
    resetToDefault(option);
  };

  const enabledToggle = enabled(option);

  const [visible, setVisible] = useState<boolean>(false);
  const previousVisible = usePrevious(visible);

  useEffect(() => {
    if (!previousVisible && visible && nextEnableSection) {
      const { current } = sectionEditorRef as any;
      const { changeSelection } = current;
      changeSelection(nextEnableSection);
    }
  }, [visible, previousVisible, nextEnableSection, sectionEditorRef]);

  useEffect(() => {
    if (visible) {
      if (option === DATES_SECTIONS[0].code) {
        setResetValue(displayOEData);
      } else if (option === DATES_SECTIONS[1].code) {
        setResetValue(displayRulesData);
      } else if (option === DATES_SECTIONS[2].code) {
        setResetValue(displayLifeData);
      } else {
        setResetValue(displayWaiverData);
      }
    }
    // No need to reset when other variable change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <EditableHotspot
      alwaysVisible={false}
      editText={isHiddenEditor ? '' : 'Edit'}
      editIcon={<img src={editIcon} alt="edit" />}
      customModal={(visible: boolean, onClose: Function) => {
        if (visible) {
          onChangeSection(option);
        }
        setVisible(visible);
        return (
          <>
            <HalfScreenModal
              visible={visible}
              title="Dates & Eligibility"
              onCancel={() => resetToCurrent(onClose)}
              wrapClassName={styles.dateModalWrapper}
              confirmModalShow={true}
              onReset={() => resetTextEditor()}
              onSubmit={() => {
                onSubmit(onClose, editableSectionName, isHiddenEditor);
              }}
              isResetEnabled={true}
              width={900}
              destroyOnClose={true}
            >
              <SectionEditor
                sectionNames={DATES_SECTIONS}
                onChange={(section: string) => onChange(section)}
                handleEditorChange={(data: string) => handleTextEditor(data)}
                textEditorRef={textEditorRef}
                ref={sectionEditorRef}
                isToggleNeeded={true}
                toggleChecked={enabledToggle}
                onSwitch={(enabled: boolean, selectedOption: string) =>
                  onSwitch(enabled, selectedOption)
                }
                selectedSection={datesSectionLabel}
                defaultSection={editableSectionTitle}
                defaultContent={getDefaultData()}
                defaultValue={editableSectionName}
                benguideId={masterId}
                limit={1500}
                disabled={!enabledToggle}
                startDate={startDate}
                endDate={endDate}
                onchangeDate={onchangeDate}
                resetValue={resetValue}
                waiverAmount={waiverAmount}
                waiverFrequency={waiverFrequency}
                onChangeWaiverAmount={onChangeWaiverAmount}
                onChangeWaiverFrequency={onChangeWaiverFrequency}
              />
            </HalfScreenModal>
          </>
        );
      }}
    >
      <div
        className={
          editableSectionName === DATES_SECTIONS[0].code
            ? styles.importantDates
            : ''
        }
      >
        <Row>
          {editableSectionName === DATES_SECTIONS[0].code &&
            !isSectionDisabled && (
              <Col>
                <OpenEnrollmentIcon className={styles.oeIcon} />
              </Col>
            )}
          {!isSectionDisabled && (
            <Col
              className={
                editableSectionName === DATES_SECTIONS[0].code
                  ? styles.effectiveDate
                  : styles.eligibilitySections
              }
            >
              {editableSectionName === DATES_SECTIONS[0].code && (
                <>
                  <div className={styles.effectiveLabel}>
                    Open Enrollment Date:
                  </div>
                  <div className={styles.openEnrolmentDate}>
                    <Row className={styles.planYear}>
                      {startDate && endDate ? (
                        <Col>
                          {formatOpenEnrolmentDate(
                            startDate?.toString(),
                            endDate?.toString()
                          )}
                        </Col>
                      ) : (
                        <Col>{'-'}</Col>
                      )}
                    </Row>
                  </div>
                </>
              )}
              <div className={styles.contentWrapper}>
                <Row
                  className={
                    editableSectionName === DATES_SECTIONS[0].code
                      ? styles.content
                      : ''
                  }
                >
                  <ContentPreviewer htmlContent={previewContent} />
                </Row>
                <Row className={styles.waiverCreditTextWrapper}>
                  {!isEmptyString(sectionWaiverAmount) &&
                    editableSectionName === DATES_SECTIONS[3].code && (
                      <span>
                        Your waiver credit:{' '}
                        <span className={styles.waiverCreditAmountText}>
                          {currencyFormatter(Number(sectionWaiverAmount))}{' '}
                          {
                            WAIVER_CREDIT_FREQUENCY[
                              sectionWaiverFrequency as keyof typeof WAIVER_CREDIT_FREQUENCY
                            ]?.label
                          }
                        </span>{' '}
                      </span>
                    )}
                </Row>
              </div>
            </Col>
          )}
        </Row>
      </div>

      {isSectionDisabled && isEditMode && (
        <Button onClick={() => {}} className={styles.addContent}>
          <span className={styles.addContentText}>+ Add Content</span>
        </Button>
      )}
    </EditableHotspot>
  );
};

export default DatesEditable;
