import dayjs, { OpUnitType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const INPUT_DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const MMDDYYYY_SLASHES_FORMAT = 'MM/DD/YYYY';
export const SHORT_MONTH_DATE_FORMAT = 'MMM D, YYYY';
export const LONG_MONTH_DATE_FORMAT = 'MMMM D, YYYY';
export const BANNER_DATE_FORMAT = 'dddd, MMMM Do [at] h:mma';
export const TWELVE_HOUR_TIME_FORMAT = 'hh:mm a';
export const TWENTY_HOUR_TIME_FORMAT = 'HH:mm';
export const UTC_TIME_FORMAT = 'Z';
export const DATE_TIME_FORMAT_DEFAULT = 'YYYY-MM-DD HH:mm';

export const SHORT_DATE_AND_TIME_FORMAT = 'M/D/YY h:mma';

export const getCurrentUnixTime = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const getEffectiveDates = (startDate: string, endDate: string) => {
  const start = dayjs(startDate).format('LL');
  const end = dayjs(endDate).format('LL');
  return start + ' - ' + end;
};

export const getFormattedInputDate = (date: string, dateFormat: string) => {
  return dayjs(date).format(dateFormat);
};

export const getCurrentUserTimezone = () => {
  return dayjs.tz.guess();
};

export const getStartOfTimeUnitWithTimezone = (
  timezone: string,
  timeUnit: OpUnitType
) => {
  return dayjs().tz(timezone).startOf(timeUnit);
};

export const getEndOfTimeUnitWithTimezone = (
  timezone: string,
  timeUnit: OpUnitType
) => {
  return dayjs().tz(timezone).endOf(timeUnit);
};

export const getDateWithTimezoneFormatted = (
  date: dayjs.Dayjs,
  timezone: string,
  format: string
) => {
  return date.tz(timezone).format(format);
};

export const getCurrentHourWithTimezone = (timezone: string) => {
  return dayjs().tz(timezone).hour();
};

export const getCurrentMinuteWithTimezone = (timezone: string) => {
  return dayjs().tz(timezone).minute();
};

export const formatDateWithoutTime = (date: string | undefined | Date) => {
  if (date) {
    return dayjs(date).format(INPUT_DATE_FORMAT_DEFAULT);
  } else {
    return '';
  }
};

export const formatOpenEnrolmentDate = (
  firstDate: string | undefined | Date,
  lastDate: string | undefined | Date
) => {
  const startDate = dayjs(firstDate).format(LONG_MONTH_DATE_FORMAT);
  const endDate = dayjs(lastDate).format(LONG_MONTH_DATE_FORMAT);

  return `${startDate} - ${endDate}`;
};
