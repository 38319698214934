import { FC } from 'react';

import infoIcon from 'images/information-banner.svg';

import styles from './infoBanner.module.less';

type InfoBannerProps = {
  header: string;
  description: string;
  className?: string;
};

const InfoBanner: FC<InfoBannerProps> = (props: InfoBannerProps) => {
  const { header, description, className = '' } = props;

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.logoWrapper}>
        <img src={infoIcon} alt="info icon" />
      </div>
      <div className={styles.info}>
        <div className={styles.header}>{header}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default InfoBanner;
