import cssVars from 'css-vars-ponyfill';
import isEmpty from 'lodash/isEmpty';
import StyleConfiguration from 'model/StyleConfiguration';

export const joinClasses = (classes: string[]) => classes.join(' ');

const BLACK_COLOR_HASH = '#000000';

export const setStyleVariables = (styleConfig: StyleConfiguration) => {
  const root = document.documentElement;

  cssVars({
    variables: {
      'home-text-color':
        !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
          ? styleConfig.homeTextColor
          : BLACK_COLOR_HASH,
      'color-theme':
        !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
          ? styleConfig.colorTheme
          : BLACK_COLOR_HASH,
    },
  });

  root.style.setProperty(
    '--home-text-color',
    !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
      ? styleConfig.homeTextColor
      : BLACK_COLOR_HASH
  );
  root.style.setProperty(
    '--color-theme',
    !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
      ? styleConfig.colorTheme
      : BLACK_COLOR_HASH
  );
};
