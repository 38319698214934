import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Col, Row } from 'antd';
import { ReactComponent as LeftArrowIcon } from 'images/icons/Icon-left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'images/icons/Icon-right-arrow.svg';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import SavePlanDescriptionModal from 'components/SavePlanDescriptionModal/SavePlanDescriptionModal';

import PlanCardEditor from 'modules/benefitsWellness/components/PlanCardEditor';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import Spinner from 'components/Spinner/Spinner';
import {
  fetchAdditionalPerksPlanList,
  getRichMediaContent,
  reloadAdditionalPerksSection,
  updateRichMediaContent,
} from 'modules/additionalPerks/slices/additionalPerksSlice';
import {
  ADDITIONAL_PERKS_CHANNEL,
  OPEN_ADDITIONAL_PERKS_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import iconEdit from 'images/icon-edit.svg';
import AdditionalPerksPlan from 'model/AdditionalPerksPlan';
import AddYourText from 'modules/benefitsWellness/components/AddYourText/AddYourText';
import BenefitCarrier from 'model/BenefitCarrier';
import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './additionalPerksCard.module.less';

type AdditionalPerksCardProps = {
  plan: AdditionalPerksPlan;
  moveBottom: () => void;
  moveTop: () => void;
  hideTop: boolean;
  hideBottom: boolean;
  renderReorder: boolean;
  dataIndex?: number;
};

const AdditionalPerksCard: FC<AdditionalPerksCardProps> = ({
  plan,
  moveBottom,
  moveTop,
  hideTop,
  hideBottom,
  renderReorder,
  dataIndex,
}: AdditionalPerksCardProps) => {
  const upClient = useUPClient();

  const benguide = useBenguide();
  const dispatch = useAppDispatch();
  const { richMedia } = useAppSelector((state) => state.additionalPerks);
  const { masterId, isEditMode } = benguide;
  const defaultContent = '';
  const { id, benefitKind, benefitCarrier = {} as BenefitCarrier } = plan;
  const { benefitDescription = '' } = benefitCarrier;
  const richMediaForPlan = get(richMedia, id);
  const { content, inProgress: isRichMediaLoading } = richMediaForPlan || {};

  const isContentReceived = content !== undefined;

  const [editorContent, setEditorContent] = useState<string>(defaultContent);
  const [isVisible, setIsVisible] = useState(false);
  const [
    isSavePlanDescriptionModalVisible,
    setIsSavePlanDescriptionModalVisible,
  ] = useState<boolean>(false);
  const loadContent = useCallback(() => {
    dispatch(getRichMediaContent(masterId, id));
  }, [dispatch, masterId, id]);

  const saveContent = (content: string) => {
    dispatch(
      updateRichMediaContent(masterId, id, content, () => {
        dispatch(
          reloadAdditionalPerksSection(
            benguide.masterId,
            benguide.latestRevision
          )
        );
        dispatch(
          fetchAdditionalPerksPlanList(
            benguide.employerId,
            benguide.planYearId,
            benguide.benefitClasses
          )
        );
        setIsSavePlanDescriptionModalVisible(false);
      })
    );
  };

  useEffect(() => {
    loadContent();
  }, [loadContent, plan?.description]);

  useEffect(() => {
    setEditorContent(content || defaultContent);
  }, [content]);

  const showAddContentBtn = useMemo(() => {
    return isEmpty(content) && isEditMode;
  }, [content, isEditMode]);

  const renderHoverMoveOverlay = () => (
    <div className={styles.moveOverlay}>
      {/* Top Section */}
      {!hideTop ? (
        <div className={styles.top} onClick={moveTop}>
          <LeftArrowIcon className={styles.topIcon} />
          <label>Move</label>
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}

      {/* Bottom Section */}
      {!hideBottom ? (
        <div className={styles.bottom} onClick={moveBottom}>
          <label>Move</label>
          <RightArrowIcon className={styles.bottomIcon} />
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}
    </div>
  );

  const { carrier } = plan;
  const { logoUrl } = carrier || {};

  const setCurrentConetnt = () => {
    setEditorContent(content);
  };

  const handleDescriptionEditSubmit = (content: string) => {
    const isDescriptionEdited = plan && plan.description !== content;
    // We dont saveContent() if description is not edited as it is useless API call
    if (isDescriptionEdited) {
      setIsSavePlanDescriptionModalVisible(true);
    }
  };

  return (
    <div className={styles.perksCardWrapper}>
      {' '}
      {renderReorder && (
        <div className={styles.moveWrapper}>
          <EditableHotspot
            alwaysVisible={false}
            className={styles.hotSpotHoverContainer}
            customModal={() => <></>}
            overlayComponent={renderHoverMoveOverlay()}
          >
            <div className={styles.moveArea}></div>
          </EditableHotspot>
        </div>
      )}
      <Row>
        <Col span={24}>
          <div className={styles.summaryWrapper}>
            <EditableHotspot
              dataTestId={`additionalPerks-plan${dataIndex}-name`}
              alwaysVisible={false}
              editText="Edit"
              editIcon={<img src={iconEdit} alt="edit" />}
              useChannel={{
                client: upClient,
                channel: ADDITIONAL_PERKS_CHANNEL,
                event: OPEN_ADDITIONAL_PERKS_EDIT_MODAL,
                data: plan,
              }}
            >
              <div className={styles.summary}>
                {logoUrl && (
                  <div className={styles.icon}>
                    <img src={logoUrl} alt="" />
                  </div>
                )}
                <div className={styles.planName}>{plan.name}</div>
                {(!isEmpty(plan.documents) ||
                  !isEmpty(plan.additionalDocuments) ||
                  !isEmpty(plan.additionalWeblinks)) && (
                  <div
                    className={styles.viewDetailsLink}
                    onClick={() => setIsVisible(true)}
                  >
                    Plan Resources
                  </div>
                )}
              </div>
            </EditableHotspot>
          </div>
        </Col>
      </Row>
      <div
        className={
          showAddContentBtn ? styles.addTextWrapper : styles.description
        }
      >
        {!isContentReceived ? (
          <Spinner />
        ) : (
          <EditableHotspot
            dataTestId={`additionalPerks-plan${dataIndex}-description`}
            className={showAddContentBtn ? styles.addTextHotspot : ''}
            alwaysVisible={showAddContentBtn}
            editText={showAddContentBtn ? '' : 'Edit'}
            editIcon={
              showAddContentBtn ? <></> : <img src={iconEdit} alt="edit" />
            }
            customModal={(
              visible: boolean,
              onClose: Function,
              editorKey: string
            ) => (
              <PlanCardEditor
                isOpenModal={visible}
                closeModal={onClose}
                editorContent={editorContent}
                isResetEnabled={true}
                resetToCurrentValue={() => {}}
                resetToDefault={() => {}}
                defaultContent={`<p>${benefitDescription}</p>`}
                updateEditorContent={setEditorContent}
                benguideId={masterId}
                onSubmit={handleDescriptionEditSubmit}
                setCurrentConetnt={setCurrentConetnt}
                editorKey={editorKey}
              />
            )}
          >
            {showAddContentBtn ? (
              <AddYourText />
            ) : (
              <div className={styles.content}>
                <ContentPreviewer
                  htmlContent={
                    isEmpty(editorContent) ? defaultContent : editorContent
                  }
                />
              </div>
            )}
          </EditableHotspot>
        )}
      </div>
      <ViewResourcesModal
        plan={plan}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        benefitKind={benefitKind}
      />
      <SavePlanDescriptionModal
        confirmLoading={isRichMediaLoading}
        isVisible={isSavePlanDescriptionModalVisible}
        onClose={() => {
          setIsSavePlanDescriptionModalVisible(false);
          setEditorContent(plan?.description || '');
        }}
        onConfirm={() => saveContent(editorContent)}
      />
    </div>
  );
};
export default AdditionalPerksCard;
