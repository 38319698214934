import { useAppDispatch } from 'hooks/redux';
import {
  enableEditMode,
  loggedInUserType,
} from 'modules/auth/slices/benguideSlice';
import UPClient from 'modules/clients/UPClient/UPClient';
import {
  ENABLE_EDIT_MODE,
  UP_COMMON_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';

const UPCommonChannel = () => {
  const dispatch = useAppDispatch();
  return (
    <UPClient
      channel={UP_COMMON_CHANNEL}
      subscribe={(event: string, data: any) => {
        if (ENABLE_EDIT_MODE === event) {
          dispatch(enableEditMode());
          dispatch(loggedInUserType(data?.userType));
        }
      }}
    />
  );
};

export default UPCommonChannel;
