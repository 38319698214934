import { FC, useEffect, useRef, useState } from 'react';
import { Modal, Row, Select, Switch } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import BenefitsConsultation from 'components/BenefitsConsultation/BenefitsConsultation';
import EditableHotspot, {
  SIDE_BAR,
} from 'components/EditableHotspot/EditableHotspot';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import iconEdit from 'images/icon-edit.svg';
import iconScheduleConsultation from 'images/icons/icon-ScheduleConsultation.svg';
import { ReactComponent as ContactSupportIcon } from 'images/icons/side-nav/contact-support.svg';
import {
  BC_STEP_ONE,
  BC_STEP_THREE,
  BC_STEP_TWO,
  CONSULTANT_TYPES,
} from 'constants/commonConstants';
import { SectionName } from 'modules/home/constants';
import {
  checkIfConsultantsExist,
  createRichMediaSupport,
  getBenefitConsultationConfigData,
  getContactSupportConfigdata,
  getContentRichMediaSupport,
  getDefaultRichMediaBC,
  getDefaultRichMediaSupport,
} from 'modules/home/slices/contactSupportSlice';
import BenconsultationConfig from 'model/BenConsultationConfig';
import { trackEvents } from 'utils/initGA4';
import styles from './contactSupport.module.less';

const { Option } = Select;

type ContactSupportProps = {
  bcStep?: string;
  showButton?: boolean;
  openScheduleModal?: boolean;
  setOpenScheduleModal?: Function;
  btnWrapperClassName?: string;
  className?: string;
};

const ContactSupport: FC<ContactSupportProps> = ({
  bcStep = BC_STEP_ONE,
  showButton = true,
  openScheduleModal = false,
  setOpenScheduleModal,
  btnWrapperClassName = '',
  className = '',
}: ContactSupportProps) => {
  const textEditorRef = useRef<any>(null);
  const modalRef = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    defaultTextSupport,
    contentSupport,
    contentSupportBC,
    contentSupportSuccess,
    contactSupportConfig,
    benefitConsultationConfig,
    defaultTextBC,
    consultantConfirmationBroker,
    consultantConfirmationEmployer,
  } = useAppSelector((state) => state.contactSupport);
  const benConsultation = useAppSelector(
    (state) => state.benefitsConsultation.benConsultation
  );

  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const { isEditMode } = useBenguide();
  const { masterId } = benguideData || {};
  const { locationId, brokerServiceId, employerServiceId, clientId } =
    benefitConsultationConfig || ({} as BenconsultationConfig);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState<SectionName>(
    SectionName.CONTACT_SUPPORT_GENERAL
  );
  const [checked, setChecked] = useState<boolean>(false);
  const [contentData, setContentData] = useState<string>('');
  const [contentDataBC, setContentDataBC] = useState<string>('');
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [step, setStep] = useState<string>(bcStep);
  const [consultationType, setConsultationType] = useState<string>('');
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const benGuide = useAppSelector((state) => state.benguide.data);

  const [isBrokerConsultantExist, setIsBrokerConsultantExist] =
    useState<boolean>(false);
  const [isEmployerConsultantExist, setIsEmployerConsultantExist] =
    useState<boolean>(false);

  useEffect(() => {
    if (consultantConfirmationBroker) {
      setIsBrokerConsultantExist(checkIfBrokerExist());
    }
    if (consultantConfirmationEmployer) {
      setIsEmployerConsultantExist(checkIfEmployerExist());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantConfirmationBroker]);

  const checkIfBrokerExist = (): boolean => {
    const exist = consultantConfirmationBroker.find((obj: any) => {
      const foundEmployer = obj.mappedEmployers.find(
        (emp: any) => emp.employerId === benGuide.employerId
      );
      if (obj.status.status && foundEmployer) return true;
    });
    return !!exist;
  };

  const checkIfEmployerExist = (): boolean => {
    const exist = consultantConfirmationEmployer.find(
      (emp: any) => emp.status.status
    );
    return !!exist;
  };

  useEffect(() => {
    if (isEditMode) {
      dispatch(
        checkIfConsultantsExist(
          CONSULTANT_TYPES.ER,
          benGuide.orgId,
          benGuide.employerId
        )
      );
      dispatch(
        checkIfConsultantsExist(
          CONSULTANT_TYPES.BROKER,
          benGuide.orgId,
          benGuide.employerId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benConsultation]);

  useEffect(() => {
    dispatch(
      getBenefitConsultationConfigData(
        benguideData.orgId,
        benguideData.employerId
      )
    );
    if (masterId && contentSupportSuccess) {
      dispatch(getContactSupportConfigdata(masterId));
    }
  }, [contentSupportSuccess, dispatch, masterId, benguideData]);

  useEffect(() => {
    if (masterId && !modalRef.current && !openPreviewModal) {
      dispatch(
        getDefaultRichMediaSupport(
          masterId,
          SectionName.CONTACT_SUPPORT_GENERAL
        )
      );
      dispatch(
        getContentRichMediaSupport(
          masterId,
          SectionName.CONTACT_SUPPORT_GENERAL
        )
      );
      dispatch(getDefaultRichMediaBC(masterId));
      dispatch(
        getContentRichMediaSupport(
          masterId,
          SectionName.CONTACT_SUPPORT_BENEFIT_CONSULTATION
        )
      );
    }
  }, [dispatch, masterId, openPreviewModal]);

  useEffect(() => {
    if (contactSupportConfig && contactSupportConfig !== null) {
      setChecked(contactSupportConfig && contactSupportConfig.enabled);
    }
  }, [contactSupportConfig]);

  useEffect(() => {
    if (!modalRef.current && !openPreviewModal) {
      setContentData(contentSupport);
      setContentDataBC(contentSupportBC);
    }
  }, [
    contactSupportConfig,
    contentSupport,
    contentSupportBC,
    openPreviewModal,
  ]);

  useEffect(() => {
    setOpenPreviewModal(openScheduleModal);
  }, [openScheduleModal]);

  useEffect(() => {
    if (isModalVisible) {
      textEditorRef?.current?.changeEditorContent(contentSupport);
    }
  }, [isModalVisible, contentSupport]);

  const selectRichTextType = (value: SectionName) => {
    setSelectionType(value);

    if (value === SectionName.CONTACT_SUPPORT_GENERAL) {
      textEditorRef?.current?.changeEditorContent(contentData);
    } else {
      textEditorRef?.current?.changeEditorContent(contentDataBC);
    }
  };

  const onSwitch = (value: boolean) => {
    setChecked(value);
  };

  const closeSupportModal = (onClose: Function) => {
    setSelectionType(SectionName.CONTACT_SUPPORT_GENERAL);

    setContentDataBC(contentSupportBC);
    setContentData(contentSupport);

    if (contactSupportConfig && contactSupportConfig !== null) {
      setChecked(contactSupportConfig && contactSupportConfig.enabled);
    }
    onClose();
  };

  const resetToDefault = () => {
    if (selectionType === SectionName.CONTACT_SUPPORT_GENERAL) {
      textEditorRef?.current?.clearTextEditorContent(defaultTextSupport);
    } else {
      textEditorRef?.current?.clearTextEditorContent(defaultTextBC);
    }
  };

  const onChangeTextEditor = (data: string) => {
    if (selectionType === SectionName.CONTACT_SUPPORT_GENERAL) {
      setContentData(data);
    } else {
      setContentDataBC(data);
    }
  };

  const saveSupportData = async (onClose: Function) => {
    await dispatch(
      createRichMediaSupport(
        masterId,
        SectionName.CONTACT_SUPPORT_GENERAL,
        contentData,
        checked
      )
    );
    await dispatch(
      createRichMediaSupport(
        masterId,
        SectionName.CONTACT_SUPPORT_BENEFIT_CONSULTATION,
        contentDataBC
      )
    );
    onClose();
    setSelectionType(SectionName.CONTACT_SUPPORT_GENERAL);
  };

  const openPreview = () => {
    setOpenPreviewModal(!openPreviewModal);
  };

  const closePreview = () => {
    modalRef.current = true;
    setOpenPreviewModal(!openPreviewModal);
    setOpenScheduleModal && setOpenScheduleModal(false);
    setStep(bcStep);
  };

  const openContactSupportPreview = () => {
    if (!isEditMode) {
      openPreview();
    }
  };

  const isEmployerConsultationEnabled = () => {
    if (isEditMode) {
      return (
        benConsultation?.employerBenefitConsultationEnabled &&
        isEmployerConsultantExist
      );
    }
    return benConsultation?.employerBenefitConsultationEnabled;
  };
  const isBrokerConsultationEnabled = () => {
    if (isEditMode) {
      return (
        benConsultation?.brokerBenefitConsultationEnabled &&
        isBrokerConsultantExist
      );
    }
    return benConsultation?.brokerBenefitConsultationEnabled;
  };

  const showBC =
    benConsultation?.brokerBenefitConsultationEnabled ||
    benConsultation?.employerBenefitConsultationEnabled;

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setSaveDisable(true)}
      />
      <div className={`${styles.contactSupportWrapper} ${className}`}>
        {showButton && (
          <EditableHotspot
            dataTestId="contact-support"
            className={btnWrapperClassName}
            section={SIDE_BAR}
            alwaysVisible
            customModal={(visible: boolean, onClose: Function) => {
              setModalVisible(visible);
              return (
                <HalfScreenModal
                  visible={visible}
                  onCancel={() => closeSupportModal(onClose)}
                  wrapClassName={styles.supportModalWrapper}
                  confirmModalShow={true}
                  onReset={() => resetToDefault()}
                  onSubmit={() => saveSupportData(onClose)}
                  isResetEnabled={checked}
                  afterClose={() => textEditorRef?.current?.resetUndo()}
                >
                  <div>
                    <Row>
                      <span className={styles.overlay}>Support Overlay </span>
                      <LinkButton
                        className={styles.previewBtn}
                        onClick={openPreview}
                      >
                        Preview
                      </LinkButton>
                    </Row>
                    <Row className={styles.supportRow}>
                      <span className={styles.subOverlay}>
                        Display Support Button?
                      </span>
                      <div className={styles.switch}>
                        <Switch
                          onChange={onSwitch}
                          defaultChecked={checked}
                          checked={checked}
                        />
                      </div>
                    </Row>
                    <Row
                      className={`${styles.supportRow} ${
                        showBC ? '' : styles.hidden
                      }`}
                    >
                      <span className={styles.subOverlay}>
                        Select Selection to Edit:{' '}
                      </span>

                      <Select
                        defaultValue={selectionType}
                        onChange={(value) => selectRichTextType(value)}
                        disabled={!checked}
                        value={selectionType}
                      >
                        <Option
                          value={SectionName.CONTACT_SUPPORT_GENERAL}
                          key={'general'}
                        >
                          General
                        </Option>
                        <Option
                          value={
                            SectionName.CONTACT_SUPPORT_BENEFIT_CONSULTATION
                          }
                          key={'bc'}
                        >
                          Benefits Consultation
                        </Option>
                      </Select>
                    </Row>
                    <Row
                      className={`${styles.supportRow} ${
                        checked ? '' : styles.textEditor
                      }`}
                    >
                      <RichTextEditor
                        limit={1500}
                        onChange={onChangeTextEditor}
                        ref={textEditorRef}
                        defaultText={contentData}
                        initialValue={
                          contentData !== defaultTextSupport
                            ? contentData
                            : defaultTextSupport
                        }
                        masterId={masterId}
                        disabled={!checked}
                        showMediaUpload={false}
                      />
                    </Row>
                  </div>
                </HalfScreenModal>
              );
            }}
          >
            {(isEditMode || checked) && (
              <SubmitButton
                onClick={() => openContactSupportPreview()}
                className={`${styles.contactSupportButton} ${
                  isEditMode
                    ? styles.editContactSupport
                    : styles.previewContactSupport
                }`}
                wrapperClassName={styles.contactSupportBtnWrapper}
              >
                {!isEditMode && (
                  <ContactSupportIcon className={styles.previewContactIcon} />
                )}
                <span
                  className={`${styles.supportButton} ${
                    isEditMode ? '' : styles.previewSupport
                  }`}
                >
                  {checked ? 'Contact Support' : 'Add Support Button'}
                </span>
                {isEditMode && (
                  <img className={styles.editIcon} src={iconEdit} alt="Edit" />
                )}
              </SubmitButton>
            )}
          </EditableHotspot>
        )}

        <Modal
          onCancel={() => {
            setOpenPreviewModal(false);
            setOpenScheduleModal && setOpenScheduleModal(false);
            setStep(bcStep);
            trackEvents({
              category: 'Benefits Consultation',
              action: 'close_benefits_consultation',
              label: 'Close Benefits Consultation',
            });
          }}
          centered
          visible={openPreviewModal}
          wrapClassName={`${styles.previewWrapper} ${
            step === BC_STEP_THREE ? styles.widget : ''
          }`}
          closeIcon={
            step === BC_STEP_THREE ? <CloseCircleOutlined /> : undefined
          }
          footer={null}
          maskClosable={false}
          destroyOnClose
          title={`${
            step === BC_STEP_ONE
              ? 'Need Benefits Assistance?'
              : step === BC_STEP_TWO
              ? 'Schedule a Benefits Consultation'
              : `${consultationType} Consultation`
          }`}
        >
          <div className={styles.preview}>
            {step === BC_STEP_ONE && (
              <>
                <ContentPreviewer htmlContent={contentData} />
                {showBC && (
                  <>
                    <ContentPreviewer htmlContent={contentDataBC} />
                    <div className={styles.scheduleLink}>
                      <img
                        className={styles.editIcon}
                        src={iconScheduleConsultation}
                        alt="schedule-consultation"
                      />
                      <div className={styles.scheduleConsultContainer}>
                        <div
                          className={` ${
                            isEditMode ? styles.cursorDisabled : ''
                          }`}
                        >
                          <span
                            className={`${styles.lblScheduleConsult} ${
                              isEditMode ? styles.disabled : ''
                            }`}
                            onClick={() => {
                              setStep(BC_STEP_TWO);
                              trackEvents({
                                category: 'Benefits Consultation',
                                action: 'schedule_consultation',
                                label: 'Schedule Consultation',
                              });
                            }}
                          >
                            Schedule a Consultation
                          </span>
                        </div>

                        <span className={styles.lblSubScheduleConsult}>
                          One-on-one 20 min consultation
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {(step === BC_STEP_TWO || step === BC_STEP_THREE) && (
            <BenefitsConsultation
              brokerServiceId={brokerServiceId}
              employerServiceId={employerServiceId}
              clientId={clientId}
              locationId={locationId}
              setConsultationType={setConsultationType}
              consultationType={consultationType}
              setStep={setStep}
              employerConsultationEnabled={isEmployerConsultationEnabled()}
              brokerConsultationEnabled={isBrokerConsultationEnabled()}
            />
          )}
          {step !== BC_STEP_THREE && (
            <SubmitButton
              wrapperClassName={styles.modalCloseBtn}
              onClick={closePreview}
              className={isEditMode ? styles.closeBtn : styles.closeBtnBlack}
            >
              {isEditMode ? 'Back to Editing' : 'Close'}
            </SubmitButton>
          )}
        </Modal>
      </div>
    </NotificationContextProvider>
  );
};

export default ContactSupport;
