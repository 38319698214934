import { FC } from 'react';
import iconClose from 'images/plan-recommender/icon-close.png';

import styles from './PlanRecommenderPopup.module.less';

type PlanRecommenderPopup = {
  closeModal: Function;
  clickStart: Function;
};

const PlanRecommenderPopup: FC<PlanRecommenderPopup> = ({
  closeModal,
  clickStart,
}: PlanRecommenderPopup) => {
  return (
    <div className={styles.planRecommenderModal}>
      <div className={styles.modalContentWrapper}>
        <div
          className={styles.closeIcon}
          onClick={() => {
            closeModal();
          }}
        >
          <img src={iconClose} alt="close" />
        </div>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            Your Go-To Plan Recommender Assistant
          </div>
          <div className={styles.subTitle}>
            {`Picking benefits can be tricky. Try out the plan
            recommender to see which plans are best suited for you.`}
            <br />
            {`Just answer a few questions, and we'll do the rest.`}
          </div>

          <button
            className={styles.getStartedBtn}
            onClick={() => {
              clickStart();
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanRecommenderPopup;
