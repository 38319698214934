import { FC } from 'react';
import { FREQUENCY_RATE_DISPLAY_MAP } from 'modules/planRecommender/pages/constants';

import styles from './cardTitles.module.less';

type CardTitlesProps = {
  frequency?: any;
  allowHeaderTitle: any;
  planType?: any;
  keys: any;
};

const CardTitles: FC<CardTitlesProps> = (props: CardTitlesProps) => {
  const { frequency, allowHeaderTitle, planType, keys } = props;
  const getFeildName = (title: any) => {
    return title.fieldName === 'monthlyCost'
      ? frequency !== undefined
        ? FREQUENCY_RATE_DISPLAY_MAP[frequency]
        : title.fieldValue
      : title.fieldValue;
  };

  return (
    <div className="title-container-wrapper">
      <div
        // auto_id="plan-card-titles"
        className={`${styles.titleContainer} ${styles.titleContainerMargin}`}
      >
        {keys.map((titleGroup: any, titleGroupIndex: any) => {
          const titleGroupSize = titleGroup.length;

          return titleGroup.map((title: any, titleIndex: any) => {
            if (title.fieldValue.length > 0) {
              if (titleGroupIndex === 0 && allowHeaderTitle) {
                if (planType === 'medical') {
                  return (
                    <div key={Math.random()} className={styles.titleItem}>
                      {getFeildName(title) === 'Plan Name' && (
                        <div
                          className={`${styles.titleTop} ${styles.medicalPlanName}`}
                        >
                          <span className={styles.titleValue}>
                            {getFeildName(title)}
                          </span>
                          {title.fieldDescription ? (
                            <span
                              className={`${styles.popover} ${
                                title.fieldDescription.length > 50
                                  ? 'large'
                                  : ''
                              }`}
                            >
                              {title.fieldDescription}
                            </span>
                          ) : title.fieldValue.length > 20 ? (
                            <span className={styles.popover}>
                              {title.fieldValue}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      )}
                      {getFeildName(title) !== 'Plan Name' && (
                        <div
                          className={`${styles.titleTop} ${styles.medicalMonthlyCost}`}
                        >
                          <span className={styles.titleValue}>
                            {getFeildName(title)}
                          </span>
                          {title.fieldDescription ? (
                            <span
                              className={`${styles.popover} ${
                                title.fieldDescription.length > 50
                                  ? styles.large
                                  : ''
                              }`}
                            >
                              {title.fieldDescription}
                            </span>
                          ) : title.fieldValue.length > 20 ? (
                            <span className={styles.popover}>
                              {title.fieldValue}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div key={Math.random()} className={styles.titleItem}>
                      <div className={styles.titleTop}>
                        <span className={styles.titleValue}>
                          {getFeildName(title)}
                        </span>
                        {title.fieldDescription ? (
                          <span
                            className={`${styles.popover} ${
                              title.fieldDescription.length > 50
                                ? styles.large
                                : ''
                            }`}
                          >
                            {title.fieldDescription}
                          </span>
                        ) : title.fieldValue.length > 20 ? (
                          <span className={styles.popover}>
                            {title.fieldValue}
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  );
                }
              } else {
                if (titleGroupSize === titleIndex + 1) {
                  return (
                    <div key={Math.random()} className={styles.titleItem}>
                      <div className={styles.title}>
                        <span className={styles.titleValue}>
                          {title.fieldValue}
                        </span>
                        {title.fieldDescription ? (
                          <span
                            className={`${styles.popover} ${
                              title.fieldDescription.length > 50 ? 'large' : ''
                            }`}
                          >
                            {title.fieldDescription}
                          </span>
                        ) : title.fieldValue.length > 20 ? (
                          <span className={styles.popover}>
                            {title.fieldValue}
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <div className={styles.titleSeperator}></div>
                    </div>
                  );
                } else {
                  return (
                    <div key={Math.random()} className={styles.titleItem}>
                      <div className={styles.title}>
                        <span className={styles.titleValue}>
                          {title.fieldValue}
                        </span>
                        {title.fieldDescription ? (
                          <span
                            className={`${styles.popover} ${
                              title.fieldDescription.length > 50
                                ? styles.large
                                : ''
                            }`}
                          >
                            {title.fieldDescription}
                          </span>
                        ) : title.fieldValue.length > 20 ? (
                          <span className={styles.popover}>
                            {title.fieldValue}
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  );
                }
              }
            }
            return <div key={Math.random()}></div>;
          });
        })}
      </div>
    </div>
  );
};

export default CardTitles;
