import { FC } from 'react';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import { EMPLOYER_FREQUENCY_PLAN_LABEL_MAPPING } from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import ContributionField from 'modules/taxAdvantaged/components/ContributionField/ContributionField';
import { hasErContributionValue } from 'modules/taxAdvantaged/taxAdvantageUtil';

import styles from './dcapFsaPlanInfo.module.less';

type Props = {
  plan: TaxAdvantageAccountsPlan;
};

const DcapFsaPlanInfo: FC<Props> = (props: Props) => {
  const { plan } = props;
  const { employerContribution } = plan;

  return (
    <div className={styles.container}>
      {employerContribution &&
        employerContribution.frequency &&
        hasErContributionValue(employerContribution) && (
          <div className={styles.empContribution}>
            <div className={styles.frequency}>{`Employer Contribution per ${
              EMPLOYER_FREQUENCY_PLAN_LABEL_MAPPING[
                employerContribution.frequency
              ]
            }`}</div>
            <ContributionField
              label=""
              value={employerContribution.empContribution}
              type="employer"
            />
          </div>
        )}
      <div className={styles.frequency}>
        {employerContribution.otherContribution}
      </div>
      {plan.maxIndividualContributionForMarriedButFilledAsSeparately !== null &&
        plan.maxIndividualContributionForMarriedButFilledAsSeparately !==
          undefined && (
          <ContributionField
            label="Max Yearly Contribution Married Filing Separately"
            value={
              plan.maxIndividualContributionForMarriedButFilledAsSeparately
            }
          />
        )}
      {plan.maxMarriedContributionForFillingJointlyOrSingleParentAsHead !==
        null &&
        plan.maxMarriedContributionForFillingJointlyOrSingleParentAsHead !==
          undefined && (
          <ContributionField
            label="Max Yearly Contribution Married Filing Jointly or Single Parent"
            value={
              plan.maxMarriedContributionForFillingJointlyOrSingleParentAsHead
            }
          />
        )}
      {plan.endOfYearPolicy && (
        <ContributionField
          label="End of Year Policy"
          valueText={plan.endOfYearPolicy}
          valueEndOfYearPolicyField={true}
        />
      )}
    </div>
  );
};

export default DcapFsaPlanInfo;
