import { FC } from 'react';
import {
  DEFAULT_TEXT,
  RATE_TIERS,
  SUPER_SCRIPTS,
} from 'modules/planRecommender/pages/constants';
import { ReactComponent as RankIconStarGreen } from 'images/plan-recommender/icon-rank-star-green.svg';
import rankIcon from 'images/plan-recommender/icon-rank.svg';
import metadata from 'data/metadata';
import { currencyFormatterForSignedNumbers } from 'utils/commonUtil';
import { getCardFieldValue } from 'utils/planRecommenderUtils/planRecommenderUtils';

import styles from './planSummaryBox.module.less';

type PlanSummaryBoxProps = {
  plan: any;
  dbgFrequency: string;
};

const PlanSummaryBox: FC<any> = (props: PlanSummaryBoxProps) => {
  const { plan, dbgFrequency } = props;

  const filterRateTier = (plan: any) => {
    return RATE_TIERS.find((tier) => tier.fourTierValue === plan.rateTier);
  };

  const formatFieldValue = (
    key: any,
    isTooltip = false,
    hasDollar = true,
    shouldLowerCase = true
  ) => {
    const rate = filterRateTier(plan);
    const network = 'InNetwork';
    let value = getCardFieldValue(
      plan,
      key,
      network,
      rate,
      hasDollar,
      shouldLowerCase,
      dbgFrequency
    );
    value =
      !value || value.toString().trim().length === 0
        ? 'defaultValue' in key
          ? key.defaultValue
          : DEFAULT_TEXT
        : value;
    return clearSuperScripts(value, !isTooltip);
  };

  const clearSuperScripts = (value: any, withElement = true) => {
    return SUPER_SCRIPTS.reduce((current, superscript) => {
      if (value.indexOf(superscript.strValue) >= 0 && withElement) {
        const elements = value.split(superscript.strValue);
        return elements.map((elem: any) =>
          elem && elem.length > 0 ? <>{elem}</> : <></>
        );
      } else {
        if (value.indexOf(superscript.strValue) >= 0 && !withElement) {
          const search = new RegExp(superscript.strValue, 'g');
          return value.replace(search, `(${superscript.superScriptValue})`);
        } else {
          return current;
        }
      }
    }, value);
  };

  const getRankIcon = (rank: number) => {
    if (rank === 1) {
      return (
        <div className={styles.planRating}>
          <RankIconStarGreen className={styles.planRatingIcon} />
        </div>
      );
    } else {
      return (
        <div className={styles.planRating}>
          <img src={rankIcon} alt="" className={styles.planRatingIcon} />
          <div className={styles.rankText}>#{rank}</div>
        </div>
      );
    }
  };

  const { planRecommenderPlanKeys: keys } = metadata;
  const { benefitCarrier, name, hsaCompatible } = plan;
  const {
    estAnnualBreakdown = {},
    annualPremiumConfig = {},
    oopMaxIncluded,
    medicalCostIncluded,
    rank,
    type: planType,
    premiumIncluded,
    hsaCompatiblePlan,
    hraCompatiblePlan,
  } = plan;
  const { premiumFrequency = '' } = annualPremiumConfig;
  const {
    annualPremiumCost = 0,
    estMedicalCost = 0,
    estAnnualCost = 0,
    hsaContribution = 0,
    oopMaxCost = 0,
    hraContribution = 0,
  } = estAnnualBreakdown;

  const rateTier = filterRateTier(plan) as any;

  let premiumRate = '';
  if (premiumFrequency) {
    if (premiumFrequency.includes('_')) {
      premiumRate = premiumFrequency.replace(/_/g, ' ');
    } else {
      premiumRate = premiumFrequency;
    }
  }

  premiumRate = premiumRate.toLowerCase();

  const classes = [styles.planRecommended];

  if (rank !== 1) {
    classes.push(styles.false);
  }
  let planTypeHeader = '';
  if (hsaCompatible) {
    planTypeHeader = 'HSA';
  } else {
    planTypeHeader = planType;
  }
  const planNameTitle = name + ' (' + planTypeHeader + ')';

  return (
    <div className={styles.planSummaryBoxWrapper}>
      <div className={classes.join(' ')}>
        <div>
          <div className={styles.planRating}>{getRankIcon(rank)}</div>
          <div className={styles.planName} title={planNameTitle}>
            {name}
            {planType && (
              <div className={styles.planType}>
                <i className={styles.planTypeText}>({planTypeHeader})</i>
              </div>
            )}
          </div>
          <img
            className={styles.carrierLogo}
            src={benefitCarrier?.logoUrl}
            alt=""
          />
        </div>
        <div className={styles.premiumBlock}>
          <div className={styles.planSubTitle}>Premium</div>
          <div>
            <span className={styles.premiumValue}>
              {formatFieldValue(keys[0])}
            </span>
            {premiumRate === '' ? (
              <div className={styles.noRatesDash}>
                {' '}
                <span> - </span>
              </div>
            ) : (
              <span>/{premiumRate}</span>
            )}
          </div>
          <div className={styles.valueFooter}>{rateTier.coverageLabel}</div>
        </div>
        <div className={styles.deductibleBlock}>
          <div className={styles.planSubTitle}>Deductible</div>
          <div>
            <span className={styles.deductibleValue}>
              {formatFieldValue(keys[1])}
            </span>
          </div>
          <div className={styles.valueFooter}> </div>
        </div>
        <div className={styles.otherNamesBlock}>
          <div className={styles.lineSpacingDataFirst}>Out-of-Pocket Max</div>
          <div className={styles.lineSpacingData}>Primary/Specialist Copay</div>
          <div className={styles.lineSpacingData}>Inpatient/Outpatient</div>
          <div className={styles.lineSpacingData}>
            Retail Rx Tier 1 / 2 / 3 / 4
          </div>
        </div>
        <div className={styles.otherValuesBlock}>
          <div className={styles.planSubTitle}>
            {' '}
            {formatFieldValue(keys[2])}
          </div>
          <div
            className={styles.lineSpacingData}
            title={formatFieldValue(keys[3])}
          >
            {' '}
            {formatFieldValue(keys[3])}{' '}
          </div>
          <div
            className={styles.lineSpacingData}
            title={`${formatFieldValue(keys[4])} / ${formatFieldValue(
              keys[5]
            )}`}
          >
            {formatFieldValue(keys[4])} / {formatFieldValue(keys[5])}
          </div>
          <div
            className={styles.lineSpacingData}
            title={formatFieldValue(keys[6])}
          >
            {formatFieldValue(keys[6])}
          </div>
        </div>
        <div>
          <hr className={styles.separatorPlan} />
        </div>
        <div>
          <div className={styles.planFooterTitleCol}>
            <div>Est. Annual </div>
            <div>Cost Breakdown</div>
            <div></div>
          </div>
          {premiumIncluded && (
            <div className={styles.planFooterValuesCol}>
              <div>Annual Premium Cost</div>
              <div className={styles.planFooterValuesRed}>
                {currencyFormatterForSignedNumbers(annualPremiumCost)}
              </div>
            </div>
          )}
          {medicalCostIncluded && (
            <div className={styles.planFooterValuesCol}>
              <div>Estimated Medical Cost</div>
              <div className={styles.planFooterValuesRed}>
                {currencyFormatterForSignedNumbers(estMedicalCost)}
              </div>
            </div>
          )}
          {oopMaxIncluded && (
            <div className={styles.planFooterValuesCol}>
              <div>OOP MAX</div>
              <div className={styles.planFooterValuesRed}>
                {currencyFormatterForSignedNumbers(oopMaxCost)}
              </div>
            </div>
          )}
          {hsaCompatiblePlan && (
            <div className={styles.planFooterValuesCol}>
              <div>HSA Contribution</div>
              <div className={styles.planFooterValuesGreen}>
                {'-'}
                {currencyFormatterForSignedNumbers(hsaContribution)}
              </div>
            </div>
          )}
          {hraCompatiblePlan && (
            <div className={styles.planFooterValuesCol}>
              <div>HRA Allowance</div>
              <div className={styles.planFooterValuesGreen}>
                {'-'}
                {currencyFormatterForSignedNumbers(hraContribution)}
              </div>
            </div>
          )}

          <div className={styles.planFooterValuesCol}>
            <div>Estimated Annual Cost</div>
            <div className={styles.planFooterValuesBlack}>
              {currencyFormatterForSignedNumbers(estAnnualCost)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanSummaryBox;
