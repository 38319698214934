import { Row } from 'antd';
import { useAppDispatch } from 'hooks/redux';
import { setEmployeeModal } from 'modules/auth/slices/employeeAuthSlice';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import styles from './sendEmail.module.less';

const SendEmail = () => {
  const dispatch = useAppDispatch();
  return (
    <Row justify="center">
      <div>
        <h1 className={styles.textWrapper}>
          Please check your email inbox for a registration link to create an
          account.
        </h1>
        <SubmitButton
          type="primary"
          onClick={() => {
            dispatch(setEmployeeModal(false));
          }}
          htmlType="button"
          className={styles.submitPwdButton}
        >
          Close
        </SubmitButton>
      </div>
    </Row>
  );
};

export default SendEmail;
