import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import BenefitGuide from 'model/BenefitGuide';
import BenefitPage from 'model/BenefitPage';
import ContentPage from 'model/CotentPage';
import OEMeeting from 'model/oeMeeting/OEMeeting';
import {
  ADDITIONAL_CONTENT_OPTIONS,
  BenefitSection,
  BENEFIT_TYPES,
} from 'modules/home/constants';
import {
  getDateWithTimezoneFormatted,
  DATE_TIME_FORMAT_DEFAULT,
} from 'utils/dateUtil';
import OEMeetingType from './enums/OEMeetingType';

type MeetingState = {
  notStarted: boolean;
  inProgress: boolean;
  finished: boolean;
  recorded: boolean;
  enabled: boolean;
};

// Please note that notStarted, inProgress, finished states are only relevant if oe meeting is enabled
// and the meeting type is future, despite the actual data.
export const getOEMeetingState = (oeMeeting: OEMeeting): MeetingState => {
  const meetingStates: MeetingState = {
    notStarted: false,
    inProgress: false,
    finished: false,
    recorded: false,
    enabled: oeMeeting.displayMeetingInfo,
  };

  if (
    oeMeeting.displayMeetingInfo &&
    oeMeeting.oeMeetingType === OEMeetingType.FUTURE &&
    oeMeeting.meetingTime
  ) {
    const nowFormatted = getDateWithTimezoneFormatted(
      dayjs(),
      oeMeeting.timezone,
      DATE_TIME_FORMAT_DEFAULT
    );

    const startTime = dayjs(oeMeeting.meetingTime);
    const startTimeFormatted = startTime.format(DATE_TIME_FORMAT_DEFAULT);
    const endTimeFormatted = startTime
      .add(oeMeeting.durationMinutes, 'minute')
      .format(DATE_TIME_FORMAT_DEFAULT);
    if (
      nowFormatted >= startTimeFormatted &&
      nowFormatted <= endTimeFormatted
    ) {
      meetingStates.inProgress = true;
    } else if (nowFormatted < startTimeFormatted) {
      meetingStates.notStarted = true;
    } else if (nowFormatted > endTimeFormatted) {
      meetingStates.finished = true;
    }
  }
  if (
    oeMeeting.displayMeetingInfo &&
    oeMeeting.oeMeetingType === OEMeetingType.RECORDED
  ) {
    meetingStates.recorded = true;
  }

  return meetingStates;
};

export const getBenefitFromValue = (value: string) => {
  const selection = Object.values(BENEFIT_TYPES).find((benefit) => {
    return benefit.value === value;
  });
  return selection || null;
};

export const getContentTypeFromValue = (value: string) => {
  const selection = Object.values(ADDITIONAL_CONTENT_OPTIONS).find(
    (option) => option.value === value
  );
  return selection || null;
};

export const getPlansForBenefit = (value: string, benGuide: BenefitGuide) => {
  if ((benGuide as any)[value]) {
    return (benGuide as any)[value].plans;
  }
  return [];
};

export const getBenefitSectionsWithPlans = (
  benGuide: BenefitGuide,
  plansCount?: boolean
) => {
  const sectionsWithPlans = Object.values(BenefitSection).filter((section) => {
    const benefitPage: BenefitPage = (benGuide as any)[section];
    return benefitPage && plansCount;
  });
  return sectionsWithPlans;
};

export const isPlanYearChangeValid = (
  value: string,
  isEdit: boolean,
  benGuide: BenefitGuide | null,
  plansExist: boolean
) => {
  return (
    value &&
    isEdit &&
    benGuide &&
    getBenefitSectionsWithPlans(benGuide, plansExist).length > 0 &&
    benGuide.planYearId !== value
  );
};

export const isBenefitClassChangeValid = (
  value: string,
  isEdit: boolean,
  benGuide: BenefitGuide | null,
  plansExist: boolean
) => {
  return (
    value &&
    isEdit &&
    benGuide &&
    getBenefitSectionsWithPlans(benGuide, plansExist).length > 0 &&
    !isEqual(value, benGuide.benefitClasses)
  );
};

export const hasCustomContent = (
  value: string,
  benGuide: BenefitGuide
): boolean => {
  if ((benGuide as any)[value]) {
    const sections = ((benGuide as any)[value] as ContentPage).sections;
    if (sections && !isEmpty(sections)) {
      const sectionWithCustomContent = sections.find((section) => {
        return (
          (section.data.contentRefId &&
            section.data.contentRefId !== section.data.defaultContentRefId) ||
          (section.data.imageReference &&
            section.data.imageReference !== section.data.defaultImageReference)
        );
      });
      return Boolean(sectionWithCustomContent);
    }
  }
  return false;
};
