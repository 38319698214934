import { FC, useEffect } from 'react';

import { Alert, AlertProps } from 'antd';

import alertError from 'images/icons/alert-error.svg';
import alertInfo from 'images/icons/alert-info.svg';
import alertSuccess from 'images/icons/alert-success.svg';
import alertWarning from 'images/icons/alert-warning.svg';

import styles from './alertMessage.module.less';

interface Props extends AlertProps {
  closeAlert?: () => void;
  wrapperClassName?: string;
  closeTextStatus?: boolean;
}

const ALERT_TYPE = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

export type AlertInfo = {
  type: 'success' | 'error' | 'info' | 'warning' | undefined;
  message: string | React.ReactNode;
};

const AlertMessage: FC<Props> = (props: Props) => {
  const {
    type,
    message,
    icon,
    closeAlert,
    className = '',
    wrapperClassName = '',
    closeTextStatus = true,
  } = props;

  useEffect(() => {
    const time = setTimeout(() => {
      if (closeAlert) closeAlert();
    }, 15000);
    return () => clearTimeout(time);
  }, [closeAlert]);

  const closeText = closeTextStatus ? (
    <span onClick={closeAlert}>Close</span>
  ) : (
    false
  );

  const style = closeTextStatus
    ? styles.alertWrapper
    : styles.alertWrapperExtended;

  return (
    <div className={`${style} ${wrapperClassName}`}>
      <Alert
        type={type}
        description={message}
        icon={icon ? icon : getAlertIconByType(type)}
        closeText={closeText}
        showIcon
        className={`${styles.alert} ${className}`}
      />
    </div>
  );
};

const getAlertIconByType = (type: string | undefined) => {
  switch (type) {
    case ALERT_TYPE.success:
      return <img src={alertSuccess} alt="success icon" />;
    case ALERT_TYPE.error:
      return <img src={alertError} alt="error icon" />;
    case ALERT_TYPE.info:
      return <img src={alertInfo} alt="info icon" />;
    case ALERT_TYPE.warning:
      return <img src={alertWarning} alt="warning icon" />;
  }
};

export default AlertMessage;
