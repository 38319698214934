import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import Employer from 'model/Employer';
import PlanYear from 'model/PlanYear';
import * as EmployerService from 'modules/home/services/EmployerService';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import BenefitGuide from 'model/BenefitGuide';
import {
  getHomeContentPage,
  updateChecklistSuccessAPI,
} from 'modules/home/slices/homeSlice';

import {
  ADDITIONAL_CONTENT_OPTIONS,
  CHECKLIST_OPTIONS,
  HOME_CHECK_LIST,
  Section,
  SectionName,
} from 'modules/home/constants';
import BenefitGuideBasicInfo from 'model/BenefitGuideBasicInfo ';
import {
  initBenguideCompleted,
  updatePlansForBenGuide,
} from 'modules/auth/slices/benguideSlice';
import { regenerateNHCContent } from 'modules/newHireChecklist/slices/nhcSlice';

interface BasicInfoState {
  planYears: PlanYear[];
  planYear: PlanYear | null;
  employer: Employer | null;
  inProgress: boolean;
  requestType: string;
  error: any;
  savedGuide: BenefitGuide | null;
  saveSnapshot: string;
  saveSnapshotInprogress: boolean;
  benefitGuide: BenefitGuide | null;
  masterId: string | null;
  layoutBenefitGuide: BenefitGuide | null;
  basicInfo: BenefitGuideBasicInfo | null;
}

const initialState = {
  planYears: [],
  planYear: null,
  employer: null,
  inProgress: false,
  requestType: '',
  error: null,
  savedGuide: null,
  saveSnapshot: '',
  saveSnapshotInprogress: false,
  benefitGuide: null,
  masterId: null,
  layoutBenefitGuide: null,
  basicInfo: null,
} as BasicInfoState;

const basicInfoSlice = createSlice({
  name: 'basicInfo',
  initialState,
  reducers: {
    findEmployerStarted(state, action: PayloadAction) {
      state.employer = null;
      state.inProgress = true;
      state.requestType = action.type;
    },
    findEmployerSuccess(state, action: PayloadAction<Employer>) {
      state.employer = action.payload;
      state.inProgress = false;
      state.requestType = action.type;
    },
    findEmployerFailed(state, action: PayloadAction<any>) {
      state.employer = null;
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
    },
    getPlanYearsStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.planYears = [];
    },
    getPlanYearsSuccess(state, action: PayloadAction<PlanYear[]>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.planYears = action.payload;
    },
    getPlanYearsFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.planYears = [];
      state.error = action.payload;
    },
    getPlanYearByIdStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.planYear = null;
    },
    getPlanYearByIdSuccess(state, action: PayloadAction<PlanYear>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.planYear = action.payload;
    },
    getPlanYearByIdFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.planYear = null;
      state.error = action.payload;
    },
    saveSnapshotStarted(state) {
      state.saveSnapshotInprogress = true;
    },
    saveSnapshotSuccess(state, action: PayloadAction<string>) {
      state.saveSnapshotInprogress = false;
      state.saveSnapshot = action.payload;
    },
    saveSnapshotError(state, action: PayloadAction<any>) {
      state.saveSnapshotInprogress = false;
      state.error = action.payload;
    },
    saveBenGuideStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.savedGuide = null;
    },
    saveBenGuideSuccess(state, action: PayloadAction<BenefitGuide>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.savedGuide = action.payload;
      state.layoutBenefitGuide = action.payload;
    },
    saveBenGuideFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.savedGuide = null;
      state.error = action.payload.data;
    },
    findBenGuideStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    findBenGuideSuccess(state, action: PayloadAction<BenefitGuide>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.benefitGuide = action.payload;
    },
    findBenGuideFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.benefitGuide = null;
      state.error = action.payload.data;
    },
    resetBenefitGuides(state) {
      state.benefitGuide = null;
      state.savedGuide = null;
    },
    updateBasicInfoLocalSuccess(
      state,
      action: PayloadAction<BenefitGuideBasicInfo>
    ) {
      state.inProgress = false;
      state.requestType = action.type;
      state.basicInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initBenguideCompleted, (state, action) => {
      state.masterId = action.payload.masterId;
      state.layoutBenefitGuide = action.payload;
    });
  },
});

export const {
  getPlanYearsStarted,
  getPlanYearsSuccess,
  getPlanYearsFailed,
  getPlanYearByIdStarted,
  getPlanYearByIdSuccess,
  getPlanYearByIdFailed,
  findEmployerStarted,
  findEmployerSuccess,
  findEmployerFailed,
  saveBenGuideStarted,
  saveBenGuideSuccess,
  saveBenGuideFailed,
  resetBenefitGuides,
  saveSnapshotStarted,
  saveSnapshotSuccess,
  saveSnapshotError,
  findBenGuideFailed,
  findBenGuideStarted,
  findBenGuideSuccess,
  updateBasicInfoLocalSuccess,
} = basicInfoSlice.actions;

export default basicInfoSlice.reducer;

export const findEmployerById =
  (employerId: string) => async (dispatch: Dispatch) => {
    dispatch(findEmployerStarted());
    try {
      const response = await EmployerService.findEmployerById(employerId);
      dispatch(findEmployerSuccess(response.data));
    } catch (error) {
      // TODO: create error object model and extract required data
      dispatch(findEmployerFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const getPlanYears =
  (employerId: string) => async (dispatch: Dispatch) => {
    dispatch(getPlanYearsStarted());
    try {
      const response = await EmployerService.findPlanYears(employerId);
      dispatch(getPlanYearsSuccess(response.data));
    } catch (error) {
      dispatch(getPlanYearsFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const getPlanYearById =
  (employerId: string, planYearId: string) => async (dispatch: Dispatch) => {
    dispatch(getPlanYearByIdStarted());
    try {
      const response = await EmployerService.findPlanYearById(
        employerId,
        planYearId
      );
      dispatch(getPlanYearByIdSuccess(response.data));
    } catch (error) {
      dispatch(getPlanYearByIdFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const createBenguide =
  (benGuide: BenefitGuideBasicInfo) => async (dispatch: Dispatch) => {
    dispatch(saveBenGuideStarted());
    try {
      const response = await BenGuideService.createBenGuide(benGuide);
      dispatch(saveBenGuideSuccess(response.data));
      dispatch(getHomeContentPage(response.data.masterId, Section.HOME));
    } catch (error: any) {
      if (error.response) {
        // TODO: create error model to extract required data
        dispatch(
          saveBenGuideFailed(JSON.parse(JSON.stringify(error.response)))
        );
        return;
      }
      saveBenGuideFailed(JSON.parse(JSON.stringify(error)));
    }
  };

export const updateBasicInfo =
  (
    _basicInfo: BenefitGuideBasicInfo,
    selectedContent: string[],
    isNHCUpdated = false,
    revision: number
  ) =>
  async (dispatch: Dispatch) => {
    const basicInfo = { ..._basicInfo, revision: revision };
    dispatch(saveBenGuideStarted());
    try {
      const response = await BenGuideService.updateBasicInfo(basicInfo);
      dispatch(saveBenGuideSuccess(response.data));
      dispatch(updatePlansForBenGuide(response.data));
      if (basicInfo.masterId) {
        const options: string[] = [];
        if (selectedContent) {
          selectedContent.forEach((element) => {
            if (element === ADDITIONAL_CONTENT_OPTIONS.newHireCheckList.value) {
              options.push(CHECKLIST_OPTIONS.NEW_HIRE_CHECKLIST);
            } else if (
              element === ADDITIONAL_CONTENT_OPTIONS.datesAndEligibility.value
            ) {
              options.push(CHECKLIST_OPTIONS.DATES_AND_ELIGIBILITY);
            } else if (
              element === ADDITIONAL_CONTENT_OPTIONS.howInsuranceWorks.value
            ) {
              options.push(CHECKLIST_OPTIONS.HOW_INSURANCE_WORKS);
            } else if (element === ADDITIONAL_CONTENT_OPTIONS.resources.value) {
              options.push('RESOURCES');
            }
          });
        }
        const data = {
          sectionName: HOME_CHECK_LIST,
          options: options,
          keepSelected: true,
        };
        const checkListResponse = await BenGuideService.updateCheckList(
          basicInfo.masterId,
          data
        );
        dispatch(updateChecklistSuccessAPI(selectedContent));
        if (isNHCUpdated) {
          dispatch(
            regenerateNHCContent(
              basicInfo.masterId,
              SectionName.NHC_TEXT,
              checkListResponse.data.latestRevision
            )
          );
        }
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(
          saveBenGuideFailed(JSON.parse(JSON.stringify(error.response)))
        );
        return;
      }
      saveBenGuideFailed(JSON.parse(JSON.stringify(error)));
    }
  };

export const updateBasicInfoWithoutCheckListData =
  (
    _basicInfo: BenefitGuideBasicInfo,
    selectedContent: string[],
    revision: number
  ) =>
  async (dispatch: Dispatch) => {
    const basicInfo = { ..._basicInfo, revision: revision };
    dispatch(saveBenGuideStarted());
    try {
      const response = await BenGuideService.updateBasicInfo(basicInfo);
      dispatch(saveBenGuideSuccess(response.data));
      dispatch(updatePlansForBenGuide(response.data));
      if (basicInfo.masterId) {
        dispatch(updateChecklistSuccessAPI(selectedContent));
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(
          saveBenGuideFailed(JSON.parse(JSON.stringify(error.response)))
        );
        return;
      }
      saveBenGuideFailed(JSON.parse(JSON.stringify(error)));
    }
  };

export const updateBenguide =
  (benGuide: BenefitGuide) => async (dispatch: Dispatch) => {
    dispatch(saveBenGuideStarted());
    try {
      const response = await BenGuideService.updateBenGuide(benGuide);
      dispatch(saveBenGuideSuccess(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(
          saveBenGuideFailed(JSON.parse(JSON.stringify(error.response)))
        );
        return;
      }
      saveBenGuideFailed(JSON.parse(JSON.stringify(error)));
    }
  };

export const findBenguideByHash =
  (urlHash: string, revision: number) => async (dispatch: Dispatch) => {
    dispatch(findBenGuideStarted());
    try {
      const response = await BenGuideService.findBenGuideByHash(
        urlHash,
        revision
      );
      dispatch(findBenGuideSuccess(response.data));
    } catch (error: any) {
      dispatch(findEmployerFailed(error));
    }
  };

export const saveSnapshotImage =
  (benguideId: string, image: string) => async (dispatch: Dispatch) => {
    dispatch(saveSnapshotStarted());
    try {
      const imageResponse = await fetch(image);
      const imageBlob = await imageResponse.blob();
      await BenGuideService.uploadSnapShotImage(benguideId, imageBlob);
      dispatch(saveSnapshotSuccess(''));
    } catch (error) {
      dispatch(saveSnapshotError(error));
    }
  };

export const updateBasicInfoLocal = (basicInfo: BenefitGuideBasicInfo) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateBasicInfoLocalSuccess(basicInfo));
  };
};
