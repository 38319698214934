import { FC, useEffect } from 'react';
import { unifiedPortalUrl } from 'utils/apiUtil';

const MESSAGE = 'message';

type UPClientProps = {
  subscribe: Function;
  channel: string;
};

export type UPEvent = {
  channel: string;
  event: string;
  data: any;
  dbgMetaData?: any;
};

const UPClient: FC<UPClientProps> = (props: UPClientProps) => {
  const { subscribe, channel } = props;

  useEffect(() => {
    const listenToDBG = (_event: any) => {
      if (_event.origin !== unifiedPortalUrl) return;
      const { channel: _channel, event, data } = _event.data;
      if (channel === _channel) {
        subscribe(event, data);
      }
    };

    window.addEventListener(MESSAGE, listenToDBG, true);

    return () => {
      window.removeEventListener(MESSAGE, listenToDBG, true);
    };
  }, [channel, subscribe]);

  return <></>;
};

export default UPClient;
