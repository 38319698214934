import { FC, useEffect, useState } from 'react';
import { SwitchChangeEventHandler } from 'antd/lib/switch';

import SwitchButton from 'components/SwitchButton/SwitchButton';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import BoxPopover from 'components/BoxPopover/BoxPopover';
import { unifiedPortalUrl } from 'utils/apiUtil';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  BROKER_CONSULTATION_WARNING_MSG,
  CONSULTANT_TYPE_BROKER,
  CONSULTANT_TYPE_EMPLOYER,
  CONSULTANT_TYPES,
  EMPLOYER_AND_BROKER_CONSULTATION_WARNING_MSG,
  EMPLOYER_CONSULTATION_WARNING_MSG,
} from 'constants/commonConstants';
import { ReactComponent as QuestionCircleIcon } from 'images/question-round.svg';
import { editBenConsultation } from 'modules/benefitsWellness/slices/benefitsConsultationSlice';

import AlertMessage from 'components/AlertMessage/AlertMessage';
import { checkIfConsultantsExist } from 'modules/home/slices/contactSupportSlice';
import styles from './configureBC.module.less';

type ToggleSectionProps = {
  consultantType: string;
  openUrl: Function;
  handleSwitch: SwitchChangeEventHandler | undefined;
  isEnabled: boolean;
  isClickable: boolean;
};

const ToggleSection: FC<ToggleSectionProps> = (props: ToggleSectionProps) => {
  const { consultantType, openUrl, handleSwitch, isEnabled, isClickable } =
    props;

  return (
    <div className={styles.toggleWrapper}>
      <div className={styles.toggle}>
        <label className={isClickable ? styles.label : styles.labelMuted}>
          Display {consultantType} Benefits Consultation?
        </label>

        <SwitchButton
          checked={isEnabled}
          onChange={handleSwitch}
          disabled={!isClickable}
        />
      </div>
      <label className={styles.labelLink} onClick={() => openUrl()}>
        Setup {consultantType} Consultants
      </label>
    </div>
  );
};

type ConfigureBCProps = {
  isOpenModal: boolean;
  closeModal: Function;
};

const ConfigureBC: FC<ConfigureBCProps> = (props: ConfigureBCProps) => {
  const { isOpenModal, closeModal } = props;
  const dispatch = useAppDispatch();
  const benGuide = useAppSelector((state) => state.benguide.data);
  const benConsultation = useAppSelector(
    (state) => state.benefitsConsultation.benConsultation
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.inProgress
  );

  const brokerConsultantData: any = useAppSelector(
    (state) => state.contactSupport.consultantConfirmationBroker
  );

  const employerConsultantData: any = useAppSelector(
    (state) => state.contactSupport.consultantConfirmationEmployer
  );

  const [isEnabledBroker, setEnabledBroker] = useState<boolean>(false);
  const [isEnabledEmployer, setEnabledEmployer] = useState<boolean>(false);
  const [isBrokerConsultantExist, setIsBrokerConsultantExist] =
    useState<boolean>(false);
  const [isEmployerConsultantExist, setIsEmployerConsultantExist] =
    useState<boolean>(false);

  const path = `${unifiedPortalUrl}/brokers/${benGuide.orgId}/employers/${benGuide.employerId}`;

  useEffect(() => {
    setEnabledBroker(
      benConsultation.brokerBenefitConsultationEnabled &&
        isBrokerConsultantExist
    );
    setEnabledEmployer(
      benConsultation.employerBenefitConsultationEnabled &&
        isEmployerConsultantExist
    );
    dispatch(
      checkIfConsultantsExist(
        CONSULTANT_TYPES.ER,
        benGuide.orgId,
        benGuide.employerId
      )
    );
    dispatch(
      checkIfConsultantsExist(
        CONSULTANT_TYPES.BROKER,
        benGuide.orgId,
        benGuide.employerId
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benConsultation]);

  useEffect(() => {
    if (brokerConsultantData) {
      setIsBrokerConsultantExist(checkIfBrokerExist());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerConsultantData]);

  useEffect(() => {
    if (employerConsultantData) {
      setIsEmployerConsultantExist(checkIfEmployerExist());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerConsultantData]);

  const checkIfBrokerExist = (): boolean => {
    const exist = brokerConsultantData.find((obj: any) => {
      const foundEmployer = obj.mappedEmployers.find(
        (emp: any) => emp.employerId === benGuide.employerId
      );
      if (obj.status.status && foundEmployer) return true;
    });

    return !!exist;
  };

  const checkIfEmployerExist = (): boolean => {
    const exist = employerConsultantData.find((emp: any) => emp.status.status);
    return !!exist;
  };

  const getConsultantApplicableWarningMsg = (): string | null => {
    if (!isBrokerConsultantExist && !isEmployerConsultantExist)
      return EMPLOYER_AND_BROKER_CONSULTATION_WARNING_MSG;
    if (!isBrokerConsultantExist) return BROKER_CONSULTATION_WARNING_MSG;
    if (!isEmployerConsultantExist) return EMPLOYER_CONSULTATION_WARNING_MSG;
    return null;
  };

  const isConsultantApplicable = (type: string): boolean => {
    if (!isBrokerConsultantExist && !isEmployerConsultantExist) return false;
    if (CONSULTANT_TYPES.ER === type) return isEmployerConsultantExist;
    if (CONSULTANT_TYPES.BROKER === type) return isBrokerConsultantExist;

    return false;
  };

  return (
    <HalfScreenModal
      width="550px"
      title="Enable/Disable Benefits Consultation"
      visible={isOpenModal}
      onCancel={() => closeModal()}
      className={styles.configureBCWrapper}
      isSubmitDisabled={inProgress}
      isLoading={inProgress}
      onSubmit={() => {
        dispatch(
          editBenConsultation(benGuide.masterId, {
            brokerBenefitConsultationEnabled: isEnabledBroker,
            employerBenefitConsultationEnabled: isEnabledEmployer,
          })
        ).then(() => closeModal());
      }}
    >
      <BoxPopover
        header="Benefits Consultation"
        description="Please make sure the consultants are set up for this employer before enabling the consultation types that you want the employees to be able to schedule."
        className={styles.bcTooltip}
        placement="bottomRight"
      >
        <QuestionCircleIcon />
      </BoxPopover>
      {
        // No employer and broker exist
        getConsultantApplicableWarningMsg() ? (
          <AlertMessage
            className={styles.alert}
            type="warning"
            message={getConsultantApplicableWarningMsg()}
            wrapperClassName={styles.planAlertWrapper}
            closeTextStatus={false}
          />
        ) : (
          <></>
        )
      }

      <ToggleSection
        consultantType={CONSULTANT_TYPE_BROKER}
        handleSwitch={() => setEnabledBroker(!isEnabledBroker)}
        isEnabled={isEnabledBroker}
        openUrl={() => window.open(`${path}/broker-consultation`, '_blank')}
        isClickable={isConsultantApplicable(CONSULTANT_TYPES.BROKER)}
      />
      <ToggleSection
        consultantType={CONSULTANT_TYPE_EMPLOYER}
        handleSwitch={() => setEnabledEmployer(!isEnabledEmployer)}
        isEnabled={isEnabledEmployer}
        openUrl={() => window.open(`${path}/employer-consultation`, '_blank')}
        isClickable={isConsultantApplicable(CONSULTANT_TYPES.ER)}
      />
    </HalfScreenModal>
  );
};

export default ConfigureBC;
