export const AuthErrorCodes = {
  INVALID_DOMAIN: {
    code: 'invalid.username.domain',
    type: 'error',
    message:
      'The details entered are not recognized. Please try using a work email or contact your HR.',
  },
  PLAN_NOT_FOUND: {
    code: 'plan.not.found',
    type: 'error',
    message: 'No plans found!',
  },
  TOKEN_NOT_FOUND: {
    code: 'token.not.found',
    type: 'warning',
    message: 'Token not found.',
  },
  TOKEN_EXPIRED: {
    code: 'token.expired',
    type: 'error',
    message: `This link has expired. In order to register
    this account. you'll need to re-send a
    registration invite to your email address.`,
  },
  TOKEN_EXPIRED_RESET: {
    code: 'token.expired.reset',
    type: 'error',
    message: `This link has expired. In order to reset password for this account, 
    you'll need to re-send a reset password invite to your email address.`,
  },
  INVALID_EMAIL: {
    code: 'invalid.email',
    type: 'error',
    message:
      'Our system does not recognize the information you’ve entered. If you’re looking to register, please',
  },
  LOGIN_EXISTS: {
    code: 'existing.login.found',
    type: 'warning',
    message: `An account already exists for this email address. Please log in to the application from the login page.`,
  },
  TOKEN_CLAIMED: {
    code: 'token.claimed',
    type: 'warning',
    message: 'Sorry, this token is already claimed.',
  },
  INVALID_CREDENTIALS: {
    code: 'invalid_grant',
    type: 'error',
    message: `This email and password combination does not match
    our records. Please check them and try again.`,
  },
  LOGIN_COMPROMISED: {
    code: 'invalid.password.compromised',
    type: 'error',
    message: `The password you are attempting to use was found to be present in a 3rd party database of compromised passwords.`,
  },
  PERMISSION_DENIED: {
    code: 'permission.denied',
    type: 'error',
    message: `You don't have sufficient permissions.`,
  },
  INTERNAL_ERROR: {
    code: 'internal.error',
    type: 'error',
    message: `An unknown error has occurred. Please try again.`,
  },
  EMPLOYEE_SIGN_ERROR: {
    code: 'An error occurred while signing up the employee',
    type: 'error',
    message: 'An error occurred while signing up the employee.',
  },
  PWDRESET_INFO: {
    type: 'info',
    message:
      'We have received your request. If a valid account exists, you will receive password reset instructions at that e-mail address.',
  },
  INVALID_LOGIN_TYPE: {
    code: 'invalid.login.type',
    type: 'error',
    message: 'Invalid login type. Please try a different email.',
  },
  ER_ADMIN_EXISTS: {
    code: 'er.admin.individual.exists',
  },
  UNKNOWN_ERROR: {
    type: 'error',
    message: 'An unknown error has occurred. Please try again.',
  },
  SSO_EXISITS: {
    code: 'er.admin.individual.with.sso.enabled.exists',
    type: 'error',
    message: `An account already exists for this email
    address. Please log in to the application
    from the login page.`,
  },
  EMPLOYEE_NOT_FOUND: {
    code: 'employee.not.found',
    type: 'error',
    message: `Our system does not recognize the information you’ve entered. If you’re looking to register, please`,
  },
  PLAN_NOT_MATCHED: {
    type: 'info',
    message: `Your ID cards for this Plan Year may not be reflected in this benefits guide.`,
  },
};
