import IconPlanRecomEE from 'images/plan-recommender/icon-single.svg';
import IconPlanRecomES from 'images/plan-recommender/icon-married.svg';
import IconPlanRecomEC from 'images/plan-recommender/icon-child.svg';
import IconPlanRecomEF from 'images/plan-recommender/icon-family.svg';
import IconPlanRecomBaby from 'images/plan-recommender/icon-baby.svg';
import IconPlanRecomInpatient from 'images/plan-recommender/icon-inpatient.svg';
import IconPlanRecomOutpatient from 'images/plan-recommender/icon-outpatient.svg';
import IconPlanRecomNone from 'images/plan-recommender/icon-none.svg';
import IconPlanRecomInNetwork from 'images/plan-recommender/icon-in-network.svg';
import IconPlanRecomOutNetwork from 'images/plan-recommender/icon-out-network.svg';
import IconPlanRecomKaiser from 'images/plan-recommender/icon-kaiser.svg';
import IconPlanRecomKaiserMobile from 'images/plan-recommender/icon-kaiser-mobile.png';

export const PLAN_RECOMMENDER_QUESTIONS_TYPES = {
  DROPDOWN: 'DROPDOWN',
  SELECT: 'SELECT',
  SLIDER: 'SLIDER',
  PLANS: 'PLANS',
  INCREMENTER: 'INCREMENTER',
  EMAIL: 'EMAIL',
  EMAIL_SUCCESS: 'EMAIL_SUCCESS',
};

export const PLAN_RECOMMENDER_QUESTIONS = {
  COVERAGE: 'COVERAGE',
  PROCEDURES: 'PROCEDURES',
  DOCTOR_VISITS: 'DOCTOR_VISITS',
  SPECIALIST_VISITS: 'SPECIALIST_VISITS',
  DOCTOR_PREFERENCE: 'DOCTOR_PREFERENCE',
  PRESCRIPTIONS: 'PRESCRIPTIONS',
  LOCATION: 'LOCATION',
  COMBINED_DOCTOR_VISITS: 'COMBINED_DOCTOR_VISITS',
};

export const PLAN_RECOMMENDER_QUESTIONS_ICONS = {
  'ico-ee': IconPlanRecomEE,
  'ico-es': IconPlanRecomES,
  'ico-ec': IconPlanRecomEC,
  'ico-ef': IconPlanRecomEF,

  'ico-pregnancy': IconPlanRecomBaby,
  'ico-major-surgery': IconPlanRecomInpatient,
  'ico-minor-surgery': IconPlanRecomOutpatient,
  'ico-none': IconPlanRecomNone,

  'ico-in-network': IconPlanRecomInNetwork,
  'ico-board-range-doctors': IconPlanRecomOutNetwork,
  'ico-kaiser':
    window.innerWidth > 576 ? IconPlanRecomKaiser : IconPlanRecomKaiserMobile,
};

export function getFrequencyName(frequency: string) {
  switch (frequency) {
    case 'MONTHLY':
      return 'Monthly';
    case 'SEMI_MONTHLY':
      return 'Semi-Monthly';
    case 'BI_WEEKLY':
      return 'Bi-Weekly';
    case 'PER_PAY_PERIOD':
      return 'Per Pay Period';
    case null:
      return '';
    default:
      return frequency;
  }
}

export const RATE_TIERS = [
  {
    value: 'Y0C',
    fourTierValue: 'EE',
    nTierValue: 'EE',
    label: 'Just You',
    coverageLabel: 'Covers you',
  },
  {
    value: 'Y1C',
    fourTierValue: 'EC',
    nTierValue: 'EC',
    label: 'You + Child(ren)',
    coverageLabel: 'Covers You + Child(ren)',
  },
  {
    value: 'Y1S0C',
    fourTierValue: 'ES',
    nTierValue: 'ES',
    label: 'You + Spouse',
    coverageLabel: 'You + Spouse / Partner',
  },
  {
    value: 'Y1S1C',
    fourTierValue: 'EF',
    nTierValue: 'EF',
    label: 'You + Family',
    coverageLabel: 'Covers You + Family',
  },
];

export const DEFAULT_TEXT = '-';

export const SUPER_SCRIPTS = [
  {
    strValue: 'SUP-1',
    superScriptValue: 1,
    label: '1 After Deductible is Met ',
  },
  {
    strValue: 'SUP-2',
    superScriptValue: 2,
    label: '2 Before Deductible is Met ',
  },
];

export const PLAN_RECOMMENDER_PLAN_NOTES = {
  HMO: 'HMO plans require a referral to see a specialist and for you to stay in-network when you see a doctor',
  EPO: 'EPO plans don’t always require a referral to see a specialist, but still require for you to stay in-network as much as possible',
  PPO: 'PPO plans don’t require a referral and allow for the flexibility of going out-of-network ',
  HSA: 'HSA plans have a much higher deductible than other plan types and are usually best if you utilize your HSA fully',
  POS: 'A POS plan combines HMO and PPO features, offering flexibility and cost savings. It requires a PCP and referrals for specialists.',
  OAP: 'PPO plans don’t require a referral and allow for the flexibility of going out-of-network ',
} as any;

export const PER_PAY_PERIOD = 'PER_PAY_PERIOD';
export const SEMI_MONTHLY = 'SEMI_MONTHLY';
export const MONTHLY = 'MONTHLY';
export const BI_WEEKLY = 'BI_WEEKLY';

export const FREQUENCY_RATE_DISPLAY_MAP = {
  [PER_PAY_PERIOD]: 'Your Per Pay Period Cost',
  [SEMI_MONTHLY]: 'Your Semi-Monthly Cost',
  [MONTHLY]: 'Your Monthly Cost',
  [BI_WEEKLY]: 'Your Bi-Weekly Cost',
} as any;
