import { useMemo, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import {
  setIsPlanModalOpen,
  setValidPlan,
  setValidPlanClear,
} from 'modules/aiAssistantChat/slices/aiAssistantChatSlice';
import { sortAlphabetic } from 'utils/commonUtil';
import { PLAN_DOCUMENT_NOT_FOUND } from 'modules/aiAssistantChat/constants/constants';
import { getPlanAccordingToCategory } from 'modules/aiAssistantChat/utils/commonUtil';

import { ReactComponent as AiIndicator } from 'images/icon-ai-indicator.svg';
import styles from './planAssistanceModal.module.less';

type FromValues = {
  plan: string;
};

interface Option {
  plans?: Plan[];
}
interface Plan {
  value: string;
  label: string;
  category: string;
}

const PlanAssistantModal = () => {
  const dispatch = useAppDispatch();
  const benGuideState = useAppSelector((state) => state.benguide.data);
  const aiAssistantState = useAppSelector(
    (state) => state.persisted.aiAssistantChat
  );
  const { isOpenPlanModal, validPlan } = aiAssistantState;
  const [formValues, setFormValues] = useState<FromValues>();
  const [form] = Form.useForm();

  const options = useMemo(
    () => getPlanAccordingToCategory(benGuideState),
    [benGuideState]
  );

  const handleOnValuesChange = (_: any, allValues: FromValues) => {
    setFormValues({ ...allValues });
    dispatch(setValidPlanClear());
  };

  const handleContinue = () => {
    const selectedPlan = options
      .flatMap((option: Option) => option.plans || [])
      .find((plan: Plan) => plan.value === formValues?.plan);

    dispatch(
      setValidPlan({
        planId: selectedPlan?.value!,
        category: selectedPlan?.category!,
        planName: selectedPlan?.label!,
        callback: () => {
          dispatch(setIsPlanModalOpen(false));
          form.resetFields();
        },
      })
    );
  };

  const handleOnCancel = () => {
    dispatch(setIsPlanModalOpen(false));
    dispatch(setValidPlanClear());
    form.resetFields();
  };

  const renderOptions = () => {
    return options.map((option: any) => {
      const { plans, title } = option;
      return plans?.length === 0 ? null : (
        <Select.OptGroup
          key={title}
          label={<span className={styles.optingGroupLabel}>{title}</span>}
        >
          {sortAlphabetic(plans, 'label')?.map((plan) => (
            <Select.Option key={plan?.value} value={plan?.value}>
              {plan?.label}
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
    });
  };

  return (
    <ConfirmationDialog
      title={
        <div>
          <AiIndicator className={styles.aiIndicatorIcon} />
          Plan Assistance
        </div>
      }
      centered
      visible={isOpenPlanModal}
      width={600}
      onOk={handleContinue}
      okText="Continue"
      cancelText="Cancel"
      onCancel={handleOnCancel}
      flipFooterOrder
      btnFullWidth
      isCancelLink
      closable={false}
      okDisabled={!formValues?.plan}
      btnLoading={validPlan.isPending}
    >
      <Row>
        <Col span={24}>
          The AI Assistant is available to assist with any inquiries regarding a
          particular plans from this benefits guide.
        </Col>
        <Col className={styles.planSelectWrapper} span={24}>
          <Form
            layout="vertical"
            form={form}
            onValuesChange={handleOnValuesChange}
          >
            <Form.Item
              name="plan"
              label={<span className={styles.formLabel}>Choose Plan</span>}
            >
              <Select
                showSearch
                optionFilterProp="children"
                className={styles.selectStyles}
                listHeight={150}
              >
                {renderOptions()}
              </Select>
            </Form.Item>
          </Form>
        </Col>

        {validPlan?.isError && (
          <Col xs={24}>
            <FixedAlertMessage type="error" message={PLAN_DOCUMENT_NOT_FOUND} />
          </Col>
        )}
      </Row>
    </ConfirmationDialog>
  );
};

export default PlanAssistantModal;
