import {
  OPEN_BASIC_INFO_MODAL,
  OPEN_DEDUCTIBLE_MODAL,
  OPEN_RATES_MODAL,
  OPEN_RX_MODAL,
  OPEN_SERVICE_MODAL,
  OPEN_CUSTOM_ROW_MODAL,
} from 'modules/clients/UPClient/UPClientConst';

const meta = {
  benguideMetaData: {
    planFieldGroupings: [
      {
        planType: 'medical',
        hotspots: [
          {
            elements: [
              {
                fieldName: 'title',
                fieldValue: 'Plan Name',
                fieldQueries: ['$.name'],
              },
            ],
            event: OPEN_BASIC_INFO_MODAL,
          },
          {
            elements: [
              {
                fieldName: 'monthlyCost',
                fieldValue: 'Your Monthly Cost',
                fieldFormatter: 'contributionFormatter',
                defaultValue: '',
              },
            ],
            event: OPEN_RATES_MODAL,
          },
          {
            elements: [
              {
                fieldName: 'customFields',
                fieldValue: '+ Manage Custom Row',
                fieldFormatter: '',
                defaultValue: '',
              },
              {
                fieldName: 'spaceField',
                type: 'space',
                isCustomField: true,
              },
            ],
            isSeparateRowEvent: true,
            event: OPEN_CUSTOM_ROW_MODAL,
          },

          {
            elements: [
              {
                fieldName: 'deductibleCost',
                fieldValue: 'Deductible (Ind/ Fam)',
                fieldDescription:
                  'Generally, this is a fixed amount you pay out-of-pocket before insurance starts to share the cost of covered expenses. Note that preventive care is typically covered 100% by insurance regardless of where you stand with your deductible.',
                fieldFormatter: 'coverageFormatter',
              },
              {
                fieldName: 'indWithinFamilyDeductible',
                fieldValue: 'Ind. within Fam Deductible',
                fieldDescription:
                  'The Amount each family member must meet individually for their own medical expenses before insurance coverage expands.',
                fieldFormatter: 'coverageFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'outOfPocketMax',
                fieldValue: 'Out-of-Pocket Max',
                fieldDescription:
                  'The most you have to pay for covered services and prescriptions in a plan year.',
                fieldFormatter: 'coverageFormatter',
              },
              {
                fieldName: 'indWithinFamilyOOPMax',
                fieldValue: 'Ind. within Fam OOP Max',
                fieldDescription: `The maximum amount each family member pays for covered services in a year, contributing to the family's total out-of-pocket limit.`,
                fieldFormatter: 'coverageFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
            ],
            event: OPEN_DEDUCTIBLE_MODAL,
            isCustomizedDeductible: true,
          },
          {
            elements: [
              {
                fieldName: 'preventiveCare',
                fieldValue: 'Preventive Care',
                serviceBenefitCode: 95,
                fieldDescription:
                  'Preventive care typically includes annual check-ups, flu shots, vaccinations, as well as certain tests and screenings.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'primaryCareVisit',
                fieldValue: 'Primary Care Visit',
                serviceBenefitCode: 1,
                fieldDescription:
                  'Cost of an outpatient appointment to see a general health care provider, especially a family physician, internist, or pediatrician, usually as part of regular, non-emergency care. ',
                fieldFormatter: 'customServiceFormatter',
              },

              {
                fieldName: 'specialistVisit',
                fieldValue: 'Specialist Visit',
                serviceBenefitCode: 2,
                fieldDescription:
                  'A specialist focuses on a specific area of medicine. Examples include dermatologists, cardiologists, psychiatrists, surgeons, etc. Some plan types require a referral from your primary care physician for the specialist visit to be covered.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
              {
                fieldName: 'urgentCare',
                fieldValue: 'Urgent Care',
                serviceBenefitCode: 13,
                fieldDescription:
                  'Urgent care centers are specific medical facilities where you can receive same-day treatment for urgent, but non-life-threatening conditions.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'ambulance',
                fieldValue: 'Ambulance',
                serviceBenefitCode: 16,
                fieldDescription:
                  'Insurance will cover medically necessary ambulance rides when other forms of transportation will jeopardize the person’s life.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'emergencyRoom',
                fieldValue: 'Emergency Room',
                serviceBenefitCode: 15,
                fieldDescription:
                  "An emergency room provides treatment for an acute injury or illness that poses an immediate risk to a person's life or long-term health.",
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
              {
                fieldName: 'labXray',
                fieldValue: 'Lab & X-Ray',
                serviceBenefitCode: 77,
                fieldDescription:
                  'Services for certain laboratory tests and x-rays.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'imaging',
                fieldValue: 'Imaging (CT/PET/MRI)',
                serviceBenefitCode: 37,
                fieldDescription:
                  'Services for advanced imaging, including CT, PET, or MRI scans.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'inpatient',
                fieldValue: 'Inpatient',
                serviceBenefitCode: 87,
                fieldDescription:
                  'The cost associated with being admitted to the hospital to receive inpatient services. The expectation is the patient will occupy a bed and remain at least overnight.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'outPatient',
                fieldValue: 'Outpatient',
                serviceBenefitCode: 88,
                fieldDescription:
                  'The cost associated with outpatient services. The expectation is the patient will not be admitted to the hospital overnight.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'childBirth',
                fieldValue: 'Childbirth/Delivery Services',
                serviceBenefitCode: 90,
                fieldDescription:
                  'Services related to delivering a newborn baby.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'infertilityTreatment',
                fieldValue: 'Infertility Treatment',
                serviceBenefitCode: 9,
                fieldDescription:
                  'Treatment options to restore fertility or help someone get pregnant with advanced medical techniques.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
              {
                fieldName: 'mentalHealth',
                fieldValue: 'Mental Health (Outpatient)',
                serviceBenefitCode: 89,
                fieldDescription:
                  'Mental Health (Outpatient) services typically include individual or group counseling, psychotherapy, dialectical behavior therapy, family therapy, etc.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'mentalHealth(inpatient)',
                fieldValue: 'Mental Health (Inpatient)',
                serviceBenefitCode: 25,
                fieldDescription:
                  'Inpatient mental health treatment takes place in a residential facility, where a person is offered 24-hour care.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'chiropracticCare',
                fieldValue: 'Chiropractic Care',
                serviceBenefitCode: 34,
                fieldDescription:
                  'Chiropractic care is covered under most health insurance plans. Some plans may have visit limits, dollar limits or referral requirements.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'acupuncture',
                fieldValue: 'Acupuncture',
                serviceBenefitCode: 40,
                fieldDescription:
                  'Some health plans cover acupuncture when it is medically necessary and prescribed by your doctor.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'rehabilitationServices',
                fieldValue: 'Rehabilitation Services',
                serviceBenefitCode: 93,
                fieldDescription:
                  'Services that help you keep, regain, or improve skills/functions for daily living that were lost or impaired because you were sick, hurt, or disabled. This may include physical therapy, occupational therapy, speech-language pathology, etc.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'habilitationServices',
                fieldValue: 'Habilitation Services',
                serviceBenefitCode: 92,
                fieldDescription:
                  'Services that help you keep, learn, or improve skills/functions for daily living. This may include physical therapy, occupational therapy, speech-language pathology, etc.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
              {
                fieldName: 'homeHealthCare',
                fieldValue: 'Home Health Care',
                serviceBenefitCode: 14,
                fieldDescription: 'Services that a person receives at home.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'skilledNursingCare',
                fieldValue: 'Skilled Nursing Care',
                serviceBenefitCode: 94,
                fieldDescription:
                  'Services from licensed nurses in your own home or in a facility.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'hospiceServices',
                fieldValue: 'Hospice Services',
                serviceBenefitCode: 91,
                fieldDescription:
                  'Services to provide comfort and support for people in the last stages of a terminal illness.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'durableMedicalEquipment',
                fieldValue: 'Durable Medical Equipment',
                serviceBenefitCode: 35,
                fieldDescription:
                  'Equipment and supplies ordered by a health care provider for everyday or extended use. Coverage for DME may include oxygen equipment, wheelchairs, crutches, blood testing strips for diabetics, etc.',
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
              },
              {
                fieldName: 'spaceField',
                type: 'space',
                excludeIfUnavailable: [14, 91, 94, 35],
              },
              {
                fieldName: 'spaceField',
                type: 'space',
                includeIfServiceAvailable: [14, 91, 94, 35],
              },
              {
                additionalServices: 'true',
              },
              {
                fieldName: 'spaceField',
                type: 'space',
                excludeIfUnavailable: [0],
              },
            ],
            event: OPEN_SERVICE_MODAL,
            isCustomizedServices: true,
          },
          {
            elements: [
              {
                fieldName: 'spaceField',
                type: 'space',
                isRx: true,
              },
              {
                fieldName: 'rxDeductibles',
                fieldValue: 'Rx Deductible (Ind/ Fam)',
                showTitleIfExists: true,
                fieldDescription:
                  'Rx deductible is a fixed amount you pay out-of-pocket before insurance starts to share the cost of covered prescription medications.',
                fieldFormatter: 'customServiceFormatter',
              },
              {
                fieldName: 'rxOOP',
                fieldValue: 'Rx Out-of-Pocket Max (Ind/ Fam)',
                showTitleIfExists: true,
                fieldDescription:
                  'The most you have to pay for covered prescription medications in a plan year.',
                fieldFormatter: 'customServiceFormatter',
              },

              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 1 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_1',
              },
              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 2 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_2',
              },
              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 3 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_3',
              },
              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 4 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_4',
              },
              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 5 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_5',
              },
              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 6 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_6',
              },
              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 7 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_7',
              },
              {
                fieldName: 'rxCost',
                fieldValue: 'Tier 8 Retail Rx',
                showTitleIfExists: true,
                fieldFormatter: 'customServiceFormatter',
                rxCode: 'TIER_8',
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 1 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_1',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 2 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_2',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 3 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_3',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 4 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_4',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 5 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_5',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 6 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_6',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 7 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_7',
              },
              {
                fieldName: 'mailRxCost',
                fieldValue: 'Tier 8 Mail Order Rx',
                fieldDescription:
                  "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
                fieldFormatter: 'customServiceFormatter',
                showTitleIfExists: true,
                rxCode: 'TIER_8',
              },
            ],
            event: OPEN_RX_MODAL,
            isCustomizedRX: true,
          },
        ],
      },
      {
        planType: 'dental',
        hotspots: [
          {
            elements: [
              {
                fieldName: 'title',
                fieldValue: 'Plan Name',
                fieldQueries: ['$.name'],
              },
            ],
            event: OPEN_BASIC_INFO_MODAL,
          },
          {
            elements: [
              {
                fieldName: 'monthlyCost',
                fieldValue: 'Your Monthly Cost',
                fieldFormatter: 'contributionFormatter',
                defaultValue: '',
              },
            ],
            event: OPEN_RATES_MODAL,
          },
          {
            elements: [
              {
                fieldName: 'deductibleCost',
                fieldValue: 'Deductible (Ind/ Fam)',
                fieldDescription:
                  'This is a fixed amount you pay out-of-pocket before insurance starts to share the cost of covered expenses.',
                fieldFormatter: 'coverageFormatter',
              },
              {
                fieldName: 'calendarYearMax',
                fieldValue: 'Plan Year Max',
                fieldDescription:
                  'This is the maximum dollar amount a dental benefit plan will pay toward the cost of dental care within a the plan year. The patient is personally responsible for paying costs above the annual maximum.',
                fieldFormatter: 'calenderYearMaxFormatter',
              },
            ],
            event: OPEN_DEDUCTIBLE_MODAL,
          },
          {
            elements: [
              {
                fieldName: 'preventive',
                fieldValue: 'Preventive Dental Care',
                fieldDescription:
                  'Preventive services are associated with the prevention of disease through educational and protective measures (such as routine office visits, cleanings, check-ups, etc). Diagnostic may include x-rays, bitewing, full-mouth, or intraoral occlusal film.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 38,
              },
              {
                fieldName: 'routineDentalService',
                fieldValue: 'Routine Dental Service',
                fieldDescription: '',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code== 8)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 8,
                showTitleIfExists: true,
              },
              {
                fieldName: 'dentalCheckUpChild',
                fieldValue: 'Dental Check-up (Children)',
                fieldDescription: '',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code==44)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 44,
                showTitleIfExists: true,
              },
              {
                fieldName: 'basicDentalCare',
                fieldValue: 'Basic Dental Care',
                fieldDescription:
                  'Procedures to repair and restore individual teeth due to decay, trauma, impaired function, attrition, abrasion, erosion, etc.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 53,
              },
              {
                fieldName: 'basicDentalCareChild',
                fieldValue: 'Basic Dental Care (Child)',
                fieldDescription:
                  'Procedures to repair and restore individual teeth due to decay, trauma, impaired function, attrition, abrasion, erosion, etc.',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code==50)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 50,
                showTitleIfExists: true,
              },
              {
                fieldName: 'majorDentalCare',
                fieldValue: 'Major Dental Care',
                fieldDescription:
                  'Procedures dealing with the restoration of teeth. This could include inlays, crowns, veneers, bridges, root canals, oral surgery, etc.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 55,
              },
              {
                fieldName: 'majorDentalCareChild',
                fieldValue: 'Major Dental Care (Child)',
                fieldDescription:
                  'Procedures dealing with the restoration of teeth. This could include inlays, crowns, veneers, bridges, root canals, oral surgery, etc.',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code==52)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 52,
                showTitleIfExists: true,
              },
              {
                fieldName: 'accidentalDental',
                fieldValue: 'Accidental Dental',
                fieldDescription: '',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code== 58)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 58,
                showTitleIfExists: true,
              },
              {
                additionalServices: 'true',
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
              {
                fieldName: 'orthodontia',
                fieldValue: 'Orthodontia',
                fieldDescription:
                  'When it comes to braces and orthodontics, it is important to know what your dental insurance covers and what different coverage exists for both children and adults.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 54,
              },
              {
                fieldName: 'orthodontiaChild',
                fieldValue: 'Orthodontia (Child)',
                fieldDescription:
                  'When it comes to braces and orthodontics, it is important to know what your dental insurance covers and what different coverage exists for both children and adults.',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code==51)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 51,
                showTitleIfExists: true,
              },
            ],
            event: OPEN_SERVICE_MODAL,
            isCustomizedServices: true,
          },
          {
            elements: [
              {
                fieldName: 'orthodontiaLifetimeMax',
                fieldValue: 'Orthodontia Lifetime Max',
                fieldDescription:
                  'Orthodontic coverage typically involves a Lifetime Maximum (LTM) benefit. This is the most money the dental plan will pay for dental care for the employee (or family) for the life of the plan.',
                fieldFilter: ['$.orthodontiaLifetimeMax'],
                fieldFormatter: 'coverageFormatter',
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
            ],
            event: OPEN_DEDUCTIBLE_MODAL,
          },
        ],
      },
      {
        planType: 'vision',
        hotspots: [
          {
            elements: [
              {
                fieldName: 'title',
                fieldValue: 'Plan Name',
                fieldQueries: ['$.name'],
              },
            ],
            event: OPEN_BASIC_INFO_MODAL,
          },
          {
            elements: [
              {
                fieldName: 'monthlyCost',
                fieldValue: 'Your Monthly Cost',
                fieldFormatter: 'contributionFormatter',
                defaultValue: '',
              },
            ],
            event: OPEN_RATES_MODAL,
          },
          {
            elements: [
              {
                fieldName: 'exam',
                fieldValue: 'Routine Eye Exam',
                fieldDescription:
                  'A standard eye exam involves a series of tests to evaluate your vision and check for eye diseases.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 12,
              },
              {
                fieldName: 'routingExam',
                fieldValue: 'Routine Eye Exam (Children)',
                fieldDescription:
                  'Vision plans typically pay a portion of basic preventive care such as eye exams and vision tests.',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code==42)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 42,
                showTitleIfExists: true,
              },
              {
                fieldName: 'frames',
                fieldValue: 'Frames',
                fieldDescription:
                  "You'll pay separately for the frames and lenses. Your frame allowance is the amount covered by your vision insurance.",
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 71,
              },
              {
                fieldName: 'lenses',
                fieldValue: 'Lenses (Single)',
                fieldDescription:
                  'Lenses that have the same prescription throughout the entire lens. Typically used to sharpen vision for either nearby or faraway objects, but not both.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 72,
              },
              {
                fieldName: 'lenses',
                fieldValue: 'Lenses (Bi)',
                fieldDescription:
                  'Lenses that contain two different lens powers. Typically used to sharpen vision for nearby and faraway objects.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 73,
              },
              {
                fieldName: 'lenses',
                fieldValue: 'Lenses (Tri)',
                fieldDescription:
                  'Lenses that contain three different lens powers. Typically used to sharpen vision for nearby, medium-range, and faraway objects.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 74,
              },
              {
                fieldName: 'spaceField',
                type: 'space',
              },
              {
                fieldName: 'eyeGlasses',
                fieldValue: 'Eye Glasses (Children)',
                fieldDescription: '',
                fieldFilter: [
                  '$.customServices[?(@.benefitCode.code==43)].serviceValue.:networkCamelCase',
                ],
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 43,
                showTitleIfExists: true,
              },
              {
                fieldName: 'medicalNecContacts',
                fieldValue: 'Medical Nec. Contacts',
                fieldDescription:
                  'Contact lenses prescribed when certain medical conditions hinder vision correction through regular eyeglasses, and contact lenses are the accepted standard of treatment.',
                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 76,
              },
              {
                fieldName: 'elective',
                fieldValue: 'Elective Contacts',
                fieldDescription:
                  'Contact lenses prescribed when vision correction could be treated with eyeglasses, but contact lenses are preferred.',

                fieldFormatter: 'customServiceFormatter',
                serviceBenefitCode: 75,
              },
              {
                additionalServices: 'true',
              },
            ],
            event: OPEN_SERVICE_MODAL,
            isCustomizedServices: true,
          },
        ],
      },
    ],
  },
};

export default meta;
