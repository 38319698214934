import styles from './voluntaryPlanTag.module.less';
import { ReactComponent as VoluntaryIcon } from './voluntaryLogo.svg';

export const VoluntaryPlanTag = () => {
  return (
    <div className={styles.voluntaryPlanTagWrapper}>
      <span className={styles.voluntaryIcon}>
        <VoluntaryIcon />
      </span>
      <span>VOLUNTARY</span>
    </div>
  );
};
