import styles from './bouncingDots.module.less';

const BouncingDots = () => {
  return (
    <div className={styles.bouncingLoaderWrapper}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default BouncingDots;
