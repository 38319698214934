import { FC, useState, useLayoutEffect, useEffect } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  AGE_BAND,
  FOUR_TIER,
  FOUR_TIER_COVERAGES,
  N_TIER,
  N_TIER_COVERAGES,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import CoverageType from 'model/CoverageType';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setSelectedCoverage } from 'modules/benefitsWellness/slices/benefitWellnessSlice';

import styles from './whosCoverSelector.module.less';

type WhosCoverSelectorProps = {
  rateType: string;
  planListUpdateFlag?: boolean;
  nTiersCount?: number;
};

const WhosCoverSelector: FC<WhosCoverSelectorProps> = ({
  rateType,
  planListUpdateFlag,
  nTiersCount,
}: WhosCoverSelectorProps) => {
  const [isOptionsOpen, setIsOptionOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const { selectedCoverageType } = useAppSelector(
    (state) => state.benefitWellness
  );

  const dispatch = useAppDispatch();

  const itemSelected = (type: CoverageType) => {
    dispatch(setSelectedCoverage(type));
    setIsOptionOpen(false);
  };

  useLayoutEffect(() => {
    if (isFirstLoad) {
      dispatch(setSelectedCoverage(FOUR_TIER_COVERAGES[0].items[0]));
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, dispatch]);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(setSelectedCoverage(FOUR_TIER_COVERAGES[0].items[0]));
    }
  }, [planListUpdateFlag, isFirstLoad, dispatch]);

  if (rateType === AGE_BAND) {
    return null;
  }

  return (
    <div className={styles.whosCoverSelectorWrapper}>
      <div className={styles.dot}></div>
      <label>Who is Covered</label>

      <div
        className={styles.dropdownWrapper}
        onClick={(event) => {
          event.stopPropagation();
          setIsOptionOpen(!isOptionsOpen);
        }}
      >
        <div className={styles.selectedTypeLabel}>
          {selectedCoverageType.label}
          <CaretDownOutlined className={styles.icon} />
        </div>
        {isOptionsOpen && (
          <>
            <div
              className={styles.outside}
              onClick={() => {
                setIsOptionOpen(false);
              }}
            ></div>
            <div
              className={styles.overlay}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {rateType === FOUR_TIER &&
                FOUR_TIER_COVERAGES[0].items.map((item) => {
                  return (
                    <div
                      key={item.value}
                      className={`${styles.item} ${
                        selectedCoverageType.value === item.value
                          ? styles.selected
                          : ''
                      }`}
                      onClick={() => {
                        itemSelected(item);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              {rateType === N_TIER && (
                <>
                  <div className={styles.tabs}>
                    {N_TIER_COVERAGES.map((coverage, index) => (
                      <div
                        key={coverage.name}
                        className={`${styles.tab} ${
                          activeTab === index ? styles.active : ''
                        }`}
                        onClick={() => {
                          setActiveTab(index);
                        }}
                      >
                        {coverage.name}
                      </div>
                    ))}
                  </div>
                  {N_TIER_COVERAGES[activeTab].items
                    .slice(0, nTiersCount)
                    .map((item) => (
                      <div
                        key={item.value}
                        className={`${styles.item} ${
                          selectedCoverageType.value === item.value
                            ? styles.selected
                            : ''
                        }`}
                        onClick={() => {
                          itemSelected(item);
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WhosCoverSelector;
