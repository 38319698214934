import React, { useState } from 'react';
import { Col, Form, Row, Input, Button, FormProps } from 'antd';
import isEmpty from 'lodash/isEmpty';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import { PromptType } from 'model/AiAssistant/common';
import {
  ASSISTANT_INITIALIZE_TEXT,
  PLAN_CHANGE_POPOVER_TEXT,
} from 'modules/aiAssistantChat/constants/constants';
import { ReactComponent as AiQuestionSubmitIcon } from 'images/ai-assistant-submit.svg';
import styles from './aiAssistantInput.module.less';

type Props = {
  promptType: PromptType | null;
  planName?: string;
  disabled?: boolean;
  loading?: boolean;
  onSubmit: (question: string) => void;
  scrollIntoView?: () => void;
};

type FormValues = {
  question: string;
};

const AiAssistantInput = (props: Props) => {
  const { promptType, planName, disabled, loading, onSubmit, scrollIntoView } =
    props;

  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState<FormValues>({
    question: '',
  });

  const handleOnValuesChange: FormProps<FormValues>['onValuesChange'] = (
    _: any,
    allValues: FormValues
  ) => {
    setInputValue({ ...allValues });
  };

  const handleOnFinish: FormProps<FormValues>['onFinish'] = (
    values: FormValues
  ) => {
    const { question } = values;
    const trimmedQuestion = question.trim();

    if (isEmpty(trimmedQuestion)) {
      return;
    }
    onSubmit(trimmedQuestion);
    form.resetFields();
    scrollIntoView?.();
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.shiftKey) {
      return;
    }
    e.preventDefault();
    form.submit();
  };

  return (
    <>
      {promptType === PromptType.DBG_PLAN && (
        <Row justify="start" className={styles.planNameHeader}>
          <Col flex="90px">
            <b>Plan Name: </b>
          </Col>
          <Col xs={20} className={styles.planNameWrapper}>
            <PlanyearPopover
              content={PLAN_CHANGE_POPOVER_TEXT}
              placement="topLeft"
            >
              <b className={styles.disabledText}>{planName}</b>
            </PlanyearPopover>
          </Col>
        </Row>
      )}

      <div className={styles.inputSectionWrapper}>
        <Form
          form={form}
          onFinish={handleOnFinish}
          onValuesChange={handleOnValuesChange}
        >
          <Form.Item name="question" noStyle>
            <div className={styles.inputWrapper}>
              <Input.TextArea
                className={styles.textArea}
                placeholder={ASSISTANT_INITIALIZE_TEXT}
                autoSize={{ maxRows: 10, minRows: 1 }}
                autoFocus
                onPressEnter={handlePressEnter}
                disabled={disabled}
              />
              <Button
                className={styles.submitBtn}
                type="link"
                htmlType="submit"
                disabled={!inputValue.question || disabled}
                loading={loading}
                icon={<AiQuestionSubmitIcon className={styles.submitIcon} />}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default AiAssistantInput;
