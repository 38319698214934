import { FC } from 'react';
import MissingBenefitValue from 'components/MissingBenefitValue/MissingBenefitValue';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import styles from './contributionField.module.less';
type Props = {
  label: string;
  value?: number | null;
  valueText?: string | null;
  className?: string;
  type?: 'employer' | 'general';
  valueEndOfYearPolicyField?: boolean;
};

const ContributionField: FC<Props> = (props: Props) => {
  const {
    label,
    value,
    className = '',
    type = 'general',
    valueText = '',
    valueEndOfYearPolicyField = false,
  } = props;

  return (
    <div
      className={`${styles.container} ${className} ${
        type === 'employer' ? styles.employerContribution : ''
      }`}
    >
      <div className={styles.label}>{label}</div>
      <div
        className={`${styles.value} ${
          valueEndOfYearPolicyField === true && styles.valueEOYP
        }`}
      >
        {typeof value !== 'number' && !valueText ? (
          <MissingBenefitValue />
        ) : (
          <>
            {valueEndOfYearPolicyField === false ? (
              <OverflowPopover placement={'right'}>
                {value !== null && value !== undefined
                  ? `$${value.toLocaleString()}`
                  : valueText}
              </OverflowPopover>
            ) : (
              <div>
                {value !== null && value !== undefined ? (
                  <div>`$${value.toLocaleString()}`</div>
                ) : (
                  <div>{valueText} </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ContributionField;
