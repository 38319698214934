import { FC, ReactElement, useContext } from 'react';
import { Dropdown, Button } from 'antd';
import { NotificationContext } from 'context/NotificationContext';

import styles from './planSelectButton.module.less';

type PlanSelectButtonProps = {
  overlay: ReactElement;
  selectLabel: string;
  className?: string;
};

const PlanSelectButton: FC<PlanSelectButtonProps> = (
  props: PlanSelectButtonProps
) => {
  const { overlay, selectLabel, className = styles.dropdownButton } = props;
  const notificationFlag = useContext(NotificationContext);

  return (
    <Dropdown
      disabled={notificationFlag}
      overlay={overlay}
      trigger={['click']}
      overlayClassName={styles.planDropdownMenu}
      getPopupContainer={(triggerNode) =>
        triggerNode.parentElement ? triggerNode.parentElement : triggerNode
      }
    >
      <Button
        disabled={notificationFlag}
        onClick={(e) => e.preventDefault()}
        className={className}
      >
        {selectLabel}
      </Button>
    </Dropdown>
  );
};

export default PlanSelectButton;
