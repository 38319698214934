enum RouteMap {
  CREATE_BENGUIDE = '/create',
  BASIC_INFO_EDIT_PATH = 'basic-info/edit',
  HOME_PATH = '',
  NEW_HIRE_CHECKLIST = 'new-hire-checklist',
  HOW_INSURANCE_WORKS = 'how-insurance-works',
  DATES_AND_ELIGIBILITY = 'dates-and-eligibility',
  MEDICAL = 'health-benefit/medical',
  PLAN_RECOMMENDER_MEDICAL = 'plan-recommender/medical',
  TELEHEALTH = 'telehealth',
  DENTAL = 'dental',
  VISION = 'vision',
  LIFE_AND_DISABILITY = 'life-and-disability',
  VOLUNTARY = 'voluntary',
  WELLBEING = 'wellbeing',
  HRA = 'tax-advantaged/hra',
  FSA = 'tax-advantaged/fsa',
  HSA = 'tax-advantaged/hsa',
  COMMUTER = 'tax-advantaged/commuter',
  FOUR_O_ONE_K = '401k',
  HOLIDAYS_TIME_OFF = 'holidays-time-off',
  FAMILY_AND_LEAVE = 'family-and-leave',
  ADDITIONAL_PERKS = 'additional-perks',
  RESOURCES = 'resources',
  PUBLISH = 'publish',
  PREVIEW = 'preview',
}

export default RouteMap;
