import { FC } from 'react';
import { Tabs, TabsProps } from 'antd';

import styles from './tabPanel.module.less';

const { TabPane } = Tabs;

interface TabPanelProps extends TabsProps {
  className?: string;
}

const TabPanel: FC<TabsProps> = (props: TabPanelProps) => {
  const { className = '', children, ...rest } = props;
  return (
    <Tabs {...rest} className={`${styles.tabsWrapper} ${className}`}>
      {children}
    </Tabs>
  );
};

export { TabPane };
export default TabPanel;
