const metadata = {
  benguideMetaData: {
    planTypes: [
      {
        planType: 'medical',
        planTypeDescription: 'Medical',
        planTypeUrl: '/medical',
      },
      {
        planType: 'dental',
        planTypeDescription: 'Dental',
        planTypeUrl: '/dental',
      },
      {
        planType: 'vision',
        planTypeDescription: 'Vision',
        planTypeUrl: '/vision',
      },
      {
        planType: 'savings',
        planTypeDescription: 'Spending Accounts',
        planTypeUrl: '/spending-accounts',
      },
      {
        planType: 'commuter',
        planTypeDescription: 'Commuter Benefits',
        planTypeUrl: '/commuter',
      },
      {
        planType: 'fourOOneK',
        planTypeDescription: '401(k)',
        planTypeUrl: '/401k',
      },
      {
        planType: 'insurance',
        planTypeDescription: 'Life & Disability',
        planTypeUrl: '/life-and-disability',
      },
      {
        planType: 'perks',
        planTypeDescription: 'Perks',
        planTypeUrl: '/perks',
      },
      {
        planType: 'wellbeing',
        planTypeDescription: 'Wellbeing',
        planTypeUrl: '/wellbeing',
      },
      {
        planType: 'workLife',
        planTypeDescription: 'Work Life',
        planTypeUrl: '/work-life',
      },
    ],
    planFieldGroupings: [
      {
        planType: 'medical',
        groups: [
          [
            {
              fieldName: 'title',
              fieldValue: 'Plan Name',
              fieldQueries: ['$.name'],
            },
            {
              fieldName: 'monthlyCost',
              fieldValue: 'Your Monthly Cost',
              fieldFilter: ['$'],
              fieldFormatter: 'contributionFormatter',
              defaultValue: '',
            },
          ],
          [
            {
              fieldName: 'customFields',
              fieldValue: '+ Manage Custom Row',
              fieldFilter: ['$.customFields'],
              fieldFormatter: '',
              defaultValue: '',
            },
          ],
          [
            {
              fieldName: 'deductibleCost',
              fieldValue: 'Deductible(Ind/ Fam)',
              fieldDescription:
                'Generally, this is a fixed amount you pay out-of-pocket before insurance starts to share the cost of covered expenses. Note that preventive care is typically covered 100% by insurance regardless of where you stand with your deductible.',
              fieldFilter: ['$.deductibles'],
              fieldFormatter: 'coverageFormatter',
            },
            {
              fieldName: 'outOfPocketMax',
              fieldValue: 'Out-of-Pocket Max',
              fieldDescription:
                "The amount of money you pay for medical expenses that aren't covered by your insurance.",
              fieldFilter: ['$.outOfPocket'],
              fieldFormatter: 'coverageFormatter',
            },
            {
              fieldName: 'primaryCareVisit',
              fieldValue: 'Primary Care Visit',
              serviceBenefitCode: 1,
              fieldDescription:
                'Cost of an outpatient appointment to see a general health care provider, especially a family physician, internist, or pediatrician, usually as part of regular, non-emergency care. ',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==1)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'specialistVisit',
              fieldValue: 'Specialist Visit',
              serviceBenefitCode: 2,
              fieldDescription:
                "If your plan requires a referral and you don't get one first, your insurance won't cover the cost to see the specialist. Most HMO plans require a referral from your primary care physician before you can see any other health care professional (except in an emergency). ",
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==2)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
          ],
          [
            {
              fieldName: 'urgentCare',
              fieldValue: 'Urgent Care',
              serviceBenefitCode: 13,
              fieldDescription:
                'An urgent care situation is one that requires prompt medical attention, generally within 24 to 48 hours, but is not a medical emergency.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==13)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'ambulance',
              fieldValue: 'Ambulance',
              serviceBenefitCode: 16,
              fieldDescription:
                'Generally, insurance covers ambulance transportion when it\'s deemed "medically necessary" and transportation by any other methods, such as a private vehicle, taxicab, wheelchair van, and/or gurney van, will jeopardize the patient’s health.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==16)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'emergencyRoom',
              fieldValue: 'Emergency Room',
              serviceBenefitCode: 15,
              fieldDescription:
                "An emergency room provides treatment for an acute injury or illness that poses an immediate risk to a person's life or long-term health.",
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==15)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
          ],
          [
            {
              fieldName: 'labXray',
              fieldValue: 'Lab & X-Ray',
              serviceBenefitCode: 77,
              fieldDescription:
                "Services that are not considered an emergency may require precertification (pre-authorization). It’s generally required for expensive radiology services such as ultrasounds, CAT scans, and MRIs. While your medical provider obtains this from the insurance carrier, it's your responsibility to make sure it gets done. ",
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==77)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'inpatient',
              fieldValue: 'Inpatient',
              serviceBenefitCode: 87,
              fieldDescription:
                'The cost associated with being admitted to the hospital to receive inpatient services. The expectation is the patient will occupy a bed and remain at least overnight.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==87)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'outPatient',
              fieldValue: 'Outpatient',
              serviceBenefitCode: 88,
              fieldDescription:
                'The cost associated with outpatient services. The expectation is the patient will not be admitted to the hospital overnight.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==88)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'preventiveCare',
              fieldValue: 'Preventive Care',
              serviceBenefitCode: 95,
              fieldDescription:
                'Preventive care typically includes annual check-ups, flu shots, vaccinations, as well as certain tests and screenings.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==95)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
          ],
          [
            {
              fieldName: 'chiropracticCare',
              fieldValue: 'Chiropractic Care',
              serviceBenefitCode: 34,
              fieldDescription:
                'Chiropractic care is covered under most health insurance plans. Some plans may have visit limits, dollar limits or referral requirements.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==34)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'acupuncture',
              fieldValue: 'Acupuncture',
              serviceBenefitCode: 40,
              fieldDescription:
                'Some health plans cover acupuncture when it is medically necessary and prescribed by your doctor.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==40)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
            {
              fieldName: 'mentalHealth',
              fieldValue: 'Mental Health (Outpatient)',
              serviceBenefitCode: 89,
              fieldDescription:
                "Health plans typically provide coverage for outpatient and inpatient behavioral and mental health services. Check your insurance carrier's Summary of Benefits & Coverage for details. Services that are not considered an emergency may require precertification (preauthorization). While your medical provider obtains this from the carrier, it's your responsibility to make sure it gets done. ",
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==89)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              excludeFromFiltering: true,
            },
          ],
          [
            {
              additionalServices: 'true',
            },
          ],
          [
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 1 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_1'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 1a Retail Rx',
              showTitleIfExists: true,
              fieldDescription: 'Typically lower cost generic drugs.',
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_1A'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 1b Retail Rx',
              showTitleIfExists: true,
              fieldDescription: 'Generic drugs.',
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_1B'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 2 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_2'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 2a Retail Rx',
              showTitleIfExists: true,
              fieldDescription: '',
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_2A'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 2b Retail Rx',
              showTitleIfExists: true,
              fieldDescription: '',
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_2B'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 3 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_3'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 4 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_4'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 5 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_5'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 6 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_6'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 7 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_7'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 8 Retail Rx',
              showTitleIfExists: true,
              fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_8'],
              fieldFormatter: 'customServiceFormatter',
            },
          ],
          [
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 1 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_1'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 1a Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_1A'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 1b Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_1B'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 2 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_2'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 2a Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_2A'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 2b Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_2B'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 3 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_3'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 4 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_4'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 5 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_5'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 6 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_6'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 7 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_7'],
              fieldFormatter: 'customServiceFormatter',
            },
            {
              fieldName: 'rxCost',
              fieldValue: 'Tier 8 Mail Order Rx',
              showTitleIfExists: true,
              fieldDescription:
                "Using your carrier's mail order pharmacy may save you time and money.For details, check your carrier's Summary of Benefits and Coverage (SBC).",
              fieldFilter: ['$.mailOrderRxCosts.:networkCamelCase.TIER_8'],
              fieldFormatter: 'customServiceFormatter',
            },
          ],
        ],
      },
      {
        planType: 'dental',
        groups: [
          [
            {
              fieldName: 'title',
              fieldValue: 'Plan Name',
              fieldQueries: ['$.name'],
            },
            {
              fieldName: 'monthlyCost',
              fieldValue: 'Your Monthly Cost',
              fieldFilter: ['$'],
              fieldFormatter: 'contributionFormatter',
              defaultValue: '',
            },
          ],
          [
            {
              fieldName: 'deductibleCost',
              fieldValue: 'Deductible(Ind/ Fam)',
              fieldDescription:
                'This is a fixed amount you pay out-of-pocket before insurance starts to share the cost of covered expenses.',
              fieldFilter: ['$.deductibles'],
              fieldFormatter: 'coverageFormatter',
            },
            {
              fieldName: 'calendarYearMax',
              fieldValue: 'Plan Year Max',
              fieldDescription:
                'This is the maximum dollar amount a dental benefit plan will pay toward the cost of dental care within a the plan year. The patient is personally responsible for paying costs above the annual maximum.',
              fieldFilter: ['$.calendarYearMax'],
              fieldFormatter: 'calenderYearMaxFormatter',
            },
            {
              fieldName: 'preventive',
              fieldValue: 'Preventive',
              fieldDescription:
                'Preventive services are associated with the prevention of disease through educational and protective measures (such as routine office visits, cleanings, check-ups, etc). Diagnostic may include x-rays, bitewing, full-mouth, or intraoral occlusal film.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==38)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 38,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'accidentalDental',
              fieldValue: 'Accidental Dental',
              fieldDescription: '',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code== 58)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 58,
            },
            {
              fieldName: 'routineDentalService',
              fieldValue: 'Routine Dental Service',
              fieldDescription: '',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code== 8)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 8,
            },
            {
              fieldName: 'dentalCheckUpChild',
              fieldValue: 'Dental Check-up (Children)',
              fieldDescription: '',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==44)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 44,
            },
            {
              fieldName: 'basicDentalCare',
              fieldValue: 'Basic Dental Care',
              fieldDescription:
                'Procedures to repair and restore individual teeth due to decay, trauma, impaired function, attrition, abrasion, erosion, etc.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==53)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 53,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'basicDentalCareChild',
              fieldValue: 'Basic Dental Care (Child)',
              fieldDescription:
                'Procedures to repair and restore individual teeth due to decay, trauma, impaired function, attrition, abrasion, erosion, etc.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==50)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 50,
            },
            {
              fieldName: 'majorDentalCare',
              fieldValue: 'Major Dental Care',
              fieldDescription:
                'Procedures dealing with the restoration of teeth. This could include inlays, crowns, veneers, bridges, root canals, oral surgery, etc.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==55)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 55,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'majorDentalCareChild',
              fieldValue: 'Major Dental Care (Child)',
              fieldDescription:
                'Procedures dealing with the restoration of teeth. This could include inlays, crowns, veneers, bridges, root canals, oral surgery, etc.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==52)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 52,
            },
          ],
          [
            {
              fieldName: 'orthodontia',
              fieldValue: 'Orthodontia',
              fieldDescription:
                'When it comes to braces and orthodontics, it is important to know what your dental insurance covers and what different coverage exists for both children and adults.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==54)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 54,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'orthodontiaChild',
              fieldValue: 'Orthodontia (Child)',
              fieldDescription:
                'When it comes to braces and orthodontics, it is important to know what your dental insurance covers and what different coverage exists for both children and adults.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==51)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 51,
            },
            {
              fieldName: 'orthodontiaMax',
              fieldValue: 'Ortho Lifetime Max',
              fieldDescription:
                'Orthodontic coverage typically involves a Lifetime Maximum (LTM) benefit. This is the most money the dental plan will pay for dental care for the employee (or family) for the life of the plan.',
              fieldFilter: ['$.orthodontiaLifetimeMax'],
              fieldFormatter: 'orthodontiaMaxFormatter',
            },
          ],
        ],
      },
      {
        planType: 'vision',
        groups: [
          [
            {
              fieldName: 'title',
              fieldValue: 'Plan Name',
              fieldQueries: ['$.name'],
            },
            {
              fieldName: 'monthlyCost',
              fieldValue: 'Your Monthly Cost',
              fieldFilter: ['$'],
              fieldFormatter: 'contributionFormatter',
              defaultValue: '',
            },
          ],
          [
            {
              fieldName: 'exam',
              fieldValue: 'Routine Eye Exam',
              fieldDescription:
                'Vision plans typically pay a portion of basic preventive care such as eye exams and vision tests.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==12)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 12,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'routingExam',
              fieldValue: 'Routine Eye Exam (Children)',
              fieldDescription:
                'Vision plans typically pay a portion of basic preventive care such as eye exams and vision tests.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==42)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 42,
            },
            {
              fieldName: 'frames',
              fieldValue: 'Frames',
              fieldDescription:
                "You'll pay separately for the frames and lenses. Your frame allowance is the amount covered by your vision insurance.",
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==71)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 71,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'lenses',
              fieldValue: 'Lenses (Single)',
              fieldDescription:
                'Amount covered by your insurance for single vision lenses.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==72)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 72,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'lenses',
              fieldValue: 'Lenses (Bi)',
              fieldDescription:
                'Amount covered by your insurance for bifocal lenses.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==73)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 73,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'lenses',
              fieldValue: 'Lenses (Tri)',
              fieldDescription:
                'Amount covered by your insurance for trifocal lenses.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==74)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 74,
              excludeFromFiltering: true,
            },
          ],
          [
            {
              fieldName: 'necessary',
              fieldValue: 'Necessary',
              fieldDescription:
                'Medically necessary (non-elective) contact lenses are prescribed when certain medical conditions hinder vision correction through regular eyeglasses.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==76)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 76,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'elective',
              fieldValue: 'Elective',
              fieldDescription:
                'Elective contacts are chosen by the patient to correct an eye condition that eyeglasses or sometimes laser surgery can also correct.',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==75)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 75,
              excludeFromFiltering: true,
            },
            {
              fieldName: 'eyeGlasses',
              fieldValue: 'Eye Glasses (Children)',
              fieldDescription: '',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==43)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 43,
            },
            {
              fieldName: 'laser',
              fieldValue: 'Laser Correction Surgery',
              fieldDescription: '',
              fieldFilter: [
                '$.customServices[?(@.benefitCode.code==86)].serviceValue.:networkCamelCase',
              ],
              fieldFormatter: 'customServiceFormatter',
              serviceBenefitCode: 86,
            },
          ],
        ],
      },
    ],
  },
  coverages: [
    {
      title: 'No Spouse',
      name: 'No Spouse',
      items: [
        {
          value: 'Y0C',
          fourTierValue: 'EE',
          nTierValue: 'EE',
          label: 'Just You',
          coverageLabel: 'Covers you',
        },
        {
          value: 'Y1C',
          fourTierValue: 'EC',
          nTierValue: 'EE_PLUS_1',
          label: 'You + 1 Child',
          coverageLabel: 'Covers you + child',
        },
        {
          value: 'Y2C',
          fourTierValue: 'EC',
          nTierValue: 'EE_PLUS_2',
          label: 'You + 2 Children',
          coverageLabel: 'Covers you + children',
        },
        {
          value: 'Y3C',
          fourTierValue: 'EC',
          nTierValue: 'EE_PLUS_3',
          label: 'You + 3 Children',
          coverageLabel: 'Covers you + children',
        },
        {
          value: 'Y4C',
          fourTierValue: 'EC',
          nTierValue: 'EE_PLUS_4',
          label: 'You + 4 Children',
          coverageLabel: 'Covers you + children',
        },
        {
          value: 'Y5C',
          fourTierValue: 'EC',
          nTierValue: 'EE_PLUS_5',
          label: 'You + 5 Children',
          coverageLabel: 'Covers you + children',
        },
        {
          value: 'Y6C',
          fourTierValue: 'EC',
          nTierValue: 'EE_PLUS_6',
          label: 'You + 6 Children',
          coverageLabel: 'Covers you + children',
        },
      ],
    },
    {
      title: 'Spouse',
      name: 'Spouse',
      items: [
        {
          value: 'Y0S0C',
          fourTierValue: 'EE',
          nTierValue: 'EE',
          label: 'Just You',
          coverageLabel: 'Covers you',
        },
        {
          value: 'Y1S0C',
          fourTierValue: 'ES',
          nTierValue: 'EE_PLUS_1',
          label: 'You + Spouse',
          coverageLabel: 'Covers you + spouse',
        },
        {
          value: 'Y1S1C',
          fourTierValue: 'EF',
          nTierValue: 'EE_PLUS_2',
          label: 'You + Spouse + 1 Child',
          coverageLabel: 'Covers you + family',
        },
        {
          value: 'Y1S2C',
          fourTierValue: 'EF',
          nTierValue: 'EE_PLUS_3',
          label: 'You + Spouse  + 2 Children',
          coverageLabel: 'Covers you + family',
        },
        {
          value: 'Y1S3C',
          fourTierValue: 'EF',
          nTierValue: 'EE_PLUS_4',
          label: 'You + Spouse + 3 Children',
          coverageLabel: 'Covers you + family',
        },
        {
          value: 'Y1S4C',
          fourTierValue: 'EF',
          nTierValue: 'EE_PLUS_5',
          label: 'You + Spouse + 4 Children',
          coverageLabel: 'Covers you + family',
        },
        {
          value: 'Y1S5C',
          fourTierValue: 'EF',
          nTierValue: 'EE_PLUS_6',
          label: 'You + Spouse + 5 Children',
          coverageLabel: 'Covers you + family',
        },
        {
          value: 'Y1S6C',
          fourTierValue: 'EF',
          nTierValue: 'EE_PLUS_6',
          label: 'You + Spouse + 6 Children',
          coverageLabel: 'Covers you + family',
        },
      ],
    },
    {
      title: '4-Tier',
      name: '4-Tier',
      type: 'FOUR_TIER',
      items: [
        {
          value: 'Y0C',
          fourTierValue: 'EE',
          nTierValue: 'EE',
          label: 'Just You',
          coverageLabel: 'Covers you',
        },
        {
          value: 'Y1S0C',
          fourTierValue: 'ES',
          nTierValue: 'ES',
          label: 'You + Spouse',
          coverageLabel: 'Covers you + Spouse',
        },
        {
          value: 'Y1C',
          fourTierValue: 'EC',
          nTierValue: 'EC',
          label: 'You + Child(ren)',
          coverageLabel: 'Covers you + Child(ren)',
        },

        {
          value: 'Y1S1C',
          fourTierValue: 'EF',
          nTierValue: 'EF',
          label: 'You + Family',
          coverageLabel: 'Covers you + Family',
        },
      ],
    },
  ],
  planRecommenderPlanKeys: [
    {
      fieldName: 'monthlyCost',
      fieldValue: 'Your Monthly Cost',
      fieldFilter: ['$'],
      fieldFormatter: 'contributionFormatter',
      defaultValue: '',
      showTitleIfExists: true,
    },
    {
      fieldName: 'deductibleCost',
      fieldValue: 'Deductible(Ind/ Fam)',
      fieldDescription:
        'This is a fixed amount you pay out-of-pocket before insurance starts to share the cost of covered expenses.',
      fieldFilter: ['$.deductibles'],
      fieldFormatter: 'specificCoverageFormatter',
    },
    {
      fieldName: 'outOfPocketMax',
      fieldValue: 'Out-of-Pocket Max',
      fieldDescription:
        "The amount of money you pay for medical expenses that aren't covered by your insurance.",
      fieldFilter: ['$.outOfPocket'],
      fieldFormatter: 'specificCoverageFormatter',
    },
    {
      fieldName: 'primaryCareVisit',
      fieldValue: 'Primary Care Visit',
      serviceBenefitCode: 1,
      fieldDescription:
        'Cost of an outpatient appointment to see a general health care provider, especially a family physician, internist, or pediatrician, usually as part of regular, non-emergency care. ',
      fieldFilter: [
        '$.customServices[?(@.benefitCode.code==1)].serviceValue.:networkCamelCase',
        ' / ',
        '$.customServices[?(@.benefitCode.code==2)].serviceValue.:networkCamelCase',
      ],
      fieldFormatter: 'customServiceFormatter',
      excludeFromFiltering: true,
    },
    {
      fieldName: 'inpatient',
      fieldValue: 'Inpatient',
      serviceBenefitCode: 87,
      fieldDescription:
        'The cost associated with being admitted to the hospital to receive inpatient services. The expectation is the patient will occupy a bed and remain at least overnight.',
      fieldFilter: [
        '$.customServices[?(@.benefitCode.code==87)].serviceValue.:networkCamelCase',
      ],
      fieldFormatter: 'customServiceFormatter',
      excludeFromFiltering: true,
    },
    {
      fieldName: 'outPatient',
      fieldValue: 'Outpatient',
      serviceBenefitCode: 88,
      fieldDescription:
        'The cost associated with outpatient services. The expectation is the patient will not be admitted to the hospital overnight.',
      fieldFilter: [
        '$.customServices[?(@.benefitCode.code==88)].serviceValue.:networkCamelCase',
      ],
      fieldFormatter: 'customServiceFormatter',
      excludeFromFiltering: true,
    },

    {
      fieldName: 'rxCostTier1',
      fieldValue: 'Tier 1 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_1'],
      fieldFormatter: 'slashFormatter',
    },
    {
      fieldName: 'rxCostTier2',
      fieldValue: 'Tier 2 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_2'],
      fieldFormatter: 'slashFormatter',
    },

    {
      fieldName: 'rxCostTier3',
      fieldValue: 'Tier 3 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_3'],
      fieldFormatter: 'slashFormatter',
    },
    {
      fieldName: 'rxCostTier4',
      fieldValue: 'Tier 4 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_4'],
      fieldFormatter: 'slashFormatter',
    },
    {
      fieldName: 'rxCostTier5',
      fieldValue: 'Tier 5 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_5'],
      fieldFormatter: 'slashFormatter',
    },
    {
      fieldName: 'rxCostTier6',
      fieldValue: 'Tier 6 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_6'],
      fieldFormatter: 'slashFormatter',
    },
    {
      fieldName: 'rxCostTier7',
      fieldValue: 'Tier 7 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_7'],
      fieldFormatter: 'slashFormatter',
    },
    {
      fieldName: 'rxCostTier8',
      fieldValue: 'Tier 8 Retail Rx',
      showTitleIfExists: true,
      fieldFilter: ['$.rxCosts.:networkCamelCase.TIER_8'],
      fieldFormatter: 'slashFormatter',
    },
  ],
};

export default metadata;
