export const getPathWithHash = (
  hash: string,
  path: string,
  employerName?: string
) => {
  if (path) {
    return `/${hash}${employerName ? '/' + employerName : ''}/${path}`;
  }
  return `/${hash}${employerName ? '/' + employerName : ''}`;
};

export const getHashWithToken = (
  hash: string,
  token: string,
  employerName?: string
) => {
  if (token) {
    return `${getPathWithHash(hash, '', employerName)}?previewToken=${token}`;
  }
  return `${getPathWithHash(hash, '', employerName)}`;
};
