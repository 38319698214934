import IconFlexibleHours from 'images/icons/Icon-Flexible-Hours.svg';
import IconHoliday from 'images/icons/Icon-Holiday.svg';
import IconSick from 'images/icons/Icon-Sick.svg';
import IconPaidTimeOff from 'images/icons/Icon-PTO.svg';
import IconOther from 'images/icons/Icon-Other.svg';
import iconChildcare from 'images/icons/Icon-Childcare.svg';
import iconParentalLeave from 'images/icons/Icon-ParentalLeave.svg';
import iconFertility from 'images/icons/Icon-Fertility.svg';
import iconBereavement from 'images/icons/Icon-Bereavement.svg';

export const colorConfigurationTypes = {
  HOME_TEXT_COLOR: 'homeTextColor',
  COLOR_THEME: 'colorTheme',
  BACKGROUND_COLOR: 'backgroundColor',
};

export const cropShapeTypes = {
  RECT: 'rect',
  ROUND: 'round',
};

export const EMPTY_MESSAGE = '';
export const BENGUIDE_NAME_DUPLICATE_MESSAGE = 'Duplicate guide name exists';

export enum BenGuideStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export const maxPlanNameSize: number = 50;

export const BENEFIT_CATEGORY = {
  MEDICAL: {
    value: 'MEDICAL',
  },
  DENTAL: {
    value: 'DENTAL',
  },
  VISION: {
    value: 'VISION',
  },
  TELEHEALTH: {
    value: 'TELEHEALTH',
  },
  WELLBEING: {
    value: 'WELLBEING',
  },
  HOLIDAYS_AND_TIME_OFF: {
    value: 'HOLIDAYS_AND_TIME_OFF',
  },
  LIFE: {
    value: 'LIFE',
  },
  FAMILY_AND_LEAVE: {
    value: 'FAMILY_AND_LEAVE',
  },
  ADDITIONAL_PERK: {
    value: 'ADDITIONAL_PERKS',
  },
  RETIREMENT: {
    value: 'RETIREMENT_401K',
  },
  VOLUNTARY_BENEFIT: {
    value: 'VOL_BENEFITS',
  },
  TAX_ADVANTAGE: {
    value: 'TAX_ADVANTAGE',
  },
};

export const exitDBGMessages: {
  [key in BenGuideStatus]: {
    title: string;
    description: string;
    descriptionNew?: string;
    confirmBtnText: string;
    cancelBtnText: string;
  };
} = {
  DRAFT: {
    title: 'Exit Guide?',
    description:
      'Changes to this page have not been saved. Are you sure you want to exit?',
    descriptionNew:
      'Your Benefits Guide has not been saved. Are you sure you want to exit this page?',
    confirmBtnText: 'Yes - Exit Guide',
    cancelBtnText: 'Cancel',
  },
  PUBLISHED: {
    title: 'Exit Guide?',
    description:
      'Changes to this page have not been saved. Are you sure you want to exit?',
    confirmBtnText: 'Yes - Exit Guide',
    cancelBtnText: 'Cancel',
  },
  // Archived guides will not be used, added for type safety
  ARCHIVED: {
    title: '',
    description: '',
    confirmBtnText: '',
    cancelBtnText: '',
  },
};

export const DOCUMENT_TYPE = {
  SBC: {
    value: 'SBC',
    label: 'SBC',
  },
  PLAN_SUMMARY: {
    value: 'PLAN_SUMMARY',
    label: 'Plan Summary',
  },
};

export const BENEFIT_TYPES: { [key: string]: string } = {
  MEDICAL: 'Medical',
  DENTAL: 'Dental',
  VISION: 'Vision',
};

export const BENEFIT_KIND = {
  PAID_TIME_OFF: {
    value: 'PAID_TIME_OFF',
    label: 'Paid Time Off',
    icon: IconPaidTimeOff,
  },
  SICK: { value: 'SICK', label: 'Sick', icon: IconSick },
  HOLIDAY: { value: 'HOLIDAY', label: 'Holiday', icon: IconHoliday },
  FLEXIBLE_WORKING_HOURS: {
    value: 'FLEXIBLE_WORKING_HOURS',
    label: 'Flexible Working Hours',
    icon: IconFlexibleHours,
  },
  OTHER_TIME_OFF: {
    value: 'OTHER_TIME_OFF',
    label: 'Other Time Off',
    icon: IconOther,
  },
  BACKUP_CHILDCARE: {
    value: 'BACKUP_CHILDCARE',
    label: 'Backup Childcare',
    icon: iconChildcare,
  },
  BEREAVEMENT: {
    value: 'BEREAVEMENT',
    label: 'Bereavement',
    icon: iconBereavement,
  },
  FERTILITY: {
    value: 'FERTILITY',
    label: 'Fertility',
    icon: iconFertility,
  },
  PARENTAL_LEAVE: {
    value: 'PARENTAL_LEAVE',
    label: 'Parental Leave',
    icon: iconParentalLeave,
  },
  FAMILY_AND_LEAVE_OTHER: {
    value: 'FAMILY_AND_LEAVE_OTHER',
    label: 'Family And Leave Other',
    icon: IconOther,
  },
};

export const VOL_BENEFITS_KIND = [
  'ACCIDENT',
  'CRITICAL_ILLNESS',
  'HOSPITAL',
  'CUSTOM_VOLUNTARY_BENEFIT',
];

export const NOTIFICATION_GENERAL_UPDATE_STATE = 'GENERAL_UPDATE';
export const NOTIFICATION_GENERAL_ARCHIVED_STATE = 'ARCHIVED';

export const LEGAL_DISCLAIMER = `This Benefits Guide offers a high-level overview of your available
benefits. The official Plan Documents are what govern your official
rights and coverage under each plan.`;

export const ALL_BENEFIT_CATEGORIES = [
  'medical',
  'dental',
  'vision',
  'telehealth',
  'lifeAndDisability',
  'volBenefits',
  'wellbeing',
  'hra',
  'fsa',
  'hsa',
  'commuter',
  'fourOOneK',
  'holidaysAndTimeOff',
  'familyAndLeave',
  'additionalPerks',
];

export const BenefitKind = {
  RETIREMENT_401K: { value: 'RETIREMENT_401K', label: '401(K)' },
  WELLBEING_GENERIC: { value: 'WELLBEING_GENERIC', label: 'WELLBEING' },
  PAID_TIME_OFF: { value: 'PAID_TIME_OFF', label: 'Paid Time Off' },
  SICK: { value: 'SICK', label: 'Sick' },
  HOLIDAY: { value: 'HOLIDAY', label: 'Holiday' },
  FLEXIBLE_WORKING_HOURS: {
    value: 'FLEXIBLE_WORKING_HOURS',
    label: 'Flexible Working Hours',
  },
  OTHER_TIME_OFF: { value: 'OTHER_TIME_OFF', label: 'Other Time Off' },
  PARENTAL_LEAVE: { value: 'PARENTAL_LEAVE', label: 'Parental Leave' },
  BACKUP_CHILDCARE: { value: 'BACKUP_CHILDCARE', label: 'Backup Childcare' },
  FERTILITY: { value: 'FERTILITY', label: 'Fertility' },
  BEREAVEMENT: { value: 'BEREAVEMENT', label: 'Bereavement' },
  FAMILY_AND_LEAVE_OTHER: { value: 'FAMILY_AND_LEAVE_OTHER', label: 'Other' },
  ACCIDENT: { value: 'ACCIDENT', label: 'Accident' },
  CRITICAL_ILLNESS: { value: 'CRITICAL_ILLNESS', label: 'Critical Illness' },
  HOSPITAL: { value: 'HOSPITAL', label: 'Hospital' },
  CUSTOM_PLAN: { value: 'CUSTOM_VOLUNTARY_BENEFIT', label: 'Custom Plan' },
  HRA: { value: 'HRA', label: 'Health Reimbursement Account (HRA)' },
  FSA: { value: 'FSA', label: 'Healthcare Flexible Spending Account (FSA)' },
  DCAP_FSA: {
    value: 'DCAP_FSA',
    label: 'Dependent Care Flexible Spending Account (DCFSA)',
  },
  LIMITED_FSA: {
    value: 'LIMITED_FSA',
    label: 'Limited Purpose Flexible Spending Account (LPFSA)',
  },
  HSA: { value: 'HSA', label: 'Health Savings Account (HSA)' },
  COMMUTER_GENERIC: { value: 'COMMUTER_GENERIC', label: 'Commuter' },
  CUSTOM_VOLUNTARY_BENEFIT: {
    value: 'CUSTOM_VOLUNTARY_BENEFIT',
    label: 'Other Voluntary Benefit',
  },
  MEDICAL: { value: 'MEDICAL', label: 'Medical' },
  VISION: { value: 'VISION', label: 'Vision' },
  DENTAL: { value: 'DENTAL', label: 'Dental' },
};

export const mdvServicesToolTipCustomResponse = [
  {
    label: 'Orthodontia (Child)',
    text: 'When it comes to braces and orthodontics, it is important to know what your dental insurance covers and what different coverage exists for both children and adults.',
  },
  {
    label: 'Major Dental Care ( Child)',
    text: 'Procedures dealing with the restoration of teeth. This could include inlays, crowns, veneers, bridges, root canals, oral surgery, etc.',
  },
  {
    label: 'Basic Dental Care (Child)',
    text: 'Procedures to repair and restore individual teeth due to decay, trauma, impaired function, attrition, abrasion, erosion, etc.',
  },
  {
    label: 'Routine Eye Exam (Children)',
    text: 'Vision plans typically pay a portion of basic preventive care such as eye exams and vision tests.',
  },
];

export const FILE_TYPE_ERROR_PDF =
  'This file type is not supported. You can only upload pdf file';

export const TELEHEALTH_BENEFITS_KIND = [
  'TELEHEALTH',
  'RX_DELIVERY',
  'RX_COUPONS',
];

export const HOLIDAY_TIME_OFF_BENEFITS_KIND = [
  'PAID_TIME_OFF',
  'SICK',
  'HOLIDAY',
  'FLEXIBLE_WORKING_HOURS',
  'OTHER_TIME_OFF',
];

export const FAMILY_AND_LEAVE_BENEFITS_KIND = [
  'BACKUP_CHILDCARE',
  'PARENTAL_LEAVE',
  'FERTILITY',
  'BEREAVEMENT',
  'FAMILY_AND_LEAVE_OTHER',
];

export const WELLBEING_GENERIC = 'WELLBEING_GENERIC';

export const NO_NAME = 'NO_NAME';

export const ERROR_MESSAGE_PLAN_EDITING = (
  <span>
    Changes cannot be saved until guide is refreshed. Please take note of your
    changes as they <u>will not</u> be saved when you refresh this page.
  </span>
);

export const BC_STEP_ONE = 'Info Screen';
export const BC_STEP_TWO = 'Select consultant type';
export const BC_STEP_THREE = 'Select date';

export const CONSULTANT_TYPE_BROKER = 'Broker';
export const CONSULTANT_TYPE_EMPLOYER = 'Employer';

export const CONSULTANT_TYPES = {
  BROKER: 'BROKER',
  ER: 'ER',
};

export const BROKER_CONSULTATION_WARNING_MSG =
  'Broker Consultation cannot be enabled because there are no broker consultants assigned to this employer.';
export const EMPLOYER_CONSULTATION_WARNING_MSG =
  'Employer Consultation cannot be enabled because there are no employer consultants assigned to this employer.';
export const EMPLOYER_AND_BROKER_CONSULTATION_WARNING_MSG =
  'Broker or Employer Consultation cannot be enabled because there are no consultants assigned to this employer.';

export const DENTAL_ADVANCED_PROFILE_PARAMETERS = [
  'Waiting Period',
  'Endodontic Care',
  'Periodontic Care',
  'Implants',
];
export const VISION_ADVANCED_PROFILE_PARAMETERS = ['Contact Lenses Frequency'];

type TooltipContent = {
  title: string;
  subtitle: string;
};

export const TOOLTIP_CONST: { [key: string]: TooltipContent } = {
  'Preventive Dental Care': {
    title: 'Preventive Dental Care',
    subtitle: 'Includes services such as exams, X-rays, and cleanings.',
  },
  'Basic Dental Care': {
    title: 'Basic Dental Care',
    subtitle:
      'Includes services such as fillings, extractions, and root canals.',
  },
  'Major Dental Care': {
    title: 'Major Dental Care',
    subtitle:
      'Includes extensive procedures such as crowns, bridges, and dentures.',
  },
};
