import { FC } from 'react';

import { useAppSelector } from 'hooks/redux';
import MDVNetworkSelector from 'modules/benefitsWellness/components/MDVNetworkSelector/MDVNetworkSelector';
import MDVPlanTitle from 'modules/benefitsWellness/components/MDVPlanTitle/MDVPlanTitle';
import WhosCoverSelector from 'modules/benefitsWellness/components/WhosCoverSelector/WhosCoverSelector';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';

import styles from './mdvPlanHeaderWrapper.module.less';

type MDVHeaderWrapperProps = {
  planType: MDVTypes;
  addedPlansCount: number;
  rateType: string;
  hideCoverageFilter: boolean;
  planListUpdateFlag?: boolean;
  nTiersCount?: number;
};

const MDVPlanHeaderWrapper: FC<MDVHeaderWrapperProps> = (
  props: MDVHeaderWrapperProps
) => {
  const {
    planType,
    addedPlansCount,
    rateType,
    hideCoverageFilter,
    planListUpdateFlag,
    nTiersCount,
  } = props;
  const { isTablet } = useAppSelector((state) => state.layout);
  return (
    <div className={styles.mdvPlansHeaderWrapper}>
      <MDVPlanTitle planType={planType} addedPlansCount={addedPlansCount} />
      {/* displayed in MDVPlansWrapper for smaller screens */}
      {!isTablet && <MDVNetworkSelector />}
      {/* displayed in MDVPlansFilters for smaller screens */}
      {!hideCoverageFilter && !isTablet && (
        <WhosCoverSelector
          rateType={rateType}
          planListUpdateFlag={planListUpdateFlag}
          nTiersCount={nTiersCount}
        />
      )}
    </div>
  );
};

export default MDVPlanHeaderWrapper;
