import axios from 'axios';

const HTTP_TIMEOUT = 100000;
const CONTENT_TYPE = 'application/json';

export const CONTENT_TYPE_HEADER = 'Content-Type';
export const CONTENT_TYPE_MULTIPART_FORM = 'multipart/form-data';

const httpModifiedInstance = axios.create({
  timeout: HTTP_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Web-Auth-Client': 'UP_ADMIN',
  },
});

const httpInstance = axios.create({
  timeout: HTTP_TIMEOUT,
  withCredentials: true,
  headers: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE,
  },
});

export const http = httpInstance;

export const httpM = httpModifiedInstance;

export const isValidUrl = (url: string) => {
  try {
    return (
      url.match(
        /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=,]*)$/g
      ) != null
    );
  } catch (_) {
    return false;
  }
};

export const getProtocol = (_url: string) => {
  let url;
  try {
    url = new URL(_url);
  } catch (_) {
    return null;
  }
  return url.protocol;
};

export const addHttp = (url: string) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
