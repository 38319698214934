import { FC, ReactNode } from 'react';
import Modal, { ModalProps } from 'antd/lib/modal/Modal';

import LargeButton from 'components/buttons/LargeButton/LargeButton';

import styles from './confirmationDialog.module.less';

interface ConfirmationDialogProps extends ModalProps {
  cancelText?: string;
  children?: ReactNode;
  flipFooterOrder?: boolean;
  btnFullWidth?: boolean;
  isCancelLink?: boolean;
  okDisabled?: boolean;
  btnLoading?: boolean;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (
  props: ConfirmationDialogProps
) => {
  const {
    title,
    visible,
    okText,
    cancelText,
    onCancel,
    onOk,
    children,
    width = '465px',
    flipFooterOrder = false,
    btnFullWidth = false,
    isCancelLink = false,
    okDisabled,
    btnLoading = false,
    ...rest
  } = props;
  const btnFullWidthClassName = btnFullWidth ? styles.fullWidth : '';
  const getModalFooter = (
    <div className={styles.footer}>
      {!flipFooterOrder && (
        <LargeButton
          className={`${styles.cancelBtn} ${btnFullWidthClassName}`}
          type={'primary'}
          onClick={onCancel}
          loading={btnLoading}
        >
          {cancelText}
        </LargeButton>
      )}

      <LargeButton
        className={`${styles.confirmBtn} ${btnFullWidthClassName}`}
        type={flipFooterOrder ? 'primary' : 'default'}
        onClick={onOk}
        disabled={okDisabled}
        loading={btnLoading}
      >
        {okText}
      </LargeButton>
      {flipFooterOrder && (
        <LargeButton
          className={`${styles.cancelBtn} ${btnFullWidthClassName}`}
          type={isCancelLink ? 'link' : 'default'}
          onClick={onCancel}
          loading={btnLoading}
        >
          {cancelText}
        </LargeButton>
      )}
    </div>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      footer={getModalFooter}
      wrapClassName={styles.wrapper}
      width={width}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationDialog;
