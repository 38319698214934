import React from 'react';
import { Button, Col, Row } from 'antd';
import { useStompClient } from 'react-stomp-hooks';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AiAssistantInput from 'modules/aiAssistantChat/components/AiAssistantInput/AiAssistantInput';
import {
  appendChatQuestion,
  chatInitialize,
  setToNewChat,
} from 'modules/aiAssistantChat/slices/aiAssistantChatSlice';
import { ASSISTANT_INITIALIZE_WARNING } from 'modules/aiAssistantChat/constants/constants';
import { ReactComponent as NewChatIcon } from 'images/new-chat.svg';
import styles from './aiAssistantChatFooter.module.less';

type Props = {
  scrollIntoView: () => void;
};
const AiAssistantChatFooter = (props: Props) => {
  const { scrollIntoView } = props;
  const dispatch = useAppDispatch();
  const { promptType, messages, chatId, selectedPlan } = useAppSelector(
    (state) => state.persisted.aiAssistantChat
  );

  const stompClient = useStompClient();
  const lastMessage = messages[messages.length - 1];
  const disableInput =
    lastMessage?.isPending || lastMessage?.animate || lastMessage?.isError;

  const loadingSubmit = lastMessage?.isPending;

  const handleOnSubmit = (question: string) => {
    if (isEmpty(chatId)) {
      dispatch(chatInitialize({ text: question }));
    } else {
      dispatch(
        appendChatQuestion({
          text: question,
          chatId: chatId!,
          wsClient: stompClient,
        })
      );
    }
  };

  return (
    <div className={styles.footerWrapper}>
      <AiAssistantInput
        scrollIntoView={scrollIntoView}
        promptType={promptType}
        planName={selectedPlan?.planName}
        disabled={disableInput}
        loading={loadingSubmit}
        onSubmit={handleOnSubmit}
      />

      <Row
        justify="center"
        align="middle"
        className={styles.newChatSectionWrapper}
      >
        <Col>
          <p className={styles.warningText}>{ASSISTANT_INITIALIZE_WARNING}</p>
        </Col>
        <Col>
          <Button
            className={styles.newChatBtn}
            type="link"
            onClick={() => dispatch(setToNewChat())}
          >
            New Chat <NewChatIcon className={styles.newChatIcon} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AiAssistantChatFooter;
