import { currencyFormatter } from 'utils/commonUtil';
import { DEFAULT_TEXT } from 'modules/planRecommender/pages/constants';
import { getEEContributionField } from 'modules/benefitsWellness/util';

const jp = require('jsonpath');

export const NOT_COVERED = 'NOT_COVERED';
export const NOT_APPLICABLE = 'NOT_APPLICABLE';
export const NOT_COVERED_FORMATTED = 'Not Covered';
export const NOT_APPLICABLE_FORMATTED = 'Not Applicable';

const frequencyLabel = {
  PER_DAY: 'per day',
  PER_VISIT: 'per visit',
  PER_ADMISSION: 'per admission',
  PER_TRIP: 'per trip',
  PER_WEEK: 'per week',
  PER_MONTH: 'per month',
  PER_YEAR: 'per year',
  PER_PROCEDURE: 'per procedure',
  PER_SCRIPT: 'per script',
  PER_ALLOWANCE: 'per allowance',
  PER_ITEM: 'per item',
  NO_INFO: '',
} as any;

const contributionQuery =
  '$.rates["fourTierContributions","ntierContributions"].contributions[?(@.tierName==":rate4Tier" || @.tierName==":rateNTier")].:costType';

const appendRXOtherSuperScripts = (data: any, formattedValue: any) => {
  return data &&
    data.costSharingPolicy &&
    data.costSharingPolicy.toString().toLowerCase() === 'other' &&
    'copayPriorToDeductible' in data &&
    data.copayPriorToDeductible === 'NO'
    ? `${formattedValue} SUP-1`
    : formattedValue;
};

const getFormattedCopayValue = (data: any, includeFrequency = true) =>
  'copay' in data
    ? `${currencyFormatter(parseFloat(data.copay))} ${
        includeFrequency && data.copayFrequency
          ? frequencyLabel[data.copayFrequency]
          : ''
      }`
    : DEFAULT_TEXT;

const getFormattedCoinsuranceValue = (data: any) =>
  'coinsurance' in data ? `${data.coinsurance}%` : DEFAULT_TEXT;

const appendSuperScripts = (data: any, formattedValue: any) => {
  return data &&
    data.costSharingPolicy &&
    data.costSharingPolicy.toString().toLowerCase() === 'copay' &&
    'copayPriorToDeductible' in data &&
    data.copayPriorToDeductible === 'NO'
    ? `${formattedValue} SUP-1`
    : data &&
      data.costSharingPolicy &&
      data.costSharingPolicy.toString().toLowerCase() === 'coinsurance' &&
      'coinsuranceAfterDeductible' in data &&
      data.coinsuranceAfterDeductible === 'YES'
    ? `${formattedValue} SUP-1`
    : data &&
      data.costSharingPolicy &&
      data.costSharingPolicy.toString().toLowerCase() === 'combined' &&
      'coinsuranceAfterDeductible' in data &&
      data.coinsuranceAfterDeductible === 'YES'
    ? `${formattedValue} SUP-1`
    : formattedValue;
};

const regularFormatter = (data: any, includeFrequency = true) => {
  if (
    data &&
    data.costSharingPolicy &&
    data.costSharingPolicy.toString() === NOT_APPLICABLE
  ) {
    return NOT_APPLICABLE_FORMATTED;
  }
  if (
    data &&
    data.benefitCovered &&
    data.benefitCovered.toString().toLowerCase() === 'no'
  )
    return NOT_COVERED_FORMATTED;
  if (
    data &&
    data.benefitCovered &&
    data.benefitCovered.toString().toLowerCase() === 'no_info'
  )
    return 'No Info';
  if (data === NOT_COVERED) return NOT_COVERED_FORMATTED;
  if (data === NOT_APPLICABLE) return NOT_APPLICABLE_FORMATTED;
  if (
    data &&
    data.costSharingPolicy &&
    data.costSharingPolicy.toString().toLowerCase() === 'copay'
  )
    return appendSuperScripts(
      data,
      getFormattedCopayValue(data, includeFrequency)
    );
  if (
    data &&
    data.costSharingPolicy &&
    data.costSharingPolicy.toString().toLowerCase() === 'coinsurance'
  )
    return appendSuperScripts(data, getFormattedCoinsuranceValue(data));
  if (
    data &&
    data.costSharingPolicy &&
    data.costSharingPolicy.toString().toLowerCase() === 'combined'
  )
    return `${getFormattedCopayValue(data)} + ${appendSuperScripts(
      data,
      getFormattedCoinsuranceValue(data)
    )}`;
  if (
    data &&
    data.costSharingPolicy &&
    data.costSharingPolicy.toString().toLowerCase() === 'other'
  )
    return data.info
      ? appendRXOtherSuperScripts(data, data.info)
      : DEFAULT_TEXT;

  return DEFAULT_TEXT;
};

const fieldFormatters = {
  customServiceFormatter: (data: any) => regularFormatter(data),
  slashFormatter: (data: any) => regularFormatter(data, false),
  contributionFormatter: (
    data: any,
    rate: any,
    network: any,
    hasDollar: any,
    shouldLowercase: any,
    frequency?: string
  ) => {
    if (data.rates && data.rates.type === 'AGE_BAND') return 'Age Banded';
    let queryResult = executeJsonPathQuery(
      contributionQuery,
      data,
      network,
      rate,
      frequency ? getEEContributionField(frequency) : undefined
    )
      .reduce((val: any, elem: any) => elem + val, '')
      .toString();
    queryResult =
      queryResult.length > 0 && shouldLowercase
        ? queryResult.toLowerCase()
        : queryResult;
    queryResult =
      queryResult.length > 0 &&
      hasDollar &&
      /^-?\d+(\.\d{1,2})?$/.test(queryResult)
        ? `${
            queryResult < 0
              ? '$0.00'
              : currencyFormatter(parseFloat(queryResult))
          }`
        : queryResult;

    return queryResult;
  },
  coverageFormatter: (data: any, type: any, network: any) => {
    if (data !== undefined) {
      const extraCoverageValue = (data: any, type: any, network: any) => {
        return coverageAndSpecificCoverageFormatter(data, network, type);
      };
      return `${extraCoverageValue(
        data,
        'individual',
        network
      )} / ${extraCoverageValue(data, 'family', network)}`;
    }
  },
  specificCoverageFormatter: (data: any, type: any, network: any) => {
    const coverageType = type.fourTierValue === 'EE' ? 'individual' : 'family';
    if (data !== undefined) {
      const extraCoverageValue = (data: any, type: any, network: any) => {
        return coverageAndSpecificCoverageFormatter(data, network, type);
      };
      return `${extraCoverageValue(data, coverageType, network)}`;
    }
  },
  orthodontiaMaxFormatter: (data: any, type: any, network: any) => {
    if (data !== undefined) {
      const orthoCoverageValue = (data: any, type: any, network: any) => {
        return calenderYearMaxAndOrthodontiaMaxFormatter(data, network);
      };
      return `${orthoCoverageValue(data, type, network)}`;
    }
  },
  calenderYearMaxFormatter: (data: any, type: any, network: any) => {
    if (data !== undefined) {
      const calenderYearMaxCoverageValue = (
        data: any,
        type: any,
        network: any
      ) => {
        return calenderYearMaxAndOrthodontiaMaxFormatter(data, network);
      };
      return `${calenderYearMaxCoverageValue(data, type, network)}`;
    }
  },
} as any;

const coverageAndSpecificCoverageFormatter = (
  data: any,
  network: any,
  type: any
) => {
  if (
    data[`${type}${network}Applicable`] === undefined ||
    data[`${type}${network}Applicable`] === 'COVERED'
  ) {
    return data[`${type}${network}Cost`] === undefined
      ? DEFAULT_TEXT
      : data[`${type}${network}Cost`];
  } else {
    return regularFormatter(data);
  }
};

const calenderYearMaxAndOrthodontiaMaxFormatter = (data: any, network: any) => {
  if (
    data[`${networkFieldVariance[network].camelCase}Applicable`] ===
      undefined ||
    data[`${networkFieldVariance[network].camelCase}Applicable`] === 'COVERED'
  ) {
    return data[`${networkFieldVariance[network].camelCase}Cost`] === undefined
      ? DEFAULT_TEXT
      : currencyFormatter(
          data[`${networkFieldVariance[network].camelCase}Cost`]
        );
  } else {
    return regularFormatter(
      data[`${networkFieldVariance[network].camelCase}Applicable`]
    );
  }
};

const networkFieldVariance = {
  InNetwork: {
    camelCase: 'inNetwork',
    properCase: 'InNetwork',
  },
  OutOfNetwork: {
    camelCase: 'outOfNetwork',
    properCase: 'OutOfNetwork',
  },
} as any;

const executeJsonPathQuery = (
  query: any,
  cardData: any,
  network: any,
  rate: any,
  costName?: string
) => {
  if (query) {
    return jp.query(
      cardData,
      query
        .replace(':rate4Tier', rate.fourTierValue)
        .replace(':rateNTier', rate.nTierValue)
        .replace(':networkCamelCase', networkFieldVariance[network].camelCase)
        .replace(':networkProperCase', networkFieldVariance[network].properCase)
        .replace(':costType', costName ? costName : 'employeeCost')
    );
  } else {
    return '';
  }
};

export function getCardFieldValue(
  cardData: any,
  fieldData: any,
  network: any,
  rate: any,
  hasDollar = true,
  shouldLowercase = true,
  frequency?: string
) {
  let result = DEFAULT_TEXT;
  if (fieldData && fieldData.fieldQueries) {
    result = fieldData.fieldQueries.reduce((concatString: any, query: any) => {
      if (query.indexOf('$') === 0) {
        let queryResult = executeJsonPathQuery(query, cardData, network, rate)
          .reduce((val: any, elem: any) => elem + val, '')
          .toString();
        // Formatting...
        queryResult =
          queryResult.length > 0 && shouldLowercase
            ? queryResult.toLowerCase()
            : queryResult;
        queryResult =
          queryResult.length > 0 &&
          hasDollar &&
          /^-?\d+(\.\d{1,2})?$/.test(queryResult)
            ? `${
                queryResult < 0
                  ? '$0.00'
                  : currencyFormatter(parseFloat(queryResult))
              }`
            : queryResult;
        if (!queryResult || queryResult.length === 0) {
          queryResult = DEFAULT_TEXT;
        }
        return concatString + queryResult;
      } else {
        return concatString + query;
      }
    }, '');
  } else if (fieldData && fieldData.fieldFilter) {
    result = fieldData.fieldFilter.reduce((concatString: any, query: any) => {
      if (query.indexOf('$') === 0) {
        const filteredData = executeJsonPathQuery(
          query,
          cardData,
          network,
          rate,
          frequency
        );
        let queryResult = fieldFormatters[fieldData.fieldFormatter](
          filteredData[0],
          rate,
          network,
          hasDollar,
          shouldLowercase,
          frequency
        );
        if (!queryResult || queryResult.length === 0) {
          queryResult =
            'defaultValue' in fieldData ? fieldData.defaultValue : DEFAULT_TEXT;
        }
        return concatString + queryResult;
      } else {
        return concatString + query;
      }
    }, '');
  }
  return result;
}
