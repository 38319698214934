import { FC } from 'react';
import { Popover, PopoverProps } from 'antd';

import styles from './tooltipPopover.module.less';

interface Props extends PopoverProps {
  className?: string;
}

const TooltipPopover: FC<Props> = (props: Props) => {
  const { className = styles.tooltipOverlay, children, ...rest } = props;

  return (
    <Popover overlayClassName={className} trigger="hover" {...rest}>
      {children}
    </Popover>
  );
};

export default TooltipPopover;
