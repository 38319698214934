import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Form, Input, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { AUTH_VIEW } from 'modules/auth/constants/authConstants';
import {
  authFlow,
  login,
  setAuthView,
  setShowAlert,
} from 'modules/auth/slices/employeeAuthSlice';

import styles from './employeeLogin.module.less';

const Login = () => {
  const { hash = '', employerName = '' } = useParams();
  const baseUrl = `/${hash}/`;
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const inProgress = useAppSelector((state) => state.employeeAuth.inProgress);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const textChangeHandler = (event: any) => {
    const { value, name }: { [key: string]: string } = event.target;

    if (name === 'email') {
      setShowPassword(false);
    }
    setCredentials({ ...credentials, [name]: value.trim() });
  };

  const loginHandler = (values: any) => {
    const constructRedirectURL = `benguideV2${baseUrl}${employerName}`;
    if (!showPassword && values?.email) {
      dispatch(authFlow(values?.email, constructRedirectURL));
    } else {
      dispatch(
        login(
          values?.email?.trim(),
          values?.password?.trim(),
          constructRedirectURL,
          benguideData?.planYearId
        )
      );
    }
    setShowPassword(true);
  };

  useEffect(() => {
    return () => {
      setShowPassword(false);
      setCredentials({
        email: '',
        password: '',
      });
      form?.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(form.getFieldValue('email'))) {
      dispatch(setShowAlert(false));
      form?.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPassword]);

  return (
    <Row justify="center" className={styles.wrapperContainer}>
      <InputForm
        isLoading={inProgress}
        form={form}
        layout="vertical"
        onFinish={loginHandler}
      >
        <Form.Item
          name="email"
          label={<>Email Address</>}
          rules={[
            {
              required: true,
              message: 'Please enter your email',
            },
          ]}
        >
          <Input
            className={styles.inputWrapper}
            name="email"
            onChange={textChangeHandler}
          />
        </Form.Item>
        {showPassword && (
          <Form.Item
            name="password"
            label={<>Password</>}
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input
              className={styles.inputWrapper}
              name="password"
              type="password"
              autoFocus
              autoComplete="off"
              onChange={textChangeHandler}
            />
          </Form.Item>
        )}
        <SubmitButton
          type="primary"
          htmlType="submit"
          disabled={inProgress}
          loading={inProgress}
          className={styles.submitPwdButton}
        >
          {showPassword ? 'Log In' : 'Continue'}
        </SubmitButton>

        <div className={styles.resetPwdWrapper}>
          <LinkButton
            className={styles.resetPwdButton}
            onClick={() => dispatch(setAuthView(AUTH_VIEW.EMAIL.type))}
          >
            {AUTH_VIEW.REGISTER.value}
          </LinkButton>{' '}
          <LinkButton
            className={styles.resetPwdButton}
            onClick={() => dispatch(setAuthView(AUTH_VIEW.REQUEST_RESET.type))}
          >
            {AUTH_VIEW.REQUEST_RESET.value}
          </LinkButton>
        </div>
      </InputForm>
    </Row>
  );
};

export default Login;
