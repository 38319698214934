const planCategories = [
  { title: 'Medical', key: 'medical' },
  { title: 'Dental', key: 'dental' },
  { title: 'Vision', key: 'vision' },
  { title: 'Life & Disability', key: 'lifeAndDisability' },
];

const getCategoryAccordingToKey = (key: string) => {
  const category: { [key: string]: string } = {
    medical: 'MEDICAL',
    dental: 'DENTAL',
    vision: 'VISION',
    lifeAndDisability: 'LIFE',
  };

  return category[key] || key.toUpperCase();
};

const mapPlansToOptions = (
  plans: { planId: string; planName: string }[] = [],
  category: string
) => {
  return plans.map(({ planId, planName }) => ({
    value: planId,
    label: planName,
    category: category,
  }));
};

export const getPlanAccordingToCategory = (data: any) => {
  const options = planCategories?.map(({ title, key }) => {
    const plans = data?.[key]?.plans || [];

    return {
      title,
      plans: mapPlansToOptions(plans, getCategoryAccordingToKey(key)),
    };
  });
  return options || [];
};
