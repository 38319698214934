import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import Plan from 'model/Plan';
import PlanReference from 'model/PlanReference';
import {
  getAllTaxAdvantagedPlans,
  getTaxAdvantagedPlanList,
} from 'modules/benefitsWellness/services/TaxAdvantagedPlanService';
import * as BenGuideService from 'services/BenGuideService';
import * as PlanService from 'modules/auth/services/PlanService';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import SectionApiRequestPayload from 'model/SectionApiRequestPayload';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import {
  DCAP_FSA_PLAN_CONTENT_TABS,
  FSA_BENEFIT_KINDS,
  FSA_PLAN_CONTENT_TABS,
  LIMITED_FSA_PLAN_CONTENT_TABS,
  TaxAdvantagedSection,
  TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP,
} from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { SectionName } from 'modules/home/constants';

interface TaxAdvantagedState {
  planList: {
    inProgress: boolean;
    data: Plan[];
  };
  fsaSection: {
    enabled: boolean;
    plans: PlanReference[];
  };
  selectedPlans: {
    data: TaxAdvantageAccountsPlan[];
    inProgress: boolean;
  };
  defaultRichMedia: {
    inProgress: boolean;
    error: any;
    data: {
      [key in string]: string;
    };
  };
  inProgress: boolean;
}

const initialState: TaxAdvantagedState = {
  planList: {
    inProgress: false,
    data: [],
  },
  fsaSection: {
    enabled: false,
    plans: [],
  },
  selectedPlans: {
    data: [],
    inProgress: false,
  },
  inProgress: false,
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.FSA_OVERVIEW_TEXT]: '',
      [SectionName.FSA_ELIGIBILITY_TEXT]: '',
      [SectionName.FSA_EXPENSES_TEXT]: '',
      [SectionName.FSA_ADVANTAGES_TEXT]: '',
      [SectionName.FSA_CONSIDERATIONS_TEXT]: '',
    },
  },
};

type PlanFetchData = {
  employerId: string;
  planYearId: string;
  benefitClasses: string[];
};

type SelectPlanParams = {
  benguideId: string;
  addPlansPayload: AddPlanApiRequestPayload;
  benefitKind: string;
};

export const fetchFSAPlans = createAsyncThunk<any, PlanFetchData>(
  'fsa/fetchFSAPlans',
  async ({ employerId, planYearId, benefitClasses }) => {
    const response = await getTaxAdvantagedPlanList(
      employerId,
      planYearId,
      FSA_BENEFIT_KINDS.map((fsaData) => fsaData.benefitKind),
      benefitClasses
    );
    return response.data.content;
  }
);

export const selectFSAPlans = createAsyncThunk<
  SectionApiRequestPayload,
  SelectPlanParams
>('fsa/selectFSAPlans', async ({ benguideId, addPlansPayload }) => {
  await BenGuideService.addPlans(benguideId, addPlansPayload);
  const response = await BenGuideService.getBenefitPageSection(
    benguideId,
    TaxAdvantagedSection.FSA
  );
  return response.data;
});

export const getFSASection = createAsyncThunk<
  SectionApiRequestResponse,
  { benguideId: string }
>('fsa/getBenefitSection', async ({ benguideId }) => {
  const response = await BenGuideService.getBenefitPageSection(
    benguideId,
    TaxAdvantagedSection.FSA
  );
  return response.data;
});

export const getPlansFromPlanRefs = createAsyncThunk<
  TaxAdvantageAccountsPlan[],
  { planRefs: PlanReference[] }
>('fsa/getPlansFromPlanRefs', async ({ planRefs }) => {
  const planPromises = planRefs.map((plan) => {
    const { planId, revision } = plan;
    return PlanService.getPlans('tax-advantage-accounts', planId, revision);
  });
  const promiseResponses = await Promise.all(planPromises);
  return promiseResponses.map((plan) => plan.data);
});

const fsaSlice = createSlice({
  name: 'fsa',
  initialState,
  reducers: {
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.data[payload.section] = payload.media.content;
    },
    getDefaultMediaFailed(state, payload) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
    updateFSASection(state, { payload }) {
      state.fsaSection = payload;
    },
    listAllFSAPlansStarted(state) {
      state.planList.inProgress = true;
    },
    listAllFSAPlansSuccess(state, { payload }) {
      state.planList.inProgress = false;
      state.planList.data = payload;
    },
    listAllFSAPlansFailed(state) {
      state.planList.inProgress = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFSAPlans.pending, (state) => {
      state.planList.inProgress = true;
    });
    builder.addCase(fetchFSAPlans.fulfilled, (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.data = payload;
    });
    builder.addCase(selectFSAPlans.pending, (state) => {
      state.selectedPlans.inProgress = true;
    });
    builder.addCase(selectFSAPlans.fulfilled, (state, { payload }) => {
      state.selectedPlans.inProgress = false;
      state.fsaSection = payload.benefitPage;
    });
    builder.addCase(getFSASection.pending, (state) => {
      state.inProgress = true;
    });
    builder.addCase(getFSASection.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.fsaSection = payload.benefitPage;
    });
    builder.addCase(getPlansFromPlanRefs.pending, (state) => {
      state.selectedPlans.inProgress = true;
    });
    builder.addCase(getPlansFromPlanRefs.fulfilled, (state, { payload }) => {
      state.selectedPlans.inProgress = false;
      state.selectedPlans.data = payload;
    });
  },
});

export const {
  updateFSASection,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
  listAllFSAPlansStarted,
  listAllFSAPlansSuccess,
  listAllFSAPlansFailed,
} = fsaSlice.actions;

export default fsaSlice.reducer;

export const listFSAPlans = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(listAllFSAPlansStarted());
    try {
      const response = await getAllTaxAdvantagedPlans(
        employerId,
        planYearId,
        ['FSA', 'LIMITED_FSA', 'DCAP_FSA'],
        benefitClasses
      );
      dispatch(listAllFSAPlansSuccess(response.data));
    } catch (error) {
      dispatch(listAllFSAPlansFailed());
    }
  };
};

export const getDefaultMediaForFSA = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  let benefitTab = FSA_PLAN_CONTENT_TABS;
  if (
    TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.DEPENDENT_CARE_FSA.benefitKind ===
    benefitKind
  ) {
    benefitTab = DCAP_FSA_PLAN_CONTENT_TABS;
  } else if (
    TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.LIMITED_PURPOSE_FSA
      .benefitKind === benefitKind
  ) {
    benefitTab = LIMITED_FSA_PLAN_CONTENT_TABS;
  }
  return async (dispatch: Dispatch) => {
    dispatch(getDefaultMediaStarted());
    benefitTab.forEach((section) => {
      BenGuideService.getRichMediaContentForPlan(
        benguideId,
        section.code,
        planId,
        true
      )
        .then(({ data }) => {
          dispatch(
            getDefaultMediaSuccess({
              section: section.code,
              media: data,
            })
          );
        })
        .catch((error) => {
          dispatch(getDefaultMediaFailed(error));
        });
    });
  };
};
