import { FC, useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import get from 'lodash/get';

import { useAppSelector } from 'hooks/redux';
import { ToolbarItem } from 'routes/ToolbarRoutes';
import styles from './toolbarDropdown.module.less';

type ToolbarDropdownProps = {
  menuItems: ToolbarItem[];
  selected: string;
  disabled?: boolean;
  onMenuItemClick: (route: string) => void;
};

const ToolbarDropdown: FC<ToolbarDropdownProps> = (
  props: ToolbarDropdownProps
) => {
  const { menuItems, selected, disabled = false, onMenuItemClick } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.toolbarHeader}>BENEFITS GUIDE EDITOR</div>
      <Dropdown
        overlay={getMenu(menuItems, onMenuItemClick)}
        trigger={['click']}
        overlayClassName={`${styles.overlay}`}
        placement="bottomCenter"
        disabled={disabled}
      >
        <Button className={styles.selectedBtn}>{selected}</Button>
      </Dropdown>
    </div>
  );
};

export default ToolbarDropdown;

const getMenu = (
  items: ToolbarItem[],
  onMenuItemClick: (route: string) => void
) => (
  <Menu mode="vertical">
    {items.map((navItem, index) => (
      <ToolbarMenuItem
        key={`header-nav-${index}`}
        navItem={navItem}
        onClick={onMenuItemClick}
      />
    ))}
  </Menu>
);

type ToolbarMenuItemProps = {
  navItem: ToolbarItem;
  onClick: (route: string) => void;
  key: string;
};

const ToolbarMenuItem: FC<ToolbarMenuItemProps> = (
  props: ToolbarMenuItemProps
) => {
  const { navItem, onClick, ...rest } = props;

  const [isSelected, setIsSelected] = useState(false);

  const location = useLocation();

  const state = useAppSelector((state) => state);

  useEffect(() => {
    if (navItem.children) {
      const matchedSub = navItem.children.some((subRoute) =>
        matchPath(`/:hash/${subRoute}`, location.pathname)
      );
      setIsSelected(matchedSub);
      return;
    }
    const match = matchPath(`/:hash/${navItem.path}`, location.pathname);
    setIsSelected(Boolean(match));
  }, [location.pathname, navItem.children, navItem.path]);

  const getChildRoutePlanTotal = (childRoutes: ToolbarItem[]) => {
    return `${childRoutes.reduce((sum: number, childItem: ToolbarItem) => {
      if (childItem.planPath) {
        return sum + get(state, childItem.planPath, []).length;
      }
      return sum;
    }, 0)}`;
  };

  return (
    <Menu.Item
      className={isSelected ? styles.active : ''}
      onClick={() => onClick(navItem.path)}
      {...rest}
    >
      <Link className={styles.dropdownLink} to={navItem.path}>
        {navItem.label}
        {navItem.children && ` (${getChildRoutePlanTotal(navItem.children)})`}
        {navItem.planPath && ` (${get(state, navItem.planPath, []).length})`}
      </Link>
    </Menu.Item>
  );
};
