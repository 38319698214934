import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Modal, ModalProps, Row } from 'antd';
import AiAssistantChatScreen from 'modules/aiAssistantChat/components/AiAssistantChatScreen/AiAssistantChatScreen';
import { ReactComponent as MinimizeIcon } from 'images/icon-modal-minimize.svg';
import { ReactComponent as CloseIcon } from 'images/icon-modal-close.svg';
import styles from 'modules/aiAssistantChat/components/AiAssistantChatModal/aiAssistantChatModal.module.less';
import {
  setToNewChat,
  setUpdateLastMessageAnimate,
} from 'modules/aiAssistantChat/slices/aiAssistantChatSlice';

interface Props extends ModalProps {
  visible: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
}

const AiAssistantChatModal = (props: Props) => {
  const { visible, setIsChatOpen, ...rest } = props;

  const dispatch = useDispatch();
  const handleMinimize = () => {
    setIsChatOpen(false);
    dispatch(setUpdateLastMessageAnimate());
  };
  const handleClose = () => {
    dispatch(setToNewChat());
    setIsChatOpen(false);
  };
  return (
    <Modal
      width={850}
      visible={visible}
      maskClosable={false}
      centered
      wrapClassName={styles.modalMain}
      title={
        <Row justify="end" gutter={16}>
          <Col onClick={handleMinimize}>
            <MinimizeIcon className={styles.minimizeIcon} />
          </Col>
          <Col onClick={handleClose}>
            <CloseIcon className={styles.closeIcon} />
          </Col>
        </Row>
      }
      closable={false}
      footer={null}
      destroyOnClose
      {...rest}
    >
      <AiAssistantChatScreen />
    </Modal>
  );
};

export default memo(AiAssistantChatModal);
