import { FC } from 'react';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import Modal from 'antd/lib/modal/Modal';
import { planRecommenderStatusMissingData } from 'utils/commonUtil';
import styles from './missingDataModal.module.less';

type MissingDataModalProps = {
  setVisible: Function;
  status: boolean;
  missingData: any;
  plans: any[];
};

const MissingDataModal: FC<MissingDataModalProps> = ({
  status,
  setVisible,
  missingData,
  plans,
}: MissingDataModalProps) => {
  const keys = Object.keys(missingData);

  return (
    <Modal
      visible={status}
      footer={[
        <div className={styles.missingDataModalWrapper} key={'buttons'}>
          <Button
            className={styles.done}
            onClick={() => {
              setVisible(false);
            }}
          >
            Close
          </Button>
        </div>,
      ]}
      title={[
        <div key={'title'} className={styles.title}>
          Missing Plan Data
        </div>,
      ]}
      centered
      className={styles.missingDataModalWrapper}
      closable={false}
    >
      {!isEmpty(plans)
        ? keys.map((obj, index) => {
            return (
              <div key={index}>
                <div className={styles.planText}>{obj}</div>
                {missingData[obj].map((item: any) => (
                  <ul key={item}>
                    {planRecommenderStatusMissingData.map((obj) => {
                      if (obj.title === item)
                        return (
                          <li className={styles.missingText}>{obj.message}</li>
                        );
                    })}
                  </ul>
                ))}
              </div>
            );
          })
        : ''}
      <p className={styles.validationText}>
        NOTE: All values must contain a dollar amount
      </p>
    </Modal>
  );
};

export default MissingDataModal;
