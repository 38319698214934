import { createSlice, Dispatch } from '@reduxjs/toolkit';
import * as benguideService from 'services/BenGuideService';

type PlanRecommenderState = {
  stepCount: number;
  currentStep: number;
  questionsInProgress: boolean;
  questions: any[];
  answers: any[];
  plans: any[];
  answersInProgress: boolean;
  resultInProgress: boolean;
};

const initialState: PlanRecommenderState = {
  stepCount: 0,
  currentStep: 0,
  questionsInProgress: false,
  questions: [],
  answers: [],
  plans: [],
  answersInProgress: false,
  resultInProgress: false,
};

const planRecommenderSlice = createSlice({
  name: 'planRecommender',
  initialState,
  reducers: {
    previousStep: (state) => {
      state.currentStep = state.currentStep - 1;
    },
    nextStep: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    updateAnswerStarted: (state) => {
      state.answersInProgress = true;
    },
    updateAnswers: (state, { payload }) => {
      state.answers = payload;
      state.answersInProgress = false;
    },
    updateQuestions: (state, { payload }) => {
      state.questions = payload;
      state.stepCount = payload.length;
    },
    updateResultStarted: (state) => {
      state.resultInProgress = true;
    },
    updateResult: (state, { payload }) => {
      state.plans = payload;
      state.resultInProgress = false;
    },
    updateResultFailed: (state) => {
      state.resultInProgress = false;
      state.answersInProgress = false;
    },
  },
});

export const {
  nextStep,
  previousStep,
  updateQuestions,
  updateAnswers,
  updateResult,
  updateAnswerStarted,
  updateResultStarted,
  updateResultFailed,
} = planRecommenderSlice.actions;

export const fetchPlanRecommenderQuestions = (
  benguideId: string,
  revision: number,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    await benguideService
      .getPlanRecommenderQuestions(benguideId, benefitKind)
      .then(({ data }) => {
        dispatch(updateQuestions(data));
      });
  };
};

export const updatePlanRecommenderAnswers = (
  benguideId: string,
  revision: number,
  benefitKind: string,
  questionId: string,
  answer: any,
  answers: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateAnswerStarted());
    const filteredAnswers = answers.filter(
      (answerObj: any) => answerObj.questionId !== questionId
    );
    filteredAnswers.push({ questionId: questionId, answer: answer });
    dispatch(updateAnswers(filteredAnswers));
    benguideService
      .updatePlanRecommenderAnswers(benguideId, benefitKind, filteredAnswers)
      .then(({ data }) => {
        dispatch(updateQuestions(data));
      })
      .catch((error) => console.error(error.response));
  };
};

export const fetchPlanRecommenderResult = (
  benguideId: string,
  revision: number,
  benefitKind: string,
  answers: any,
  plans: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateResultStarted());
    try {
      const { data } = await benguideService.fetchPlanRecommenderResult(
        benguideId,
        benefitKind,
        answers
      );

      const updatedPlans = data.map((plan: any) => {
        const dbgPlan =
          plans.find(
            (p: any) => p.id === plan.planId && p.planRevision === plan.revision
          ) || {};
        return { ...plan, ...dbgPlan };
      });
      dispatch(updateResult(updatedPlans));
    } catch (error) {
      dispatch(updateResultFailed());
    }
  };
};

export const updatePlanRecommenderAnswersEditDrawer = (
  benguideId: string,
  revision: number,
  benefitKind: string,
  answers: any,
  plans: any
) => {
  return async (dispatch: any) => {
    dispatch(updateAnswerStarted());

    dispatch(updateAnswers(answers));
    benguideService
      .updatePlanRecommenderAnswers(benguideId, benefitKind, answers)
      .then(({ data }) => {
        dispatch(updateQuestions(data));
      })
      .then(() =>
        dispatch(
          fetchPlanRecommenderResult(
            benguideId,
            revision,
            benefitKind,
            answers,
            plans
          )
        )
      )
      .catch((error) => console.error(error.response));
  };
};

export default planRecommenderSlice.reducer;
