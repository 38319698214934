import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import OEMeeting from 'model/oeMeeting/OEMeeting';
import OEVideoFile from 'model/oeMeeting/OEVideoFile';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import { regenerateNHCContent } from 'modules/newHireChecklist/slices/nhcSlice';
import { SectionName } from 'modules/home/constants';

interface OEMeetingState {
  inProgress: boolean;
  requestType: string;
  error: any;
  oeMeeting: OEMeeting | null;
  oeMeetingSuccess: boolean;
  oeVideoFile: OEVideoFile | null;
}

const initialState = {
  inProgress: false,
  requestType: '',
  error: null,
  oeMeeting: null,
  oeMeetingSuccess: false,
  oeVideoFile: null,
} as OEMeetingState;

const oeMeetingInProgress = (state: OEMeetingState, action: PayloadAction) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.oeMeeting = null;
  state.error = null;
  state.oeMeetingSuccess = false;
};

const oeMeetingCompleted = (
  state: OEMeetingState,
  action: PayloadAction<OEMeeting>,
  isCreate: boolean
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.oeMeeting = action.payload;
  state.error = null;
  state.oeMeetingSuccess = isCreate;
};

const oeMeetingFailed = (state: OEMeetingState, action: PayloadAction) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.oeMeeting = null;
  state.error = action.payload;
  state.oeMeetingSuccess = false;
};

const oeMeetingSlice = createSlice({
  name: 'oeMeeting',
  initialState,
  reducers: {
    updateOEMeetingStarted(state, action: PayloadAction) {
      oeMeetingInProgress(state, action);
    },
    updateOEMeetingSuccess(state, action: PayloadAction<OEMeeting>) {
      oeMeetingCompleted(state, action, true);
    },
    updateOEMeetingFailed(state, action) {
      oeMeetingFailed(state, action);
    },
    getOEMeetingStarted(state, action: PayloadAction) {
      oeMeetingInProgress(state, action);
    },
    getOEMeetingSuccess(state, action: PayloadAction<OEMeeting>) {
      oeMeetingCompleted(state, action, false);
    },
    getOEMeetingFailed(state, action) {
      oeMeetingFailed(state, action);
    },
    uploadOEVideoStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.oeVideoFile = null;
      state.error = null;
    },
    uploadOEVideoSuccess(state, action: PayloadAction<OEVideoFile>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.oeVideoFile = action.payload;
      state.error = null;
    },
    uploadOEVideoFailed(state, action) {
      state.inProgress = false;
      state.requestType = action.type;
      state.oeVideoFile = null;
      state.error = action.payload;
    },
    resetOEVideoStateCompleted: (state) => {
      state.oeVideoFile = null;
    },
  },
});

export const {
  updateOEMeetingStarted,
  updateOEMeetingSuccess,
  updateOEMeetingFailed,
  getOEMeetingStarted,
  getOEMeetingSuccess,
  getOEMeetingFailed,
  uploadOEVideoStarted,
  uploadOEVideoSuccess,
  uploadOEVideoFailed,
  resetOEVideoStateCompleted,
} = oeMeetingSlice.actions;

export default oeMeetingSlice.reducer;

export const getOEMeeting =
  (benGuideId: string, oeMeetingId: string) => async (dispatch: Dispatch) => {
    dispatch(getOEMeetingStarted());
    try {
      const response = await BenGuideService.getOEMeeting(
        benGuideId,
        oeMeetingId
      );
      dispatch(getOEMeetingSuccess(response.data));
    } catch (error) {
      dispatch(getOEMeetingFailed(error));
    }
  };

export const updateOEMeeting =
  (
    benGuideId: string,
    oeMeeting: OEMeeting,
    isCreate: boolean,
    isNHCUpdated: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(updateOEMeetingStarted());
    try {
      let response;
      if (isCreate) {
        response = await BenGuideService.createOEMeeting(benGuideId, oeMeeting);
      } else {
        response = await BenGuideService.updateOEMeeting(benGuideId, oeMeeting);
      }
      if (isNHCUpdated) {
        dispatch(regenerateNHCContent(benGuideId, SectionName.NHC_TEXT));
      }

      dispatch(updateOEMeetingSuccess(response.data));
    } catch (error) {
      dispatch(updateOEMeetingFailed(error));
    }
  };

export const uploadOEVideo =
  (benGuideId: string, revision: number, video: File) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadOEVideoStarted());
    try {
      const response = await BenGuideService.uploadOEMeetingVideoRecording(
        benGuideId,
        video
      );
      dispatch(uploadOEVideoSuccess(response.data));
    } catch (error) {
      dispatch(uploadOEVideoFailed(error));
    }
  };

export const resetOEVideoState = () => {
  return (dispatch: Dispatch) => {
    dispatch(resetOEVideoStateCompleted());
  };
};
