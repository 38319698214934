import { FC, ReactNode } from 'react';

import styles from './planCardContainer.module.less';

type Props = {
  children: ReactNode;
  className?: string;
};

const PlanCardContainer: FC<Props> = (props: Props) => {
  const { children, className = '' } = props;

  return <div className={`${styles.container} ${className}`}>{children}</div>;
};

export default PlanCardContainer;
