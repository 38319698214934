import { LoadingOutlined } from '@ant-design/icons';

import styles from './fullScreenLoader.module.less';

const FullScreenLoader = () => {
  return (
    <div className={styles.fullScreenLoaderWrapper}>
      <LoadingOutlined style={{ fontSize: 50 }} spin />
    </div>
  );
};

export default FullScreenLoader;
