import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { PromptType, ScreenSelectionType } from 'model/AiAssistant/common';
import SelectionCard, {
  SelectionCardTypes,
} from 'modules/aiAssistantChat/components/SelectionCard/SelectionCard';
import AiAssistantChatBody from 'modules/aiAssistantChat/components/AiAssistantChatBody/AiAssistantChatBody';
import AiAssistantChatFooter from 'modules/aiAssistantChat/components/AiAssistantChatFooter/AiAssistantChatFooter';
import PlanAssistantModal from 'modules/aiAssistantChat/components/PlanAssistanceModal/PlanAssistantModal';

import {
  setIsPlanModalOpen,
  setPromptType,
} from 'modules/aiAssistantChat/slices/aiAssistantChatSlice';
import {
  GENERAL_ASSISTANT_TEXT,
  GENERAL_HEADER_TEXT,
  HELP_HEADER_TEXT,
  PLAN_ASSISTANT_TEXT,
  PLAN_HEADER_TEXT,
  SELECTION_GENERAL_ASSISTANT_TEXT,
  SELECTION_PLAN_ASSISTANCE_TEXT,
} from 'modules/aiAssistantChat/constants/constants';
import { ReactComponent as AiAssistantIcon } from 'images/icon-ai-assistant-black.svg';
import { ReactComponent as PlansIcon } from 'images/icon-plan-assistance.svg';
import { ReactComponent as GeneralIcon } from 'images/icon-general-questions.svg';
import { trackEvents } from 'utils/initGA4';
import styles from './aiAssistantChatScreen.module.less';

const AiAssistantChatScreen = () => {
  const dispatch = useAppDispatch();
  const aiAssistantState = useAppSelector(
    (state) => state.persisted.aiAssistantChat
  );
  const { selectedScreen, promptType, messages } = aiAssistantState;
  const [scrollingInterrupted, setScrollingInterrupted] =
    useState<boolean>(false);
  const prevScrollTopRef = useRef(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  const lastMessage = messages[messages.length - 1];

  /**
   * Handles the scroll event on the target element.
   * Determines whether scrolling has been interrupted based on the scroll position.
   *
   * @param {React.UIEvent<HTMLElement>} e - The scroll event.
   */
  const handleScroll = useCallback((e: React.UIEvent<HTMLElement>) => {
    const element = e.target as HTMLDivElement;
    const currentScrollTop = element.scrollTop;
    const maxScrollTop = element.scrollHeight - element.clientHeight;

    if (currentScrollTop >= maxScrollTop) {
      setScrollingInterrupted(false);
    } else if (Math.abs(currentScrollTop - prevScrollTopRef.current) >= 1) {
      if (currentScrollTop < prevScrollTopRef.current) {
        setScrollingInterrupted(true);
      }
    } else {
      setScrollingInterrupted(false);
    }
    prevScrollTopRef.current = currentScrollTop;
  }, []);

  const scrollIntoView = useCallback(
    (arg?: any) => {
      if (scrollRef.current && !scrollingInterrupted) {
        scrollRef.current.scrollIntoView(arg);
      }
    },
    [scrollRef, scrollingInterrupted]
  );

  useEffect(() => {
    setScrollingInterrupted(false);
    scrollIntoView();

    // Disable exhaustive-deps rule as we need to trigger this effect when the lastMessage changes
    // Resting the scroll position is necessary when the last message is pending

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScreen, lastMessage?.isPending]);

  const initializeContent = {
    [PromptType.DBG_PLAN]: {
      icon: <PlansIcon />,
      title: PLAN_ASSISTANT_TEXT,
    },
    [PromptType.GENERAL_BENEFITS]: {
      icon: <GeneralIcon />,
      title: GENERAL_ASSISTANT_TEXT,
    },
  };

  const selectionCardArr: SelectionCardTypes[] = [
    {
      title: PLAN_HEADER_TEXT,
      description: SELECTION_PLAN_ASSISTANCE_TEXT,
      icon: <PlansIcon />,
      type: PromptType.DBG_PLAN,
      onClick: () => {
        dispatch(setIsPlanModalOpen(true));
        trackEvents({
          category: 'AI Assistant',
          action: 'ai_assistant_plan',
          label: 'AI Assistant Plan',
        });
      },
    },
    {
      title: GENERAL_HEADER_TEXT,
      description: SELECTION_GENERAL_ASSISTANT_TEXT,
      icon: <GeneralIcon />,
      type: PromptType.GENERAL_BENEFITS,
      onClick: () => {
        dispatch(setPromptType(PromptType.GENERAL_BENEFITS));
        trackEvents({
          category: 'AI Assistant',
          action: 'ai_assistant_general_benefits',
          label: 'AI Assistant General Benefits',
        });
      },
    },
  ];

  const renderBodyWrapper = (
    additionalClasses: string,
    children: ReactNode
  ) => (
    <div
      className={`${styles.bodyWrapper} ${additionalClasses}`}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );

  const renderHeader = (icon: ReactNode, title: string) => (
    <div className={styles.headerWrapper}>
      {icon}
      <h1 className={styles.headerText}>{title}</h1>
    </div>
  );

  let bodyContent;
  let includeFooter = false;

  switch (selectedScreen) {
    case ScreenSelectionType.SELECTION:
      bodyContent = renderBodyWrapper(
        styles.selectionHeight,
        <>
          <div className={styles.selectionSectionWrapper}>
            {renderHeader(<AiAssistantIcon />, HELP_HEADER_TEXT)}
            <div className={styles.selectionWrapper}>
              {selectionCardArr?.map((item) => (
                <SelectionCard key={item.type} {...item} />
              ))}
            </div>
          </div>
          <PlanAssistantModal />;
        </>
      );
      break;

    case ScreenSelectionType.NEW_CHAT_INITIALIZE:
      bodyContent = renderBodyWrapper(
        styles.chatHeight,
        <div className={styles.selectionSectionWrapper}>
          {renderHeader(
            initializeContent[
              (promptType as PromptType) || PromptType.GENERAL_BENEFITS
            ]?.icon,
            initializeContent[
              (promptType as PromptType) || PromptType.GENERAL_BENEFITS
            ]?.title
          )}
        </div>
      );
      includeFooter = true;
      break;

    case ScreenSelectionType.CHAT_HISTORY:
      bodyContent = renderBodyWrapper(
        `${styles.chatHeight} ${styles.chatPadding}`,
        <>
          <AiAssistantChatBody scrollIntoView={scrollIntoView} />
          <div ref={scrollRef}></div>
        </>
      );
      includeFooter = true;
      break;

    default:
      return null;
  }

  return (
    <>
      {bodyContent}
      {includeFooter && (
        <AiAssistantChatFooter
          scrollIntoView={() => scrollIntoView({ behavior: 'smooth' })}
        />
      )}
    </>
  );
};

export default memo(AiAssistantChatScreen);
