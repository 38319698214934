import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import {
  fetchWellbeingPlanList,
  reloadWellbeingSection,
} from 'modules/benefitsWellness/slices/wellbeingSlice';
import UPClient from 'modules/clients/UPClient/UPClient';
import useUPClient from 'modules/clients/UPClient/useUPClient';

import {
  WELLBEING_PLAN_CHANNEL,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
  RELOAD_WELLBEING_PLAN_LIST,
  RELOAD_WELLBEING_PLAN,
} from 'modules/clients/UPClient/UPClientConst';

const WellbeingPlansChannel = () => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();
  return (
    <UPClient
      channel={WELLBEING_PLAN_CHANNEL}
      subscribe={(event: string) => {
        switch (event) {
          case RELOAD_WELLBEING_PLAN_LIST:
            dispatch(
              fetchWellbeingPlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case RELOAD_WELLBEING_PLAN:
            dispatch(
              reloadWellbeingSection(benguide.masterId, benguide.latestRevision)
            );
            dispatch(
              fetchWellbeingPlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default WellbeingPlansChannel;
