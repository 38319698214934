import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import * as BenefitConsultationService from 'modules/home/services/BenefitConsultationService';
import { SectionName } from 'modules/home/constants';
import { getRichMedia } from 'modules/auth/services/BenguideService';
import ContentPage from 'model/CotentPage';
import BenconsultationConfig from 'model/BenConsultationConfig';
import RichMedia from 'model/RichMedia';
import { CONSULTANT_TYPES } from 'constants/commonConstants';

interface ContactSupportState {
  inProgress: boolean;
  defaultTextSupport: string;
  error: any;
  contentSupport: string;
  contentSupportBC: string;
  contentSupportSuccess: boolean;
  contactSupportConfig: ContentPage | null;
  benefitConsultationConfig: BenconsultationConfig | null;
  defaultTextBC: string;
  consultantConfirmationBroker: any;
  consultantConfirmationEmployer: any;
}

const initialState = {
  inProgress: false,
  defaultTextSupport: '',
  error: null,
  contentSupport: '',
  contentSupportBC: '',
  contentSupportSuccess: true,
  contactSupportConfig: null,
  benefitConsultationConfig: null,
  latestRevision: 0,
  defaultTextBC: '',
  consultantConfirmationBroker: null,
  consultantConfirmationEmployer: null,
} as ContactSupportState;

const contactSupportSlice = createSlice({
  name: 'contactSupport',
  initialState,
  reducers: {
    getDefaultRichMediaSupportStarted(state) {
      state.inProgress = true;
      state.defaultTextSupport = '';
      state.contentSupportSuccess = false;
    },
    getDefaultRichMediaSupportSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultTextSupport = action.payload;
    },
    getDefaultRichMediaSupportFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultTextSupport = '';
      state.error = action.payload;
    },
    getContentRichMediaSupportStarted(state) {
      state.inProgress = true;
    },
    getContentRichMediaSupportSuccess(
      state,
      action: PayloadAction<{ content: string; sectionName: SectionName }>
    ) {
      state.inProgress = false;
      if (action.payload.sectionName === SectionName.CONTACT_SUPPORT_GENERAL) {
        state.contentSupport = action.payload.content;
      } else {
        state.contentSupportBC = action.payload.content;
      }
    },
    getContentRichMediaSupportFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.contentSupport = '';
      state.contentSupportBC = '';
      state.error = action.payload;
    },
    createRichMediaSupportStarted(state) {
      state.inProgress = true;
      state.contentSupportSuccess = false;
    },
    createRichMediaSupportSuccess(
      state,
      action: PayloadAction<{ media: RichMedia; sectionName: SectionName }>
    ) {
      state.inProgress = false;
      if (action.payload.sectionName === SectionName.CONTACT_SUPPORT_GENERAL) {
        state.contentSupport = action.payload.media.content;
      } else {
        state.contentSupportBC = action.payload.media.content;
      }
      state.contentSupportSuccess = true;
    },
    createRichMediaSupportFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.contentSupport = '';
      state.contentSupportBC = '';
      state.error = action.payload;
      state.contentSupportSuccess = false;
    },
    resetDefaultText(state) {
      state.inProgress = false;
      state.defaultTextSupport = '';
      state.error = null;
      state.contentSupportSuccess = false;
    },
    getContactSupportConfigStarted(state) {
      state.inProgress = true;
      state.error = null;
      state.contactSupportConfig = null;
    },
    getContactSupportConfigSuccess(state, action: PayloadAction<ContentPage>) {
      state.inProgress = false;
      state.error = null;
      state.contactSupportConfig = action.payload;
    },
    getContactSupportConfigFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
      state.contactSupportConfig = null;
    },
    getBenefitConsultationConfigStarted(state) {
      state.inProgress = true;
      state.error = null;
      state.benefitConsultationConfig = null;
    },
    getBenefitConsultationConfigSuccess(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = null;
      state.benefitConsultationConfig = action.payload;
    },
    getBenefitConsultationConfigFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
      state.benefitConsultationConfig = null;
    },
    getDefaultRichMediaStartedBC(state) {
      state.inProgress = true;
      state.defaultTextBC = '';
    },
    getDefaultRichMediaSuccessBC(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultTextBC = action.payload;
    },
    getDefaultRichMediaFailedBC(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultTextBC = '';
      state.error = action.payload;
    },
    getBenefitConsultationConformationStarted(state) {
      state.inProgress = true;
    },
    getBenefitConsultationConformationSuccessEmployer(
      state,
      action: PayloadAction<any>
    ) {
      state.inProgress = false;
      state.consultantConfirmationEmployer = action.payload?.content;
    },
    getBenefitConsultationConformationSuccessBroker(
      state,
      action: PayloadAction<any>
    ) {
      state.inProgress = false;
      state.consultantConfirmationBroker = action.payload?.content;
    },
    getBenefitConsultationConformationFailed(
      state,
      action: PayloadAction<any>
    ) {
      state.inProgress = false;
      state.error = action.payload;
    },
  },
});

export const {
  getDefaultRichMediaSupportStarted,
  getDefaultRichMediaSupportSuccess,
  getDefaultRichMediaSupportFailed,
  getContentRichMediaSupportFailed,
  getContentRichMediaSupportStarted,
  getContentRichMediaSupportSuccess,
  createRichMediaSupportStarted,
  createRichMediaSupportSuccess,
  createRichMediaSupportFailed,
  resetDefaultText,
  getContactSupportConfigStarted,
  getContactSupportConfigSuccess,
  getContactSupportConfigFailed,

  getBenefitConsultationConfigStarted,
  getBenefitConsultationConfigSuccess,
  getBenefitConsultationConfigFailed,
  getDefaultRichMediaStartedBC,
  getDefaultRichMediaSuccessBC,
  getDefaultRichMediaFailedBC,
  getBenefitConsultationConformationStarted,
  getBenefitConsultationConformationSuccessEmployer,
  getBenefitConsultationConformationSuccessBroker,
  getBenefitConsultationConformationFailed,
} = contactSupportSlice.actions;

export default contactSupportSlice.reducer;

export const getDefaultRichMediaSupport =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaSupportStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultRichMediaSupportSuccess(response.data.content));
    } catch (error) {
      dispatch(
        getDefaultRichMediaSupportFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const getDefaultRichMediaBC =
  (benguideId: string) => async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaStartedBC());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        SectionName.CONTACT_SUPPORT_BENEFIT_CONSULTATION
      );
      dispatch(getDefaultRichMediaSuccessBC(response.data.content));
    } catch (error) {
      dispatch(getDefaultRichMediaFailedBC(JSON.parse(JSON.stringify(error))));
    }
  };

export const getContentRichMediaSupport =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getContentRichMediaSupportStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(
        getContentRichMediaSupportSuccess({
          content: response.data.content,
          sectionName,
        })
      );
    } catch (error) {
      dispatch(
        getContentRichMediaSupportFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const createRichMediaSupport =
  (
    benguideId: string,
    sectionName: SectionName,
    contentOverview: string,
    enabled?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(createRichMediaSupportStarted());
    try {
      const data = {
        sectionName: sectionName,
        content: contentOverview,
      };
      const sectionData = {
        enabled: enabled,
      };
      enabled !== undefined &&
        (await BenGuideService.updateSection(
          benguideId,
          SectionName.CONTACT_SUPPORT,
          sectionData
        ));
      await BenGuideService.createRichMedia(benguideId, data);
      const createdData: RichMedia = {
        content: data.content,
        benGuideId: '',
        title: sectionName,
      };
      dispatch(
        createRichMediaSupportSuccess({ media: createdData, sectionName })
      );
    } catch (error) {
      dispatch(createRichMediaSupportFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const getContactSupportConfigdata =
  (masterId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(getContactSupportConfigStarted());
      const response = await BenGuideService.getContentPage(
        masterId,
        SectionName.CONTACT_SUPPORT
      );
      dispatch(getContactSupportConfigSuccess(response.data.contentPageVO));
    } catch (error) {
      dispatch(
        getContactSupportConfigFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const getBenefitConsultationConfigData =
  (organizationId: string, employerId: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(getBenefitConsultationConfigStarted());
      const response =
        await BenefitConsultationService.getBenefitConsultationConfig(
          organizationId,
          employerId
        );
      dispatch(getBenefitConsultationConfigSuccess(response.data));
    } catch (error) {
      dispatch(
        getBenefitConsultationConfigFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };

export const checkIfConsultantsExist =
  (consultantLevel: string, organizationId: string, employerId: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(getBenefitConsultationConformationStarted());
      const response = await BenefitConsultationService.checkIfConsultantsExist(
        consultantLevel,
        organizationId,
        employerId
      );
      if (consultantLevel === CONSULTANT_TYPES.ER) {
        dispatch(
          getBenefitConsultationConformationSuccessEmployer(response.data)
        );
      } else {
        dispatch(
          getBenefitConsultationConformationSuccessBroker(response.data)
        );
      }
    } catch (error) {
      dispatch(
        getBenefitConsultationConformationFailed(
          JSON.parse(JSON.stringify(error))
        )
      );
    }
  };
