import { FC, ReactNode } from 'react';

import styles from './pageHeader.module.less';

type PageHeaderProps = {
  pageHeaderTitle: string;
  pageHeaderIcon: ReactNode;
  customIconStyle?: string;
  managePlansButton?: ReactNode;
};

const PageHeader: FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const {
    pageHeaderTitle,
    pageHeaderIcon,
    customIconStyle = '',
    managePlansButton,
  } = props;

  return (
    <div className={styles.pageHeaderWrapper}>
      <div className={`${styles.iconComponent} ${customIconStyle}`}>
        {pageHeaderIcon}
      </div>
      <span>{pageHeaderTitle}</span>
      {managePlansButton && (
        <div className={styles.managePlansBtn}>{managePlansButton}</div>
      )}
    </div>
  );
};

export default PageHeader;
