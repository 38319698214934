import { FC } from 'react';

import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Input } from 'antd';
import { isEmptyString } from 'utils/stringUtil';

interface CashInputProps {
  value?: string;
  onChange?: Function;
  size?: SizeType;
  className?: string;
  disabled?: boolean;
}

const CashInput: FC<CashInputProps> = ({
  value,
  onChange,
  size,
  disabled,
  className,
}: CashInputProps) => {
  const onChangeHandler = (value: string) => {
    const outputValue = value.replace(/,/g, '');
    if (/^\d*\.?\d{0,2}$/.test(outputValue)) {
      value = outputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (onChange) onChange(value);
    }
  };

  const getValue = () => {
    if (isEmptyString(value)) return '';
    else return (value + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <Input
      size={size}
      disabled={disabled}
      prefix={'$'}
      onChange={(e) => onChangeHandler(e.target.value)}
      value={getValue()}
      className={className}
    />
  );
};

export default CashInput;
