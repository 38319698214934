import { FC, ReactNode, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import cssVars from 'css-vars-ponyfill';
import { isEmpty } from 'lodash';
import { useGAPageViews } from 'hooks/useGAPageViews';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  BENGUIDE_COMMON_CHANNEL,
  BENGUIDE_LOADING_COMPLETED,
  CLOSE_MODALS,
  UP_COMMON_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import { setCloseModalsInUP } from 'modules/auth/slices/benguideSlice';
import { isInIframe } from 'utils/httpUtil';
import { initGTM } from 'utils/initGTM';
import { initGA4 } from 'utils/initGA4';

type LayoutWrapperProps = {
  children: ReactNode;
};

const LayoutWrapper: FC<LayoutWrapperProps> = ({
  children,
}: LayoutWrapperProps) => {
  const { benguide } = useSelector((state: any) => state);
  const { data: benguideData, closeModalsInUP } = benguide;
  const { styles: styleConfig = {} } = benguideData || {};
  const upClient = useUPClient();
  const dispatch = useDispatch();

  useEffect(() => {
    initGTM();
    initGA4();
  }, []);

  useGAPageViews();

  useEffect(() => {
    if (closeModalsInUP && isInIframe()) {
      upClient.postMessage({
        channel: UP_COMMON_CHANNEL,
        event: CLOSE_MODALS,
        data: {},
      });
      dispatch(setCloseModalsInUP(false));
    }
  }, [dispatch, closeModalsInUP, upClient]);

  const setColorHashes = useCallback(() => {
    const root = document.documentElement;
    cssVars({
      variables: {
        'home-text-color':
          !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
            ? styleConfig.homeTextColor
            : '#000000',
        'color-theme':
          !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
            ? styleConfig.colorTheme
            : '#000000',
      },
    });
    root.style.setProperty(
      '--home-text-color',
      !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
        ? styleConfig.homeTextColor
        : '#000000'
    );
    root.style.setProperty(
      '--color-theme',
      !isEmpty(styleConfig) && !isEmpty(styleConfig.homeTextColor)
        ? styleConfig.colorTheme
        : '#000000'
    );
  }, [styleConfig]);

  useEffect(() => {
    if (!isEmpty(benguideData)) {
      setColorHashes();
    }
  }, [benguideData, setColorHashes]);

  useEffect(() => {
    upClient.postMessage({
      channel: BENGUIDE_COMMON_CHANNEL,
      event: BENGUIDE_LOADING_COMPLETED,
      data: {},
    });
  });

  return <>{children}</>;
};

export default LayoutWrapper;
