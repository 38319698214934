import styles from './badge.module.less';

export enum SizeType {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

type Props = {
  size: SizeType;
  className?: string;
  color?: string;
  text?: string | number;
};

const Badge = (props: Props) => {
  const { size, className, color = '#E1464E', text } = props;
  return (
    <span
      className={`${styles.badge} ${className || ''} ${styles[size]}`}
      style={{ backgroundColor: `${color}` }}
    >
      {text}
    </span>
  );
};

export default Badge;
