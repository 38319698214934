import SelectSectionName from 'model/SectionName';
import { SectionName } from 'modules/home/constants';

export const HIW_SECTIONS: SelectSectionName[] = [
  {
    title: 'How does a Deductible Work?',
    code: SectionName.HIW_HOW_DOES_A_DEDUCTIBLE_WORK,
  },
  {
    title: 'What Counts Towards a Deductible?',
    code: SectionName.HIW_WHAT_COUNTS_TOWARDS_A_DEDUCTIBLE,
  },
  {
    title: 'What is Coinsurance?',
    code: SectionName.HIW_WHAT_IS_COINSURANCE,
  },
  {
    title: 'What Counts Towards an Out-of-Pocket Limit?',
    code: SectionName.HIW_COUNTS_TOWARDS_AN_OUT_OF_POCKET_LIMIT,
  },
  {
    title: 'What is In-Network vs. Out-of-Network?',
    code: SectionName.HIW_WHAT_IS_IN_NETWORK_VS_OUT_NETWORK,
  },
  {
    title: 'What Happens If I Go Out-of-Network?',
    code: SectionName.HIW_WHAT_HAPPENS_IF_I_GO_OUT_OF_NETWORK,
  },
  {
    title: 'Changing Medical Carriers',
    code: SectionName.HIW_CHANGING_MEDICAL_CARRIERS,
  },
  {
    title: 'Prior Authorization for Prescriptions',
    code: SectionName.HIW_PRIOR_AUTHORIZATION_OF_PRESCRIPTION,
  },
];
