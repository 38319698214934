import useBenguide from 'hooks/benguide';
import { useAppDispatch } from 'hooks/redux';
import {
  fetchFourOOneKPlanList,
  reloadFourOOneKSection,
} from 'modules/fourOOneK/slices/fourOOneKSlice';
import UPClient from 'modules/clients/UPClient/UPClient';
import {
  FOUR_O_ONE_K_PLAN_CHANNEL,
  RELOAD_FOUR_O_ONE_K_PLAN_LIST,
  RELOAD_FOUR_O_ONE_K_PLAN,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';

const FourOOneKPlansChannel = () => {
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const upClient = useUPClient();
  return (
    <UPClient
      channel={FOUR_O_ONE_K_PLAN_CHANNEL}
      subscribe={(event: string) => {
        switch (event) {
          case RELOAD_FOUR_O_ONE_K_PLAN_LIST:
            dispatch(
              fetchFourOOneKPlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case RELOAD_FOUR_O_ONE_K_PLAN:
            dispatch(
              reloadFourOOneKSection(benguide.masterId, benguide.latestRevision)
            );
            dispatch(
              fetchFourOOneKPlanList(
                benguide.employerId,
                benguide.planYearId,
                benguide.benefitClasses
              )
            );
            break;
          case BENGUIDE_NOTIFICATION_HIDE_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
              data: {},
            });
            break;
          case BENGUIDE_NOTIFICATION_SHOW_OVERLAY:
            upClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default FourOOneKPlansChannel;
