import { FC, useState, useEffect } from 'react';
import { Button, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getPlanRecommenderStatus } from 'modules/benefitsWellness/slices/PlanRecommenderEnableSlice';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import { ReactComponent as QuestionCircleIcon } from 'images/question-round.svg';
import BoxPopover from 'components/BoxPopover/BoxPopover';

import { planRecommenderStatusErrorMessages } from 'utils/commonUtil';
import styles from './planRecommenderModal.module.less';

type PlanRecommenderModalProps = {
  onClose: () => {};
  onView: Function;
  visible: boolean;
  defaultEnabled: boolean;
  onChange: Function;
  closeContributions?: boolean;
  missingDataModalStatus: Function;
  setMissingPlanAttributes: Function;
  onSubmit: Function;
};

const PlanRecommenderModal: FC<PlanRecommenderModalProps> = ({
  onClose,
  visible,
  defaultEnabled,
  onChange,
  onSubmit,
  onView,
  closeContributions = true,
  missingDataModalStatus,
  setMissingPlanAttributes,
}: PlanRecommenderModalProps) => {
  const { inProgress, planRecommender } = useAppSelector(
    (state) => state.planRecommenderStatus
  );

  const [isEnabled, setEnabled] = useState<boolean>(planRecommender.enabled);

  const dispatch = useAppDispatch();

  const benguideId = useAppSelector((state) => state.benguide.data?.masterId);

  useEffect(() => {
    if (visible) {
      dispatch(getPlanRecommenderStatus(benguideId));
    }
  }, [visible, dispatch, benguideId, defaultEnabled]);

  useEffect(() => {
    if (visible) {
      setEnabled(planRecommender.enabled);
    }
  }, [planRecommender, visible]);

  useEffect(() => {
    onView(false);
  }, [closeContributions, onView]);

  return (
    <Modal
      visible={visible}
      footer={null}
      centered
      className={styles.planRecommenderModalWrapper}
      closable={false}
    >
      <div>
        <BoxPopover
          header="Plan Recommender"
          description="Plan Recommender is a tool that gives employees customized recommendations and cost estimations based on their anticipated health plan usage."
          className={styles.planRecommenderTooltip}
          placement="bottomRight"
        >
          <QuestionCircleIcon />
        </BoxPopover>
        <div className={styles.title}>Edit Plan Recommender</div>
        {inProgress ? (
          <div className={styles.loader}>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        ) : (
          <div>
            {planRecommender?.errorMessages?.length ?? 0 !== 0 ? (
              <AlertMessage
                type="warning"
                message={planRecommenderStatusErrorMessages
                  .filter((plan) =>
                    planRecommender.errorMessages.includes(plan.title)
                  )
                  .map((plan, index) => (
                    <div
                      key={plan.title}
                      className={index !== 0 ? styles.messageWrapper : ''}
                    >
                      <div>
                        <span className={styles.errorMessage}>
                          {plan.message}
                        </span>{' '}
                        {plan.code !== 1 ? (
                          <a
                            className={styles.viewLink}
                            onClick={() => {
                              if (plan.code === 2) onView(true);
                              else if (plan.code === 3) {
                                missingDataModalStatus(true);
                                setMissingPlanAttributes(
                                  planRecommender.missingPlanAttributes
                                );
                              }
                              onClose();
                            }}
                          >
                            View
                          </a>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ))}
                closeAlert={() => {}}
                closeTextStatus={false}
              />
            ) : (
              ''
            )}
            <br />
            <div className={styles.toggleWrapper}>
              <label
                className={
                  !planRecommender.changeable ? styles.displayText : ''
                }
              >
                Display Plan Recommender Button?
              </label>
              <Switch
                checked={isEnabled}
                onChange={() => setEnabled(!isEnabled)}
                disabled={!planRecommender.changeable}
              />
            </div>
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.cancel}
            onClick={() => {
              setEnabled(planRecommender.enabled);
              onClose();
            }}
            disabled={inProgress}
          >
            Cancel
          </Button>
          <Button
            className={styles.done}
            onClick={() => {
              onSubmit((i: any) => !i);
              !planRecommender.changeable ? onClose() : onChange(isEnabled);
            }}
            disabled={inProgress}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PlanRecommenderModal;
