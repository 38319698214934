import { FC, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as InformationBanner } from 'images/information-banner.svg';
import { BenGuideStatus } from 'constants/commonConstants';
import PlanYear from 'model/PlanYear';
import { formatPlanYearLabel } from 'utils/planYearUtils';
import { SHORT_DATE_AND_TIME_FORMAT } from 'utils/dateUtil';

import styles from './statusIndicator.module.less';

type StatusIndicatorProps = {
  status: BenGuideStatus;
  className?: string;
  benGuideName: string;
  planYear: PlanYear | null;
  benefitClasses: string[];
  createdBy: string;
  lastUpdatedBy: string;
  createdTs: string;
  lastUpdatedTs: string;
  isPreviouslyPublished?: boolean;
};

const StatusIndicator: FC<StatusIndicatorProps> = (
  props: StatusIndicatorProps
) => {
  const {
    status,
    className = '',
    benGuideName,
    planYear,
    benefitClasses,
    createdBy,
    createdTs,
    lastUpdatedBy,
    lastUpdatedTs,
    isPreviouslyPublished = false,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="status-indicator">
            <StatusDropdown
              guideName={benGuideName}
              planYear={planYear}
              benefitClasses={benefitClasses}
              createdBy={createdBy}
              lastUpdatedBy={lastUpdatedBy}
              lastUpdatedTs={lastUpdatedTs}
              createdTs={createdTs}
            />
          </Menu.Item>
        </Menu>
      }
      placement="bottomCenter"
      trigger={['click']}
      visible={isDropdownOpen}
      onVisibleChange={(e) => setIsDropdownOpen(e)}
      overlayClassName={styles.dropdownWrapper}
    >
      <div className={`${styles.wrapper} ${className}`}>
        Status:
        <span className={styles.status}>
          {isPreviouslyPublished
            ? STATUS_LABELS.PUBLISHED
            : STATUS_LABELS[status]}
        </span>
      </div>
    </Dropdown>
  );
};

export default StatusIndicator;

const STATUS_LABELS: { [key in BenGuideStatus]: string } = {
  PUBLISHED: 'Published',
  DRAFT: ' In Progress',
  ARCHIVED: 'Archived',
};

type StatusDropdownProps = {
  guideName: string;
  planYear: PlanYear | null;
  benefitClasses: string[];
  createdBy: string;
  lastUpdatedBy: string;
  createdTs: string;
  lastUpdatedTs: string;
};

const StatusDropdown: FC<StatusDropdownProps> = (
  props: StatusDropdownProps
) => {
  const {
    guideName,
    planYear,
    benefitClasses,
    createdBy,
    lastUpdatedBy,
    createdTs,
    lastUpdatedTs,
  } = props;

  return (
    <div className={styles.dropdownContent}>
      <div>
        <InformationBanner />
      </div>
      <div className={styles.infoSection}>
        <div>
          <div className={styles.statusHeader}>About This Guide</div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoLabel}>Guide Name:</div>
          <div className={styles.infoValue}>{guideName}</div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoLabel}>For Plan Year:</div>
          <div className={styles.infoValue}>
            {planYear && formatPlanYearLabel(planYear)}
          </div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoLabel}>For Benefit Class:</div>
          <div className={styles.infoValue}>{benefitClasses.join(', ')}</div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoLabel}>Created By:</div>
          <div className={styles.infoValue}>{`${createdBy}  ${dayjs(
            createdTs
          ).format(SHORT_DATE_AND_TIME_FORMAT)}`}</div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoLabel}>Last Updated By:</div>
          <div className={styles.infoValue}>{`${lastUpdatedBy}  ${dayjs(
            lastUpdatedTs
          ).format(SHORT_DATE_AND_TIME_FORMAT)}`}</div>
        </div>
      </div>
    </div>
  );
};
