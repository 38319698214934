import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Form, Input, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import {
  login,
  passwordReset,
  setEmployeeModal,
} from 'modules/auth/slices/employeeAuthSlice';

import styles from './resetPassword.module.less';

const ResetPassword = () => {
  const { hash = '', employerName = '' } = useParams();
  const baseUrl = `/${hash}/`;
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const userEmail = useAppSelector((state) => state.employeeAuth.user);
  const token = useAppSelector((state) => state.employeeAuth.token);
  const inProgress = useAppSelector((state) => state.employeeAuth.inProgress);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const resetPasswordHandler = async (values: any) => {
    const constructRedirectURL = `benguideV2${baseUrl}${employerName}`;
    if (token) {
      const response = await dispatch(
        passwordReset(token, values?.confirmPassword?.trim())
      );
      if (response?.status === 201) {
        dispatch(
          login(
            values?.email?.trim(),
            values?.confirmPassword?.trim(),
            constructRedirectURL,
            benguideData?.planYearId
          )
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      form?.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({ email: userEmail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  return (
    <Row justify="center" className={styles.wrapperContainer}>
      <InputForm form={form} layout="vertical" onFinish={resetPasswordHandler}>
        <Form.Item
          name="email"
          label={<>Work Email Address</>}
          rules={[
            {
              type: 'email',
              message: 'Invalid email format',
            },
            {
              required: true,
              message: 'Please enter your work email',
            },
          ]}
        >
          <Input
            disabled={!!userEmail}
            className={styles.inputWrapper}
            name="email"
            autoFocus
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          className={styles.formInput}
          label={<>New Password</>}
          rules={[
            {
              pattern: new RegExp(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/
              ),
              message: `Please choose a different password with 8
              or more characters, upper & lower case
              letters, and a special character.`,
            },
            { required: true, message: 'Please enter your new password' },
          ]}
        >
          <Input
            className={styles.inputWrapper}
            name="newPassword"
            type="password"
            autoFocus
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          className={styles.formInput}
          label={<>Confirm Password</>}
          rules={[
            {
              required: true,
              message: 'Please enter confirmation password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
              },
            }),
          ]}
        >
          <Input
            className={styles.inputWrapper}
            name="confirmPassword"
            type="password"
            autoComplete="off"
          />
        </Form.Item>
        <SubmitButton
          type="primary"
          htmlType="submit"
          disabled={inProgress}
          loading={inProgress}
          className={styles.submitPwdButton}
        >
          Save
        </SubmitButton>
        <Row justify="center">
          <LinkButton
            className={styles.resetPwdButton}
            onClick={() => dispatch(setEmployeeModal(false))}
          >
            Cancel
          </LinkButton>
        </Row>
      </InputForm>
    </Row>
  );
};

export default ResetPassword;
