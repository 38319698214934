import { SectionName } from 'modules/home/constants';

import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const employerUrl = `${baseApi}/v1/employers`;
const benguideServiceUrl = `${baseApi}/v2/benefit-guides`;

export const getPlanYearData = async (
  employerId: string,
  planYearId: string
) => {
  return http.get(`${employerUrl}/${employerId}/plan-years/${planYearId}`);
};

export const getRichMedia = async (
  benguideId: string,
  sectionName: SectionName
) => {
  return http.get(
    `${benguideServiceUrl}/${benguideId}/rich-media?section-name=${sectionName}`
  );
};

export const getSectionImage = async (
  benguideId: string,
  sectionName: SectionName,
  revision: number
) => {
  return http.get(
    `${benguideServiceUrl}/${benguideId}/images?section-name=${sectionName}&revision=${revision}`
  );
};

export const getSectionImageUrl = (
  benguideId: string,
  sectionName: SectionName,
  revision: number
) => {
  return `${benguideServiceUrl}/${benguideId}/images?section-name=${sectionName}&revision=${revision}`;
};

export const getDefaultRichMedia = async (
  benguideId: string,
  sectionName: SectionName
) => {
  return http.get(
    `${benguideServiceUrl}/${benguideId}/rich-media?section-name=${sectionName}&default-value=${true}`
  );
};
