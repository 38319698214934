import { Spin } from 'antd';

import styles from './Spinner.module.less';

const Spinner = () => {
  return (
    <>
      <div className={styles.spinWrapper}>
        <Spin />
      </div>
    </>
  );
};

export default Spinner;
