import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

import styles from 'components/PlanyearPopover/PlanyearPopover.module.less';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  width?: string | number;
  visible?: boolean;
  isDisabled?: boolean;
  placement?: TooltipPlacement;
  zIndex?: number;
};

const PlanyearPopover = ({
  children,
  content,
  width,
  visible,
  isDisabled,
  placement = 'top',
  zIndex,
}: Props) => {
  const getOverlayClassName = (placement: TooltipPlacement) => {
    let className = `${styles.popover} `;
    switch (placement) {
      case 'top':
      case 'topLeft':
      case 'topRight':
      case 'bottom':
      case 'bottomLeft':
      case 'bottomRight':
        className += `${styles.popoverTop} `;
        break;
      case 'left':
      case 'leftTop':
      case 'leftBottom':
        className += `${styles.popoverLeft} `;
        break;
      case 'right':
      case 'rightTop':
      case 'rightBottom':
        className += `${styles.popoverRight} `;
        break;
    }
    return className;
  };

  return (
    <Popover
      overlayInnerStyle={{
        width,
      }}
      zIndex={zIndex}
      visible={isDisabled === true ? false : visible}
      placement={placement}
      trigger={isDisabled ? [] : ['hover']}
      content={content}
      overlayClassName={getOverlayClassName(placement)}
    >
      {children}
    </Popover>
  );
};

export default PlanyearPopover;
