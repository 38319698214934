import { memo } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import AiAssistantChatModal from 'modules/aiAssistantChat/components/AiAssistantChatModal/AiAssistantChatModal';
import { WEB_SOCKET_URL } from 'modules/aiAssistantChat/services/AiAssistantServices';

const AiAssistantChat = memo(
  ({
    isChatOpen,
    setIsChatOpen,
  }: {
    isChatOpen: boolean;
    setIsChatOpen: (isOpen: boolean) => void;
  }) => {
    return (
      <>
        <StompSessionProvider
          url={WEB_SOCKET_URL}
          onWebSocketError={(e) => {
            console.error('WebSocket Error:', e);
          }}
          reconnectDelay={500}
        >
          <AiAssistantChatModal
            visible={isChatOpen}
            setIsChatOpen={setIsChatOpen}
          />
        </StompSessionProvider>
      </>
    );
  }
);

AiAssistantChat.displayName = 'AiAssistantChat';
export default AiAssistantChat;
