export const IN_NETWORK = { value: 'IN_NETWORK' };
export const OUT_NETWORK = { value: 'OUT_NETWORK' };
export const FREQUENCIES: { [key: string]: { value: string; label: string } } =
  {
    MONTHLY: { value: 'MONTHLY', label: 'Monthly' },
    SEMI_MONTHLY: { value: 'SEMI_MONTHLY', label: 'Semi-Monthly' },
    BI_WEEKLY: { value: 'BI_WEEKLY', label: 'Bi-Weekly' },
  };

export const BENEFIT_CATEGORY = {
  MEDICAL: {
    value: 'MEDICAL',
  },
};

export const N_TIER = 'N_TIER';
export const AGE_BAND = 'AGE_BAND';
export const AGE_BAND_LABEL = 'Age Banded';
export const FOUR_TIER = 'FOUR_TIER';

export const FOUR_TIER_COVERAGES = [
  {
    title: '4-Tier',
    name: '4-Tier',
    type: 'FOUR_TIER',
    items: [
      {
        value: 'Y0C',
        fourTierValue: 'EE',
        nTierValue: 'EE',
        label: 'Just You',
        coverageLabel: 'Covers you',
      },
      {
        value: 'Y1S0C',
        fourTierValue: 'ES',
        nTierValue: 'ES',
        label: 'You + Spouse',
        coverageLabel: 'Covers you + Spouse',
      },
      {
        value: 'Y1C',
        fourTierValue: 'EC',
        nTierValue: 'EC',
        label: 'You + Child(ren)',
        coverageLabel: 'Covers you + Child(ren)',
      },

      {
        value: 'Y1S1C',
        fourTierValue: 'EF',
        nTierValue: 'EF',
        label: 'You + Family',
        coverageLabel: 'Covers you + Family',
      },
    ],
  },
];

export const N_TIER_COVERAGES = [
  {
    title: 'No Spouse',
    name: 'No Spouse',
    items: [
      {
        value: 'Y0C',
        fourTierValue: 'EE',
        nTierValue: 'EE',
        label: 'Just You',
        coverageLabel: 'Covers you',
      },
      {
        value: 'Y1C',
        fourTierValue: 'EC',
        nTierValue: 'EE_PLUS_1',
        label: 'You + 1 Child',
        coverageLabel: 'Covers you + child',
      },
      {
        value: 'Y2C',
        fourTierValue: 'EC',
        nTierValue: 'EE_PLUS_2',
        label: 'You + 2 Children',
        coverageLabel: 'Covers you + children',
      },
      {
        value: 'Y3C',
        fourTierValue: 'EC',
        nTierValue: 'EE_PLUS_3',
        label: 'You + 3 Children',
        coverageLabel: 'Covers you + children',
      },
      {
        value: 'Y4C',
        fourTierValue: 'EC',
        nTierValue: 'EE_PLUS_4',
        label: 'You + 4 Children',
        coverageLabel: 'Covers you + children',
      },
      {
        value: 'Y5C',
        fourTierValue: 'EC',
        nTierValue: 'EE_PLUS_5',
        label: 'You + 5 Children',
        coverageLabel: 'Covers you + children',
      },
      {
        value: 'Y6C',
        fourTierValue: 'EC',
        nTierValue: 'EE_PLUS_6',
        label: 'You + 6 Children',
        coverageLabel: 'Covers you + children',
      },
    ],
  },
  {
    title: 'Spouse',
    name: 'Spouse',
    items: [
      {
        value: 'Y0S0C',
        fourTierValue: 'EE',
        nTierValue: 'EE',
        label: 'Just You',
        coverageLabel: 'Covers you',
      },
      {
        value: 'Y1S0C',
        fourTierValue: 'ES',
        nTierValue: 'EE_PLUS_1',
        label: 'You + Spouse',
        coverageLabel: 'Covers you + spouse',
      },
      {
        value: 'Y1S1C',
        fourTierValue: 'EF',
        nTierValue: 'EE_PLUS_2',
        label: 'You + Spouse + 1 Child',
        coverageLabel: 'Covers you + family',
      },
      {
        value: 'Y1S2C',
        fourTierValue: 'EF',
        nTierValue: 'EE_PLUS_3',
        label: 'You + Spouse  + 2 Children',
        coverageLabel: 'Covers you + family',
      },
      {
        value: 'Y1S3C',
        fourTierValue: 'EF',
        nTierValue: 'EE_PLUS_4',
        label: 'You + Spouse + 3 Children',
        coverageLabel: 'Covers you + family',
      },
      {
        value: 'Y1S4C',
        fourTierValue: 'EF',
        nTierValue: 'EE_PLUS_5',
        label: 'You + Spouse + 4 Children',
        coverageLabel: 'Covers you + family',
      },
      {
        value: 'Y1S5C',
        fourTierValue: 'EF',
        nTierValue: 'EE_PLUS_6',
        label: 'You + Spouse + 5 Children',
        coverageLabel: 'Covers you + family',
      },
      {
        value: 'Y1S6C',
        fourTierValue: 'EF',
        nTierValue: 'EE_PLUS_6',
        label: 'You + Spouse + 6 Children',
        coverageLabel: 'Covers you + family',
      },
    ],
  },
];

export const FREQUENCY_LABEL = {
  PER_DAY: 'per day',
  PER_VISIT: 'per visit',
  PER_SERVICE: 'per service',
  PER_ADMISSION: 'per admission',
  PER_TRIP: 'per trip',
  PER_WEEK: 'per week',
  PER_MONTH: 'per month',
  PER_YEAR: 'per year',
  PER_PROCEDURE: 'per procedure',
  PER_SCRIPT: 'per script',
  PER_ALLOWANCE: 'per allowance',
  NO_INFO: '',
  PER_ITEM: 'per item',
  EVERY_OTHER_YEAR: 'every other year',
};

export const NO_INFO = 'No Info';
export const NOT_COVERED_FORMATTED = 'Not Covered';
export const NOT_APPLICABLE_FORMATTED = 'Not Applicable';

export const SUPER_SCRIPTS = [
  {
    strValue: 'SUP-1',
    superScriptValue: 1,
    label: '1 After Deductible is Met ',
  },
  {
    strValue: 'SUP-2',
    superScriptValue: 2,
    label: '2 Before Deductible is Met ',
  },
];

export const COST_TYPE = {
  COINSURANCE: 'coinsurance',
  COPAY: 'copay',
  OTHER: 'other',
};

export const NETWORK_PREFIX = {
  IN: 'in',
  OUT_OF: 'outOf',
};

export const SERVICE_APPLICABLE = {
  COVERED: 'COVERED',
  NOT_COVERED: 'NOT_COVERED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const LIFE_PLAN_TYPES = {
  BASIC_LIFE: { value: 'BASIC_LIFE', label: 'Basic Life' },
  BASIC_ADD: { value: 'BASIC_ADD', label: 'Basic AD&D' },
  BASIC_LIFE_AND_ADD: {
    value: 'BASIC_LIFE_AND_ADD',
    label: 'Basic Life w/AD&D',
  },
  STD: { value: 'STD', label: 'Basic Short-Term Disability' },
  LTD: { value: 'LTD', label: 'Basic Long-Term Disability' },
};

export const VOLUNTARY_LIFE_PLAN_TYPES = {
  VOLUNTARY_LIFE: { value: 'VOLUNTARY_LIFE', label: 'Voluntary Life' },
  VOLUNTARY_ADD: { value: 'VOLUNTARY_ADD', label: 'Voluntary AD&D' },
  VOLUNTARY_LIFE_ADD: {
    value: 'VOLUNTARY_LIFE_ADD',
    label: 'Voluntary Life w/AD&D',
  },
  VOLUNTARY_STD: {
    value: 'VOLUNTARY_STD',
    label: 'Voluntary Short-Term Disability',
  },
  VOLUNTARY_LTD: {
    value: 'VOLUNTARY_LTD',
    label: 'Voluntary Long-Term Disability',
  },
};

export const LIFE_AND_DISABILITY_CARD_ORDER = [
  LIFE_PLAN_TYPES.BASIC_LIFE.value,
  LIFE_PLAN_TYPES.BASIC_ADD.value,
  LIFE_PLAN_TYPES.BASIC_LIFE_AND_ADD.value,
  LIFE_PLAN_TYPES.STD.value,
  VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_STD.value,
  LIFE_PLAN_TYPES.LTD.value,
  VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LTD.value,
  VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE.value,
  VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_ADD.value,
  VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE_ADD.value,
];

export const LIFE_BENEFIT_OPTIONS = {
  FLAT_AMOUNT: 'FLAT_AMOUNT',
  MULTIPLIER_OF_SALARY: 'MULTIPLIER_OF_SALARY',
  MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT: 'MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT',
};

export const PLAN_TYPE_PPO = 'PPO';
export const PLAN_TYPE_HMO = 'HMO';
export const PLAN_TYPE_EPO = 'EPO';
export const PLAN_TYPE_POS = 'POS';
export const PLAN_TYPE_OAP = 'OAP';
export const PLAN_TYPE_HSA = 'HSA';

export const addPlansTooltipContent = `You may only add plans that have the same plan year and benefit classes as this guide. If there are plans that you expected to be available for this guide, please confirm that the plans and guide share at least one benefit class and that their plan years match.`;

export const NDASH = '–';

export const RX_TIER_LABELS = [
  { value: 'TIER_1', label: 'Tier 1 Retail Rx' },
  { value: 'TIER_2', label: 'Tier 2 Retail Rx' },
  { value: 'TIER_3', label: 'Tier 3 Retail Rx' },
  { value: 'TIER_4', label: 'Tier 4 Retail Rx' },
  { value: 'TIER_5', label: 'Tier 5 Retail Rx' },
  { value: 'TIER_6', label: 'Tier 6 Retail Rx' },
  { value: 'TIER_7', label: 'Tier 7 Retail Rx' },
  { value: 'TIER_8', label: 'Tier 8 Retail Rx' },
];

export const MAIL_ORDER_RX_TIER_LABELS = [
  { value: 'TIER_1', label: 'Tier 1 Mail Order Rx' },
  { value: 'TIER_2', label: 'Tier 2 Mail Order Rx' },
  { value: 'TIER_3', label: 'Tier 3 Mail Order Rx' },
  { value: 'TIER_4', label: 'Tier 4 Mail Order Rx' },
  { value: 'TIER_5', label: 'Tier 5 Mail Order Rx' },
  { value: 'TIER_6', label: 'Tier 6 Mail Order Rx' },
  { value: 'TIER_7', label: 'Tier 7 Mail Order Rx' },
  { value: 'TIER_8', label: 'Tier 8 Mail Order Rx' },
];

export const MOBILE_DEDUCTIBLE_LABELS = [
  { value: 'outOfPocket', label: 'Out-of-pocket Max' },
  { value: 'deductibles', label: 'Deductible (Ind/Fam)' },
  { value: 'calendarYearMax', label: 'Plan Year Max' },
  { value: 'orthodontiaLifetimeMax', label: 'Orthodontia Lifetime Max' },
];

export const MOBILE_IND_DEDUCTIBLE_LABELS = [
  { value: 'indWithinFamilyOOPMax', label: 'Ind. within Fam OOP Max' },
  { value: 'indWithinFamilyDeductible', label: 'Ind. within Fam Deductible' },
];

export const MOBILE_RX_DEDUCTIBLE_OOP_LABELS = [
  { value: 'rxDeductibles', label: 'Rx Deductible (Ind/Fam)' },
  { value: 'rxOOP', label: 'Rx Out-of-Pocket Max (Ind/Fam)' },
];

export const PLAN_DESCRIPTION_WORD_LIMIT = 2500;
