import { baseApi } from 'utils/apiUtil';
import { http } from 'utils/httpUtil';

const benguideApiV2 = `${baseApi}/v2/benefit-guides`;

export const getPlanRecommenderEnableOrDisable = (benguideId: string) => {
  return http.get(
    `${benguideApiV2}/${benguideId}/plan-recommender/verify-enable-or-not`
  );
};
