import React from 'react';

// define manually if keys aren't mapping
const manualShortKeyMap = {
  'Emergency Room Care': 'Emergency Room',
  'Emergency Room': 'Emergency Room',
  'Outpatient Mental Health': 'Outpatient',
  'Tier 1 Mail Order Rx': 'Tier 1 Mail Order',
  'Tier 2 Mail Order Rx': 'Tier 2 Mail Order',
  'Tier 3 Mail Order Rx': 'Tier 3 Mail Order',
  'Tier 4 Mail Order Rx': 'Tier 4 Mail Order',
  'Tier 5 Mail Order Rx': 'Tier 5 Mail Order',
  'Tier 6 Mail Order Rx': 'Tier 6 Mail Order',
  'Tier 7 Mail Order Rx': 'Tier 7 Mail Order',
  'Tier 8 Mail Order Rx': 'Tier 8 Mail Order',
} as { [shortName: string]: string };

export const getLabelsSuperscript = (
  planLabels: any[],
  shortName: string,
  networkType: 'IN' | 'OUT'
) => {
  const assignedKey = manualShortKeyMap[shortName] || null;

  const parameter = planLabels?.find(
    (label) =>
      (label?.shortName?.trim() === shortName?.trim() ||
        (assignedKey && label?.shortName?.trim() === assignedKey)) &&
      label?.network === networkType
  );

  return parameter?.labelVOs?.map((label: any) => label?.superscript) || [];
};

export const getLabelsSuperscriptWithKey = (
  planLabels: any[],
  key: string,
  networkType: 'IN' | 'OUT'
): string => {
  const parameter = planLabels?.find(
    (label) => label?.key === key && label?.network === networkType
  );

  const superscripts =
    parameter?.labelVOs?.map((label: any) => label?.superscript) || [];

  // Return joined superscripts or an empty string if no superscripts are found
  return superscripts?.length > 0 ? superscripts.join(', ') : '';
};

export const getSupTextFromReactNode = (node: React.ReactNode): string => {
  if (typeof node === 'string' || typeof node === 'number') {
    return '';
  }

  if (React.isValidElement(node)) {
    if (node.type === 'sup') {
      const supText = node.props.children || '';
      return supText ? `(${supText})` : '';
    }

    if (node.props && node.props.children) {
      if (Array.isArray(node.props.children)) {
        for (const child of node.props.children) {
          const supText = getSupTextFromReactNode(child);
          if (supText) return supText;
        }
      } else {
        return getSupTextFromReactNode(node.props.children);
      }
    }
  }

  return '';
};
