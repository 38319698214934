import React, { FC } from 'react';

type PlanRecommenderStepProps = {
  step: number;
  currentStep: number;
  children: any;
};

const PlanRecommenderStep: FC<PlanRecommenderStepProps> = (
  props: PlanRecommenderStepProps
) => {
  const { children, step, currentStep } = props;
  if (step === currentStep) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return <></>;
  }
};

export default PlanRecommenderStep;
