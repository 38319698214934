import { FC } from 'react';
import { Radio, RadioProps } from 'antd';

import styles from './radioButton.module.less';

const RadioButton: FC<RadioProps> = (props: RadioProps) => {
  const { ...rest } = props;
  return (
    <div className={styles.radioWrapper}>
      <Radio {...rest} />
    </div>
  );
};

export default RadioButton;
