import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

import SectionApiRequestPayload from 'model/SectionApiRequestPayload';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import Section from 'model/Section';
import {
  setCloseModalsInUP,
  updateBenguideLatestRevision,
} from 'modules/auth/slices/benguideSlice';
import * as worklifeService from 'services/WorkLifeService';
import * as benguideService from 'services/BenGuideService';
import * as planService from 'modules/auth/services/PlanService';
import { SectionName } from 'modules/home/constants';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { convertEncodedStringToBlob } from 'utils/fileUtil';
import PageSection from 'model/PageSection';

const initialState = {
  planList: {
    inProgress: false,
    data: null,
    error: null,
  },
  holidayAndTimeOffSection: {
    inProgress: false,
    enable: false,
    plans: [],
    error: null,
  },
  richMedia: {},
  sectionImage: {
    inProgress: false,
    image: '',
    error: null,
  },
  latestRevision: 0,
  defaultRichMedia: {
    inProgress: false,
    error: null,
    data: {
      [SectionName.HOLIDAY]: '',
      [SectionName.FLEXIBLE_WORKING_HOURS]: '',
      [SectionName.OTHER_TIME_OFF]: '',
      [SectionName.PAID_TIME_OFF]: '',
      [SectionName.SICK]: '',
    },
  },
  sectionImageOriginal: '',
  sections: [] as PageSection[],
};

const holidayAndTimeOffSlice = createSlice({
  name: 'holidayAndTimeOff',
  initialState,
  reducers: {
    planListFetchingStarted: (state) => {
      state.planList.inProgress = true;
    },
    planListFetchingCompleted: (state, { payload }) => {
      state.planList.data = payload;
      state.planList.error = null;
      state.planList.inProgress = false;
    },
    planListFetchingFailed: (state, { payload }) => {
      state.planList.inProgress = false;
      state.planList.error = payload;
    },
    holidaySectionUpdateStart: (state) => {
      state.holidayAndTimeOffSection.inProgress = true;
    },
    holidaySectionUpdateCompleted: (state, { payload }) => {
      state.holidayAndTimeOffSection.inProgress = false;
      state.holidayAndTimeOffSection.enable = payload.enable;
      state.holidayAndTimeOffSection.plans = payload.plans;
      state.holidayAndTimeOffSection.error = null;
    },
    holidaySectionUpdateFailed: (state, { payload }) => {
      state.holidayAndTimeOffSection.inProgress = false;
      state.holidayAndTimeOffSection.error = payload;
    },
    richMediaFetchingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingStarted: (state, { payload }) => {
      set(state, `richMedia.${payload}.inProgress`, true);
    },
    richMediaUpdatingCompleted: (state, { payload }) => {
      const { planId, content } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, content);
      set(state, `richMedia.${planId}.error`, null);
    },
    richMediaUpdatingFailed: (state, { payload }) => {
      const { planId, error } = payload;
      set(state, `richMedia.${planId}.inProgress`, false);
      set(state, `richMedia.${planId}.content`, '');
      set(state, `richMedia.${planId}.error`, error);
    },
    uploadSectionImageStart(
      state,
      action: PayloadAction<{ image: string; original?: string }>
    ) {
      state.sectionImage.image = action.payload.image;
      state.sectionImage.error = null;
      state.sectionImage.inProgress = true;
      if (action.payload.original) {
        state.sectionImageOriginal = action.payload.original;
      }
    },
    uploadSectionImageSuccess(state, { payload }) {
      state.sectionImage.error = null;
      state.sectionImage.image = payload.imageReference;
      state.sectionImage.inProgress = false;
      state.latestRevision = payload.latestRevision;
    },
    uploadSectionImageFailed(state, { payload }) {
      state.sectionImage.error = payload;
      state.sectionImage.image = '';
      state.sectionImage.inProgress = false;
    },
    deleteSectionImageStart(state) {
      state.sectionImage.inProgress = true;
      state.sectionImage.error = null;
    },
    deleteSectionImageSuccess(state, { payload }) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = null;
      state.latestRevision = payload.latestRevision;
    },
    deleteSectionImagaFailed(state, { payload }) {
      state.sectionImage.inProgress = false;
      state.sectionImage.error = payload;
    },
    getDefaultMediaStarted(state) {
      state.defaultRichMedia.inProgress = true;
    },
    getDefaultMediaSuccess(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      (state.defaultRichMedia.data as any)[payload.section] =
        payload.media.content;
    },
    getDefaultMediaFailed(state, { payload }) {
      state.defaultRichMedia.inProgress = false;
      state.defaultRichMedia.error = payload;
    },
    setSections(state, { payload }) {
      state.sections = payload;
    },
  },
});

export const {
  planListFetchingCompleted,
  planListFetchingFailed,
  planListFetchingStarted,
  holidaySectionUpdateStart,
  holidaySectionUpdateCompleted,
  holidaySectionUpdateFailed,
  richMediaFetchingStarted,
  richMediaUpdatingCompleted,
  richMediaUpdatingFailed,
  richMediaUpdatingStarted,
  deleteSectionImagaFailed,
  deleteSectionImageStart,
  deleteSectionImageSuccess,
  uploadSectionImageFailed,
  uploadSectionImageStart,
  uploadSectionImageSuccess,
  getDefaultMediaStarted,
  getDefaultMediaSuccess,
  getDefaultMediaFailed,
  setSections,
} = holidayAndTimeOffSlice.actions;

export const fetchHolidayAndTimeOffPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planListFetchingStarted());
    worklifeService
      .getPlanList(employerId, planYearId, benefitClasses)
      .then((response) => {
        const { data } = response || {};
        dispatch(planListFetchingCompleted(data));
      })
      .catch((error) => {
        dispatch(planListFetchingFailed(error));
      });
  };
};

export const updateHolidayAndTimeOffSection: any = (section: Section) => {
  return async (dispatch: Dispatch) => {
    dispatch(setSections(section.sections));
    const planPromisses = section.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return planService.getPlans('work-life-plans', planId || id, revision);
    });
    const plans = (await Promise.all(planPromisses)).map(
      (plan: any) => plan.data as Section
    );
    dispatch(holidaySectionUpdateCompleted({ ...section, plans }));
    dispatch(setCloseModalsInUP(true));
  };
};

export const updatedPlanList = (
  benguideId: string,
  request: SectionApiRequestPayload
) => {
  return async (dispatch: Dispatch) => {
    benguideService
      .updateBenefitSection(benguideId, request)
      .then(({ data }) => {
        const { benefitPage } = data || {};
        dispatch(updateHolidayAndTimeOffSection(benefitPage));
      })
      .catch((error) => {
        dispatch(holidaySectionUpdateFailed(error));
      });
  };
};

export const addMultiplePlans = (
  benguideId: string,
  request: Array<AddPlanApiRequestPayload>
) => {
  return async (dispatch: Dispatch) => {
    dispatch(holidaySectionUpdateStart());
    benguideService
      .addMultiplePlans(benguideId, { newPlans: request })
      .then(({ data }) => {
        dispatch(
          reloadBenefitAndTimeOffSection(benguideId, data.latestRevision)
        );
      })
      .catch((error) => {
        dispatch(holidaySectionUpdateFailed(error));
      });
  };
};

export const reloadBenefitAndTimeOffSection: any = (
  benguideId: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await benguideService.getHolidayAndTimeOffSection(
      benguideId,
      revision
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(updateHolidayAndTimeOffSection(sectionResponse.benefitPage));
  };
};

export const getRichMediaContent = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaFetchingStarted(planId));
    benguideService
      .getRichMediaContent(benguideId, benefitKind, planId)
      .then(({ data }) => {
        const { content } = data;
        dispatch(
          richMediaUpdatingCompleted({ planId: planId, content: content })
        );
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId: planId, error: error }));
      });
  };
};

export const updateRichMediaContent = (
  benguideId: string,
  planId: string,
  content: string,
  benefitKind: string,
  callBack: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(richMediaUpdatingStarted(planId));
    benguideService
      .updateRichMediaContent(benguideId, benefitKind, planId, content)
      .then(() => {
        callBack && callBack();
        dispatch(
          richMediaUpdatingCompleted({ planId: planId, content: content })
        );
      })
      .catch((error) => {
        dispatch(richMediaUpdatingFailed({ planId: planId, error: error }));
      });
  };
};

export const handleSectionImageUpload =
  (
    benguideId: string,
    image: string,
    sectionName: SectionName,
    originalImage?: string,
    cropArea?: OriginalImageCropParams,
    originalRef?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(uploadSectionImageStart({ image, original: originalImage }));
    try {
      let updateOriginalRef = originalRef;

      if (originalImage) {
        const originalImgBlob = await convertEncodedStringToBlob(originalImage);
        const originalImgRes = await benguideService.uploadSectionImage(
          benguideId,
          originalImgBlob,
          sectionName,
          true
        );
        updateOriginalRef = originalImgRes.data.imageReference;
      }
      const imageBlob = await convertEncodedStringToBlob(image);
      const response = await benguideService.uploadSectionImage(
        benguideId,
        imageBlob,
        sectionName,
        false,
        updateOriginalRef,
        cropArea
      );
      dispatch(reloadBenefitAndTimeOffSection(benguideId));
      dispatch(uploadSectionImageSuccess(response.data));
      dispatch(updateBenguideLatestRevision(response.data.latestRevision));
    } catch (error) {
      dispatch(uploadSectionImageFailed(error));
    }
  };

export const handleSectionImageDelete =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(deleteSectionImageStart());
    try {
      const response = await benguideService.deleteSectionImage(
        benguideId,
        sectionName
      );
      dispatch(deleteSectionImageSuccess(response.data.latestRevision));
    } catch (error) {
      dispatch(deleteSectionImagaFailed(error));
    }
  };

export const getDefaultText = (
  benguideId: string,
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    let sectionName = SectionName.HOLIDAY;
    if (benefitKind === benefitCategory.FLEXIBLE_WORKING_HOURS.value) {
      sectionName = SectionName.FLEXIBLE_WORKING_HOURS;
    } else if (benefitKind === benefitCategory.OTHER_TIME_OFF.value) {
      sectionName = SectionName.OTHER_TIME_OFF;
    } else if (benefitKind === benefitCategory.PAID_TIME_OFF.value) {
      sectionName = SectionName.PAID_TIME_OFF;
    } else if (benefitKind === benefitCategory.SICK.value) {
      sectionName = SectionName.SICK;
    }
    benguideService
      .getRichMediaContentForPlan(benguideId, sectionName, planId, true)
      .then(({ data }) => {
        dispatch(
          getDefaultMediaSuccess({
            section: sectionName,
            media: data,
          })
        );
      })
      .catch((error) => {
        dispatch(getDefaultMediaFailed(error));
      });
  };
};

export default holidayAndTimeOffSlice.reducer;
