import { FC, useContext, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';

import ErrorBoundary from 'modules/auth/pages/ErrorBoundary/ErrorBoundary';
import AppLayout from 'layout/AppLayout';
import {
  initBenguideDataByHash,
  initBenguideDataByToken,
} from 'modules/auth/slices/authSlice';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { NavigationContext } from 'context/NavigationContext';
import {
  ADDITIONAL_CONTENT_OPTIONS,
  BENEFIT_TYPES,
} from 'modules/home/constants';
import ContentPage from 'model/CotentPage';
import useBenguide from 'hooks/benguide';
import Login from 'modules/auth/pages/Login/Login';
import FullScreenLoader from 'components/FullScreenLoader/FullScreenLoader';
import { isInIframe, http as axios } from 'utils/httpUtil';
import { getBenConsultation } from 'modules/benefitsWellness/slices/benefitsConsultationSlice';

type PrivateRouteProps = {
  layout?: any;
};

const PrivateRoute: FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const { auth } = useAppSelector((state) => state);
  const benGuideLoading = useAppSelector((state) => state.benguide.inProgress);
  const benefitGuide = useAppSelector(
    (state) => state.basicInfo.layoutBenefitGuide
  );
  const dispatch = useAppDispatch();
  const { hash = '', employerName = '' } = useParams();
  const search = useLocation().search;
  const previewToken = new URLSearchParams(search).get('previewToken');

  const { setDisabledRoutes } = useContext(NavigationContext);
  const benguide = useBenguide();
  const { inProgress, benguideError } = auth;
  const { authFailed, notPublishedBenguide, notFound, invalidated } =
    benguideError || {};

  useEffect(() => {
    axios.defaults.withCredentials = true;
    if (!benguide.id) {
      if (previewToken) {
        dispatch(initBenguideDataByToken(previewToken, hash));
      } else {
        dispatch(initBenguideDataByHash(hash, isInIframe()));
      }
    }
  }, [dispatch, hash, previewToken, benguide.id]);

  useEffect(() => {
    benguide.masterId && dispatch(getBenConsultation(benguide.masterId));
  }, [benguide.masterId, dispatch]);

  // checks benefit guide object for disabled sections and disables related route
  useEffect(() => {
    if (benefitGuide) {
      const disabledBenefitRoutes = Object.entries(BENEFIT_TYPES)
        .filter(([key]) => {
          const contentPage: ContentPage = (benefitGuide as any)[key];
          return contentPage && !contentPage.enabled;
        })
        .map(([, value]) => {
          return value.route;
        });
      const disabledContentRoutes = Object.entries(ADDITIONAL_CONTENT_OPTIONS)
        .filter(([key]) => {
          const contentPage: ContentPage = (benefitGuide as any)[key];
          return contentPage && !contentPage.enabled;
        })
        .map(([, value]) => {
          return value.route;
        });
      setDisabledRoutes([...disabledBenefitRoutes, ...disabledContentRoutes]);
    }
  }, [benefitGuide, setDisabledRoutes]);

  if (inProgress || benGuideLoading || (!authFailed && !benguide.id)) {
    return <FullScreenLoader />;
  }

  if (notPublishedBenguide) {
    return (
      <ErrorBoundary
        lineOne={'This benefits guide is currently undergoing updates.'}
        lineTwo={
          'Please check back later or contact your system administrator.'
        }
      />
    );
  }

  if (notFound) {
    return (
      <ErrorBoundary
        lineOne={'This benefits guide is no longer available.'}
        lineTwo={'Please contact your system administrator.'}
      />
    );
  }

  if (invalidated) {
    return (
      <ErrorBoundary
        lineOne={
          'You dont have access to this, please reach out to your Broker.'
        }
      />
    );
  }

  if (authFailed) {
    return <Login />;
  }

  if (
    benefitGuide?.status === 'PUBLISHED' &&
    employerName !== 'plan-recommender' &&
    ((benefitGuide?.employerName &&
      (employerName === '' || employerName !== benefitGuide.employerName)) ||
      (!benefitGuide?.employerName && employerName !== ''))
  ) {
    return (
      <ErrorBoundary
        lineOne={'This benefit guide is no longer available.'}
        lineTwo={'Please contact your system administrator.'}
      />
    );
  }

  return (
    <AppLayout layout={props.layout}>
      <Outlet />
    </AppLayout>
  );
};

export default PrivateRoute;
