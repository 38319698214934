import { FC } from 'react';

import iconWarning from 'images/icons/icon-warning-yellow.svg';

import styles from './missingInfo.module.less';

type MissingInfoProps = {
  missingInfoText: string;
};

const MissingInfo: FC<MissingInfoProps> = (props: MissingInfoProps) => {
  const { missingInfoText } = props;
  return (
    <div className={`${styles.missingOuterWrapper} missingOuterWrapper`}>
      <div className={`${styles.missingInfoWrapper} missingInfoWrapper`}>
        <div className={`${styles.missingTextWrapper} missingTextWrapper`}>
          <img
            src={iconWarning}
            alt="warning-icon"
            className={`${styles.iconWarning} iconWarning`}
          />
          <span className={`${styles.missingText} missingText`}>
            {missingInfoText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MissingInfo;
