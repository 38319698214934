import { ReactNode } from 'react';
import { isNull } from 'lodash';
import { Modal, ModalProps } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import { AUTH_VIEW, PLAN_VIEW } from 'modules/auth/constants/authConstants';
import { AuthErrorCodes } from 'modules/auth/constants/errorCodes';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { setAuthView } from 'modules/auth/slices/employeeAuthSlice';
import AuthHeading from './AuthHeading/AuthHeading';

import styles from './authModal.module.less';

interface Props extends ModalProps {
  visible: boolean;
  children: ReactNode;
  errorMessage?: ReactNode;
  handleClose: () => void;
}

const AuthModal = (props: Props) => {
  const { visible, children, handleClose, ...rest } = props;
  const dispatch = useAppDispatch();
  const alert = useAppSelector((state) => state.employeeAuth.errorAlert);
  const isLoading = useAppSelector((state) => state.employeeAuth.inProgress);
  const isEmployeeLoggedIn = useAppSelector((state) => state.idCard.isLoggedIn);
  const election = useAppSelector((state) => state.idCard.electionData) || {};
  const authView = useAppSelector((state) => state.employeeAuth.view);
  const onboadingView = useAppSelector((state) => state.idCard.view);
  const showAlert = useAppSelector(
    (state) => state.employeeAuth.showErrorAlert
  );

  const getAuthHeader = (type: string) => {
    switch (type) {
      case AUTH_VIEW.LOGIN.type:
        return AUTH_VIEW.LOGIN.value;
      case AUTH_VIEW.REQUEST_RESET.type:
        return AUTH_VIEW.REQUEST_RESET.value;
      case AUTH_VIEW.PWD_RESET.type:
        return AUTH_VIEW.PWD_RESET.value;
      case AUTH_VIEW.FALL_BACK.type:
        return AUTH_VIEW.FALL_BACK.value;
      case AUTH_VIEW.VERIFY.type:
        return AUTH_VIEW.VERIFY.value;
      case AUTH_VIEW.ER_ADMIN.type:
        return AUTH_VIEW.ER_ADMIN.value;
      case AUTH_VIEW.TERMS.type:
        return AUTH_VIEW.TERMS.value;
      default:
        return AUTH_VIEW.REGISTER.value;
    }
  };

  const getOnboardingHeading = (type: string) => {
    switch (type) {
      case PLAN_VIEW.ON_BOARDING.type:
        return election.hasDBGPlans
          ? PLAN_VIEW.ON_BOARDING.title
          : PLAN_VIEW.ON_BOARDING.fallBackTitle;
      case PLAN_VIEW.MEDICAL.type:
        return PLAN_VIEW.MEDICAL.title;
      case PLAN_VIEW.DENTAL.type:
        return PLAN_VIEW.DENTAL.title;
      case PLAN_VIEW.VISION.type:
        return PLAN_VIEW.VISION.title;
      default:
        break;
    }
  };

  const renderAuth = () => {
    if (isEmployeeLoggedIn && !isNull(election)) {
      return (
        <div className={styles.authWrapper}>
          <div
            className={`${styles.altertWrapper} ${
              onboadingView === PLAN_VIEW.CARD.type && styles.idCardAlertWrapper
            }`}
          >
            {showAlert && (
              <FixedAlertMessage type={alert.type} message={alert.message} />
            )}
          </div>
          <div
            className={` ${styles.commonContainer} ${
              onboadingView === PLAN_VIEW.CARD.type && styles.cardViewPadding
            }`}
          >
            <h1 className={styles.heading}>
              {getOnboardingHeading(onboadingView)}
            </h1>
            {onboadingView === PLAN_VIEW.ON_BOARDING.type && (
              <>
                {election.hasDBGPlans ? (
                  <p>{PLAN_VIEW.ON_BOARDING.descripion}</p>
                ) : (
                  <p>{PLAN_VIEW.ON_BOARDING.fallBackDescription}</p>
                )}
              </>
            )}
            {children}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.authWrapper}>
          <div className={styles.altertWrapper}>
            {showAlert && (
              <FixedAlertMessage
                type={alert.type}
                message={
                  <>
                    {alert.message}{' '}
                    {alert.message ===
                      AuthErrorCodes.EMPLOYEE_NOT_FOUND.message && (
                      <LinkButton
                        className={styles.linkBtn}
                        onClick={() =>
                          dispatch(setAuthView(AUTH_VIEW.REGISTER))
                        }
                      >
                        Click Here.
                      </LinkButton>
                    )}
                  </>
                }
              />
            )}
          </div>
          <div className={styles.authContainer}>
            <AuthHeading header={getAuthHeader(authView!)} /> {children}
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      width={700}
      maskClosable={false}
      closable={isLoading ? false : true}
      wrapClassName={styles.modalMain}
      visible={visible}
      onCancel={handleClose}
      {...rest}
    >
      {renderAuth()}
    </Modal>
  );
};

export default AuthModal;
