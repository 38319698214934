import RouteMap from 'routes/RouteMap';

export type ToolbarItem = {
  path: RouteMap | ToolbarSubmenu;
  label: string;
  children?: ToolbarItem[];
  planPath?: string;
};

export enum ToolbarSubmenu {
  TAX_ADVANTAGED = 'tax-advantaged',
}

export const toolbarRoutes: ToolbarItem[] = [
  {
    path: RouteMap.BASIC_INFO_EDIT_PATH,
    label: 'Basic Guide Info',
  },
  { path: RouteMap.HOME_PATH, label: 'Home Screen' },
  {
    path: RouteMap.NEW_HIRE_CHECKLIST,
    label: 'New Hire Checklist',
  },
  {
    path: RouteMap.HOW_INSURANCE_WORKS,
    label: 'How Insurance Works',
  },
  {
    path: RouteMap.DATES_AND_ELIGIBILITY,
    label: 'Dates & Eligibility',
  },
  {
    path: RouteMap.MEDICAL,
    label: 'Medical',
    planPath: 'medical.medicalSection.plans',
  },
  {
    path: RouteMap.TELEHEALTH,
    label: 'Telehealth/Rx Delivery',
    planPath: 'telehealth.telehealthSection.plans',
  },
  {
    path: RouteMap.DENTAL,
    label: 'Dental',
    planPath: 'dental.dentalSection.plans',
  },
  {
    path: RouteMap.VISION,
    label: 'Vision',
    planPath: 'vision.visionSection.plans',
  },
  {
    path: RouteMap.LIFE_AND_DISABILITY,
    label: 'Life & Disability',
    planPath: 'lifeAndDisability.lifeAndDisabilitySection.plans',
  },
  {
    path: RouteMap.VOLUNTARY,
    label: 'Voluntary Benefits',
    planPath: 'voluntaryBenefits.volBenefitsSection.plans',
  },
  {
    path: RouteMap.WELLBEING,
    label: 'Wellbeing',
    planPath: 'wellbeing.wellbeingSection.plans',
  },
  {
    path: ToolbarSubmenu.TAX_ADVANTAGED,
    label: 'Tax Advantaged Accounts',
    children: [
      { path: RouteMap.HRA, label: 'HRA', planPath: 'hra.hraSection.plans' },
      { path: RouteMap.FSA, label: 'FSA', planPath: 'fsa.fsaSection.plans' },
      { path: RouteMap.HSA, label: 'HSA', planPath: 'hsa.hsaSection.plans' },
      {
        path: RouteMap.COMMUTER,
        label: 'Commuter',
        planPath: 'commuter.commuterSection.plans',
      },
    ],
  },
  {
    path: RouteMap.FOUR_O_ONE_K,
    label: '401(k)',
    planPath: 'fourOOneK.fourOOneKSection.plans',
  },
  {
    path: RouteMap.HOLIDAYS_TIME_OFF,
    label: 'Holidays & Time Off',
    planPath: 'holidayAndTimeOff.holidayAndTimeOffSection.plans',
  },
  {
    path: RouteMap.FAMILY_AND_LEAVE,
    label: 'Family & Leave',
    planPath: 'familyAndLeave.familyAndLeaveSection.plans',
  },
  {
    path: RouteMap.ADDITIONAL_PERKS,
    label: 'Additional Perks',
    planPath: 'additionalPerks.additionalPerksSection.plans',
  },
  {
    path: RouteMap.RESOURCES,
    label: 'Resources',
  },
];
