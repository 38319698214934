import { useState, useEffect } from 'react';
import { Col, Divider, Input, Row, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { isNull } from 'lodash';
import { updateMemberId } from 'modules/auth/slices/idCardSlice';
import { useAppSelector } from 'hooks/redux';
import OverflowPopover from 'components/PlanYearOverFlowPopover/OverflowPopover';
import { CoverageDetails, IndividualName } from 'modules/auth/types/types';
import { formatEffectiveDates } from 'utils/commonUtil';
import { PLAN_VIEW } from 'modules/auth/constants/authConstants';
import EditIcon from 'images/icon-login.svg';

import styles from './idCard.module.less';

export type IDDataType = {
  id: string;
  benefitKind: string;
  planId: string;
  planName: string;
  individualId: string;
  subscriberId: string;
  employerId: string;
  individualName: IndividualName;
  subscriberName: IndividualName;
  dob: string | null;
  coverageDetails: CoverageDetails;
  tier: string | null;
  planYearId: string;
};

type IDType = {
  isFlipped: boolean;
  data: IDDataType;
  employerData: any;
  isWaived: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  hasPlan: boolean;
  view: string;
  isDependent: boolean;
};

type Edit = {
  editModeOn: boolean;
  editValue: string;
};

const IDCard = ({
  isFlipped,
  data,
  employerData,
  isWaived,
  isLoading,
  isEmpty,
  hasPlan = true,
  view,
  isDependent = false,
}: IDType) => {
  const currentPlanYear = useAppSelector(
    (state) => state.idCard.currentPlanYear
  );

  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const employee = useAppSelector((state) => state.idCard.currentEmployee);
  const dispatch = useDispatch();
  const [editMemberId, setSetEditMemberId] = useState<Edit>({
    editModeOn: false,
    editValue: data?.coverageDetails?.memberIdNumber || '',
  });

  const getPlanYearDates = formatEffectiveDates(
    currentPlanYear?.startDate,
    currentPlanYear?.endDate
  );

  const getPlanViewTitle = (key: string) => {
    if (PLAN_VIEW[key]) {
      return PLAN_VIEW[key].value;
    }
    return '';
  };

  const onEditClick = () => {
    setSetEditMemberId({
      editValue: data?.coverageDetails?.memberIdNumber || '',
      editModeOn: true,
    });
  };

  const handleOnEditSave = () => {
    if (!isLoading && !isEmpty) {
      dispatch(
        updateMemberId(
          employee?.individualId,
          benguideData?.planYearId,
          data.benefitKind,
          editMemberId.editValue
        )
      );
    }

    setSetEditMemberId({
      ...editMemberId,
      editModeOn: false,
    });
  };

  useEffect(() => {
    if (editMemberId.editModeOn) {
      setSetEditMemberId({
        ...editMemberId,
        editModeOn: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlipped, view, data]);

  return (
    <div className={`${styles.flipCard} ${isFlipped ? styles.flipped : ''}`}>
      {isEmpty || isLoading ? (
        <div className={styles.waivedContainer}>
          <Skeleton paragraph={{ rows: 4 }} />
        </div>
      ) : isWaived ? (
        <div className={styles.waivedContainer}>
          You have waived plans for this benefit
        </div>
      ) : !hasPlan ? (
        <div className={styles.waivedContainer}>
          {`${getPlanViewTitle(view)} Benefits are not been offered currently`}
        </div>
      ) : (
        <div className={styles.flipCardInner}>
          <div className={styles.flipCardFront}>
            <div className={styles.cardTopContainer}>
              <Row justify="space-between">
                <Col>
                  {data?.coverageDetails?.logoUrl && (
                    <img
                      src={data?.coverageDetails?.logoUrl}
                      className={styles.employerLogo}
                      alt="employer"
                    />
                  )}
                </Col>
                <Col>
                  {employerData?.logoUrl && (
                    <img
                      src={employerData?.logoUrl}
                      className={styles.employerLogo}
                      alt="client"
                    />
                  )}
                </Col>
              </Row>
              <h1 className={styles.heading}>
                <OverflowPopover maxWidth="490px">
                  {data?.planName}
                </OverflowPopover>
              </h1>

              <p className={styles.subHeading}>
                <b> Effective Date : {`${getPlanYearDates?.startDate}`} </b>
              </p>
            </div>
            <div className={styles.cardBottomContainer}>
              <Row gutter={24}>
                <Col span={12} className={styles.labelText}>
                  <b>Name</b>
                </Col>
                <Col span={12}>
                  <OverflowPopover maxWidth="150px">
                    {data?.individualName?.firstName &&
                    data?.individualName?.lastName ? (
                      <>
                        {`${data?.individualName?.firstName} ${data?.individualName?.lastName}`}
                      </>
                    ) : (
                      '-'
                    )}
                  </OverflowPopover>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} className={styles.labelText}>
                  <b>Primary Subscriber</b>
                </Col>
                <Col span={12}>
                  <OverflowPopover maxWidth="150px">
                    {data?.subscriberName?.firstName &&
                    data?.subscriberName?.lastName ? (
                      <>
                        {`${data?.subscriberName?.firstName} ${data?.subscriberName?.lastName}`}
                      </>
                    ) : (
                      '-'
                    )}
                  </OverflowPopover>
                </Col>
              </Row>
              <Divider className={styles.containerDivider} />
              <Row gutter={32}>
                <Col span={12}>
                  <Row gutter={4}>
                    <Col span={9} className={styles.labelText}>
                      <b>ID #</b>
                    </Col>
                    <Col span={15}>
                      {!isDependent ? (
                        <div className={styles.editcontainer}>
                          <>
                            {editMemberId.editModeOn ? (
                              <Input
                                className={styles.editInput}
                                type="input"
                                name="memberId"
                                value={editMemberId.editValue}
                                onChange={(e) =>
                                  setSetEditMemberId({
                                    ...editMemberId,
                                    editValue: e.currentTarget.value,
                                  })
                                }
                              />
                            ) : (
                              <OverflowPopover maxWidth="150px">
                                {data?.coverageDetails?.memberIdNumber ? (
                                  <>
                                    {`${
                                      view === PLAN_VIEW.MEDICAL.type &&
                                      !isNull(
                                        data?.coverageDetails?.alphaPrefix
                                      )
                                        ? data?.coverageDetails?.alphaPrefix
                                        : ''
                                    }${
                                      data?.coverageDetails?.memberIdNumber
                                    }`}{' '}
                                  </>
                                ) : (
                                  <span
                                    className={styles.linkText}
                                    onClick={() => onEditClick()}
                                  >
                                    <b>Add ID</b>
                                  </span>
                                )}
                              </OverflowPopover>
                            )}
                          </>
                          <>
                            {editMemberId.editModeOn ? (
                              <span
                                className={styles.linkText}
                                onClick={() => handleOnEditSave()}
                              >
                                <b>Save</b>
                              </span>
                            ) : (
                              <img
                                src={EditIcon}
                                alt="edit"
                                className={styles.editIcon}
                                onClick={() => onEditClick()}
                              />
                            )}
                          </>
                        </div>
                      ) : (
                        <>
                          <OverflowPopover maxWidth="150px">
                            {data?.coverageDetails?.memberIdNumber ? (
                              <>
                                {`${
                                  view === PLAN_VIEW.MEDICAL.type &&
                                  !isNull(data?.coverageDetails?.alphaPrefix)
                                    ? data?.coverageDetails?.alphaPrefix
                                    : ''
                                }${data?.coverageDetails?.memberIdNumber}`}{' '}
                              </>
                            ) : (
                              '-'
                            )}
                          </OverflowPopover>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col span={9} className={styles.labelText}>
                      <b>Group ID #</b>
                    </Col>
                    <Col span={15}>
                      {data?.coverageDetails?.groupIdNumber ? (
                        <OverflowPopover maxWidth="150px">
                          {data?.coverageDetails?.groupIdNumber}
                        </OverflowPopover>
                      ) : (
                        '-'
                      )}
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col span={9} className={styles.labelText}>
                      <b>Phone</b>
                    </Col>
                    <Col span={15}>
                      {data?.coverageDetails?.carrierPhone ? (
                        <OverflowPopover maxWidth="150px">
                          <a
                            className={styles.linkText}
                            href={`tel:${data?.coverageDetails?.carrierPhone}`}
                          >
                            <b> {data?.coverageDetails?.carrierPhone}</b>
                          </a>
                        </OverflowPopover>
                      ) : (
                        '-'
                      )}
                    </Col>
                  </Row>
                </Col>
                {view === PLAN_VIEW.MEDICAL.type && (
                  <Col span={12}>
                    <Row>
                      <Col span={9} className={styles.labelText}>
                        <b>Rx Bin #</b>
                      </Col>

                      <Col span={15}>
                        {data?.coverageDetails?.rxBINumber ? (
                          <OverflowPopover maxWidth="150px">
                            {data?.coverageDetails?.rxBINumber}
                          </OverflowPopover>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={9} className={styles.labelText}>
                        <b>Rx PCN #</b>
                      </Col>
                      <Col span={15}>
                        {data?.coverageDetails?.rxPCNumber ? (
                          <OverflowPopover maxWidth="150px">
                            {data?.coverageDetails?.rxPCNumber}
                          </OverflowPopover>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={9} className={styles.labelText}>
                        <b>Rx Group #</b>
                      </Col>
                      <Col span={15}>
                        {data?.coverageDetails?.rxGroupNumber ? (
                          <OverflowPopover maxWidth="150px">
                            {data?.coverageDetails?.rxGroupNumber}
                          </OverflowPopover>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </div>
          </div>
          <div className={styles.flipCardBack}>
            <div className={styles.cardTopContainer}>
              <Row justify="space-between">
                <Col>
                  {data?.coverageDetails?.logoUrl && (
                    <img
                      src={data?.coverageDetails?.logoUrl}
                      className={styles.employerLogo}
                      alt="employer"
                    />
                  )}
                </Col>
                <Col>
                  {employerData?.logoUrl && (
                    <img
                      src={employerData?.logoUrl}
                      className={styles.employerLogo}
                      alt="client"
                    />
                  )}
                </Col>
              </Row>
            </div>{' '}
            <div className={styles.cardBackBottomContainer}>
              <Row>
                <Col span={24}>
                  <h3 className={styles.backHeader}>MEMBERS AND PROVIDERS</h3>
                  <span className={styles.subHeading}>
                    Network providers must call to request prior approval and
                    confirm eligibility
                  </span>
                </Col>
              </Row>
              <Divider className={styles.containerDivider} />

              <Row gutter={32} justify="space-between">
                <Col span={12} className={styles.labelText}>
                  <Row justify="space-between">
                    <b>Carrier Phone #:</b>
                    {!data?.coverageDetails?.carrierPhone && '-'}
                  </Row>
                </Col>
                <Col>
                  {data?.coverageDetails?.carrierPhone && (
                    <a
                      className={styles.linkText}
                      href={`tel:${data?.coverageDetails?.carrierPhone}`}
                    >
                      <b> {data?.coverageDetails?.carrierPhone}</b>
                    </a>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IDCard;
