import { useLocation } from 'react-router-dom';
import {
  BENGUIDE_NOTIFICATIONS,
  BENGUIDE_SAVE_COMPLETED,
  REFRESH_BENGUIDE,
  EXIT_BENGUIDE,
  MEDICAL_PLAN_CHANNEL,
  DENTAL_PLAN_CHANNEL,
  VISION_PLAN_CHANNEL,
  TELEHEALTH_PLAN_CHANNEL,
  WELLBEING_PLAN_CHANNEL,
  FAMILY_AND_LEAVE_CHANNEL,
  HOLIDAY_AND_TIMEOFF_CHANNEL,
  ADDITIONAL_PERKS_CHANNEL,
  VOLUNTARY_BENEFITS_CHANNEL,
  LIFE_AND_DISABILITY_CHANNEL,
  BENGUIDE_COMMON_CHANNEL,
  FOUR_O_ONE_K_PLAN_CHANNEL,
  TAX_ADVANTAGED_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import UPClient from 'modules/clients/UPClient/UPClient';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import useBenguide from 'hooks/benguide';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';

interface BenefitGuideRefreshChannelProps {
  benefitKind?: string;
}

const BenefitGuideRefreshChannel = (props: BenefitGuideRefreshChannelProps) => {
  const upClient = useUPClient();
  const benguide = useBenguide();
  const location = useLocation();
  const getChannelBasedOnBenefitKind = (): string => {
    if (props.benefitKind === MDVTypes.MEDICAL) {
      return MEDICAL_PLAN_CHANNEL;
    } else if (props.benefitKind === MDVTypes.DENTAL) {
      return DENTAL_PLAN_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.TELEHEALTH.value) {
      return TELEHEALTH_PLAN_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.WELLBEING.value) {
      return WELLBEING_PLAN_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.FAMILY_AND_LEAVE.value) {
      return FAMILY_AND_LEAVE_CHANNEL;
    } else if (
      props.benefitKind === BENEFIT_CATEGORY.HOLIDAYS_AND_TIME_OFF.value
    ) {
      return HOLIDAY_AND_TIMEOFF_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.ADDITIONAL_PERK.value) {
      return ADDITIONAL_PERKS_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.VOLUNTARY_BENEFIT.value) {
      return VOLUNTARY_BENEFITS_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.LIFE.value) {
      return LIFE_AND_DISABILITY_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.RETIREMENT.value) {
      return FOUR_O_ONE_K_PLAN_CHANNEL;
    } else if (props.benefitKind === BENEFIT_CATEGORY.TAX_ADVANTAGE.value) {
      return TAX_ADVANTAGED_PLAN_CHANNEL;
    }
    return VISION_PLAN_CHANNEL;
  };

  return (
    <UPClient
      channel={BENGUIDE_NOTIFICATIONS}
      subscribe={(event: string, data: any) => {
        switch (event) {
          case REFRESH_BENGUIDE:
            upClient.postMessage({
              channel: getChannelBasedOnBenefitKind(),
              event: BENGUIDE_SAVE_COMPLETED,
              data: {},
            });
            upClient.postMessage({
              channel: BENGUIDE_COMMON_CHANNEL,
              event: BENGUIDE_SAVE_COMPLETED,
              data: benguide.masterId,
            });
            window.location.href = `${location.pathname}/?revision=${data.revision}`;
            break;
          case EXIT_BENGUIDE:
            upClient.postMessage({
              channel: getChannelBasedOnBenefitKind(),
              event: EXIT_BENGUIDE,
              data: {},
            });
            upClient.postMessage({
              channel: BENGUIDE_COMMON_CHANNEL,
              event: EXIT_BENGUIDE,
              data: {},
            });
            break;
        }
      }}
    />
  );
};

export default BenefitGuideRefreshChannel;
