import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import * as DentalPlanService from 'modules/benefitsWellness/services/DentalPlanService';
import * as PlanService from 'modules/auth/services/PlanService';
import * as BenguideService from 'services/BenGuideService';
import PlanReference from 'model/PlanReference';
import SectionApiRequestResponse from 'model/SectionApiRequestResponse';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import DentalSection from 'model/MDVSection';
import MDVPlan from 'model/MDVPlan';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import { setCloseModalsInUP } from 'modules/auth/slices/benguideSlice';
const SECTION_DENTAL = 'DENTAL';

type DentalState = {
  dentalPlanList: {
    inProgress: boolean;
    error: any;
    data: MDVPlan[];
  };
  dentalSection: {
    enable: boolean;
    plans: MDVPlan[];
  };
  inProgress: boolean;
  filteredPlanList: MDVPlan[];
  planProviders: string[];
  planTypes: string[];
  appliedFilters: {
    providers: object;
    types: object;
  };
};

const initialState = {
  dentalPlanList: {
    inProgress: false,
    error: null,
    data: [],
  },
  dentalSection: {
    enable: false,
    plans: [],
  },
  inProgress: false,
  filteredPlanList: [],
  planProviders: [],
  planTypes: [],
  appliedFilters: {
    providers: {},
    types: {},
  },
} as DentalState;

const dentalSlice = createSlice({
  name: 'dental',
  initialState,
  reducers: {
    dentalPlanListFetchingStarted: (state) => {
      state.dentalPlanList.inProgress = true;
      state.dentalPlanList.error = null;
    },
    dentalPlanListFetchingCompleted: (state, { payload }) => {
      state.dentalPlanList.inProgress = false;
      state.dentalPlanList.error = null;
      state.dentalPlanList.data = payload;
    },
    dentalPlanListFetchingFailed: (state, { payload }) => {
      state.dentalPlanList.inProgress = false;
      state.dentalPlanList.error = payload;
      state.dentalPlanList.data = [];
    },
    dentalSectionUpdatingCompleted: (state, { payload }) => {
      state.dentalSection = payload;
      state.filteredPlanList = payload.plans;
    },
  },
});

export const {
  dentalPlanListFetchingStarted,
  dentalPlanListFetchingCompleted,
  dentalPlanListFetchingFailed,
  dentalSectionUpdatingCompleted,
} = dentalSlice.actions;

export default dentalSlice.reducer;

export const getDentalPlanList = (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(dentalPlanListFetchingStarted());
    DentalPlanService.getDentalPlanList(employerId, planYearId, benefitClasses)
      .then(({ data }: AxiosResponse) => {
        const { content } = data;
        dispatch(dentalPlanListFetchingCompleted(content));
      })
      .catch((error) => {
        dispatch(dentalPlanListFetchingFailed(error));
      });
  };
};

export const updateDentalSection: any = (
  dentalSection: DentalSection,
  closeUpPopUp: boolean = true
) => {
  return async (dispatch: Dispatch) => {
    const dentalPlanPromisses = dentalSection.plans.map((plan: any) => {
      const { id, planId, revision } = plan;
      return PlanService.getPlans('dentals', planId || id, revision);
    });
    const plans = (await Promise.all(dentalPlanPromisses)).map(
      (plan: any) => plan.data as MDVPlan
    );
    dispatch(dentalSectionUpdatingCompleted({ ...dentalSection, plans }));
    if (closeUpPopUp) {
      dispatch(setCloseModalsInUP(true));
    }
  };
};

export const saveAndReloadDentalSection = (
  benguideId: string,
  selectedPlans: MDVPlan[],
  orderByPlanNames: boolean
) => {
  const planReferences = selectedPlans.map((plan) => {
    return {
      planId: plan.id,
      planName: plan.name,
      startDate: plan.startDate,
      endDate: plan.endDate,
      isArchived: plan.archived,
      revision: plan.revision,
      groups: plan.groups,
      benefitCategory: BENEFIT_CATEGORY.DENTAL.value,
      benefitKind: BENEFIT_CATEGORY.DENTAL.value,
      carrier: {
        id: get(plan, 'carrier.id', null),
        name: get(plan, 'carrier.name', null),
      },
      benefitCarrier: {
        id: get(plan, 'benefitCarrier.id', null),
        name: get(plan, 'benefitCarrier.name', null),
      },
    } as PlanReference;
  });

  return async (dispatch: Dispatch) => {
    const sectionApiRequest = {
      plans: planReferences,
      sectionName: SECTION_DENTAL,
      orderByPlanNames: orderByPlanNames,
    } as AddPlanApiRequestPayload;
    await BenguideService.addPlans(benguideId, sectionApiRequest);
    dispatch(reloadDentalSection(benguideId));
  };
};

export const reloadDentalSection: any = (
  benguideId: string,
  closeUpPopUp: boolean = true
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await BenguideService.getBenefitPageSection(
      benguideId,
      BENEFIT_CATEGORY.DENTAL.value
    );
    const sectionResponse = data as SectionApiRequestResponse;
    dispatch(updateDentalSection(sectionResponse.benefitPage, closeUpPopUp));
  };
};
